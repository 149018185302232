<?php

Namespace Model\Drive;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Drive List of Piranha Web Services';
        $page['heading'] = 'List of Drive';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Drive Mod', $page, $pageVars);
        return $page;
    }

    public function showDriveLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Drive Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DriveView();
            $view->loadTemplate('Drive', 'DriveLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayDrive($bind);
            \ISOPHP\js_core::$console->log('apply Drive Directory Create 2', $vars);
        };
    }

    public function showDriveDirectoryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Drive Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DriveView();
            $view->loadTemplate('Drive', 'DirectoryCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDrive($bind);
            \ISOPHP\js_core::$console->log('apply Drive Directory Create 2', $vars);
        };
    }

    public function showDriveDirectoryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Drive List');
            $vars['control'] = 'Drive';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/drive/directory/all';
            $afterwards = $this->applyDriveDirectoryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Drive Model: ', $result);
            return $result;
        };
    }

    public function applyDriveDirectoryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Drive Directory List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Drive Directory List 1', $vars);
                $view = new \View\DriveView();
                $view->loadTemplate('Drive', 'DirectoryList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDrive($bind);
                \ISOPHP\js_core::$console->log('apply Drive Directory List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Drive Directory List failed');
            }
        };
        return $func;
    }

    public function showDriveDirectoryListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Drive List');
            $vars['control'] = 'Drive';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/drive/directory/all';
            $afterwards = $this->applyDriveDirectoryListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Drive Model: ', $result);
            return $result;
        };
    }

    public function applyDriveDirectoryListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Drive Directory List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Drive Directory List 1', $vars);
                $view = new \View\DriveView();
                $view->loadTemplate('Drive', 'DirectoryListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayDriveDirectoryDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Drive Directory List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Drive Directory List failed');
            }
        };
        return $func;
    }

    public function showDriveObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Drive Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DriveView();
            $view->loadTemplate('Drive', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDrive($bind);
            \ISOPHP\js_core::$console->log('apply Drive Object Create 2', $vars);
        };
    }

    public function showDriveObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for directory name');
            \ISOPHP\js_core::$console->log($pageVars['directory_name']);
            \ISOPHP\js_core::$console->log('Showing Drive List');
            $vars['control'] = 'Drive';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/drive/object/all';
            $vars['directory_name'] = $pageVars['params']['directory_name'];
            $afterwards = $this->applyDriveObjectListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Drive Model: ', $result);
            return $result;
        };
    }

    public function applyDriveObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Drive Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Drive Object List 1', $vars);
                $view = new \View\DriveView();
                $view->loadTemplate('Drive', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDrive($bind);
                \ISOPHP\js_core::$console->log('apply Drive Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Drive Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save directory
     */
    public function saveDirectoryAttemptToDrive($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save directory to drive') ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $vars = array() ;
        $vars['control'] = 'Drive' ;
        $vars['action'] = 'directory-create' ;
        $vars['api_uri'] = '/api/drive/directory/create' ;
        $vars['directory_name'] = $jQuery('#directory_name')->val() ;
        if ($jQuery('#directory_public_access')->prop('checked')) {
            $vars['public_access'] = 'on' ;
        } else {
            $vars['public_access'] = '' ;
        }
        $vars['comment'] = $jQuery('#comment')->val() ;
        $afterwards = $this->saveDirectoryResultFromDrive($pageVars) ;
        $result = $this->performRequest($vars, $afterwards) ;
        return $result ;
    }

    public function saveDirectoryResultFromDrive($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save drive directory result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Directory '.$request_data['directory']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('drive directory saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Drive', 'directory-list', array(), '/drive/directory/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Directory '.$request_data['directory']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('drive directory save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToDrive($pageVars) {
        \ISOPHP\js_core::$console->log('save object to drive');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Drive';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/drive/object/create';
        $vars['directory_name'] = $jQuery('#directory_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $afterwards = $this->saveObjectResultFromDrive($pageVars);
        $create_message = 'Creating Object...' ;
        \Core\WindowMessage::showMessage($create_message, 'warning');
        $result = $this->performFileRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromDrive($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save drive object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['name'].' in Directory '.$request_data['directory'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('drive object saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $directory_name = $request_data['directory'] ;
                $navigate->route('Drive', 'object-list', array(
                    'directory_name' => $directory_name,
                ), '/drive/'.$directory_name.'/object/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['name'].' in Directory '.$request_data['directory'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('drive object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToDrive($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to drive');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Drive';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/drive/object/delete';
        $vars['directory_name'] = $pageVars['params']['directory_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteObjectResultFromDrive($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    /**
     * Download object
     */
    public function downloadObjectAttemptFromDrive($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $directory_name = $pageVars['params']['directory_name'] ;
        $object_key = $pageVars['params']['object_key'] ;
        $object_id = $pageVars['params']['object_id'] ;

        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $token = $cookies['user_tokens'][0]['data'] ;
        }
        \ISOPHP\js_core::$console->log('download object dump PageVars', $pageVars);
        $config = new \Model\Configuration() ;
        $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
        $server_url .= '/api/drive/object/download' ;
        $server_url .= '?token='.$token ;
        $server_url .= '&directory_name='.$directory_name ;
        $server_url .= '&object_key='.$object_key ;
        $server_url .= '&object_id='.$object_id ;
        $slug = $directory_name.'_'.$object_id ;
        $dl_el_id = 'download_link_'.$slug ;
        $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download></a>' ;
        \ISOPHP\js_core::$console->log('Drive Object download html', $html);
        $body_jq = $jQuery('body') ;
        $body_jq->append($html) ;
        $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
        $dl_el_js->click() ;
        \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
        \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
    }

    public function downloadObjectResultFromDrive($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Download drive object result to', $request_data);

            self::downloadToFile($request_data->responseText, 'filename.txt', 'text/plain') ;

            if ($request_data['status'] === 'OK') {
                $msg = 'Downloaded Object '.$request_data['object']['drive_object_name'].' from Directory '.$request_data['object']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('drive object downloaded, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('drive object download failed');
            }
        };
        return $func;
    }

    public function deleteObjectResultFromDrive($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete drive object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object']['drive_object_name'].' from Directory '.$request_data['object']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('drive object deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Drive', 'directory-list', array(), '/drive/directory/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['drive_directory_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('drive object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete directory
     */
    public function deleteDirectoryAttemptToDrive($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete directory to drive');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Drive';
        $vars['action'] = 'directory-delete';
        $vars['api_uri'] = '/api/drive/directory/delete';
        $vars['directory_name'] = $pageVars['params']['directory_name'];
        \ISOPHP\js_core::$console->log('delete directory vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteDirectoryResultFromDrive($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDirectoryResultFromDrive($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete drive directory result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Directory '.$request_data['directory'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('drive directory deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Drive', 'directory-list', array(), '/drive/directory/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Directory '.$request_data['directory'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('drive directory delete failed');
            }
            $show_directory_list = self::showDriveDirectoryList($pageVars) ;
            $show_directory_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_drive_landing = $jQuery('.go-drive-landing');
                $go_drive_landing->off() ;
                $go_drive_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'landing', array(), '/drive');
                });

                $go_drive_directory_list = $jQuery('.go-drive-directory-list');
                $go_drive_directory_list->off() ;
                $go_drive_directory_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'directory-list', array(), '/drive/directory/list');
                });

                $go_drive_directory_create = $jQuery('.go-drive-directory-create');
                $go_drive_directory_create->off() ;
                $go_drive_directory_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'directory-create', array(), '/drive/directory/create');
                });

                $save_directory = $jQuery('.attempt-drive-directory-create');
                $save_directory->off() ;
                $save_directory->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'directory-save-attempt', array());
                });

                $go_drive_object_list = $jQuery('.go-drive-object-list');
                $go_drive_object_list->off() ;
                $go_drive_object_list->on('click', function ($jsThis) use ($jQuery, $navigate) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $directory_name = $jqThis->attr('data-drive-directory-name') ;
                    $navigate->route('Drive', 'object-list', array(
                        'directory_name' => $directory_name,
                    ), '/drive/'.$directory_name.'/object/list');
                });

                $go_drive_object_create = $jQuery('.go-drive-object-create');
                $go_drive_object_create->off() ;
                $go_drive_object_create->on('click', function ($jsThis) use ($jQuery, $navigate) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $directory_name = $jqThis->attr('data-drive-directory-name') ;
                    $navigate->route('Drive', 'object-create', array(
                        'directory_name' => $directory_name,
                    ), '/drive/'.$directory_name.'/object/create');
                });

                $save_object = $jQuery('.attempt-drive-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'object-save-attempt', array());
                });

                $download_object = $jQuery('.attempt-drive-object-download');
                $download_object->off() ;
                $download_object->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked object download button');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    \ISOPHP\js_core::$console->log('jqthis object download ', $this_id, $jqThis);
                    $object_name = $jqThis->attr('data-drive-object-name') ;
                    $object_id = $jqThis->attr('data-drive-object-id') ;
                    $directory_name = $jqThis->attr('data-drive-directory-name') ;
                    \ISOPHP\js_core::$console->log('object download data vars', $object_name, $object_id, $directory_name);
                    $navigate->route('Drive', 'object-download-attempt', array(
                        'object_id' => $object_id,
                        'object_key' => $object_name,
                        'directory_name' => $directory_name,
                    ));
                });

//                $download_object = $jQuery('.attempt-drive-object-download');
//                $download_object->off() ;
//                $download_object->on('click', function ($jsthis) use ($navigate, $jQuery) {
//                    \ISOPHP\js_core::$console->log('Clicked recording download button');
//                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
//                    $device_id = $jqThis->attr('data-mobiledevice-device-id') ;
//                    $recording_id = $jqThis->attr('data-mobiledevice-recording-id') ;
//                    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
//                    if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
//                        $token = $cookies['user_tokens'][0]['data'] ;
//                    }
//                    $config = new \Model\Configuration() ;
//                    $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
//                    $server_url .= '/api/mobiledevice/recording/play' ;
//                    $server_url .= '?token='.$token ;
//                    $server_url .= '&device_name='.$device_name ;
//                    $server_url .= '&device_id='.$device_id ;
//                    $server_url .= '&recording_id='.$recording_id ;
//                    $slug = $device_id.'_'.$recording_id ;
//                    $dl_el_id = 'download_link_'.$slug ;
//                    $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download>'.$slug.'.mp4</a>' ;
//                    \ISOPHP\js_core::$console->log('Recording download html', $html);
//                    $body_jq = $jQuery('body') ;
//                    $body_jq->append($html) ;
//                    $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
//                    $dl_el_js->click() ;
//                    \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
//                    \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
////                    $dl_el_jq->remove() ;
//                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-drive-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-drive-object-name') ;
                    $object_id = $jqThis->attr('data-drive-object-id') ;
                    $directory_name = $jqThis->attr('data-drive-directory-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg  = "You are about to delete the Object $object_name from the directory $directory_name. ";
                    $msg .= "This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $directory_name) {
                        $navigate->route('Drive', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'directory_name' => $directory_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $directory_delete = $jQuery('.attempt-drive-directory-delete');
                $directory_delete->off() ;
                $directory_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $directory_name = $jqThis->attr('data-drive-directory-name') ;
                    \ISOPHP\js_core::$console->log('Clicked directory delete button');
                    $msg = "You are about to delete the Directory $directory_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $directory_name) {
                        $navigate->route('Drive', 'directory-delete-attempt', array(
                            'directory_name' => $directory_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindDirectoryListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding directory switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding directory switch');
            $switch_directory = $jQuery('#directory_name');
            $switch_directory->off() ;
            $switch_directory->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#directory_name') ;
                $directory_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked directory switch');
                $navigate->route('Drive', 'object-list', array(
                    'directory_name' => $directory_name,
                ));
            } ) ;

        };
        return $func;
    }

    public static function downloadToFile ($content, $filename, $contentType) {
        $a = \ISOPHP\js_core::$window->document->createElement('a');
        $content_array = [$content] ;
        $type_object = new \StdClass() ;
        $type_object->type = $contentType ;
        $file = new \ISOPHP\js_core::$window->Blob($content_array, $type_object);
        $a->href= \ISOPHP\js_core::$window->URL->createObjectURL($file);
        $a->download = $filename;
        $a->click();
        \ISOPHP\js_core::$window->URL->revokeObjectURL($a->href);
    }

}