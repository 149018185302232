<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-networkacl-create" class="btn btn-success attempt-networkacl-create">Create</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                
                    <div>
                        <h4>New SVPC Network ACL</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>SVPC</label>
                            <div id="svpc_svpc_dropdown">
                                <select class="form-control" name="svpc_name" id="svpc_name">
                                    <option>Loading SVPCs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Network ACL</label>
                            <textarea name="networkacl_value" id="networkacl_value" class="form-control"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control"></input>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Subnet Associations</label>
                            <input type="text"  name="subnet_associations" id="subnet_associations" class="form-control"></input>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Edge Associations</label>
                            <input type="text"  name="edge_associations" id="edge_associations" class="form-control"></input>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Main</label>
                            <select name="main" id="main">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-networkacl-create" class="btn btn-success attempt-networkacl-create">Create</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    return $html;

};
