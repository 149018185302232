<?php
\Core\View::$template = function () {
    $html = '
    <div class="nav_wrap">
      <nav class="navbar navbar-expand-lg fixed-top nav-transparent">
      <div class="container">
        <a class="navbar-brand" href="#" style="height: 90px;">
          <img src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/piranha-corp.png" height="80">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link nav-signup go-login-page" href="/login" >Login</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </div>

    <section class="general top-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-xs-12 col-12 top-info">
            <h1 class="landing-page-heading">The Piranha Cloud</h1>
          </div>
        </div>
      </div>
    </section>
    
    <section class="general">
      <div class="container">
        <div class="row ">
          <div class="col-lg-12">
            <h1 class="sitefont">Welcome</h1>
            <p>
              Piranha Web Services provides the most advanced Hosting Services for your applications,
              with unrivalled Price and Performance. <a href="/login"><span class="">Login Now</span> </a>to begin. 
            </p>
          </div>
        </div>
      </div>
    </section>';


    $html = $html . '

    <section class="footer-bottom">
      <div class="container">
        <div class="row clearfix">
          <div class="col-md-3 col-12 col-xs-12 col-lg-3 copyright_footer">
            © 2024 Piranha Corporation
          </div>
          <div class="col-md-9 col-12 col-xs-12 col-lg-9">
            <ul class="footer-links">
              <li>
                <a target="_blank" href="https://docs.piranha.sh">Documentation</a>
              </li>
              <li>
                <a target="_blank" href="https://www.piranha.sh/careers">Careers</a>
              </li>
              <li>
                <a target="_blank" href="https://www.piranha.sh/privacy">Privacy</a>
              </li>
              <li>
                <a target="_blank" href="https://www.piranha.sh/terms">Terms</a>
              </li>
              <li>
                <a target="_blank" href="https://support.piranha.sh">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>';

    return $html;
};

