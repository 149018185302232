<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $volumes_are_array = \ISOPHP\core::$php->is_array($rqd['volumes']) ;
    if ($volumes_are_array === true) {
        $volumes_exist = true ;
    } else {
        $volumes_exist = false ;
    }
    if ($volumes_exist === true) {
        $volume_count = \ISOPHP\core::$php->count($rqd['volumes']) ;
    } else {
        $volume_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-volume-create" class="btn btn-success go-sc1-volume-create">New Volume</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-volume-list" class="btn-refresh btn btn-info go-sc1-volume-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Volume List</h4>
         </div> ' ;

    if ($volumes_exist === true && $volume_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-volume-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_volume item_list_sc1_volumes" id="item_list_sc1_volumes">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">Instance</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">Size</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">Data</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_volume_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['volumes'] as $one_sc1_volume) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' . $one_sc1_volume['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_instance_name"><span class="instance_id sc1_listing">' . $one_sc1_volume['vm_name'] .' : ' . $one_sc1_volume['vmid'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_volume_name"><span class="volume_name sc1_listing">' . $one_sc1_volume['size'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_volume_data"><span class="sc1_volume_data sc1_listing"> Write: ' . $one_sc1_volume['wr_bytes'] . '<br/>Read: ' . $one_sc1_volume['rd_bytes'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_volume_type"><span class="sc1_type sc1_listing">' . $one_sc1_volume['type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_volume_' . $one_sc1_volume['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-volume-edit btn btn-outline-primary"
                                   data-sc1-volume-id="' . $one_sc1_volume['id'] . '"
                                   data-sc1-volume-name="' . $one_sc1_volume['volume_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_volume_' . $one_sc1_volume['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-volume-delete btn btn-outline-danger"
                                   data-sc1-volume-id="' . $one_sc1_volume['id'] . '"
                                   data-sc1-volume-name="' . $one_sc1_volume['volume_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-volume-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Volumes</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-volume-create" class="btn btn-success go-sc1-volume-create">New Volume</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-volume-list" class="btn-refresh btn btn-info go-sc1-volume-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Volumes</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_volume_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_volume_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_volume_awscli_aws" class="instruction_set instruction_set_list_volume_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_volume_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_volume_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_volume_ptc_aws" class="instruction_set instruction_set_list_volume_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};