<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                
                    <div>
                        <h4>New Payment Method</h4>
                    </div>
                    
                    <div class="row">
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-paymentmethod-create" class="btn btn-success attempt-paymentmethod-create">Create</span>
                            <span id="go-billing-paymentmethod-list" class="btn btn-warning go-billing-paymentmethod-list">Payment Method List</span>
                            <span id="go-billing-landing" class="btn btn-warning go-billing-landing">Billing Home</span>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control" id="name" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name on Card</label>
                                <input type="text" name="name_on_card" id="name_on_card" class="form-control" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Card Number</label>
                                <input type="text" name="card_number" id="card_number" class="form-control" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>CVV</label>
                                <input type="text"  name="cvv_no" id="cvv_no" class="form-control" maxlength="4" size="4" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Billing Address</label>
                                <input type="text" name="billing_address" id="billing_address" class="form-control" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Postcode</label>
                                <input type="text" name="postcode" id="postcode" class="form-control" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-paymentmethod-create" class="btn btn-success attempt-paymentmethod-create">Create</span>
                            <span id="go-billing-paymentmethod-list" class="btn btn-warning go-billing-paymentmethod-list">Payment Method List</span>
                            <span id="go-billing-landing" class="btn btn-warning go-billing-landing">Billing Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    return $html;

};
