<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $definitions_are_array = \ISOPHP\core::$php->is_array($rqd['definitions']) ;
    if ($definitions_are_array === true) {
        $definitions_exist = true ;
    } else {
        $definitions_exist = false ;
    }
    if ($definitions_exist === true) {
        $definition_count = \ISOPHP\core::$php->count($rqd['definitions']) ;
    } else {
        $definition_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcs-definition-create" class="btn btn-success go-pcs-definition-create">New Definition</span>
            <span id="go-pcs-landing" class="btn btn-info go-pcs-landing">PCS Home</span>
            <span id="go-pcs-definition-list" class="btn btn-info go-pcs-definition-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Definition List</h4>
        </div> ' ;

    if ($definitions_exist === true && $definition_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-1 col-1">
                        &nbsp;
                    </div>
                    <div class="col-sm-10 col-10 one_pcs_definition">
                        <div class="col-sm-3 col-3">
                            <h5 class="pcs_definition_id_title">PCS Definition ID</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="pcs_definition_id_title">Definition Name</h5>
                        </div>
                        <div class="col-sm-5 col-5">
                            <h5 class="pcs_definition_id_title">Comment</h5>
                        </div>                        
                    </div>
                    <div class="col-sm-1 col-1">
                        &nbsp;
                    </div>
                 </div>';
        foreach ($rqd['definitions'] as $one_pcs_definition) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_pcs_definition">
                    <div class="col-sm-3 col-3">
                        <p class="pcs_list_id"><span class="pcs_id pcs_listing">' . $one_pcs_definition['pcs_definition_id'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="pcs_list_name"><span class="pcs_name pcs_listing">' . $one_pcs_definition['pcs_definition_name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="pcs_list_comment pcs_listing">' . $one_pcs_definition['pcs_definition_comment'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_pcs_definition_' . $one_pcs_definition['pcs_definition_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-definition-edit btn btn-outline-primary"
                                   data-pcs-definition-id="' . $one_pcs_definition['pcs_definition_id'] . '"
                                   data-pcs-definition-name="' . $one_pcs_definition['pcs_definition_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_pcs_definition_' . $one_pcs_definition['pcs_definition_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-definition-delete btn btn-outline-danger"
                                   data-pcs-definition-id="' . $one_pcs_definition['pcs_definition_id'] . '"
                                   data-pcs-definition-name="' . $one_pcs_definition['pcs_definition_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Definitions</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-pcs-definition-create" class="btn btn-success go-pcs-definition-create">New Definition</span>
                <span id="go-pcs-landing" class="btn btn-info go-pcs-landing">PCS Home</span>
                <span id="go-pcs-definition-list" class="btn btn-info go-pcs-definition-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing PCS Definitions</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_definition_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_definition_awscli"></i></h5>
                    <pre id="instruction_set_list_definition_awscli" class="instruction_set instruction_set_list_definition_awscli">
aws s3 ls --profile=abc --region=hq --endpoint-url=http://aws.saharaws.vm:8888
2020-01-23 14:06:53 definition1
2020-01-23 14:06:58 definition2
                    </pre>
              ';


    return $html;

};