<?php

\Core\View::$template = function () {

    $pv = \Core\View::$page_vars;

    $html = '   

            <div class="col-sm-12 col-12">
                    
                <div class="col-sm-12 col-12 text-center">
                    <span id="attempt-ss3-object-create" class="btn btn-success attempt-ss3-object-create"
                          data-ss3-object-creation-key="'.$pv['params']['object_creation_key'].'"
                          data-ss3-bucket-id="' . $pv['params']['bucket_name'] . '"
                          data-ss3-bucket-name="' . $pv['params']['bucket_name'] . '">Create</span>
                    <span id="go-ss3-bucket-list" class="btn btn-warning go-ss3-bucket-list">Bucket List</span>
                    <span id="go-ss3-object-list" class="btn btn-warning go-ss3-object-list"
                          data-ss3-bucket-id="' . $pv['params']['bucket_name'] . '"
                          data-ss3-bucket-name="' . $pv['params']['bucket_name'] . '">Object List</span>
                    <span id="go-ss3-landing" class="btn btn-warning go-ss3-landing">SS3 Home</span>
                </div>
            
                <div>
                    <h5>New SS3 Object</h5>
                    <h6>Bucket: <strong>'.$pv['params']['bucket_name'].'</strong></h6>
                    <h6>Key: <strong>'.$pv['params']['object_creation_key'].'</strong></h6>
                </div>

                <div class="col-sm-12 col-12">
                    <div class="form-group">
                        <label>File</label>
                        <input type="file" name="file" class="form-control" id="file" />
                    </div>
                </div>

                <div class="col-sm-12 col-12">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" name="object_name" class="form-control" id="object_name" value="" />
                        <input type="hidden" name="bucket_name" class="form-control" id="bucket_name" value="'.$pv['params']['bucket_name'].'" />
                    </div>
                </div>
                    
                <div class="col-sm-12 col-12 text-center">
                    <span id="attempt-ss3-object-create" class="btn btn-success attempt-ss3-object-create"
                          data-ss3-object-creation-key="'.$pv['params']['object_creation_key'].'"
                          data-ss3-bucket-id="' . $pv['params']['bucket_name'] . '"
                          data-ss3-bucket-name="' . $pv['params']['bucket_name'] . '">Create</span>
                    <span id="go-ss3-bucket-list" class="btn btn-warning go-ss3-bucket-list">Bucket List</span>
                    <span id="go-ss3-object-list" class="btn btn-warning go-ss3-object-list"
                          data-ss3-bucket-id="' . $pv['params']['bucket_name'] . '"
                          data-ss3-bucket-name="' . $pv['params']['bucket_name'] . '">Object List</span>
                    <span id="go-ss3-landing" class="btn btn-warning go-ss3-landing">SS3 Home</span>
                </div>
                    
            </div> ';


    return $html;

};
