<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
        
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-svpc-vpc-create" class="btn btn-success attempt-svpc-vpc-create disabled">Create</span>
                        <span id="go-svpc-svpc-list" class="btn btn-info go-svpc-vpc-list">VPC List</span>
                        <span id="go-svpc-landing" class="btn btn-info go-svpc-landing">SVPC Home</span>
                    </div>
                        
                    <div>
                        <h4>New VPC</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Name</label>
                        <input type="text" name="name" class="form-control" id="name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>CIDR</label>
                        <input type="text" name="cidr" class="form-control" id="cidr" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="description" id="description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-svpc-vpc-create" class="btn btn-success attempt-svpc-vpc-create disabled">Create</span>
                        <span id="go-svpc-svpc-list" class="btn btn-info go-svpc-vpc-list">VPC List</span>
                        <span id="go-svpc-landing" class="btn btn-info go-svpc-landing">SVPC Home</span>
                    </div>
                    
                </div>
                
            </div>
                         
        </div>';

    return $html;

};