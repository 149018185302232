<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to the Piranha Container Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Container execution from Web Based Definitions.</h3>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-pcs-definition-list" class="go-pcs-definition-list btn btn-lg btn-warning">Definitions</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-pcs-definition-create" class="go-pcs-definition-create btn btn-lg btn-warning">Create Definition</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-pcs-container-list" class="go-pcs-container-list btn btn-lg btn-warning">Containers</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-pcs-container-create" class="go-pcs-container-create btn btn-lg btn-warning">Create Container</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;