<?php

Namespace Model\SS3;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SS3 List of Piranha Web Services';
        $page['heading'] = 'List of SS3';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SS3 Mod', $page, $pageVars);
        return $page;
    }

    public function showSS3Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SS3 Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SS3View();
            $view->loadTemplate('SS3', 'SS3Landing.php', $vars);
            $bind = self::bindButtons();
            $view->displaySS3($bind);
            \ISOPHP\js_core::$console->log('apply SS3 Bucket Create 2', $vars);
        };
    }

    public function showSS3BucketCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SS3 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SS3View();
            $view->loadTemplate('SS3', 'BucketCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySS3($bind);
            \ISOPHP\js_core::$console->log('apply SS3 Bucket Create 2', $vars);
        };
    }

    public function showSS3BucketList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SS3 List');
            $vars['control'] = 'SS3';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/ss3/bucket/all';
            $vars['page'] = $pageVars['params']['page'] ;
            $afterwards = $this->applySS3BucketListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            \ISOPHP\js_core::$console->log('SS3 Model: ', $result);
            return $result;
        };
    }

    public function applySS3BucketListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SS3 Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SS3 Bucket List 1', $vars);
                $view = new \View\SS3View();
                $view->loadTemplate('SS3', 'BucketList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySS3($bind);
                \ISOPHP\js_core::$console->log('apply SS3 Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SS3 Bucket List failed');
            }
        };
        return $func;
    }

    public function showSS3BucketListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SS3 List');
            $vars['control'] = 'SS3';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/ss3/bucket/all';
            $vars['page'] = 'all' ;
            $afterwards = $this->applySS3BucketListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            \ISOPHP\js_core::$console->log('SS3 Model: ', $result);
            return $result;
        };
    }

    public function applySS3BucketListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SS3 Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SS3 Bucket List 1', $vars);
                $view = new \View\SS3View();
                $view->loadTemplate('SS3', 'BucketListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySS3BucketDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SS3 Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SS3 Bucket List failed');
            }
        };
        return $func;
    }

    public function showSS3ObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SS3 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SS3View();
            $view->loadTemplate('SS3', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySS3($bind);
            \ISOPHP\js_core::$console->log('apply SS3 Object Create 2', $vars);
        };
    }

    public function showSS3ObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for bucket name') ;
            \ISOPHP\js_core::$console->log($pageVars['bucket_name']) ;
            \ISOPHP\js_core::$console->log('Showing SS3 List') ;
            $vars['control'] = 'SS3' ;
            $vars['action'] = 'list' ;
            $vars['api_uri'] = '/api/ss3/object/all' ;
            $vars['bucket_name'] = $pageVars['params']['bucket_name'] ;
            $vars['page'] = $pageVars['params']['page'] ;
            if (isset($pageVars['params']['key'])) {
                $vars['key'] = $pageVars['params']['key'] ;
            }
            \ISOPHP\js_core::$console->log('showSS3ObjectList $vars') ;
            \ISOPHP\js_core::$console->log($vars) ;
            $afterwards = $this->applySS3ObjectListResult($pageVars) ;
            $result = $this->performRequest($vars, $afterwards) ;
            \ISOPHP\js_core::$console->log('SS3 Model: ', $result) ;
            return $result ;
        };
    }

    public function applySS3ObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SS3 Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SS3 Object List 1', $vars);
                $view = new \View\SS3View();
                $view->loadTemplate('SS3', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySS3($bind);
                \ISOPHP\js_core::$console->log('apply SS3 Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SS3 Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save bucket
     */
    public function saveBucketAttemptToSS3($pageVars)
    {
        \ISOPHP\js_core::$console->log('save bucket to ss3') ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $vars = array() ;
        $vars['control'] = 'SS3' ;
        $vars['action'] = 'bucket-create' ;
        $vars['api_uri'] = '/api/ss3/bucket/create' ;
        $vars['bucket_name'] = $jQuery('#bucket_name')->val() ;
        if ($jQuery('#bucket_public_access')->prop('checked')) {
            $vars['public_access'] = 'on' ;
        } else {
            $vars['public_access'] = '' ;
        }
        $vars['description'] = $jQuery('#description')->val() ;
        $afterwards = $this->saveBucketResultFromSS3($pageVars) ;
        $result = $this->performRequest($vars, $afterwards) ;
        return $result ;
    }

    public function saveBucketResultFromSS3($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save ss3 bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Bucket '.$request_data['bucket']['ss3_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ss3 bucket saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SS3', 'bucket-list', array(), '/ss3/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Bucket '.$request_data['bucket']['ss3_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ss3 bucket save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToSS3($pageVars) {
        \ISOPHP\js_core::$console->log('save object to ss3');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SS3';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/ss3/object/create';
        $vars['key'] = $pageVars['params']['object_key'];
        $vars['bucket_name'] = $jQuery('#bucket_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $afterwards = $this->saveObjectResultFromSS3($pageVars);
        $create_message = 'Creating Object...' ;
        \Core\WindowMessage::showMessage($create_message, 'warning');
        $result = $this->performFileRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromSS3($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save ss3 object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['name'].' in Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ss3 object saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $bucket_name = $request_data['bucket'] ;
                $navigate->route('SS3', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ), '/ss3/'.$bucket_name.'/object/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['name'].' in Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ss3 object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToSS3($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to ss3');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SS3';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/ss3/object/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteObjectResultFromSS3($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    /**
     * Download object
     */
    public function downloadObjectAttemptFromSS3($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $bucket_name = $pageVars['params']['bucket_name'] ;
        $object_key = $pageVars['params']['object_key'] ;
        $object_id = $pageVars['params']['object_id'] ;

        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $token = $cookies['user_tokens'][0]['data'] ;
        }
        \ISOPHP\js_core::$console->log('download object dump PageVars', $pageVars);
        $config = new \Model\Configuration() ;
        $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
        $server_url .= '/api/ss3/object/download' ;
        $server_url .= '?token='.$token ;
        $server_url .= '&bucket_name='.$bucket_name ;
        $server_url .= '&object_key='.$object_key ;
        $server_url .= '&object_id='.$object_id ;
        $slug = $bucket_name.'_'.$object_id ;
        $dl_el_id = 'download_link_'.$slug ;
        $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download></a>' ;
        \ISOPHP\js_core::$console->log('SS3 Object download html', $html);
        $body_jq = $jQuery('body') ;
        $body_jq->append($html) ;
        $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
        $dl_el_js->click() ;
        \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
        \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
    }

    public function downloadObjectResultFromSS3($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Download ss3 object result to', $request_data);

            self::downloadToFile($request_data->responseText, 'filename.txt', 'text/plain') ;

            if ($request_data['status'] === 'OK') {
                $msg = 'Downloaded Object '.$request_data['object']['ss3_object_name'].' from Bucket '.$request_data['object']['ss3_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ss3 object downloaded, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['ss3_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ss3 object download failed');
            }
        };
        return $func;
    }

    public function deleteObjectResultFromSS3($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete ss3 object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object'].' from Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ss3 object deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $bucket_name = $request_data['bucket'] ;
                $navigate->route('SS3', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ), '/ss3/'.$bucket_name.'/object/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['ss3_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ss3 object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete bucket
     */
    public function deleteBucketAttemptToSS3($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete bucket to ss3');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SS3';
        $vars['action'] = 'bucket-delete';
        $vars['api_uri'] = '/api/ss3/bucket/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        \ISOPHP\js_core::$console->log('delete bucket vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBucketResultFromSS3($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBucketResultFromSS3($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete ss3 bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ss3 bucket deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SS3', 'bucket-list', array(), '/ss3/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ss3 bucket delete failed');
            }
            $show_bucket_list = self::showSS3BucketList($pageVars) ;
            $show_bucket_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_ss3_landing = $jQuery('.go-ss3-landing');
                $go_ss3_landing->off() ;
                $go_ss3_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SS3', 'landing', array(), '/ss3');
                });

                $go_ss3_bucket_list = $jQuery('.go-ss3-bucket-list');
                $go_ss3_bucket_list->off() ;
                $go_ss3_bucket_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SS3', 'bucket-list', array(), '/ss3/bucket/list');
                });

                $go_ss3_bucket_create = $jQuery('.go-ss3-bucket-create');
                $go_ss3_bucket_create->off() ;
                $go_ss3_bucket_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SS3', 'bucket-create', array(), '/ss3/bucket/create');
                });

                $save_bucket = $jQuery('.attempt-ss3-bucket-create');
                $save_bucket->off() ;
                $save_bucket->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SS3', 'bucket-save-attempt', array());
                });

                $go_ss3_object_list = $jQuery('.go-ss3-object-list');
                $go_ss3_object_list->off() ;
                $go_ss3_object_list->on('click', function ($jsThis) use ($jQuery, $navigate) {
                    \ISOPHP\js_core::$console->log('Clicked object list button');
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;

                    $bucket_name = $jqThis->attr('data-ss3-bucket-name') ;
                    \ISOPHP\js_core::$console->log('$bucket_name');
                    \ISOPHP\js_core::$console->log($bucket_name);

                    $object_key = $jqThis->attr('data-ss3-object-key') ;
                    \ISOPHP\js_core::$console->log('$object_key');
                    \ISOPHP\js_core::$console->log($object_key);

                    $nav_ray = array(
                        'bucket_name' => $bucket_name
                    ) ;
                    if ($object_key !== null) {
                        $nav_ray['key'] = $object_key ;
                    }
                    $navigate->route('SS3', 'object-list', $nav_ray, '/ss3/'.$bucket_name.'/object/list') ;
                });

                $go_ss3_object_create = $jQuery('.go-ss3-object-create');
                $go_ss3_object_create->off() ;
                $go_ss3_object_create->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked object create button');
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $bucket_name = $jqThis->attr('data-ss3-bucket-name') ;
                    $object_creation_key = $jqThis->attr('data-ss3-object-creation-key') ;
                    $navigate->route('SS3', 'object-create', array(
                        'bucket_name' => $bucket_name,
                        'object_creation_key' => $object_creation_key,
                    ), '/ss3/'.$bucket_name.'/'.$object_creation_key.'/object/create');
                });

                $save_object = $jQuery('.attempt-ss3-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_key = $jqThis->attr('data-ss3-object-id') ;
                    $navigate->route('SS3', 'object-save-attempt', array(
                        'object_key' => $object_key,
                    ));
                });

                $download_object = $jQuery('.attempt-ss3-object-download');
                $download_object->off() ;
                $download_object->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked object download button');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    \ISOPHP\js_core::$console->log('jqthis object download ', $this_id, $jqThis);
                    $object_name = $jqThis->attr('data-ss3-object-name') ;
                    $object_id = $jqThis->attr('data-ss3-object-id') ;
                    $bucket_name = $jqThis->attr('data-ss3-bucket-name') ;
                    \ISOPHP\js_core::$console->log('object download data vars', $object_name, $object_id, $bucket_name);
                    $navigate->route('SS3', 'object-download-attempt', array(
                        'object_id' => $object_id,
                        'object_key' => $object_name,
                        'bucket_name' => $bucket_name,
                    ));
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-ss3-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-ss3-object-name') ;
                    $object_id = $jqThis->attr('data-ss3-object-id') ;
                    $bucket_name = $jqThis->attr('data-ss3-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg  = "You are about to delete the Object $object_name from the bucket $bucket_name. ";
                    $msg .= "This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $bucket_name) {
                        $navigate->route('SS3', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'bucket_name' => $bucket_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $bucket_delete = $jQuery('.attempt-ss3-bucket-delete');
                $bucket_delete->off() ;
                $bucket_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $bucket_name = $jqThis->attr('data-ss3-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked bucket delete button');
                    $msg = "You are about to delete the Bucket $bucket_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $bucket_name) {
                        $navigate->route('SS3', 'bucket-delete-attempt', array(
                            'bucket_name' => $bucket_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $ss3_bucket_paginator = $jQuery('.ss3-bucket-paginator');
                $ss3_bucket_paginator->off() ;
                $ss3_bucket_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked ss3 bucket paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SS3', 'bucket-list', array(
                        'page' => $new_page
                    ), '/ss3/bucket/list/page/'.$new_page);
                });

                $ss3_object_paginator = $jQuery('.ss3-object-paginator');
                $ss3_object_paginator->off() ;
                $ss3_object_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked ss3 object paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $bucket_name = $jQuery('#ss3-bucket-id')->val();
                    $jsthis->preventDefault();
                    $navigate->route('SS3', 'object-list', array(
                        'bucket_name' => $bucket_name,
                        'page' => $new_page
                    ), '/ss3/'.$bucket_name.'/object/list/page/'.$new_page);
                });

            }
        };
    }

    public function bindBucketListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding bucket switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding bucket switch');
            $switch_bucket = $jQuery('#bucket_name');
            $switch_bucket->off() ;
            $switch_bucket->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#bucket_name') ;
                $bucket_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked bucket switch');
                $navigate->route('SS3', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ));
            } ) ;

        };
        return $func;
    }

    public static function downloadToFile ($content, $filename, $contentType) {
        $a = \ISOPHP\js_core::$window->document->createElement('a');
        $content_array = [$content] ;
        $type_object = new \StdClass() ;
        $type_object->type = $contentType ;
        $file = new \ISOPHP\js_core::$window->Blob($content_array, $type_object);
        $a->href= \ISOPHP\js_core::$window->URL->createObjectURL($file);
        $a->download = $filename;
        $a->click();
        \ISOPHP\js_core::$window->URL->revokeObjectURL($a->href);
    }

}
