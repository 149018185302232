<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
        
            <div class="row">
             
                <div class="col-sm-12 col-12" >
                
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-balancedgroup-create" class="btn btn-success attempt-balancedgroup-create">Create</span>
                        <span id="go-sc1-balancedgroup-list" class="btn btn-warning go-sc1-balancedgroup-list">Balanced Group List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                
                    <div class="col-sm-12 col-12">
                        <h4>New Balanced Group</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <h5>Schedule Information</h5>
                        </div>
                    
                        <div class="row">
                        
                            <div class="col-sm-12 col-12">
                                <div class="form-group col-sm-4 col-4">
                                    <h6>Frequency:</h6>
                                </div>
                                <div class="form-group col-sm-4 col-4">
                                    <label>Once</label>
                                    <input type="radio" name="once_repeat" class="form-control" id="once_repeat" value="once" checked="checked" />
                                </div>
                                <div class="form-group col-sm-4 col-4">
                                    <label>Repeated</label>
                                    <input type="radio" name="once_repeat" class="form-control" id="once_repeat" value="repeat" />
                                </div>
                            </div>
                        
                            <div class="col-sm-12 col-12">
                                <div class="form-group col-sm-4 col-4">
                                    <h6>Schedule Type:</h6>
                                </div>
                                <div class="form-group col-sm-4 col-4">
                                    <label>By Date</label>
                                    <input type="radio" name="by_date_cron" class="form-control" id="by_date_cron" value="date" checked="checked" />
                                </div>
                                <div class="form-group col-sm-4 col-4">
                                    <label>By Cron</label>
                                    <input type="radio" name="by_date_cron" class="form-control" id="by_date_cron" value="cron" />
                                </div>
                            </div>
                        
                            <div class="col-sm-12 col-12">

                                <div class="col-sm-12 col-12">
                                    <h5>Date Fields</h5>
                                </div>

                                <div class="col-sm-12 col-12">
                                    <label>Start Time</label>
                                    <input type="text" name="start_time" class="form-control scheduled-instance-datepicker-dropdown" id="start_time" value="" />
                                </div>
        
                                <div class="col-sm-12 col-12">
                                    <label>End Time</label>
                                    <input type="text" name="end_time" class="form-control scheduled-instance-datepicker-dropdown" id="end_time" value="" />
                                </div>
                                
                            </div>
                        
                            <div class="col-sm-12 col-12">

                                <div class="col-sm-12 col-12">
                                    <h5>Cron Fields</h5>
                                </div>

                                <div class="col-sm-12 col-12">
                                    <label>Crontab</label>
                                    <input type="text" name="crontab" class="form-control" id="crontab" value="" />
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <h5>Instance Information</h5>
                        </div>
                
                        <div class="col-sm-12 col-12">
                            <div class="form-group col-sm-4 col-4">
                                <h6>Instance Settings:</h6>
                            </div>
                            <div class="form-group col-sm-4 col-4">
                                <label>Launch Template</label>
                                <input type="radio" name="instance_settings_type" class="form-control" id="instance_settings_type" value="launch_template" checked="checked" />
                            </div>
                            <div class="form-group col-sm-4 col-4">
                                <label>Custom</label>
                                <input type="radio" name="instance_settings_type" class="form-control" id="instance_settings_type" value="custom" />
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12 row instance_settings_type_launch_template_wrap">
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Instance Count</label>
                                    <input type="text" name="instance_count" class="form-control" id="instance_count" value="" />
                                </div>
                            </div>
                        
                        </div>
                    
                        <div class="col-sm-12 col-12 row instance_settings_type_launch_template_wrap">
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Launch Template</label>
                                    <div id="sc1_launchtemplate_dropdown">
                                        <select class="form-control" name="launchtemplate_id" id="launchtemplate_id">
                                            <option value="none">Loading Launch Templates</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                        

                        <div class="col-sm-12 col-12 row instance_settings_type_custom_wrap">
                        
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Instance Count</label>
                                    <input type="text" name="instance_count" class="form-control" id="instance_count" value="" />
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Size</label>
                                    <div id="sc1_size_dropdown">
                                        <select class="form-control" name="size_id" id="size_id">
                                            <option>Loading Sizes</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Image</label>
                                    <div id="sc1_image_dropdown">
                                        <select class="form-control" name="image_id" id="image_id">
                                            <option>Loading Images</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" class="form-control" id="name" value="" />
                                </div>
                            </div>
                            
                            <div class="col-sm-12 col-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea name="description" id="description" class="form-control"></textarea>
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-balancedgroup-create" class="btn btn-success attempt-balancedgroup-create">Create</span>
                        <span id="go-sc1-balancedgroup-list" class="btn btn-warning go-sc1-balancedgroup-list">Balanced Group List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                    
                </div>
                
            </div>
            
        </div>';

    return $html;


};
