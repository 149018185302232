<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $buckets_are_array = \ISOPHP\core::$php->is_array($rqd['buckets']) ;
    if ($buckets_are_array === true) {
        $buckets_exist = true ;
    } else {
        $buckets_exist = false ;
    }
    if ($buckets_exist === true) {
        $bucket_count = \ISOPHP\core::$php->count($rqd['buckets']) ;
    } else {
        $bucket_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-generativeai-bucket-create" class="btn btn-success go-generativeai-bucket-create">New Bucket</span>
            <span id="go-generativeai-landing" class="btn btn-info go-generativeai-landing">GenerativeAI Home</span>
            <span id="go-generativeai-bucket-list" class="btn-refresh btn btn-info go-generativeai-bucket-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Bucket List</h4>
        </div> ' ;

    if ($buckets_exist === true && $bucket_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'generativeai-bucket-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <h5 class="generativeai_bucket_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="generativeai_bucket_id_title">Name</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="generativeai_bucket_id_title">Description</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="generativeai_bucket_id_title">Modified</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="generativeai_bucket_id_title">Scope</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="generativeai_bucket_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['buckets'] as $one_generativeai_bucket) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_generativeai_bucket">
                    <div class="col-sm-2 col-2">
                        <p class="generativeai_list_id"><span class="generativeai_id generativeai_listing">' . $one_generativeai_bucket['generativeai_bucket_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2" style="overflow-x: hidden">
                        <p class="generativeai_list_name"><span class="generativeai_name generativeai_listing">' . $one_generativeai_bucket['generativeai_bucket_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2" style="overflow-x: hidden">
                        <p class="generativeai_list_comment generativeai_listing">' . $one_generativeai_bucket['description'] . '</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="generativeai_list_name"><span class="generativeai_name generativeai_listing">' ;

            $html = $html . $one_generativeai_bucket['creation_date_friendly'] ;

            $html = $html .
                '        </span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="generativeai_list_name"><span class="generativeai_name generativeai_listing">' ;
                    if ($one_generativeai_bucket['public'] === true) {
                        $html = $html . 'Public' ;
                    } else {
                        $html = $html . 'Private' ;
                    }
            $html = $html .
                '        </span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="upload_generativeai_bucket_' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   class="fa fa-1x fa-upload hvr-grow dom go-generativeai-object-create btn btn-outline-success"
                                   data-generativeai-bucket-id="' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   data-generativeai-bucket-name="' . $one_generativeai_bucket['generativeai_bucket_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="list_generativeai_bucket_' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   class="fa fa-1x fa-bars hvr-grow dom go-generativeai-object-list btn btn-outline-primary"
                                   data-generativeai-bucket-id="' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   data-generativeai-bucket-name="' . $one_generativeai_bucket['generativeai_bucket_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_generativeai_bucket_' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-generativeai-bucket-delete btn btn-outline-danger"
                                   data-generativeai-bucket-id="' . $one_generativeai_bucket['generativeai_bucket_id'] . '"
                                   data-generativeai-bucket-name="' . $one_generativeai_bucket['generativeai_bucket_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'generativeai-bucket-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Storage Buckets</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-generativeai-bucket-create" class="btn btn-success go-generativeai-bucket-create">New Bucket</span>
                <span id="go-generativeai-landing" class="btn btn-info go-generativeai-landing">GenerativeAI Home</span>
                <span id="go-generativeai-bucket-list" class="btn-refresh btn btn-info go-generativeai-bucket-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;


    return $html;

};
