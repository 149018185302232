<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $commits_are_array = \ISOPHP\core::$php->is_array($rqd['commits']) ;
    if ($commits_are_array === true) {
        $commits_exist = true ;
    } else {
        $commits_exist = false ;
    }
    if ($commits_exist === true) {
        $commit_count = \ISOPHP\core::$php->count($rqd['commits']) ;
    } else {
        $commit_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
            <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">Repositories</span>
            <span id="go-scm-commit-list" class="btn-refresh btn btn-info go-scm-commit-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Repository Charts</h4>
        </div> 
        
        <div class="col-sm-12 col-12">
        
        
                <div class="row col-sm-12">

                    <div class="col-sm-4 hvr-pop">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="row col-sm-12">
                                    <div class="col-xs-3">
                                        <i class="fa fa-check-circle fa-4x hvr-buzz-out"></i>
                                    </div>
                                    <div class="col-xs-9 text-right">
                                        <div class="chart_feature_text huge hvr-grow-rotate">
                                            '.$rqd['charts']['statistics']["Total commits"].'
                                        </div>
                                        <div>Commits</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 hvr-pop">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="row col-sm-12">
                                    <div class="col-xs-3">
                                        <i class="fa fa-database fa-4x hvr-buzz-out"></i>
                                    </div>
                                    <div class="col-xs-9 text-right">
                                        <div class="chart_feature_text huge hvr-grow-rotate" >
                                            '.$rqd['charts']['statistics']["Average commits per day"].'
                                        </div>
                                        <div>Daily</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 hvr-pop">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="row col-sm-12">
                                    <div class="col-xs-12 text-center">
                                        <div class="huge hvr-grow-rotate">
                                            '.$rqd['charts']['statistics']["Active for"].'
                                        </div>
                                        <div>Days Active</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row col-sm-12">
                    <div class="col-sm-12">
                        <div class="panel">
                            <div id="chart-commits-by-date" class="chart" style="width:100%"></div>
                        </div>
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="row col-sm-12">
                                    <div class="col-xs-6 text-center">
                                        <div class="huge hvr-grow-rotate">
                                            '.$rqd['charts']['statistics']["First commit date"].'
                                        </div>
                                        <div>First commit date</div>
                                    </div>
                                    <div class="col-xs-6 text-center">
                                        <div class="huge hvr-grow-rotate">
                                            '.$rqd['charts']['statistics']["Latest commit date"].'
                                        </div>
                                        <div>Latest commit date</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-sm-12">
                    <div class="col-sm-12">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="huge hvr-grow-rotate">
                                    Activity by Hour
                                </div>
                            </div>
                        </div>
                        <div class="panel">
                            <div id="chart-commits-by-hour" class="chart hour" style="width:100%"></div>
                        </div>
                    </div>
                </div>

                <div class="row col-sm-12">
                    <div class="col-sm-12">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="huge hvr-grow-rotate">
                                    Activity by Weekday
                                </div>
                            </div>
                        </div>
                        <div class="panel">
                            <div id="chart-commits-by-day" class="chart" style="width:100%"></div>
                        </div>
                    </div>
                </div>

                <div class="row col-sm-12">
                    <div class="col-sm-12">
                        <div class="panel panel-green">
                            <div class="panel-heading">
                                <div class="huge hvr-grow-rotate">
                                    Contributors
                                </div>
                            </div>
                        </div> ' ;

                        foreach ($rqd['charts']['charts']['contributor'] as $contributor) {

                            $html = $html .
                                '
                            <div class="col-md-6">
                                <div class="thumbnail">
                                    <h4>'.$contributor['name'].'<br />
                                        <small>
                                            '.$contributor['email'].'
                                        </small>
                                    </h4>
                                    <h5>
                                        '.$contributor['commits'].'
                                    </h5>
                                    <div class="chart" style="height: 200px; width: 100%">

                                    </div>
                                </div>
                            </div>';

                        }
    $html = $html .
        '
                    </div>
                </div>
        
        </div> ' ;



    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
                <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">Repositories</span>
                <span id="go-scm-commit-list" class="btn-refresh btn btn-info go-scm-commit-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    $html = $html .
        '
<script src="/app/SCM/Assets/js/Highcharts-7.0.3/code/highcharts.js"></script>
<link rel="stylesheet" type="text/css" href="' . CSS_ASSET_PREFIX . 'app/SCM/Assets/css/repositorycharts.css">
<link rel="stylesheet" type="text/css" href="' . CSS_ASSET_PREFIX . 'app/SCM/Assets/css/repositoryhistory.css">

<script type="text/javascript">
    var chart_data = '.\ISOPHP\core::$php->json_encode($rqd['charts']['charts']).'  ;
    console.log("chart data", chart_data) ;
</script>

<script src="/app/SCM/Assets/js/repositorycharts.js"></script>' ;

    return $html;

};