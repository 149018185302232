<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Billing, you can manage your Invoices Here.</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Storage For Web Based Payment Methods and Invoices</h3>
                    <p>Simple and Secure Payments</p>            
                </div>
                
                <div class="col-sm-12">
                    <div class="col-sm-6">
                        <span id="go-billing-paymentmethod-list" class="go-billing-paymentmethod-list btn btn-lg btn-warning">Payment Methods</span>
                    </div>        
                    <div class="col-sm-6">
                        <span id="go-billing-paymentmethod-create" class="go-billing-paymentmethod-create btn btn-lg btn-warning">New Payment Method</span>
                    </div>
                </div>
                
                <div class="col-sm-12">
                    <div class="col-sm-6">
                        <span id="go-billing-invoice-list" class="go-billing-invoice-list btn btn-lg btn-warning">Invoices</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-billing-invoice-create" class="go-billing-invoice-create btn btn-lg btn-warning">Pay Invoice</span>
                    </div>        
                </div>
                
                <div class="col-sm-12">
                    <div class="col-sm-6">
                        <span id="go-billing-usage-history" class="go-billing-usage-history btn btn-lg btn-warning">Usage History</span>
                    </div>
                    <div class="col-sm-6">
                        &nbsp;
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;
