<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $instances_are_array = \ISOPHP\core::$php->is_array($rqd['instances']) ;
    if ($instances_are_array === true) {
        $instances_exist = true ;
    } else {
        $instances_exist = false ;
    }
    if ($instances_exist === true) {
        $instance_count = \ISOPHP\core::$php->count($rqd['instances']) ;
    } else {
        $instance_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-mindstorm-instance-create" class="btn btn-success go-mindstorm-instance-create">New Instance</span>
            <span id="go-mindstorm-landing" class="btn btn-info go-mindstorm-landing">Mindstorm Home</span>
            <span id="go-mindstorm-instance-list" class="btn btn-info go-mindstorm-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Database Instances</h4>
         </div> ' ;
    $html = $html .
       '<div class="col-sm-12 col-12 one_mindstorm_instance">
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">ID</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">Name</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">Status</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">Region</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">DNS</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="mindstorm_instance_id_title">Edit</h5>
            </div>
        </div>';

    if ($instances_exist === true && $instance_count !== false) {

        foreach ($rqd['instances'] as $one_mindstorm_instance) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_mindstorm_instance">
                    <div class="col-sm-2 col-2">
                        <p class="mindstorm_list_id"><span class="mindstorm_id mindstorm_listing">' . $one_mindstorm_instance['dbid'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="mindstorm_list_name"><span class="mindstorm_name mindstorm_listing"><strong>' . $one_mindstorm_instance['engine'] . ': </strong>'. $one_mindstorm_instance['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="mindstorm_list_status">' . $one_mindstorm_instance['status'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <p class="mindstorm_list_region">' . $one_mindstorm_instance['region'] . '</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="mindstorm_list_dns">' . $one_mindstorm_instance['dns_string'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_mindstorm_instance_' . $one_mindstorm_instance['instanceid'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-instance-edit btn btn-outline-primary"
                                   data-mindstorm-instance-id="' . $one_mindstorm_instance['instanceid'] . '"
                                   data-mindstorm-instance-name="' . $one_mindstorm_instance['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_mindstorm_instance_' . $one_mindstorm_instance['instanceid'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-instance-delete btn btn-outline-danger"
                                   data-mindstorm-instance-id="' . $one_mindstorm_instance['instanceid'] . '"
                                   data-mindstorm-instance-name="' . $one_mindstorm_instance['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Database Instances</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-mindstorm-instance-create" class="btn btn-success go-mindstorm-instance-create">New Instance</span>
            <span id="go-mindstorm-landing" class="btn btn-info go-mindstorm-landing">Mindstorm Home</span>
            <span id="go-mindstorm-instance-list" class="btn btn-info go-mindstorm-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing Mindstorm Instances</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_instance_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_instance_awscli"></i></h5>
                    <pre id="instruction_set_list_instance_awscli" class="instruction_set instruction_set_list_instance_awscli">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.instance:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             
             </div>';


    return $html;

};
