<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Database, your Relational Database Service</h1>
            </div>
        
            <div class="col-sm-12 row-margin-top">
                
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Databases</h3>
                        <p>Our Compute service provides Virtual Machines in a range on configurations.</p>            
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-database-instance-list" class="go-database-instance-list btn btn-lg btn-warning landing-label">Instances</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-database-image-list" class="go-database-image-list btn btn-lg btn-warning landing-label">Images</span>
                        </div>
                        <!--
                        <div class="col-sm-6">
                            <span id="go-database-query-list" class="go-database-query-list btn btn-lg btn-warning landing-label disabled">Queries</span>
                        </div>
                        -->
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-database-performancechart-list" class="go-database-performancechart-list btn btn-lg btn-warning landing-label disabled">Performance Charts</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-database-size-list" class="go-database-size-list btn btn-lg btn-warning landing-label">Sizes</span>
                        </div>
                    </div>
                    
                    <!--
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-database-reservation-list" class="go-database-reservation-list btn btn-lg btn-warning landing-label disabled">Reservations</span>
                        </div>
                    </div>
                    -->
                    
                </div>
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Storage</h3>
                        <p>Storage for Disaster Recovery</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-database-backup-list" class="btn btn-lg btn-warning go-database-backup-list landing-label disabled">Backups</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-database-snapshot-list" class="btn btn-lg btn-warning go-database-snapshot-list landing-label">Snapshots</span>
                        </div>                                      
                    </div>
                    
                </div>
                
            </div> ';


//    $html = $html . '
//            <div class="col-sm-12 row-margin-top">
//
//                <div class="col-sm-6">
//
//                    <div class="col-sm-12">
//                        <h3>Networking &amp; Security</h3>
//                        <p>Providing Control over the networks in which your resources reside.</p>
//                    </div>
//
//                    <div class="col-sm-12 landing-row">
//                        <div class="col-sm-4">
//                            <span id="go-database-subnetgroup-list" class="go-database-subnetgroup-list btn btn-lg btn-warning landing-label disabled">Subnet Groups</span>
//                        </div>
//                        <div class="col-sm-4">
//                            <span id="go-database-parametergroup-list" class="go-database-parametergroup-list btn btn-lg btn-warning landing-label disabled">Parameter Groups</span>
//                        </div>
//                        <div class="col-sm-4">
//                            <span id="go-database-optiongroup-list" class="go-database-optiongroup-list btn btn-lg btn-warning landing-label disabled">Option Groups</span>
//                        </div>
//                    </div>
//
//                </div>
//
//                <div class="col-sm-6">
//                    &nbsp;
//                </div>
//
//            </div>
//
//        </div>';

    return $html ;

} ;