<?php

Namespace Model\Billing;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Billing List of Piranha Web Services';
        $page['heading'] = 'List of Billing';
        \ISOPHP\js_core::$console->log('Billing Mod', $page, $pageVars);
        return $page;
    }

    public function showBillingLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BillingView();
            $view->loadTemplate('Billing', 'BillingLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayBilling($bind);
            \ISOPHP\js_core::$console->log('apply Billing PaymentMethod Create 2', $vars);
        };
    }

    public function showBillingPaymentMethodCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BillingView();
            $view->loadTemplate('Billing', 'PaymentMethodCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayBilling($bind);
            \ISOPHP\js_core::$console->log('apply Billing PaymentMethod Create 2', $vars);
        };
    }

    public function showBillingPaymentMethodList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing List');
            $vars['control'] = 'Billing';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/billing/paymentmethod/all';
            $afterwards = $this->applyBillingPaymentMethodListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Billing Model: ', $result);
            return $result;
        };
    }

    public function applyBillingPaymentMethodListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Billing PaymentMethod List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Billing PaymentMethod List 1', $vars);
                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'PaymentMethodList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBilling($bind);
                \ISOPHP\js_core::$console->log('apply Billing PaymentMethod List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Billing PaymentMethod List failed');
            }
        };
        return $func;
    }

    public function showBillingPaymentMethodListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing List');
            $vars['control'] = 'Billing';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/billing/paymentmethod/all';
            $afterwards = $this->applyBillingPaymentMethodListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Billing Model: ', $result);
            return $result;
        };
    }

    public function applyBillingPaymentMethodListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Billing PaymentMethod List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Billing PaymentMethod List 1', $vars);
                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'PaymentMethodListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayBillingPaymentMethodDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Billing PaymentMethod List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Billing PaymentMethod List failed');
            }
        };
        return $func;
    }

    public function showBillingInvoiceCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BillingView();
            $view->loadTemplate('Billing', 'InvoiceCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayBilling($bind);
            \ISOPHP\js_core::$console->log('apply Billing Invoice Create 2', $vars);
        };
    }

    public function showBillingInvoiceList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for paymentmethod name');
            \ISOPHP\js_core::$console->log($pageVars['paymentmethod_name']);
            \ISOPHP\js_core::$console->log('Showing Billing List');
            $vars['control'] = 'Billing';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/billing/invoice/all';
            $afterwards = $this->applyBillingInvoiceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Billing Model: ', $result);
            return $result;
        };
    }

    public function applyBillingInvoiceListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Billing Invoice List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Billing Invoice List 1', $vars);
                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'InvoiceList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBilling($bind);
                \ISOPHP\js_core::$console->log('apply Billing Invoice List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Billing Invoice List failed');
            }
        };
        return $func;
    }

    public function showBillingUsageHistory($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for paymentmethod name');
            \ISOPHP\js_core::$console->log($pageVars['paymentmethod_name']);
            \ISOPHP\js_core::$console->log('Showing Billing Usage History');
            $vars['control'] = 'Billing';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/billing/history/all';
            $afterwards = $this->applyBillingUsageHistoryResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Billing Model: ', $result);
            return $result;
        };
    }

    public function applyBillingUsageHistoryResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Billing Usage History result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Billing Usage History 1', $vars);
                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'UsageHistory.php', $vars);
                $bind = self::bindButtons();
                $view->displayBilling($bind);
                \ISOPHP\js_core::$console->log('apply Billing Usage History 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Billing Usage History failed');
            }
        };
        return $func;
    }

    public function showBillingInvoiceChargeableItemsList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Billing Invoice Chargeable items List');
            $vars['control'] = 'Billing';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/billing/invoice/chargeableitems/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applyBillingInvoiceChargeableItemsListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Billing Model: ', $result);
            return $result;
        } ;
    }

    public function applyBillingInvoiceChargeableItemsListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Billing Invoice Chargeable Item List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Billing Invoice Chargeable Item List 1', $vars);
                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'InvoiceChargeableItemList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBillingChargeableItemsPage($bind);
                \ISOPHP\js_core::$console->log('apply Billing Invoice Chargeable Item List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Billing Invoice Chargeable Item List failed');
            }
        };
        return $func;
    }


    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save paymentmethod
     */
    public function savePaymentMethodAttemptToBilling($pageVars)
    {
        \ISOPHP\js_core::$console->log('save paymentmethod to billing');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Billing';
        $vars['action'] = 'paymentmethod-create';
        $vars['api_uri'] = '/api/billing/paymentmethod/create';
        $vars['name'] = $jQuery('#name')->val();
        $vars['name_on_card'] = $jQuery('#$name_on_card')->val();
        $vars['card_number'] = $jQuery('#card_number')->val();
        $vars['cvv'] = $jQuery('#cvv')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['billing_address'] = $jQuery('#billing_address')->val();
        $vars['postcode'] = $jQuery('#postcode')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePaymentMethodResultFromBilling($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function savePaymentMethodResultFromBilling($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save billing paymentmethod result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created PaymentMethod '.$request_data['paymentmethod']['billing_paymentmethod_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('billing paymentmethod saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating PaymentMethod '.$request_data['paymentmethod']['billing_paymentmethod_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('billing paymentmethod save failed');
            }
        };
        return $func;
    }

    /**
     * Save invoice
     */
    public function saveInvoiceAttemptToBilling($pageVars)
    {
        \ISOPHP\js_core::$console->log('save invoice to billing');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Billing';
        $vars['action'] = 'invoice-create';
        $vars['api_uri'] = '/api/billing/invoice/create';
        $vars['paymentmethod_name'] = $jQuery('#paymentmethod_name')->val();
        $vars['invoice_name'] = $jQuery('#invoice_name')->val();
        $vars['invoice_value'] = $jQuery('#invoice_value')->val();
        $vars['invoice_type'] = $jQuery('#invoice_type')->val();
        \ISOPHP\js_core::$console->log('save invoice vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveInvoiceResultFromBilling($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveInvoiceResultFromBilling($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save billing invoice result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Invoice '.$request_data['invoice']['billing_invoice_name'].' for PaymentMethod '.$request_data['invoice']['billing_paymentmethod_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('billing invoice saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Invoice '.$request_data['invoice']['billing_paymentmethod_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('billing invoice save failed');
            }
        };
        return $func;
    }
    /**
     * Delete invoice
     */
    public function deleteInvoiceAttemptToBilling($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete invoice to billing');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Billing';
        $vars['action'] = 'invoice-delete';
        $vars['api_uri'] = '/api/billing/invoice/delete';
        $vars['paymentmethod_name'] = $pageVars['params']['paymentmethod_name'];
        $vars['invoice_key'] = $pageVars['params']['invoice_key'];
        $vars['invoice_id'] = $pageVars['params']['invoice_id'];
        \ISOPHP\js_core::$console->log('delete invoice vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveInvoiceResultFromBilling($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteInvoiceResultFromBilling($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete billing invoice result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Invoice '.$request_data['invoice']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('billing invoice deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Invoice '.$request_data['invoice']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('billing invoice delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete paymentmethod
     */
    public function deletePaymentMethodAttemptToBilling($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete paymentmethod to billing');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Billing';
        $vars['action'] = 'paymentmethod-delete';
        $vars['api_uri'] = '/api/billing/paymentmethod/delete';
        $vars['id'] = $pageVars['params']['id'];
        $vars['name'] = $pageVars['params']['name'];
        \ISOPHP\js_core::$console->log('delete paymentmethod vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deletePaymentMethodResultFromBilling($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deletePaymentMethodResultFromBilling($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete billing paymentmethod result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted PaymentMethod '.$request_data['paymentmethod']['name'].' for PaymentMethod '.$request_data['paymentmethod']['billing_paymentmethod_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('billing paymentmethod deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting PaymentMethod '.$request_data['paymentmethod']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('billing paymentmethod delete failed');
            }
            $show_paymentmethod_list = self::showBillingPaymentMethodList($pageVars) ;
            $show_paymentmethod_list() ;
        };
        return $func;
    }

    public static function showBillingSingleInvoiceDetails($invoice_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing Billing Single Invoice');
            $vars['control'] = 'Billing';
            $vars['action'] = 'invoice-one';
            $vars['api_uri'] = '/api/billing/invoice/one';
            $vars['invoice_id'] = $invoice_id;
            $afterwards = self::applyBillingSingleInvoiceResult($invoice_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        }
    }

    public static function applyBillingSingleInvoiceResult($invoice_id) {
        $func = function ($response_data) use ($invoice_id) {
            \ISOPHP\js_core::$console->log('applying our SCM Single Invoice result to', $response_data);
            if ($response_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $response_data;
                $vars['invoice_id'] = $invoice_id ;
                \ISOPHP\js_core::$console->log('apply Billing Single invoice data 2', $vars);

                $view = new \View\BillingView();
                $view->loadTemplate('Billing', 'SingleInvoiceDetails.php', $vars);
                $bind = self::bindButtons();
                $view->displayBillingSingleInvoiceDetails($bind);
                \ISOPHP\js_core::$console->log('apply Billing Single invoice List 2', $vars);

                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;

            }
            if ($response_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Single repository List failed');
            }
        };
        return $func;
    }

    public function downloadCSVInvoiceAttemptFromBilling($pageVars) {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $invoice_id = $pageVars['params']['invoice_id'] ;
        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $token = $cookies['user_tokens'][0]['data'] ;
        }
        \ISOPHP\js_core::$console->log('download csv dump PageVars', $pageVars);
        $config = new \Model\Configuration() ;
        $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
        $server_url .= '/api/billing/invoice/csv' ;
        $server_url .= '?token='.$token ;
        $server_url .= '&invoice_id='.$invoice_id ;
        $dl_el_id = 'download_link_billing_csv_'.$invoice_id ;
        $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download></a>' ;
        \ISOPHP\js_core::$console->log('', $html);
        $body_jq = $jQuery('body') ;
        $body_jq->append($html) ;
        $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
        $dl_el_js->click() ;
    }

    public function downloadPDFInvoiceAttemptFromBilling($pageVars) {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $invoice_id = $pageVars['params']['invoice_id'] ;
        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $token = $cookies['user_tokens'][0]['data'] ;
        }
        \ISOPHP\js_core::$console->log('download pdf dump PageVars', $pageVars);
        $config = new \Model\Configuration() ;
        $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
        $server_url .= '/api/billing/invoice/pdf' ;
        $server_url .= '?token='.$token ;
        $server_url .= '&invoice_id='.$invoice_id ;
        $dl_el_id = 'download_link_billing_pdf_'.$invoice_id ;
        $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download></a>' ;
        \ISOPHP\js_core::$console->log('', $html);
        $body_jq = $jQuery('body') ;
        $body_jq->append($html) ;
        $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
        $dl_el_js->click() ;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_billing_landing = $jQuery('.go-billing-landing');
                $go_billing_landing->off() ;
                $go_billing_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'landing', array(), '/billing');
                });

                $go_billing_paymentmethod_list = $jQuery('.go-billing-paymentmethod-list');
                $go_billing_paymentmethod_list->off() ;
                $go_billing_paymentmethod_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'paymentmethod-list', array(), '/billing/paymentmethod/list');
                });

                $go_billing_paymentmethod_create = $jQuery('.go-billing-paymentmethod-create');
                $go_billing_paymentmethod_create->off() ;
                $go_billing_paymentmethod_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'paymentmethod-create', array(), '/billing/paymentmethod/create');
                });

                $save_paymentmethod = $jQuery('.attempt-paymentmethod-create');
                $save_paymentmethod->off() ;
                $save_paymentmethod->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'paymentmethod-save-attempt', array());
                });

                $go_billing_invoice_list = $jQuery('.go-billing-invoice-list');
                $go_billing_invoice_list->off() ;
                $go_billing_invoice_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'invoice-list', array(), '/billing/invoice/list');
                });

                $go_billing_usage_history = $jQuery('.go-billing-usage-history');
                $go_billing_usage_history->off() ;
                $go_billing_usage_history->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'usage-history', array(), '/billing/usage/history');
                });

                $go_sc1_billing_invoice_chargeableitem_list_page = $jQuery('.billing-invoice-chargeableitem-paginator');
                $go_sc1_billing_invoice_chargeableitem_list_page->off() ;
                $go_sc1_billing_invoice_chargeableitem_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked billing_invoice_chargeableitem paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('Billing', 'invoice-chargeableitem-list', array('page' => $new_page), '/billing/invoice/chargeableitem/list/page/'.$new_page);
                });

                $go_sc1_billing_invoice_chargeableitem_list_page_direct = $jQuery('.paginator_direct_page');
                $go_sc1_billing_invoice_chargeableitem_list_page_direct->off() ;
                $go_sc1_billing_invoice_chargeableitem_list_page_direct->on('change', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Changed variable billing_invoice_chargeableitem paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->val() ;
                    $invalid_pages = array(0, null) ;
                    if (\ISOPHP\core::$php->in_array($new_page, $invalid_pages)) {
                        $new_page = 1 ;
                    }
                    $jsthis->preventDefault();
                    $navigate->route('Billing', 'invoice-chargeableitem-list', array('page' => $new_page), '/billing/invoice/chargeableitem/list/page/'.$new_page);
                });

                $go_billing_invoice_create = $jQuery('.go-billing-invoice-create');
                $go_billing_invoice_create->off() ;
                $go_billing_invoice_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'invoice-create', array(), '/billing/invoice/create');
                });

                $save_invoice = $jQuery('.attempt-invoice-create');
                $save_invoice->off() ;
                $save_invoice->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Billing', 'invoice-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $invoice_delete = $jQuery('.attempt-invoice-delete');
                $invoice_delete->off() ;
                $invoice_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $invoice_name = $jqThis->attr('data-billing-invoice-name') ;
                    $invoice_id = $jqThis->attr('data-billing-invoice-id') ;
                    $paymentmethod_name = $jqThis->attr('data-billing-paymentmethod-name') ;
                    \ISOPHP\js_core::$console->log('Clicked invoice delete button');
                    $msg = "You are about to delete the Invoice for $invoice_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $invoice_id, $invoice_name, $paymentmethod_name) {
                        $navigate->route('Billing', 'invoice-delete-attempt', array(
                            'invoice_id' => $invoice_id,
                            'invoice_name' => $invoice_name,
                            'paymentmethod_name' => $paymentmethod_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $paymentmethod_delete = $jQuery('.attempt-paymentmethod-delete');
                $paymentmethod_delete->off() ;
                $paymentmethod_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $paymentmethod_name = $jqThis->attr('data-billing-paymentmethod-name') ;
                    \ISOPHP\js_core::$console->log('Clicked paymentmethod delete button');
                    $msg = "You are about to delete the PaymentMethod $paymentmethod_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $paymentmethod_name) {
                        $navigate->route('Billing', 'paymentmethod-delete-attempt', array(
                            'paymentmethod_name' => $paymentmethod_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $repository_info = $jQuery('.attempt-billing-single-invoice');
                $repository_info->off() ;
                $repository_info->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $invoice_id = $jqThis->attr('data-billing-invoice-id') ;
                    \ISOPHP\js_core::$console->log('Clicked invoice info button');
                    $msg = "Details for Invoice: $invoice_id";
                    $data  = '' ;
                    $data .= '<div class="col-sm-12" id="billing_single_invoice_details">' ;
                    $data .= '  <h3>Loading ...</h3>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
                    self::showBillingSingleInvoiceDetails($invoice_id) ;
                } ) ;

                $download_csvinvoice = $jQuery('.attempt-billing-csvinvoice-download');
                $download_csvinvoice->off() ;
                $download_csvinvoice->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked csvinvoice download button');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $invoice_id = $jqThis->attr('data-billing-invoice-id') ;
                    $navigate->route('Billing', 'csvinvoice-download-attempt', array(
                        'invoice_id' => $invoice_id
                    ));
                });

                $download_pdfinvoice = $jQuery('.attempt-billing-pdfinvoice-download');
                $download_pdfinvoice->off() ;
                $download_pdfinvoice->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked pdfinvoice download button');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $invoice_id = $jqThis->attr('data-billing-invoice-id') ;
                    $navigate->route('Billing', 'pdfinvoice-download-attempt', array(
                        'invoice_id' => $invoice_id
                    ));
                });

            }
        };
    }

    public function bindPaymentMethodListDropDownButtonsForInvoiceList()
    {
        \ISOPHP\js_core::$console->log('Started binding paymentmethod switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding paymentmethod switch');
            $switch_paymentmethod = $jQuery('#paymentmethod_name');
            $switch_paymentmethod->off() ;
            $switch_paymentmethod->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#paymentmethod_name') ;
                $paymentmethod_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked paymentmethod switch');
                $navigate->route('Billing', 'invoice-list', array(
                    'paymentmethod_name' => $paymentmethod_name,
                ));
            } ) ;

        };
        return $func;
    }



}
