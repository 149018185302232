<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $objects_are_array = \ISOPHP\core::$php->is_array($rqd['objects']) ;
    if ($objects_are_array === true) {
        $objects_exist = true ;
    } else {
        $objects_exist = false ;
    }
    if ($objects_exist === true) {
        $object_count = \ISOPHP\core::$php->count($rqd['objects']) ;
    } else {
        $object_count = false ;
    }

    $html = $html .
       '<div class="col-sm-12 col-12 text-center">
            <span id="go-drive-object-create" class="btn btn-success go-drive-object-create"
                  data-drive-directory-id="'.$rqd['directory'].'"
                  data-drive-directory-name="'.$rqd['directory'].'" >New Object</span>
            <span id="go-drive-landing" class="btn btn-warning go-drive-landing">Drive Home</span>
            <span id="go-drive-directory-list" class="btn btn-warning go-drive-directory-list">Directory List</span>
            <span id="go-drive-object-list"
                  class="btn btn-info go-drive-object-list"
                  data-drive-directory-id="'.$rqd['directory'].'"
                  data-drive-directory-name="'.$rqd['directory'].'" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Object List: <strong>'.$rqd['directory'].'</strong></h4>
        </div> ' ;

    if ($objects_exist === true && $object_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_drive_object">
                        <div class="col-sm-3 col-3">
                            <h5 class="drive_object_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="drive_object_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="drive_object_table_title">Size</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="drive_object_table_title">Modified</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="drive_object_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['objects'] as $one_drive_object) {

            $dotless_name = \ISOPHP\core::$php->str_replace('.', '', $one_drive_object['name']) ;
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-3 col-3">
                        <p class="drive_list_directory_name"><span class="drive_directory_name drive_listing">' . $one_drive_object['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_object_type"><span class="drive_type drive_listing">' . $one_drive_object['type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_id"><span class="drive_id drive_listing">' . $one_drive_object['size_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_id"><span class="drive_id drive_listing">' . $one_drive_object['last_modified'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="download_drive_object_' . $rqd['directory'] . '_' . $dotless_name . '"
                                   class="fa fa-1x fa-download hvr-grow dom attempt-drive-object-download btn btn-outline-primary"
                                   data-drive-object-id="' . $one_drive_object['id'] . '"
                                   data-drive-object-name="' . $one_drive_object['name'] . '"
                                   data-drive-directory-name="' . $rqd['directory'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_drive_object_' . $dotless_name . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-drive-object-delete btn btn-outline-danger"
                                   data-drive-object-id="' . $one_drive_object['id'] . '"
                                   data-drive-object-name="' . $one_drive_object['name'] . '"
                                   data-drive-directory-name="' . $rqd['directory'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div> ';
        }

        $html = $html .
               '<div class="col-sm-12 col-12 text-center">
                    <span id="go-drive-object-create" class="btn btn-success go-drive-object-create"
                          data-drive-directory-id="'.$rqd['directory'].'"
                          data-drive-directory-name="'.$rqd['directory'].'" >New Object</span>
                    <span id="go-drive-landing" class="btn btn-warning go-drive-landing">Drive Home</span>
                    <span id="go-drive-directory-list" class="btn btn-warning go-drive-directory-list">Directory List</span>
                    <span id="go-drive-object-list"
                          class="btn btn-info go-drive-object-list"
                          data-drive-directory-id="'.$rqd['directory'].'"
                          data-drive-directory-name="'.$rqd['directory'].'" >
                        <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                    </span>
                </div>';
    } else {

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                    <h5>This Directory is Empty</h5>
             </div>';

    }

    return $html;

};