<?php

Namespace Controller;

class LoginController extends \Controller\Base
{

    public function execute($pageVars) {

        $page_model = new \Model\Login\PageModel();
        $page = $page_model->getPage($pageVars);

        \ISOPHP\js_core::$window->document->title = $page['title'];

        $res = new \Controller\Result();
        \ISOPHP\js_core::$console->log('LoginController\Execute $res is', $res) ;
        \ISOPHP\js_core::$console->log('LoginController\Execute $pageVars[route][action] is', $pageVars['route']['action']) ;

        if ($pageVars['route']['action'] == 'attempt') {
            $page_model->getLoginResult($pageVars);
            $res->type = null; # no type means no further action
            return $res;
        }

        if ($pageVars['route']['action'] == 'show') {
            $res->page = $page;
            $res->view = 'Login.php';
            $res->type = 'view';
            $res->view_control = 'Login';
            $res->post_template = array();
            $res->post_template[] = $page_model->bindButtons();
            return $res;
        }

        \ISOPHP\js_core::$console->log('LoginController\Execute empty return') ;

    }

}