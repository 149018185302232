<?php

\Core\View::$template = function () {

    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-sc1-launchtemplate-update" class="btn btn-success attempt-sc1-launchtemplate-update">Save</span>
                        <span id="go-sc1-launchtemplate-list" class="btn btn-warning go-sc1-launchtemplate-list">Launch Template List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                
                    <div>
                        <h4>Edit Launch Template</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>ID:</label></div>                          
                                <div>&nbsp;&nbsp;&nbsp;<strong>'.$rqd['launchtemplate']['id'].'</strong></div>
                                <input type="hidden" id="launchtemplate_id" name="launchtemplate_id" value="'.$rqd['launchtemplate']['id'].'" />
                            </div>
                        </div> 

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Image</label></div>                          
                                <div id="sc1_image_dropdown"></div>
                            </div>
                        </div> 

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Size</label></div>                          
                                <div id="sc1_size_dropdown"></div>
                            </div>
                        </div> 

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Template Name</label>
                                <input type="text" name="template_name" class="form-control" id="template_name" value="'.$rqd['launchtemplate']['template_name'].'" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Instance Name</label>
                                <input type="text" name="instance_name" class="form-control" id="instance_name" value="'.$rqd['launchtemplate']['instance_name'].'" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Keypair</label></div>                          
                                <div id="sc1_keypair_dropdown"></div>
                            </div>
                        </div> 
                                              
                        <div class="col-sm-12 col-12 networks_available_wrap">
                            <h3>Networks Enabled</h3>
                        </div> 
                                              
                        <div class="col-sm-12 col-12 networks_available_wrap">
                            <div class="col-sm-6 col-6 text-right">
                                Internet Enabled:
                            </div>
                            <div class="col-sm-6 col-6">           
                                 <input type="checkbox" name="internet_enabled" class="float-left" id="internet_enabled" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="col-sm-12 col-12 form-group">
                                <label class="networks_enabled_label">VPC Networks:</label>
                            </div>
                                  
                            <div class="col-sm-12 col-12">
                                <div id="svpc_vpc_multiselect" class="form-control">
                                    <select class="form-control" name="svpc_name" id="svpc_name">
                                        <option>Loading SVPCs</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                        
                            <div class="col-sm-6 col-6">
                                <label>Description:</label>
                                <textarea name="description" id="description" class="form-control" rows="10" cols="30">'.$rqd['launchtemplate']['description'].'</textarea>
                            </div>
                      
                            <div class="col-sm-6 col-6">
                                <label>User Data Script:</label>
                                <textarea name="userdata" id="userdata" class="form-control" rows="10">'.$rqd['launchtemplate']['userdata'].'</textarea>
                            </div>
                            
                        </div>
                        
                    </div>
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-sc1-launchtemplate-update" class="btn btn-success attempt-sc1-launchtemplate-update">Save</span>
                        <span id="go-sc1-launchtemplate-list" class="btn btn-warning go-sc1-launchtemplate-list">Launch Template List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                    
                </div>
            </div>
        </div>';

    return $html;

};
