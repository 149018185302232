<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $past_invoices_are_array = \ISOPHP\core::$php->is_array($rqd['past_invoices']) ;
    if ($past_invoices_are_array === true) {
        $past_invoices_exist = true ;
    } else {
        $past_invoices_exist = false ;
    }
    if ($past_invoices_exist === true) {
        $past_invoice_count = $rqd['past_invoices']['count'] ;
    } else {
        $past_invoice_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-billing-invoice-pay" class="btn btn-success go-billing-invoice-pay">Pay Current Invoice</span>
            <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
            <span id="go-billing-invoice-list" class="btn-refresh btn btn-info go-billing-invoice-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Invoice List</h4>
        </div> ' ;

    if ( ($past_invoices_exist === true) && ($past_invoice_count > 0) ) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <h5>Past Invoices: <strong>'.$past_invoice_count.'</strong> </h5>
                 </div>
                 <div class="col-sm-12 col-12">
                    <div class="col-sm-3 col-3">
                        <h5 class="billing_past_invoice_id_title">Period</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="billing_past_invoice_id_title">Amount</h5>
                    </div>
                    <div class="col-sm-4 col-4">
                        <h5 class="billing_past_invoice_id_title">Items</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="billing_past_invoice_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="billing_past_invoice_id_title">Paid</h5>
                    </div>
                 </div> ';

        foreach ($rqd['past_invoices']['data'] as $one_billing_past_invoice) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_billing_past_invoice">
                    <div class="col-sm-3 col-3">
                        <p class="billing_list_comment billing_listing">' . $one_billing_past_invoice['period_format'] . '</p>
                    </div> 
                    <div class="col-sm-1 col-1">
                        <p class="billing_list_name"><span class="billing_name billing_listing"> £' . $one_billing_past_invoice['amount'] . '</span></p>
                    </div>    
                    <div class="col-sm-4 col-4">                
                        <span id="attempt-billing-single-invoice-' . $one_billing_past_invoice['id'] . '"
                              data-billing-invoice-id="' . $one_billing_past_invoice['id'] . '"
                              class="btn btn-info attempt-billing-single-invoice">
                            <i class="fas fa-external-link-alt"></i>
                        </span>                        
                        <span id="go-billing-pdfinvoice-download-' . $one_billing_past_invoice['id'] . '"
                              data-billing-invoice-id="' . $one_billing_past_invoice['id'] . '"
                              class="btn btn-info attempt-billing-pdfinvoice-download">
                            <i class="fas fa-file-pdf-o"></i>
                        </span>
                        <span id="go-billing-csvinvoice-download-' . $one_billing_past_invoice['id'] . '"
                              data-billing-invoice-id="' . $one_billing_past_invoice['id'] . '"
                              class="btn btn-info attempt-billing-csvinvoice-download">
                            <i class="fas fa-file-csv"></i>
                        </span>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_id"><span class="billing_id billing_listing">' . $one_billing_past_invoice['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_comment billing_listing">' ;

                        if ($one_billing_past_invoice['paid'] === "true") {
                            $html = $html . '<i class="fa fa-2x fa fa-check" style="color: green"></i>' ;
                        } else {
                            $html = $html . '<i class="fas fa-2x fa-window-close" style="color: red"></i>' ;
                        }

            $html = $html . '
                        </p>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Past Invoices</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-billing-invoice-pay" class="btn btn-success go-billing-invoice-pay">Pay Current Invoice</span>
                <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
                <span id="go-billing-invoice-list" class="btn-refresh btn btn-info go-billing-invoice-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};
