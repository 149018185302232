<?php

Namespace Model\Database;

use ISOPHP\core;

class ParameterGroupPageModel extends \Model\Base {

// $bind = \Model\Database\PageModel::bindButtons();
    public function showDatabaseParameterGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'ParameterGroupCreate.php', $vars);
            $bind = \Model\Database\PageModel::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database ParameterGroup Create 2', $vars);
        };
    }

    public function showDatabaseParameterGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/parametergroup/all';
            $afterwards = $this->applyDatabaseParameterGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseParameterGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database ParameterGroup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database ParameterGroup List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'ParameterGroupList.php', $vars);
                $bind = \Model\Database\PageModel::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database ParameterGroup List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database ParameterGroup List failed');
            }
        };
        return $func;
    }

    /**
     * Save parametergroup
     */
    public function saveParameterGroupAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('save parametergroup to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'parametergroup-create';
        $vars['api_uri'] = '/api/database/parametergroup/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save parametergroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveParameterGroupResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveParameterGroupResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save database parametergroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['parametergroup']['database_parametergroup_name'].' for Instance '.$request_data['parametergroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database parametergroup saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['parametergroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database parametergroup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete parametergroup
     */
    public function deleteParameterGroupAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete parametergroup to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'parametergroup-delete';
        $vars['api_uri'] = '/api/database/parametergroup/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['parametergroup_id'] = $jQuery('#parametergroup_name')->val();
        \ISOPHP\js_core::$console->log('delete parametergroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteParameterGroupResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteParameterGroupResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete database parametergroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['parametergroup']['database_parametergroup_name'].' for Instance '.$request_data['parametergroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database parametergroup deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['parametergroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database parametergroup delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete parametergroup
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_database_parametergroup_list = $jQuery('.go-database-parametergroup-list');
                $go_database_parametergroup_list->off() ;
                $go_database_parametergroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'parametergroup-list', array(), '/database/parametergroup/list');
                });

                $go_database_parametergroup_create = $jQuery('.go-database-parametergroup-create');
                $go_database_parametergroup_create->off() ;
                $go_database_parametergroup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'parametergroup-create', array(), '/database/parametergroup/create');
                });

                $save_parametergroup = $jQuery('.attempt-parametergroup-create');
                $save_parametergroup->off() ;
                $save_parametergroup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'parametergroup-save-attempt', array());
                });

                $parametergroup_delete = $jQuery('.attempt-parametergroup-delete');
                $parametergroup_delete->off() ;
                $parametergroup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $parametergroup_name = $jqThis->attr('data-database-parametergroup-name') ;
                    $parametergroup_id = $jqThis->attr('data-database-parametergroup-id') ;
                    \ISOPHP\js_core::$console->log('Clicked parametergroup delete button');
                    if ($parametergroup_name !== '') {
                        $name_or_id = $parametergroup_name ;
                    } else {
                        $name_or_id = $parametergroup_id ;
                    }
                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $parametergroup_id) {
                        $navigate->route('Database', 'parametergroup-delete-attempt', array('parametergroup_id' => $parametergroup_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}