<?php

Namespace Model\SVPC;

use ISOPHP\core;

class DHCPOptionsPageModel extends \Model\Base
{

    public function showSVPCDHCPOptionsCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'DHCPOptionsCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC DHCPOptions Create 2', $vars);
        };
    }

    public function showSVPCDHCPOptionsList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC DHCPOptions List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/dhcpoptions/all';
            $afterwards = $this->applySVPCDHCPOptionsListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCDHCPOptionsListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC DHCPOptions List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC DHCPOptions List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'DHCPOptionsList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC DHCPOptions List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC DHCPOptions List failed');
            }
        };
        return $func;
    }



    /**
     * Save dhcpoptions
     */
    public function saveDHCPOptionsAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save dhcpoptions to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'dhcpoptions-create';
        $vars['api_uri'] = '/api/svpc/dhcpoptions/create';
        $vars['svpc_id'] = $jQuery('#svpc_id')->val();
        $vars['value'] = $jQuery('#value')->val();
        $vars['type'] = $jQuery('#type')->val();
        $vars['name'] = $jQuery('#name')->val();
        \ISOPHP\js_core::$console->log('save dhcpoptions vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveDHCPOptionsResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveDHCPOptionsResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc dhcpoptions result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created DHCP Options '.$request_data['dhcpoptions']['svpc_dhcpoptions_name'].' for SVPC '.$request_data['dhcpoptions']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc dhcpoptions saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating DHCP Options '.$request_data['dhcpoptions']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc dhcpoptions save failed');
            }
        };
        return $func;
    }


    /**
     * Delete dhcpoptions
     */
    public function deleteDHCPOptionsAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete dhcpoptions to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'dhcpoptions-delete';
        $vars['api_uri'] = '/api/svpc/dhcpoptions/delete';
        $vars['svpc_dhcpoptions_id'] = $jQuery('#svpc_dhcpoptions_id')->val();
        \ISOPHP\js_core::$console->log('delete dhcpoptions vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveDHCPOptionsResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDHCPOptionsResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc dhcpoptions result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted DHCP Options '.$request_data['dhcpoptions']['svpc_dhcpoptions_name'].' for SVPC '.$request_data['dhcpoptions']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc dhcpoptions deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'dhcpoptions-list', array(), '/svpc/dhcpoptions/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting DHCP Options '.$request_data['dhcpoptions']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc dhcpoptions delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons DHCP Options');
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });

                $go_svpc_dhcpoptions_list = $jQuery('.go-svpc-dhcpoptions-list');
                $go_svpc_dhcpoptions_list->off() ;
                $go_svpc_dhcpoptions_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'dhcpoptions-list', array(), '/svpc/dhcpoptions/list');
                });

                $go_svpc_dhcpoptions_create = $jQuery('.go-svpc-dhcpoptions-create');
                $go_svpc_dhcpoptions_create->off() ;
                $go_svpc_dhcpoptions_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'dhcpoptions-create', array(), '/svpc/dhcpoptions/create');
                });

                $save_dhcpoptions = $jQuery('.attempt-dhcpoptions-create');
                $save_dhcpoptions->off() ;
                $save_dhcpoptions->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'dhcpoptions-save-attempt', array());
                });

//                $instructions_switch = $jQuery('.switch_instruction_set');
//                $instructions_switch->off() ;
//                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
//                    $id = $jsthis->target->id ;
//                    $new_id = str_replace('switch_', '', $id) ;
//                    $instruction_set = $jQuery("#" . $new_id) ;
//                    $jq_switch = $jQuery("#" . $id) ;
//                    if ($instruction_set->css('display') == 'none') {
//                        $jq_switch->removeClass("fa-toggle-off");
//                        $jq_switch->addClass("fa-toggle-on");
//                        $instruction_set->slideDown();
//                    } else {
//                        $jq_switch->removeClass("fa-toggle-on");
//                        $jq_switch->addClass("fa-toggle-off");
//                        $instruction_set->slideUp();
//                    }
//                } ) ;

                $dhcpoptions_delete = $jQuery('.attempt-dhcpoptions-delete');
                $dhcpoptions_delete->off() ;
                $dhcpoptions_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $dhcpoptions_name = $jqThis->attr('data-svpc-dhcpoptions-name') ;
//                    $dhcpoptions_id = $jqThis->attr('data-svpc-dhcpoptions-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked dhcpoptions delete button');
//                    $msg = "You are about to delete the DHCP Options for $dhcpoptions_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $dhcpoptions_id) {
//                        $navigate->route('SVPC', 'dhcpoptions-delete-attempt', array('dhcpoptions_id' => $dhcpoptions_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }


}