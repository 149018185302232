<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-application-create" class="btn btn-success attempt-application-create">Create</span>
                        <span id="go-analytics-application-list" class="btn btn-info go-analytics-application-list">Application List</span>
                        <span id="go-analytics-landing" class="btn btn-info go-analytics-landing">Analytics Home</span>
                    </div>
                        
                    <div>
                        <h4>New Application</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" class="form-control" id="name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>URL</label>
                            <input type="text" name="url" class="form-control" id="url" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea name="description" id="description" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-application-create" class="btn btn-success attempt-application-create">Create</span>
                        <span id="go-analytics-application-list" class="btn btn-info go-analytics-application-list">Application List</span>
                        <span id="go-analytics-landing" class="btn btn-info go-analytics-landing">Analytics Home</span>
                    </div>
                    
                </div>
            </div>
            
             
        </div>';

    return $html;

};