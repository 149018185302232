<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">

                <div class="col-sm-12 col-12" >
                
                    <div>
                        <h4>New DNS Object</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <label>Repository</label>
                            <div id="scm_repository_dropdown">
                                <select class="form-control" name="repository_name" id="repository_name">
                                    <option>Loading Repositories</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <label>Name</label>
                            <input type="text" name="object_name" class="form-control" id="object_name" value="" />
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <label>Value</label>
                            <textarea name="object_value" id="object_value" class="form-control"></textarea>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <label>Object Type</label>
                            <select id="object_type" name="object_type" class="form-control">
                                 <option value="a_ipv4_address">A – IPv4 address</option>
                                 <option value="cname_canonical_name">CNAME – Canonical name</option>
                                 <option value="mx_mail_exchange">MX – Mail exchange</option>
                                 <option value="aaaa_ipv6_address">AAAA – IPv6 address</option>
                                 <option value="txt_text">TXT – Text</option>
                                 <option value="ptr_pointer">PTR – Pointer</option>
                                 <option value="srv_service_locator">SRV – Service locator</option>
                                 <option value="spf_sender_policy_framework">SPF – Sender Policy Framework</option>
                                 <option value="naptr_name_authority_pointer">NAPTR – Name Authority Pointer</option>
                                 <option value="ns_name_server">NS – Name server</option>
                            </select>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-object-create" class="btn btn-success attempt-object-create">Create</span>
                            <span id="go-scm-object-list" class="btn btn-warning go-scm-object-list">SCM Object List</span>
                            <span id="go-scm-landing" class="btn btn-warning go-scm-landing">SCM Home</span>
                        </div>
                        
                    </div>
                    
                </div>
                
        </div>';

    return $html;

};

