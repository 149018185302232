<?php

Namespace View;

class ServicesView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displayServices($binder)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('display services');
            $jQuery = \ISOPHP\js_core::$jQuery;
            $tplfunc = \Core\View::$template;
            \ISOPHP\js_core::$console->log('pl func', $tplfunc);
            $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
            $jQuery('#services_content')->html($tpl_data);
            \ISOPHP\js_core::$console->log('binding');
            $binder();
        }
    }

    public function displayServicesDomainDropDown($binder)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('display services domain dropdown');
            $jQuery = \ISOPHP\js_core::$jQuery;
            $tplfunc = \Core\View::$template;
            \ISOPHP\js_core::$console->log('pl func', $tplfunc);
            $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
            $jQuery('#services_domain_dropdown')->html($tpl_data);
            \ISOPHP\js_core::$console->log('binding');
            $binder();
        }
    }

    public function displayServicesNewPost($binder)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('display new services form');
            $jQuery = \ISOPHP\js_core::$jQuery;
            $tplfunc = \Core\View::$template;
            \ISOPHP\js_core::$console->log('pl func', $tplfunc);
            $tpl_data = $tplfunc();
            $jQuery('#services_list')->html($tpl_data);
            \ISOPHP\js_core::$console->log('binding');
            $binder();
        }
    }

}