<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $launchconfigurations_are_array = \ISOPHP\core::$php->is_array($rqd['launchconfigurations']) ;
    if ($launchconfigurations_are_array === true) {
        $launchconfigurations_exist = true ;
    } else {
        $launchconfigurations_exist = false ;
    }
    if ($launchconfigurations_exist === true) {
        $launchconfiguration_count = \ISOPHP\core::$php->count($rqd['launchconfigurations']) ;
    } else {
        $launchconfiguration_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-launchconfiguration-create" class="btn btn-success go-sc1-launchconfiguration-create">New Launch Configuration</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-launchconfiguration-list" class="btn btn-info go-sc1-launchconfiguration-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Launch Configuration List</h4>
         </div> ' ;

    if ($launchconfigurations_exist === true && $launchconfiguration_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-launchconfiguration-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_launchconfiguration item_list_sc1_launchconfigurations" id="item_list_sc1_launchconfigurations">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchconfiguration_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchconfiguration_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchconfiguration_table_title">Size</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchconfiguration_table_title">Start</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchconfiguration_table_title">End</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchconfiguration_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchconfiguration_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['launchconfigurations'] as $one_sc1_launchconfiguration) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->substr($one_sc1_launchconfiguration['id'], 0, 10) ;

            $html = $html .
                    '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_launchconfiguration_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_launchconfiguration['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_instance_size"><span class="sc1_instance_size sc1_listing">' . $one_sc1_launchconfiguration['size'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchconfiguration_start_time"><span class="sc1_launchconfiguration_start_time sc1_listing">' . $one_sc1_launchconfiguration['start_time'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchconfiguration_end_time"><span class="sc1_launchconfiguration_end_time sc1_listing">' . $one_sc1_launchconfiguration['end_time'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_launchconfiguration_description"><span class="sc1_launchconfiguration_description sc1_listing">' . $one_sc1_launchconfiguration['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_launchconfiguration_' . $one_sc1_launchconfiguration['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-launchconfiguration-edit btn btn-outline-primary"
                                   data-sc1-launchconfiguration-id="' . $one_sc1_launchconfiguration['id'] . '"
                                   data-sc1-launchconfiguration-name="' . $one_sc1_launchconfiguration['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_launchconfiguration_' . $one_sc1_launchconfiguration['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-launchconfiguration-delete btn btn-outline-danger"
                                   data-sc1-launchconfiguration-id="' . $one_sc1_launchconfiguration['id'] . '"
                                   data-sc1-launchconfiguration-name="' . $one_sc1_launchconfiguration['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-launchconfiguration-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Launch Configurations</h5>
             </div>' ;

    }

    $html = $html .
       '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-launchconfiguration-create" class="btn btn-success go-sc1-launchconfiguration-create">New Launch Configuration</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-launchconfiguration-list" class="btn btn-info go-sc1-launchconfiguration-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Launch Configurations</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_launchconfiguration_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_launchconfiguration_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_launchconfiguration_awscli_aws" class="instruction_set instruction_set_list_launchconfiguration_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_launchconfiguration_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_launchconfiguration_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_launchconfiguration_ptc_aws" class="instruction_set instruction_set_list_launchconfiguration_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceScheduled nstanceSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};