<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $jobs_are_array = \ISOPHP\core::$php->is_array($rqd['jobs']) ;
    if ($jobs_are_array === true) {
        $jobs_exist = true ;
    } else {
        $jobs_exist = false ;
    }
    if ($jobs_exist === true) {
        $job_count = \ISOPHP\core::$php->count($rqd['jobs']) ;
    } else {
        $job_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-job-create" class="btn btn-success go-build-job-create">New Job</span>
            <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
            <span id="go-build-job-list" class="btn-refresh btn btn-info go-build-job-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Job List</h4>
        </div> ' ;

    if ($jobs_exist === true && $job_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_build_job">
                    <div class="col-sm-3 col-3">
                        <h5 class="build_job_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_id_title">Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="build_job_id_title">Description</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="build_job_id_title">#</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="build_job_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['jobs'] as $one_build_job) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_build_job">
                    <div class="col-sm-3 col-3">
                        <p class="build_list_id"><span class="build_id build_listing">' . $one_build_job['build_job_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_name"><span class="build_name build_listing">' . $one_build_job['build_job_name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="build_list_comment build_listing">' . $one_build_job['build_job_description'] . '</p>
                    </div>  
                    <div class="col-sm-1 col-1">
                        <p class="build_list_count build_listing">' . $one_build_job['last_run_build'] . '</p>
                    </div>     
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-3 col-3">
                                <i id="execute_build_job_' . $one_build_job['id'] . '"
                                   class="fa fa-1x fa-play hvr-grow dom attempt-job-execute btn btn-outline-success"
                                   data-build-job-id="' . $one_build_job['id'] . '"
                                   data-build-job-name="' . $one_build_job['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="execution_list_build_job_' . $one_build_job['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-job-execution-list btn btn-outline-primary"
                                   data-build-job-id="' . $one_build_job['id'] . '"
                                   data-build-job-name="' . $one_build_job['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="edit_build_job_' . $one_build_job['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom go-build-job-edit btn btn-outline-primary"
                                   data-build-job-id="' . $one_build_job['id'] . '"
                                   data-build-job-name="' . $one_build_job['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="delete_build_job_' . $one_build_job['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-job-delete btn btn-outline-danger"
                                   data-build-job-id="' . $one_build_job['id'] . '"
                                   data-build-job-name="' . $one_build_job['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Jobs</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-build-job-create" class="btn btn-success go-build-job-create">New Job</span>
                <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                <span id="go-build-job-list" class="btn-refresh btn btn-info go-build-job-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};