<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-generativeai-bucket-create" class="btn btn-success attempt-generativeai-bucket-create">Create</span>
                        <span id="go-generativeai-bucket-list" class="btn btn-info go-generativeai-bucket-list">GenerativeAI Bucket List</span>
                        <span id="go-generativeai-landing" class="btn btn-info go-generativeai-landing">GenerativeAI Home</span>
                    </div>
                        
                    <div>
                        <h4>New Bucket</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Bucket Name</label>
                        <input type="text" name="bucket_name" class="form-control" id="bucket_name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label for="bucket_public_access">Public Access</label>
                        <input type="checkbox" name="bucket_public_access" class="form-control" id="bucket_public_access" />   
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="description" id="description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-generativeai-bucket-create" class="btn btn-success attempt-generativeai-bucket-create">Create</span>
                        <span id="go-generativeai-bucket-list" class="btn btn-info go-generativeai-bucket-list">GenerativeAI Bucket List</span>
                        <span id="go-generativeai-landing" class="btn btn-info go-generativeai-landing">GenerativeAI Home</span>
                    </div>
                    
                </div>
            </div>
             
        </div>';

    return $html;

};
