<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Instance Name' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['vm_name'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Instance ID' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['vm_id'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Instance Description' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['vm_description'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Instance Region' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['region'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;




    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        OS Distribution' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['instances']['config']['image.os'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        OS Release' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['instances']['config']['image.release'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        OS Version' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['instances']['config']['image.version'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;



    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Instance Type' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['size'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Memory Size' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['internal_size']['mem'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Disk Size' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['internal_size']['hdd'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Core Count' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['internal_size']['cores'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;



    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        Internet Enabled' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    if ($rqd['vm']['internet_enabled'] === true) {
        $html = $html . 'Yes' ;
    } else {
        $html = $html . 'No' ;
    }
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        VPC Enabled' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    if ($rqd['vm']['vpc_enabled'] === true) {
        $html = $html . 'Yes' ;
    } else {
        $html = $html . 'No' ;
    }
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '<div class="col-sm-12">' ;
    $html = $html . '    <div class="col-sm-4">' ;
    $html = $html . '      <strong>' ;
    $html = $html . '        User Data' ;
    $html = $html . '      </strong>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-8">' ;
    $html = $html . '        '.$rqd['vm']['user_data'] ;
    $html = $html . '    </div>' ;
    $html = $html . '</div>' ;


    return $html;

};