<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $vpcs_are_array = \ISOPHP\core::$php->is_array($rqd['vpcs']) ;
    if ($vpcs_are_array === true) {
        $vpcs_exist = true ;
    } else {
        $vpcs_exist = false ;
    }
    if ($vpcs_exist === true) {
        $svpc_count = \ISOPHP\core::$php->count($rqd['vpcs']) ;
    } else {
        $svpc_count = false ;
    }

    if ($vpcs_exist === true && $svpc_count !== false) {
        $select_size = $svpc_count ;
        if ($svpc_count > 5) {
            $select_size = 5 ;
        }
        $html = $html . '<select class="form-control" name="svpc_name" id="svpc_name" size="'.$select_size.'" multiple>';
        foreach ($rqd['vpcs'] as $one_svpc_vpc) {
            $html = $html . '<option value="' . $one_svpc_vpc['id'] . '">' . $one_svpc_vpc['name'] . ' - ' . $one_svpc_vpc['id'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available VPCs</option>
             </select>' ;

    }

    return $html;

};