<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $instance_executionlogs_are_array = \ISOPHP\core::$php->is_array($rqd['instance_executionlogs']) ;
    if ($instance_executionlogs_are_array === true) {
        $instance_executionlogs_exist = true ;
    } else {
        $instance_executionlogs_exist = false ;
    }
    if ($instance_executionlogs_exist === true) {
        $instance_executionlog_count = \ISOPHP\core::$php->count($rqd['instance_executionlogs']) ;
    } else {
        $instance_executionlog_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-instance-list" class="btn btn-info go-sc1-instance-list">SC1 Instances</span>
            <span id="go-sc1-instance-executionlog-list" class="btn btn-info go-sc1-instance-executionlog-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Instance Execution Log List</h4>
         </div> ' ;

    if ( ($instance_executionlogs_exist === true) && ($instance_executionlog_count > 0) ) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-instance-executionlog-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_instance item_list_sc1_instance_executionlogs" id="item_list_sc1_instance_executionlogs">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_instance_table_title">Time</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_instance_table_title">Status</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_instance_table_title">ID</h5>
                        </div>
                        <div class="col-sm-7 col-7">
                            <h5 class="sc1_instance_table_title">Log Message</h5>
                        </div>
                    </div>
                 </div>';
        foreach ($rqd['instance_executionlogs'] as $one_sc1_instance_executionlog) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_time"><span class="sc1_list_time sc1_listing">' . $one_sc1_instance_executionlog['time_format'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_status"><span class="sc1_list_status sc1_listing">' . $one_sc1_instance_executionlog['status'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_list_id sc1_listing">' . $one_sc1_instance_executionlog['id'] . '</span></p>
                    </div>
                    <div class="col-sm-7 col-7">
                        <p class="sc1_list_message"><span class="sc1_list_message sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->json_encode($one_sc1_instance_executionlog['log']) ;

            $html = $html . '</span></p>
                    </div>
                 </div>';
        }


        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-instance-executionlog-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Instance Execution Logs</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-instance-list" class="btn btn-info go-sc1-instance-list">SC1 Instances</span>
            <span id="go-sc1-instance-executionlog-list" class="btn btn-info go-sc1-instance-executionlog-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    return $html;

};