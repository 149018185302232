<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $dhcpoptions_are_array = \ISOPHP\core::$php->is_array($rqd['dhcpoptions']) ;
    if ($dhcpoptions_are_array === true) {
        $dhcpoptions_exist = true ;
    } else {
        $dhcpoptions_exist = false ;
    }
    if ($dhcpoptions_exist === true) {
        $dhcpoptions_count = \ISOPHP\core::$php->count($rqd['dhcpoptions']) ;
    } else {
        $dhcpoptions_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-dhcpoptions-create" class="btn btn-success go-svpc-dhcpoptions-create">Create DHCP Options</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-dhcpoptions-list" class="btn btn-info go-svpc-dhcpoptions-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>DHCP Options List</h4>
         </div> ' ;

    if ($dhcpoptions_exist === true && $dhcpoptions_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_svpc_dhcpoptions">
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">SVPC DHCP Options ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">Domain</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_dhcpoptions_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['dhcpoptions'] as $one_svpc_dhcpoptions) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_dhcpoptions['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_domain_name"><span class="svpc_domain_name svpc_listing">' . $one_svpc_dhcpoptions['vpc_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_dhcpoptions_name"><span class="svpc_dhcpoptions_name svpc_listing">' . $one_svpc_dhcpoptions['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_dhcpoptions_data"><span class="svpc_dhcpoptions_data svpc_listing">' . $one_svpc_dhcpoptions['value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_dhcpoptions_type"><span class="svpc_type svpc_listing">' . $one_svpc_dhcpoptions['type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_svpc_dhcpoptions_' . $one_svpc_dhcpoptions['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-dhcpoptions-edit btn btn-outline-primary"
                                   data-svpc-dhcpoptions-id="' . $one_svpc_dhcpoptions['id'] . '"
                                   data-svpc-dhcpoptions-name="' . $one_svpc_dhcpoptions['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_svpc_dhcpoptions_' . $one_svpc_dhcpoptions['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-dhcpoptions-delete btn btn-outline-danger"
                                   data-svpc-dhcpoptions-id="' . $one_svpc_dhcpoptions['id'] . '"
                                   data-svpc-dhcpoptions-name="' . $one_svpc_dhcpoptions['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';

        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted DHCP Options</h5>
             </div>' ;

    }

    $html = $html .
   '<div class="col-sm-12 col-12 text-center">
        <span id="go-svpc-dhcpoptions-create" class="btn btn-success go-svpc-dhcpoptions-create">Create DHCP Options</span>
        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
        <span id="go-svpc-dhcpoptions-list" class="btn btn-info go-svpc-dhcpoptions-list">
            <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
        </span>
    </div>' ;

    return $html;

};