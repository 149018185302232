<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-dhcpoptions-create" class="btn btn-success attempt-dhcpoptions-create">Create</span>
                        <span id="go-svpc-dhcpoptions-list" class="btn btn-warning go-svpc-dhcpoptions-list">DHCP Options Set List</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
            
                    <div>
                        <h4>New DHCP Options Set</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>SVPC</label>
                                <div id="svpc_svpc_dropdown">
                                    <select class="form-control" name="svpc_name" id="svpc_name">
                                        <option>Loading SVPCs</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control" id="name" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Value</label>
                                <textarea name="value" id="value" class="form-control"></textarea>
                            </div>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>DHCP Options Type</label>
                                <select id="type" name="type" class="form-control">
                                     <option value="type_1">Type 1</option>
                                     <option value="type_2">Type 2</option>
                                     <option value="type_3">Type 3</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-dhcpoptions-create" class="btn btn-success attempt-dhcpoptions-create">Create</span>
                        <span id="go-svpc-dhcpoptions-list" class="btn btn-warning go-svpc-dhcpoptions-list">DHCP Options Set List</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    return $html;

};
