<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $emulators_are_array = \ISOPHP\core::$php->is_array($rqd['emulators']) ;
    if ($emulators_are_array === true) {
        $emulators_exist = true ;
    } else {
        $emulators_exist = false ;
    }
    if ($emulators_exist === true) {
        $emulator_count = \ISOPHP\core::$php->count($rqd['emulators']) ;
    } else {
        $emulator_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-mobiledevice-emulator-create" class="btn btn-success go-mobiledevice-emulator-create">New Emulator</span>
            <span id="go-mobiledevice-landing" class="btn btn-info go-mobiledevice-landing">Mobile Device Home</span>
            <span id="go-mobiledevice-emulator-list" class="btn btn-info go-mobiledevice-emulator-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Emulator List</h4>
        </div> ' ;

    if ($emulators_exist === true && $emulator_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_mobiledevice_device">
                    <div class="col-sm-3 col-3">
                        <h5 class="mobiledevice_emulator_id_title">ID</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="mobiledevice_emulator_id_title">Make/Model</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="mobiledevice_emulator_id_title">Description</h5>
                    </div> 
                    <div class="col-sm-3 col-3">
                        <h5 class="mobiledevice_emulator_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['emulators'] as $one_mobiledevice_emulator) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_mobiledevice_emulator">
                    <div class="col-sm-3 col-3">
                        <p class="mobiledevice_list_id"><span class="mobiledevice_id mobiledevice_listing">' . $one_mobiledevice_emulator['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="mobiledevice_list_name"><span class="mobiledevice_name mobiledevice_listing">' . $one_mobiledevice_emulator['make_model'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="mobiledevice_list_comment mobiledevice_listing">' . $one_mobiledevice_emulator['description'] . '</p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="screenview_mobiledevice_emulator_' . $one_mobiledevice_emulator['id'] . '"
                                   class="fa fa-1x fa-television hvr-grow dom attempt-device-screenview btn btn-outline-success"
                                   data-mobiledevice-emulator-id="' . $one_mobiledevice_emulator['id'] . '"
                                   data-mobiledevice-emulator-name="' . $one_mobiledevice_emulator['id'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="recording_history_mobiledevice_emulator_' . $one_mobiledevice_emulator['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-mobiledevice-recording-list btn btn-outline-primary"
                                   data-mobiledevice-emulator-id="' . $one_mobiledevice_emulator['id'] . '"
                                   data-mobiledevice-emulator-name="' . $one_mobiledevice_emulator['id'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_mobiledevice_emulator_' . $one_mobiledevice_emulator['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-device-delete btn btn-outline-danger"
                                   data-mobiledevice-emulator-id="' . $one_mobiledevice_emulator['id'] . '"
                                   data-mobiledevice-emulator-name="' . $one_mobiledevice_emulator['id'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Mobile Emulators</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-mobiledevice-emulator-create" class="btn btn-success go-mobiledevice-emulator-create">New Emulator</span>
                <span id="go-mobiledevice-landing" class="btn btn-info go-mobiledevice-landing">Mobile Device Home</span>
                <span id="go-mobiledevice-emulator-list" class="btn btn-info go-mobiledevice-emulator-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};