<?php

Namespace Model\PCS;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'PCS List of Piranha Web Services';
        $page['heading'] = 'List of PCS';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('PCS Mod', $page, $pageVars);
        return $page;
    }

    public function showPCSLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCS Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCSView();
            $view->loadTemplate('PCS', 'PCSLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCS($bind);
            \ISOPHP\js_core::$console->log('apply PCS Definition Create 2', $vars);
        };
    }

    public function showPCSDefinitionCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCSView();
            $view->loadTemplate('PCS', 'DefinitionCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCS($bind);
            \ISOPHP\js_core::$console->log('apply PCS Definition Create 2', $vars);
        };
    }

    public function showPCSDefinitionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCS List');
            $vars['control'] = 'PCS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/pcs/definition/all';
            $afterwards = $this->applyPCSDefinitionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('PCS Model: ', $result);
            return $result;
        };
    }

    public function applyPCSDefinitionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCS Definition List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCS Definition List 1', $vars);
                $view = new \View\PCSView();
                $view->loadTemplate('PCS', 'DefinitionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCS($bind);
                \ISOPHP\js_core::$console->log('apply PCS Definition List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCS Definition List failed');
            }
        };
        return $func;
    }

    public function showPCSDefinitionListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCS List');
            $vars['control'] = 'PCS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/pcs/definition/all';
            $afterwards = $this->applyPCSDefinitionListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('PCS Model: ', $result);
            return $result;
        };
    }

    public function applyPCSDefinitionListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCS Definition List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCS Definition List 1', $vars);
                $view = new \View\PCSView();
                $view->loadTemplate('PCS', 'DefinitionListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCSDefinitionDropDown($bind);
                \ISOPHP\js_core::$console->log('apply PCS Definition List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCS Definition List failed');
            }
        };
        return $func;
    }

    public function showPCSContainerCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCSView();
            $view->loadTemplate('PCS', 'ContainerCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCS($bind);
            \ISOPHP\js_core::$console->log('apply PCS Container Create 2', $vars);
        };
    }

    public function showPCSContainerList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for definition name');
            \ISOPHP\js_core::$console->log($pageVars['definition_name']);
            if (isset($pageVars['definition_name'])) {
                \ISOPHP\js_core::$console->log('Showing PCS List');
                $vars['control'] = 'PCS';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/pcs/container/all';
                $vars['definition_name'] = $pageVars['definition_name'];
                $afterwards = $this->applyPCSContainerListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('PCS Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyPCSContainerListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCS Container List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCS Container List 1', $vars);
                $view = new \View\PCSView();
                $view->loadTemplate('PCS', 'ContainerList.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCS($bind);
                \ISOPHP\js_core::$console->log('apply PCS Container List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCS Container List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save definition
     */
    public function saveDefinitionAttemptToPCS($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save definition to pcs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCS';
        $vars['action'] = 'definition-create';
        $vars['api_uri'] = '/api/pcs/definition/create';
        $vars['definition_name'] = $jQuery('#definition_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveDefinitionResultFromPCS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveDefinitionResultFromPCS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save pcs definition result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Definition '.$request_data['definition']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcs definition saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('PCS', 'definition-list', array(), '/pcs/definition/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Definition '.$request_data['definition']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcs definition save failed');
            }
        };
        return $func;
    }

    /**
     * Save container
     */
    public function saveContainerAttemptToPCS($pageVars)
    {
        \ISOPHP\js_core::$console->log('save container to pcs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCS';
        $vars['action'] = 'container-create';
        $vars['api_uri'] = '/api/pcs/container/create';
        $vars['definition_name'] = $jQuery('#definition_name')->val();
        $vars['container_name'] = $jQuery('#container_name')->val();
        $vars['container_value'] = $jQuery('#container_value')->val();
        $vars['container_type'] = $jQuery('#container_type')->val();
        \ISOPHP\js_core::$console->log('save container vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveContainerResultFromPCS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveContainerResultFromPCS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save pcs container result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Container '.$request_data['container']['pcs_container_name'].' for Definition '.$request_data['container']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcs container saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Container '.$request_data['container']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcs container save failed');
            }
        };
        return $func;
    }
    /**
     * Delete container
     */
    public function deleteContainerAttemptToPCS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete container to pcs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCS';
        $vars['action'] = 'container-delete';
        $vars['api_uri'] = '/api/pcs/container/delete';
        $vars['definition_name'] = $pageVars['params']['definition_name'];
        $vars['container_key'] = $pageVars['params']['container_key'];
        $vars['container_id'] = $pageVars['params']['container_id'];
        \ISOPHP\js_core::$console->log('delete container vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveContainerResultFromPCS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteContainerResultFromPCS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete pcs container result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Container '.$request_data['container']['pcs_container_name'].' from Definition '.$request_data['container']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcs container deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Container '.$request_data['container']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcs container delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete definition
     */
    public function deleteDefinitionAttemptToPCS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete definition to pcs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCS';
        $vars['action'] = 'definition-delete';
        $vars['api_uri'] = '/api/pcs/definition/delete';
        $vars['definition_name'] = $pageVars['params']['definition_name'];
        \ISOPHP\js_core::$console->log('delete definition vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteDefinitionResultFromPCS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDefinitionResultFromPCS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete pcs definition result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Definition '.$request_data['definition']['pcs_definition_name'].' for Definition '.$request_data['definition']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcs definition deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('PCS', 'definition-list', array(), '/pcs/definition/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Definition '.$request_data['definition']['pcs_definition_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcs definition delete failed');
            }
            $show_definition_list = self::showPCSDefinitionList($pageVars) ;
            $show_definition_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_pcs_landing = $jQuery('.go-pcs-landing');
                $go_pcs_landing->off() ;
                $go_pcs_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'landing', array(), '/pcs');
                });

                $go_pcs_definition_list = $jQuery('.go-pcs-definition-list');
                $go_pcs_definition_list->off() ;
                $go_pcs_definition_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'definition-list', array(), '/pcs/definition/list');
                });

                $go_pcs_definition_create = $jQuery('.go-pcs-definition-create');
                $go_pcs_definition_create->off() ;
                $go_pcs_definition_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'definition-create', array(), '/pcs/definition/create');
                });

                $save_definition = $jQuery('.attempt-definition-create');
                $save_definition->off() ;
                $save_definition->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'definition-save-attempt', array());
                });

                $go_pcs_container_list = $jQuery('.go-pcs-container-list');
                $go_pcs_container_list->off() ;
                $go_pcs_container_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'container-list', array(), '/pcs/container/list');
                });

                $go_pcs_container_create = $jQuery('.go-pcs-container-create');
                $go_pcs_container_create->off() ;
                $go_pcs_container_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'container-create', array(), '/pcs/container/create');
                });

                $save_container = $jQuery('.attempt-container-create');
                $save_container->off() ;
                $save_container->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCS', 'container-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $container_delete = $jQuery('.attempt-container-delete');
                $container_delete->off() ;
                $container_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $container_name = $jqThis->attr('data-pcs-container-name') ;
                    $container_id = $jqThis->attr('data-pcs-container-id') ;
                    $definition_name = $jqThis->attr('data-pcs-definition-name') ;
                    \ISOPHP\js_core::$console->log('Clicked container delete button');
                    $msg = "You are about to delete the Container for $container_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $container_id, $container_name, $definition_name) {
                        $navigate->route('PCS', 'container-delete-attempt', array(
                            'container_id' => $container_id,
                            'container_name' => $container_name,
                            'definition_name' => $definition_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $definition_delete = $jQuery('.attempt-definition-delete');
                $definition_delete->off() ;
                $definition_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $definition_name = $jqThis->attr('data-pcs-definition-name') ;
                    \ISOPHP\js_core::$console->log('Clicked definition delete button');
                    $msg = "You are about to delete the Definition $definition_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $definition_name) {
                        $navigate->route('PCS', 'definition-delete-attempt', array(
                            'definition_name' => $definition_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindDefinitionListDropDownButtonsForContainerList()
    {
        \ISOPHP\js_core::$console->log('Started binding definition switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding definition switch');
            $switch_definition = $jQuery('#definition_name');
            $switch_definition->off() ;
            $switch_definition->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#definition_name') ;
                $definition_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked definition switch');
                $navigate->route('PCS', 'container-list', array(
                    'definition_name' => $definition_name,
                ));
            } ) ;

        };
        return $func;
    }



}