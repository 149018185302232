<?php

Namespace Model\SC1;

use ISOPHP\core;

class InstancePageModel extends \Model\Base
{

    public function showSC1InstanceCreate($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 Create');
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceCreate.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Instance Create 2', $vars);
            };
        }
    }

    public function showSC1InstanceList($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 Instance List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/sc1/instance/all';
                if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                    $vars['page'] = 1 ;
                } else {
                    $vars['page'] = $pageVars['params']['page'] ;
                }
                $afterwards = $this->applySC1InstanceListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1InstanceListResult($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $func = function ($request_data) use ($pageVars) {
                \ISOPHP\js_core::$console->log('applying our SC1 Instance List result to', $request_data);
                if ($request_data['status'] === 'OK') {
                    $vars = array();
                    $vars['params'] = $pageVars['params'];
                    $vars['request_data'] = $request_data;
                    \ISOPHP\js_core::$console->log('apply SC1 Instance List 1', $vars);
                    $view = new \View\SC1View();
                    $view->loadTemplate('SC1', 'InstanceList.php', $vars);
                    $bind = self::bindButtons();
                    $view->displaySC1($bind);
                    \ISOPHP\js_core::$console->log('apply SC1 Instance List 2', $vars);
                    $page_model = new \Model\SC1\PageModel();
                    $bind = $page_model::bindAllButtons() ;
                    $bind() ;
                }
                if ($request_data['status'] !== 'OK') {
                    \ISOPHP\js_core::$console->log('SC1 Instance List failed');
                }
            };
            return $func;
        }
    }


    public function showSC1InstanceListDropDown($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/sc1/instance/all';
                $afterwards = $this->applySC1InstanceListDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1InstanceListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Instance List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Instance List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1InstanceDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Instance List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Instance List failed');
            }
        };
        return $func;
    }

    public function showSC1InstanceSizeDropDown($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list-size';
                $vars['api_uri'] = '/api/sc1/size/all';
                $vars['page'] = 'all';
                $afterwards = $this->applySC1InstanceSizeDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1InstanceSizeDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Instance Size List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Instance Size List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceSizeDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1SizeDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Instance Size List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Instance Size List failed');
            }
        };
        return $func;
    }

    public function showSC1InstanceImageDropDown($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 Image List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list-image';
                $vars['api_uri'] = '/api/sc1/image/all';
                $afterwards = $this->applySC1InstanceImageDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1InstanceImageDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Instance Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Instance Image List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceImageDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1ImageDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Instance Image List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Instance Image List failed');
            }
        };
        return $func;
    }

    public static function showSC1SingleInstanceDetails($instance_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing SC1 Single Instance');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list-keypair';
            $vars['api_uri'] = '/api/sc1/instance/one';
            $vars['instance_id'] = $instance_id;
            $afterwards = self::applySC1SingleInstanceResult($instance_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        }
    }

    public static function applySC1SingleInstanceResult($instance_id) {
        $func = function ($request_data) use ($instance_id) {
            \ISOPHP\js_core::$console->log('applying our SC1 Single Instance result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Single Instance List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'SingleInstanceDetails.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1SingleInstanceDetails($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Single Instance List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Single Instance List failed');
            }
        };
        return $func;
    }

    public static function showSC1InstanceTerminal($instance_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing SC1 Instance Terminal');
            $vars['control'] = 'SC1';
            $vars['action'] = 'terminal-session-open';
            $vars['api_uri'] = '/api/sc1/terminal/history';
            $vars['instance_id'] = $instance_id;
            $afterwards = self::applySC1InstanceTerminalResult($instance_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model showSC1InstanceTerminal: ', $result);
            return $result;
        }
        return null ;
    }

    public static function runCommandSC1InstanceTerminal($instance_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $jQuery = \ISOPHP\js_core::$jQuery;
            \ISOPHP\js_core::$console->log('Run Command in SC1 Instance Terminal');
            $vars['control'] = 'SC1';
            $vars['action'] = 'terminal-run-command';
            $vars['api_uri'] = '/api/sc1/terminal/write';
            $vars['instance_id'] = $instance_id;
            $vars['data'] = $jQuery('#command_data')->val() ;
            $vars['history'] = 'true' ;
            $afterwards = self::applySC1InstanceTerminalResult($instance_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model runCommandSC1InstanceTerminal: ', $result);
            return $result;
        }
        return null ;
    }

    public static function applySC1InstanceTerminalResult($instance_id) {
        $func = function ($request_data) use ($instance_id) {
            \ISOPHP\js_core::$console->log('applying our SC1 Terminal Instance result to', $request_data);


            $jQuery = \ISOPHP\js_core::$jQuery;
            \ISOPHP\js_core::$console->log('setting terminal overlay title to fixed position', $instance_id);
            $overlay_title = $jQuery('.progressTitle') ;
            $overlay_title->css('display', 'block') ;
            $overlay_title->css('position', 'fixed') ;
            $overlay_title->css('z-index', '1000') ;
            $overlay_title->css('background-color', 'white') ;

            if ( is_object($request_data) ) {
                \ISOPHP\js_core::$console->log('termres 1');
                \ISOPHP\js_core::$console->log('rt');
                \ISOPHP\js_core::$console->log($request_data->responseText);
                \ISOPHP\js_core::$console->log('rd');
                \ISOPHP\js_core::$console->log($request_data->responseText);
//                if ( isset($request_data->responseText) ) {
                \ISOPHP\js_core::$console->log('termres 2');
                $json_val = \ISOPHP\core::$php->json_encode($request_data->responseText) ;
                \ISOPHP\js_core::$console->log('termres 2 b');
                \ISOPHP\js_core::$console->log($json_val);
                $request_data = \ISOPHP\core::$php->json_decode($json_val, true) ;
                \ISOPHP\js_core::$console->log('termres 2 c');
                \ISOPHP\js_core::$console->log($request_data);
//                }
            }

            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Instance Terminal 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceTerminal.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1Terminal($bind, $instance_id, $request_data);
                \ISOPHP\js_core::$console->log('apply SC1 Instance Terminal 2', $vars);


                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('#overlay_inner') ;
                \ISOPHP\js_core::$console->log('add class overlay_inner_terminal_large to overlay', $instance_id);
                $overlay_inner->addClass('overlay_inner_terminal_large') ;

                $command_field = $jQuery('#command_data');
                \ISOPHP\js_core::$console->log('bound to #command_data', $instance_id);
                $command_field->attr('data-instance-id', $instance_id) ;
                \ISOPHP\js_core::$console->log('#command_data attr set to instance id', $command_field->attr('data-instance-id'));
                $command_field->focus() ;
                \ISOPHP\js_core::$console->log('command field is now focussed');

                $terminal_element = $jQuery('#terminal-console');
                \ISOPHP\js_core::$console->log('binding click to #terminal-console', $instance_id);
                $terminal_element->on('click', function ($jsThis)  {
                    \ISOPHP\js_core::$console->log('terminal console has been clicked', $jsThis);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $command_field = $jQuery('#command_data');
                    $command_field->focus() ;
                    \ISOPHP\js_core::$console->log('command field is now focussed', $jsThis);
                });

                $command_field->on('keyup', function ($jsThis)  {
                    \ISOPHP\js_core::$console->log('$jsThis', $jsThis);
                    \ISOPHP\js_core::$console->log('$jsThis->originalEvent->key', $jsThis->originalEvent->key);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $command_field = $jQuery('#command_data');
                    $instance_id = $command_field->attr('data-instance-id');
                    \ISOPHP\js_core::$console->log('$instance_id', $instance_id);
                    \ISOPHP\js_core::$console->log('key up event', $jsThis->originalEvent->key);
                    if ($jsThis->originalEvent->key === 'Enter' ) {
                        \ISOPHP\js_core::$console->log('Enter has been pressed in command field', $jsThis->originalEvent->key);
                        $command_string = $jQuery('#command_data')->val() ;
                        # todo truncate this string
                        \Core\WindowMessage::showMessage('Executing: ' . $command_string, 'good');
                        self::runCommandSC1InstanceTerminal($instance_id) ;
                    }
                });
            }
            if ($request_data['status'] !== 'OK') {
                \Core\WindowMessage::showMessage($request_data['message'], 'bad');
                \ISOPHP\js_core::$console->log('SC1 Instance Terminal command failed');
                $command_field = $jQuery('#command_data');
                \ISOPHP\js_core::$console->log('bound to #command_data', $instance_id);
                $command_field->attr('data-instance-id', $instance_id) ;
                \ISOPHP\js_core::$console->log('#command_data attr set to instance id', $command_field->attr('data-instance-id'));
                $command_field->focus() ;
                \ISOPHP\js_core::$console->log('command field is now focussed');
            }
        };
        return $func;
    }

    public function showSC1InstanceExecutionLogList($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 ExecutionLog List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/sc1/instance/executionlog/all';
                if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                    $vars['page'] = 1 ;
                } else {
                    $vars['page'] = $pageVars['params']['page'] ;
                }
                $afterwards = $this->applySC1InstanceExecutionLogListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1InstanceExecutionLogListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Instance ExecutionLog List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Instance ExecutionLog List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'InstanceExecutionLogList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Instance ExecutionLog List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 ScheduledInstance ExecutionLog List failed');
            }
        };
        return $func;
    }


    public function showSC1KeypairDropDown($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 Keypair List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list-keypair';
                $vars['api_uri'] = '/api/sc1/keypair/all';
                $afterwards = $this->applySC1KeypairDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1KeypairDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Keypair List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Keypair List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'KeypairDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1KeypairDropdown($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Keypair List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Keypair List failed');
            }
        };
        return $func;
    }

    /**
     * Save instance
     */
    public function saveInstanceAttemptToSC1($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('save instance to sc1');
            $jQuery = \ISOPHP\js_core::$jQuery;
            $vars = array() ;
            $vars['control'] = 'SC1';
            $vars['action'] = 'instance-create';
            $vars['api_uri'] = '/api/sc1/instance/create';
            $vars['instance_name'] = $jQuery('#instance_name')->val();
            $vars['description'] = $jQuery('#description')->val();
            $vars['userdata'] = $jQuery('#userdata')->val();
            $vars['size_slug'] = $jQuery('#size_slug')->val();
            $vars['image_id'] = $jQuery('#image_id')->val();

            if ($jQuery('#keypair_id')->val() !== "none") {
                $vars['keypair'] = $jQuery('#keypair_id')->val();
            }

            if ($jQuery('#internet_enabled')->is(":checked")) {
                $vars['internet_enabled'] = "true";
            }

            if ($jQuery('#egress_enabled')->is(":checked")) {
                $vars['egress_enabled'] = "true";
            }

            $private_network_array = $jQuery('#svpc_name')->val();
            $vars['private_networks'] = \ISOPHP\core::$php->join("," , $private_network_array) ;

//        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
//        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
            $afterwards = $this->saveInstanceResultFromSC1($pageVars);
            $msg = 'Requesting Instance '.$vars['instance_name'];
            \Core\WindowMessage::showMessage($msg, 'good');
            $result = $this->performRequest($vars, $afterwards);
            return $result;
        }

    }

    public function saveInstanceResultFromSC1($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $jQuery = \ISOPHP\js_core::$jQuery;
            $func = function ($request_data) use ($jQuery, $pageVars) {
                \ISOPHP\js_core::$console->log('applying our Save sc1 instance result to', $request_data);
                if ($request_data['status'] === 'OK') {
                    $msg = 'Creation begun of Instance '.$request_data['vm_details']['vm_name'];
                    \Core\WindowMessage::showMessage($msg, 'good');
                    \ISOPHP\js_core::$console->log('sc1 instance saved, data: ', $request_data);
                    $msg_type = 'good' ;
                    $update_url = '/api/sc1/instance/modify_status' ;
                    $vars = array(
                        'vm_id' => $request_data['vm_details']['vm_id'],
                        'mod_id' => $request_data['modification_id'],
                        'vm_name' => $request_data['vm_details']['vm_name'],
                    ) ;
                    $finish_function = function () {
                        $navigate = new \Model\Navigate();
                        $navigate->route('SC1', 'instance-list', array(), '/sc1/instance/list');
                    } ;
                    \Core\WindowMessage::showStatus($msg_type, $vars, $update_url, $finish_function) ;
                    $navigate = new \Model\Navigate();
                    $navigate->route('SC1', 'instance-list', array(), '/sc1/instance/list');
                }
                if ($request_data['status'] !== 'OK') {
                    $msg = 'Failed creation Request for Instance. '.$request_data['message'] ;
                    \Core\WindowMessage::showMessage($msg, 'bad') ;
                    \ISOPHP\js_core::$console->log('sc1 instance save failed') ;
                }
            };
            return $func;
        }
    }

    /**
     * Modify instance
     */
    public static function modifyInstanceAttemptToSC1($vmid, $new_state)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('modify instance to sc1');
            $jQuery = \ISOPHP\js_core::$jQuery;
            $vars = array() ;
            $vars['control'] = 'SC1';
            $vars['action'] = 'instance-state-change';
            $vars['api_uri'] = '/api/sc1/instance/state_change';
            $vars['new_state'] = $new_state ;
            $vars['vm_id'] = $vmid ;
            $afterwards = self::modifyInstanceResultFromSC1($vars);
            $msg = 'Changing Instance '.$vars['instance_name'];
            \Core\WindowMessage::showMessage($msg, 'good');
            $result = self::performRequest($vars, $afterwards);
            return $result;
        }
    }

    public static function modifyInstanceResultFromSC1($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $jQuery = \ISOPHP\js_core::$jQuery;
            $func = function ($request_data) use ($jQuery, $pageVars) {
                \ISOPHP\js_core::$console->log('applying our Modify sc1 instance result to', $request_data);
                if ($request_data['status'] === 'OK') {
                    $msg = 'Instance '.$request_data['vm_name'].' changed to '.$request_data['new_state'] ;
                    \Core\WindowMessage::showMessage($msg, 'good');
                    \ISOPHP\js_core::$console->log('sc1 instance modified, data: ', $request_data);
                    $navigate = new \Model\Navigate();
                    $navigate->route('SC1', 'instance-list', array(), '/sc1/instance/list');
                }
                if ($request_data['status'] !== 'OK') {
                    $msg = 'Failed Modify Request for Instance '.$request_data['vm_name'] ;
                    \Core\WindowMessage::showMessage($msg, 'bad');
                    \ISOPHP\js_core::$console->log('sc1 instance modify failed');
                }
            };
            return $func;
        }

    }

    public function deleteInstanceAttemptToSC1($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('delete volume to sc1');
            \ISOPHP\js_core::$console->log($pageVars);
            $jQuery = \ISOPHP\js_core::$jQuery;
            $vars = array() ;
            $vars['control'] = 'SC1';
            $vars['action'] = 'instance-delete';
            $vars['api_uri'] = '/api/sc1/instance/delete';
            $vars['instance_name'] = $pageVars['params']['instance_name'];
            $vars['instance_id'] = $pageVars['params']['instance_id'];
            \ISOPHP\js_core::$console->log('delete volume vars');
            \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
            $afterwards = $this->deleteInstanceResultFromSC1($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            return $result;
        }
    }

    public function deleteInstanceResultFromSC1($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $jQuery = \ISOPHP\js_core::$jQuery;
            $func = function ($request_data) use ($jQuery, $pageVars) {
                \ISOPHP\js_core::$console->log('applying our Delete sc1 instance result to', $request_data);
                if ($request_data['status'] === 'OK') {
                    $msg = 'Deleted Instance '.$request_data['vm'] ;
                    \Core\WindowMessage::showMessage($msg, 'good');
                    \ISOPHP\js_core::$console->log('sc1 instance deleted, data: ', $request_data);
                    $navigate = new \Model\Navigate();
                    $navigate->route('SC1', 'instance-list', array(), '/sc1/instance/list');
                }
                if ($request_data['status'] !== 'OK') {
                    $msg = 'Failed Deleting Instance '.$request_data['vm'];
                    \Core\WindowMessage::showMessage($msg, 'bad');
                    \ISOPHP\js_core::$console->log('sc1 instance delete failed');
                }
            };
            return $func;
        }
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_sc1_instance_list = $jQuery('.go-sc1-instance-list');
                $go_sc1_instance_list->off() ;
                $go_sc1_instance_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'instance-list', array(), '/sc1/instance/list/');
                });

                $go_sc1_instance_list_page = $jQuery('.sc1-instance-paginator');
                $go_sc1_instance_list_page->off() ;
                $go_sc1_instance_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 instance log paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'instance-list', array('page' => $new_page), '/sc1/instance/list/page/'.$new_page);
                });

                $go_sc1_instance_executionlog_list = $jQuery('.go-sc1-instance-executionlog-list');
                $go_sc1_instance_executionlog_list->off() ;
                $go_sc1_instance_executionlog_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'instance-executionlog-list', array(), '/sc1/instance/executionlog/list');
                });

                $go_sc1_instance_executionlog_list_page= $jQuery('.sc1-instance-executionlog-paginator');
                $go_sc1_instance_executionlog_list_page->off() ;
                $go_sc1_instance_executionlog_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 instance execution log paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'instance-executionlog-list', array('page' => $new_page), '/sc1/instance/executionlog/list/page/'.$new_page);
                });

                $go_sc1_instance_create = $jQuery('.go-sc1-instance-create');
                $go_sc1_instance_create->off() ;
                $go_sc1_instance_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'instance-create', array(), '/sc1/instance/create');
                });

                $save_instance = $jQuery('.attempt-instance-create');
                $save_instance->off() ;
                $save_instance->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'instance-save-attempt', array());
                });

                $instance_edit = $jQuery('.attempt-instance-edit');
                $instance_edit->off() ;
                $instance_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $instance_name = $jqThis->attr('data-sc1-instance-name') ;
                    $instance_id = $jqThis->attr('data-sc1-instance-id') ;
                    $instance_state = $jqThis->attr('data-sc1-instance-state') ;
                    \ISOPHP\js_core::$console->log('Clicked instance edit button');
                    $msg = "Editing Instance $instance_name.";
                    $data  = '<div class="col-sm-12 overlay-col">' ;
                    $data .= '  <h3>State: '.\ISOPHP\core::$php->ucfirst($instance_state).'</h3>' ;
                    $data .= '</div>' ;
                    $data .= '<div class="col-sm-12 overlay-col">' ;
                    if ($instance_state == 'stopped') {
                        $data .= '  <div class="col-sm-6 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_start" class="fa fa-2x fa-play btn-outline-info instance_modify instance_start"></i>' ;
                        $data .= '  </div>' ;
                        $data .= '  <div class="col-sm-6 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>' ;
                        $data .= '  </div>' ;
                    }
                    if ($instance_state == 'running') {
                        $data .= '  <div class="col-sm-4 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_stop" class="fa fa-2x fa-stop btn-outline-info instance_modify instance_stop"></i>' ;
                        $data .= '  </div>' ;
                        $data .= '  <div class="col-sm-4 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_pause" class="fa fa-2x fa-pause btn-outline-info instance_modify instance_pause"></i>' ;
                        $data .= '  </div>' ;
                        $data .= '  <div class="col-sm-4 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>' ;
                        $data .= '  </div>' ;
                    }
                    if ($instance_state == 'paused') {
                        $data .= '  <div class="col-sm-6 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_resume" class="fa fa-2x fa-resume btn-outline-info instance_modify instance_resume"></i>' ;
                        $data .= '  </div>' ;
                        $data .= '  <div class="col-sm-6 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>' ;
                        $data .= '  </div>' ;
                    }
                    if ($instance_state == 'poweredoff') {
                        $data .= '  <div class="col-sm-12 overlay-col">' ;
                        $data .= '    <i id="modify_'.$instance_id.'_poweron" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweron"></i>' ;
                        $data .= '  </div>' ;
                    }
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-12 overlay-col">' ;
                    $data .= '    <h3>Console:</h3>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-12 overlay-col">' ;
                    $data .= '    <span class="col-sm-12 btn btn-outline-primary terminal-open-button"
                                        data-sc1-instance-id="'.$instance_id.'" ' ;
                    $data .= '          id="terminal-open-button" >' ;
                    $data .= '      <i id="open_console_'.$instance_id.'" class="fa fa-2x fa-terminal btn-outline-info instance_console instance_console_open"></i>' ;
                    $data .= '      Open Terminal' ;
                    $data .= '    </span>' ;
                    $data .= '  </div>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);

                    $terminal_open = $jQuery('#terminal-open-button');
                    $terminal_open->off() ;
                    $terminal_open->on('click', function ($jsthis) use ($navigate, $jQuery, $instance_id) {
                        $jsthis->preventDefault();
                        $this_id = $jsthis->currentTarget->id;
                        $jqThis = $jQuery('#'.$this_id) ;

                        $data  = '<div class="col-sm-12 overlay-col" id="sc1_terminal">' ;
                        $data .= '    <link rel="stylesheet" type="text/css" href="/app/SC1/Assets/css/code-terminal.css">' ;
                        $data .= '    <script type="text/javascript" src="/app/SC1/Assets/js/console.js"></script>' ;
                        $data .= '    <div class="col-sm-12 terminal-console" id="terminal-console">' ;
                        $data .= '      <pre>Welcome to your Terminal</pre>' ;
                        $data .= '      <br />' ;
                        $data .= '      <br />' ;
                        $data .= '      <pre>' ;
                        $data .= '        Loading' ;
                        $data .= '      </pre>' ;
                        $data .= '    </div>' ;
                        $data .= '</div>' ;

                        $msg = "Terminal Console for Instance ID: $instance_id" ;
                        \Core\WindowMessage::showOverlay($msg, $data);
                        \ISOPHP\js_core::$console->log('Showing terminal overlay');

                        \ISOPHP\js_core::$console->log('Clicked terminal open button');
                        self::showSC1InstanceTerminal($instance_id) ;

                    } ) ;

                    $instance_modify = $jQuery('.instance_modify');
                    $instance_modify->off() ;
                    $instance_modify->on('click', function ($jsthis) use ($instance_name, $instance_id, $jQuery) {
                        $jsthis->preventDefault();
                        $this_id = $jsthis->currentTarget->id;
                        $jqThis = $jQuery('#'.$this_id) ;
                        $new_state = 'none' ;
                        if ($jqThis->hasClass('instance_start') == true) {
                            $new_state = 'start' ;
                        } elseif ($jqThis->hasClass('instance_stop') == true) {
                            $new_state = 'stop' ;
                        } elseif ($jqThis->hasClass('instance_pause') == true) {
                            $new_state = 'pause' ;
                        } elseif ($jqThis->hasClass('instance_resume') == true) {
                            $new_state = 'resume' ;
                        } elseif ($jqThis->hasClass('instance_poweroff') == true) {
                            $new_state = 'poweroff' ;
                        } elseif ($jqThis->hasClass('instance_poweron') == true) {
                            $new_state = 'poweron' ;
                        }
                        if ($new_state == 'none') {
                            $msg = 'Unable to find target state' ;
                            \Core\WindowMessage::showMessage($msg, 'bad');
                            return false ;
                        }
                        \ISOPHP\js_core::$console->log('Clicked instance modify button');
                        $msg = "You are about to modify the Instance $instance_name to $new_state.<br>Are you sure ?";
                        $confirmAction = function () use ($instance_id, $new_state) {
                            self::modifyInstanceAttemptToSC1($instance_id, $new_state) ;
                        };
                        \Core\WindowMessage::areYouSure($msg, $confirmAction);
                    } ) ;
                } ) ;

                $instance_info = $jQuery('.attempt-instance-info');
                $instance_info->off() ;
                $instance_info->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $instance_name = $jqThis->attr('data-sc1-instance-name') ;
                    $instance_id = $jqThis->attr('data-sc1-instance-id') ;
                    $instance_state = $jqThis->attr('data-sc1-instance-state') ;
                    \ISOPHP\js_core::$console->log('Clicked instance info button');
                    $msg = "Instance Details for: $instance_name.";
                    $data  = '<div class="col-sm-12">' ;
                    $data .= '  <h3>State: '.\ISOPHP\core::$php->ucfirst($instance_state).'</h3>' ;
                    $data .= '</div>' ;
                    $data .= '<div class="col-sm-12" id="sc1_single_instance_details">' ;
                    $data .= '  ' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
                    self::showSC1SingleInstanceDetails($instance_id) ;
                } ) ;

                $instance_delete = $jQuery('.attempt-instance-delete');
                $instance_delete->off() ;
                $instance_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $instance_name = $jqThis->attr('data-sc1-instance-name') ;
                    $instance_id = $jqThis->attr('data-sc1-instance-id') ;
                    \ISOPHP\js_core::$console->log('Clicked instance delete button');
                    $msg = "You are about to delete the Instance $instance_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $instance_id, $instance_name) {
                        $navigate->route('SC1', 'instance-delete-attempt', array(
                            'instance_id' => $instance_id,
                            'instance_name' => $instance_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        };
    }


}