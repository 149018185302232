<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = $html .'
                 <div class="fullRow overlay_invoice">
                 
                 <div class="fullRow header">
                 
                     <div class="two_column">                     
                        <div class="two_column">
                            <h5 class="billing_usage_history_id_title">Period</h5>
                        </div>
                        <div class="two_column">' ;
    $html = $html . $rqd['invoice']['period_format']  ;
    $html = $html .
                       '</div>                        
                     </div>

                     <div class="two_column">
                        <div class="two_column">
                            <h5 class="billing_usage_history_id_title">Amount</h5>
                        </div>
                        <div class="two_column">
                           <strong>' ;
    $html = $html . '£'.$rqd['invoice']['amount']  ;
    $html = $html .     '  </strong>
                        </div>     
                                           
                    </div>
                    
                 </div> ';


    $html = $html . '  <div class="fullRow subtitle">' ;
    $html = $html . '    <h5>Items: </h5>' ;
    $html = $html . '  </div>' ;

    $html = $html . '<div class="fullRow invoice_item_table">' ;
    $html = $html . '  <div class="bill_cell bill_cell_entity">' ;
    $html = $html . '    <strong>Entity</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="bill_cell bill_cell_charge_type_id">' ;
    $html = $html . '    <strong>Type</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="bill_cell bill_cell_entity_id">' ;
    $html = $html . '    <strong>ID</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="bill_cell bill_cell_charge_period_start_friendly">' ;
    $html = $html . '    <strong>Start</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="bill_cell bill_cell_total_charge_period">' ;
    $html = $html . '    <strong>Period</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="bill_cell bill_cell_total_charge">' ;
    $html = $html . '    <strong>Charge</strong>' ;
    $html = $html . '  </div>' ;
    $html = $html . '</div>' ;

    $html = $html . '  <div class="fullRow invoice_item_table">' ; # overlay_invoice

    foreach ($rqd['invoice']['chargeable_items'] as $one_invoiced_item) {

        $html = $html . '<div class="fullRow subtitle">' ;
        $html = $html . '  <div class="bill_cell bill_cell_entity">' ;
        $html = $html . '    '.$one_invoiced_item['entity'] ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="bill_cell bill_cell_charge_type_id">' ;
        $html = $html . '    '.$one_invoiced_item['charge_type_id'] ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="bill_cell bill_cell_entity_id">' ;
        $html = $html . '    '.$one_invoiced_item['entity_id'] ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="bill_cell bill_cell_charge_period_start_friendly">' ;
        $html = $html . '    '.$one_invoiced_item['charge_period_start_friendly'] ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="bill_cell bill_cell_total_charge_period">' ;
        $html = $html . '    '.$one_invoiced_item['total_charge_period'] ;
        $html = $html . '&nbsp;&nbsp;'.$one_invoiced_item['charge_period_unit'].'s' ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="bill_cell bill_cell_total_charge">' ;
        $html = $html . '    £'.$one_invoiced_item['total_charge'] ;
        $html = $html . '  </div>' ;
        $html = $html . '</div>' ;
    }

    $html = $html . '  </div>' ;

    if ($rqd['invoice']['truncated_items'] === true) {
        $html = $html . '  <div class="fullRow">' ;
        $html = $html . '    Items have been truncated, from ' . $rqd['invoice']['original_count'] ;
        $html = $html . '  </div>' ;
    }

    $html = $html . '  <div class="fullRow">' ;
    $html = $html . '    &nbsp;';
    $html = $html . '  </div>' ;

    $html = $html . '  </div>' ; # overlay_invoice

    return $html;

};
