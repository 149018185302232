<?php

Namespace Model\SC1;

use ISOPHP\core;

class KeypairPageModel extends \Model\Base {


    public function showSC1KeypairCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'KeypairCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Keypair Create 2', $vars);
        };
    }

    public function showSC1KeypairList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/keypair/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1KeypairListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1KeypairListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Keypair List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Keypair List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'KeypairList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Keypair List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Keypair List failed');
            }
        };
        return $func;
    }

    /**
     * Save keypair
     */
    public function saveKeypairAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save keypair to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'keypair-create';
        $vars['api_uri'] = '/api/sc1/keypair/create';
        $vars['name'] = $jQuery('#name')->val();
        $vars['base64'] = true ;
        $new_key_data = $jQuery('#new_key_data')->val() ;
        $vars['new_key_data'] = \ISOPHP\js_core::$window->btoa($new_key_data);
//        $vars['new_key_data'] = \ISOPHP\js_core::$window->encodeURIComponent($new_key_data) ;
        $vars['description'] = $jQuery('#description')->val() ;
        $vars['serialized'] = true ;
        \ISOPHP\js_core::$console->log('save keypair vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveKeypairResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveKeypairResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 keypair result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Keypair '.$request_data['keypair']['name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 keypair saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'keypair-list', array(), '/sc1/keypair/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Keypair '.$request_data['keypair']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 keypair save failed');
            }
        };
        return $func;
    }
    /**
     * Delete keypair
     */
    public function deleteKeypairAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete keypair to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'keypair-delete';
        $vars['api_uri'] = '/api/sc1/keypair/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete keypair vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteKeypairResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteKeypairResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 keypair result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Keypair '.$request_data['keypair'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 keypair deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'keypair-list', array(), '/sc1/keypair/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Keypair '.$request_data['keypair']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 keypair delete failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding keypair buttons');

                # Keypairs

                $go_sc1_keypair_list = $jQuery('.go-sc1-keypair-list');
                $go_sc1_keypair_list->off() ;
                $go_sc1_keypair_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'keypair-list', array(), '/sc1/keypair/list');
                });

                $go_sc1_keypair_list_page = $jQuery('.sc1-keypair-paginator');
                $go_sc1_keypair_list_page->off() ;
                $go_sc1_keypair_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 keypair paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'keypair-list', array('page' => $new_page), '/sc1/keypair/list/page/'.$new_page);
                });

                $go_sc1_keypair_create = $jQuery('.go-sc1-keypair-create');
                $go_sc1_keypair_create->off() ;
                $go_sc1_keypair_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'keypair-create', array(), '/sc1/keypair/create');
                });

                $save_keypair = $jQuery('.attempt-keypair-create');
                $save_keypair->off() ;
                $save_keypair->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'keypair-save-attempt', array());
                });

                $keypair_delete = $jQuery('.attempt-keypair-delete');
                $keypair_delete->off() ;
                $keypair_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $keypair_name = $jqThis->attr('data-sc1-keypair-name') ;
                    $keypair_id = $jqThis->attr('data-sc1-keypair-id') ;
                    \ISOPHP\js_core::$console->log('Clicked keypair delete button');
                    $msg = "You are about to delete the Key Pair $keypair_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $keypair_id, $keypair_name) {
                        $navigate->route('SC1', 'keypair-delete-attempt', array(
                            'id' => $keypair_id,
                            'name' => $keypair_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;
            }
        } ;
    }

}