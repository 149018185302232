<?php

Namespace Controller;

class GenerativeAIController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('GenerativeAI controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In GenerativeAI, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\GenerativeAI\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'GenerativeAI.php';
            $res->type = 'view';
            $res->view_control = 'GenerativeAI';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showGenerativeAILanding($pageVars);
            } else if ($pageVars['route']['action'] == 'bucket-list') {
                $res->post_template[] = $page_model->showGenerativeAIBucketList($pageVars);
            } else if ($pageVars['route']['action'] == 'bucket-create') {
                $res->post_template[] = $page_model->showGenerativeAIBucketCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
//                $res->post_template[] = $page_model->showGenerativeAIBucketListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindBucketListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showGenerativeAIObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'object-create') {
                $res->post_template[] = $page_model->showGenerativeAIObjectCreate($pageVars);
//                $res->post_template[] = $page_model->showGenerativeAIBucketListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindBucketListDropDownButtonsForObjectCreate();
            } else if ($pageVars['route']['action'] == 'bucket-save-attempt') {
                $page_model->saveBucketAttemptToGenerativeAI($pageVars);
                $res->view = 'GenerativeAI.php';
                $res->control = 'GenerativeAI';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToGenerativeAI($pageVars);
                $res->view = 'GenerativeAI.php';
                $res->control = 'GenerativeAI';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'bucket-delete-attempt') {
                $page_model->deleteBucketAttemptToGenerativeAI($pageVars);
                $res->view = 'GenerativeAI.php';
                $res->control = 'GenerativeAI';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToGenerativeAI($pageVars);
                $res->view = 'GenerativeAI.php';
                $res->control = 'GenerativeAI';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-download-attempt') {
                $page_model->downloadObjectAttemptFromGenerativeAI($pageVars);
                $res->view = 'GenerativeAI.php';
                $res->control = 'GenerativeAI';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showGenerativeAILanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('GenerativeAI Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}