<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-natgateway-create" class="btn btn-success attempt-natgateway-create">Create</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                
                    <div>
                        <h4>New SVPC NAT Gateway</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>VPC</label>
                            <div id="svpc_vpc_dropdown">
                                <select class="form-control" name="svpc_name" id="svpc_name">
                                    <option>Loading SVPCs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Subnet</label>
                            <div id="svpc_subnet_dropdown">
                                <select class="form-control" name="subnet_id" id="subnet_id">
                                    <option>Please Choose a VPC...</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Static IP</label>
                            <select name="public_ip_enable" id="public_ip_enable">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-natgateway-create" class="btn btn-success attempt-natgateway-create">Create</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    return $html;

};
