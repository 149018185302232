<?php

Namespace Model\SC1;

use ISOPHP\core;

class StaticIPPageModel extends \Model\Base {


    public function showSC1StaticIPCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'StaticIPCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 StaticIP Create 2', $vars);
        };
    }

    public function showSC1StaticIPList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/staticip/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1StaticIPListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1StaticIPListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 StaticIP List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 StaticIP List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'StaticIPList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 StaticIP List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 StaticIP List failed');
            }
        };
        return $func;
    }

    /**
     * Save staticip
     */
    public function saveStaticIPAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save staticip to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'staticip-create';
        $vars['api_uri'] = '/api/sc1/staticip/create';
        $vars['sc1_instance_id'] = $jQuery('#vm_id')->val();
        $vars['staticip_name'] = $jQuery('#staticip_name')->val();
        \ISOPHP\js_core::$console->log('save staticip vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveStaticIPResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveStaticIPResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 staticip result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created StaticIP for Instance '.$request_data['staticip']['instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 staticip saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'staticip-list', array(), '/sc1/staticip/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating StaticIP '.$request_data['staticip']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 staticip save failed');
            }
        };
        return $func;
    }
    /**
     * Delete staticip
     */
    public function deleteStaticIPAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete staticip to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'staticip-delete';
        $vars['api_uri'] = '/api/sc1/staticip/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete staticip vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteStaticIPResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteStaticIPResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 staticip result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Static IP '.$request_data['staticip']['id'].' from Instance '.$request_data['staticip']['instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 staticip deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'staticip-list', array(), '/sc1/staticip/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Static IP from Instance '.$request_data['staticip']['instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 staticip delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete staticip
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding staticip buttons');
                # Static IP

                $go_sc1_staticip_list = $jQuery('.go-sc1-staticip-list');
                $go_sc1_staticip_list->off() ;
                $go_sc1_staticip_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'staticip-list', array(), '/sc1/staticip/list');
                });

                $go_sc1_staticip_list_page = $jQuery('.sc1-staticip-paginator');
                $go_sc1_staticip_list_page->off() ;
                $go_sc1_staticip_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 staticip paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'staticip-list', array('page' => $new_page), '/sc1/staticip/list/page/'.$new_page);
                });

                $go_sc1_staticip_create = $jQuery('.go-sc1-staticip-create');
                $go_sc1_staticip_create->off() ;
                $go_sc1_staticip_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'staticip-create', array(), '/sc1/staticip/create');
                });

                $save_staticip = $jQuery('.attempt-staticip-create');
                $save_staticip->off() ;
                $save_staticip->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'staticip-save-attempt', array());
                });

                $staticip_delete = $jQuery('.attempt-staticip-delete');
                $staticip_delete->off() ;
                $staticip_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $staticip_name = $jqThis->attr('data-sc1-staticip-name') ;
//                    $staticip_id = $jqThis->attr('data-sc1-staticip-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked staticip delete button');
//                    if ($staticip_name !== '') {
//                        $name_or_id = $staticip_name ;
//                    } else {
//                        $name_or_id = $staticip_id ;
//                    }
//                    $msg = "You are about to delete the Static IP $name_or_id. This cannot be undone.<br />Are you sure ?";
//                    $confirmAction = function () use ($navigate, $staticip_id, $staticip_name) {
//                        $navigate->route('SC1', 'staticip-delete-attempt', array(
//                            'id' => $staticip_id,
//                            'name' => $staticip_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }
}