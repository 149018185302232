<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Simple Mini Proxy</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Public Network Port Endpoints for Private VM&apos;s</h3>
                        <p>Network entries you can use.</p>            
                    </div>
                    
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-smp-record-list" class="go-smp-record-list btn btn-lg btn-warning">Records</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-smp-record-create" class="go-smp-record-create btn btn-lg btn-warning">Create Record</span>
                        </div>          
                    </div>
                    
                </div>
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Health Checks</h3>
                        <p>Ensure Your Mini Proxy Records are working as expected.</p>          
                    </div>
                    
                    <div class="col-sm-12">
                   
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                        <div class="col-sm-6">
                            <span id="go-smp-healthcheck-list" class="btn btn-lg btn-warning go-smp-healthcheck-list">Health Checks</span>
                        </div>
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                                        
                    </div>
                </div>

            </div>
          
        </div>';

    return $html ;

} ;