<?php

Namespace Model\SVPC;

use ISOPHP\core;

class NATGatewayPageModel extends \Model\Base
{

    public function showSVPCNATGatewayCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'NATGatewayCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC NATGateway Create 2', $vars);
        };
    }

    public function showSVPCNATGatewayList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/natgateway/all';
            $afterwards = $this->applySVPCNATGatewayListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCNATGatewayListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC NATGateway List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC NATGateway List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'NATGatewayList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC NATGateway List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC NATGateway List failed');
            }
        };
        return $func;
    }



    /**
     * Save natgateway
     */
    public function saveNATGatewayAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save natgateway to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'natgateway-create';
        $vars['api_uri'] = '/api/svpc/natgateway/create';
        $vars['vpc_id'] = $jQuery('#svpc_name')->val();
        $vars['public_ip_enable'] = $jQuery('#public_ip_enable')->val();
        \ISOPHP\js_core::$console->log('save natgateway vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveNATGatewayResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveNATGatewayResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc natgateway result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created NATGateway '.$request_data['natgateway']['svpc_natgateway_name'].' for SVPC '.$request_data['natgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc natgateway saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating NATGateway '.$request_data['natgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc natgateway save failed');
            }
        };
        return $func;
    }


    /**
     * Delete natgateway
     */
    public function deleteNATGatewayAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete natgateway to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'natgateway-delete';
        $vars['api_uri'] = '/api/svpc/natgateway/delete';
        $vars['svpc_natgateway_id'] = $jQuery('#svpc_natgateway_id')->val();
        \ISOPHP\js_core::$console->log('delete natgateway vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveNATGatewayResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteNATGatewayResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc natgateway result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted NATGateway '.$request_data['natgateway']['svpc_natgateway_name'].' for SVPC '.$request_data['natgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc natgateway deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'natgateway-list', array(), '/svpc/natgateway/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting NATGateway '.$request_data['natgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc natgateway delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons NAT Gateway');
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });

                $go_svpc_natgateway_list = $jQuery('.go-svpc-natgateway-list');
                $go_svpc_natgateway_list->off() ;
                $go_svpc_natgateway_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'natgateway-list', array(), '/svpc/natgateway/list');
                });

                $go_svpc_natgateway_create = $jQuery('.go-svpc-natgateway-create');
                $go_svpc_natgateway_create->off() ;
                $go_svpc_natgateway_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'natgateway-create', array(), '/svpc/natgateway/create');
                });

                $save_natgateway = $jQuery('.attempt-natgateway-create');
                $save_natgateway->off() ;
                $save_natgateway->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'natgateway-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $natgateway_delete = $jQuery('.attempt-natgateway-delete');
                $natgateway_delete->off() ;
                $natgateway_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $natgateway_name = $jqThis->attr('data-svpc-natgateway-name') ;
//                    $natgateway_id = $jqThis->attr('data-svpc-natgateway-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked natgateway delete button');
//                    $msg = "You are about to delete the NAT Gateway for $natgateway_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $natgateway_id) {
//                        $navigate->route('SVPC', 'natgateway-delete-attempt', array('natgateway_id' => $natgateway_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

            }
        };
    }


}