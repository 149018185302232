<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Cost Prediction, where you can gauge your future spend.</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Simple, transparent and quick - plan your outgoings</h3>
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-costprediction-prediction-list" class="go-costprediction-prediction-list btn btn-lg btn-warning">Predictions</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-costprediction-prediction-create" class="go-costprediction-prediction-create btn btn-lg btn-warning">New Prediction</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;