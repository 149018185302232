<?php

Namespace Model\Analytics;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Analytics List of Piranha Web Services';
        $page['heading'] = 'List of Analytics';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Analytics Mod', $page, $pageVars);
        return $page;
    }

    public function showAnalyticsLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Analytics Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\AnalyticsView();
            $view->loadTemplate('Analytics', 'AnalyticsLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayAnalytics($bind);
            \ISOPHP\js_core::$console->log('apply Analytics Application Create 2', $vars);
        };
    }

    public function showAnalyticsApplicationCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Analytics Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\AnalyticsView();
            $view->loadTemplate('Analytics', 'ApplicationCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayAnalytics($bind);
            \ISOPHP\js_core::$console->log('apply Analytics Application Create 2', $vars);
        };
    }

    public function showAnalyticsApplicationList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Analytics List');
            $vars['control'] = 'Analytics';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/analytics/application/all';
            $afterwards = $this->applyAnalyticsApplicationListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Analytics Model: ', $result);
            return $result;
        };
    }


    public function showAnalyticsApplicationAccessList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Analytics List');
            $vars['control'] = 'Analytics';
            $vars['action'] = 'application-list';
            $vars['api_uri'] = '/api/analytics/application/access/all';
            $vars['application_id'] = $pageVars['params']['application_id'];
            $afterwards = $this->applyAnalyticsApplicationAccessListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Analytics Model: ', $result);
            return $result;
        };
    }

    public function applyAnalyticsApplicationAccessListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Analytics Application Access List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Analytics Application Access List 1', $vars);
                $view = new \View\AnalyticsView();
                $view->loadTemplate('Analytics', 'ApplicationAccessList.php', $vars);
                $bind = self::bindButtons();
                $view->displayAnalytics($bind);
                \ISOPHP\js_core::$console->log('apply Analytics Application Access List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Analytics Application Access List failed');
            }
        } ;
        return $func ;
    }

    public function applyAnalyticsApplicationListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Analytics Application List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Analytics Application List 1', $vars);
                $view = new \View\AnalyticsView();
                $view->loadTemplate('Analytics', 'ApplicationList.php', $vars);
                $bind = self::bindButtons();
                $view->displayAnalytics($bind);
                \ISOPHP\js_core::$console->log('apply Analytics Application List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Analytics Application List failed');
            }
        };
        return $func;
    }

    public function showAnalyticsApplicationListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Analytics List');
            $vars['control'] = 'Analytics';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/analytics/application/all';
            $afterwards = $this->applyAnalyticsApplicationListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Analytics Model: ', $result);
            return $result;
        };
    }

    public function applyAnalyticsApplicationListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Analytics Application List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Analytics Application List 1', $vars);
                $view = new \View\AnalyticsView();
                $view->loadTemplate('Analytics', 'ApplicationListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayAnalyticsApplicationDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Analytics Application List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Analytics Application List failed');
            }
        };
        return $func;
    }


    public static function showAnalyticsSingleApplicationDetails($application_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing SC1 Single Application');
            $vars['control'] = 'Analytics';
            $vars['action'] = 'application-one';
            $vars['api_uri'] = '/api/analytics/application/one';
            $vars['application_id'] = $application_id;
            $afterwards = self::applyAnalyticsSingleApplicationResult($application_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Analytics Model: ', $result);
            return $result;
        }
    }

    public static function applyAnalyticsSingleApplicationResult($repository_id) {
        $func = function ($request_data) use ($repository_id) {
            \ISOPHP\js_core::$console->log('applying our Analytics Single Application result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Analytics Single Instance List 1', $vars);
                $view = new \View\AnalyticsView();
                $view->loadTemplate('Analytics', 'SingleApplicationDetails.php', $vars);
                $bind = self::bindButtons();
                $view->displayAnalyticsSingleApplicationDetails($bind);
                \ISOPHP\js_core::$console->log('apply Analytics Single repository List 2', $vars);

                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;

            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Analytics Single repository List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);
            return true;
        };
    }

    /**
     * Save application
     */
    public function saveApplicationAttemptToAnalytics($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save application to analytics');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Analytics';
        $vars['action'] = 'application-create';
        $vars['api_uri'] = '/api/analytics/application/create';
        $vars['name'] = $jQuery('#name')->val();
        $vars['url'] = $jQuery('#url')->val();
        $vars['description'] = $jQuery('#description')->val();
        $afterwards = $this->saveApplicationResultFromAnalytics($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveApplicationResultFromAnalytics($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save analytics application result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Application: '.$request_data['application']['name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('analytics application saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Analytics', 'application-list', array(), '/analytics/application/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Application: '.$request_data['application']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('analytics application save failed');
            }
        };
        return $func;
    }


    /**
     * Delete application
     */
    public function deleteApplicationAttemptToAnalytics($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete application to analytics');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Analytics';
        $vars['action'] = 'application-delete';
        $vars['api_uri'] = '/api/analytics/application/delete';
        $vars['application_name'] = $pageVars['params']['application_name'];
        \ISOPHP\js_core::$console->log('delete application vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteApplicationResultFromAnalytics($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteApplicationResultFromAnalytics($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete analytics application result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Application '.$request_data['application']['analytics_application_name'].' for Application '.$request_data['application']['analytics_application_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('analytics application deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Analytics', 'application-list', array(), '/analytics/application/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Application '.$request_data['application']['analytics_application_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('analytics application delete failed');
            }
            $show_application_list = self::showAnalyticsApplicationList($pageVars) ;
            $show_application_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_analytics_landing = $jQuery('.go-analytics-landing');
                $go_analytics_landing->off() ;
                $go_analytics_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'landing', array(), '/analytics');
                });

                $go_analytics_application_list = $jQuery('.go-analytics-application-list');
                $go_analytics_application_list->off() ;
                $go_analytics_application_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'application-list', array(), '/analytics/application/list');
                });

                $go_analytics_application_access_list = $jQuery('.go-analytics-application-access-list');
                $go_analytics_application_access_list->off() ;
                $go_analytics_application_access_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $app_id = $jqThis->attr('data-analytics-application-id') ;
                    $navigate->route('Analytics', 'application-access-list', array(
                        'application_id' => $app_id
                    ), '/analytics/application/'.$app_id.'/access/list');
                });

                $go_analytics_application_create = $jQuery('.go-analytics-application-create');
                $go_analytics_application_create->off() ;
                $go_analytics_application_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'application-create', array(), '/analytics/application/create');
                });

                $save_application = $jQuery('.attempt-application-create');
                $save_application->off() ;
                $save_application->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'application-save-attempt', array());
                });

                $go_analytics_object_list = $jQuery('.go-analytics-object-list');
                $go_analytics_object_list->off() ;
                $go_analytics_object_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'object-list', array(), '/analytics/object/list');
                });

                $go_analytics_object_create = $jQuery('.go-analytics-object-create');
                $go_analytics_object_create->off() ;
                $go_analytics_object_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'object-create', array(), '/analytics/object/create');
                });

                $save_object = $jQuery('.attempt-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Analytics', 'object-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-analytics-object-name') ;
                    $object_id = $jqThis->attr('data-analytics-object-id') ;
                    $application_name = $jqThis->attr('data-analytics-application-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg = "You are about to delete the Object for $object_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $application_name) {
                        $navigate->route('Analytics', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'application_name' => $application_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $application_delete = $jQuery('.attempt-application-delete');
                $application_delete->off() ;
                $application_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $application_name = $jqThis->attr('data-analytics-application-name') ;
                    \ISOPHP\js_core::$console->log('Clicked application delete button');
                    $msg = "You are about to delete the Application $application_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $application_name) {
                        $navigate->route('Analytics', 'application-delete-attempt', array(
                            'application_name' => $application_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $application_info = $jQuery('.attempt-application-info');
                $application_info->off() ;
                $application_info->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $application_name = $jqThis->attr('data-analytics-application-name') ;
                    $application_id = $jqThis->attr('data-analytics-application-id') ;
                    \ISOPHP\js_core::$console->log('Clicked application info button');
                    $msg   = "Application Details for: $application_name";
                    $data  = '' ;
                    $data .= '<div class="col-sm-12" id="analytics_single_application_details">' ;
                    $data .= '  <h3>Loading ...</h3>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
                    self::showAnalyticsSingleApplicationDetails($application_id) ;
                } ) ;

            }
        };
    }

    public function bindApplicationListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding application switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding application switch');
            $switch_application = $jQuery('#application_name');
            $switch_application->off() ;
            $switch_application->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#application_name') ;
                $application_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked application switch');
                $navigate->route('Analytics', 'object-list', array(
                    'application_name' => $application_name,
                ));
            } ) ;

        };
        return $func;
    }



}