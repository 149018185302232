<?php

Namespace Core;

class Router
{

    private $bootstrapParams;
    private $route;
    private $availableRoutes = array();
    private $ucase_controls = array();

    public function run($bootstrapParams)
    {
        // \ISOPHP\js_core::$console->log('Running router with the following') ;
        // \ISOPHP\js_core::$console->log($bootstrapParams) ;
        $this->bootstrapParams = $bootstrapParams;
        $this->setCurrentRoute();
        // \ISOPHP\js_core::$console->log('route is:', $this->route) ;
        return $this->route;
    }

    private function setCurrentRoute()
    {
        $this->getAvailableRoutes();
        // \ISOPHP\js_core::$console->log('Router 1') ;
        $this->getUcaseControls();
        // \ISOPHP\js_core::$console->log('Router 1') ;
        $this->parseControllerAliases();
        // \ISOPHP\js_core::$console->log('Router 2') ;
        $this->setRouteController();
        // \ISOPHP\js_core::$console->log('Router 3') ;
        $this->setRouteAction();
        // \ISOPHP\js_core::$console->log('Router 4') ;
        $this->setRouteExtraParams();
        // \ISOPHP\js_core::$console->log('Router 5') ;
    }

    private function getAvailableRoutes() {
        $this->availableRoutes = array(
            "LandingPage" => array("show"),
            "Login" => array("show", "attempt"),
            "Logout" => array("now"),
            "ComingSoon" => array("show"),
            "CostPrediction" => array(
                'show', 'landing',
                'prediction-create', 'prediction-list', 'prediction-save-attempt', 'prediction-delete-attempt',
                'prediction-display'
            ),
            "Billing" => array(
                'show', 'landing',
                'paymentmethod-create', 'paymentmethod-list', 'paymentmethod-save-attempt', 'paymentmethod-delete-attempt',
                'invoice-create', 'invoice-list', 'invoice-chargeableitem-list', 'invoice-save-attempt', 'invoice-delete-attempt',
                'usage-history', 'csvinvoice-download-attempt', 'pdfinvoice-download-attempt'
            ),
            "SC1" => array(
                'show', 'landing', 'size-list', 'image-list', 'image-create',
                'instance-list', 'instance-executionlog-list', 'instance-details-display', 'instance-create', 'instance-save-attempt', 'instance-delete-attempt',
                'volume-create', 'volume-list', 'volume-save-attempt', 'volume-delete-attempt',
                'scheduledinstance-create', 'scheduledinstance-list', 'scheduledinstance-save-attempt',
                'scheduledinstance-delete-attempt', 'scheduledinstance-executionlog-list',
                'capacityreservation-create', 'capacityreservation-list', 'capacityreservation-save-attempt', 'capacityreservation-delete-attempt',
                'staticip-create', 'staticip-list', 'staticip-save-attempt', 'staticip-delete-attempt',
                'securitygroup-create', 'securitygroup-list', 'securitygroup-save-attempt', 'securitygroup-delete-attempt',
                'securitygroup-rule-inbound-create', 'securitygroup-rule-inbound-delete', 'securitygroup-rule-outbound-create', 'securitygroup-rule-outbound-delete',
                'launchtemplate-create', 'launchtemplate-edit', 'launchtemplate-list', 'launchtemplate-save-attempt', 'launchtemplate-update-attempt', 'launchtemplate-delete-attempt',
                'backup-create', 'backup-list', 'backup-save-attempt', 'backup-delete-attempt',
                'snapshot-create', 'snapshot-list', 'snapshot-save-attempt', 'snapshot-delete-attempt', 'snapshot-restore-attempt',
                'keypair-create', 'keypair-list', 'keypair-save-attempt', 'keypair-delete-attempt',
                'networkinterface-create', 'networkinterface-list', 'networkinterface-save-attempt', 'networkinterface-delete-attempt',
                'loadbalancer-create', 'loadbalancer-list', 'loadbalancer-executionlog-list', 'loadbalancer-save-attempt', 'loadbalancer-delete-attempt',
                'balancedgroup-create', 'balancedgroup-list', 'balancedgroup-save-attempt', 'balancedgroup-delete-attempt',
                'scaledinstancegroup-create', 'scaledinstancegroup-list', 'scaledinstancegroup-executionlog-list', 'scaledinstancegroup-save-attempt', 'scaledinstancegroup-delete-attempt',
                'launchconfiguration-create', 'launchconfiguration-list', 'launchconfiguration-save-attempt', 'launchconfiguration-delete-attempt'
            ),
            "SS3" => array(
                'show', 'landing',
                'bucket-list', 'bucket-create', 'bucket-save-attempt', 'bucket-delete-attempt',
                'object-create', 'object-list', 'object-save-attempt', 'object-delete-attempt',
                'object-download-attempt'
            ),
            "Drive" => array(
                'show', 'landing',
                'directory-list', 'directory-create', 'directory-save-attempt', 'directory-delete-attempt',
                'object-create', 'object-list', 'object-save-attempt', 'object-delete-attempt',
                'object-download-attempt'
            ),
            "SAM" => array(
                'show', 'landing',
                'user-account', 'user-account-save-attempt', 'user-keyset-create', 'keyset-delete-attempt',
                'user-keyset-delete', 'user-keyset-list', 'user-edit', 'user-edit-attempt',
                'user-create', 'user-list', 'user-save-attempt', 'user-delete-attempt',
                'role-create', 'role-list', 'role-save-attempt', 'role-delete-attempt',
                'group-create', 'group-list', 'group-save-attempt', 'group-delete-attempt',
                'policy-edit', 'policy-create', 'policy-list', 'policy-save-attempt',
                'policy-delete-attempt', 'policy-permission-add', 'policy-permission-delete'
            ),
            "SVPC" => array(
                'show', 'landing',
                'vpc-create', 'vpc-list', 'vpc-save-attempt', 'vpc-delete-attempt',
                'subnet-create', 'subnet-list', 'subnet-save-attempt', 'subnet-delete-attempt',
                'dhcpoptions-create', 'dhcpoptions-list', 'dhcpoptions-save-attempt', 'dhcpoptions-delete-attempt',
                'internetgateway-create', 'internetgateway-list', 'internetgateway-save-attempt', 'internetgateway-delete-attempt',
                'natgateway-create', 'natgateway-list', 'natgateway-save-attempt', 'natgateway-delete-attempt',
                'networkacl-create', 'networkacl-list', 'networkacl-save-attempt', 'networkacl-delete-attempt',
                'peeringconnection-create', 'peeringconnection-list', 'peeringconnection-save-attempt', 'peeringconnection-delete-attempt',
                'routetable-create', 'routetable-list', 'routetable-save-attempt', 'routetable-delete-attempt',
                'sitetositevpn-create', 'sitetositevpn-list', 'sitetositevpn-save-attempt', 'sitetositevpn-delete-attempt',
            ),
            "Database" => array(
                'show', 'landing', 'image-list', 'size-list',
                'instance-create', 'instance-list', 'instance-save-attempt', 'instance-delete-attempt',
                'query-create', 'query-list', 'query-save-attempt', 'query-delete-attempt',
                'performancechart-create', 'performancechart-list', 'performancechart-save-attempt', 'performancechart-delete-attempt',
                'backup-create', 'backup-list', 'backup-save-attempt', 'backup-delete-attempt',
                'snapshot-create', 'snapshot-list', 'snapshot-save-attempt', 'snapshot-delete-attempt',
                'reservation-create', 'reservation-list', 'reservation-save-attempt', 'reservation-delete-attempt',
                'optiongroup-create', 'optiongroup-list', 'optiongroup-save-attempt', 'optiongroup-delete-attempt',
                'parametergroup-create', 'parametergroup-list', 'parametergroup-save-attempt', 'parametergroup-delete-attempt',
                'subnetgroup-create', 'subnetgroup-list', 'subnetgroup-save-attempt', 'subnetgroup-delete-attempt',
            ),
            "DNS" => array(
                'show', 'landing',
                'domain-list', 'domain-create', 'domain-save-attempt', 'domain-delete-attempt', 'domain-create',
                'record-create', 'record-list', 'record-save-attempt', 'record-delete-attempt'
            ),
            "SLB" => array(
                'show', 'landing',
                'record-create', 'record-list', 'record-save-attempt', 'record-delete-attempt'
            ),
            "SMP" => array(
                'show', 'landing',
                'record-create', 'record-list', 'record-save-attempt', 'record-delete-attempt'
            ),
            "SSQS" => array(
                'show', 'landing',
                'queue-list', 'queue-create', 'queue-save-attempt', 'queue-delete-attempt', 'queue-create',
                'message-create', 'message-list', 'message-save-attempt', 'message-delete-attempt'
            ),
            "Services" => array( 'show', 'landing' ),
            "Services2" => array( 'show', 'landing', 'services2', 'services3', 'services4', 'services5', 'services6' ),
            "Closure" => array(
                'show', 'landing', 'repository-list', 'repository-create', 'repository-save-attempt', 'repository-delete-attempt',
                'function-create', 'function-list', 'function-save-attempt', 'function-delete-attempt', 'function-execute-attempt',
                'function-execution-list', 'function-edit'
            ),
            "PCR" => array(
                'show', 'landing',
                'registry-list', 'registry-create', 'registry-save-attempt', 'registry-delete-attempt',
                'registry-tag-list',
                'object-create', 'object-list', 'object-save-attempt', 'object-delete-attempt'
            ),
            "PCS" => array(
                'show', 'landing',
                'definition-list', 'definition-create', 'definition-save-attempt', 'definition-delete-attempt',
                'container-create', 'container-list', 'container-save-attempt', 'container-delete-attempt'
            ),
            "ArduinoDesign" => array(
                'show', 'landing'
            ),
            "ArduinoExecute" => array(
                'show', 'landing'
            ),
            "Analytics" => array(
                'show', 'landing',
                'application-access-list',
                'application-list', 'application-create', 'application-save-attempt', 'application-delete-attempt'
            ),
            "Artifact" => array(
                'show', 'landing',
                'repository-list', 'repository-create', 'repository-save-attempt', 'repository-delete-attempt',
                'object-create', 'object-list', 'object-save-attempt', 'object-delete-attempt'
            ),
            "Build" => array(
                'show', 'landing', 'job-list', 'job-create', 'job-edit', 'job-edit-attempt', 'job-save-attempt',
                'job-execute-attempt', 'job-delete-attempt', 'job-execution-list', 'execution-create',
                'execution-list', 'execution-save-attempt', 'execution-delete-attempt',
                'pipeline-create', 'pipeline-list', 'pipeline-save-attempt', 'pipeline-delete-attempt',
                'pipeline-rebuild-attempt', 'pipeline-cancel-attempt', 'pipeline-execute'
            ),
//            "Default" => array('show', 'landing'),
//            "DefaultModule" => array('show', 'landing'),
            "Home" => array('show', 'landing'),
            "Mindstorm" => array(
                'show', 'landing',
                'instance-create', 'instance-list', 'instance-save-attempt', 'instance-delete-attempt'
            ),
            "MobileDevice" => array(
                'show', 'landing',
                'device-list', 'device-create', 'device-save-attempt', 'device-delete-attempt',
                'emulator-list', 'emulator-create', 'emulator-save-attempt', 'emulator-delete-attempt',
                'recording-create', 'recording-list', 'recording-save-attempt', 'recording-delete-attempt'
            ),
            "DeviceAutomation" => array(
                'show', 'landing',
                'device-list', 'device-create', 'device-save-attempt', 'device-delete-attempt',
                'device-control',
                'emulator-list', 'emulator-create', 'emulator-save-attempt', 'emulator-delete-attempt',
                'recording-create', 'recording-list', 'recording-save-attempt', 'recording-delete-attempt'
            ),
            "SCM" => array(
                'show', 'landing',
                'secret-list', 'secret-create', 'secret-save-attempt', 'secret-delete-attempt',
                'repository-list', 'repository-create', 'repository-save-attempt', 'repository-delete-attempt',
                'repository-history', 'repository-browse', 'repository-charts', 'repository-contributors',
                'commit-details', 'object-list'
            ),
            "UserSettings" => array(
                'show', 'landing'
            ),
        );
    }

    private function getUcaseControls() {
        $controls = \ISOPHP\core::$php->array_keys($this->availableRoutes) ;
        $ucase_controls = [] ;
        foreach ($controls as $control) {
            $ucase_controls[]  = \ISOPHP\core::$php->strtoupper($control) ;
        }
        $this->ucase_controls = $ucase_controls ;
    }

    private function getDefaultRoute()
    {
        $this->setDefaultRouteExtraParams();
//        return array("control" => "LandingPage", "action" => "show", "extraParams" => $this->route["extraParams"]);
        return array("control" => "Login", "action" => "show", "extraParams" => $this->route["extraParams"]);
    }

    private function parseControllerAliases()
    {
//      $allInfoObjects = \Core\AutoLoader::getInfoObjects() ;
        $aliases = array();
//      foreach ($allInfoObjects as $infoObject) {
//        $aliases = array_merge( $aliases, $infoObject->routeAliases() ); }
        if (isset($this->bootstrapParams[1])) {
            if (\ISOPHP\core::$php->array_key_exists($this->bootstrapParams[1], $aliases)) {
                $this->bootstrapParams[1] = strtr($this->bootstrapParams[1], $aliases);
            }
        }
    }

    private function setRouteController() {
        if (isset($this->bootstrapParams[1])) {
            $ucase_req_control = \ISOPHP\core::$php->strtoupper($this->bootstrapParams[1]) ;
//            if (\ISOPHP\core::$php->array_key_exists($this->bootstrapParams[1], $this->availableRoutes)) {


            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Setting ucase req control', $ucase_req_control);
                \ISOPHP\js_core::$console->log('Using ucase controls', $this->ucase_controls);
            }
            if (\ISOPHP\core::$php->in_array($ucase_req_control, $this->ucase_controls)) {
                if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                    \ISOPHP\js_core::$console->log('is in ucase controls ray');
                }

                $this->route["control"] = $this->bootstrapParams[1];
//                $this->route["control"] = $ucase_req_control;
                if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                    \ISOPHP\js_core::$console->log('current route control', $this->route["control"]);
                }
            } else {
                if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                    \ISOPHP\js_core::$console->log('not in ucase controls ray');
                }
                $this->route = $this->getDefaultRoute();
            }
        } else {

            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('nothing set for bstrap param 1');
            }
            $this->route = $this->getDefaultRoute();
        }

    }

    private function setRouteAction() {
        $actionSet = isset($this->bootstrapParams[2]);
        $actionExists = \ISOPHP\core::$php->in_array($this->bootstrapParams[2], $this->availableRoutes[$this->bootstrapParams[1]]);

        \ISOPHP\js_core::$console->log('check first action exists');
        \ISOPHP\js_core::$console->log($actionExists, $this->bootstrapParams[2], $this->bootstrapParams[1], $this->availableRoutes);
        if ($actionExists == false) {
            $this->bootstrapParams[1] = \ISOPHP\core::$php->strtoupper($this->bootstrapParams[1]) ;
            $uppercaseControlActionExists = \ISOPHP\core::$php->in_array($this->bootstrapParams[2], $this->availableRoutes[$this->bootstrapParams[1]]);
            \ISOPHP\js_core::$console->log('check uppercased control action exists');
            \ISOPHP\js_core::$console->log($uppercaseControlActionExists);

            if ($uppercaseControlActionExists == true) {
                $actionExists = $uppercaseControlActionExists ;
                \ISOPHP\js_core::$console->log('uppercase control action does exist');
                \ISOPHP\js_core::$console->log($uppercaseControlActionExists);
            }
        }

        if ($actionSet && $actionExists) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('router correct action ');
                \ISOPHP\js_core::$console->log($this->bootstrapParams[2]);
                \ISOPHP\js_core::$console->log($this->availableRoutes[$this->route['control']]);
            }
//            $correctAct = \ISOPHP\core::$php->in_array($this->bootstrapParams[2], $this->availableRoutes[$this->bootstrapParams[1]]);
        } else {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('router correct action false');
                \ISOPHP\js_core::$console->log($actionSet, $actionExists);
                \ISOPHP\js_core::$console->log($this->bootstrapParams[2]);
                \ISOPHP\js_core::$console->log($this->bootstrapParams);
                \ISOPHP\js_core::$console->log($this->availableRoutes);
                \ISOPHP\js_core::$console->log($this->availableRoutes[$this->bootstrapParams[1]]);
                \ISOPHP\js_core::$console->log($this->availableRoutes[$this->route['control']]);
            }
//            $correctAct = false;
        }

        if ($actionSet == true && $actionExists == true) {
            $this->route["action"] = $this->bootstrapParams[2];
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('route action is ', $this->route["action"]);
            }
        }
//        if ($actionSet !== true) {
//            $this->route = $this->getDefaultRoute();
//            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
//                \ISOPHP\js_core::$console->log('action set not true, default route');
//            }
//        }
//        if ($correctAct !== true) {
//            $this->route = $this->getDefaultRoute();
//            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
//                \ISOPHP\js_core::$console->log('action correct not true, default route');
//            }
//        }
    }

    private function setRouteExtraParams() {
        $this->route["extraParams"] = array();
        $numberOfExtraParams = \ISOPHP\core::$php->count($this->bootstrapParams) - 3;
        for ($i = 3; $i < ($numberOfExtraParams + 3); $i++) {
            $this->route["extraParams"][] = $this->bootstrapParams[$i];
        }
    }

    private function setDefaultRouteExtraParams() {
        $this->route["extraParams"] = array();
        $numberOfExtraParams = \ISOPHP\core::$php->count($this->bootstrapParams) - 1;
        for ($i = 1; $i < ($numberOfExtraParams + 1); $i++) {
            $this->route["extraParams"][] = $this->bootstrapParams[$i];
        }
    }

}
