<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-repository-create" class="btn btn-success attempt-repository-create">Create</span>
                        <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">SCM Repository List</span>
                        <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
                    </div>
                        
                    <div>
                        <h4>New Repository</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Repository Name</label>
                        <input type="text" name="repository_name" class="form-control" id="repository_name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="repository_description" id="repository_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-repository-create" class="btn btn-success attempt-repository-create">Create</span>
                        <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">SCM Repository List</span>
                        <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
                    </div>
                    
                </div>
                
            </div>
             
        </div>';

    return $html;

};