<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Device Automation, your Building Automation</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Device Automation for Home and Office</h3> 
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-deviceautomation-device-list" class="go-deviceautomation-device-list btn btn-lg btn-warning">Devices</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-deviceautomation-device-create" class="go-deviceautomation-device-create btn btn-lg btn-warning">Create Device</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;