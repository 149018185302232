<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to VPC, the Virtual Private Cloud Service.</h1>
            </div>
        
            <div class="col-sm-12 row-margin-top">
            
                <div class="col-sm-6">
                    
                    <div class="col-sm-12">
                        <h3>VPC&apos;s and Subnets</h3>
                        <p>A system for VPC&apos;s and Subnets allows services and clients across the Internet or within your VPC&apos;s
                        to make use of High Availability, Scalable Networking Technology.</p>            
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-svpc-vpc-list" class="go-svpc-vpc-list btn btn-lg btn-warning">VPC&apos;s</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-svpc-subnet-list" class="go-svpc-subnet-list btn btn-lg btn-warning disabled">Subnets</span>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-svpc-internetgateway-list" class="go-svpc-internetgateway-list btn btn-lg btn-warning disabled">Internet Gateways</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-svpc-natgateway-list" class="go-svpc-natgateway-list btn btn-lg btn-warning disabled">NAT Gateways</span>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-svpc-routetable-list" class="go-svpc-routetable-list btn btn-lg btn-warning disabled">Route Tables</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-svpc-dhcpoptions-list" class="go-svpc-dhcpoptions-list btn btn-lg btn-warning disabled">DHCP Options Sets</span>
                        </div>
                    </div>' ;

    $html = $html . '
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-svpc-peeringconnection-list" class="go-svpc-peeringconnection-list btn btn-lg btn-warning disabled">Peering Connections</span>
                        </div>
                        <div class="col-sm-6">
                            &nbsp;
                        </div>
                    </div>
                    
                </div>
            
                <div class="col-sm-6">
                    
                    <div class="col-sm-12">
                        <h3>Security</h3>
                        <p>A system for VPC&apos;s and Subnets allows services and clients across the Internet or within your VPC&apos;s
                        to make use of High Availability, Scalable Networking Technology.</p>            
                    </div>
                    
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-svpc-networkacl-list" class="go-svpc-networkacl-list btn btn-lg btn-warning disabled">Network ACL&apos;s</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-securitygroup-list" class="go-sc1-securitygroup-list btn btn-lg btn-warning">Security Groups</span>
                        </div>    
                    </div>
                    
                    <div class="col-sm-12 row-margin-top">
                        <h3>VPN</h3>
                        <p>Virtual Private Network technology to allow secure communications between your Private Networks.</p>            
                    </div>' ;

    $html = $html . '
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-svpc-sitetositevpn-list" class="go-svpc-sitetositevpn-list btn btn-lg btn-warning disabled">Site to Site VPN</span>
                        </div>
                        <div class="col-sm-6">
                            &nbsp;
                        </div>                                    
                    </div>' ;

    $html = $html . '
                    
                </div>
            
            </div>
          
        </div>';

    return $html ;

} ;