<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $records_are_array = \ISOPHP\core::$php->is_array($rqd['records']) ;
    if ($records_are_array === true) {
        $records_exist = true ;
    } else {
        $records_exist = false ;
    }
    if ($records_exist === true) {
        $record_count = \ISOPHP\core::$php->count($rqd['records']) ;
    } else {
        $record_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-dns-record-create"
                  data-dns-domain-id="' . $pv['params']['domain_id'] . '"
                  data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                  class="btn btn-success go-dns-record-create">Create New Record</span>
            <span id="go-dns-landing" class="btn btn-warning go-dns-landing">DNS Home</span>
            <span id="go-dns-domain-list" class="btn btn-warning go-dns-domain-list">Domain List</span>
            <span id="go-dns-record-list"
                  data-dns-domain-id="' . $pv['params']['domain_id'] . '"
                  data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                  class="btn-refresh btn btn-info go-dns-record-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Domain <strong>' . $rqd['domain'] . '</strong>, Record List</h4>
        </div> ' ;

    if ($rqd['page_count'] > 1) {
        $html = $html . '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'dns-record-paginator') ;
        $html = $html .'</div>' ;
    }

    if ($records_exist === true && $record_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_dns_record">
                        <div class="col-sm-2 col-2">
                            <h5 class="dns_record_table_title">ID</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="dns_record_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="dns_record_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="dns_record_table_title">Type</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="dns_record_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['records'] as $one_dns_record) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="dns_list_id"><span class="dns_id dns_listing">' . $one_dns_record['dns_record_id_short'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="dns_list_record_name"><span class="dns_record_name dns_listing">' . $one_dns_record['dns_record_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="dns_list_record_data"><span class="dns_record_data dns_listing">' . $one_dns_record['dns_record_value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="dns_list_record_type"><span class="dns_type dns_listing">' . $one_dns_record['dns_record_type'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <!--
                            <div class="col-sm-6 col-6">
                                <i id="edit_dns_record_' . $one_dns_record['dns_record_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-record-edit btn btn-outline-primary"
                                   data-dns-domain-id="' . $pv['params']['domain_id'] . '"
                                   data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                                   data-dns-record-id="' . $one_dns_record['dns_record_id'] . '"
                                   data-dns-record-name="' . $one_dns_record['dns_record_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                            -->
                            <div class="col-sm-12 col-12">
                                <i id="delete_dns_record_' . $one_dns_record['dns_record_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-record-delete btn btn-outline-danger"
                                   data-dns-domain-id="' . $pv['params']['domain_id'] . '"
                                   data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                                   data-dns-record-id="' . $one_dns_record['dns_record_id'] . '"
                                   data-dns-record-name="' . $one_dns_record['dns_record_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'dns-record-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html . '<input type="hidden" id="dns-domain-id" value="' . $pv['params']['domain_id'] . '" />' ;
        $html = $html . '<input type="hidden" id="dns-domain-name" value="' . $pv['params']['domain_name'] . '" />' ;

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
            <span id="go-dns-record-create"
                  data-dns-domain-id="' . $pv['params']['domain'] . '"
                  data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                  class="btn btn-success go-dns-record-create">Create New Record</span>
            <span id="go-dns-landing" class="btn btn-warning go-dns-landing">DNS Home</span>
            <span id="go-dns-domain-list" class="btn btn-warning go-dns-domain-list">Domain List</span>
            <span id="go-dns-record-list"
                  data-dns-domain-id="' . $pv['params']['domain'] . '"
                  data-dns-domain-name="' . $pv['params']['domain_name'] . '"
                  class="btn-refresh btn btn-info go-dns-record-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Records</h5>
             </div>' ;

    }

    return $html;

};
