<?php

Namespace Model\Services;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Services List of Piranha Web Services';
        $page['heading'] = 'List of Services';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Services Mod getPage', $page, $pageVars);
        return $page;
    }

    public function showServicesLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ServicesView();
            $view->loadTemplate('Services', 'ServicesLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Domain Create 2', $vars);
        };
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Showing Last Login');
                $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
                \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
                $last_login = $user_logged['last_login'];
                $jQuery = \ISOPHP\js_core::$jQuery;
                $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
                $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
                $jQuery('#latest-login-date')->html($login_date);
                $jQuery('#latest-login-time')->html($login_time);
//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
                return true;
            }
        } ;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Services', 'show', array(), '/services');
                });

            }
        };
    }


}
