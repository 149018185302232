<?php

Namespace Model\GenerativeAI;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'GenerativeAI List of Piranha Web Services';
        $page['heading'] = 'List of GenerativeAI';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('GenerativeAI Mod', $page, $pageVars);
        return $page;
    }

    public function showGenerativeAILanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing GenerativeAI Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\GenerativeAIView();
            $view->loadTemplate('GenerativeAI', 'GenerativeAILanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayGenerativeAI($bind);
            \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket Create 2', $vars);
        };
    }

    public function showGenerativeAIBucketCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing GenerativeAI Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\GenerativeAIView();
            $view->loadTemplate('GenerativeAI', 'BucketCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayGenerativeAI($bind);
            \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket Create 2', $vars);
        };
    }

    public function showGenerativeAIBucketList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing GenerativeAI List');
            $vars['control'] = 'GenerativeAI';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/generativeai/bucket/all';
            $vars['page'] = $pageVars['params']['page'] ;
            $afterwards = $this->applyGenerativeAIBucketListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            \ISOPHP\js_core::$console->log('GenerativeAI Model: ', $result);
            return $result;
        };
    }

    public function applyGenerativeAIBucketListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our GenerativeAI Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket List 1', $vars);
                $view = new \View\GenerativeAIView();
                $view->loadTemplate('GenerativeAI', 'BucketList.php', $vars);
                $bind = self::bindButtons();
                $view->displayGenerativeAI($bind);
                \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('GenerativeAI Bucket List failed');
            }
        };
        return $func;
    }

    public function showGenerativeAIBucketListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing GenerativeAI List');
            $vars['control'] = 'GenerativeAI';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/generativeai/bucket/all';
            $vars['page'] = 'all' ;
            $afterwards = $this->applyGenerativeAIBucketListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            \ISOPHP\js_core::$console->log('GenerativeAI Model: ', $result);
            return $result;
        };
    }

    public function applyGenerativeAIBucketListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our GenerativeAI Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket List 1', $vars);
                $view = new \View\GenerativeAIView();
                $view->loadTemplate('GenerativeAI', 'BucketListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayGenerativeAIBucketDropDown($bind);
                \ISOPHP\js_core::$console->log('apply GenerativeAI Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('GenerativeAI Bucket List failed');
            }
        };
        return $func;
    }

    public function showGenerativeAIObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing GenerativeAI Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\GenerativeAIView();
            $view->loadTemplate('GenerativeAI', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayGenerativeAI($bind);
            \ISOPHP\js_core::$console->log('apply GenerativeAI Object Create 2', $vars);
        };
    }

    public function showGenerativeAIObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for bucket name') ;
            \ISOPHP\js_core::$console->log($pageVars['bucket_name']) ;
            \ISOPHP\js_core::$console->log('Showing GenerativeAI List') ;
            $vars['control'] = 'GenerativeAI' ;
            $vars['action'] = 'list' ;
            $vars['api_uri'] = '/api/generativeai/object/all' ;
            $vars['bucket_name'] = $pageVars['params']['bucket_name'] ;
            $vars['page'] = $pageVars['params']['page'] ;
            if (isset($pageVars['params']['key'])) {
                $vars['key'] = $pageVars['params']['key'] ;
            }
            \ISOPHP\js_core::$console->log('showGenerativeAIObjectList $vars') ;
            \ISOPHP\js_core::$console->log($vars) ;
            $afterwards = $this->applyGenerativeAIObjectListResult($pageVars) ;
            $result = $this->performRequest($vars, $afterwards) ;
            \ISOPHP\js_core::$console->log('GenerativeAI Model: ', $result) ;
            return $result ;
        };
    }

    public function applyGenerativeAIObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our GenerativeAI Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply GenerativeAI Object List 1', $vars);
                $view = new \View\GenerativeAIView();
                $view->loadTemplate('GenerativeAI', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayGenerativeAI($bind);
                \ISOPHP\js_core::$console->log('apply GenerativeAI Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('GenerativeAI Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save bucket
     */
    public function saveBucketAttemptToGenerativeAI($pageVars)
    {
        \ISOPHP\js_core::$console->log('save bucket to generativeai') ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $vars = array() ;
        $vars['control'] = 'GenerativeAI' ;
        $vars['action'] = 'bucket-create' ;
        $vars['api_uri'] = '/api/generativeai/bucket/create' ;
        $vars['bucket_name'] = $jQuery('#bucket_name')->val() ;
        if ($jQuery('#bucket_public_access')->prop('checked')) {
            $vars['public_access'] = 'on' ;
        } else {
            $vars['public_access'] = '' ;
        }
        $vars['description'] = $jQuery('#description')->val() ;
        $afterwards = $this->saveBucketResultFromGenerativeAI($pageVars) ;
        $result = $this->performRequest($vars, $afterwards) ;
        return $result ;
    }

    public function saveBucketResultFromGenerativeAI($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save generativeai bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Bucket '.$request_data['bucket']['generativeai_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('generativeai bucket saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('GenerativeAI', 'bucket-list', array(), '/generativeai/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Bucket '.$request_data['bucket']['generativeai_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('generativeai bucket save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToGenerativeAI($pageVars) {
        \ISOPHP\js_core::$console->log('save object to generativeai');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'GenerativeAI';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/generativeai/object/create';
        $vars['key'] = $pageVars['params']['object_key'];
        $vars['bucket_name'] = $jQuery('#bucket_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $afterwards = $this->saveObjectResultFromGenerativeAI($pageVars);
        $create_message = 'Creating Object...' ;
        \Core\WindowMessage::showMessage($create_message, 'warning');
        $result = $this->performFileRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromGenerativeAI($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save generativeai object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['name'].' in Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('generativeai object saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $bucket_name = $request_data['bucket'] ;
                $navigate->route('GenerativeAI', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ), '/generativeai/'.$bucket_name.'/object/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['name'].' in Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('generativeai object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToGenerativeAI($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to generativeai');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'GenerativeAI';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/generativeai/object/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteObjectResultFromGenerativeAI($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    /**
     * Download object
     */
    public function downloadObjectAttemptFromGenerativeAI($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $bucket_name = $pageVars['params']['bucket_name'] ;
        $object_key = $pageVars['params']['object_key'] ;
        $object_id = $pageVars['params']['object_id'] ;

        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $token = $cookies['user_tokens'][0]['data'] ;
        }
        \ISOPHP\js_core::$console->log('download object dump PageVars', $pageVars);
        $config = new \Model\Configuration() ;
        $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
        $server_url .= '/api/generativeai/object/download' ;
        $server_url .= '?token='.$token ;
        $server_url .= '&bucket_name='.$bucket_name ;
        $server_url .= '&object_key='.$object_key ;
        $server_url .= '&object_id='.$object_id ;
        $slug = $bucket_name.'_'.$object_id ;
        $dl_el_id = 'download_link_'.$slug ;
        $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download></a>' ;
        \ISOPHP\js_core::$console->log('GenerativeAI Object download html', $html);
        $body_jq = $jQuery('body') ;
        $body_jq->append($html) ;
        $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
        $dl_el_js->click() ;
        \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
        \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
    }

    public function downloadObjectResultFromGenerativeAI($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Download generativeai object result to', $request_data);

            self::downloadToFile($request_data->responseText, 'filename.txt', 'text/plain') ;

            if ($request_data['status'] === 'OK') {
                $msg = 'Downloaded Object '.$request_data['object']['generativeai_object_name'].' from Bucket '.$request_data['object']['generativeai_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('generativeai object downloaded, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['generativeai_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('generativeai object download failed');
            }
        };
        return $func;
    }

    public function deleteObjectResultFromGenerativeAI($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete generativeai object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object'].' from Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('generativeai object deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $bucket_name = $request_data['bucket'] ;
                $navigate->route('GenerativeAI', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ), '/generativeai/'.$bucket_name.'/object/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['generativeai_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('generativeai object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete bucket
     */
    public function deleteBucketAttemptToGenerativeAI($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete bucket to generativeai');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'GenerativeAI';
        $vars['action'] = 'bucket-delete';
        $vars['api_uri'] = '/api/generativeai/bucket/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        \ISOPHP\js_core::$console->log('delete bucket vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBucketResultFromGenerativeAI($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBucketResultFromGenerativeAI($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete generativeai bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('generativeai bucket deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('GenerativeAI', 'bucket-list', array(), '/generativeai/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Bucket '.$request_data['bucket'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('generativeai bucket delete failed');
            }
            $show_bucket_list = self::showGenerativeAIBucketList($pageVars) ;
            $show_bucket_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_generativeai_landing = $jQuery('.go-generativeai-landing');
                $go_generativeai_landing->off() ;
                $go_generativeai_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('GenerativeAI', 'landing', array(), '/generativeai');
                });

                $go_generativeai_bucket_list = $jQuery('.go-generativeai-bucket-list');
                $go_generativeai_bucket_list->off() ;
                $go_generativeai_bucket_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('GenerativeAI', 'bucket-list', array(), '/generativeai/bucket/list');
                });

                $go_generativeai_bucket_create = $jQuery('.go-generativeai-bucket-create');
                $go_generativeai_bucket_create->off() ;
                $go_generativeai_bucket_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('GenerativeAI', 'bucket-create', array(), '/generativeai/bucket/create');
                });

                $save_bucket = $jQuery('.attempt-generativeai-bucket-create');
                $save_bucket->off() ;
                $save_bucket->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('GenerativeAI', 'bucket-save-attempt', array());
                });

                $go_generativeai_object_list = $jQuery('.go-generativeai-object-list');
                $go_generativeai_object_list->off() ;
                $go_generativeai_object_list->on('click', function ($jsThis) use ($jQuery, $navigate) {
                    \ISOPHP\js_core::$console->log('Clicked object list button');
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;

                    $bucket_name = $jqThis->attr('data-generativeai-bucket-name') ;
                    \ISOPHP\js_core::$console->log('$bucket_name');
                    \ISOPHP\js_core::$console->log($bucket_name);

                    $object_key = $jqThis->attr('data-generativeai-object-key') ;
                    \ISOPHP\js_core::$console->log('$object_key');
                    \ISOPHP\js_core::$console->log($object_key);

                    $nav_ray = array(
                        'bucket_name' => $bucket_name
                    ) ;
                    if ($object_key !== null) {
                        $nav_ray['key'] = $object_key ;
                    }
                    $navigate->route('GenerativeAI', 'object-list', $nav_ray, '/generativeai/'.$bucket_name.'/object/list') ;
                });

                $go_generativeai_object_create = $jQuery('.go-generativeai-object-create');
                $go_generativeai_object_create->off() ;
                $go_generativeai_object_create->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked object create button');
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $bucket_name = $jqThis->attr('data-generativeai-bucket-name') ;
                    $object_creation_key = $jqThis->attr('data-generativeai-object-creation-key') ;
                    $navigate->route('GenerativeAI', 'object-create', array(
                        'bucket_name' => $bucket_name,
                        'object_creation_key' => $object_creation_key,
                    ), '/generativeai/'.$bucket_name.'/'.$object_creation_key.'/object/create');
                });

                $save_object = $jQuery('.attempt-generativeai-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_key = $jqThis->attr('data-generativeai-object-id') ;
                    $navigate->route('GenerativeAI', 'object-save-attempt', array(
                        'object_key' => $object_key,
                    ));
                });

                $download_object = $jQuery('.attempt-generativeai-object-download');
                $download_object->off() ;
                $download_object->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    \ISOPHP\js_core::$console->log('Clicked object download button');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    \ISOPHP\js_core::$console->log('jqthis object download ', $this_id, $jqThis);
                    $object_name = $jqThis->attr('data-generativeai-object-name') ;
                    $object_id = $jqThis->attr('data-generativeai-object-id') ;
                    $bucket_name = $jqThis->attr('data-generativeai-bucket-name') ;
                    \ISOPHP\js_core::$console->log('object download data vars', $object_name, $object_id, $bucket_name);
                    $navigate->route('GenerativeAI', 'object-download-attempt', array(
                        'object_id' => $object_id,
                        'object_key' => $object_name,
                        'bucket_name' => $bucket_name,
                    ));
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-generativeai-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-generativeai-object-name') ;
                    $object_id = $jqThis->attr('data-generativeai-object-id') ;
                    $bucket_name = $jqThis->attr('data-generativeai-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg  = "You are about to delete the Object $object_name from the bucket $bucket_name. ";
                    $msg .= "This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $bucket_name) {
                        $navigate->route('GenerativeAI', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'bucket_name' => $bucket_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $bucket_delete = $jQuery('.attempt-generativeai-bucket-delete');
                $bucket_delete->off() ;
                $bucket_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $bucket_name = $jqThis->attr('data-generativeai-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked bucket delete button');
                    $msg = "You are about to delete the Bucket $bucket_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $bucket_name) {
                        $navigate->route('GenerativeAI', 'bucket-delete-attempt', array(
                            'bucket_name' => $bucket_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $generativeai_bucket_paginator = $jQuery('.generativeai-bucket-paginator');
                $generativeai_bucket_paginator->off() ;
                $generativeai_bucket_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked generativeai bucket paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('GenerativeAI', 'bucket-list', array(
                        'page' => $new_page
                    ), '/generativeai/bucket/list/page/'.$new_page);
                });

                $generativeai_object_paginator = $jQuery('.generativeai-object-paginator');
                $generativeai_object_paginator->off() ;
                $generativeai_object_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked generativeai object paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $bucket_name = $jQuery('#generativeai-bucket-id')->val();
                    $jsthis->preventDefault();
                    $navigate->route('GenerativeAI', 'object-list', array(
                        'bucket_name' => $bucket_name,
                        'page' => $new_page
                    ), '/generativeai/'.$bucket_name.'/object/list/page/'.$new_page);
                });

            }
        };
    }

    public function bindBucketListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding bucket switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding bucket switch');
            $switch_bucket = $jQuery('#bucket_name');
            $switch_bucket->off() ;
            $switch_bucket->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#bucket_name') ;
                $bucket_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked bucket switch');
                $navigate->route('GenerativeAI', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ));
            } ) ;

        };
        return $func;
    }

    public static function downloadToFile ($content, $filename, $contentType) {
        $a = \ISOPHP\js_core::$window->document->createElement('a');
        $content_array = [$content] ;
        $type_object = new \StdClass() ;
        $type_object->type = $contentType ;
        $file = new \ISOPHP\js_core::$window->Blob($content_array, $type_object);
        $a->href= \ISOPHP\js_core::$window->URL->createObjectURL($file);
        $a->download = $filename;
        $a->click();
        \ISOPHP\js_core::$window->URL->revokeObjectURL($a->href);
    }

}
