<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Build, your Automation Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Build Server Automation</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-build-job-list" class="go-build-job-list btn btn-lg btn-warning disabled">Jobs</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-build-job-create" class="go-build-job-create btn btn-lg btn-warning disabled">Create Job</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-build-pipeline-list" class="go-build-pipeline-list btn btn-lg btn-warning">Pipelines</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-build-pipeline-execute" class="go-build-pipeline-create btn btn-lg btn-warning disabled">Execute Pipeline</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;