<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $domains_are_array = \ISOPHP\core::$php->is_array($rqd['domains']) ;
    if ($domains_are_array === true) {
        $domains_exist = true ;
    } else {
        $domains_exist = false ;
    }
    if ($domains_exist === true) {
        $domain_count = \ISOPHP\core::$php->count($rqd['domains']) ;
    } else {
        $domain_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-dns-domain-create" class="btn btn-success go-dns-domain-create">New Domain</span>
            <span id="go-dns-landing" class="btn btn-info go-dns-landing">DNS Home</span>
            <span id="go-dns-domain-list" class="btn-refresh btn btn-info go-dns-domain-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Domain List</h4>
         </div> ' ;

    if ($rqd['page_count'] > 1) {
        $html = $html . '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'dns-domain-paginator') ;
        $html = $html .'</div>' ;
    }

    if ($domains_exist === true && $domain_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-3 col-3">
                        <h5 class="dns_domain_id_title">ID</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="dns_domain_id_title">Name</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="dns_domain_id_title">Comment</h5>
                    </div>
                    <div class="col-sm-4 col-4">
                        <h5 class="dns_domain_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['domains'] as $one_dns_domain) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_dns_domain">
                    <div class="col-sm-3 col-3">
                        <p class="dns_list_id"><span class="dns_id dns_listing">' . $one_dns_domain['dns_domain_id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="dns_list_name"><span class="dns_name dns_listing">' . $one_dns_domain['dns_domain_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="dns_list_comment dns_listing">' . $one_dns_domain['dns_domain_comment'] . '</p>
                    </div>     
                    <div class="col-sm-4 col-4">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="list_dns_domain_' . $one_dns_domain['dns_domain_id'] . '"
                                   class="fa fa-1x fa-bars hvr-grow dom go-dns-record-list btn btn-outline-primary"
                                   data-dns-domain-id="' . $one_dns_domain['dns_domain_id'] . '"
                                   data-dns-domain-name="' . $one_dns_domain['dns_domain_name'] . '"></i>
                            </div>
                            <!--
                            <div class="col-sm-4 col-4">
                                <i id="edit_dns_domain_' . $one_dns_domain['dns_domain_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-domain-edit btn btn-outline-primary"
                                   data-dns-domain-id="' . $one_dns_domain['dns_domain_id'] . '"
                                   data-dns-domain-name="' . $one_dns_domain['dns_domain_name'] . '"></i>
                            </div>
                            -->
                            <div class="col-sm-6 col-6">
                                <i id="delete_dns_domain_' . $one_dns_domain['dns_domain_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-domain-delete btn btn-outline-danger"
                                   data-dns-domain-id="' . $one_dns_domain['dns_domain_id'] . '"
                                   data-dns-domain-name="' . $one_dns_domain['dns_domain_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'dns-domain-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-dns-domain-create" class="btn btn-success go-dns-domain-create">New Domain</span>
                <span id="go-dns-landing" class="btn btn-info go-dns-landing">DNS Home</span>
                <span id="go-dns-domain-list" class="btn-refresh btn btn-info go-dns-domain-list btn-refresh">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Domains</h5>
             </div>';

    }

    return $html;

};
