<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $instances_are_array = \ISOPHP\core::$php->is_array($rqd['instances']) ;
    if ($instances_are_array === true) {
        $instances_exist = true ;
    } else {
        $instances_exist = false ;
    }
    if ($instances_exist === true) {
        $instance_count = \ISOPHP\core::$php->count($rqd['instances']) ;
    } else {
        $instance_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-database-instance-create" class="btn btn-success go-database-instance-create">New Instance</span>
            <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
            <span id="go-database-instance-list" class="btn-refresh btn btn-info go-database-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Database Instances</h4>
         </div> ' ;
    $html = $html .
       '<div class="col-sm-12 col-12 one_database_instance">
            <div class="col-sm-2 col-2">
                <h5 class="database_instance_id_title">ID</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_instance_id_title">Name</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="database_instance_id_title">Status</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="database_instance_id_title">Region</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_instance_id_title">Networks</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_instance_id_title">DNS</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_instance_id_title">Edit</h5>
            </div>
        </div>';

    if ($instances_exist === true && $instance_count !== false) {

        foreach ($rqd['instances'] as $one_database_instance) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_database_instance">
                    <div class="col-sm-2 col-2">
                        <p class="database_list_id"><span class="database_id database_listing">' . $one_database_instance['vmid'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_name"><span class="database_name database_listing"><strong>' . $one_database_instance['engine'] . ': </strong>'. $one_database_instance['name'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="database_list_status">' . $one_database_instance['status'] . '</p>
                    </div>     
                    <div class="col-sm-1 col-1">
                        <p class="database_list_region">' . $one_database_instance['region'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">' ;


                    foreach ($one_database_instance['nics_expanded'] as $expanded_nic) {

                        $html = $html .
                            '<span class="sc1_list_network">'
                            .$expanded_nic['network'] . ', '
                            .$expanded_nic['ip_address'].
                            ' </span>' ;

                    }

                $html = $html .
                    '</div>
                    <div class="col-sm-2 col-2"> ';

                        if ($one_database_instance['internet_enabled'] == true) {
                            $html = $html . '<div class="col-sm-12 col-12 database-network-list">' ;
                            $html = $html . '  <a href="'. $one_database_instance['slug'].'_endpoint" target="_blank">' ;
                            $html = $html . "Endpoint: ".$one_database_instance['dns_endpoint'] ;
                            $html = $html . '  </a>' ;
                            $html = $html . '</div>' ;
                        } else {
                            $html = $html . '<div class="col-sm-12 col-12 database-network-status">' ;
                            $html = $html . 'DNS available when Internet network is enabled' ;
                            $html = $html . '</div>' ;
                        }

                $html = $html .
                   '</div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="edit_database_instance_' . $one_database_instance['vm_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-instance-edit btn btn-outline-primary"
                                   data-database-instance-id="' . $one_database_instance['vm_id'] . '"
                                   data-database-instance-state="' . $one_database_instance['status'] . '"
                                   data-database-instance-name="' . $one_database_instance['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="info_sc1_instance_' . $one_database_instance['vm_id'] . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-instance-info btn btn-outline-primary"
                                   data-database-instance-id="' . $one_database_instance['vm_id'] . '"
                                   data-database-instance-state="' . $one_database_instance['status'] . '"
                                   data-database-instance-name="' . $one_database_instance['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_database_instance_' . $one_database_instance['vm_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-instance-delete btn btn-outline-danger"
                                   data-database-instance-id="' . $one_database_instance['vm_id'] . '"
                                   data-database-instance-state="' . $one_database_instance['status'] . '"
                                   data-database-instance-name="' . $one_database_instance['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Database Instances</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-database-instance-create" class="btn btn-success go-database-instance-create">New Instance</span>
            <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
            <span id="go-database-instance-list" class="btn-refresh btn btn-info go-database-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;


    return $html;

};
