<?php

$console = new \ISOPHP\console();

$route_set = array() ;

if (ISOPHP_EXECUTION_ENVIRONMENT === 'ZEND') {
    $raw_path_one = $_SERVER['REQUEST_URI'];
} else {
    $raw_path_one = \ISOPHP\js_core::$window->location->pathname ;
    if (\ISOPHP\core::$php->substr($raw_path_one, 0, 1) !== '1') {
        $raw_path_one = '/'.$raw_path_one ;
    }
}

$raw_path = substr($raw_path_one, 1) ;
$parts = \ISOPHP\core::$php->explode('/', $raw_path) ;

if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
    unset($parts[0]) ;
    $newparts = [] ;
    for ($i=0; $i < \ISOPHP\core::$php->count($parts) ; $i++) {
        $pi = $i ;
        $npi = $i -1 ;
        $newparts[$npi] = $parts[$pi] ;
    }
    $parts = $newparts ;
}

$_REQUEST['control'] = \ISOPHP\core::$php->ucfirst($parts[0]) ;
if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
    \ISOPHP\js_core::$console->log('Setting request control to '.$parts[0]);
    \ISOPHP\js_core::$console->log('$raw_path', $raw_path);
    \ISOPHP\js_core::$console->log('request parts ', $parts);
}

if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
    $join_parts = (isset($parts[2]) && (\ISOPHP\core::$php->strlen($parts[2]) > 0) );
} else {
    $join_parts = (isset($parts[2]) && (strlen($parts[2]) > 0) );
}

if (isset($parts[0]) && !isset($parts[1])) {
    $_REQUEST['action'] = "show" ;
} else if ($join_parts ===true) {
    $_REQUEST['action'] = $parts[1].'-'.$parts[2] ;
} else {
    $_REQUEST['action'] = $parts[1] ;
}
if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
    \ISOPHP\js_core::$console->log('Setting request action to '.$_REQUEST['action']);
}

$route_set[] = isset($_REQUEST['control']) ;
$route_set[] = isset($_REQUEST['action']) ;

if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
    \ISOPHP\js_core::$console->log('Set route is ', $route_set);
}

if ( \ISOPHP\core::$php->in_array(false, $route_set) ) {
    $_REQUEST['control'] = "Index" ;
    $_REQUEST['action'] = "index" ; }

if (!isset($_REQUEST['output-format'])) {
    $_REQUEST['output-format'] = "HTML"; }

$cleo_vars = array();
$cleo_vars[0] = __FILE__;
$cleo_vars[1] = $_REQUEST['control'];
$cleo_vars[2] = $_REQUEST['action'];
foreach($_REQUEST as $post_key => $post_var) {
    if (!\ISOPHP\core::$php->in_array($post_key, array('control', 'action'))) {
        $cleo_vars[] = "--$post_key=$_REQUEST[$post_key]" ; } }
$_ENV['bootstrap'] = \ISOPHP\core::$php->serialize($cleo_vars);

$console->log($cleo_vars, $_ENV) ;

$bootStrap = new \Core\BootStrap();
\ISOPHP\core::$bootstrap = $bootStrap ;
$argv_or_null = (isset($argv)) ? $argv : null ;
$bootStrapParams = (isset($_ENV['bootstrap'])) ? \ISOPHP\core::$php->unserialize($_ENV['bootstrap']) : $argv_or_null ;
\ISOPHP\core::$bootstrap->main($bootStrapParams, array());
