<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $vms_are_array = \ISOPHP\core::$php->is_array($rqd['vms']) ;
    if ($vms_are_array === true) {
        $vms_exist = true ;
    } else {
        $vms_exist = false ;
    }
    if ($vms_exist === true) {
        $vm_count = \ISOPHP\core::$php->count($rqd['vms']) ;
    } else {
        $vm_count = false ;
    }

    if ($vms_exist === true && $vm_count !== false) {
        $html = $html . '<select class="form-control" name="vm_id" id="vm_id">';
        foreach ($rqd['vms'] as $one_database_vm) {
            $html = $html . '<option value="' . $one_database_vm['vmid'] . '">' . $one_database_vm['name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available vms</option>
             </select>' ;

    }

    return $html;

};