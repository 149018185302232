<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $internetgateways_are_array = \ISOPHP\core::$php->is_array($rqd['internetgateways']) ;
    if ($internetgateways_are_array === true) {
        $internetgateways_exist = true ;
    } else {
        $internetgateways_exist = false ;
    }
    if ($internetgateways_exist === true) {
        $internetgateway_count = \ISOPHP\core::$php->count($rqd['internetgateways']) ;
    } else {
        $internetgateway_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-internetgateway-create" class="btn btn-success go-svpc-internetgateway-create">Create Internet Gateway</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-internetgateway-list" class="btn btn-info go-svpc-internetgateway-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Internet Gateway List</h4>
         </div> ' ;

    if ($internetgateways_exist === true && $internetgateway_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_svpc_internetgateway">
                        <div class="col-sm-3 col-3">
                            <h5 class="svpc_internetgateway_table_title">Internet Gateway ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_internetgateway_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_internetgateway_table_title">VPC ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_internetgateway_table_title">State</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="svpc_internetgateway_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['internetgateways'] as $one_svpc_internetgateway) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-3 col-3">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_internetgateway['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_internetgateway_name"><span class="name svpc_listing">' . $one_svpc_internetgateway['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_svpc_name"><span class="svpc_svpc_name svpc_listing">' . $one_svpc_internetgateway['vpc_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_internetgateway_type"><span class="svpc_type svpc_listing">' . $one_svpc_internetgateway['state'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_svpc_internetgateway_' . $one_svpc_internetgateway['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-internetgateway-edit btn btn-outline-primary"
                                   data-svpc-internetgateway-id="' . $one_svpc_internetgateway['id'] . '"
                                   data-svpc-internetgateway-name="' . $one_svpc_internetgateway['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_svpc_internetgateway_' . $one_svpc_internetgateway['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-internetgateway-delete btn btn-outline-danger"
                                   data-svpc-internetgateway-id="' . $one_svpc_internetgateway['id'] . '"
                                   data-svpc-internetgateway-name="' . $one_svpc_internetgateway['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Internet Gateways</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-internetgateway-create" class="btn btn-success go-svpc-internetgateway-create">Create Internet Gateway</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-internetgateway-list" class="btn btn-info go-svpc-internetgateway-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};