<?php

Namespace Model\Closure;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Closure List of Piranha Web Services';
        $page['heading'] = 'List of Closure';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Closure Mod', $page, $pageVars);
        return $page;
    }

    public function showClosureLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ClosureView();
            $view->loadTemplate('Closure', 'ClosureLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayClosure($bind);
        };
    }

    public function showClosureRepositoryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ClosureView();
            $view->loadTemplate('Closure', 'RepositoryCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayClosure($bind);
            \ISOPHP\js_core::$console->log('apply Closure Repository Create 2', $vars);
        };
    }

    public function showClosureRepositoryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure List');
            $vars['control'] = 'Closure';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/closure/repository/all';
            $afterwards = $this->applyClosureRepositoryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            return $result;
        };
    }

    public function applyClosureRepositoryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Closure Repository List 1', $vars);
                $view = new \View\ClosureView();
                $view->loadTemplate('Closure', 'RepositoryList.php', $vars);
                $bind = self::bindButtons();
                $view->displayClosure($bind);
                \ISOPHP\js_core::$console->log('apply Closure Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Repository List failed');
            }
        };
        return $func;
    }

    public function showClosureRepositoryListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure List');
            $vars['control'] = 'Closure';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/closure/repository/all';
            $afterwards = $this->applyClosureRepositoryListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            return $result;
        };
    }

    public function applyClosureRepositoryListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Closure Repository List 1', $vars);
                $view = new \View\ClosureView();
                $view->loadTemplate('Closure', 'RepositoryListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayClosureRepositoryDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Closure Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Repository List failed');
            }
        };
        return $func;
    }

    public function showClosureFunctionCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ClosureView();
            $view->loadTemplate('Closure', 'FunctionCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayClosure($bind);
            \ISOPHP\js_core::$console->log('apply Closure Function Create 2', $vars);
        };
    }

    public function showClosureFunctionEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure Edit');
            $vars['control'] = 'Closure';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/closure/function/one';
            $vars['function_id'] = $pageVars['params']['function_id'];
            $afterwards = $this->applyClosureFunctionEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            \ISOPHP\js_core::$console->log('apply Closure Function Edit 2', $vars);
            return $result;
        };
    }

    public function applyClosureFunctionEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Function Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Closure Function Edit 1', $vars);
                $view = new \View\ClosureView();
                $view->loadTemplate('Closure', 'FunctionEdit.php', $vars);
                $bind = self::bindButtons();
                $view->displayClosure($bind);
                \ISOPHP\js_core::$console->log('apply Closure Function Edit 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Function Edit failed');
            }
        } ;
        return $func ;
    }

    public function showClosureFunctionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure List');
            $vars['control'] = 'Closure';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/closure/function/all';
            $vars['page'] = $pageVars['params']['page'];
            $afterwards = $this->applyClosureFunctionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            return $result;
        };
    }

    public function applyClosureFunctionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Function List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Closure Function List 1', $vars);
                $view = new \View\ClosureView();
                $view->loadTemplate('Closure', 'FunctionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayClosure($bind);
                \ISOPHP\js_core::$console->log('apply Closure Function List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Function List failed');
            }
        } ;
        return $func ;
    }

    public function showClosureFunctionExecutionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure List');
            $vars['control'] = 'Closure';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/closure/function/execution/all';
            $vars['function_id'] = $pageVars['params']['function_id'];
            $vars['page'] = $pageVars['params']['page'];
            $afterwards = $this->applyClosureFunctionExecutionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            return $result;
        };
    }

    public function applyClosureFunctionExecutionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Function Execution List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Closure Function Execution List 1', $vars);
                $view = new \View\ClosureView();
                $view->loadTemplate('Closure', 'FunctionExecutionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayClosure($bind);
                \ISOPHP\js_core::$console->log('apply Closure Function Execution List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Function Execution List failed');
            }
        } ;
        return $func ;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save repository
     */
    public function saveRepositoryAttemptToClosure($pageVars)
    {
        \ISOPHP\js_core::$console->log('save repository to closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Closure';
        $vars['action'] = 'repository-create';
        $vars['api_uri'] = '/api/closure/repository/create';
        $vars['repository_name'] = $jQuery('#repository_name')->val();
        $vars['repository_runtime'] = $jQuery('#repository_runtime')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRepositoryResultFromClosure($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRepositoryResultFromClosure($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save closure repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Repository '.$request_data['repository']['closure_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('closure repository saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Closure', 'repository-list', array(), '/closure/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Repository '.$request_data['repository']['closure_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('closure repository save failed');
            }
        };
        return $func;
    }

    /**
     * Execute closure
     */
    public function executeFunctionAttemptToClosure($pageVars)
    {
        \ISOPHP\js_core::$console->log('execute function in closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Closure';
        $vars['action'] = 'closure-create';
        $vars['api_uri'] = '/api/closure/function/execute';
        $vars['function_id'] = $pageVars['params']['function_id'];
        $vars['page'] = $pageVars['params']['page'];
        $afterwards = $this->executeFunctionResultFromClosure($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function executeFunctionResultFromClosure($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our execute closure function result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Executing Closure '.$request_data['closure']['execution_id'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('closure function executed, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Closure', 'function-execution-list', array(
                    'function_id' => $pageVars['params']['function_id']
                ), '/closure/function/'.$pageVars['params']['function_id'].'/execution/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Executing Closure '.$request_data['closure']['closure_function_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('closure function execute failed');
            }
        };
        return $func;
    }

    /**
     * Save closure
     */
    public function saveFunctionAttemptToClosure($pageVars)
    {
        \ISOPHP\js_core::$console->log('save function to closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Closure';
        $vars['action'] = 'closure-create';
        $vars['api_uri'] = '/api/closure/function/create';
        $function_id = $jQuery('#function_id')->val();
        \ISOPHP\js_core::$console->log('function id is :', $function_id);
        if ($function_id !== null) {
            $vars['function_id'] = $jQuery('#function_id')->val();
        }
        $vars['function_repository'] = $jQuery('#function_repository')->val();
        $vars['function_name'] = $jQuery('#function_name')->val();
        $vars['function_runtime'] = $jQuery('#function_runtime')->val();
        $vars['function_triggers'] = $jQuery('#function_triggers')->val();
        \ISOPHP\js_core::$console->log('function triggers :', $vars['function_triggers']);
        $vars['function_destinations'] = $jQuery('#function_destinations')->val();
        \ISOPHP\js_core::$console->log('function destinations :', $vars['function_destinations']);

        $editor = \ISOPHP\js_core::$window->document->querySelector(".CodeMirror")->CodeMirror ;
        \ISOPHP\js_core::$console->log('$editor', $editor);
        $vars['function_code'] = $editor->getValue() ;
        \ISOPHP\js_core::$console->log('$vars[function_code]', $vars['function_code']);

        $vars['function_code'] = \ISOPHP\js_core::$window->encodeURIComponent($vars['function_code']) ;
        \ISOPHP\js_core::$console->log('save closure vars');
        \ISOPHP\js_core::$console->log($vars);

        $afterwards = $this->saveFunctionResultFromClosure($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveFunctionResultFromClosure($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save closure function result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Closure '.$request_data['closure']['closure_function_name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('closure function saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Closure', 'function-list', array(), '/closure/function/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Closure '.$request_data['closure']['closure_function_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('closure function save failed');
            }
        };
        return $func;
    }
    /**
     * Delete closure
     */
    public function deleteFunctionAttemptToClosure($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete closure to closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Closure';
        $vars['action'] = 'closure-delete';
        $vars['api_uri'] = '/api/closure/function/delete';
        $vars['function_name'] = $pageVars['params']['function_name'];
        $vars['function_id'] = $pageVars['params']['function_id'];
        \ISOPHP\js_core::$console->log('delete closure vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteFunctionResultFromClosure($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteFunctionResultFromClosure($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete closure function result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Function '.$request_data['closure']['closure_function_name'].' from Closures ';
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('closure function deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Closure', 'function-list', array(), '/closure/function/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Closure '.$request_data['closure']['closure_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('closure function delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete repository
     */
    public function deleteRepositoryAttemptToClosure($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete repository to closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Closure';
        $vars['action'] = 'repository-delete';
        $vars['api_uri'] = '/api/closure/repository/delete';
        $vars['repository_name'] = $pageVars['params']['repository_name'];
        \ISOPHP\js_core::$console->log('delete repository vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteRepositoryResultFromClosure($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRepositoryResultFromClosure($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete closure repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Repository '.$request_data['repository']['closure_repository_name'].' for Repository '.$request_data['repository']['closure_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('closure repository deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Closure', 'repository-list', array(), '/closure/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Repository '.$request_data['repository']['closure_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('closure repository delete failed');
            }
            $show_repository_list = self::showClosureRepositoryList($pageVars) ;
            $show_repository_list() ;
        };
        return $func;
    }


    public function showClosureFunctionExecutionLog($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Closure Execution Log');
            $vars['control'] = 'Closure';
            $vars['action'] = 'function-log';
            $vars['api_uri'] = '/api/closure/function/log';
            $vars['function_id'] = $pageVars['params']['function_id'];
            $vars['execution_id'] = $pageVars['params']['execution_id'];
            $afterwards = self::applyClosureFunctionExecutionLog($pageVars);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Closure Model: ', $result);
            return $result;
        };
    }

    public function applyClosureFunctionExecutionLog($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Closure Function Execution Log result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;

                $html  = '<div class="fullWidth">' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <h4><strong>Function ID:</strong>'.$request_data['function_id'].'</h4>' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <h4><strong>Execution ID:</strong>'.$request_data['execution_id'].'</h4>' ;
                $html .= '    </div>' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <textarea name="" class="log_output" id="log_output" rows="15" style="overflow-y: scroll">' ;
                $html .= $request_data['output'] ;
                $html .= '</textarea>' ;
                $html .= '</div>' ;

                \Core\WindowMessage::showOverlay($html);
                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;

            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Closure Function Execution Log failed');
            }
        };
        return $func;
    }



    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_closure_landing = $jQuery('.go-closure-landing');
                $go_closure_landing->off() ;
                $go_closure_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'landing', array(), '/closure');
                });

                $go_closure_repository_list = $jQuery('.go-closure-repository-list');
                $go_closure_repository_list->off() ;
                $go_closure_repository_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'repository-list', array(), '/closure/repository/list');
                });

                $go_closure_repository_create = $jQuery('.go-closure-repository-create');
                $go_closure_repository_create->off() ;
                $go_closure_repository_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'repository-create', array(), '/closure/repository/create');
                });

                $save_repository = $jQuery('.attempt-repository-create');
                $save_repository->off() ;
                $save_repository->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'repository-save-attempt', array());
                });

                $go_closure_function_list = $jQuery('.go-closure-function-list');
                $go_closure_function_list->off() ;
                $go_closure_function_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'function-list', array(), '/closure/function/list');
                });

                $go_closure_function_create = $jQuery('.go-closure-function-create');
                $go_closure_function_create->off() ;
                $go_closure_function_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'function-create', array(), '/closure/function/create');
                });

                $go_closure_function_edit = $jQuery('.go-closure-function-edit');
                $go_closure_function_edit->off() ;
                $go_closure_function_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_name = $jqThis->attr('data-closure-function-name') ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    $navigate->route('Closure', 'function-edit', array(
                        'function_id' => $function_id,
                        'function_name' => $function_name
                    ), '/closure/function/'.$function_id.'/edit');
                });

                $save_function = $jQuery('.attempt-function-create');
                $save_function->off() ;
                $save_function->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Closure', 'function-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $function_delete = $jQuery('.attempt-function-delete');
                $function_delete->off() ;
                $function_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_name = $jqThis->attr('data-closure-function-name') ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    \ISOPHP\js_core::$console->log('Clicked closure delete button');
                    $msg = "You are about to delete the Function named $function_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $function_id, $function_name) {
                        $navigate->route('Closure', 'function-delete-attempt', array(
                            'function_id' => $function_id,
                            'function_name' => $function_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $repository_delete = $jQuery('.attempt-repository-delete');
                $repository_delete->off() ;
                $repository_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_name = $jqThis->attr('data-closure-repository-name') ;
                    \ISOPHP\js_core::$console->log('Clicked repository delete button');
                    $msg = "You are about to delete the Repository $repository_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $repository_name) {
                        $navigate->route('Closure', 'repository-delete-attempt', array(
                            'repository_name' => $repository_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $function_execute = $jQuery('.attempt-function-execute');
                $function_execute->off() ;
                $function_execute->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_name = $jqThis->attr('data-closure-function-name') ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    \ISOPHP\js_core::$console->log('Clicked function execute button');
                    $msg = "You are about to execute the Function $function_name.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $function_id) {
                        $navigate->route('Closure', 'function-execute-attempt', array(
                            'function_id' => $function_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $function_rebuild = $jQuery('.attempt-function-rebuild');
                $function_rebuild->off() ;
                $function_rebuild->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_name = $jqThis->attr('data-closure-function-name') ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    \ISOPHP\js_core::$console->log('Clicked function rebuild button');
                    $msg = "You are about to execute the Function $function_name.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $function_id) {
                        $navigate->route('Closure', 'function-execute-attempt', array(
                            'function_id' => $function_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


                $function_execution_list = $jQuery('.go-function-execution-list');
                $function_execution_list->off() ;
                $function_execution_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_name = $jqThis->attr('data-closure-function-name') ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    \ISOPHP\js_core::$console->log('Clicked function execution list button');
                    $navigate->route('Closure', 'function-execution-list', array(
                        'function_id' => $function_id
                    ), '/closure/function/'.$function_id.'/execution/list');
                } ) ;

                $go_closure_function_list_page = $jQuery('.closure-function-execution-paginator');
                $go_closure_function_list_page->off() ;
                $go_closure_function_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked closure function execution paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $function_id = $jQuery('#closure-function-id')->val() ;
//                    \ISOPHP\js_core::$console->log('new page is ' . $new_page);
                    $navigate->route('Closure', 'function-execution-list', array(
                        'function_id' => $function_id,
                        'page' => $new_page
                    ), '/closure/function/'.$function_id.'/execution/page/'.$new_page);
                });

                $closure_function_execution_paginator_direct = $jQuery('.closure-function-execution-paginator.paginator_direct_page');
                $closure_function_execution_paginator_direct->off() ;
                $closure_function_execution_paginator_direct->on('change', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Changed variable closure_function_execution paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->val() ;
                    $invalid_pages = array(0, null) ;
                    if (\ISOPHP\core::$php->in_array($new_page, $invalid_pages)) {
                        $new_page = 1 ;
                    }
                    $jsthis->preventDefault();
                    $function_id = $jQuery('#closure-function-id')->val() ;
                    $navigate->route('Closure', 'function-execution-list', array(
                        'function_id' => $function_id,
                        'page' => $new_page
                    ), '/closure/function/'.$function_id.'/execution/page/'.$new_page);
                });

                $function_execution_log = $jQuery('.show-function-execution-log');
                $function_execution_log->off() ;
                $function_execution_log->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $function_id = $jqThis->attr('data-closure-function-id') ;
                    $function_execution_id = $jqThis->attr('data-closure-function-execution-id') ;
                    \ISOPHP\js_core::$console->log('Clicked function execution log button');

                    $pageVars = array() ;
                    $pageVars['params'] = array() ;
                    $pageVars['params']['function_id'] = $function_id ;
                    $pageVars['params']['execution_id'] = $function_execution_id ;
                    $show_log = self::showClosureFunctionExecutionLog($pageVars) ;
                    $show_log();

                } ) ;

            }
        };
    }

    public function bindRepositoryListDropDownButtonsForFunctionList()
    {
        \ISOPHP\js_core::$console->log('Started binding repository switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding repository switch');
            $switch_repository = $jQuery('#repository_name');
            $switch_repository->off() ;
            $switch_repository->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#repository_name') ;
                $repository_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked repository switch');
                $navigate->route('Closure', 'closure-list', array(
                    'repository_name' => $repository_name,
                ));
            } ) ;

        };
        return $func;
    }



}
