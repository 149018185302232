<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $snapshots_are_array = \ISOPHP\core::$php->is_array($rqd['snapshots']) ;
    if ($snapshots_are_array === true) {
        $snapshots_exist = true ;
    } else {
        $snapshots_exist = false ;
    }
    if ($snapshots_exist === true) {
        $snapshot_count = \ISOPHP\core::$php->count($rqd['snapshots']) ;
    } else {
        $snapshot_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-database-snapshot-create" class="btn btn-success go-database-snapshot-create">New Snapshot</span>
            <span id="go-database-landing" class="btn btn-warning go-database-landing">Database Home</span>
            <span id="go-database-snapshot-list" class="btn-refresh btn btn-info go-database-snapshot-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Snapshot List</h4>
         </div> ' ;

    if ($snapshots_exist === true && $snapshot_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_database_snapshot">
                        <div class="col-sm-2 col-2">
                            <h5 class="database_snapshot_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="database_snapshot_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="database_snapshot_table_title">Size</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="database_snapshot_table_title">Start</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="database_snapshot_table_title">End</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="database_snapshot_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="database_snapshot_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['snapshots'] as $one_database_snapshot) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="database_list_id"><span class="database_id database_listing">' . $one_database_snapshot['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_instance_name"><span class="database_instance_name database_listing">' . $one_database_snapshot['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_snapshot_size"><span class="database_snapshot_name database_listing">' . $one_database_snapshot['size'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="database_list_snapshot_start_time"><span class="database_snapshot_start_time database_listing">' . $one_database_snapshot['start_time'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="database_list_snapshot_end_time"><span class="database_snapshot_end_time database_listing">' . $one_database_snapshot['end_time'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_snapshot_description"><span class="database_snapshot_description database_listing">' . $one_database_snapshot['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_database_snapshot_' . $one_database_snapshot['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-snapshot-edit btn btn-outline-primary"
                                   data-database-snapshot-id="' . $one_database_snapshot['id'] . '"
                                   data-database-snapshot-name="' . $one_database_snapshot['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_database_snapshot_' . $one_database_snapshot['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-snapshot-delete btn btn-outline-danger"
                                   data-database-snapshot-id="' . $one_database_snapshot['id'] . '"
                                   data-database-snapshot-name="' . $one_database_snapshot['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Snapshots</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-database-snapshot-create" class="btn btn-success go-database-snapshot-create">New Snapshot</span>
            <span id="go-database-landing" class="btn btn-warning go-database-landing">Database Home</span>
            <span id="go-database-snapshot-list" class="btn-refresh btn btn-info go-database-snapshot-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing Database Snapshots</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_snapshot_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_snapshot_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_snapshot_awscli_aws" class="instruction_set instruction_set_list_snapshot_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_snapshot_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_snapshot_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_snapshot_ptc_aws" class="instruction_set instruction_set_list_snapshot_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceScheduled nstanceSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};