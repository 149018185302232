<?php

Namespace Model\SC1;

use ISOPHP\core;

class ScaledInstanceGroupPageModel extends \Model\Base {

// $bind = \Model\SC1\PageModel::bindButtons();
    public function showSC1ScaledInstanceGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'ScaledInstanceGroupCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 ScaledInstanceGroup Create 2', $vars);
        };
    }

    public function addSC1ScaledInstanceGroupCreateButtonChanges($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');

            $jQuery = \ISOPHP\js_core::$jQuery;
            $by_date_cron_radio = $jQuery('input[type=radio][name=by_date_cron]');
            $by_date_cron_radio->on('change', function($jqThis) use ($jQuery) {
                $by_date_cron_val = $jQuery('input[type=radio][name=by_date_cron]')->val() ;
                if ($by_date_cron_val === 'date'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($by_date_cron_val === 'cron'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

            $frequency_radio = $jQuery('input[type=radio][name=frequency]');
            $frequency_radio->on('change', function($jqThis) use ($jQuery) {
                $frequency_val = $jQuery('input[type=radio][name=frequency]')->val() ;
                if ($frequency_val === 'once'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($frequency_val === 'repeat'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

        };
    }

    public function showSC1ScaledInstanceGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/scaledinstancegroup/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1ScaledInstanceGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1ScaledInstanceGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 ScaledInstanceGroup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 ScaledInstanceGroup List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'ScaledInstanceGroupList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 ScaledInstanceGroup List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 ScaledInstanceGroup List failed');
            }
        };
        return $func;
    }

    public function showSC1ScaledInstanceGroupExecutionLogList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 ExecutionLog List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/scaledinstancegroup/executionlog/all';
            $afterwards = $this->applySC1ScaledInstanceGroupExecutionLogListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1ScaledInstanceGroupExecutionLogListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 ScaledInstanceGroup ExecutionLog List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 ScaledInstanceGroup ExecutionLog List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'ScaledInstanceGroupExecutionLogList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 ScaledInstanceGroup ExecutionLog List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 ScaledInstanceGroup ExecutionLog List failed');
            }
        };
        return $func;
    }

    /**
     * Save scaledinstancegroup
     */
    public function saveScaledInstanceGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save scaledinstancegroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'scaledinstancegroup-create';
        $vars['api_uri'] = '/api/sc1/scaledinstancegroup/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save scaledinstancegroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveScaledInstanceGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveScaledInstanceGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 scaledinstancegroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['scaledinstancegroup']['sc1_scaledinstancegroup_name'].' for Instance '.$request_data['scaledinstancegroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 scaledinstancegroup saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'scaledinstancegroup-list', array(), '/sc1/scaledinstancegroup/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['scaledinstancegroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 scaledinstancegroup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete scaledinstancegroup
     */
    public function deleteScaledInstanceGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete scaledinstancegroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'scaledinstancegroup-delete';
        $vars['api_uri'] = '/api/sc1/scaledinstancegroup/delete';
        $vars['scaledinstancegroup_id'] = $pageVars['params']['scaledinstancegroup_id'];
        \ISOPHP\js_core::$console->log('delete scaledinstancegroup vars');
        \ISOPHP\js_core::$console->log($pageVars);
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteScaledInstanceGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteScaledInstanceGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 scaledinstancegroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['scaledinstancegroup']['sc1_scaledinstancegroup_name'].' for Instance '.$request_data['scaledinstancegroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 scaledinstancegroup deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'scaledinstancegroup-list', array(), '/sc1/scaledinstancegroup/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['scaledinstancegroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 scaledinstancegroup delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete scaledinstancegroup
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                $go_sc1_scaledinstancegroup_list = $jQuery('.go-sc1-scaledinstancegroup-list');
                $go_sc1_scaledinstancegroup_list->off() ;
                $go_sc1_scaledinstancegroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scaledinstancegroup-list', array(), '/sc1/scaledinstancegroup/list');
                });

                $go_sc1_scaledinstancegroup_list_page = $jQuery('.sc1-scaledinstancegroup-paginator');
                $go_sc1_scaledinstancegroup_list_page->off() ;
                $go_sc1_scaledinstancegroup_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 scaledinstancegroup paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'scaledinstancegroup-list', array('page' => $new_page), '/sc1/scaledinstancegroup/list/page/'.$new_page);
                });

                $go_sc1_scaledinstancegroup_executionlog_list = $jQuery('.go-sc1-scaledinstancegroup-executionlog-list');
                $go_sc1_scaledinstancegroup_executionlog_list->off() ;
                $go_sc1_scaledinstancegroup_executionlog_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scaledinstancegroup-executionlog-list', array(), '/sc1/scaledinstancegroup/executionlog/list');
                });

                $go_sc1_scaledinstancegroup_create = $jQuery('.go-sc1-scaledinstancegroup-create');
                $go_sc1_scaledinstancegroup_create->off() ;
                $go_sc1_scaledinstancegroup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scaledinstancegroup-create', array(), '/sc1/scaledinstancegroup/create');
                });

                $save_scaledinstancegroup = $jQuery('.attempt-scaledinstancegroup-create');
                $save_scaledinstancegroup->off() ;
                $save_scaledinstancegroup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scaledinstancegroup-save-attempt', array());
                });

                $scaledinstancegroup_delete = $jQuery('.attempt-scaledinstancegroup-delete');
                $scaledinstancegroup_delete->off() ;
                $scaledinstancegroup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $scaledinstancegroup_name = $jqThis->attr('data-sc1-scaledinstancegroup-name') ;
//                    $scaledinstancegroup_id = $jqThis->attr('data-sc1-scaledinstancegroup-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked scaledinstancegroup delete button');
//                    if ($scaledinstancegroup_name !== '') {
//                        $name_or_id = $scaledinstancegroup_name ;
//                    } else {
//                        $name_or_id = $scaledinstancegroup_id ;
//                    }
//                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $scaledinstancegroup_id) {
//                        $navigate->route('SC1', 'scaledinstancegroup-delete-attempt', array(
//                            'scaledinstancegroup_id' => $scaledinstancegroup_id)
//                        );
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}