<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = '   
        <div class="row">
         
            <div class="col-sm-12 col-12">
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-paymentmethod-create" class="btn btn-success attempt-paymentmethod-create">Create</span>
                        <span id="go-billing-invoice-list" class="btn btn-info go-billing-invoice-list">Invoice List</span>
                        <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
                    </div>
                        
                    <div>
                        <h4>Pay Invoice: ' . $rqd['invoice_id'] . '</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Payment Method Name</label>
                            <input type="text" name="paymentmethod_name" class="form-control" id="paymentmethod_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Comment</label>
                            <textarea name="comment" id="comment" class="form-control" rows="10" cols="30" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-paymentmethod-create" class="btn btn-success attempt-paymentmethod-create">Create</span>
                        <span id="go-billing-invoice-list" class="btn btn-info go-billing-invoice-list">Invoice List</span>
                        <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
                    </div>
                    
                </div>
            </div>
            
        </div>';

    return $html;

};
