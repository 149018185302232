<?php

Namespace Model\Logout;

class PageModel extends \Model\Base
{

    public $returned_data;

    public function getPage()
    {
        $page = array();
        $page['title'] = 'Logout of your Piranha Web Services account';
        $page['heading'] = 'Thanks for using Piranha Web Services!';
        \ISOPHP\js_core::$console->log('Logout Mod', $page);
        return $page;
    }


    public function performLogout()
    {
        $vars['control'] = 'Logout';
        $vars['action'] = 'now';
//        $registry = \ISOPHP\core::$registry;
//        $logged = $registry->getValue('user_logged');

        \ISOPHP\js_core::$console->log('perform logout started');
        \Core\WindowMessage::closeOverlay();
        
        \ISOPHP\js_core::$window->clearInterval(\Model\Login\PageModel::$session_watcher);
        
        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
//        \ISOPHP\js_core::$console->log('$cookies is ', $cookies);
        \Model\DefaultModule\PageModel::emptyCookieArray(true) ;
//        \ISOPHP\js_core::$console->log('$cookies emptied');
        
        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
//        \ISOPHP\js_core::$console->log('$cookies is ', $cookies);
        
//        $home_page_url = \ISOPHP\js_core::$window->location->origin ;
//        \ISOPHP\js_core::$console->log('apply logout done, now navigate to: '.$home_page_url);
//        \ISOPHP\js_core::$window->location->assign($home_page_url) ;
        \ISOPHP\js_core::$console->log('apply logout done, now navigate to: Login/show');
        $navigate = new \Model\Navigate();
        $navigate->route('Login', 'show', array(), '/');
        $result = true;
        return $result;
    }

}