<?php

Namespace Controller;

class DriveController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Drive controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Drive, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Drive\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Drive.php';
            $res->type = 'view';
            $res->view_control = 'Drive';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showDriveLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'directory-list') {
                $res->post_template[] = $page_model->showDriveDirectoryList($pageVars);
            } else if ($pageVars['route']['action'] == 'directory-create') {
                $res->post_template[] = $page_model->showDriveDirectoryCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
//                $res->post_template[] = $page_model->showDriveDirectoryListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindDirectoryListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showDriveObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'object-create') {
                $res->post_template[] = $page_model->showDriveObjectCreate($pageVars);
//                $res->post_template[] = $page_model->showDriveDirectoryListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindDirectoryListDropDownButtonsForObjectCreate();
            } else if ($pageVars['route']['action'] == 'directory-save-attempt') {
                $page_model->saveDirectoryAttemptToDrive($pageVars);
                $res->view = 'Drive.php';
                $res->control = 'Drive';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToDrive($pageVars);
                $res->view = 'Drive.php';
                $res->control = 'Drive';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'directory-delete-attempt') {
                $page_model->deleteDirectoryAttemptToDrive($pageVars);
                $res->view = 'Drive.php';
                $res->control = 'Drive';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToDrive($pageVars);
                $res->view = 'Drive.php';
                $res->control = 'Drive';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-download-attempt') {
                $page_model->downloadObjectAttemptFromDrive($pageVars);
                $res->view = 'Drive.php';
                $res->control = 'Drive';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showDriveLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Drive Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}