<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to SC1, the Compute Service provided by Piranha Web Services</h1>
            </div>
        
            <div class="col-sm-12 row-margin-top">
                
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Instances</h3>
                        <p>Our Compute service provides Virtual Machines in a range on configurations.</p>            
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-instance-list" class="go-sc1-instance-list btn btn-lg btn-warning landing-label">Instances</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-size-list" class="go-sc1-size-list btn btn-lg btn-warning landing-label">Sizes</span>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-launchtemplate-list" class="go-sc1-launchtemplate-list btn btn-lg btn-warning landing-label">Launch Templates</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-scheduledinstance-list" class="go-sc1-scheduledinstance-list btn btn-lg btn-warning l4anding-label disabled">Scheduled Instances</span>
                        </div>
                    </div>
                    
                </div> ' ;

    /*
            <div class="col-sm-4">
                <span id="go-sc1-capacityreservation-list" class="go-sc1-capacityreservation-list btn btn-lg btn-warning landing-label disabled">Capacity <br> Reservation</span>
            </div>
            <div class="col-sm-4">
                <span id="go-sc1-spotrequest-list" class="go-sc1-spotrequest-list btn btn-lg btn-warning disabled landing-label">Spot Requests</span>
            </div>
            <div class="col-sm-4">
                <span id="go-sc1-reservations" class="go-sc1-reservations btn btn-lg btn-warning disabled landing-label">Reservations</span>
            </div>
            <div class="col-sm-4">
                <span id="go-sc1-dedicated-list" class="go-sc1-dedicated-list btn btn-lg btn-warning disabled landing-label">Dedicated <br> Hosts</span>
            </div>
     */

    $html = $html . '  
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Images</h3>
                        <p>Management of Disk Images, created by ourselves and you.</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                   
                        <div class="col-sm-6">
                            <span id="go-sc1-image-list" class="btn btn-lg btn-warning go-sc1-image-list">Machine Images</span>
                        </div>
                        <div class="col-sm-6">
                            &nbsp;
                        </div>
                                        
                    </div>
                </div>
            </div>';
    /*
            <div class="col-sm-6">
                <span id="go-sc1-image-tasks" class="btn btn-lg btn-warning go-sc1-image-list disabled landing-label">SMI <br> Tasks</span>
            </div>
     */
    $html = $html . '
            
            <div class="col-sm-12 row-margin-top">                
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Block Storage</h3>
                        <p>Block Storage Volumes</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-volume-list" class="btn btn-lg btn-warning go-sc1-volume-list landing-label disabled">Volumes</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-backup-list" class="btn btn-lg btn-warning go-sc1-backup-list landing-label disabled">Backups</span>
                        </div>                            
                    </div>
                    
                    <div class="col-sm-12 landing-row">            
                        <div class="col-sm-6">
                            <span id="go-sc1-snapshot-list" class="btn btn-lg btn-warning go-sc1-snapshot-list landing-label disabled">Snapshots</span>
                        </div>
                        <div class="col-sm-6">
                            &nbsp;&nbsp;
                        </div>                                       
                    </div>
                    
                </div> ';

    /*
            <div class="col-sm-4">
                <span id="go-sc1-block-lifecycle" class="btn btn-lg btn-warning disabled go-sc1-block-lifecycle landing-label">Lifecycle <br> Manager</span>
            </div>
     */
    $html = $html . '  
                
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Networking &amp; Security</h3>
                        <p>Providing Control over the networks in which your resources reside.</p>            
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-securitygroup-list" class="go-sc1-securitygroup-list btn btn-lg btn-warning landing-label">Security Groups</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-staticip-list" class="go-sc1-staticip-list btn btn-lg btn-warning landing-label disabled">Static IPs</span>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-keypair-list" class="go-sc1-keypair-list btn btn-lg btn-warning landing-label">Key Pairs</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-networkinterface-list" class="go-sc1-networkinterface-list btn btn-lg btn-warning landing-label disabled">Network Interfaces</span>
                        </div>
                    </div>
                    
                </div>
                
            </div> ';

    /*
     *
            <div class="col-sm-4">
                <span id="go-sc1-placementgroup-list" class="go-sc1-placement-groups btn btn-lg btn-warning disabled landing-label">Placement <br> Groups</span>
            </div>
     */
    $html = $html . ' 
            
            <div class="col-sm-12 row-margin-top">
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Load Balancing</h3>
                        <p>Distributing your application across servers for resilience.</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-sc1-loadbalancer-list" class="go-sc1-loadbalancer-list btn btn-lg btn-warning landing-label">Load Balancers</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-balancedgroup-list" class="go-sc1-balancedgroup-list btn btn-lg btn-warning landing-label">Balanced Groups</span>
                        </div>                    
                    </div>
                    
                </div>
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Scaled Instances</h3>
                        <p>Ensuring your Applications can scale with their load.</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                   
                        <div class="col-sm-6">
                            <span id="go-sc1-launchconfiguration-list" class="go-sc1-launchconfiguration-list btn btn-lg btn-warning landing-label disabled">Launch Configurations</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sc1-scaledinstancegroup-list" class="go-sc1-scaledinstancegroup-list btn btn-lg btn-warning landing-label disabled">Scaled Instance <br> Groups</span>
                        </div>
                        
                    </div>
                </div>
                
            </div>
          
        </div>';

    return $html ;

} ;