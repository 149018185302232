<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $commits_are_array = \ISOPHP\core::$php->is_array($rqd['commits']) ;
    if ($commits_are_array === true) {
        $commits_exist = true ;
    } else {
        $commits_exist = false ;
    }
    if ($commits_exist === true) {
        $commit_count = \ISOPHP\core::$php->count($rqd['commits']) ;
    } else {
        $commit_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
            <span id="go-scm-commit-list" class="btn btn-info go-scm-commit-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Repository List</h4>
        </div> ' ;

    if ($commits_exist === true && $commit_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <h5 class="scm_commit_id_title">Message</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="scm_commit_id_title">Author</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="scm_commit_id_title">Date</h5>
                    </div>
                    <div class="col-sm-5 col-5">
                        <h5 class="scm_commit_id_title">Hash</h5>
                    </div>
                 </div>';
        foreach ($rqd['commits'] as $one_scm_commit) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_scm_commit">
                    <div class="col-sm-2 col-2">
                        <p class="scm_list_id"><span class="scm_id scm_listing">' . $one_scm_commit['message'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="scm_list_name"><span class="scm_name scm_listing">' . $one_scm_commit['author'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="scm_list_description scm_listing">' . $one_scm_commit['date'] . '</p>
                    </div>                      
                    <div class="col-sm-3 col-3">
                        <p class="scm_list_description scm_listing">' . $one_scm_commit['hash'] . '</p>
                    </div>                      
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Commits</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
                <span id="go-scm-commit-list" class="btn btn-info go-scm-commit-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};