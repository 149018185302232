<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    Tracking Url: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$rqd['application']['tracking_url'] ;
    $html = $html . '  </div>' ;

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    Application ID: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$rqd['application_id'] ;
    $html = $html . '  </div>' ;

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    Tracking Code: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    <pre class="code">
    
            var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(["trackPageView"]);
            _paq.push(["enableLinkTracking"]);
            (function() {
                var u="//'.$rqd['tracking_host'].'/";
                _paq.push(["setTrackerUrl", u+""]);
                _paq.push(["setSiteId", "'.$rqd['application_id'].'"]);
                var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
                g.type="text/javascript";
                g.async=true;
                g.src="//'.$rqd['tracking_host'].'/";
                g.onload=tracking_code_load_success;
                g.onerror=tracking_code_load_failure;
                s.parentNode.insertBefore(g,s);
            })();
             
             ' ;
    $html = $html . '    </pre>' ;
    $html = $html . '  </div>' ;

    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    &nbsp;';
    $html = $html . '  </div>' ;
    
    return $html;

};