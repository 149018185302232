<?php

Namespace Controller;

class SS3Controller extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SS3 controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SS3, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SS3\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SS3.php';
            $res->type = 'view';
            $res->view_control = 'SS3';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSS3Landing($pageVars);
            } else if ($pageVars['route']['action'] == 'bucket-list') {
                $res->post_template[] = $page_model->showSS3BucketList($pageVars);
            } else if ($pageVars['route']['action'] == 'bucket-create') {
                $res->post_template[] = $page_model->showSS3BucketCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
//                $res->post_template[] = $page_model->showSS3BucketListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindBucketListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showSS3ObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'object-create') {
                $res->post_template[] = $page_model->showSS3ObjectCreate($pageVars);
//                $res->post_template[] = $page_model->showSS3BucketListDropDown($pageVars);
//                $res->post_template[] = $page_model->bindBucketListDropDownButtonsForObjectCreate();
            } else if ($pageVars['route']['action'] == 'bucket-save-attempt') {
                $page_model->saveBucketAttemptToSS3($pageVars);
                $res->view = 'SS3.php';
                $res->control = 'SS3';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToSS3($pageVars);
                $res->view = 'SS3.php';
                $res->control = 'SS3';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'bucket-delete-attempt') {
                $page_model->deleteBucketAttemptToSS3($pageVars);
                $res->view = 'SS3.php';
                $res->control = 'SS3';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToSS3($pageVars);
                $res->view = 'SS3.php';
                $res->control = 'SS3';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-download-attempt') {
                $page_model->downloadObjectAttemptFromSS3($pageVars);
                $res->view = 'SS3.php';
                $res->control = 'SS3';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSS3Landing($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SS3 Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}