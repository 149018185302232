<?php

\Core\View::$template = function () {

    $pv = \Core\View::$page_vars;

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-drive-object-create" class="btn btn-success attempt-drive-object-create">Create</span>
                        <span id="go-drive-directory-list" class="btn btn-warning go-drive-directory-list">Directory List</span>
                        <span id="go-drive-object-list" class="btn btn-warning go-drive-object-list"
                              data-drive-directory-id="' . $pv['params']['directory_name'] . '"
                              data-drive-directory-name="' . $pv['params']['directory_name'] . '">Object List</span>
                        <span id="go-drive-landing" class="btn btn-warning go-drive-landing">Drive Home</span>
                    </div>
                
                    <div>
                        <h4>Directory: <strong>'.$pv['params']['directory_name'].'</strong></h4>
                        <h5>New Drive Object</h5>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>File</label>
                                <input type="file" name="file" class="form-control" id="file" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="object_name" class="form-control" id="object_name" value="" />
                                <input type="hidden" name="directory_name" class="form-control" id="directory_name" value="'.$pv['params']['directory_name'].'" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-drive-object-create" class="btn btn-success attempt-drive-object-create">Create</span>
                            <span id="go-drive-directory-list" class="btn btn-warning go-drive-directory-list">Directory List</span>
                            <span id="go-drive-object-list" class="btn btn-warning go-drive-object-list"
                                  data-drive-directory-id="' . $pv['params']['directory_name'] . '"
                                  data-drive-directory-name="' . $pv['params']['directory_name'] . '">Object List</span>
                            <span id="go-drive-landing" class="btn btn-warning go-drive-landing">Drive Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';


    return $html;

};
