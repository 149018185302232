<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $buckets_are_array = \ISOPHP\core::$php->is_array($rqd['buckets']) ;
    if ($buckets_are_array === true) {
        $buckets_exist = true ;
    } else {
        $buckets_exist = false ;
    }
    if ($buckets_exist === true) {
        $bucket_count = \ISOPHP\core::$php->count($rqd['buckets']) ;
    } else {
        $bucket_count = false ;
    }
    if ($buckets_exist === true && $bucket_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your bucket:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="bucket_name" id="bucket_name">';
        foreach ($rqd['buckets'] as $one_ss3_bucket) {
            $html = $html . '<option value="' . $one_ss3_bucket['ss3_bucket_name'] . '">' . $one_ss3_bucket['ss3_bucket_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available buckets</option>
             </select>' ;

    }

    $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-ss3-landing" class="btn btn-info go-ss3-landing">SS3 Home</span>
             </div>' ;

    return $html;

};