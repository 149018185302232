<?php

Namespace View;

class SVPCView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displaySVPC($binder)
    {
        \ISOPHP\js_core::$console->log('display svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#svpc_content')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySVPCVPCDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display svpc svpc dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#svpc_vpc_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySVPCSubnetDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display svpc subnet dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#svpc_subnet_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySVPCVPCMultiSelect($binder)
    {
        \ISOPHP\js_core::$console->log('display svpc vpc multi select');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
        \ISOPHP\js_core::$console->log('display svpc vpc multi select data', $tpl_data) ;
        $jQuery('#svpc_vpc_multiselect')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySVPCNewPost($binder)
    {
        \ISOPHP\js_core::$console->log('display new svpc form');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
        $jQuery('#svpc_list')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

}