<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $executions_are_array = \ISOPHP\core::$php->is_array($rqd['executions']['history_index']) ;
    if ($executions_are_array === true) {
        $executions_exist = true ;
    } else {
        $executions_exist = false ;
    }
    if ($executions_exist === true) {
        $execution_count = \ISOPHP\core::$php->count($rqd['executions']['history_index']) ;
    } else {
        $execution_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-landing" class="btn btn-warning go-build-landing">Build Home</span>
            <span id="go-build-job-list" class="btn btn-success go-build-job-list">Job List</span>
            <span id="go-job-execution-list" data-build-job-id="' . $rqd['job_id'] .'" class="btn btn-info go-job-execution-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
       '<div class="col-sm-12 col-12">
            <h4>Job Execution List</h4>
         </div> ' ;

    if ($executions_exist === true && $execution_count !== false) {
        $html = $html .
              '<div class="col-sm-12 col-12 one_build_job">
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">Start</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">Time</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">Status</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">Params</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_job_table_title">Log</h5>
                    </div>                     
               </div>';
        foreach ($rqd['executions']['history_index'] as $one_build_execution_details) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="build_list_id"><span class="build_id build_listing">' . $one_build_execution_details['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_start_time"><span class="run_start build_listing">' . $one_build_execution_details['start_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_duration"><span class="run_end build_list_duration">' . $one_build_execution_details['duration_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_build_name"><span class="build_job_name build_listing">' . $one_build_execution_details['status'] . '</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <span class="build_type build_listing">'  ;

            $params_are_array = \ISOPHP\core::$php->is_array($one_build_execution_details['params']) ;
            if ($params_are_array === true) {
                $execution_count = \ISOPHP\core::$php->count($rqd['executions']['history_index']) ;
                if ($execution_count > 0) {
                    foreach ( $one_build_execution_details['params'] as $parameter_key => $parameter_value) {
                        $html =  $html . '<input type="hidden" class="execution_parameters_'.$rqd['job_id'].'_'.$one_build_execution_details['id'].'" name="'.$parameter_key.'" value="'.$parameter_value.'" />' ;
                    }
                    $html =  $html . '                      
                        <span id="show_build_job_execution_parameters_'.$rqd['job_id'].'_'.$one_build_execution_details['id'].'" 
                         class="btn btn-outline-primary show-build-job-execution-parameters" data-build-job-id="'
                        .$rqd['job_id'] . '" data-build-job-execution-id="'.$one_build_execution_details['id'].'">'
                        .' View </span>' ;
                } else {
                    $html =  $html . '<span>No Parameters Included</span>' ;
                }
            }

            $html = $html .
                       '</span>
                    </div>
                    <div class="col-sm-2 col-2">                        
                        <span id="show_build_job_execution_log_'.$rqd['function_id'].'_'.$one_build_execution_details['id'].'" 
                         class="btn btn-outline-primary show-job-execution-log" data-build-job-id="'
                         .$rqd['job_id'] . '" data-build-job-execution-id="'.$one_build_execution_details['id'].'">'
                         .' View </span>
                    </div>
                 </div>';
        }
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-landing" class="btn btn-warning go-build-landing">Build Home</span>
            <span id="go-build-job-list" class="btn btn-success go-build-job-list">Job List</span>
            <span id="go-job-execution-list" data-build-job-id="' . $rqd['job_id'] . '" class="btn btn-info go-job-execution-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

}; 