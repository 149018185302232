<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $repositories_are_array = \ISOPHP\core::$php->is_array($rqd['repositories']) ;
    if ($repositories_are_array === true) {
        $repositories_exist = true ;
    } else {
        $repositories_exist = false ;
    }
    if ($repositories_exist === true) {
        $repository_count = \ISOPHP\core::$php->count($rqd['repositories']) ;
    } else {
        $repository_count = false ;
    }
    if ($repositories_exist === true && $repository_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your repository:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="repository_name" id="repository_name">';
        foreach ($rqd['repositories'] as $one_scm_repository) {
            $html = $html . '<option value="' . $one_scm_repository['scm_repository_name'] . '">' . $one_scm_repository['scm_repository_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available repositories</option>
             </select>' ;

    }

    $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-scm-landing" class="btn btn-info go-scm-landing">SCM Home</span>
             </div>' ;

    return $html;

};