<?php

Namespace Model\SSQS;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SSQS List of Piranha Web Services';
        $page['heading'] = 'List of SSQS';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SSQS Mod', $page, $pageVars);
        return $page;
    }

    public function showSSQSLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SSQSView();
            $view->loadTemplate('SSQS', 'SSQSLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySSQS($bind);
            \ISOPHP\js_core::$console->log('apply SSQS Queue Create 2', $vars);
        };
    }

    public function showSSQSQueueCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SSQSView();
            $view->loadTemplate('SSQS', 'QueueCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySSQS($bind);
            \ISOPHP\js_core::$console->log('apply SSQS Queue Create 2', $vars);
        };
    }

    public function showSSQSQueueList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS List');
            $vars['control'] = 'SSQS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/ssqs/queue/all';
            $afterwards = $this->applySSQSQueueListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SSQS Model: ', $result);
            return $result;
        };
    }

    public function applySSQSQueueListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SSQS Queue List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SSQS Queue List 1', $vars);
                $view = new \View\SSQSView();
                $view->loadTemplate('SSQS', 'QueueList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySSQS($bind);
                \ISOPHP\js_core::$console->log('apply SSQS Queue List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SSQS Queue List failed');
            }
        };
        return $func;
    }

    public function showSSQSQueueListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS List');
            $vars['control'] = 'SSQS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/ssqs/queue/all';
            $afterwards = $this->applySSQSQueueListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SSQS Model: ', $result);
            return $result;
        };
    }

    public function applySSQSQueueListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SSQS Queue List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SSQS Queue List 1', $vars);
                $view = new \View\SSQSView();
                $view->loadTemplate('SSQS', 'QueueListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySSQSQueueDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SSQS Queue List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SSQS Queue List failed');
            }
        };
        return $func;
    }

    public function showSSQSMessageCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SSQSView();
            $view->loadTemplate('SSQS', 'MessageCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySSQS($bind);
            \ISOPHP\js_core::$console->log('apply SSQS Message Create 2', $vars);
        };
    }

    public function showSSQSMessageList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SSQS List');
            $vars['control'] = 'SSQS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/ssqs/message/all';
            $afterwards = $this->applySSQSMessageListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SSQS Model: ', $result);
            return $result;
        };
    }

    public function applySSQSMessageListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SSQS Message List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SSQS Message List 1', $vars);
                $view = new \View\SSQSView();
                $view->loadTemplate('SSQS', 'MessageList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySSQS($bind);
                \ISOPHP\js_core::$console->log('apply SSQS Message List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SSQS Message List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);
//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save queue
     */
    public function saveQueueAttemptToSSQS($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save queue to ssqs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SSQS';
        $vars['action'] = 'queue-create';
        $vars['api_uri'] = '/api/ssqs/queue/create';
        $vars['queue_name'] = $jQuery('#queue_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveQueueResultFromSSQS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveQueueResultFromSSQS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save ssqs queue result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Queue '.$request_data['queue']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ssqs queue saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SSQS', 'queue-list', array(), '/ssqs/queue/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Queue '.$request_data['queue']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ssqs queue save failed');
            }
        };
        return $func;
    }

    /**
     * Save message
     */
    public function saveMessageAttemptToSSQS($pageVars)
    {
        \ISOPHP\js_core::$console->log('save message to ssqs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SSQS';
        $vars['action'] = 'message-create';
        $vars['api_uri'] = '/api/ssqs/message/create';
        $vars['queue_name'] = $jQuery('#queue_name')->val();
        $vars['message_value'] = $jQuery('#message_value')->val();
        \ISOPHP\js_core::$console->log('save message vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveMessageResultFromSSQS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveMessageResultFromSSQS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save ssqs message result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Message '.$request_data['message']['ssqs_message_name'].' for Queue '.$request_data['message']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ssqs message saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Message '.$request_data['message']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ssqs message save failed');
            }
        };
        return $func;
    }

    /**
     * Delete queue
     */
    public function deleteQueueAttemptToSSQS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete queue to ssqs');
        \ISOPHP\js_core::$console->log($pageVars);
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SSQS';
        $vars['action'] = 'queue-delete';
        $vars['api_uri'] = '/api/ssqs/queue/delete';
        $vars['queue_name'] = $pageVars['params']['queue_name'];
        \ISOPHP\js_core::$console->log('delete queue vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteQueueResultFromSSQS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteQueueResultFromSSQS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete ssqs queue result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Queue '.$request_data['queue']['ssqs_queue_name'].' for Queue '.$request_data['queue']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ssqs queue deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SSQS', 'queue-list', array(), '/ssqs/queue/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Queue '.$request_data['queue']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ssqs queue delete failed');
            }
            $show_queue_list = self::showSSQSQueueList($pageVars) ;
            $show_queue_list() ;
        };
        return $func;
    }
    /**
     * Delete message
     */
    public function deleteMessageAttemptToSSQS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete message to ssqs');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SSQS';
        $vars['action'] = 'message-delete';
        $vars['api_uri'] = '/api/ssqs/message/delete';
        $vars['ssqs_queue_name'] = $jQuery('#queue_name')->val();
        $vars['ssqs_queue_id'] = $jQuery('#queue_id')->val();
        \ISOPHP\js_core::$console->log('delete message vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveMessageResultFromSSQS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteMessageResultFromSSQS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete ssqs message result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Message '.$request_data['message']['ssqs_message_name'].' for Queue '.$request_data['message']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('ssqs message deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Message '.$request_data['message']['ssqs_queue_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('ssqs message delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_ssqs_landing = $jQuery('.go-ssqs-landing');
                $go_ssqs_landing->off() ;
                $go_ssqs_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'landing', array(), '/ssqs');
                });

                $go_ssqs_queue_list = $jQuery('.go-ssqs-queue-list');
                $go_ssqs_queue_list->off() ;
                $go_ssqs_queue_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'queue-list', array(), '/ssqs/queue/list');
                });

                $go_ssqs_queue_create = $jQuery('.go-ssqs-queue-create');
                $go_ssqs_queue_create->off() ;
                $go_ssqs_queue_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'queue-create', array(), '/ssqs/queue/create');
                });

                $save_queue = $jQuery('.attempt-queue-create');
                $save_queue->off() ;
                $save_queue->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'queue-save-attempt', array());
                });

                $go_ssqs_message_list = $jQuery('.go-ssqs-message-list');
                $go_ssqs_message_list->off() ;
                $go_ssqs_message_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'message-list', array(), '/ssqs/message/list');
                });

                $go_ssqs_message_create = $jQuery('.go-ssqs-message-create');
                $go_ssqs_message_create->off() ;
                $go_ssqs_message_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'message-create', array(), '/ssqs/message/create');
                });

                $save_message = $jQuery('.attempt-message-create');
                $save_message->off() ;
                $save_message->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SSQS', 'message-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $message_delete = $jQuery('.attempt-message-delete');
                $message_delete->off() ;
                $message_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $message_name = $jqThis->attr('data-ssqs-message-name') ;
                    $message_id = $jqThis->attr('data-ssqs-message-id') ;
                    \ISOPHP\js_core::$console->log('Clicked message delete button');
                    $msg = "You are about to delete the Message for $message_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $message_id) {
                        $navigate->route('SSQS', 'message-delete-attempt', array('message_id' => $message_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

                $queue_delete = $jQuery('.attempt-queue-delete');
                $queue_delete->off() ;
                $queue_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $queue_name = $jqThis->attr('data-ssqs-queue-name') ;
                    $queue_id = $jqThis->attr('data-ssqs-queue-id') ;
                    \ISOPHP\js_core::$console->log('Clicked queue delete button');
                    $msg = "You are about to delete the Queue $queue_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $queue_id, $queue_name) {
                        $navigate->route('SSQS', 'queue-delete-attempt', array(
                            'queue_id' => $queue_id,
                            'queue_name' => $queue_name)
                        );
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }


}