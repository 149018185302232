<?php

Namespace Model\DNS;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'DNS List of Piranha Web Services';
        $page['heading'] = 'List of DNS';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('DNS Mod', $page, $pageVars);
        return $page;
    }

    public function showDNSLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DNSView();
            $view->loadTemplate('DNS', 'DNSLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayDNS($bind);
            \ISOPHP\js_core::$console->log('apply DNS Domain Create 2', $vars);
        };
    }

    public function showDNSDomainCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DNSView();
            $view->loadTemplate('DNS', 'DomainCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDNS($bind);
            \ISOPHP\js_core::$console->log('apply DNS Domain Create 2', $vars);
        };
    }

    public function showDNSDomainList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS List');
            $vars['control'] = 'DNS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/dns/domain/all';
            $vars['page'] = $pageVars['params']['page'] ;
            $afterwards = $this->applyDNSDomainListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DNS Model: ', $result);
            return $result;
        };
    }

    public function applyDNSDomainListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DNS Domain List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DNS Domain List 1', $vars);
                $view = new \View\DNSView();
                $view->loadTemplate('DNS', 'DomainList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDNS($bind);
                \ISOPHP\js_core::$console->log('apply DNS Domain List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DNS Domain List failed');
            }
        };
        return $func;
    }

    public function showDNSDomainListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS List');
            $vars['control'] = 'DNS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/dns/domain/all';
            $afterwards = $this->applyDNSDomainListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DNS Model: ', $result);
            return $result;
        };
    }

    public function applyDNSDomainListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DNS Domain List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DNS Domain List 1', $vars);
                $view = new \View\DNSView();
                $view->loadTemplate('DNS', 'DomainListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayDNSDomainDropDown($bind);
                \ISOPHP\js_core::$console->log('apply DNS Domain List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DNS Domain List failed');
            }
        };
        return $func;
    }

    public function showDNSRecordCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DNSView();
            $view->loadTemplate('DNS', 'RecordCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDNS($bind);
            \ISOPHP\js_core::$console->log('apply DNS Record Create 2', $vars);
        };
    }

    public function showDNSRecordList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DNS List');
            $vars['control'] = 'DNS';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/dns/record/all';
            $vars['domain_name'] = $pageVars['params']['domain_name'] ;
            $vars['page'] = $pageVars['params']['page'] ;
            $afterwards = $this->applyDNSRecordListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DNS Model: ', $result);
            return $result;
        };
    }

    public function applyDNSRecordListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DNS Record List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DNS Record List 1', $vars);
                $view = new \View\DNSView();
                $view->loadTemplate('DNS', 'RecordList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDNS($bind);
                \ISOPHP\js_core::$console->log('apply DNS Record List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DNS Record List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save domain
     */
    public function saveDomainAttemptToDNS($pageVars)
    {
        \ISOPHP\js_core::$console->log('save domain to dns');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DNS';
        $vars['action'] = 'domain-create';
        $vars['api_uri'] = '/api/dns/domain/create';
        $vars['domain_name'] = $jQuery('#domain_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveDomainResultFromDNS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveDomainResultFromDNS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save dns domain result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Domain '.$request_data['domain']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('dns domain saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DNS', 'domain-list', array(), '/dns/domain/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Domain '.$request_data['domain']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('dns domain save failed');
            }
        };
        return $func;
    }

    /**
     * Save record
     */
    public function saveRecordAttemptToDNS($pageVars)
    {
        \ISOPHP\js_core::$console->log('save record to dns');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DNS';
        $vars['action'] = 'record-create';
        $vars['api_uri'] = '/api/dns/record/create';
        $vars['region'] = $jQuery('#region')->val();
        $vars['identifier'] = $jQuery('#identifier')->val();
        $vars['domain_name'] = $jQuery('#dns-domain-name')->val();
        $vars['record_name'] = $jQuery('#record_name')->val();
        $vars['record_value'] = $jQuery('#record_value')->val();
        $vars['record_type'] = $jQuery('#record_type')->val();
        $afterwards = $this->saveRecordResultFromDNS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRecordResultFromDNS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save dns record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Record '.$request_data['record']['dns_record_name'].' for Domain '.$request_data['record']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('dns record saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DNS', 'record-list', array(
                    'domain_name' => $request_data['record']['dns_domain_name']
                ), '/dns/domain/'.$request_data['record']['dns_domain_name'].'/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Record '.$request_data['record']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('dns record save failed');
            }
        };
        return $func;
    }
    /**
     * Delete record
     */
    public function deleteRecordAttemptToDNS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete record to dns');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DNS';
        $vars['action'] = 'record-delete';
        $vars['api_uri'] = '/api/dns/record/delete';
        $vars['domain_name'] = $pageVars['params']['domain_name'];
        $vars['record_name'] = $pageVars['params']['record_name'] ;
        $vars['record_id'] = $pageVars['params']['record_id'] ;
        \ISOPHP\js_core::$console->log('delete record vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $afterwards = $this->deleteRecordResultFromDNS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRecordResultFromDNS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete dns record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Record '.$request_data['record']['dns_record_name'].' for Domain '.$request_data['record']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('dns record deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DNS', 'record-list', array(
                    'domain_name' => $request_data['record']['dns_domain_name']
                ), '/dns/domain/'.$request_data['record']['dns_domain_name'].'/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Record '.$request_data['record']['dns_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('dns record delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete record
     */
    public function deleteDomainAttemptToDNS($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete record to dns');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DNS';
        $vars['action'] = 'record-delete';
        $vars['api_uri'] = '/api/dns/domain/delete';
        $vars['dns_domain_id'] = $pageVars['params']['domain_id'];
        \ISOPHP\js_core::$console->log('delete record vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteDomainResultFromDNS($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDomainResultFromDNS($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete dns domain result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Domain '.$request_data['domain'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('dns domain deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DNS', 'domain-list', array(), '/dns/domain/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Domain '.$request_data['domain'] ;
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('dns domain delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_dns_landing = $jQuery('.go-dns-landing');
                $go_dns_landing->off() ;
                $go_dns_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DNS', 'landing', array(), '/dns');
                });

                $go_dns_domain_list = $jQuery('.go-dns-domain-list');
                $go_dns_domain_list->off() ;
                $go_dns_domain_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DNS', 'domain-list', array(), '/dns/domain/list');
                });

                $go_dns_domain_create = $jQuery('.go-dns-domain-create');
                $go_dns_domain_create->off() ;
                $go_dns_domain_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DNS', 'domain-create', array(), '/dns/domain/create');
                });

                $save_domain = $jQuery('.attempt-domain-create');
                $save_domain->off() ;
                $save_domain->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DNS', 'domain-save-attempt', array());
                });

                $go_dns_record_list = $jQuery('.go-dns-record-list');
                $go_dns_record_list->off() ;
                $go_dns_record_list->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $domain_name = $jqThis->attr('data-dns-domain-name') ;
                    $navigate->route('DNS', 'record-list', array(
                        'domain_name' => $domain_name
                    ), '/dns/domain/'.$domain_name.'/record/list');
                });

                $go_dns_record_create = $jQuery('.go-dns-record-create');
                $go_dns_record_create->off() ;
                $go_dns_record_create->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
                    $this_id = $jsThis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $domain_name = $jqThis->attr('data-dns-domain-name') ;
                    $navigate->route('DNS', 'record-create', array(
                        'domain_name' => $domain_name
                    ), '/dns/domain/'.$domain_name.'/record/create');
                });

                $save_record = $jQuery('.attempt-record-create');
                $save_record->off() ;
                $save_record->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DNS', 'record-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $record_delete = $jQuery('.attempt-record-delete');
                $record_delete->off() ;
                $record_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $domain_name = $jqThis->attr('data-dns-domain-name') ;
                    $record_name = $jqThis->attr('data-dns-record-name') ;
                    $record_id = $jqThis->attr('data-dns-record-id') ;
                    \ISOPHP\js_core::$console->log('Clicked record delete button');
                    $msg = "You are about to delete the Record for $record_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $domain_name, $record_name, $record_id) {
                        $navigate->route('DNS', 'record-delete-attempt', array(
                            'domain_name' => $domain_name,
                            'record_name' => $record_name,
                            'record_id' => $record_id,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $domain_delete = $jQuery('.attempt-domain-delete');
                $domain_delete->off() ;
                $domain_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $domain_name = $jqThis->attr('data-dns-domain-name') ;
                    $domain_id = $jqThis->attr('data-dns-domain-id') ;
                    \ISOPHP\js_core::$console->log('Clicked domain delete button');
                    $msg = "You are about to delete the Domain $domain_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $domain_id) {
                        $navigate->route('DNS', 'domain-delete-attempt', array('domain_id' => $domain_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $dns_domain_paginator = $jQuery('.dns-domain-paginator');
                $dns_domain_paginator->off() ;
                $dns_domain_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked dns domain paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('DNS', 'domain-list', array(
                        'page' => $new_page
                    ), '/dns/domain/list/page/'.$new_page);
                });

                $dns_domain_paginator_direct = $jQuery('.dns-domain-paginator.paginator_direct_page');
                $dns_domain_paginator_direct->off() ;
                $dns_domain_paginator_direct->on('change', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Changed variable dns domain paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->val() ;
                    $invalid_pages = array(0, null) ;
                    if (\ISOPHP\core::$php->in_array($new_page, $invalid_pages)) {
                        $new_page = 1 ;
                    }
                    $jsthis->preventDefault();
                    $navigate->route('DNS', 'domain-list', array(
                        'page' => $new_page
                    ), '/dns/domain/list/page/'.$new_page);
                });

                $dns_record_paginator = $jQuery('.dns-record-paginator');
                $dns_record_paginator->off() ;
                $dns_record_paginator->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked dns record paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $domain_name = $jQuery('#dns-domain-name')->val();
                    $jsthis->preventDefault();
                    $navigate->route('DNS', 'record-list', array(
                        'domain_name' => $domain_name,
                        'page' => $new_page
                    ), '/dns/domain/'.$domain_name.'/record/list/page/'.$new_page);
                });

                $dns_record_paginator_direct = $jQuery('.dns-record-paginator.paginator_direct_page');
                $dns_record_paginator_direct->off() ;
                $dns_record_paginator_direct->on('change', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Changed variable dns record paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->val() ;
                    $domain_name = $jQuery('#dns-domain-name')->val();
                    $invalid_pages = array(0, null) ;
                    if (\ISOPHP\core::$php->in_array($new_page, $invalid_pages)) {
                        $new_page = 1 ;
                    }
                    $jsthis->preventDefault();
                    $navigate->route('DNS', 'record-list', array(
                        'domain_name' => $domain_name,
                        'page' => $new_page
                    ), '/dns/domain/'.$domain_name.'/record/list/page/'.$new_page);
                });


            }
        };
    }


}
