<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $repositories_are_array = \ISOPHP\core::$php->is_array($rqd['repositories']) ;
    if ($repositories_are_array === true) {
        $repositories_exist = true ;
    } else {
        $repositories_exist = false ;
    }
    if ($repositories_exist === true) {
        $repository_count = \ISOPHP\core::$php->count($rqd['repositories']) ;
    } else {
        $repository_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-closure-repository-create" class="btn btn-success go-closure-repository-create">New Repository</span>
            <span id="go-closure-landing" class="btn btn-info go-closure-landing">Closure Home</span>
            <span id="go-closure-repository-list" class="btn btn-info go-closure-repository-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Repository List</h4>
        </div> ' ;

    if ($repositories_exist === true && $repository_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-1 col-1">
                        &nbsp;
                    </div>
                    <div class="col-sm-10 col-10 one_closure_repository">
                        <div class="col-sm-3 col-3">
                            <h5 class="closure_repository_id_title">Closure Repository ID</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="closure_repository_id_title">Repository Name</h5>
                        </div>
                        <div class="col-sm-5 col-5">
                            <h5 class="closure_repository_id_title">Comment</h5>
                        </div>                        
                    </div>
                    <div class="col-sm-1 col-1">
                        &nbsp;
                    </div>
                 </div>';
        foreach ($rqd['repositories'] as $one_closure_repository) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_closure_repository">
                    <div class="col-sm-3 col-3">
                        <p class="closure_list_id"><span class="closure_id closure_listing">' . $one_closure_repository['closure_repository_id'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="closure_list_name"><span class="closure_name closure_listing">' . $one_closure_repository['closure_repository_name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="closure_list_comment closure_listing">' . $one_closure_repository['closure_repository_comment'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_closure_repository_' . $one_closure_repository['closure_repository_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-repository-edit btn btn-outline-primary"
                                   data-closure-repository-id="' . $one_closure_repository['closure_repository_id'] . '"
                                   data-closure-repository-name="' . $one_closure_repository['closure_repository_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_closure_repository_' . $one_closure_repository['closure_repository_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-repository-delete btn btn-outline-danger"
                                   data-closure-repository-id="' . $one_closure_repository['closure_repository_id'] . '"
                                   data-closure-repository-name="' . $one_closure_repository['closure_repository_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Repositories</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-closure-repository-create" class="btn btn-success go-closure-repository-create">New Repository</span>
                <span id="go-closure-landing" class="btn btn-info go-closure-landing">Closure Home</span>
                <span id="go-closure-repository-list" class="btn btn-info go-closure-repository-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing Closure Repositories</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_repository_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_repository_awscli"></i></h5>
                    <pre id="instruction_set_list_repository_awscli" class="instruction_set instruction_set_list_repository_awscli">
aws s3 ls --profile=abc --region=hq --endpoint-url=http://aws.saharaws.vm:8888
2020-01-23 14:06:53 repository1
2020-01-23 14:06:58 repository2
                    </pre>
              ';


    return $html;

};