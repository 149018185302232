<?php

Namespace Model\CostPrediction;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'CostPrediction List of Piranha Web Services';
        $page['heading'] = 'List of CostPrediction';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('CostPrediction Mod', $page, $pageVars);
        return $page;
    }

    public function showCostPredictionLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing CostPrediction Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\CostPredictionView();
            $view->loadTemplate('CostPrediction', 'CostPredictionLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayCostPrediction($bind);
            \ISOPHP\js_core::$console->log('apply CostPrediction Prediction Create 2', $vars);
        };
    }

    public function showCostPredictionPredictionCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing CostPrediction Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\CostPredictionView();
            $view->loadTemplate('CostPrediction', 'PredictionCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayCostPrediction($bind);
            \ISOPHP\js_core::$console->log('apply CostPrediction Prediction Create 2', $vars);
        };
    }

    public function showCostPredictionPredictionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing CostPrediction List');
            $vars['control'] = 'CostPrediction';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/costprediction/prediction/all';
            $afterwards = $this->applyCostPredictionPredictionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('CostPrediction Model: ', $result);
            return $result;
        };
    }

    public function applyCostPredictionPredictionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our CostPrediction Prediction List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply CostPrediction Prediction List 1', $vars);
                $view = new \View\CostPredictionView();
                $view->loadTemplate('CostPrediction', 'PredictionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayCostPrediction($bind);
                \ISOPHP\js_core::$console->log('apply CostPrediction Prediction List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('CostPrediction Prediction List failed');
            }
        };
        return $func;
    }

    public function showCostPredictionPredictionListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing CostPrediction List');
            $vars['control'] = 'CostPrediction';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/costprediction/prediction/all';
            $afterwards = $this->applyCostPredictionPredictionListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('CostPrediction Model: ', $result);
            return $result;
        };
    }

    public function applyCostPredictionPredictionListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our CostPrediction Prediction List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply CostPrediction Prediction List 1', $vars);
                $view = new \View\CostPredictionView();
                $view->loadTemplate('CostPrediction', 'PredictionListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayCostPredictionPredictionDropDown($bind);
                \ISOPHP\js_core::$console->log('apply CostPrediction Prediction List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('CostPrediction Prediction List failed');
            }
        };
        return $func;
    }

    public function showCostPredictionObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing CostPrediction Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\CostPredictionView();
            $view->loadTemplate('CostPrediction', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayCostPrediction($bind);
            \ISOPHP\js_core::$console->log('apply CostPrediction Object Create 2', $vars);
        };
    }

    public function showCostPredictionObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for prediction name');
            \ISOPHP\js_core::$console->log($pageVars['prediction_name']);
            if (isset($pageVars['prediction_name'])) {
                \ISOPHP\js_core::$console->log('Showing CostPrediction List');
                $vars['control'] = 'CostPrediction';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/costprediction/object/all';
                $vars['prediction_name'] = $pageVars['prediction_name'];
                $afterwards = $this->applyCostPredictionObjectListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('CostPrediction Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyCostPredictionObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our CostPrediction Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply CostPrediction Object List 1', $vars);
                $view = new \View\CostPredictionView();
                $view->loadTemplate('CostPrediction', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayCostPrediction($bind);
                \ISOPHP\js_core::$console->log('apply CostPrediction Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('CostPrediction Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save prediction
     */
    public function savePredictionAttemptToCostPrediction($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save prediction to costprediction');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'CostPrediction';
        $vars['action'] = 'prediction-create';
        $vars['api_uri'] = '/api/costprediction/prediction/create';
        $vars['prediction_name'] = $jQuery('#prediction_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePredictionResultFromCostPrediction($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function savePredictionResultFromCostPrediction($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save costprediction prediction result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Prediction '.$request_data['prediction']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('costprediction prediction saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Prediction '.$request_data['prediction']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('costprediction prediction save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToCostPrediction($pageVars)
    {
        \ISOPHP\js_core::$console->log('save object to costprediction');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'CostPrediction';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/costprediction/object/create';
        $vars['prediction_name'] = $jQuery('#prediction_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $vars['object_value'] = $jQuery('#object_value')->val();
        $vars['object_type'] = $jQuery('#object_type')->val();
        \ISOPHP\js_core::$console->log('save object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromCostPrediction($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromCostPrediction($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save costprediction object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['object']['costprediction_object_name'].' for Prediction '.$request_data['object']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('costprediction object saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['object']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('costprediction object save failed');
            }
        };
        return $func;
    }

    /**
     * Delete prediction
     */
    public function deletePredictionAttemptToCostPrediction($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete prediction to costprediction');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'CostPrediction';
        $vars['action'] = 'prediction-delete';
        $vars['api_uri'] = '/api/costprediction/prediction/delete';
        $vars['prediction_name'] = $pageVars['params']['prediction_name'];
        \ISOPHP\js_core::$console->log('delete prediction vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deletePredictionResultFromCostPrediction($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deletePredictionResultFromCostPrediction($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete costprediction prediction result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Prediction '.$request_data['prediction']['costprediction_prediction_name'].' for Prediction '.$request_data['prediction']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('costprediction prediction deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Prediction '.$request_data['prediction']['costprediction_prediction_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('costprediction prediction delete failed');
            }
            $show_prediction_list = self::showCostPredictionPredictionList($pageVars) ;
            $show_prediction_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_costprediction_landing = $jQuery('.go-costprediction-landing');
                $go_costprediction_landing->off() ;
                $go_costprediction_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('CostPrediction', 'landing', array(), '/costprediction');
                });

                $go_costprediction_prediction_list = $jQuery('.go-costprediction-prediction-list');
                $go_costprediction_prediction_list->off() ;
                $go_costprediction_prediction_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('CostPrediction', 'prediction-list', array(), '/costprediction/prediction/list');
                });

                $go_costprediction_prediction_create = $jQuery('.go-costprediction-prediction-create');
                $go_costprediction_prediction_create->off() ;
                $go_costprediction_prediction_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('CostPrediction', 'prediction-create', array(), '/costprediction/prediction/create');
                });

                $save_prediction = $jQuery('.attempt-prediction-create');
                $save_prediction->off() ;
                $save_prediction->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('CostPrediction', 'prediction-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $prediction_delete = $jQuery('.attempt-prediction-delete');
                $prediction_delete->off() ;
                $prediction_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $prediction_name = $jqThis->attr('data-costprediction-prediction-name') ;
                    \ISOPHP\js_core::$console->log('Clicked prediction delete button');
                    $msg = "You are about to delete the Prediction $prediction_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $prediction_name) {
                        $navigate->route('CostPrediction', 'prediction-delete-attempt', array(
                            'prediction_name' => $prediction_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindPredictionListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding prediction switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding prediction switch');
            $switch_prediction = $jQuery('#prediction_name');
            $switch_prediction->off() ;
            $switch_prediction->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#prediction_name') ;
                $prediction_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked prediction switch');
                $navigate->route('CostPrediction', 'object-list', array(
                    'prediction_name' => $prediction_name,
                ));
            } ) ;

        };
        return $func;
    }



}