<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Artifact, your Artifact Repository</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Storage For Artifact Packages</h3>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-artifact-repository-list" class="go-artifact-repository-list btn btn-lg btn-warning">Repositories</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-artifact-repository-create" class="go-artifact-repository-create btn btn-lg btn-warning">Create Repository</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-artifact-object-list" class="go-artifact-object-list btn btn-lg btn-warning">Objects</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-artifact-object-create" class="go-artifact-object-create btn btn-lg btn-warning">Create Object</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;