<?php

Namespace Model\DefaultModule;

use Core\WindowMessage;

class PageModel extends \Model\Base
{
    public $current_token ;

    public static function bindMenu()
    {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Binding menu HS');
                $jQuery = \ISOPHP\js_core::$jQuery;
                $user_menu_html = self::getMenuHTML('user');
                $user_menu = $jQuery('#user-menu-button');
                $user_menu->html($user_menu_html);
                self::bindMenuLinks();
            }
        };
    }


    public static function bindMenuLinks() {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Binding menu links HS');

            $jQuery = \ISOPHP\js_core::$jQuery;
            $navigate = new \Model\Navigate();

            $service_menu_service_database_relational = $jQuery('.service_menu_service_database_relational');
            $service_menu_service_database_relational->off();
            $service_menu_service_database_relational->on('click', function () use ($navigate) {
                $navigate->route('Database', 'show', array(), '/database/relational');
            });

            $service_menu_service_database_document = $jQuery('.service_menu_service_database_document');
            $service_menu_service_database_document->off();
            $service_menu_service_database_document->on('click', function () use ($navigate) {
                $navigate->route('Database', 'show', array(), '/database/document');
            });

            $service_menu_service_costprediction = $jQuery('.service_menu_service_costprediction');
            $service_menu_service_costprediction->off();
            $service_menu_service_costprediction->on('click', function () use ($navigate) {
                $navigate->route('CostPrediction', 'show', array(), '/costprediction');
            });

            $service_menu_service_ss3 = $jQuery('.service_menu_service_billing');
            $service_menu_service_ss3->off();
            $service_menu_service_ss3->on('click', function () use ($navigate) {
                $navigate->route('Billing', 'show', array(), '/billing');
            });

            $service_menu_service_sc1 = $jQuery('.service_menu_service_sc1');
            $service_menu_service_sc1->off();
            $service_menu_service_sc1->on('click', function () use ($navigate) {
                $navigate->route('SC1', 'show', array(), '/sc1');
            });

            $service_menu_service_svpc = $jQuery('.service_menu_service_svpc');
            $service_menu_service_svpc->off();
            $service_menu_service_svpc->on('click', function () use ($navigate) {
                $navigate->route('SVPC', 'show', array(), '/svpc');
            });

            $service_menu_service_ss3 = $jQuery('.service_menu_service_ss3');
            $service_menu_service_ss3->off();
            $service_menu_service_ss3->on('click', function () use ($navigate) {
                $navigate->route('SS3', 'show', array(), '/ss3');
            });

            $service_menu_service_ssqs = $jQuery('.service_menu_service_ssqs');
            $service_menu_service_ssqs->off();
            $service_menu_service_ssqs->on('click', function () use ($navigate) {
                $navigate->route('SSQS', 'show', array(), '/ssqs');
            });

            $service_menu_service_smp = $jQuery('.service_menu_service_smp');
            $service_menu_service_smp->off();
            $service_menu_service_smp->on('click', function () use ($navigate) {
                $navigate->route('SMP', 'show', array(), '/smp');
            });

            $service_menu_service_smp = $jQuery('.service_menu_service_slb');
            $service_menu_service_smp->off();
            $service_menu_service_smp->on('click', function () use ($navigate) {
                $navigate->route('SLB', 'show', array(), '/slb');
            });

            $service_menu_service_dns = $jQuery('.service_menu_service_dns');
            $service_menu_service_dns->off();
            $service_menu_service_dns->on('click', function () use ($navigate) {
                $navigate->route('DNS', 'show', array(), '/dns');
            });

            $service_menu_sam = $jQuery('.service_menu_service_sam');
            $service_menu_sam->off();
            $service_menu_sam->on('click', function () use ($navigate) {
                $navigate->route('SAM', 'show', array(), '/sam');
            });

            $service_menu_closure = $jQuery('.service_menu_service_closure');
            $service_menu_closure->off();
            $service_menu_closure->on('click', function () use ($navigate) {
                $navigate->route('Closure', 'show', array(), '/closure');
            });

            $service_menu_closure_repo = $jQuery('.service_menu_service_closure_repo');
            $service_menu_closure_repo->off();
            $service_menu_closure_repo->on('click', function () use ($navigate) {
                $navigate->route('Closure', 'repository-list', array(), '/closure/repository/list');
            });

            $service_menu_pcr = $jQuery('.service_menu_service_pcr');
            $service_menu_pcr->off();
            $service_menu_pcr->on('click', function () use ($navigate) {
                $navigate->route('PCR', 'show', array(), '/pcr');
            });

            $service_menu_pcs = $jQuery('.service_menu_service_pcs');
            $service_menu_pcs->off();
            $service_menu_pcs->on('click', function () use ($navigate) {
                $navigate->route('PCS', 'show', array(), '/pcs');
            });

            $service_menu_build = $jQuery('.service_menu_service_build');
            $service_menu_build->off();
            $service_menu_build->on('click', function () use ($navigate) {
                $navigate->route('Build', 'show', array(), '/build');
            });

            $service_menu_scm = $jQuery('.service_menu_service_scm');
            $service_menu_scm->off();
            $service_menu_scm->on('click', function () use ($navigate) {
                $navigate->route('SCM', 'show', array(), '/scm');
            });

            $service_menu_artifact = $jQuery('.service_menu_service_artifact');
            $service_menu_artifact->off();
            $service_menu_artifact->on('click', function () use ($navigate) {
                $navigate->route('Artifact', 'show', array(), '/artifact');
            });

            $service_menu_pipeline = $jQuery('.service_menu_service_pipeline');
            $service_menu_pipeline->off();
            $service_menu_pipeline->on('click', function () use ($navigate) {
                $navigate->route('Build', 'pipeline-list', array(), '/build/pipeline/execution/all');
            });

            $service_menu_mindstorm = $jQuery('.service_menu_service_mindstorm');
            $service_menu_mindstorm->off();
            $service_menu_mindstorm->on('click', function () use ($navigate) {
                $navigate->route('Mindstorm', 'show', array(), '/mindstorm');
            });

            $service_menu_arduinodesign = $jQuery('.service_menu_service_arduinodesign');
            $service_menu_arduinodesign->off();
            $service_menu_arduinodesign->on('click', function () use ($navigate) {
                $navigate->route('ArduinoDesign', 'show', array(), '/arduinodesign');
            });

            $service_menu_arduinoexecute = $jQuery('.service_menu_service_arduinoexecute');
            $service_menu_arduinoexecute->off();
            $service_menu_arduinoexecute->on('click', function () use ($navigate) {
                $navigate->route('ArduinoExecute', 'show', array(), '/arduinoexecute');
            });

            $service_menu_mobiledevice = $jQuery('.service_menu_service_mobiledevice');
            $service_menu_mobiledevice->off();
            $service_menu_mobiledevice->on('click', function () use ($navigate) {
                $navigate->route('MobileDevice', 'show', array(), '/mobiledevice');
            });

            $service_menu_mobileemulator = $jQuery('.service_menu_service_mobileemulator');
            $service_menu_mobileemulator->off();
            $service_menu_mobileemulator->on('click', function () use ($navigate) {
                $navigate->route('MobileDevice', 'emulator-list', array(), '/mobiledevice/emulator-list');
            });

            $service_menu_appanalytics = $jQuery('.service_menu_service_appanalytics');
            $service_menu_appanalytics->off();
            $service_menu_appanalytics->on('click', function () use ($navigate) {
                $navigate->route('Analytics', 'show', array(), '/analytics');
            });

            $service_menu_remotedesktop = $jQuery('.service_menu_service_remotedesktop');
            $service_menu_remotedesktop->off();
            $service_menu_remotedesktop->on('click', function () use ($navigate) {
                $navigate->route('RemoteDesktop', 'show', array(), '/remotedesktop');
            });

            $service_menu_services = $jQuery('.service_menu_services');
            $service_menu_services->off();
            $service_menu_services->on('click', function () use ($navigate) {
                $navigate->route('Services', 'show', array(), '/services');
            });

            $service_menu_services2 = $jQuery('.service_menu_service_services2');
            $service_menu_services2->off();
            $service_menu_services2->on('click', function () use ($navigate) {
                $navigate->route('Services2', 'show', array(), '/services2');
            });

            $service_menu_services3 = $jQuery('.service_menu_service_services3');
            $service_menu_services3->off();
            $service_menu_services3->on('click', function () use ($navigate) {
                $navigate->route('Services2', 'services3', array(), '/services3');
            });

            $service_menu_services4 = $jQuery('.service_menu_service_services4');
            $service_menu_services4->off();
            $service_menu_services4->on('click', function () use ($navigate) {
                $navigate->route('Services2', 'services4', array(), '/services4');
            });

            $service_menu_services5 = $jQuery('.service_menu_service_services5');
            $service_menu_services5->off();
            $service_menu_services5->on('click', function () use ($navigate) {
                $navigate->route('Services2', 'services5', array(), '/services5');
            });

            $service_menu_services6 = $jQuery('.service_menu_service_services6');
            $service_menu_services6->off();
            $service_menu_services6->on('click', function () use ($navigate) {
                $navigate->route('Services2', 'services6', array(), '/services6');
            });

            $service_menu_usersettings = $jQuery('.menu_usersettings');
            $service_menu_usersettings->off();
            $service_menu_usersettings->on('click', function ($jsthis) use ($navigate) {
                $jsthis->preventDefault();
                $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;
                $navigate->route('SAM', 'user-edit', array('user_id' => $user_logged['user']['id'] ), '/sam/user/edit/'.$user_logged['user']['id'] );
            });

            $settings_menu_logout = $jQuery('.logout');
            $settings_menu_logout->off();
            $settings_menu_logout->on('click', function () use ($navigate) {
                $navigate->route('Logout', 'now', array(), '/logout');
            });

            $switch_theme_set = $jQuery('.switch_theme_set');
            $switch_theme_set->off() ;
            $switch_theme_set->on('click', function ($jsthis) use ($navigate, $jQuery) {
                $id = $jsthis->target->id ;
                $jq_switch = $jQuery("#" . $id) ;
                $body_element = $jQuery("body") ;
                if ( $body_element->hasClass('dark_theme') == true ) {
                    $jq_switch->removeClass("fa-toggle-off");
                    $jq_switch->addClass("fa-toggle-on");
                    $body_element->removeClass("dark_theme");
                    $body_element->addClass("light_theme");
                } else if ( $body_element->hasClass('light_theme') == true ) {
                    $jq_switch->removeClass("fa-toggle-on");
                    $jq_switch->addClass("fa-toggle-off");
                    $body_element->removeClass("light_theme");
                    $body_element->addClass("dark_theme");
                }
            } ) ;

            \ISOPHP\js_core::$console->log('Binding menu links HS End');
        }
    }

    public static function getMenuHTML($menu_type) {
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged');
        $user = $user_logged;
        $user_type = $user['user']['user_type'];
        $html = '';

        if ($menu_type === 'user') {

          $html =  '<div id="user_menu_entries" class="menu_entries user_menu_entries col-sm-12">
                        <div class="menu_entry main_menu_entry col-sm-4"> 
                            <span id="service_menu_services" class="btn btn-primary service_menu_services">Services</span>
                        </div>
                        <div class="menu_entry main_menu_entry col-sm-4">
                            <span id="menu_usersettings" class="btn btn-primary menu_usersettings">Settings</span>
                        </div>
                        <div class="menu_entry main_menu_entry col-sm-4">
                            <span id="logout" class="btn btn-warning logout">Logout</span>
                        </div>
                    </div> ';

        }

        return $html;

    }


    public static function getPaginatorHTML($page_count, $current_page=1, $extra_classes="") {
        $html = ' <strong>Page: </strong>';
        $rand_id = \Core\WindowMessage::makeid(8) ;
        if ($page_count < 20) {
            for ($page_links_shown=1; $page_links_shown <= $page_count; $page_links_shown++) {
                if ($page_links_shown == $current_page) {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                } else {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator page_'.$page_links_shown.' '.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .$page_links_shown.'</span>' ;
                }
            }
        } else {

            $penultimate = $page_count - 1 ;
            $second_from_last = $page_count - 2 ;
            $start_and_end_pages = [1, 2 ,3, $second_from_last, $penultimate, $page_count] ;
            \ISOPHP\js_core::$console->log('$current_page', $current_page) ;
            $next_page = $current_page + 1 ;
            \ISOPHP\js_core::$console->log('$next_page', $next_page) ;
            $previous_page = $next_page - 2 ;

            if ($current_page != 1) {
                $html = $html .' <span id="paginator_previous'.$previous_page.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$previous_page.' '.$extra_classes.'" ' ;
                $html = $html .'       data-page="' . $previous_page . '" >' ;
                $html = $html .'&lt; Previous</span>' ;
            }

            for ($page_links_shown=1; $page_links_shown <= 3; $page_links_shown++) {
                if ($page_links_shown == $current_page) {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                } else {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator page_'.$page_links_shown.' '.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .$page_links_shown.'</span>' ;
                }
            }

            if ($current_page === 3) {
                for ($page_links_shown=4; $page_links_shown <= 5; $page_links_shown++) {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                }
            }

            $html = $html .' ... ' ;

            if ( \ISOPHP\core::$php->in_array($current_page, $start_and_end_pages) === false ) {
                for ($page_links_shown = $previous_page ; $page_links_shown <= $next_page; $page_links_shown++) {
                    if ($page_links_shown == $current_page) {
                        $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                        $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                        $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                    } else {
                        $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator page_'.$page_links_shown.' '.$page_links_shown.' '.$extra_classes.'" ' ;
                        $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                        $html = $html .$page_links_shown.'</span>' ;
                    }
                }
                $html = $html .' ... ' ;
            }

            if ($current_page === $second_from_last) {
                $second_from_last_minus_two = $second_from_last - 2 ;
                $second_from_last_minus_one = $second_from_last - 1 ;
                for ($page_links_shown=$second_from_last_minus_two; $page_links_shown <= $second_from_last_minus_one; $page_links_shown++) {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                }
            }

            for ($page_links_shown=$second_from_last; $page_links_shown <= $page_count; $page_links_shown++) {
                if ($page_links_shown == $current_page) {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .'<strong>'.$page_links_shown.'</strong></span>' ;
                } else {
                    $html = $html .' <span id="paginator_'.$page_links_shown.'_'.$rand_id.'" class="paginator page_'.$page_links_shown.' '.$page_links_shown.' '.$extra_classes.'" ' ;
                    $html = $html .'       data-page="' . $page_links_shown . '" >' ;
                    $html = $html .$page_links_shown.'</span>' ;
                }
            }

            if ($current_page != $page_count) {
                $html = $html .' <span id="paginator_previous'.$next_page.'_'.$rand_id.'" class="paginator paginator_current_page page_'.$next_page.' '.$extra_classes.'" ' ;
                $html = $html .'       data-page="' . $next_page . '" >' ;
                $html = $html .'Next &gt;</span>' ;
            }

            $html = $html .'&nbsp;&nbsp;&nbsp;&nbsp;' ;

            $html = $html .' <span id="paginator_direct_'.$rand_id.'"' ;
            $html = $html .'       class="button button_primary paginator_direct page_direct '.$extra_classes.'" >' ;
            $html = $html .'    <strong>Go to page:</strong>' ;
            $html = $html .' </span>' ;
            $html = $html .' <input type="text" id="paginator_direct_page" class="paginator_direct_page '.$extra_classes.'" value="'.$current_page.'" />' ;

        }
        return $html;
    }

    public static function userIsAuthenticated() {

        \ISOPHP\js_core::$console->log('in user auth check') ;
        $cookies = self::getCookieArray() ;
        \ISOPHP\js_core::$console->log('cookie user token dump') ;
        \ISOPHP\js_core::$console->log($cookies['user_tokens']) ;

        if (isset($cookies['user_tokens'])) {

            \ISOPHP\js_core::$console->log('user_tokens key found in cookies') ;

            $token_count = \ISOPHP\core::$php->count($cookies['user_tokens']) ;
            for ($token_iterator=0; $token_iterator<$token_count; $token_iterator++) {

                $token_time = $cookies['user_tokens'][$token_iterator]['time'] ;
                $half_an_hour_seconds = 30 * 60 ;
                $now_time = \ISOPHP\core::$php->time();

                \ISOPHP\js_core::$console->log('now time') ;
                \ISOPHP\js_core::$console->log($now_time) ;
                \ISOPHP\js_core::$console->log('token time') ;
                \ISOPHP\js_core::$console->log($token_time) ;

                if ($now_time - $token_time < $half_an_hour_seconds) {
                    # Token has been less than half an hour assume ok
                    $token_data = $cookies['user_tokens'][$token_iterator]['data'] ;
                    // check this now or wait until performRequest ?
                    \ISOPHP\js_core::$console->log('is auth returning true') ;
                    return true ;
                }

            }
            # Tokens are all old,
            \ISOPHP\js_core::$console->log('is auth returning false, Token is old') ;
            return false ;

        } else {
            # No user logged
            \ISOPHP\js_core::$console->log('is auth returning false, No user logged') ;
            return false ;
        }
    }

    public static function getCookieArray() {
        $cookie_string = \ISOPHP\js_core::$window->document->cookie ;
        $cookie_ray = [] ;
        \ISOPHP\js_core::$console->log($cookie_string) ;
//        \ISOPHP\js_core::$console->log('gca 1') ;
        $theCookies = \ISOPHP\core::$php->explode('; ', $cookie_string) ;
//        \ISOPHP\js_core::$console->log('gca 2') ;
        $cookie_count = \ISOPHP\core::$php->count($theCookies) ;
//        \ISOPHP\js_core::$console->log('gca 3') ;
        for ($i = 0; $i < $cookie_count ; $i++) {
//            \ISOPHP\js_core::$console->log('gca 4') ;
            $parts = \ISOPHP\core::$php->explode('=', $theCookies[$i]) ;
//            \ISOPHP\js_core::$console->log('gca 5') ;
            \ISOPHP\js_core::$console->log($parts[0]) ;
            \ISOPHP\js_core::$console->log($parts[1]) ;

            if (\ISOPHP\core::$php->strlen($parts[1]) == 0) {
                $cookie_ray[$parts[0]] = '' ;
//                \ISOPHP\js_core::$console->log('gca 6') ;
            } else if (\ISOPHP\core::$php->json_decode($parts[1]) !== null) {
                $unserial = \ISOPHP\core::$php->json_decode($parts[1]) ;
                $cookie_ray[$parts[0]] = $unserial ;
//                \ISOPHP\js_core::$console->log('gca 7') ;
            } else {
                $cookie_ray[$parts[0]] = $parts[1] ;
//                \ISOPHP\js_core::$console->log('gca 8') ;
            }
        }
        return $cookie_ray ;
    }

    public static function setCookieArray($cookies) {
        \ISOPHP\js_core::$console->log('setting cookies to array func') ;
        \ISOPHP\js_core::$console->log($cookies) ;
        self::emptyCookieArray() ;
        \ISOPHP\js_core::$console->log('setting cookies to array loop') ;
        foreach ($cookies as $cookie_key => $cookie_value) {
            $cookie_string = $cookie_key.'='.$cookie_value ;
            $cookie_string = $cookie_string . '; path=/' ;
//            \ISOPHP\js_core::$console->log('setting cookie string') ;
//            \ISOPHP\js_core::$console->log($cookie_string) ;
            \ISOPHP\js_core::$window->document->cookie = $cookie_string ;
        }
        return true ;
    }

    public static function emptyCookieArray($inc_sessid = false) {
        \ISOPHP\js_core::$console->log('emptying cookies array func') ;
        $cookies = self::getCookieArray() ;
        foreach ($cookies as $cookie_key => $cookie_value) {
            if ($cookie_key == 'PHPSESSID') {
                if ($inc_sessid == true) {
                    $cookie_string = $cookie_key.'=' ;
                    $cookie_string = $cookie_string . '; path=/' ;
//                    \ISOPHP\js_core::$console->log('emptying PHPSESSID cookie string') ;
//                    \ISOPHP\js_core::$console->log($cookie_string) ;
                    \ISOPHP\js_core::$window->document->cookie = $cookie_string ;
                }
            } else {
                $cookie_string = $cookie_key.'=' ;
                $cookie_string = $cookie_string . '; path=/' ;
//                \ISOPHP\js_core::$console->log('emptying cookie string') ;
//                \ISOPHP\js_core::$console->log($cookie_string) ;
                \ISOPHP\js_core::$window->document->cookie = $cookie_string ;
            }
        }
        \ISOPHP\js_core::$console->log('emptied cookies') ;
//        \ISOPHP\js_core::$console->log(\ISOPHP\js_core::$window->document->cookie) ;
        return true ;
    }

}
