<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $sizes_are_array = \ISOPHP\core::$php->is_array($rqd['sizes']) ;
    if ($sizes_are_array === true) {
        $sizes_exist = true ;
    } else {
        $sizes_exist = false ;
    }
    if ($sizes_exist === true) {
        $size_count = \ISOPHP\core::$php->count($rqd['sizes']) ;
    } else {
        $size_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
            <span id="go-database-size-list" class="btn btn-info go-database-size-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Instance Sizes</h4>
         </div> ' ;

    if ($rqd['page_count'] > 1) {
        $html = $html .
            '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'database-size-paginator') ;
        $html = $html .'</div>' ;
    }

    $html = $html .
       '<div class="col-sm-12 col-12 one_database_size item_list_database_sizes page_item_list_title" id="item_list_database_sizes">
            <div class="col-sm-2 col-2">
                <h5 class="database_size_id_title">Slug</h5>
            </div>
            <div class="col-sm-2 col-4">
                <h5 class="database_size_id_title">Description</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_size_id_title">Cores</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_size_id_title">Memory</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="database_size_id_title">HDD Size</h5>
            </div>
        </div>';

    if ($sizes_exist === true && $size_count !== false) {

        foreach ($rqd['sizes'] as $one_database_size) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_database_size">
                    <div class="col-sm-2 col-2">
                        <p class="database_list_sizes_slug"><span class="database_name database_listing">' . $one_database_size['slug'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-4">
                        <p class="database_list_description"><span class="database_id database_listing">' . $one_database_size['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_sizes_cores">' . $one_database_size['cores'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <p class="database_list_sizes_mem">' . $one_database_size['mem'] . ' GB</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="database_list_sizes_hdd">' . $one_database_size['hdd'] . ' GB</p>
                    </div>                      
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'database-size-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no available Sizes</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
            <span id="go-database-size-list" class="btn btn-info go-database-size-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;


    return $html;

};
