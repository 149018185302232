<?php

Namespace Model\SC1;

use ISOPHP\core;

class ScheduledInstancePageModel extends \Model\Base {

// $bind = \Model\SC1\PageModel::bindButtons();
    public function showSC1ScheduledInstanceCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'ScheduledInstanceCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 ScheduledInstance Create 2', $vars);
        };
    }

    public function addSC1ScheduledInstanceCreateButtonChanges($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');

            $jQuery = \ISOPHP\js_core::$jQuery;
            $by_date_cron_radio = $jQuery('input[type=radio][name=by_date_cron]');
            $by_date_cron_radio->on('change', function($jqThis) use ($jQuery) {
                $by_date_cron_val = $jQuery('input[type=radio][name=by_date_cron]')->val() ;
                if ($by_date_cron_val === 'date'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($by_date_cron_val === 'cron'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

            $frequency_radio = $jQuery('input[type=radio][name=frequency]');
            $frequency_radio->on('change', function($jqThis) use ($jQuery) {
                $frequency_val = $jQuery('input[type=radio][name=frequency]')->val() ;
                if ($frequency_val === 'once'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($frequency_val === 'repeat'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

        };
    }

    public function addSC1ScheduledInstanceFormTypeChanges($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Adding logic to form type');

            $jQuery = \ISOPHP\js_core::$jQuery;
            $instance_settings_type_radio = $jQuery('input[type=radio][name=instance_settings_type]');
            $instance_settings_type_radio->on('change', function($jqThis) use ($jQuery) {
                $instance_settings_type_val = $jQuery('input[type=radio][name=instance_settings_type]')->val() ;
                if ($instance_settings_type_val === 'launch_template') {
                    $custom = $jQuery('#instance_settings_type_custom');
                    $custom->slideDown() ;
                    $launch_template = $jQuery('#instance_settings_type_custom_launch_template');
                    $launch_template->slideUp() ;
                } else if ($instance_settings_type_val === 'custom') {
                    $custom = $jQuery('#instance_settings_type_custom');
                    $custom->slideDown() ;
                    $launch_template = $jQuery('#instance_settings_type_custom_launch_template');
                    $launch_template->slideUp() ;
                }
            }) ;

            $frequency_radio = $jQuery('input[type=radio][name=frequency]');
            $frequency_radio->on('change', function($jqThis) use ($jQuery) {
                $frequency_val = $jQuery('input[type=radio][name=frequency]')->val() ;
                if ($frequency_val === 'once'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($frequency_val === 'repeat'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

        };
    }

    public function showSC1ScheduledInstanceList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/scheduledinstance/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1ScheduledInstanceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1ScheduledInstanceListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 ScheduledInstance List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 ScheduledInstance List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'ScheduledInstanceList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 ScheduledInstance List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 ScheduledInstance List failed');
            }
        };
        return $func;
    }

    public function showSC1ScheduledInstanceExecutionLogList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 ExecutionLog List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/scheduledinstance/executionlog/all';
            $afterwards = $this->applySC1ScheduledInstanceExecutionLogListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1ScheduledInstanceExecutionLogListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 ScheduledInstance ExecutionLog List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 ScheduledInstance ExecutionLog List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'ScheduledInstanceExecutionLogList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 ScheduledInstance ExecutionLog List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 ScheduledInstance ExecutionLog List failed');
            }
        };
        return $func;
    }

    /**
     * Save scheduledinstance
     */
    public function saveScheduledInstanceAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save scheduledinstance to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'scheduledinstance-create';
        $vars['api_uri'] = '/api/sc1/scheduledinstance/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save scheduledinstance vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveScheduledInstanceResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveScheduledInstanceResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 scheduledinstance result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['scheduledinstance']['sc1_scheduledinstance_name'].' for Instance '.$request_data['scheduledinstance']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 scheduledinstance saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'scheduledinstance-list', array(), '/sc1/scheduledinstance/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['scheduledinstance']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 scheduledinstance save failed');
            }
        };
        return $func;
    }
    /**
     * Delete scheduledinstance
     */
    public function deleteScheduledInstanceAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete scheduledinstance to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'scheduledinstance-delete';
        $vars['api_uri'] = '/api/sc1/scheduledinstance/delete';
        $vars['scheduledinstance_id'] = $pageVars['params']['scheduledinstance_id'];
        \ISOPHP\js_core::$console->log('delete scheduledinstance vars');
        \ISOPHP\js_core::$console->log($pageVars);
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteScheduledInstanceResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteScheduledInstanceResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 scheduledinstance result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['scheduledinstance']['sc1_scheduledinstance_name'].' for Instance '.$request_data['scheduledinstance']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 scheduledinstance deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'scheduledinstance-list', array(), '/sc1/scheduledinstance/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['scheduledinstance']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 scheduledinstance delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete scheduledinstance
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                $go_sc1_scheduledinstance_list = $jQuery('.go-sc1-scheduledinstance-list');
                $go_sc1_scheduledinstance_list->off() ;
                $go_sc1_scheduledinstance_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scheduledinstance-list', array(), '/sc1/scheduledinstance/list');
                });

                $go_sc1_scheduledinstance_list_page = $jQuery('.sc1-scheduledinstance-paginator');
                $go_sc1_scheduledinstance_list_page->off() ;
                $go_sc1_scheduledinstance_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 scheduledinstance paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'scheduledinstance-list', array('page' => $new_page), '/sc1/scheduledinstance/list/page/'.$new_page);
                });

                $go_sc1_scheduledinstance_executionlog_list = $jQuery('.go-sc1-scheduledinstance-executionlog-list');
                $go_sc1_scheduledinstance_executionlog_list->off() ;
                $go_sc1_scheduledinstance_executionlog_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scheduledinstance-executionlog-list', array(), '/sc1/scheduledinstance/executionlog/list');
                });

                $go_sc1_scheduledinstance_create = $jQuery('.go-sc1-scheduledinstance-create');
                $go_sc1_scheduledinstance_create->off() ;
                $go_sc1_scheduledinstance_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scheduledinstance-create', array(), '/sc1/scheduledinstance/create');
                });

                $save_scheduledinstance = $jQuery('.attempt-scheduledinstance-create');
                $save_scheduledinstance->off() ;
                $save_scheduledinstance->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'scheduledinstance-save-attempt', array());
                });

                $scheduledinstance_delete = $jQuery('.attempt-scheduledinstance-delete');
                $scheduledinstance_delete->off() ;
                $scheduledinstance_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $scheduledinstance_name = $jqThis->attr('data-sc1-scheduledinstance-name') ;
//                    $scheduledinstance_id = $jqThis->attr('data-sc1-scheduledinstance-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked scheduledinstance delete button');
//                    if ($scheduledinstance_name !== '') {
//                        $name_or_id = $scheduledinstance_name ;
//                    } else {
//                        $name_or_id = $scheduledinstance_id ;
//                    }
//                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $scheduledinstance_id) {
//                        $navigate->route('SC1', 'scheduledinstance-delete-attempt', array(
//                            'scheduledinstance_id' => $scheduledinstance_id)
//                        );
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}