<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    Docker Pull URL: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$pv['hostname'].$pv['port_string'].'/'.$pv['registry_id'].':'.$pv['tag_id'] ;
    $html = $html . '  </div>' ;

    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    &nbsp;';
    $html = $html . '  </div>' ;
    
    return $html;

};