<?php

Namespace View ;

class UserSettingsView extends \Core\View {

    public function display($data) {
//        \ISOPHP\js_core::$console->log('data', $data ) ;
        $jQuery =  \ISOPHP\js_core::$jQuery ;
        $tplfunc = \Core\View::$template ;
        $tpl_data = $tplfunc() ;
//        \ISOPHP\js_core::$console->log('davey ravey don', $tpl_data) ;
        $jQuery('#template')->html($tpl_data) ;
        $jQuery('.app-loader')->css('display', 'none') ;
    }

    public function displayDropdown($type) {
//        \ISOPHP\js_core::$console->log('data', $data ) ;
        $jQuery =  \ISOPHP\js_core::$jQuery ;
        $tplfunc = \Core\View::$template ;
        $tpl_data = $tplfunc() ;
        \ISOPHP\js_core::$console->log('display dropdown', $tpl_data) ;
        $elem = '#'.$type.'_account_wrap' ;
        $jQuery($elem)->html($tpl_data) ;
        $jQuery('.app-loader')->css('display', 'none') ;
    }

}