<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-user-create" class="btn btn-success attempt-user-create">Create</span>
                        <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <h4>New User</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <label>User Name</label>
                            <input type="text" name="username" class="form-control" id="username" value="" />
                        </div>
                        <div class="col-sm-6 col-6">
                            <label>Password</label>
                            <input type="password" name="userpass" class="form-control" id="userpass" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <label>Full Name</label>
                            <input type="text" name="full_name" class="form-control" id="full_name" value="" />
                        </div>
                        <div class="col-sm-6 col-6">
                            <label>Email</label>
                            <input type="text" name="email" class="form-control" id="email" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-3 col-3">
                            &nbsp;
                        </div>
                        <div class="col-sm-6 col-6">
                            <label>Description</label>
                            <textarea name="description" id="description" class="form-control" rows="10" cols="40"></textarea>
                        </div>
                        <div class="col-sm-3 col-3">
                            &nbsp;
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-user-create" class="btn btn-success attempt-user-create">Create</span>
                        <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                    
                </div>
            </div>
            
            <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating SAM Users</strong></h4>     
                    
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_group_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_group_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_group_awscli_aws" class="instruction_set instruction_set_create_group_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com \
                               --caller-reference 123451234512345
                               --profile=pharaoh \
                               --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceRecordSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>
                    

             </div>
             
        </div>';

    return $html;

};