<?php

Namespace Model\SC1;

use ISOPHP\core;

class SecurityGroupPageModel extends \Model\Base {

    public static $current_inbound_rules ;
    public static $current_outbound_rules ;

    public function showSC1SecurityGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'SecurityGroupCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 SecurityGroup Create 2', $vars);
        };
    }

    public function showSC1SecurityGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/securitygroup/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1SecurityGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1SecurityGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 SecurityGroup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 SecurityGroup List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'SecurityGroupList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 SecurityGroup List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 SecurityGroup List failed');
            }
        };
        return $func;
    }


    public function addSC1SecurityGroupSelectChanges($pageVars) {

        $func = function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('in addSC1SecurityGroupInboundRule');
            $jQuery = \ISOPHP\js_core::$jQuery;

            $inbound_type_change = function () use ($jQuery) {
                $val = $jQuery('#inbound_vars_type')->val() ;
                if ($val == "none") {
                    $jQuery('#inbound_vars_protocol')->val() ;
                    $jQuery('#inbound_vars_portrange')->val() ;
                } else if ($val == "customtcp") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('') ;
                } else if ($val == "customudp") {
                    $jQuery('#inbound_vars_protocol')->val('udp') ;
                    $jQuery('#inbound_vars_portrange')->val('') ;
                } else if ($val == "http") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('80') ;
                } else if ($val == "https") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('443') ;
                } else if ($val == "alltcp") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('*') ;
                } else if ($val == "alludp") {
                    $jQuery('#inbound_vars_protocol')->val('udp') ;
                    $jQuery('#inbound_vars_portrange')->val('*') ;
                } else if ($val == "allicmp") {
                    $jQuery('#inbound_vars_protocol')->val() ;
                    $jQuery('#inbound_vars_portrange')->val() ;
//            } else if ($val == "alltraffic") {
//                $jQuery('#inbound_vars_protocol')->val('tcp') ;
//                $jQuery('#inbound_vars_portrange')->val('*') ;
                } else if ($val == "ssh") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('22') ;
                } else if ($val == "smtp") {
                    $jQuery('#inbound_vars_protocol')->val('smtp') ;
                    $jQuery('#inbound_vars_portrange')->val('25') ;
                } else if ($val == "dnsudp") {
                    $jQuery('#inbound_vars_protocol')->val('udp') ;
                    $jQuery('#inbound_vars_portrange')->val('53') ;
                } else if ($val == "dnstcp") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('53') ;
                } else if ($val == "pop3") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('993') ;
                } else if ($val == "imap") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('143') ;
                } else if ($val == "mysql") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('3306') ;
                } else if ($val == "postgresql") {
                    $jQuery('#inbound_vars_protocol')->val('tcp') ;
                    $jQuery('#inbound_vars_portrange')->val('5432') ;
                }
            } ;
            $jQuery('#inbound_vars_type')->change($inbound_type_change) ;
            $inbound_source_type_change = function () use ($jQuery) {
                $val = $jQuery('#inbound_vars_source_type')->val() ;
                if ($val == "custom") {
                    $jQuery('#inbound_vars_destination_data')->val('') ;
                } else if ($val == "anywhere") {
                    $jQuery('#inbound_vars_destination_data')->val('0.0.0.0') ;
                } else if ($val == "myip") {
                    $jQuery('#inbound_vars_destination_data')->val('') ;
                }
            } ;
            $jQuery('#inbound_vars_source_type')->change($inbound_source_type_change) ;

            $outbound_type_change = function () use ($jQuery) {
                $val = $jQuery('#outbound_vars_type')->val() ;
                if ($val == "none") {
                    $jQuery('#outbound_vars_protocol')->val() ;
                    $jQuery('#outbound_vars_portrange')->val() ;
                } else if ($val == "customtcp") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('') ;
                } else if ($val == "customudp") {
                    $jQuery('#outbound_vars_protocol')->val('udp') ;
                    $jQuery('#outbound_vars_portrange')->val('') ;
                } else if ($val == "http") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('80') ;
                } else if ($val == "https") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('443') ;
                } else if ($val == "alltcp") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('*') ;
                } else if ($val == "alludp") {
                    $jQuery('#outbound_vars_protocol')->val('udp') ;
                    $jQuery('#outbound_vars_portrange')->val('*') ;
                } else if ($val == "allicmp") {
                    $jQuery('#outbound_vars_protocol')->val() ;
                    $jQuery('#outbound_vars_portrange')->val() ;
//            } else if ($val == "alltraffic") {
//                $jQuery('#outbound_vars_protocol')->val('tcp') ;
//                $jQuery('#outbound_vars_portrange')->val('*') ;
                } else if ($val == "ssh") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('22') ;
                } else if ($val == "smtp") {
                    $jQuery('#outbound_vars_protocol')->val('smtp') ;
                    $jQuery('#outbound_vars_portrange')->val('25') ;
                } else if ($val == "dnsudp") {
                    $jQuery('#outbound_vars_protocol')->val('udp') ;
                    $jQuery('#outbound_vars_portrange')->val('53') ;
                } else if ($val == "dnstcp") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('53') ;
                } else if ($val == "pop3") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('993') ;
                } else if ($val == "imap") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('143') ;
                } else if ($val == "mysql") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('3306') ;
                } else if ($val == "postgresql") {
                    $jQuery('#outbound_vars_protocol')->val('tcp') ;
                    $jQuery('#outbound_vars_portrange')->val('5432') ;
                }
            } ;
            $jQuery('#outbound_vars_type')->change($outbound_type_change) ;
            $outbound_source_type_change = function () use ($jQuery) {
                $val = $jQuery('#outbound_destination_vars_type')->val() ;
                if ($val == "custom") {
                    $jQuery('#outbound_vars_destination_data')->val('') ;
                } else if ($val == "anywhere") {
                    $jQuery('#outbound_vars_destination_data')->val('0.0.0.0') ;
                } else if ($val == "myip") {
                    $jQuery('#outbound_vars_destination_data')->val('') ;
                }
            } ;
            $jQuery('#outbound_vars_destination_type')->change($outbound_source_type_change) ;
        } ;

        return $func;
    }


    public function addSC1SecurityGroupInboundRule($pageVars) {
        \ISOPHP\js_core::$console->log('in addSC1SecurityGroupInboundRule');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $inbound_type = $jQuery('#inbound_vars_type')->val() ;
        $inbound_protocol = $jQuery('#inbound_vars_protocol')->val() ;
        $inbound_portrange = $jQuery('#inbound_vars_portrange')->val() ;
        $inbound_source_data = $jQuery('#inbound_vars_source_data')->val() ;
        $inbound_description = $jQuery('#inbound_vars_description')->val() ;

        $inbound_type_length = \ISOPHP\core::$php->strlen($inbound_type);
        if ($inbound_type_length == 0) {
            $error_message = 'Inbound Type must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $options = ["none", "customtcp", "customudp", "http", "https", "alltcp",
            "alludp", "allicmp", "alltraffic", "ssh", "smtp", "dnsudp", "dnstcp",
            "pop3", "imap", "mysql", "postgresql" ] ;

//        $inbound_protocol_length = \ISOPHP\core::$php->strlen($inbound_protocol);
//        if ($inbound_protocol_length == 0) {
//            $error_message = 'Inbound Protocol must not be empty';
//            \Core\WindowMessage::showMessage($error_message, 'bad');
//            return false;
//        }

        $inbound_portrange_length = \ISOPHP\core::$php->strlen($inbound_portrange);
        if ($inbound_portrange_length == 0) {
            $error_message = 'Inbound Port Range must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $inbound_source_data_length = \ISOPHP\core::$php->strlen($inbound_source_data);
        if ($inbound_source_data_length == 0) {
            $error_message = 'Inbound Source Data must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $rule = array(
            'type' => $inbound_type,
            'protocol' => $inbound_protocol,
            'portrange' => $inbound_portrange,
            'data' => $inbound_source_data,
            'description' => $inbound_description,
        ) ;

        $html =         '<div class="col-12">' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$inbound_type ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$inbound_protocol ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$inbound_portrange ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-3">' ;
        $html = $html . '  '.$inbound_source_data ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-3">' ;
        $html = $html . '  '.$inbound_description ;
        $html = $html . '  </div>' ;
        $html = $html . '</div>' ;

        \ISOPHP\js_core::$console->log('HTML DUMP');
        \ISOPHP\js_core::$console->log($html);
        $current_inbound_rules = self::$current_inbound_rules ;
        $current_count = \ISOPHP\core::$php->count($current_inbound_rules) ;
        $next = $current_count + 1 ;
        $current_inbound_rules[$next] = $rule ;
        self::$current_inbound_rules = $current_inbound_rules ;

        $current_rules_inbound = $jQuery('#current_rules_inbound_inner') ;
        if ($next == 1) {
            $current_rules_inbound->empty() ;
        }
        $current_rules_inbound->append($html) ;

        return true;
    }


    public function deleteSC1SecurityGroupInboundRule($pageVars) {
        $jQuery = \ISOPHP\js_core::$jQuery;

//        $current_rules_inbound = $jQuery('#current_rules_inbound') ;
//        if ($next == 1) {
//            $current_rules_inbound->empty() ;
//        }
//        $current_rules_inbound->append($html) ;

        return true;
    }



    public function addSC1SecurityGroupOutboundRule($pageVars) {
        \ISOPHP\js_core::$console->log('in addSC1SecurityGroupInboundRule');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $outbound_type = $jQuery('#outbound_vars_type')->val() ;
        $outbound_protocol = $jQuery('#outbound_vars_protocol')->val() ;
        $outbound_portrange = $jQuery('#outbound_vars_portrange')->val() ;
        $outbound_source_data = $jQuery('#outbound_vars_source_data')->val() ;
        $outbound_description = $jQuery('#outbound_vars_description')->val() ;

        $outbound_type_length = \ISOPHP\core::$php->strlen($outbound_type);
        if ($outbound_type_length == 0) {
            $error_message = 'Inbound Type must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $options = ["none", "customtcp", "customudp", "http", "https", "alltcp",
            "alludp", "allicmp", "alltraffic", "ssh", "smtp", "dnsudp", "dnstcp",
            "pop3", "imap", "mysql", "postgresql" ] ;

//        $outbound_protocol_length = \ISOPHP\core::$php->strlen($outbound_protocol);
//        if ($outbound_protocol_length == 0) {
//            $error_message = 'Outbound Protocol must not be empty';
//            \Core\WindowMessage::showMessage($error_message, 'bad');
//            return false;
//        }

        $outbound_portrange_length = \ISOPHP\core::$php->strlen($outbound_portrange);
        if ($outbound_portrange_length == 0) {
            $error_message = 'Outbound Port Range must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $outbound_source_data_length = \ISOPHP\core::$php->strlen($outbound_source_data);
        if ($outbound_source_data_length == 0) {
            $error_message = 'Outbound Source Data must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }

        $rule = array(
            'type' => $outbound_type,
            'protocol' => $outbound_protocol,
            'portrange' => $outbound_portrange,
            'data' => $outbound_source_data,
            'description' => $outbound_description,
        ) ;

        $html =         '<div class="col-12">' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$outbound_type ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$outbound_protocol ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-2">' ;
        $html = $html . '  '.$outbound_portrange ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-3">' ;
        $html = $html . '  '.$outbound_source_data ;
        $html = $html . '  </div>' ;
        $html = $html . '  <div class="col-3">' ;
        $html = $html . '  '.$outbound_description ;
        $html = $html . '  </div>' ;
        $html = $html . '</div>' ;

        \ISOPHP\js_core::$console->log('HTML DUMP');
        \ISOPHP\js_core::$console->log($html);
        $current_outbound_rules = self::$current_outbound_rules ;
        $current_count = \ISOPHP\core::$php->count($current_outbound_rules) ;
        $next = $current_count + 1 ;
        $current_outbound_rules[$next] = $rule ;
        self::$current_outbound_rules = $current_outbound_rules ;

        $current_rules_outbound = $jQuery('#current_rules_outbound_inner') ;
        if ($next == 1) {
            $current_rules_outbound->empty() ;
        }
        $current_rules_outbound->append($html) ;

        return true;
    }


    public function deleteSC1SecurityGroupOutboundRule($pageVars) {
        $jQuery = \ISOPHP\js_core::$jQuery;

//        $current_rules_outbound = $jQuery('#current_rules_outbound') ;
//        if ($next == 1) {
//            $current_rules_outbound->empty() ;
//        }
//        $current_rules_outbound->append($html) ;

        return true;
    }


    /**
     * Save securitygroup
     */
    public function saveSecurityGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save securitygroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'securitygroup-create';
        $vars['api_uri'] = '/api/sc1/securitygroup/create';
        $vars['name'] = $jQuery('#name')->val();
        $vars['vpc_id'] = $jQuery('#svpc_name')->val();
        $vars['inbound'] = \ISOPHP\core::$php->json_encode(self::$current_inbound_rules) ;
        $vars['outbound'] = \ISOPHP\core::$php->json_encode(self::$current_outbound_rules) ;
        \ISOPHP\js_core::$console->log('save securitygroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSecurityGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSecurityGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 securitygroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Security Group '.$request_data['securitygroup']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 securitygroup saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'securitygroup-list', array(), '/sc1/securitygroup/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Security Group '.$request_data['securitygroup']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 securitygroup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete securitygroup
     */
    public function deleteSecurityGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete securitygroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'securitygroup-delete';
        $vars['api_uri'] = '/api/sc1/securitygroup/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete securitygroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteSecurityGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSecurityGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 securitygroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Security Group '.$request_data['securitygroup']['name'].' from VPC '.$request_data['securitygroup']['vpc_id'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 securitygroup deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'securitygroup-list', array(), '/sc1/securitygroup/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Security Group '.$request_data['securitygroup']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 securitygroup delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding security group buttons');


                $add_sc1_securitygroup_rule_inbound = $jQuery('.add-securitygroup-inbound-rule');
                $add_sc1_securitygroup_rule_inbound->off() ;
                $add_sc1_securitygroup_rule_inbound->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-rule-inbound-create', array(), '/sc1/securitygroup/rule/inbound/create');
                });

                $delete_sc1_securitygroup_rule_inbound = $jQuery('.delete-sc1-securitygroup-rule');
                $delete_sc1_securitygroup_rule_inbound->off() ;
                $delete_sc1_securitygroup_rule_inbound->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-rule-inbound-delete', array(), '/sc1/securitygroup/rule/inbound/delete');
                });

                $add_sc1_securitygroup_rule_outbound = $jQuery('.add-securitygroup-outbound-rule');
                $add_sc1_securitygroup_rule_outbound->off() ;
                $add_sc1_securitygroup_rule_outbound->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-rule-outbound-create', array(), '/sc1/securitygroup/rule/outbound/create');
                });

                $delete_sc1_securitygroup_rule_outbound = $jQuery('.delete-sc1-securitygroup-rule');
                $delete_sc1_securitygroup_rule_outbound->off() ;
                $delete_sc1_securitygroup_rule_outbound->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-rule-outbound-delete', array(), '/sc1/securitygroup/rule/outbound/delete');
                });

                $go_sc1_securitygroup_list = $jQuery('.go-sc1-securitygroup-list');
                $go_sc1_securitygroup_list->off() ;
                $go_sc1_securitygroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-list', array(), '/sc1/securitygroup/list');
                });

                $go_sc1_securitygroup_list_page = $jQuery('.sc1-securitygroup-paginator');
                $go_sc1_securitygroup_list_page->off() ;
                $go_sc1_securitygroup_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 securitygroup paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-list', array('page' => $new_page), '/sc1/securitygroup/list/page/'.$new_page);
                });

                $go_sc1_securitygroup_create = $jQuery('.go-sc1-securitygroup-create');
                $go_sc1_securitygroup_create->off() ;
                $go_sc1_securitygroup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-create', array(), '/sc1/securitygroup/create');
                });

                $save_securitygroup = $jQuery('.attempt-securitygroup-create');
                $save_securitygroup->off() ;
                $save_securitygroup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-save-attempt', array());
                });

                $securitygroup_delete = $jQuery('.attempt-securitygroup-delete');
                $securitygroup_delete->off() ;
                $securitygroup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $securitygroup_name = $jqThis->attr('data-sc1-securitygroup-name') ;
                    $securitygroup_id = $jqThis->attr('data-sc1-securitygroup-id') ;
                    \ISOPHP\js_core::$console->log('Clicked securitygroup delete button');
                    if ($securitygroup_name !== '') {
                        $name_or_id = $securitygroup_name ;
                    } else {
                        $name_or_id = $securitygroup_id ;
                    }
                    $msg = "You are about to delete the Security Group $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $securitygroup_id, $securitygroup_name) {
                        $navigate->route('SC1', 'securitygroup-delete-attempt', array(
                            'id' => $securitygroup_id,
                            'name' => $securitygroup_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}