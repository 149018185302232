<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                
                    <div>
                        <h4>New SSQS Message</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Queue</label>
                            <div id="ssqs_queue_dropdown">
                                <select class="form-control" name="queue_name" id="queue_name">
                                    <option>Loading Queues</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message_value" id="message_value" class="form-control"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-message-create" class="btn btn-success attempt-message-create">Create</span>
                        <span id="go-ssqs-landing" class="btn btn-warning go-ssqs-landing">SSQS Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating SSQS Messages</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_message_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_message_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_message_ptc_aws" class="instruction_set instruction_set_create_message_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
                     
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_message_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_message_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_message_awscli_aws" class="instruction_set instruction_set_create_message_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com --caller-reference 123451234512345 --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceMessageSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>

             </div>';

    return $html;

};

/*
 *
 *
                    <h5>AWS Mode - Pharaoh Configure<i id="switch_instruction_set_create_message_ptc_rax"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_message_ptc_rax"></i></h5>
                    <pre id="instruction_set_create_message_ptc_rax" class="instruction_set instruction_set_create_message_ptc_rax">
ptconfigure-enterprise AWSRoute53 ensure-queue-exists --queue-name="testmailqueue.com" --queue-email="testmail@testmailqueue.com"  --queue-comment="A Comment"  --queue-ttl="60"  --aws-access-key="1234" --aws-secret-key="ABC1234" --aws-region="eu-west-1" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format="json" --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmailqueue.com"
}

                    </pre>
 *
 */