<?php

Namespace Model\DeviceAutomation;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Device Automation List of Piranha Web Services';
        $page['heading'] = 'List of Device Automation';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Device Automation Mod', $page, $pageVars);
        return $page;
    }

    public function showDeviceAutomationLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DeviceAutomationView();
            $view->loadTemplate('DeviceAutomation', 'DeviceAutomationLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayDeviceAutomation($bind);
            \ISOPHP\js_core::$console->log('apply DeviceAutomation Device Create 2', $vars);
        };
    }

    public function showDeviceAutomationDeviceCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DeviceAutomationView();
            $view->loadTemplate('DeviceAutomation', 'DeviceCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDeviceAutomation($bind);
            \ISOPHP\js_core::$console->log('apply DeviceAutomation Device Create 2', $vars);
        };
    }

    public function showDeviceAutomationDeviceList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation List');
            $vars['control'] = 'DeviceAutomation';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/deviceautomation/device/all';
            $afterwards = $this->applyDeviceAutomationDeviceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DeviceAutomation Model: ', $result);
            return $result;
        };
    }

    public function applyDeviceAutomationDeviceListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DeviceAutomation Device List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Device List 1', $vars);
                $view = new \View\DeviceAutomationView();
                $view->loadTemplate('DeviceAutomation', 'DeviceList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDeviceAutomation($bind);
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Device List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DeviceAutomation Device List failed');
            }
        };
        return $func;
    }

    public function showDeviceAutomationEmulatorCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Device Automation Emulator Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DeviceAutomationView();
            $view->loadTemplate('DeviceAutomation', 'EmulatorCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDeviceAutomation($bind);
            \ISOPHP\js_core::$console->log('apply Device Automation Emulator Create 2', $vars);
        };
    }

    public function showDeviceAutomationEmulatorList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Device Automation Emulator List');
            $vars['control'] = 'DeviceAutomation';
            $vars['action'] = 'emulator-list';
            $vars['api_uri'] = '/api/deviceautomation/emulator/all';
            $afterwards = $this->applyDeviceAutomationEmulatorListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DeviceAutomation Model: ', $result);
            return $result;
        };
    }

    public function applyDeviceAutomationEmulatorListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Device Automation Emulator List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Device Automation Emulator List 1', $vars);
                $view = new \View\DeviceAutomationView();
                $view->loadTemplate('DeviceAutomation', 'EmulatorList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDeviceAutomation($bind);
                \ISOPHP\js_core::$console->log('apply Device Automation Emulator List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Device Automation Emulator List failed');
            }
        };
        return $func;
    }

    public function showDeviceAutomationDeviceListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation List');
            $vars['control'] = 'DeviceAutomation';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/deviceautomation/device/all';
            $afterwards = $this->applyDeviceAutomationDeviceListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DeviceAutomation Model: ', $result);
            return $result;
        };
    }

    public function applyDeviceAutomationDeviceListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DeviceAutomation Device List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Device List 1', $vars);
                $view = new \View\DeviceAutomationView();
                $view->loadTemplate('DeviceAutomation', 'DeviceListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayDeviceAutomationDeviceDropDown($bind);
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Device List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DeviceAutomation Device List failed');
            }
        };
        return $func;
    }

    public function showDeviceAutomationRecordingCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DeviceAutomationView();
            $view->loadTemplate('DeviceAutomation', 'RecordingCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayDeviceAutomation($bind);
            \ISOPHP\js_core::$console->log('apply DeviceAutomation Recording Create 2', $vars);
        };
    }

    public function showDeviceAutomationRecordingList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for device name');
            \ISOPHP\js_core::$console->log($pageVars['device_name']);
            \ISOPHP\js_core::$console->log('Showing DeviceAutomation List');
            $vars['control'] = 'DeviceAutomation';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/deviceautomation/recording/all';
            $vars['device_name'] = $pageVars['params']['device_name'];
            $vars['device_id'] = $pageVars['params']['device_id'];
            $afterwards = $this->applyDeviceAutomationRecordingListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('DeviceAutomation Model: ', $result);
            return $result;
        };
    }

    public function applyDeviceAutomationRecordingListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our DeviceAutomation Recording List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Recording List 1', $vars);
                $view = new \View\DeviceAutomationView();
                $view->loadTemplate('DeviceAutomation', 'RecordingList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDeviceAutomation($bind);
                \ISOPHP\js_core::$console->log('apply DeviceAutomation Recording List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('DeviceAutomation Recording List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save device
     */
    public function saveDeviceAttemptToDeviceAutomation($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save device to deviceautomation');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DeviceAutomation';
        $vars['action'] = 'device-create';
        $vars['api_uri'] = '/api/deviceautomation/device/create';
        $vars['device_name'] = $jQuery('#device_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveDeviceResultFromDeviceAutomation($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveDeviceResultFromDeviceAutomation($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save deviceautomation device result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Device '.$request_data['device']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('deviceautomation device saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DeviceAutomation', 'device-list', array(), '/deviceautomation/device/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Device '.$request_data['device']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('deviceautomation device save failed');
            }
        };
        return $func;
    }

    /**
     * Save recording
     */
    public function saveRecordingAttemptToDeviceAutomation($pageVars)
    {
        \ISOPHP\js_core::$console->log('save recording to deviceautomation');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DeviceAutomation';
        $vars['action'] = 'recording-create';
        $vars['api_uri'] = '/api/deviceautomation/recording/create';
        $vars['device_name'] = $jQuery('#device_name')->val();
        $vars['recording_name'] = $jQuery('#recording_name')->val();
        $vars['recording_value'] = $jQuery('#recording_value')->val();
        $vars['recording_type'] = $jQuery('#recording_type')->val();
        \ISOPHP\js_core::$console->log('save recording vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRecordingResultFromDeviceAutomation($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRecordingResultFromDeviceAutomation($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save deviceautomation recording result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Recording '.$request_data['recording']['deviceautomation_recording_name'].' for Device '.$request_data['recording']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('deviceautomation recording saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Recording '.$request_data['recording']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('deviceautomation recording save failed');
            }
        };
        return $func;
    }
    /**
     * Delete recording
     */
    public function deleteRecordingAttemptToDeviceAutomation($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete recording to deviceautomation');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DeviceAutomation';
        $vars['action'] = 'recording-delete';
        $vars['api_uri'] = '/api/deviceautomation/recording/delete';
        $vars['device_name'] = $pageVars['params']['device_name'];
        $vars['recording_key'] = $pageVars['params']['recording_key'];
        $vars['recording_id'] = $pageVars['params']['recording_id'];
        \ISOPHP\js_core::$console->log('delete recording vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRecordingResultFromDeviceAutomation($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRecordingResultFromDeviceAutomation($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete deviceautomation recording result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Recording '.$request_data['recording']['deviceautomation_recording_name'].' from Device '.$request_data['recording']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('deviceautomation recording deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Recording '.$request_data['recording']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('deviceautomation recording delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete device
     */
    public function deleteDeviceAttemptToDeviceAutomation($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete device to deviceautomation');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'DeviceAutomation';
        $vars['action'] = 'device-delete';
        $vars['api_uri'] = '/api/deviceautomation/device/delete';
        $vars['device_name'] = $pageVars['params']['device_name'];
        \ISOPHP\js_core::$console->log('delete device vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteDeviceResultFromDeviceAutomation($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDeviceResultFromDeviceAutomation($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete deviceautomation device result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Device '.$request_data['device']['deviceautomation_device_name'].' for Device '.$request_data['device']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('deviceautomation device deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('DeviceAutomation', 'device-list', array(), '/deviceautomation/device/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Device '.$request_data['device']['deviceautomation_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('deviceautomation device delete failed');
            }
            $show_device_list = self::showDeviceAutomationDeviceList($pageVars) ;
            $show_device_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_deviceautomation_landing = $jQuery('.go-deviceautomation-landing');
                $go_deviceautomation_landing->off() ;
                $go_deviceautomation_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'landing', array(), '/deviceautomation');
                });

                $go_deviceautomation_device_list = $jQuery('.go-deviceautomation-device-list');
                $go_deviceautomation_device_list->off() ;
                $go_deviceautomation_device_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'device-list', array(), '/deviceautomation/device/list');
                });

                $go_deviceautomation_device_create = $jQuery('.go-deviceautomation-device-create');
                $go_deviceautomation_device_create->off() ;
                $go_deviceautomation_device_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'device-create', array(), '/deviceautomation/device/create');
                });

                $save_device = $jQuery('.attempt-device-create');
                $save_device->off() ;
                $save_device->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'device-save-attempt', array());
                });

                $go_deviceautomation_emulator_list = $jQuery('.go-deviceautomation-emulator-list');
                $go_deviceautomation_emulator_list->off() ;
                $go_deviceautomation_emulator_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'emulator-list', array(), '/deviceautomation/emulator/list');
                });

                $go_deviceautomation_emulator_create = $jQuery('.go-deviceautomation-emulator-create');
                $go_deviceautomation_emulator_create->off() ;
                $go_deviceautomation_emulator_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'emulator-create', array(), '/deviceautomation/emulator/create');
                });

                $save_emulator = $jQuery('.attempt-emulator-create');
                $save_emulator->off() ;
                $save_emulator->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'emulator-save-attempt', array());
                });

                $go_deviceautomation_recording_list = $jQuery('.go-deviceautomation-recording-list');
                $go_deviceautomation_recording_list->off() ;
                $go_deviceautomation_recording_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-deviceautomation-device-name') ;
                    $device_id = $jqThis->attr('data-deviceautomation-device-id') ;
                    \ISOPHP\js_core::$console->log('Clicked recording list button');
                    $navigate->route('DeviceAutomation', 'recording-list', array(
                        'device_name' => $device_name,
                        'device_id' => $device_id,
                    ), '/deviceautomation/'.$device_id.'/recording/list' );
                });

                $go_deviceautomation_recording_play = $jQuery('.go-deviceautomation-recording-play');
                $go_deviceautomation_recording_play->off() ;
                $go_deviceautomation_recording_play->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked recording play button');
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-deviceautomation-device-name') ;
                    $device_id = $jqThis->attr('data-deviceautomation-device-id') ;
                    $recording_id = $jqThis->attr('data-deviceautomation-recording-id') ;
                    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
                    if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
                        $token = $cookies['user_tokens'][0]['data'] ;
                    }
                    $config = new \Model\Configuration() ;
                    $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
                    $server_url .= '/api/deviceautomation/recording/play' ;
                    $server_url .= '?token='.$token ;
                    $server_url .= '&device_name='.$device_name ;
                    $server_url .= '&device_id='.$device_id ;
                    $server_url .= '&recording_id='.$recording_id ;
                    $html  = '<div class="fullWidth">' ;
                    if ($device_name !== '') {
                        $html .= '    <div class="fullWidth">' ;
                        $html .= '        <h4><strong>Device Name:</strong>'.$device_name.'</h4>' ;
                        $html .= '    </div>' ;
                    }
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <h4><strong>Device ID:</strong>'.$device_id.'</h4>' ;
                    $html .= '    </div>' ;
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <h4><strong>Recording ID:</strong>'.$recording_id.'</h4>' ;
                    $html .= '    </div>' ;
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <video width="700" height="450" controls="controls" preload="true"> ' ;
                    $html .= '            <source src="'.$server_url.'" type="video/mp4" /> ' ;
                    $html .= '        </video> ' ;
                    $html .= '    </div>' ;
                    \Core\WindowMessage::showOverlay($html);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $overlay_inner = $jQuery('.overlay_inner');
                    $overlay_inner->addClass('overlay_inner_large') ;
                });


                $go_deviceautomation_recording_download = $jQuery('.go-deviceautomation-recording-download');
                $go_deviceautomation_recording_download->off() ;
                $go_deviceautomation_recording_download->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked recording download button');
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-deviceautomation-device-name') ;
                    $device_id = $jqThis->attr('data-deviceautomation-device-id') ;
                    $recording_id = $jqThis->attr('data-deviceautomation-recording-id') ;
                    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
                    if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
                        $token = $cookies['user_tokens'][0]['data'] ;
                    }
                    $config = new \Model\Configuration() ;
                    $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
                    $server_url .= '/api/deviceautomation/recording/play' ;
                    $server_url .= '?token='.$token ;
                    $server_url .= '&device_name='.$device_name ;
                    $server_url .= '&device_id='.$device_id ;
                    $server_url .= '&recording_id='.$recording_id ;
                    $slug = $device_id.'_'.$recording_id ;
                    $dl_el_id = 'download_link_'.$slug ;
                    $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download>'.$slug.'.mp4</a>' ;
                    \ISOPHP\js_core::$console->log('Recording download html', $html);
                    $body_jq = $jQuery('body') ;
                    $body_jq->append($html) ;
                    $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
                    $dl_el_js->click() ;
                    \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
                    \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
//                    $dl_el_jq->remove() ;
                });

                $go_deviceautomation_recording_create = $jQuery('.go-deviceautomation-recording-create');
                $go_deviceautomation_recording_create->off() ;
                $go_deviceautomation_recording_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'recording-create', array(), '/deviceautomation/recording/create');
                });

                $save_recording = $jQuery('.attempt-recording-create');
                $save_recording->off() ;
                $save_recording->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'recording-save-attempt', array());
                });

                $recording_delete = $jQuery('.attempt-recording-delete');
                $recording_delete->off() ;
                $recording_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $recording_name = $jqThis->attr('data-deviceautomation-recording-name') ;
                    $recording_id = $jqThis->attr('data-deviceautomation-recording-id') ;
                    $device_name = $jqThis->attr('data-deviceautomation-device-name') ;
                    \ISOPHP\js_core::$console->log('Clicked recording delete button');
                    $msg = "You are about to delete the Recording for $recording_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $recording_id, $recording_name, $device_name) {
                        $navigate->route('DeviceAutomation', 'recording-delete-attempt', array(
                            'recording_id' => $recording_id,
                            'recording_name' => $recording_name,
                            'device_name' => $device_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $device_delete = $jQuery('.attempt-device-delete');
                $device_delete->off() ;
                $device_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-deviceautomation-device-name') ;
                    \ISOPHP\js_core::$console->log('Clicked device delete button');
                    $msg = "You are about to delete the Device $device_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $device_name) {
                        $navigate->route('DeviceAutomation', 'device-delete-attempt', array(
                            'device_name' => $device_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindDeviceListDropDownButtonsForRecordingList()
    {
        \ISOPHP\js_core::$console->log('Started binding device switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding device switch');
            $switch_device = $jQuery('#device_name');
            $switch_device->off() ;
            $switch_device->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#device_name') ;
                $device_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked device switch');
                $navigate->route('DeviceAutomation', 'recording-list', array(
                    'device_name' => $device_name,
                ));
            } ) ;

        };
        return $func;
    }



}