<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $functions_are_array = \ISOPHP\core::$php->is_array($rqd['functions']) ;
    if ($functions_are_array === true) {
        $functions_exist = true ;
    } else {
        $functions_exist = false ;
    }
    if ($functions_exist === true) {
        $function_count = \ISOPHP\core::$php->count($rqd['functions']) ;
    } else {
        $function_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-closure-function-create" class="btn btn-success go-closure-function-create">Create New Function</span>
            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
            <span id="go-closure-function-list" class="btn btn-info go-closure-function-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Function List</h4>
         </div> ' ;

    if ($functions_exist === true && $function_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'closure-function-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_closure_function">
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Repository</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Name</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="closure_function_table_title">Runtime</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Destinations</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="closure_function_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['functions'] as $one_closure_function) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_id">
                            <span class="closure_id closure_listing" data-toggle="tooltip" data-original-title="'.$one_closure_function['id'].'">'
                                . $one_closure_function['id'] .
                           '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_repository_name"><span class="closure_repository_name closure_listing">' . $one_closure_function['repository_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_closure_name"><span class="closure_function_name closure_listing">' . $one_closure_function['name'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="closure_list_closure_data"><span class="closure_function_data closure_listing">' . $one_closure_function['runtime'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_closure_type">
                            <span class="closure_type closure_listing">'  ;

                                $destinations = [
                                    ['slug' => 'standard_log', 'friendly' => 'Standard Log']
                                ] ;
                                $closure_destinations = \ISOPHP\core::$php->explode(',', $one_closure_function['destinations']) ;
                                foreach ($destinations as $destination) {
                                    if ( \ISOPHP\core::$php->in_array($destination['slug'], $closure_destinations) ) {
                                        $html = $html . '<span>'.$destination['friendly'].'</span><br />' ;
                                    }
                                }

                            $html = $html .
                           '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-3 col-3">
                                <i id="execute_closure_function_' . $one_closure_function['id'] . '"
                                   class="fa fa-1x fa-play hvr-grow dom attempt-function-execute btn btn-outline-success"
                                   data-closure-function-id="' . $one_closure_function['id'] . '"
                                   data-closure-function-name="' . $one_closure_function['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="execution_list_closure_function_' . $one_closure_function['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-function-execution-list btn btn-outline-primary"
                                   data-closure-function-id="' . $one_closure_function['id'] . '"
                                   data-closure-function-name="' . $one_closure_function['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="edit_closure_function_' . $one_closure_function['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom go-closure-function-edit btn btn-outline-primary"
                                   data-closure-function-id="' . $one_closure_function['id'] . '"
                                   data-closure-function-name="' . $one_closure_function['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="delete_closure_function_' . $one_closure_function['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-function-delete btn btn-outline-danger"
                                   data-closure-function-id="' . $one_closure_function['id'] . '"
                                   data-closure-function-name="' . $one_closure_function['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'closure-function-paginator') ;
            $html = $html .'</div>' ;
        }

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-closure-function-create" class="btn btn-success go-closure-function-create">Create New Function</span>
            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
            <span id="go-closure-function-list" class="btn btn-info go-closure-function-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};