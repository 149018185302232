<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-device-create" class="btn btn-success attempt-device-create">Create</span>
                        <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">Device Automation List</span>
                        <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
                    </div>
                        
                    <div>
                        <h4>New Device</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Device Name</label>
                            <input type="text" name="device_name" class="form-control" id="device_name" value="" />
                        </div>
                    </div>
                       
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Make &amp; Model</label>
                            <select id="make_model" name="make_model" class="form-control">
                                 <option value="android_standard">Android Standard</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea name="comment" id="comment" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-device-create" class="btn btn-success attempt-device-create">Create</span>
                        <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">Device Automation List</span>
                        <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
                    </div>
                    
                </div>
            </div>
             
        </div>';

    return $html;

};