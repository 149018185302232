<?php

Namespace Model\Build;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Build List of Piranha Web Services';
        $page['heading'] = 'List of Build';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Build Mod', $page, $pageVars);
        return $page;
    }

    public function showBuildLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BuildView();
            $view->loadTemplate('Build', 'BuildLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayBuild($bind);
            \ISOPHP\js_core::$console->log('apply Build Job Create 2', $vars);
        };
    }

    public function showBuildJobCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BuildView();
            $view->loadTemplate('Build', 'JobCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayBuild($bind);
            \ISOPHP\js_core::$console->log('apply Build Job Create 2', $vars);
        };
    }

    public function showBuildJobList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build List');
            $vars['control'] = 'Build';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/build/job/all';
            $afterwards = $this->applyBuildJobListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildJobListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Job List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Job List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'JobList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Job List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Job List failed');
            }
        };
        return $func;
    }


    public function showBuildJobEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Edit');
            $vars['control'] = 'Build';
            $vars['action'] = 'edit';
            $vars['api_uri'] = '/api/build/job/one';
            $vars['job_id'] = $pageVars['params']['job_id'];
            $afterwards = $this->applyBuildJobEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildJobEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Job Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Job Edit 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'JobEdit.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Job Edit 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Job Edit failed');
            }
        };
        return $func;
    }

    public function showBuildJobListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build List');
            $vars['control'] = 'Build';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/build/job/all';
            $afterwards = $this->applyBuildJobListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildJobListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Job List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Job List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'JobListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuildJobDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Build Job List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Job List failed');
            }
        };
        return $func;
    }

    public function showBuildPipelineCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BuildView();
            $view->loadTemplate('Build', 'PipelineCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayBuild($bind);
            \ISOPHP\js_core::$console->log('apply Build Pipeline Create 2', $vars);
        };
    }

    public function showBuildPipelineList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build List');
            $vars['control'] = 'Build';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/build/pipeline/execution/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applyBuildPipelineListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildPipelineListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Pipeline List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Pipeline List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'PipelineList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Pipeline List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Pipeline List failed');
            }
        };
        return $func;
    }


    public function showBuildPipelineEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Edit');
            $vars['control'] = 'Build';
            $vars['action'] = 'edit';
            $vars['api_uri'] = '/api/build/pipeline/one';
            $vars['pipeline_id'] = $pageVars['params']['pipeline_id'];
            $afterwards = $this->applyBuildPipelineEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildPipelineEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Pipeline Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Pipeline Edit 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'PipelineEdit.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Pipeline Edit 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Pipeline Edit failed');
            }
        };
        return $func;
    }

    public function showBuildPipelineListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build List');
            $vars['control'] = 'Build';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/build/pipeline/all';
            $afterwards = $this->applyBuildPipelineListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildPipelineListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Pipeline List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Pipeline List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'PipelineListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuildPipelineDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Build Pipeline List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Pipeline List failed');
            }
        };
        return $func;
    }

    public function showBuildObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\BuildView();
            $view->loadTemplate('Build', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayBuild($bind);
            \ISOPHP\js_core::$console->log('apply Build Object Create 2', $vars);
        };
    }

    public function showBuildObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for job name');
            \ISOPHP\js_core::$console->log($pageVars['job_name']);
            if (isset($pageVars['job_name'])) {
                \ISOPHP\js_core::$console->log('Showing Build List');
                $vars['control'] = 'Build';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/build/object/all';
                $vars['job_name'] = $pageVars['job_name'];
                $afterwards = $this->applyBuildObjectListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('Build Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyBuildObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Object List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Object List failed');
            }
        };
        return $func;
    }

    public function showBuildJobExecutionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build List');
            $vars['control'] = 'Build';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/build/job/execution/all';
            $vars['job_id'] = $pageVars['params']['job_id'];
            $afterwards = $this->applyBuildJobExecutionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildJobExecutionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Job Execution List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Job Execution List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'JobExecutionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Job Execution List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Job Execution List failed');
            }
        } ;
        return $func ;
    }

    public function showBuildPipelineExecutionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Pipeline List');
            $vars['control'] = 'Build';
            $vars['action'] = 'pipeline-list';
            $vars['api_uri'] = '/api/build/pipeline/execution/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applyBuildPipelineExecutionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildPipelineExecutionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Pipeline Execution List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Build Pipeline Execution List 1', $vars);
                $view = new \View\BuildView();
                $view->loadTemplate('Build', 'PipelineExecutionList.php', $vars);
                $bind = self::bindButtons();
                $view->displayBuild($bind);
                \ISOPHP\js_core::$console->log('apply Build Pipeline Execution List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Pipeline Execution List failed');
            }
        } ;
        return $func ;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save job
     */
    public function saveJobAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('save job to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['job_name'] = $jQuery('#job_name')->val();
        $vars['description'] = $jQuery('#description')->val();
        $vars['shell_data'] = $jQuery('#shell_data')->val();
        $job_id_element = $jQuery('#job_id')->val();
        \ISOPHP\js_core::$console->log('$job_id_element');
        \ISOPHP\js_core::$console->log($job_id_element);
        \ISOPHP\js_core::$console->log('$job_id param');
        \ISOPHP\js_core::$console->log($pageVars['params']['job_id']);
        if ($job_id_element != null) {
            $vars['job_id'] = $job_id_element ;
            $vars['action'] = 'job-edit';
            $vars['api_uri'] = '/api/build/job/edit';
        } else {
            $vars['action'] = 'job-create';
            $vars['api_uri'] = '/api/build/job/create';
        }
        $afterwards = $this->saveJobResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveJobResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save build job result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Job '.$request_data['job']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build job saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Build', 'job-list', array(), '/build/job/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Job '.$request_data['job']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build job save failed');
            }
        };
        return $func;
    }

    /**
     * Execute job
     */
    public function executeJobAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('execute job to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'job-execute';
        $vars['api_uri'] = '/api/build/job/execute';
        $vars['job_id'] = $pageVars['params']['job_id'] ;
        $afterwards = $this->executeJobResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function executeJobResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our execute build job result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Executing Job '.$request_data['execution']['pipeline']['project-name'].', ';
                $msg = $msg . 'Run ID '.$request_data['execution']['history_count'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build job executed, data: ', $request_data);
//                $navigate = new \Model\Navigate();
//                $navigate->route('Build', 'job-list', array(), '/build/job/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Starting Job '.$request_data['job']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build job execute failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('save object to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/build/object/create';
        $vars['job_name'] = $jQuery('#job_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $vars['object_value'] = $jQuery('#object_value')->val();
        $vars['object_type'] = $jQuery('#object_type')->val();
        $afterwards = $this->saveObjectResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save build object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['object']['build_object_name'].' for Job '.$request_data['object']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build object saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['object']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/build/object/delete';
        $vars['job_name'] = $pageVars['params']['job_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        $afterwards = $this->saveObjectResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteObjectResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete build object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object']['build_object_name'].' from Job '.$request_data['object']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build object deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build object delete failed');
            }
        };
        return $func;
    }
    /**
     * Rebuild pipeline
     */
    public function rebuildPipelineAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('rebuild pipeline to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'pipeline-rebuild';
        $vars['api_uri'] = '/api/build/pipeline/rebuild';
        $vars['execution_id'] = $pageVars['params']['execution_id'];
        $vars['unique_id'] = $pageVars['params']['unique_id'];
        $vars['repository_id'] = $pageVars['params']['repository_id'];
        $vars['git_id'] = $pageVars['params']['git_id'];
        $afterwards = $this->rebuildPipelineResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function rebuildPipelineResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our rebuild build pipeline result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Queued Pipeline '.$request_data['pipeline']['build_pipeline_name'].' for Pipeline '.$request_data['pipeline']['build_pipeline_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build pipeline rebuildd, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Build', 'pipeline-list', array(), '/build/pipeline/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Queueing Pipeline '.$request_data['pipeline']['build_pipeline_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build pipeline rebuild failed');
            }
            $show_pipeline_list = self::showBuildPipelineExecutionList($pageVars) ;
            $show_pipeline_list() ;
        };
        return $func;
    }
    /**
     * Cancel pipeline
     */
    public function cancelPipelineAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('cancel pipeline to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'pipeline-cancel';
        $vars['api_uri'] = '/api/build/pipeline/cancel';
        $vars['execution_id'] = $pageVars['params']['execution_id'];
        $vars['unique_id'] = $pageVars['params']['unique_id'];
        $vars['repository_id'] = $pageVars['params']['repository_id'];
        $vars['git_id'] = $pageVars['params']['git_id'];
        $afterwards = $this->cancelPipelineResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function cancelPipelineResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our cancel build pipeline result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Cancelled Pipeline '.$request_data['execution_id'].' for Pipeline '.$request_data['pipeline']['build_pipeline_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build pipeline cancelled, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Build', 'pipeline-list', array(), '/build/pipeline/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Cancelling Pipeline '.$request_data['pipeline']['build_pipeline_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build pipeline cancel failed');
            }
            $show_pipeline_list = self::showBuildPipelineExecutionList($pageVars) ;
            $show_pipeline_list() ;
        };
        return $func;
    }
    /**
     * Delete job
     */
    public function deleteJobAttemptToBuild($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete job to build');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Build';
        $vars['action'] = 'job-delete';
        $vars['api_uri'] = '/api/build/job/delete';
        $vars['job_id'] = $pageVars['params']['job_id'];
        $afterwards = $this->deleteJobResultFromBuild($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteJobResultFromBuild($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete build job result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Job '.$request_data['job']['build_job_name'].' for Job '.$request_data['job']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('build job deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Build', 'job-list', array(), '/build/job/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Job '.$request_data['job']['build_job_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('build job delete failed');
            }
            $show_job_list = self::showBuildJobList($pageVars) ;
            $show_job_list() ;
        };
        return $func;
    }



    public function showBuildJobExecutionLog($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Execution Log');
            $vars['control'] = 'Build';
            $vars['action'] = 'job-execution-log';
            $vars['api_uri'] = '/api/build/job/log';
            $vars['job_id'] = $pageVars['params']['job_id'];
            $vars['execution_id'] = $pageVars['params']['execution_id'];
            $afterwards = self::applyBuildJobExecutionLog($pageVars);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildJobExecutionLog($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Job Execution Log result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;

                $html  = '<div class="fullWidth">' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <h4><strong>Job ID:</strong>'.$request_data['job_id'].'</h4>' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <h4><strong>Execution ID:</strong>'.$request_data['execution_id'].'</h4>' ;
                $html .= '    </div>' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '        <textarea name="" class="log_output" id="log_output" rows="15" style="overflow-y: scroll">' ;
                $html .= $request_data['output'] ;
                $html .= '</textarea>' ;
                $html .= '</div>' ;

                \Core\WindowMessage::closeOverlay();
                \Core\WindowMessage::showOverlay($html);
                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;
                \ISOPHP\js_core::$console->log('do scroll of log');
                $overlay_content = \ISOPHP\js_core::$window->document->getElementById("overlay_content");
                \ISOPHP\js_core::$console->log('got element');
                $overlay_content->scrollTop = $overlay_content->scrollHeight;

            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Job Execution Log failed');
            }
        };
        return $func;
    }

    public function showBuildJobExecutionParameters($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $html  = '<div class="fullWidth">' ;
        $html .= '    <div class="fullWidth">' ;
        $html .= '        <h4><strong>Job ID:</strong>'.$pageVars['params']['job_id'].'</h4>' ;
        $html .= '    </div>' ;
        $html .= '    <div class="fullWidth">' ;
        $html .= '        <h4><strong>Execution ID:</strong>'.$pageVars['params']['execution_id'].'</h4>' ;
        $html .= '    </div>' ;
        $html .= '    <div class="fullWidth">' ;
        $target_class = '.execution_parameters_'.$pageVars['params']['job_id'].'_'.$pageVars['params']['execution_id'] ;
        $jq_params = $jQuery($target_class) ;
        foreach ($jq_params as $jq_param) {
            $parameter_key = $jq_param->attr('name') ;
            $parameter_value = $jq_param->val() ;
            $html =  $html . '<span><strong>'.$parameter_key.': </strong>'.$parameter_value.'</span><br>' ;
        }
        $html .= '</div>' ;

        \Core\WindowMessage::showOverlay($html);
        $jQuery = \ISOPHP\js_core::$jQuery;
        $overlay_inner = $jQuery('.overlay_inner');
        $overlay_inner->addClass('overlay_inner_large') ;
    }


    public function showBuildPipelineExecutionLog($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Build Pipeline Execution Log');
            $vars['control'] = 'Build';
            $vars['action'] = 'pipeline-execution-log';
            $vars['api_uri'] = '/api/build/pipeline/log';
            $vars['git_id'] = $pageVars['params']['git_id'];
            $vars['repository_id'] = $pageVars['params']['repository_id'];
            $vars['execution_id'] = $pageVars['params']['execution_id'];
            $vars['unique_id'] = $pageVars['params']['unique_id'];
            $afterwards = self::applyBuildPipelineExecutionLog($pageVars);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Build Model: ', $result);
            return $result;
        };
    }

    public function applyBuildPipelineExecutionLog($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Build Pipeline Execution Log result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;

                $html  = '<div class="fullWidth">' ;
                $html .= '    <div class="fullWidth">' ;
                $html .= '       <div class="col-sm-6 pipeline_log_title pipeline_log_title_left">' ;
                $html .= '            <div class="fullWidth">' ;
                $html .= '                <h4><strong>Repository:</strong>'.$request_data['repository_id'].'</h4>' ;
                $html .= '            </div>' ;
                $html .= '            <div class="fullWidth">' ;
                $html .= '                <h4><strong>Trigger ID:</strong>'.$request_data['git_id'].'</h4>' ;
                $html .= '            </div>' ;
                $html .= '            <div class="fullWidth">' ;
                $html .= '                <h4><strong>Execution ID:</strong>'.$request_data['execution_id'].'</h4>' ;
                $html .= '            </div>' ;
                $html .= '        </div>' ;
                $html .= '        <div class="col-sm-6 pipeline_log_title pipeline_log_title_right">' ;
                $html .= '            <div class="fullWidth">' ;

                $html .= '               <span id="refresh-pipeline-log-overlay" class="btn btn-success pipeline_refresh_button show-pipeline-execution-log"
                          data-build-execution-id="'.$request_data['execution_id'].'"
                          data-build-unique-id="'.$request_data['unique_id'].'"
                          data-build-repository-id="'.$request_data['repository_id'] . '"
                          data-build-git-id="'.$request_data['git_id'].'">' ;
                $html .= '                  Refresh' ;
                $html .= '                  <i class="fa fa-1x fa-refresh fa-refresh-button"
                          data-build-execution-id="'.$request_data['execution_id'].'"
                          data-build-unique-id="'.$request_data['unique_id'].'"
                          data-build-repository-id="'.$request_data['repository_id'] . '"
                          data-build-git-id="'.$request_data['git_id'].'"></i>' ;
                $html .= '              </span>' ;

                $html .= '           </div>' ;
                $html .= '        </div>' ;
                $html .= '    </div>' ;
                $html .= '    <div class="fullWidth" style="display:block; float: left;">' ;
                $html .= '        <pre name="" class="log_output fullWidth" id="log_output" rows="15" style="overflow-y: unset">' ;
                $html .= $request_data['output'] ;
                $html .= '</pre>' ;
                $html .= '    </div>' ;
                $html .= '</div>' ;

                \Core\WindowMessage::showOverlay($html);
                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;

                \ISOPHP\js_core::$console->log('do scroll of log');
                $overlay_content = \ISOPHP\js_core::$window->document->getElementById("overlay_content");
                $overlay_content->scrollTop = $overlay_content->scrollHeight;

                $overlay_buttons = $jQuery('.overlay_buttons');
                $overlay_buttons->html() ;

                $bind_buttons = self::bindButtons() ;
                $bind_buttons() ;

            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Build Pipeline Execution Log failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_build_landing = $jQuery('.go-build-landing');
                $go_build_landing->off() ;
                $go_build_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Build', 'landing', array(), '/build');
                });

                $go_build_job_list = $jQuery('.go-build-job-list');
                $go_build_job_list->off() ;
                $go_build_job_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Build', 'job-list', array(), '/build/job/list');
                });

                $go_build_job_create = $jQuery('.go-build-job-create');
                $go_build_job_create->off() ;
                $go_build_job_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Build', 'job-create', array(), '/build/job/create');
                });

                $save_job = $jQuery('.attempt-job-create');
                $save_job->off() ;
                $save_job->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Build', 'job-save-attempt', array());
                });

                $edit_job = $jQuery('.attempt-job-edit');
                $edit_job->off() ;
                $edit_job->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
//                    $this_id = $jsThis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $job_id = $jqThis->attr('data-build-job-id') ;
//                    $navigate->route('Build', 'job-edit-attempt', array(
//                        'job_id' => $job_id
//                    ));
                });

                $execute_job = $jQuery('.attempt-job-execute');
                $execute_job->off() ;
                $execute_job->on('click', function ($jsThis) use ($navigate, $jQuery) {
                    $jsThis->preventDefault();
//                    $this_id = $jsThis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $job_id = $jqThis->attr('data-build-job-id') ;
//                    $navigate->route('Build', 'job-execute-attempt', array(
//                        'job_id' => $job_id
//                    ));
                });

                $go_build_pipeline_list = $jQuery('.go-build-pipeline-list');
                $go_build_pipeline_list->off() ;
                $go_build_pipeline_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Build', 'pipeline-list', array(), '/build/pipeline/execution/all');
                });

                $go_build_pipeline_list_page = $jQuery('.build-pipeline-execution-paginator');
                $go_build_pipeline_list_page->off() ;
                $go_build_pipeline_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked build pipeline execution paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('Build', 'pipeline-list', array('page' => $new_page), '/build/pipeline/execution/page/'.$new_page);
                });

                $build_pipeline_execution_paginator_direct = $jQuery('.build-pipeline-execution-paginator.paginator_direct_page');
                $build_pipeline_execution_paginator_direct->off() ;
                $build_pipeline_execution_paginator_direct->on('change', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Changed variable build-pipeline-execution paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->val() ;
                    $invalid_pages = array(0, null) ;
                    if (\ISOPHP\core::$php->in_array($new_page, $invalid_pages)) {
                        $new_page = 1 ;
                    }
                    $jsthis->preventDefault();
                    $navigate->route('Build', 'pipeline-list', array('page' => $new_page), '/build/pipeline/execution/page/'.$new_page);
                });

                $go_build_pipeline_execute = $jQuery('.go-build-pipeline-execute');
                $go_build_pipeline_execute->off() ;
                $go_build_pipeline_execute->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Build', 'pipeline-execute', array(), '/build/pipeline/execute');
                });

                $go_build_pipeline_execute = $jQuery('.go-build-pipeline-execute');
                $go_build_pipeline_execute->off() ;
                $go_build_pipeline_execute->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Build', 'pipeline-create', array(), '/build/pipeline/create');
                });

                $save_object = $jQuery('.attempt-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Build', 'object-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-build-object-name') ;
                    $object_id = $jqThis->attr('data-build-object-id') ;
                    $job_name = $jqThis->attr('data-build-job-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg = "You are about to delete the Object for $object_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $job_name) {
                        $navigate->route('Build', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'job_name' => $job_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $job_delete = $jQuery('.attempt-job-delete');
                $job_delete->off() ;
                $job_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $job_id = $jqThis->attr('data-build-job-id') ;
                    $job_name = $jqThis->attr('data-build-job-name') ;
                    \ISOPHP\js_core::$console->log('Clicked job delete button');
                    $msg = "You are about to delete the Job $job_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $job_name, $job_id) {
                        $navigate->route('Build', 'job-delete-attempt', array(
                            'job_name' => $job_name,
                            'job_id' => $job_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $pipeline_rebuild = $jQuery('.attempt-pipeline-rebuild');
                $pipeline_rebuild->off() ;
                $pipeline_rebuild->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $execution_id = $jqThis->attr('data-build-execution-id') ;
                    $repository_id = $jqThis->attr('data-build-repository-id') ;
                    $git_id = $jqThis->attr('data-build-git-id') ;
                    $unique_id = $jqThis->attr('data-build-unique-id') ;
                    \ISOPHP\js_core::$console->log('Clicked pipeline rebuild button');
                    $msg  = "You are about to rebuild the Pipeline Execution $execution_id, from Repository $repository_id.";
                    $msg .= "<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $execution_id, $repository_id, $git_id, $unique_id) {
                        $navigate->route('Build', 'pipeline-rebuild-attempt', array(
                            'unique_id' => $unique_id,
                            'execution_id' => $execution_id,
                            'repository_id' => $repository_id,
                            'git_id' => $git_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $pipeline_cancel = $jQuery('.attempt-pipeline-cancel');
                $pipeline_cancel->off() ;
                $pipeline_cancel->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $execution_id = $jqThis->attr('data-build-execution-id') ;
                    $repository_id = $jqThis->attr('data-build-repository-id') ;
                    $git_id = $jqThis->attr('data-build-git-id') ;
                    $unique_id = $jqThis->attr('data-build-unique-id') ;
                    \ISOPHP\js_core::$console->log('Clicked pipeline cancel button');
                    $msg  = "You are about to cancel the Pipeline Execution $execution_id, from Repository $repository_id.";
                    $msg .= "<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $execution_id, $repository_id, $git_id, $unique_id) {
                        $navigate->route('Build', 'pipeline-cancel-attempt', array(
                            'unique_id' => $unique_id,
                            'execution_id' => $execution_id,
                            'repository_id' => $repository_id,
                            'git_id' => $git_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $job_edit = $jQuery('.go-build-job-edit');
                $job_edit->off() ;
                $job_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked job edit button');
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $job_id = $jqThis->attr('data-build-job-id') ;
                    $job_name = $jqThis->attr('data-build-job-name') ;
                    $navigate->route('Build', 'job-edit', array(
                        'job_name' => $job_name,
                        'job_id' => $job_id
                    ), '/build/job/edit/'.$job_id);
                } ) ;

                $job_execution_list = $jQuery('.go-job-execution-list');
                $job_execution_list->off() ;
                $job_execution_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $job_name = $jqThis->attr('data-build-job-name') ;
                    $job_id = $jqThis->attr('data-build-job-id') ;
                    \ISOPHP\js_core::$console->log('Clicked job execution list button');
                    $navigate->route('Build', 'job-execution-list', array(
                        'job_id' => $job_id
                    ), '/build/job/'.$job_id.'/execution/list');
                } ) ;

                $job_execution_log = $jQuery('.show-job-execution-log');
                $job_execution_log->off() ;
                $job_execution_log->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \Core\WindowMessage::closeOverlay() ;
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $job_id = $jqThis->attr('data-build-job-id') ;
                    $job_execution_id = $jqThis->attr('data-build-job-execution-id') ;
                    \ISOPHP\js_core::$console->log('Clicked job execution log button');

                    $pageVars = array() ;
                    $pageVars['params'] = array() ;
                    $pageVars['params']['job_id'] = $job_id ;
                    $pageVars['params']['execution_id'] = $job_execution_id ;
                    $show_log = self::showBuildJobExecutionLog($pageVars) ;
                    $show_log();

                } ) ;

                $job_execution_parameters = $jQuery('.show-build-job-execution-parameters');
                $job_execution_parameters->off() ;
                $job_execution_parameters->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $job_id = $jqThis->attr('data-build-job-id') ;
                    $job_execution_id = $jqThis->attr('data-build-job-execution-id') ;
                    \ISOPHP\js_core::$console->log('Clicked job execution parameters button');
                    $pageVars = array() ;
                    $pageVars['params'] = array() ;
                    $pageVars['params']['job_id'] = $job_id ;
                    $pageVars['params']['execution_id'] = $job_execution_id ;
                    self::showBuildJobExecutionParameters($pageVars) ;

                } ) ;

                $pipeline_execution_log = $jQuery('.show-pipeline-execution-log');
                $pipeline_execution_log->off() ;
                $pipeline_execution_log->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $git_id = $jqThis->attr('data-build-git-id') ;
                    $unique_id = $jqThis->attr('data-build-unique-id') ;
                    $execution_id = $jqThis->attr('data-build-execution-id') ;
                    $repository_id = $jqThis->attr('data-build-repository-id') ;
                    \ISOPHP\js_core::$console->log('Clicked pipeline execution log button') ;
                    $pageVars = array() ;
                    $pageVars['params'] = array() ;
                    $pageVars['params']['git_id'] = $git_id ;
                    $pageVars['params']['execution_id'] = $execution_id ;
                    $pageVars['params']['unique_id'] = $unique_id ;
                    $pageVars['params']['repository_id'] = $repository_id ;
                    $show_log = self::showBuildPipelineExecutionLog($pageVars) ;
                    $show_log();
                } ) ;

            }
        };
    }

    public function bindJobListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding job switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding job switch');
            $switch_job = $jQuery('#job_name');
            $switch_job->off() ;
            $switch_job->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#job_name') ;
                $job_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked job switch');
                $navigate->route('Build', 'object-list', array(
                    'job_name' => $job_name,
                ));
            } ) ;

        };
        return $func;
    }



}
