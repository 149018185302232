<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $repositories_are_array = \ISOPHP\core::$php->is_array($rqd['repositories']) ;
    if ($repositories_are_array === true) {
        $repositories_exist = true ;
    } else {
        $repositories_exist = false ;
    }
    if ($repositories_exist === true) {
        $repository_count = \ISOPHP\core::$php->count($rqd['repositories']) ;
    } else {
        $repository_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-artifact-repository-create" class="btn btn-success go-artifact-repository-create">New Repository</span>
            <span id="go-artifact-landing" class="btn btn-info go-artifact-landing">Artifact Home</span>
            <span id="go-artifact-repository-list" class="btn btn-info go-artifact-repository-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Repository List</h4>
        </div> ' ;

    if ($repositories_exist === true && $repository_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-3 col-3">
                        <h5 class="artifact_repository_id_title">ID</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="artifact_repository_id_title">Repository Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="artifact_repository_id_title">Description</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="artifact_repository_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['repositories'] as $one_artifact_repository) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_artifact_repository">
                    <div class="col-sm-3 col-3">
                        <p class="artifact_list_id"><span class="artifact_id artifact_listing">' . $one_artifact_repository['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="artifact_list_name"><span class="artifact_name artifact_listing">' . $one_artifact_repository['name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="artifact_list_comment artifact_listing">' . $one_artifact_repository['description'] . '</p>
                    </div>     
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_artifact_repository_' . $one_artifact_repository['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-repository-edit btn btn-outline-primary"
                                   data-artifact-repository-id="' . $one_artifact_repository['id'] . '"
                                   data-artifact-repository-name="' . $one_artifact_repository['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_artifact_repository_' . $one_artifact_repository['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-repository-delete btn btn-outline-danger"
                                   data-artifact-repository-id="' . $one_artifact_repository['id'] . '"
                                   data-artifact-repository-name="' . $one_artifact_repository['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Repositories</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-artifact-repository-create" class="btn btn-success go-artifact-repository-create">New Repository</span>
                <span id="go-artifact-landing" class="btn btn-info go-artifact-landing">Artifact Home</span>
                <span id="go-artifact-repository-list" class="btn btn-info go-artifact-repository-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};