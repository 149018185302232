<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $scaledinstance_groups_are_array = \ISOPHP\core::$php->is_array($rqd['scaledinstance_groups']) ;
    if ($scaledinstance_groups_are_array === true) {
        $scaledinstance_groups_exist = true ;
    } else {
        $scaledinstance_groups_exist = false ;
    }
    if ($scaledinstance_groups_exist === true) {
        $scaledinstance_group_count = \ISOPHP\core::$php->count($rqd['scaledinstance_groups']) ;
    } else {
        $scaledinstance_group_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-scaledinstancegroup-create" class="btn btn-success go-sc1-scaledinstancegroup-create">New Scaled Instance Group</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scaledinstancegroup-executionlog-list" class="btn btn-info go-sc1-scaledinstancegroup-executionlog-list">Execution Logs</span>
            <span id="go-sc1-scaledinstancegroup-list" class="btn-refresh btn btn-info go-sc1-scaledinstancegroup-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Scaled Instance Group List</h4>
         </div> ' ;

    if ($scaledinstance_groups_exist === true && $scaledinstance_group_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-scaledinstancegroup-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_scaledinstance_group item_list_sc1_scaledinstance_groups" id="item_list_sc1_scaledinstance_groups"">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scaledinstance_group_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scaledinstance_group_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scaledinstance_group_table_title">Size</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_scaledinstance_group_table_title">Start</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_scaledinstance_group_table_title">End</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scaledinstance_group_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scaledinstance_group_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['scaledinstance_groups'] as $one_sc1_scaledinstance_group) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->substr($one_sc1_scaledinstance_group['id'], 0, 10) ;

            $html = $html .
                      ' </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_instance_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_scaledinstance_group['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_scaledinstance_group_size"><span class="sc1_scaledinstance_group_name sc1_listing">' . $one_sc1_scaledinstance_group['size'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_scaledinstance_group_start_time"><span class="sc1_scaledinstance_group_start_time sc1_listing">' . $one_sc1_scaledinstance_group['start_time'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_scaledinstance_group_end_time"><span class="sc1_scaledinstance_group_end_time sc1_listing">' . $one_sc1_scaledinstance_group['end_time'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_scaledinstance_group_description"><span class="sc1_scaledinstance_group_description sc1_listing">' . $one_sc1_scaledinstance_group['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_scaledinstance_group_' . $one_sc1_scaledinstance_group['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-scaledinstancegroup-edit btn btn-outline-primary"
                                   data-sc1-scaledinstancegroup-id="' . $one_sc1_scaledinstance_group['id'] . '"
                                   data-sc1-scaledinstancegroup-name="' . $one_sc1_scaledinstance_group['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_scaledinstance_group_' . $one_sc1_scaledinstance_group['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-scaledinstancegroup-delete btn btn-outline-danger"
                                   data-sc1-scaledinstancegroup-id="' . $one_sc1_scaledinstance_group['id'] . '"
                                   data-sc1-scaledinstancegroup-name="' . $one_sc1_scaledinstance_group['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-scaledinstancegroup-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Scaled Instance Groups</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-scaledinstancegroup-create" class="btn btn-success go-sc1-scaledinstancegroup-create">New Scaled Instance Group</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scaledinstancegroup-executionlog-list" class="btn btn-info go-sc1-scaledinstancegroup-executionlog-list">Execution Logs</span>
            <span id="go-sc1-scaledinstancegroup-list" class="btn-refresh btn btn-info go-sc1-scaledinstancegroup-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    return $html;

};