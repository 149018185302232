<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $roles_are_array = \ISOPHP\core::$php->is_array($rqd['roles']) ;
    if ($roles_are_array === true) {
        $roles_exist = true ;
    } else {
        $roles_exist = false ;
    }
    if ($roles_exist === true) {
        $role_count = \ISOPHP\core::$php->count($rqd['roles']) ;
    } else {
        $role_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-role-create" class="btn btn-success go-sam-role-create">Create New Role</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-role-list" class="btn btn-info go-sam-role-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Role List</h4>
         </div> ' ;

    if ($roles_exist === true && $role_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_sam_role">
                        <div class="col-sm-2 col-2">
                            <h5 class="sam_role_table_title">ID</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="sam_role_table_title">Name</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="sam_role_table_title">Policies</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sam_role_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['roles'] as $one_sam_role) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="sam_list_id"><span class="sam_id sam_listing">' . $one_sam_role['id'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="sam_list_user_name"><span class="sam_user_name sam_listing">' . $one_sam_role['name'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4"> ' ;

            if (is_array($one_sam_role['policies']) && count($one_sam_role['policies']) > 0) {
                foreach ($one_sam_role['policies'] as $one_policy_entity => $one_policy_name) {
                    $html = $html . '<span class="col-sm-12 col-12 one_sam_policy">' ;
                    $html = $html . '    <strong>'.$one_policy_name.'</strong>' ;
                    $html = $html . '</span>' ;
                }
            } else {
                $html = $html . 'Zero Policies for Role' ;
            }

            $html = $html . '
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sam_role_' . $one_sam_role['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-role-edit btn btn-outline-primary"
                                   data-sam-role-id="' . $one_sam_role['id'] . '"
                                   data-sam-role-name="' . $one_sam_role['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sam_role_' . $one_sam_role['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-role-delete btn btn-outline-danger"
                                   data-sam-role-id="' . $one_sam_role['id'] . '"
                                   data-sam-role-name="' . $one_sam_role['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Roles</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-role-create" class="btn btn-success go-sam-role-create">Create New Role</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-role-list" class="btn btn-info go-sam-role-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div> ' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SAM Roles</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_role_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_role_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_role_awscli_aws" class="instruction_set instruction_set_list_role_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceRoleSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceRoleSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_role_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_role_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_role_ptc_aws" class="instruction_set instruction_set_list_role_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceRoleSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceRoleSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};