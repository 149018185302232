<?php

Namespace Model\Mindstorm;

use ISOPHP\core;

class InstancePageModel extends \Model\Base {

    public function showMindstormInstanceCreate($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MindstormView();
            $view->loadTemplate('Mindstorm', 'InstanceCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayMindstorm($bind);
            \ISOPHP\js_core::$console->log('apply Mindstorm Instance Create 2', $vars);
        };
    }

    public function showMindstormInstanceList($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm Instance List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mindstorm/instance/all';
            $afterwards = $this->applyMindstormInstanceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormInstanceListResult($pageVars) {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Instance List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'InstanceList.php', $vars);
                $bind = self::bindButtons();
                $view->displayMindstorm($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance List 2', $vars);
                $page_model = new \Model\Mindstorm\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Instance List failed');
            }
        };
        return $func;
    }


    public function showMindstormInstanceListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mindstorm/instance/all';
            $afterwards = $this->applyMindstormInstanceListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormInstanceListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Instance List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'InstanceListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayMindstormInstanceDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Instance List failed');
            }
        };
        return $func;
    }

    public function showMindstormInstanceSizeDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list-size';
            $vars['api_uri'] = '/api/mindstorm/size/all';
            $afterwards = $this->applyMindstormInstanceSizeDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormInstanceSizeDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Instance Size List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance Size List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'InstanceSizeDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayMindstormSizeDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance Size List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Instance Size List failed');
            }
        };
        return $func;
    }

    public function showMindstormInstanceImageDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm Image List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list-image';
            $vars['api_uri'] = '/api/mindstorm/image/all';
            $afterwards = $this->applyMindstormInstanceImageDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormInstanceImageDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Instance Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance Image List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'InstanceImageDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayMindstormImageDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Instance Image List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Instance Image List failed');
            }
        };
        return $func;
    }

    /**
     * Save instance
     */
    public function saveInstanceAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('save instance to mindstorm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'instance-create';
        $vars['api_uri'] = '/api/mindstorm/instance/create';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['vm_description'] = $jQuery('#vm_description')->val();
        $vars['size_slug'] = $jQuery('#size_slug')->val();
        $vars['image_id'] = $jQuery('#image_id')->val();
//        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
//        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveInstanceResultFromMindstorm($pageVars);
        $msg = 'Requesting Instance '.$vars['instance_name'];
        \Core\WindowMessage::showMessage($msg, 'good');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveInstanceResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save mindstorm instance result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Creation begun of Instance '.$request_data['vm_details']['vm_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm instance saved, data: ', $request_data);
                $msg_type = 'good' ;
                $update_url = '/api/mindstorm/instance/modify_status' ;
                $vars = array(
                    'vm_id' => $request_data['vm_details']['vm_id'],
                    'mod_id' => $request_data['modification_id'],
                    'vm_name' => $request_data['vm_details']['vm_name'],
                ) ;
                \Core\WindowMessage::showStatus($msg_type, $vars, $update_url) ;
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed creation Request for Instance '.$request_data['vm_details']['vm_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm instance save failed');
            }
        };
        return $func;
    }

    public function deleteInstanceAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete volume to mindstorm');
        \ISOPHP\js_core::$console->log($pageVars);
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'instance-delete';
        $vars['api_uri'] = '/api/mindstorm/instance/delete';
        $vars['instance_name'] = $pageVars['params']['instance_name'];
        $vars['instance_id'] = $pageVars['params']['instance_id'];
        \ISOPHP\js_core::$console->log('delete volume vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteInstanceResultFromMindstorm($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteInstanceResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete mindstorm instance result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Instance '.$request_data['instance']['mindstorm_instance_name'].' for Instance '.$request_data['instance']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm instance deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Instance '.$request_data['instance']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm instance delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_mindstorm_instance_list = $jQuery('.go-mindstorm-instance-list');
                $go_mindstorm_instance_list->off() ;
                $go_mindstorm_instance_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'instance-list', array(), '/mindstorm/instance/list');
                });

                $go_mindstorm_instance_create = $jQuery('.go-mindstorm-instance-create');
                $go_mindstorm_instance_create->off() ;
                $go_mindstorm_instance_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'instance-create', array(), '/mindstorm/instance/create');
                });

                $save_instance = $jQuery('.attempt-instance-create');
                $save_instance->off() ;
                $save_instance->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'instance-save-attempt', array());
                });

                $instance_edit = $jQuery('.attempt-instance-edit');
                $instance_edit->off() ;
                $instance_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $instance_name = $jqThis->attr('data-mindstorm-instance-name') ;
                    $instance_id = $jqThis->attr('data-mindstorm-instance-id') ;
                    \ISOPHP\js_core::$console->log('Clicked instance edit button');
                    $msg = "Editing Instance $instance_name.";
                    $confirmAction = function () use ($navigate, $instance_id, $instance_name) {
                        $navigate->route('Mindstorm', 'instance-edit-attempt', array(
                            'instance_id' => $instance_id,
                            'instance_name' => $instance_name,
                            '' => $instance_name
                        ));
                    };
                    $data  = '<div class="col-sm-12">' ;
                    $data .= '  <h3>State:</h3>' ;
                    $data .= '</div>' ;
                    $data .= '<div class="col-sm-12">' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-play btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-stop btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-pause btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-resume btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-power-off btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '  <div class="col-sm-2">' ;
                    $data .= '    <i class="fa fa-1x fa-power-on btn-outline-info"></i>' ;
                    $data .= '  </div>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $instance_delete = $jQuery('.attempt-instance-delete');
                $instance_delete->off() ;
                $instance_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $instance_name = $jqThis->attr('data-mindstorm-instance-name') ;
                    $instance_id = $jqThis->attr('data-mindstorm-instance-id') ;
                    \ISOPHP\js_core::$console->log('Clicked instance delete button');
                    $msg = "You are about to delete the Instance $instance_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $instance_id, $instance_name) {
                        $navigate->route('Mindstorm', 'instance-delete-attempt', array(
                            'instance_id' => $instance_id,
                            'instance_name' => $instance_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;



            }
        };
    }


}