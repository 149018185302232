<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $directories_are_array = \ISOPHP\core::$php->is_array($rqd['directories']) ;
    if ($directories_are_array === true) {
        $directories_exist = true ;
    } else {
        $directories_exist = false ;
    }
    if ($directories_exist === true) {
        $directory_count = \ISOPHP\core::$php->count($rqd['directories']) ;
    } else {
        $directory_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-drive-directory-create" class="btn btn-success go-drive-directory-create">New Directory</span>
            <span id="go-drive-landing" class="btn btn-info go-drive-landing">Drive Home</span>
            <span id="go-drive-directory-list" class="btn btn-info go-drive-directory-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Directory List</h4>
        </div> ' ;

    if ($directories_exist === true && $directory_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <h5 class="drive_directory_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="drive_directory_id_title">Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="drive_directory_id_title">Description</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="drive_directory_id_title">Scope</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="drive_directory_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['directories'] as $one_drive_directory) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_drive_directory">
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_id"><span class="drive_id drive_listing">' . $one_drive_directory['drive_directory_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_name"><span class="drive_name drive_listing">' . $one_drive_directory['drive_directory_name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="drive_list_comment drive_listing">' . $one_drive_directory['drive_directory_comment'] . '</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="drive_list_name"><span class="drive_name drive_listing">' ;
                    if ($one_drive_directory['public'] === true) {
                        $html = $html . 'Public' ;
                    } else {
                        $html = $html . 'Private' ;
                    }
            $html = $html .
                '        </span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="upload_drive_directory_' . $one_drive_directory['drive_directory_id'] . '"
                                   class="fa fa-1x fa-upload hvr-grow dom go-drive-object-create btn btn-outline-success"
                                   data-drive-directory-id="' . $one_drive_directory['drive_directory_id'] . '"
                                   data-drive-directory-name="' . $one_drive_directory['drive_directory_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="list_drive_directory_' . $one_drive_directory['drive_directory_id'] . '"
                                   class="fa fa-1x fa-bars hvr-grow dom go-drive-object-list btn btn-outline-primary"
                                   data-drive-directory-id="' . $one_drive_directory['drive_directory_id'] . '"
                                   data-drive-directory-name="' . $one_drive_directory['drive_directory_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_drive_directory_' . $one_drive_directory['drive_directory_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-drive-directory-delete btn btn-outline-danger"
                                   data-drive-directory-id="' . $one_drive_directory['drive_directory_id'] . '"
                                   data-drive-directory-name="' . $one_drive_directory['drive_directory_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Directories</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-drive-directory-create" class="btn btn-success go-drive-directory-create">New Directory</span>
                <span id="go-drive-landing" class="btn btn-info go-drive-landing">Drive Home</span>
                <span id="go-drive-directory-list" class="btn btn-info go-drive-directory-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing Drive Directories</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_directory_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_directory_awscli"></i></h5>
                    <pre id="instruction_set_list_directory_awscli" class="instruction_set instruction_set_list_directory_awscli">
aws s3 ls --profile=abc --region=hq --endpoint-url=http://aws.saharaws.vm:8888
2020-01-23 14:06:53 directory1
2020-01-23 14:06:58 directory2
                    </pre>
              ';


    return $html;

};