<?php

Namespace Model\SC1;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SC1 List of Piranha Web Services';
        $page['heading'] = 'List of SC1';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SC1 Mod', $page, $pageVars);
        return $page;
    }

    public function showSC1Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'SC1Landing.php', $vars);
            $bind = self::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Instance Create 2', $vars);
        };
    }



    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_sc1_landing = $jQuery('.go-sc1-landing');
                $go_sc1_landing->off() ;
                $go_sc1_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'landing', array(), '/sc1');
                });

                $go_sc1_size_list = $jQuery('.go-sc1-size-list');
                $go_sc1_size_list->off() ;
                $go_sc1_size_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'size-list', array(), '/sc1/size/list');
                });


                $go_sc1_image_list = $jQuery('.go-sc1-image-list');
                $go_sc1_image_list->off() ;
                $go_sc1_image_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'image-list', array(), '/sc1/image/list');
                });

                $go_sc1_image_create = $jQuery('.go-sc1-image-create');
                $go_sc1_image_create->off() ;
                $go_sc1_image_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'image-create', array(), '/sc1/image/create');
                });

                $save_image = $jQuery('.attempt-image-create');
                $save_image->off() ;
                $save_image->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'image-save-attempt', array());
                });

                $image_delete = $jQuery('.attempt-image-delete');
                $image_delete->off() ;
                $image_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $image_name = $jqThis->attr('data-sc1-image-name') ;
                    $image_id = $jqThis->attr('data-sc1-image-id') ;
                    \ISOPHP\js_core::$console->log('Clicked image delete button');
                    $msg = "You are about to delete the Instance $image_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $image_id, $image_name) {
                        $navigate->route('SC1', 'image-delete-attempt', array('image_id' => $image_id, 'image_name' => $image_name));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;




                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

            }
        };
    }

    public static function bindAllButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $instances_page_model = new \Model\SC1\InstancePageModel();
                $sizes_page_model = new \Model\SC1\SizesPageModel();
                $images_page_model = new \Model\SC1\ImagePageModel();
                $volume_page_model = new \Model\SC1\VolumePageModel();
                $scheduled_instance_page_model = new \Model\SC1\ScheduledInstancePageModel();
                $capacity_reservation_page_model = new \Model\SC1\CapacityReservationPageModel();
                $launchtemplate_page_model = new \Model\SC1\LaunchTemplatePageModel();
                $staticip_page_model = new \Model\SC1\StaticIPPageModel();
                $keypair_page_model = new \Model\SC1\KeypairPageModel();
                $networkinterface_page_model = new \Model\SC1\NetworkInterfacePageModel();
                $securitygroup_page_model = new \Model\SC1\SecurityGroupPageModel();
                $snapshot_page_model = new \Model\SC1\SnapshotPageModel();
                $backup_page_model = new \Model\SC1\BackupPageModel();
                $launch_configuration_page_model = new \Model\SC1\LaunchConfigurationPageModel();
                $scaledinstance_group_page_model = new \Model\SC1\ScaledInstanceGroupPageModel();
                $load_balancer_page_model = new \Model\SC1\LoadBalancerPageModel();
                $balanced_group_page_model = new \Model\SC1\BalancedGroupPageModel();


                $b1 = self::bindButtons();
                $b2 = $instances_page_model->bindButtons();
                $b3 = $sizes_page_model->bindButtons();
                $b4 = $images_page_model->bindButtons();
                $b5 = $volume_page_model->bindButtons();
                $b6 = $scheduled_instance_page_model->bindButtons();
                $b7 = $capacity_reservation_page_model->bindButtons();
                $b8 = $launchtemplate_page_model->bindButtons();
                $b9 = $staticip_page_model->bindButtons();
                $b10 = $keypair_page_model->bindButtons();
                $b11 = $networkinterface_page_model->bindButtons();
                $b12 = $securitygroup_page_model->bindButtons();
                $b13 = $snapshot_page_model->bindButtons();
                $b14 = $backup_page_model->bindButtons();
                $b15 = $launch_configuration_page_model->bindButtons();
                $b16 = $scaledinstance_group_page_model->bindButtons();
                $b17 = $balanced_group_page_model->bindButtons();
                $b18 = $load_balancer_page_model->bindButtons();

                $b1() ;
                $b2() ;
                $b3() ;
                $b4() ;
                $b5() ;
                $b6() ;
                $b7() ;
                $b8() ;
                $b9() ;
                $b10() ;
                $b11() ;
                $b12() ;
                $b13() ;
                $b14() ;
                $b15() ;
                $b16() ;
                $b17() ;
                $b18() ;

            }
        };
    }


}