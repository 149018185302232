<?php

Namespace Model\LandingPage ;

class PageModel extends \Model\Base {

    public function getPage() {
        $page = array() ;
        $page['title'] = 'A title for a Landing Page' ;
        $page['heading'] = 'A heading for an Landing page' ;
        \ISOPHP\js_core::$console->log('Landing Page Mod', $page) ;
        return $page ;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Binding buttons');
                $jQuery = \ISOPHP\js_core::$jQuery;
                $navigate = new \Model\Navigate();
                $go_login_page = $jQuery('.go-login-page');
                $go_login_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Login', 'show', array(), '/login');
                });
                $go_register_complete = $jQuery('.go-register-hiring') ;
                $go_register_complete->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Register', 'hiring', array(), 'register/hiring');
                }) ;
                $go_register_zero = $jQuery('.go-register-employee') ;
                $go_register_zero->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Register', 'employee', array(), 'register/employee');
                }) ;
                $go_landing_page = $jQuery('.header_logo') ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/') ;
                }) ;

            }
        } ;
    }

}