<?php

Namespace Model\Login;

class PageModel extends \Model\Base
{

    public $returned_data;
    public static $session_watcher;

    public function getPage()
    {
        $page = array();
        $page['title'] = 'Login to your Piranha Web Services account';
        $page['heading'] = 'Piranha Web Services is waiting for you, come over and Login!';
        \ISOPHP\js_core::$console->log('Login Mod', $page);
        return $page;
    }

    public function getLoginResult($pageVars)
    {
        $welcome_message = 'Logging in...' ;
        \Core\WindowMessage::showMessage($welcome_message, 'warning');
        $vars['control'] = 'Login';
        $vars['action'] = 'attempt';
        $vars['api_uri'] = '/api/user/login';
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars['user'] = $jQuery('#email_username')->val();
        $vars['pass'] = $jQuery('#pass')->val();
        $vars['organisation_id'] = $jQuery('#organisation_id')->val();
        $afterwards = $this->applyLoginResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applyLoginResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our login result to');
            \ISOPHP\js_core::$console->log($request_data);
            # the below two lines in one will throw an unimplemented
            if ( is_object($request_data) ) {
                \ISOPHP\js_core::$console->log('alr 1');
                \ISOPHP\js_core::$console->log('rt');
                \ISOPHP\js_core::$console->log($request_data->responseText);
                \ISOPHP\js_core::$console->log('rd');
                \ISOPHP\js_core::$console->log($request_data->responseText);
//                if ( isset($request_data->responseText) ) {
                    \ISOPHP\js_core::$console->log('alr 2');
                    $json_val = \ISOPHP\core::$php->json_encode($request_data->responseText) ;
                    \ISOPHP\js_core::$console->log('alr 2 b');
                    \ISOPHP\js_core::$console->log($json_val);
                    $request_data = \ISOPHP\core::$php->json_decode($json_val, true) ;
                    \ISOPHP\js_core::$console->log('alr 2 c');
                    \ISOPHP\js_core::$console->log($request_data);
//                }
            }

            if (is_array($request_data) ) {

                \ISOPHP\js_core::$console->log('alr 3');

//                if (\ISOPHP\core::$php->is_array($request_data) {
                    \ISOPHP\js_core::$console->log('alr 4');

                    if ($request_data['status'] === 'OK') {
                        \ISOPHP\js_core::$console->log('alr 5');
                        \ISOPHP\js_core::$console->log('saying hello');
                        $welcome_message = "Hello, " . $request_data['user']['full_name'];
                        \Core\WindowMessage::showMessage($welcome_message, 'good');
                        $navigate = new \Model\Navigate();
                        $extra_params = $pageVars['params'];
                        // $extra_params['user_logged'] = $request_data->user;
                        $extra_params['user_logged']['user'] = $request_data['user'];

                        $registry = \ISOPHP\core::$registry;
    //                    \ISOPHP\js_core::$console->log('registry is ', $registry);
    //                    \ISOPHP\js_core::$console->log('logged user to registry is ', $extra_params['user_logged']);
                        $registry::setValue('user_logged', $extra_params['user_logged']);

                        \ISOPHP\js_core::$console->log('Setting Cookie') ;
                        \Model\DefaultModule\PageModel::emptyCookieArray() ;
                        $this->setUserCookie($request_data['user']) ;
                        $cookiedump = \Model\DefaultModule\PageModel::getCookieArray() ;
    //                    \ISOPHP\js_core::$console->log('cookiedump');
    //                    \ISOPHP\js_core::$console->log($cookiedump);
    //                    $cookiedump = \Model\DefaultModule\PageModel::getCookieArray() ;
                        \ISOPHP\js_core::$console->log('Calling Watcher') ;
                        $this->applyLoginSessionTimeWatcher() ;

                        $navigate = new \Model\Navigate();
                        $navigate->route('Services', 'landing', array(), '/services');

                    } else {

                        \ISOPHP\js_core::$console->log('alr 6');
                        $message = $request_data['message'].' '. $request_data['error'];
                        \ISOPHP\js_core::$console->log('saying ' . $message);
                        \Core\WindowMessage::showMessage($message, 'bad');

                    }
//                }
            } else {

                \ISOPHP\js_core::$console->log('alr 7');
                \ISOPHP\js_core::$console->log('API Response incorrect');
                $message = 'Login Failed, Please try again';
                \ISOPHP\js_core::$console->log('saying ' . $message);
                \Core\WindowMessage::showMessage($message, 'bad');

            }
        } ;
        return $func;
    }

    public function setUserCookie($user)
    {
        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        foreach ($user as $key => $value) {
            if (\ISOPHP\core::$php->is_array($value)) {
                $json_val = \ISOPHP\core::$php->json_encode($value) ;
                $json_val = \ISOPHP\core::$php->str_replace("\n", '', $json_val) ;
                $json_val = \ISOPHP\core::$php->str_replace("\r", '', $json_val) ;
                $cookies['user_'.$key] = $json_val ;

                \ISOPHP\js_core::$console->log('valdump');
                \ISOPHP\js_core::$console->log($cookies['user_'.$key]);
                \ISOPHP\js_core::$console->log($json_val );
            } else {
                $cookies['user_'.$key] = $value ;
            }
        }
        \Model\DefaultModule\PageModel::setCookieArray($cookies) ;
    }

    public function applyLoginSessionTimeWatcher()
    {
        self::$session_watcher = function() {
            \ISOPHP\js_core::$console->log('single watch');
            $time_now = \ISOPHP\core::$php->time() ;
            $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;

            $token_count = \ISOPHP\core::$php->count($cookies['user_tokens']) ;
            $found_valid_token = false ;
//            $new_tokens = array() ;
            for ($token_iterator=0; $token_iterator<$token_count; $token_iterator++) {
                $token_time = $cookies['user_tokens'][$token_iterator]['time'] ;
                $allowed_session_time = 60 * 30 ; // 30 Minutes
                $used_session_time = $time_now - $token_time ;
                \ISOPHP\js_core::$console->log('$used_session_time', $used_session_time);
                if ( $used_session_time < $allowed_session_time) {
                    $found_valid_token = true ;
//                    $new_tokens[] = $cookies['user_tokens'][$token_iterator] ;
//                    break ;
                }
            }
//            $cookies['user_tokens'] = $new_tokens ;
//            \Model\DefaultModule\PageModel::setCookieArray($cookies) ;
            if ( $found_valid_token === false ) {
                $logout_page_model = new \Model\Logout\PageModel() ;
                $logout_page_model->performLogout() ;
            }
        } ;
        \ISOPHP\js_core::$console->log('setting watch interval');
        $interval = 1000 * 60 ; // check every minute if user should be logged out
        \ISOPHP\js_core::$window->setInterval(self::$session_watcher, $interval);
    }

    public static function bindButtons()
    {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Binding buttons');
                $jQuery = \ISOPHP\js_core::$jQuery;
                $perform_login_pass_field = $jQuery('#pass');
                $perform_login = $jQuery('#perform-login');
                $perform_login->on('click', function () {
                    $navigate = new \Model\Navigate();

                    /* Notification regarding login disabled. */
                    // $login_disabled_message = "We're currently not open for login! Please register and we'll contact you as soon as we're open";
                    // \ISOPHP\js_core::$console->log('saying ' . $login_disabled_message);
                    // \Core\WindowMessage::showMessage($login_disabled_message, 'bad');

//                    $navigate->route('LandingPage', 'show', array(), '/') ;
                    $navigate->route('Login', 'attempt', array());
                });
                /**
                 * Login action on keyboard enter.
                 */
                $perform_login_pass_field->keypress(function ($e) use ($perform_login) {
                    if ($e->which == 13) {
                        $perform_login->click();
                        return false;
                    }
                });

                $cancel_login = $jQuery('#cancel-login');
                $cancel_login->on('click', function () {
                    $navigate = new \Model\Navigate();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });
                $cancel_login = $jQuery('#go-register');
                $cancel_login->on('click', function () {
                    $navigate = new \Model\Navigate();
                    $navigate->route('Register', 'show', array(), '/register');
                });
            }
        };
    }

}
