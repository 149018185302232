<?php

\Core\View::$template = function () {

    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('SECRETCREATE pagevars: ', $pv);

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-secret-create" class="btn btn-success attempt-secret-create"
                              data-scm-repository-id="' . $pv['params']['repository_id'] . '"
                              data-scm-repository-name="' . $pv['params']['repository_id'] . '" >Create</span>
                        <span id="go-scm-secret-list" class="btn btn-warning go-scm-secret-list">SCM Secret List</span>
                        <span id="go-scm-landing" class="btn btn-warning go-scm-landing">SCM Home</span>
                    </div>
            
                    <div>
                        <h4>New SCM Secret</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <label>Name</label>
                            <input type="text" name="secret_name" class="form-control" id="secret_name" value="" />
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <label>Value</label>
                            <textarea name="secret_value" id="secret_value" class="form-control"></textarea>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <label>Description</label>
                            <textarea name="secret_description" id="secret_description" class="form-control"></textarea>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-secret-create" class="btn btn-success attempt-secret-create"
                                  data-scm-repository-id="' . $pv['params']['repository_id'] . '"
                                  data-scm-repository-name="' . $pv['params']['repository_id'] . '" >Create</span>
                            <span id="go-scm-secret-list" class="btn btn-warning go-scm-secret-list">SCM Secret List</span>
                            <span id="go-scm-landing" class="btn btn-warning go-scm-landing">SCM Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    return $html;

};
