<?php

Namespace Controller;

class AnalyticsController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Analytics controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Analytics, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Analytics\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Analytics.php';
            $res->type = 'view';
            $res->view_control = 'Analytics';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showAnalyticsLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'application-list') {
                $res->post_template[] = $page_model->showAnalyticsApplicationList($pageVars);
            } else if ($pageVars['route']['action'] == 'application-access-list') {
                $res->post_template[] = $page_model->showAnalyticsApplicationAccessList($pageVars);
            } else if ($pageVars['route']['action'] == 'application-create') {
                $res->post_template[] = $page_model->showAnalyticsApplicationCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'application-save-attempt') {
                $page_model->saveApplicationAttemptToAnalytics($pageVars);
                $res->view = 'Analytics.php';
                $res->control = 'Analytics';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'application-delete-attempt') {
                $page_model->deleteApplicationAttemptToAnalytics($pageVars);
                $res->view = 'Analytics.php';
                $res->control = 'Analytics';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showAnalyticsLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Analytics Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}