<?php

Namespace Model\SC1;

use ISOPHP\core;

class LaunchConfigurationPageModel extends \Model\Base {


    public function showSC1LaunchConfigurationCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'LaunchConfigurationCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 LaunchConfiguration Create 2', $vars);
        };
    }

    public function showSC1LaunchConfigurationList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/launchconfiguration/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1LaunchConfigurationListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1LaunchConfigurationListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LaunchConfiguration List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LaunchConfiguration List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LaunchConfigurationList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LaunchConfiguration List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LaunchConfiguration List failed');
            }
        };
        return $func;
    }

    /**
     * Save launchconfiguration
     */
    public function saveLaunchConfigurationAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save launchconfiguration to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'launchconfiguration-create';
        $vars['api_uri'] = '/api/sc1/launchconfiguration/create';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['launchconfiguration_name'] = $jQuery('#launchconfiguration_name')->val();
        $vars['launchconfiguration_type'] = $jQuery('#launchconfiguration_type')->val();
        \ISOPHP\js_core::$console->log('save launchconfiguration vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveLaunchConfigurationResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveLaunchConfigurationResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 launchconfiguration result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created LaunchConfiguration '.$request_data['launchconfiguration']['sc1_launchconfiguration_name'].' for Instance '.$request_data['launchconfiguration']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 launchconfiguration saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating LaunchConfiguration '.$request_data['launchconfiguration']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 launchconfiguration save failed');
            }
        };
        return $func;
    }
    /**
     * Delete launchconfiguration
     */
    public function deleteLaunchConfigurationAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete launchconfiguration to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'launchconfiguration-delete';
        $vars['api_uri'] = '/api/sc1/launchconfiguration/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete launchconfiguration vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteLaunchConfigurationResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteLaunchConfigurationResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 launchconfiguration result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Balanced Group '.$request_data['launchconfiguration']['sc1_launchconfiguration_name'].' for Instance '.$request_data['launchconfiguration']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 launchconfiguration deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Balanced Group '.$request_data['launchconfiguration']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 launchconfiguration delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding network interface buttons');

                $go_sc1_launchconfiguration_list = $jQuery('.go-sc1-launchconfiguration-list');
                $go_sc1_launchconfiguration_list->off() ;
                $go_sc1_launchconfiguration_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'launchconfiguration-list', array(), '/sc1/launchconfiguration/list');
                });

                $go_sc1_launchconfiguration_list_page = $jQuery('.sc1-launchconfiguration-paginator');
                $go_sc1_launchconfiguration_list_page->off() ;
                $go_sc1_launchconfiguration_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 launchconfiguration paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'launchconfiguration-list', array('page' => $new_page), '/sc1/launchconfiguration/list/page/'.$new_page);
                });

                $go_sc1_launchconfiguration_create = $jQuery('.go-sc1-launchconfiguration-create');
                $go_sc1_launchconfiguration_create->off() ;
                $go_sc1_launchconfiguration_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'launchconfiguration-create', array(), '/sc1/launchconfiguration/create');
                });

                $save_launchconfiguration = $jQuery('.attempt-launchconfiguration-create');
                $save_launchconfiguration->off() ;
                $save_launchconfiguration->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'launchconfiguration-save-attempt', array());
                });

                $launchconfiguration_delete = $jQuery('.attempt-launchconfiguration-delete');
                $launchconfiguration_delete->off() ;
                $launchconfiguration_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $launchconfiguration_name = $jqThis->attr('data-sc1-launchconfiguration-name') ;
//                    $launchconfiguration_id = $jqThis->attr('data-sc1-launchconfiguration-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked launchconfiguration delete button'. $launchconfiguration_name. $launchconfiguration_id);
//                    if ($launchconfiguration_name !== '') {
//                        $name_or_id = $launchconfiguration_name ;
//                    } else {
//                        $name_or_id = $launchconfiguration_id ;
//                    }
//                    $msg = "You are about to delete this Launch Template $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $launchconfiguration_id, $launchconfiguration_name) {
//                        $navigate->route('SC1', 'launchconfiguration-delete-attempt', array(
//                            'id' => $launchconfiguration_id,
//                            'name' => $launchconfiguration_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}