<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $records_are_array = \ISOPHP\core::$php->is_array($rqd['records']) ;
    if ($records_are_array === true) {
        $records_exist = true ;
    } else {
        $records_exist = false ;
    }
    if ($records_exist === true) {
        $record_count = \ISOPHP\core::$php->count($rqd['records']) ;
    } else {
        $record_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-smp-record-create" class="btn btn-success go-smp-record-create">Create New Record</span>
            <span id="go-smp-landing" class="btn btn-warning go-smp-landing">SMP Home</span>
            <span id="go-smp-record-list" class="btn btn-info go-smp-record-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Record List</h4>
         </div> ' ;

    if ($records_exist === true && $record_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_smp_record">
                        <div class="col-sm-1 col-1">
                            <h5 class="smp_record_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="smp_record_table_title">Instance</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="smp_record_table_title">Target Port</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="smp_record_table_title">Name</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="smp_record_table_title">Endpoints</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="smp_record_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['records'] as $one_smp_record) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-1 col-1">
                        <p class="smp_list_id"><span class="smp_id smp_listing">' . $one_smp_record['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="smp_list_instance"><span class="smp_instance smp_listing">' ;

            if (isset($one_smp_record['instance_id'])) {
                $html = $html . 'ID: '. $one_smp_record['instance_id'] . '<br />' ;
            }

            if (isset($one_smp_record['instance_name'])) {
                $html = $html . 'Name: '. $one_smp_record['instance_name'] . '<br />' ;
            }

            if (isset($one_smp_record['vpc_id'])) {
                $html = $html . 'VPC: '. $one_smp_record['vpc_name'] . ' / ' ;
                $html = $html . $one_smp_record['vpc_id'] . '<br />' ;
            }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="smp_list_ports">
                            <span class="smp_ports smp_listing">' ;

                            $html = $html . $one_smp_record['target_proxy_port'] . '<br />' ;

                    $html = $html .
                            '</span>
                        </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="smp_list_record_name"><span class="smp_record_name smp_listing">' ;

            if ( isset($one_smp_record['friendly_name']) &&
                 (\ISOPHP\core::$php->strlen($one_smp_record['friendly_name']) > 0) ) {
                $record_name = $one_smp_record['friendly_name'] . ' / ' ;
                $record_name = $record_name . $one_smp_record['slug'] ;
            } else {
                $record_name = $one_smp_record['slug'] ;
            }

            $html = $html . $record_name ;

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-4 col-4 smp_list_record_endpoints"> ' ;

            $html = $html . '<div class="col-sm-12 col-12 smp-endpoint-list">' ;
            $html = $html . '  <a href="'. $one_smp_record['slug'].'_'. $one_smp_record['endpoint'].'" target="_blank">' ;
            $html = $html . $one_smp_record['endpoint'] ;
            $html = $html . '  </a>' ;
            $html = $html . '</div>' ;

        $html = $html .  '
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <i id="delete_smp_record_' . $one_smp_record['id'] . '"
                               class="fa fa-1x fa-close hvr-grow dom smp-attempt-record-delete btn btn-outline-danger"
                               data-smp-record-id="' . $one_smp_record['id'] . '"
                               data-smp-record-name="' . $record_name . '"></i>
                        </div>
                    </div>
                 </div>';

        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Records</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-smp-record-create" class="btn btn-success go-smp-record-create">Create New Record</span>
            <span id="go-smp-landing" class="btn btn-warning go-smp-landing">SMP Home</span>
            <span id="go-smp-record-list" class="btn btn-info go-smp-record-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};