<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Piranha Web Services</h1>
                <p>Choose your next Service here.</p>
            </div> ';

    $html = $html . '     
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_compute col-sm-12">
                        <h4>Compute</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sc1">
                        <strong>SC1:</strong> Virtual Machines in the Cloud
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_closure">
                        <strong>Closure:</strong> Serverless Cloud Compute for Code Execution
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sc1">
                        <del>EC2</del>, Lightsail, <del>Lambda</del>, Batch, <br />
                        Elastic Beanstalk, Serverless Application Repository, <br />
                        AWS Outposts, EC2 Image Builder
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_storage col-sm-12">
                        <h4>Storage</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ss3">
                        <strong>SS3:</strong> Piranha&apos;s File Storage System, S3 compatible
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ss3">
                        <del>S3</del>, EFS, FSx, S3 Glacier, <br />
                        Storage Gateway, AWS Backup
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_containers col-sm-12">
                        <h4>Containers</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_pcr">
                        <strong>PCR:</strong> Piranha Container Registry
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_pcs">
                        <strong>PCS:</strong> Piranha Container Service
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_pks">
                        <strong>PKS:</strong> Piranha Kubernetes Service
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ss3">
                        <del>Elastic Container Registry</del>, <br />
                        <del>Elastic Container Service</del>, <br />
                        <del>Elastic Kubernetes Service</del>
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_database col-sm-12">
                        <h4>Database</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ssqs">
                        <strong>SSQS:</strong> Sahara Simple Queues - Deploy Queues in Minutes
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_srds">
                        <strong>SRDS:</strong> Relational Database Services
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_srds">
                        <del>RDS</del>, DynamoDB, ElastiCache, Neptune <br />
                        Amazon QLDB, Amazon DocumentDB,  <br />
                        Amazon Keyspaces, Amazon Timestream
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_organization col-sm-12">
                        <h4>Organization</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        <strong>SAM:</strong> Sahara Account Management
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Billing:</strong> Managing Invoices
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_costing">
                        <strong>Cost Prediction:</strong> Predict the cost of resources you&apos;re using
                    </span> 
                    <span class="btn col-sm-12 service_page_entry service_menu_service_costing">
                        AWS Cost Management, AWS Cost Explorer, <br />
                        AWS Budgets, AWS Marketplace Subscriptions
                    </span> 
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group_analytics col-sm-12">
                        <h4>Analytics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_billing">
                        <strong>Analytics:</strong> Web Page and Mobile Application Analytics
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        Athena, Amazon Redshift, EMR, CloudSearch,  <br />
                        Elasticsearch Service, Kinesis, QuickSight
                    </span> 
                </div>
                
            </div> ';

    $html = $html . '    
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_networking col-sm-12">
                        <h4>Networking</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_svpc">
                        <strong>SVPC:</strong> Virtual Private Cloud Networking
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_dns">
                        <strong>SDNS:</strong> DNS Services in the Cloud
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pmp">
                        <strong>PMP:</strong> Piranha Mini Proxy - VM Access with DNS instead of IP Addresses
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_smp">
                        <del>VPC</del>, CloudFront, <del>Route 53</del>, API Gateway,  <br />
                        Direct Connect, AWS App Mesh, AWS Cloud Map, <br />
                        Global Accelerator
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_developer_tools col-sm-12">
                        <h4>Developer Tools</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_devtool_build">
                        <strong>Build:</strong> Build Servers for your Automation
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_devtool_git">
                        <strong>Git:</strong> Git SCM Servers
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_devtool_pipelines">
                        <strong>Pipelines:</strong> Automatic Pipelines for yur Code Repositories
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_devtool_artifact">
                        <strong>Artifacts:</strong> Artifact Repositories for your Packages
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_lego">
                        CodeStar, <del>CodeCommit</del>, <del>CodeArtifact</del>, <del>CodeBuild</del>, <br />
                        CodeDeploy, CodePipeline, Cloud9, CloudShell, <br />
                        X-Ray
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_robotics col-sm-12">
                        <h4>Robotics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_lego">
                        <strong>Lego Mindstorms:</strong> Run your Mindstorms with Plug N Play Programs on our Platform
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_arduino_design">
                        <strong>Arduino Design:</strong> Design for Arduino Boards and Components
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_arduino_execute">
                        <strong>Arduino Execute:</strong> Execution for your Arduino Programs on Real Arduinos
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_arduino_execute">
                        AWS RoboMaker
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_blockchain col-sm-12">
                        <h4>Blockchain</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_blockchain">
                        <strong>Managed Blockchain:</strong> Blockchain Nodes for and major Blockchain
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_front_end_mobile col-sm-12">
                        <h4>Mobile</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_mobile_devices">                        
                        <strong>Mobile Devices:</strong> Real Mobile Devices for Application Testing
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_mobile_emulators">                        
                        <strong>Mobile Emulation:</strong> Emulated Mobile Devices for Application Testing
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_smp">        
                        AWS Amplify, <del>Mobile Hub</del>, AWS AppSync,  <br />
                        <del>Device Farm</del>, Amazon Location Service
                    </span>
                </div>

                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_end_user_computing col-sm-12">
                        <h4>End User Computing</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_euc_desktops">
                        <strong>Desktops:</strong> Web Page and Mobile Application Analytics
                    </span>
                    <span class="btn col-sm-12 service_page_entry unimplemented_service service_menu_service_euc_documents">
                        <strong>Documents:</strong> Spreadsheets, Text Documents, Desktop Publishing and More
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        <del>WorkSpaces</del>, AppStream 2.0,
                        <del>WorkDocs</del>, WorkLink
                    </span> 
                </div>
            
            </div>
                    
        </div>
        
        ';

    $html = $html . ' 
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h2>Pending Services Below</h2>
            </div>
        
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_machine_learning col-sm-12">
                        <h4>Machine Learning</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_lego">
                        Amazon SageMaker, Amazon Augmented AI, Amazon CodeGuru, <br />
                        Amazon Comprehend, Amazon Forecast, Amazon Fraud Detector, Amazon Kendra, <br />
                        Amazon Lex, Amazon Personalize, Amazon Polly, Amazon Rekognition, <br />
                        Amazon Textract, Amazon Transcribe, Amazon Translate, AWS DeepComposer, <br />
                        AWS DeepLens, AWS DeepRacer, AWS Panorama, Amazon Monitron, Amazon HealthLake, <br />
                        Amazon Lookout for Vision, Amazon Lookout for Equipment, <br />
                        Amazon Lookout for Metrics <br />
                    </span>
                </div>
                
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_application_integration col-sm-12">
                        <h4>Application Integration</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_sam">
                        Step Functions, Amazon AppFlow, Amazon EventBridge, <br />
                        Amazon MQ, Simple Notification Service,  <br />
                        Simple Queue Service, SWF, Managed Apache Airflow
                    </span> 
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_sec_id_compliance col-sm-12">
                        <h4>Security, Identity, Compliance</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_sam">
                        IAM, Resource Access Manager, Cognito, Secrets Manager, <br />
                        GuardDuty, Inspector, Amazon Macie, AWS Single Sign-On, <br />
                        Certificate Manager, Key Management Service, CloudHSM,  <br />
                        Directory Service, WAF & Shield, AWS Firewall Manager,  <br />
                        Artifact, Security Hub, Detective, AWS Audit Manager,  <br />
                        AWS Signer
                    </span> 
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_man_gov col-sm-12">
                        <h4>Management &amp; Governance</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_sam">         
                        AWS Organizations, CloudWatch, AWS Auto Scaling,  <br />
                        CloudFormation, CloudTrail, Config, OpsWorks,  <br />
                        Service Catalog, Systems Manager, AWS AppConfig, <br />
                        Trusted Advisor, Control Tower, AWS License Manager, <br />
                        AWS Well-Architected Tool, Personal Health Dashboard, <br />
                        AWS Chatbot, Launch Wizard, AWS Compute Optimizer, <br />
                        Resource Groups &amp; Tag Editor, Amazon Grafana,  <br />
                        Amazon Prometheus, AWS Proton
                    </span> 
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_business_applications col-sm-12">
                        <h4>Business Applications</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_sam">
                        Alexa for Business, Amazon Chime <br />
                        WorkMail, Amazon Honeycode
                    </span> 
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_game_development col-sm-12">
                        <h4>Game Development</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_sam">
                        Amazon GameLift
                    </span> 
                </div>
                
            </div>
      
        
        ';

    $html = $html . '   
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_media_services col-sm-12">
                        <h4>Media Services</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">
                        Kinesis Video Streams, MediaConnect, MediaConvert, <br />
                        MediaLive, MediaPackage, MediaStore, MediaTailor, <br />
                        Elemental Appliances & Software, Amazon Interactive Video Service, <br />
                        Elastic Transcoder
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_migration_transfer col-sm-12">
                        <h4>Migration &amp; Transfer</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">        
                        AWS Migration Hub, Application Discovery Service,  <br />
                        Database Migration Service, Server Migration Service,  <br />
                        AWS Transfer Family, AWS Snow Family, DataSync
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_migration_transfer col-sm-12">
                        <h4>Internet of Things</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">        
                        IoT Core, FreeRTOS, IoT 1-Click, IoT Analytics, IoT Device Defender, <br />
                        IoT Device Management, IoT Events, IoT Greengrass,  <br />
                        IoT SiteWise, IoT Things Graph
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_data_pipeline col-sm-12">
                        <h4>Data Pipeline</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">        
                        AWS Data Exchange, AWS Glue, AWS Lake Formation,  <br />
                        MSK, AWS Glue, DataBrew
                    </span>
                </div>
         
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_customer_enablement col-sm-12">
                        <h4>Customer Enablement</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">             
                        AWS IQ, Support, Managed Services, Activate for Startups
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_quantum_technologies col-sm-12">
                        <h4>Quantum Technologies</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp"> 
                        Amazon Braket
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_satellite col-sm-12">
                        <h4>Satellite</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">
                        Ground Station
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_customer_engagement col-sm-12">
                        <h4>Customer Engagement</h4>
                    </span>
                    <span class="btn service_page_entry service_menu_service_smp">
                        Amazon Connect, Pinpoint, Simple Email Service
                    </span>
                </div>
            
            </div>
                    
        </div>
        
        ';

    return $html ;

} ;