<?php

Namespace Controller;

class ClosureController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Closure controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Closure, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Closure\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('Closure List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Closure.php';
            $res->type = 'view';
            $res->view_control = 'Closure';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showClosureLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-list') {
                $res->post_template[] = $page_model->showClosureRepositoryList($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-create') {
                $res->post_template[] = $page_model->showClosureRepositoryCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'function-list') {
                $res->post_template[] = $page_model->showClosureFunctionList($pageVars);
            } else if ($pageVars['route']['action'] == 'function-execution-list') {
                $res->post_template[] = $page_model->showClosureFunctionExecutionList($pageVars);
            } else if ($pageVars['route']['action'] == 'function-create') {
                $res->post_template[] = $page_model->showClosureFunctionCreate($pageVars);
                $res->post_template[] = $page_model->showClosureRepositoryListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'function-edit') {
                $res->post_template[] = $page_model->showClosureFunctionEdit($pageVars);
                $res->post_template[] = $page_model->showClosureRepositoryListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-save-attempt') {
                $page_model->saveRepositoryAttemptToClosure($pageVars);
                $res->view = 'Closure.php';
                $res->control = 'Closure';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'function-save-attempt') {
                $page_model->saveFunctionAttemptToClosure($pageVars);
                $res->view = 'Closure.php';
                $res->control = 'Closure';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'function-execute-attempt') {
                $page_model->executeFunctionAttemptToClosure($pageVars);
                $res->view = 'Closure.php';
                $res->control = 'Closure';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'repository-delete-attempt') {
                $page_model->deleteRepositoryAttemptToClosure($pageVars);
                $res->view = 'Closure.php';
                $res->control = 'Closure';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'function-delete-attempt') {
                $page_model->deleteFunctionAttemptToClosure($pageVars);
                $res->view = 'Closure.php';
                $res->control = 'Closure';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showClosureLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Closure Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}