<?php

Namespace Controller;

class BuildController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Build controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Build, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Build\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Build.php';
            $res->type = 'view';
            $res->view_control = 'Build';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showBuildLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'job-list') {
                $res->post_template[] = $page_model->showBuildJobList($pageVars);
            } else if ($pageVars['route']['action'] == 'job-execution-list') {
                $res->post_template[] = $page_model->showBuildJobExecutionList($pageVars);
            } else if ($pageVars['route']['action'] == 'job-create') {
                $res->post_template[] = $page_model->showBuildJobCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'job-edit') {
                $res->post_template[] = $page_model->showBuildJobEdit($pageVars);
//            } else if ($pageVars['route']['action'] == 'pipeline-list') {
//                $res->post_template[] = $page_model->showBuildPipelineList($pageVars);
            } else if ($pageVars['route']['action'] == 'pipeline-list') {
                $res->post_template[] = $page_model->showBuildPipelineExecutionList($pageVars);
            } else if ($pageVars['route']['action'] == 'pipeline-execution-list') {
                $res->post_template[] = $page_model->showBuildPipelineExecutionList($pageVars);
            } else if ($pageVars['route']['action'] == 'pipeline-create') {
                $res->post_template[] = $page_model->showBuildPipelineCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'pipeline-edit') {
                $res->post_template[] = $page_model->showBuildPipelineEdit($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
                $res->post_template[] = $page_model->showBuildJobListDropDown($pageVars);
                $res->post_template[] = $page_model->bindJobListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showBuildObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'object-create') {
                $res->post_template[] = $page_model->showBuildObjectCreate($pageVars);
                $res->post_template[] = $page_model->showBuildJobListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindJobListDropDownButtonsForObjectCreate();
            } else if ($pageVars['route']['action'] == 'job-save-attempt') {
                $page_model->saveJobAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'job-edit-attempt') {
                $page_model->saveJobAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'job-execute-attempt') {
                $page_model->executeJobAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'job-delete-attempt') {
                $page_model->deleteJobAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'pipeline-rebuild-attempt') {
                $page_model->rebuildPipelineAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'pipeline-cancel-attempt') {
                $page_model->cancelPipelineAttemptToBuild($pageVars);
                $res->view = 'Build.php';
                $res->control = 'Build';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showBuildLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Build Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}
