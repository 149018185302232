<?php

Namespace Controller;

class SC1Controller extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SC1 controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated() ;
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SC1, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SC1\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('SC1 List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SC1.php';
            $res->type = 'view';
            $res->view_control = 'SC1';
            $res->post_template = array();

            $instances_page_model = new \Model\SC1\InstancePageModel();
            $sizes_page_model = new \Model\SC1\SizesPageModel();
            $images_page_model = new \Model\SC1\ImagePageModel();
            $volume_page_model = new \Model\SC1\VolumePageModel();
            $scheduled_instance_page_model = new \Model\SC1\ScheduledInstancePageModel();
            $capacity_reservation_page_model = new \Model\SC1\CapacityReservationPageModel();
            $launchtemplate_page_model = new \Model\SC1\LaunchTemplatePageModel();
            $staticip_page_model = new \Model\SC1\StaticIPPageModel();
            $keypair_page_model = new \Model\SC1\KeypairPageModel();
            $networkinterface_page_model = new \Model\SC1\NetworkInterfacePageModel();
            $securitygroup_page_model = new \Model\SC1\SecurityGroupPageModel();
            $snapshot_page_model = new \Model\SC1\SnapshotPageModel();
            $backup_page_model = new \Model\SC1\BackupPageModel();
            $launch_configuration_page_model = new \Model\SC1\LaunchConfigurationPageModel();
            $scaledinstance_group_page_model = new \Model\SC1\ScaledInstanceGroupPageModel();
            $load_balancer_page_model = new \Model\SC1\LoadBalancerPageModel();
            $balanced_group_page_model = new \Model\SC1\BalancedGroupPageModel();
            $svpc_page_model = new \Model\SVPC\PageModel();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSC1Landing($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-list') {
                $res->post_template[] = $instances_page_model->showSC1InstanceList($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-executionlog-list') {
                $res->post_template[] = $instances_page_model->showSC1InstanceExecutionLogList($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-create') {
                $res->post_template[] = $instances_page_model->showSC1InstanceCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceImageDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showSC1KeypairDropDown($pageVars);
                $res->post_template[] = $svpc_page_model->showSVPCVPCListMultiSelect($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-save-attempt') {
                $instances_page_model->saveInstanceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'instance-delete-attempt') {
                $instances_page_model->deleteInstanceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'size-list') {
                $res->post_template[] = $sizes_page_model->showSC1SizeList($pageVars);
            } else if ($pageVars['route']['action'] == 'image-list') {
                $res->post_template[] = $images_page_model->showSC1ImageList($pageVars);
//        } else if ($pageVars['route']['action'] == 'image-create') {
//            $res->post_template[] = $images_page_model->showSC1ImageCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'volume-list') {
                $res->post_template[] = $volume_page_model->showSC1VolumeList($pageVars);
            } else if ($pageVars['route']['action'] == 'volume-create') {
                $res->post_template[] = $volume_page_model->showSC1VolumeCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'volume-save-attempt') {
                $volume_page_model->saveVolumeAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'volume-delete-attempt') {
                $volume_page_model->deleteVolumeAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'scheduledinstance-list') {
                $res->post_template[] = $scheduled_instance_page_model->showSC1ScheduledInstanceList($pageVars);
            } else if ($pageVars['route']['action'] == 'scheduledinstance-executionlog-list') {
                $res->post_template[] = $scheduled_instance_page_model->showSC1ScheduledInstanceExecutionLogList($pageVars);
            } else if ($pageVars['route']['action'] == 'scheduledinstance-create') {
                $scheduled_instance_page_model = new \Model\SC1\ScheduledInstancePageModel();
                $res->post_template[] = $scheduled_instance_page_model->showSC1ScheduledInstanceCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $scheduled_instance_page_model->addSC1ScheduledInstanceCreateButtonChanges($pageVars);
            } else if ($pageVars['route']['action'] == 'scheduledinstance-save-attempt') {
                $scheduled_instance_page_model->saveScheduledInstanceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'scheduledinstance-delete-attempt') {
                $scheduled_instance_page_model->deleteScheduledInstanceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'capacityreservation-list') {
                $res->post_template[] = $capacity_reservation_page_model->showSC1CapacityReservationList($pageVars);
            } else if ($pageVars['route']['action'] == 'capacityreservation-create') {
                $res->post_template[] = $capacity_reservation_page_model->showSC1CapacityReservationCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'capacityreservation-save-attempt') {
                $capacity_reservation_page_model->saveCapacityReservationAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'capacityreservation-delete-attempt') {
                $capacity_reservation_page_model->deleteCapacityReservationAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'launchtemplate-list') {
                $res->post_template[] = $launchtemplate_page_model->showSC1LaunchTemplateList($pageVars);
            } else if ($pageVars['route']['action'] == 'launchtemplate-edit') {
                $res->post_template[] = $launchtemplate_page_model->showSC1LaunchTemplateEdit($pageVars);
            } else if ($pageVars['route']['action'] == 'launchtemplate-create') {
                $res->post_template[] = $launchtemplate_page_model->showSC1LaunchTemplateCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceImageDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showSC1KeypairDropDown($pageVars);
                $res->post_template[] = $svpc_page_model->showSVPCVPCListMultiSelect($pageVars);
            } else if ($pageVars['route']['action'] == 'launchtemplate-save-attempt') {
                $launchtemplate_page_model->saveLaunchTemplateAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'launchtemplate-update-attempt') {
                $launchtemplate_page_model->updateLaunchTemplateAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'launchtemplate-delete-attempt') {
                $launchtemplate_page_model->deleteLaunchTemplateAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'staticip-list') {
                $res->post_template[] = $staticip_page_model->showSC1StaticIPList($pageVars);
            } else if ($pageVars['route']['action'] == 'staticip-create') {
                $res->post_template[] = $staticip_page_model->showSC1StaticIPCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'staticip-save-attempt') {
                $staticip_page_model->saveStaticIPAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'staticip-delete-attempt') {
                $staticip_page_model->deleteStaticIPAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'keypair-list') {
                $res->post_template[] = $keypair_page_model->showSC1KeypairList($pageVars);
            } else if ($pageVars['route']['action'] == 'keypair-create') {
                $res->post_template[] = $keypair_page_model->showSC1KeypairCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'keypair-save-attempt') {
                $keypair_page_model->saveKeypairAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'keypair-delete-attempt') {
                $keypair_page_model->deleteKeypairAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkinterface-list') {
                $res->post_template[] = $networkinterface_page_model->showSC1NetworkInterfaceList($pageVars);
            } else if ($pageVars['route']['action'] == 'networkinterface-create') {
                $res->post_template[] = $networkinterface_page_model->showSC1NetworkInterfaceCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'networkinterface-save-attempt') {
                $networkinterface_page_model->saveNetworkInterfaceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkinterface-delete-attempt') {
                $networkinterface_page_model->deleteNetworkInterfaceAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-list') {
                $res->post_template[] = $snapshot_page_model->showSC1SnapshotList($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-create') {
                $res->post_template[] = $snapshot_page_model->showSC1SnapshotCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-save-attempt') {
                $snapshot_page_model->saveSnapshotAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-restore-attempt') {
                $snapshot_page_model->restoreSnapshotAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-delete-attempt') {
                $snapshot_page_model->deleteSnapshotAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-list') {
                $res->post_template[] = $backup_page_model->showSC1BackupList($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-create') {
                $res->post_template[] = $backup_page_model->showSC1BackupCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-save-attempt') {
                $backup_page_model->saveBackupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-delete-attempt') {
                $backup_page_model->deleteBackupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'securitygroup-list') {
                $res->post_template[] = $securitygroup_page_model->showSC1SecurityGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'securitygroup-create') {
                $res->post_template[] = $securitygroup_page_model->showSC1SecurityGroupCreate($pageVars);
                $res->post_template[] = $securitygroup_page_model->addSC1SecurityGroupSelectChanges($pageVars);
                $vpc_page_model = new \Model\SVPC\PageModel() ;
                $res->post_template[] = $vpc_page_model->showSVPCVPCListDropDown($pageVars) ;
            } else if ($pageVars['route']['action'] == 'securitygroup-save-attempt') {
                $securitygroup_page_model->saveSecurityGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'securitygroup-delete-attempt') {
                $securitygroup_page_model->deleteSecurityGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            }  else if ($pageVars['route']['action'] == 'securitygroup-rule-inbound-create') {
                $securitygroup_page_model->addSC1SecurityGroupInboundRule($pageVars) ;
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            }  else if ($pageVars['route']['action'] == 'securitygroup-rule-inbound-delete') {
                $securitygroup_page_model->deleteSC1SecurityGroupInboundRule($pageVars) ;
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            }  else if ($pageVars['route']['action'] == 'securitygroup-rule-outbound-create') {
                $securitygroup_page_model->addSC1SecurityGroupOutboundRule($pageVars) ;
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            }  else if ($pageVars['route']['action'] == 'securitygroup-rule-outbound-delete') {
                $securitygroup_page_model->deleteSC1SecurityGroupOutboundRule($pageVars) ;
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'balancedgroup-list') {
                $res->post_template[] = $balanced_group_page_model->showSC1BalancedGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'balancedgroup-executionlog-list') {
                $res->post_template[] = $balanced_group_page_model->showSC1BalancedGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'balancedgroup-create') {
                $res->post_template[] = $balanced_group_page_model->showSC1BalancedGroupCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $scheduled_instance_page_model->addSC1ScheduledInstanceCreateButtonChanges($pageVars);
            } else if ($pageVars['route']['action'] == 'balancedgroup-save-attempt') {
                $balanced_group_page_model->saveBalancedGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'balancedgroup-delete-attempt') {
                $balanced_group_page_model->deleteBalancedGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'launchconfiguration-list') {
                $res->post_template[] = $launch_configuration_page_model->showSC1LaunchConfigurationList($pageVars);
            } else if ($pageVars['route']['action'] == 'launchconfiguration-create') {
                $res->post_template[] = $launch_configuration_page_model->showSC1LaunchConfigurationCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $scheduled_instance_page_model->addSC1ScheduledInstanceCreateButtonChanges($pageVars);
            } else if ($pageVars['route']['action'] == 'launchconfiguration-save-attempt') {
                $launch_configuration_page_model->saveLaunchConfigurationAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'launchconfiguration-delete-attempt') {
                $launch_configuration_page_model->deleteLaunchConfigurationAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'loadbalancer-list') {
                $res->post_template[] = $load_balancer_page_model->showSC1LoadBalancerList($pageVars);
            } else if ($pageVars['route']['action'] == 'loadbalancer-executionlog-list') {
                $res->post_template[] = $load_balancer_page_model->showSC1LoadBalancerExecutionLogList($pageVars);
            } else if ($pageVars['route']['action'] == 'loadbalancer-create') {
                $res->post_template[] = $load_balancer_page_model->showSC1LoadBalancerCreate($pageVars);
                $res->post_template[] = $load_balancer_page_model->showSC1LoadBalancerSizeDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceListDropDown($pageVars);
                $res->post_template[] = $svpc_page_model->showSVPCVPCListMultiSelect($pageVars);
            } else if ($pageVars['route']['action'] == 'loadbalancer-save-attempt') {
                $load_balancer_page_model->saveLoadBalancerAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'loadbalancer-delete-attempt') {
                $load_balancer_page_model->deleteLoadBalancerAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'scaledinstancegroup-list') {
                $res->post_template[] = $scaledinstance_group_page_model->showSC1ScaledInstanceGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'scaledinstancegroup-executionlog-list') {
                $res->post_template[] = $scaledinstance_group_page_model->showSC1ScaledInstanceGroupExecutionLogList($pageVars);
            } else if ($pageVars['route']['action'] == 'scaledinstancegroup-create') {
                $res->post_template[] = $scaledinstance_group_page_model->showSC1ScaledInstanceGroupCreate($pageVars);
                $res->post_template[] = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $res->post_template[] = $scheduled_instance_page_model->addSC1ScheduledInstanceCreateButtonChanges($pageVars);
            } else if ($pageVars['route']['action'] == 'scaledinstancegroup-save-attempt') {
                $scaledinstance_group_page_model->saveScaledInstanceGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'scaledinstancegroup-delete-attempt') {
                $scaledinstance_group_page_model->deleteScaledInstanceGroupAttemptToSC1($pageVars) ;
                $res->view = 'SC1.php';
                $res->control = 'SC1';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSC1Landing($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $instances_page_model->bindButtons();
            $res->post_template[] = $sizes_page_model->bindButtons();
            $res->post_template[] = $images_page_model->bindButtons();
            $res->post_template[] = $volume_page_model->bindButtons();
            $res->post_template[] = $scheduled_instance_page_model->bindButtons();
            $res->post_template[] = $capacity_reservation_page_model->bindButtons();
            $res->post_template[] = $launchtemplate_page_model->bindButtons();
            $res->post_template[] = $staticip_page_model->bindButtons();
            $res->post_template[] = $keypair_page_model->bindButtons();
            $res->post_template[] = $networkinterface_page_model->bindButtons();
            $res->post_template[] = $securitygroup_page_model->bindButtons();
            $res->post_template[] = $snapshot_page_model->bindButtons();
            $res->post_template[] = $backup_page_model->bindButtons();
            $res->post_template[] = $launch_configuration_page_model->bindButtons();
            $res->post_template[] = $scaledinstance_group_page_model->bindButtons();
            $res->post_template[] = $balanced_group_page_model->bindButtons();
            $res->post_template[] = $load_balancer_page_model->bindButtons();

            \ISOPHP\js_core::$console->log('SC1 Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;

        }

    }

}