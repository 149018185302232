<?php

Namespace Model\ComingSoon ;

class PageModel extends \Model\Base {

    public function getPage() {
        $page = array() ;
        $page['title'] = 'A title for a Coming Soon Page' ;
        $page['heading'] = 'A heading for an Coming Soon page' ;
        \ISOPHP\js_core::$console->log('Coming Soon Mod', $page) ;
        return $page ;
    }

    public function setEmailSubscriptionResult() {
        $vars = array() ;
        $vars['control'] = 'EmailSubscription';
        $vars['action'] = 'attempt';
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars['email'] = $jQuery('#email')->val();
        \ISOPHP\js_core::$console->log('set email subscription');
        $afterwards = $this->applyEmailSubscriptionResult();
        \ISOPHP\js_core::$console->log('after set afterwards');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applyEmailSubscriptionResult() {
        \ISOPHP\js_core::$console->log('email sub res') ;
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery) {
            \ISOPHP\js_core::$console->log('email sub request data') ;
            \ISOPHP\js_core::$console->log($request_data) ;
            if ($request_data['status'] === 'OK') {
                $subscription_message = 'Congratulations, We&apos;ll let you know as soon as we&apos;re ready' ;
                \Core\WindowMessage::showMessage($subscription_message, 'good') ;
                $jQuery('.msg-email-subscription')->html('<div class="alert alert-success">'.$subscription_message.'</div>');
                $jQuery('#email')->val("");
            } else if ($request_data['status'] !== 'OK') {
                if ($request_data['httpStatus'] == 500) {
                    $subscription_message = 'Oh No! Something&apos;s Broken! We&aposll fix it soon...' ;
                    \Core\WindowMessage::showMessage($subscription_message, 'warning') ;
                    $jQuery('.msg-email-subscription')->html('<div class="alert alert-danger">'.$subscription_message.'</div>');
                }
                if ($request_data['httpStatus'] == 203) {
                    $subscription_message = 'Thanks! We love you too, but you&apos;ve already subscribed you once!' ;
                    \Core\WindowMessage::showMessage($subscription_message, 'warning') ;
                    $jQuery('.msg-email-subscription')->html('<div class="alert alert-danger">'.$subscription_message.'</div>');
                } else {
                    $subscription_message = 'Oh No! Invalid email address, try again... pleeeeeeease' ;
                    \Core\WindowMessage::showMessage($subscription_message, 'bad') ;
                    $jQuery('.msg-email-subscription')->html('<div class="alert alert-danger">'.$subscription_message.'</div>');
                }
            }
        } ;
        return $func;
    }    

    public function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Binding buttons');
                $jQuery = \ISOPHP\js_core::$jQuery;
                $navigate = new \Model\Navigate() ;                
                $go_comingsoon_page = $jQuery('.header_logo') ;
                $go_comingsoon_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ComingSoon', 'show', array(), '/') ;
                }) ;
                $email_subscription = $jQuery('.email_subscription') ;
                $model = $this ;
                $email_subscription->on('click', function ($jqThis) use ($model) {
                    \ISOPHP\js_core::$console->log('Coming Soon email subscription');
                    $jqThis->preventDefault();
                    $model->setEmailSubscriptionResult() ;
                });
            }
        } ;
    }

}