<?php

\Core\View::$template = function() {
    $html = '
        <form id="pharaoh_form" class="center-block register-cstm clearfix">
            <header class="col-sm-12 navbar">
                <div class="col-sm-12">
                    <h2 class="register-header"><span>Login to Piranha Web Services</span></h2>
                </div>
            </header>
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Email/User</label>
                    <input type="text" name="email_username" id="email_username" class="form-control" />
                </div> 
              
            </div> 
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" name="pass" id="pass"  class="form-control"/>
                </div> 
            </div> ' ;

    $html = $html . '<div class="row">' ;
    $html = $html . '  <div class="col-sm-6 text-center">' ;
    $html = $html . '    <div class="btn btn-success"><span id="perform-login">Login</span></div>' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-6 text-center">' ;
    $html = $html . '    <div class="btn btn-danger"><span id="cancel-login">Cancel</span></div>' ;
    $html = $html . '  </div>' ;
    $html = $html . '</div>' ;
    $html = $html . '<hr>' ;
    $html = $html . '</form>' ;
    return $html ;

} ;