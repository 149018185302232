<?php

Namespace Model\Database;

use ISOPHP\core;

class SubnetGroupPageModel extends \Model\Base {

// $bind = \Model\Database\PageModel::bindButtons();
    public function showDatabaseSubnetGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'SubnetGroupCreate.php', $vars);
            $bind = \Model\Database\PageModel::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database SubnetGroup Create 2', $vars);
        };
    }

    public function showDatabaseSubnetGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/subnetgroup/all';
            $afterwards = $this->applyDatabaseSubnetGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseSubnetGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database SubnetGroup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database SubnetGroup List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'SubnetGroupList.php', $vars);
                $bind = \Model\Database\PageModel::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database SubnetGroup List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database SubnetGroup List failed');
            }
        };
        return $func;
    }

    /**
     * Save subnetgroup
     */
    public function saveSubnetGroupAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('save subnetgroup to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'subnetgroup-create';
        $vars['api_uri'] = '/api/database/subnetgroup/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save subnetgroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSubnetGroupResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSubnetGroupResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save database subnetgroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['subnetgroup']['database_subnetgroup_name'].' for Instance '.$request_data['subnetgroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database subnetgroup saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['subnetgroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database subnetgroup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete subnetgroup
     */
    public function deleteSubnetGroupAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete subnetgroup to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'subnetgroup-delete';
        $vars['api_uri'] = '/api/database/subnetgroup/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['subnetgroup_id'] = $jQuery('#subnetgroup_name')->val();
        \ISOPHP\js_core::$console->log('delete subnetgroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteSubnetGroupResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSubnetGroupResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete database subnetgroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['subnetgroup']['database_subnetgroup_name'].' for Instance '.$request_data['subnetgroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database subnetgroup deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['subnetgroup']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database subnetgroup delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete subnetgroup
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_database_subnetgroup_list = $jQuery('.go-database-subnetgroup-list');
                $go_database_subnetgroup_list->off() ;
                $go_database_subnetgroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'subnetgroup-list', array(), '/database/subnetgroup/list');
                });

                $go_database_subnetgroup_create = $jQuery('.go-database-subnetgroup-create');
                $go_database_subnetgroup_create->off() ;
                $go_database_subnetgroup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'subnetgroup-create', array(), '/database/subnetgroup/create');
                });

                $save_subnetgroup = $jQuery('.attempt-subnetgroup-create');
                $save_subnetgroup->off() ;
                $save_subnetgroup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'subnetgroup-save-attempt', array());
                });

                $subnetgroup_delete = $jQuery('.attempt-subnetgroup-delete');
                $subnetgroup_delete->off() ;
                $subnetgroup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $subnetgroup_name = $jqThis->attr('data-database-subnetgroup-name') ;
                    $subnetgroup_id = $jqThis->attr('data-database-subnetgroup-id') ;
                    \ISOPHP\js_core::$console->log('Clicked subnetgroup delete button');
                    if ($subnetgroup_name !== '') {
                        $name_or_id = $subnetgroup_name ;
                    } else {
                        $name_or_id = $subnetgroup_id ;
                    }
                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $subnetgroup_id) {
                        $navigate->route('Database', 'subnetgroup-delete-attempt', array('subnetgroup_id' => $subnetgroup_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}