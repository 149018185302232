<?php

Namespace Model\Database;

use ISOPHP\core;

class QueryPageModel extends \Model\Base {

// $bind = \Model\Database\PageModel::bindButtons();
    public function showDatabaseQueryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'QueryCreate.php', $vars);
            $bind = \Model\Database\PageModel::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database Query Create 2', $vars);
        };
    }

    public function showDatabaseQueryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/query/all';
            $afterwards = $this->applyDatabaseQueryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseQueryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Query List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Query List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'QueryList.php', $vars);
                $bind = \Model\Database\PageModel::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database Query List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Query List failed');
            }
        };
        return $func;
    }



    public static function showDatabaseInstanceTerminal($instance_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing Database Instance Terminal');
            $vars['control'] = 'Database';
            $vars['action'] = 'terminal-session-open';
            $vars['api_uri'] = '/api/database/query/all';
            $vars['instance_id'] = $instance_id;
            $afterwards = self::applyDatabaseInstanceTerminalResult($instance_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model showDatabaseInstanceTerminal: ', $result);
            return $result;
        }
        return null ;
    }

    public static function runCommandDatabaseInstanceTerminal($instance_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            $jQuery = \ISOPHP\js_core::$jQuery;
            \ISOPHP\js_core::$console->log('Run Command in Database Instance Terminal');
            $vars['control'] = 'Database';
            $vars['action'] = 'terminal-run-command';
            $vars['api_uri'] = '/api/database/query/create';
            $vars['instance_id'] = $instance_id;
            $vars['data'] = $jQuery('#command_data')->val() ;
            $vars['history'] = 'true' ;
            $afterwards = self::applyDatabaseInstanceTerminalResult($instance_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model runCommandDatabaseInstanceTerminal: ', $result);
            return $result;
        }
        return null ;
    }

    public static function applyDatabaseInstanceTerminalResult($instance_id) {
        $func = function ($request_data) use ($instance_id) {
            \ISOPHP\js_core::$console->log('applying our Database Terminal Instance result to', $request_data);
            $jQuery = \ISOPHP\js_core::$jQuery;
            \ISOPHP\js_core::$console->log('setting terminal overlay title to fixed position', $instance_id);
            $overlay_title = $jQuery('.progressTitle') ;
            $overlay_title->css('display', 'block') ;
            $overlay_title->css('position', 'fixed') ;
            $overlay_title->css('z-index', '1000') ;
            $overlay_title->css('background-color', 'white') ;

            if ( is_object($request_data) ) {
                \ISOPHP\js_core::$console->log('termres 1');
                \ISOPHP\js_core::$console->log('rt');
                \ISOPHP\js_core::$console->log($request_data->responseText);
                \ISOPHP\js_core::$console->log('rd');
                \ISOPHP\js_core::$console->log($request_data->responseText);
//                if ( isset($request_data->responseText) ) {
                \ISOPHP\js_core::$console->log('termres 2');
                $json_val = \ISOPHP\core::$php->json_encode($request_data->responseText) ;
                \ISOPHP\js_core::$console->log('termres 2 b');
                \ISOPHP\js_core::$console->log($json_val);
                $request_data = \ISOPHP\core::$php->json_decode($json_val, true) ;
                \ISOPHP\js_core::$console->log('termres 2 c');
                \ISOPHP\js_core::$console->log($request_data);
//                }
            }

            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Instance Terminal 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'InstanceTerminal.php', $vars);
                $bind = self::bindButtons();
                $view->displayDatabaseTerminal($bind, $instance_id, $request_data);
                \ISOPHP\js_core::$console->log('apply Database Instance Terminal 2', $vars);


                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('#overlay_inner') ;
                \ISOPHP\js_core::$console->log('add class overlay_inner_terminal_large to overlay', $instance_id);
                $overlay_inner->addClass('overlay_inner_terminal_large') ;

                $command_field = $jQuery('#command_data');
                \ISOPHP\js_core::$console->log('bound to #command_data', $instance_id);
                $command_field->attr('data-instance-id', $instance_id) ;
                \ISOPHP\js_core::$console->log('#command_data attr set to instance id', $command_field->attr('data-instance-id'));
                $command_field->focus() ;
                \ISOPHP\js_core::$console->log('command field is now focussed');

                $terminal_element = $jQuery('#terminal-console');
                \ISOPHP\js_core::$console->log('binding click to #terminal-console', $instance_id);
                $terminal_element->on('click', function ($jsThis)  {
                    \ISOPHP\js_core::$console->log('terminal console has been clicked', $jsThis);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $command_field = $jQuery('#command_data');
                    $command_field->focus() ;
                    \ISOPHP\js_core::$console->log('command field is now focussed', $jsThis);
                });

                $command_field->on('keyup', function ($jsThis)  {
                    \ISOPHP\js_core::$console->log('$jsThis', $jsThis);
                    \ISOPHP\js_core::$console->log('$jsThis->originalEvent->key', $jsThis->originalEvent->key);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $command_field = $jQuery('#command_data');
                    $instance_id = $command_field->attr('data-instance-id');
                    \ISOPHP\js_core::$console->log('$instance_id', $instance_id);
                    \ISOPHP\js_core::$console->log('key up event', $jsThis->originalEvent->key);
                    if ($jsThis->originalEvent->key === 'Enter' ) {
                        \ISOPHP\js_core::$console->log('Enter has been pressed in command field', $jsThis->originalEvent->key);
                        $command_string = $jQuery('#command_data')->val() ;
                        # todo truncate this string
                        \Core\WindowMessage::showMessage('Executing: ' . $command_string, 'good');
                        self::runCommandDatabaseInstanceTerminal($instance_id) ;
                    }
                });
            }
            if ($request_data['status'] !== 'OK') {
                \Core\WindowMessage::showMessage($request_data['message'], 'bad');
                \ISOPHP\js_core::$console->log('Database Instance Terminal command failed');
                $command_field = $jQuery('#command_data');
                \ISOPHP\js_core::$console->log('bound to #command_data', $instance_id);
                $command_field->attr('data-instance-id', $instance_id) ;
                \ISOPHP\js_core::$console->log('#command_data attr set to instance id', $command_field->attr('data-instance-id'));
                $command_field->focus() ;
                \ISOPHP\js_core::$console->log('command field is now focussed');
            }
        };
        return $func;
    }

    /**
     * Save query
     */
    public function saveQueryAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('save query to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'query-create';
        $vars['api_uri'] = '/api/database/query/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save query vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveQueryResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveQueryResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save database query result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Query '.$request_data['query']['database_query_name'].' for Instance '.$request_data['query']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database query saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Query '.$request_data['query']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database query save failed');
            }
        };
        return $func;
    }
    /**
     * Delete query
     */
    public function deleteQueryAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete query to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'query-delete';
        $vars['api_uri'] = '/api/database/query/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['query_id'] = $jQuery('#query_name')->val();
        \ISOPHP\js_core::$console->log('delete query vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteQueryResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteQueryResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete database query result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Query '.$request_data['query']['database_query_name'].' for Instance '.$request_data['query']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database query deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Query '.$request_data['query']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database query delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete query
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding database query buttons');

                # Query Result

                $go_database_query_list = $jQuery('.go-database-query-list');
                $go_database_query_list->off() ;
                $go_database_query_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'query-list', array(), '/database/query/list');
                });

                $go_database_query_create = $jQuery('.go-database-query-create');
                $go_database_query_create->off() ;
                $go_database_query_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'query-create', array(), '/database/query/create');
                });

                $save_query = $jQuery('.attempt-query-create');
                $save_query->off() ;
                $save_query->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'query-save-attempt', array());
                });

                $query_delete = $jQuery('.attempt-query-delete');
                $query_delete->off() ;
                $query_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $query_name = $jqThis->attr('data-database-query-name') ;
//                    $query_id = $jqThis->attr('data-database-query-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked query delete button');
//                    if ($query_name !== '') {
//                        $name_or_id = $query_name ;
//                    } else {
//                        $name_or_id = $query_id ;
//                    }
//                    $msg = "You are about to delete the Query Result $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $query_id) {
//                        $navigate->route('Database', 'query-delete-attempt', array('query_id' => $query_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;



                $instance_edit = $jQuery('.attempt-database-instance-edit');
                $instance_edit->off() ;
                $instance_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#' . $this_id);
                    $instance_name = $jqThis->attr('data-database-instance-name');
                    $instance_id = $jqThis->attr('data-database-instance-id');
                    $instance_state = $jqThis->attr('data-database-instance-state');
                    \ISOPHP\js_core::$console->log('Clicked instance edit button');
                    $msg = "Editing Instance $instance_name.";
                    $data = '<div class="col-sm-12 overlay-col">';
                    $data .= '  <h3>State: ' . \ISOPHP\core::$php->ucfirst($instance_state) . '</h3>';
                    $data .= '</div>';
                    $data .= '<div class="col-sm-12 overlay-col">';
                    if ($instance_state == 'stopped') {
                        $data .= '  <div class="col-sm-6 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_start" class="fa fa-2x fa-play btn-outline-info instance_modify instance_start"></i>';
                        $data .= '  </div>';
                        $data .= '  <div class="col-sm-6 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>';
                        $data .= '  </div>';
                    }
                    if ($instance_state == 'running') {
                        $data .= '  <div class="col-sm-4 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_stop" class="fa fa-2x fa-stop btn-outline-info instance_modify instance_stop"></i>';
                        $data .= '  </div>';
                        $data .= '  <div class="col-sm-4 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_pause" class="fa fa-2x fa-pause btn-outline-info instance_modify instance_pause"></i>';
                        $data .= '  </div>';
                        $data .= '  <div class="col-sm-4 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>';
                        $data .= '  </div>';
                    }
                    if ($instance_state == 'paused') {
                        $data .= '  <div class="col-sm-6 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_resume" class="fa fa-2x fa-resume btn-outline-info instance_modify instance_resume"></i>';
                        $data .= '  </div>';
                        $data .= '  <div class="col-sm-6 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_poweroff" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweroff"></i>';
                        $data .= '  </div>';
                    }
                    if ($instance_state == 'poweredoff') {
                        $data .= '  <div class="col-sm-12 overlay-col">';
                        $data .= '    <i id="modify_' . $instance_id . '_poweron" class="fa fa-2x fa-power-off btn-outline-info instance_modify instance_poweron"></i>';
                        $data .= '  </div>';
                    }
                    $data .= '  </div>';
                    $data .= '  <div class="col-sm-12 overlay-col">';
                    $data .= '    <h3>Console:</h3>';
                    $data .= '  </div>';
                    $data .= '  <div class="col-sm-12 overlay-col">';
                    $data .= '    <span class="col-sm-12 btn btn-outline-primary terminal-open-button"
                                        data-database-instance-id="' . $instance_id . '" ';
                    $data .= '          id="terminal-open-button" >';
                    $data .= '      <i id="open_console_' . $instance_id . '" class="fa fa-2x fa-terminal btn-outline-info instance_console instance_console_open"></i>';
                    $data .= '      Open Terminal';
                    $data .= '    </span>';
                    $data .= '  </div>';
                    $data .= '</div>';
                    \Core\WindowMessage::showOverlay($msg, $data);

                    $terminal_open = $jQuery('#terminal-open-button');
                    $terminal_open->off();
                    $terminal_open->on('click', function ($jsthis) use ($navigate, $jQuery, $instance_id) {
                        $jsthis->preventDefault();
                        $this_id = $jsthis->currentTarget->id;
                        $jqThis = $jQuery('#' . $this_id);

                        $data = '<div class="col-sm-12 overlay-col" id="sc1_terminal">';
                        $data .= '    <link rel="stylesheet" type="text/css" href="/app/SC1/Assets/css/code-terminal.css">';
                        $data .= '    <script type="text/javascript" src="/app/SC1/Assets/js/console.js"></script>';
                        $data .= '    <div class="col-sm-12 terminal-console" id="terminal-console">';
                        $data .= '      <pre>Welcome to your Terminal</pre>';
                        $data .= '      <br />';
                        $data .= '      <br />';
                        $data .= '      <pre>';
                        $data .= '        Loading';
                        $data .= '      </pre>';
                        $data .= '    </div>';
                        $data .= '</div>';

                        $msg = "Query Console for Instance ID: $instance_id";
                        \Core\WindowMessage::showOverlay($msg, $data);
                        \ISOPHP\js_core::$console->log('Showing query terminal overlay');

                        \ISOPHP\js_core::$console->log('Clicked terminal open button');
                        self::showDatabaseInstanceTerminal($instance_id);

                    });

                }) ;
            }

        } ;

    }

}