<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Drive, your File Sharing Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Storage For Web Based Directories and Objects</h3>
                    <p>File Storage and Sharing</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-drive-directory-list" class="go-drive-directory-list btn btn-lg btn-warning">Directories</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-drive-directory-create" class="go-drive-directory-create btn btn-lg btn-warning">Create Directory</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;