<?php

\Core\View::$template = function () {


    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12">
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-user-edit" class="btn btn-success attempt-user-edit">Edit</span>
                        <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <h4>Edit User</h4>
                    </div>
                    
                    <input id="edit_user_id" name="edit_user_id" value="" type="hidden" />
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <label>User Name</label>
                            <input type="text" name="username" class="form-control" id="username" value="'.$rqd['user']['username'].'" />
                        </div>
                        <div class="col-sm-6 col-6">
                            <label>Password</label>
                            <input type="password" name="userpass" class="form-control" id="userpass" value="****" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <label>Full Name</label>
                            <input type="text" name="full_name" class="form-control" id="full_name" value="'.$rqd['user']['fullname'].'" />
                        </div>
                        <div class="col-sm-6 col-6">
                            <label>Email</label>
                            <input type="text" name="email" class="form-control" id="email" value="'.$rqd['user']['email'].'" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <label>Description</label>
                            <textarea name="description" id="description" class="form-control" rows="10" cols="40">';

                        $html = $html . $rqd['user']['description'] ;

                        $html = $html .
                           '</textarea>
                        </div>
                        
                        <div class="col-sm-6 col-6">
                            <div class="col-sm-12 col-12 text-center">
                                <label>Keysets</label>
                            </div>
                            
                            <div id="keyset-wrap" class="col-sm-12 col-12 keyset-wrap">
                                                        
                                <div class="col-sm-12 col-12 text-center">
                                    <div class="col-sm-6 col-6">
                                        <span id="go-sam-keyset-create" class="go-sam-keyset-create btn btn-lg btn-success">
                                            New Keyset: 
                                        </span>
                                    </div>
                                    <div class="col-sm-6 col-6">
                                        <input type="text" name="keyset_new_name" id="keyset_new_name" />
                                    </div>
                                </div>
                                                        
                                <div id="keyset_data" class="col-sm-12 col-12 text-center">
                                </div>';


                        $html = $html . '</div>';

                        $html = $html .
                        '</div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center" style="padding-top: 15px;">
                        <span id="attempt-user-edit" class="btn btn-success attempt-user-edit">Edit</span>
                        <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                    
                </div>
            </div>
             
        </div>';

    return $html;

};