<?php

Namespace Controller;

class SAMController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SAM controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SAM, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SAM\PageModel();
            $ua_page_model = new \Model\SAM\UserAccountPageModel();
            $policy_page_model = new \Model\SAM\PolicyPageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SAM.php';
            $res->type = 'view';
            $res->view_control = 'SAM';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSAMLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'group-list') {
                $res->post_template[] = $page_model->showSAMGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'group-create') {
                $res->post_template[] = $page_model->showSAMGroupCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'group-save-attempt') {
                $page_model->saveGroupAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'group-delete-attempt') {
                $page_model->deleteGroupAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'user-keyset-list') {
                $res->post_template[] = $page_model->showSAMUserList($pageVars);
            } else if ($pageVars['route']['action'] == 'user-keyset-create') {
                $res->post_template[] = $page_model->saveKeysetAttemptToSAM($pageVars);
            } else if ($pageVars['route']['action'] == 'user-list') {
                $res->post_template[] = $page_model->showSAMUserList($pageVars);
            } else if ($pageVars['route']['action'] == 'user-create') {
                $res->post_template[] = $page_model->showSAMUserCreate($pageVars);
                $res->post_template[] = $page_model->showSAMGroupListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'user-edit') {
                $res->post_template[] = $page_model->showSAMUserEdit($pageVars);
                $res->post_template[] = $page_model->showSAMGroupListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'user-save-attempt') {
                $page_model->saveUserAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'user-edit-attempt') {
                $page_model->editUserAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'user-delete-attempt') {
                $page_model->deleteUserAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'keyset-delete-attempt') {
                $page_model->deleteKeysetAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'role-list') {
                $res->post_template[] = $page_model->showSAMRoleList($pageVars);
            } else if ($pageVars['route']['action'] == 'role-create') {
                $res->post_template[] = $page_model->showSAMRoleCreate($pageVars);
                $res->post_template[] = $page_model->showSAMGroupListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'role-save-attempt') {
                $page_model->saveRoleAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'role-delete-attempt') {
                $page_model->deleteRoleAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'user-account') {
                $res->post_template[] = $ua_page_model->showSAMUserAccount($pageVars);
            } else if ($pageVars['route']['action'] == 'user-account-save-attempt') {
                $ua_page_model->saveUserAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
            } else if ($pageVars['route']['action'] == 'policy-list') {
                $res->post_template[] = $policy_page_model->showSAMPolicyList($pageVars);
            } else if ($pageVars['route']['action'] == 'policy-create') {
                $res->post_template[] = $policy_page_model->showSAMPolicyCreate($pageVars);
                $res->post_template[] = $policy_page_model->showSAMPolicyEntityListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'policy-save-attempt') {
                $policy_page_model->savePolicyAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'policy-delete-attempt') {
                $policy_page_model->deletePolicyAttemptToSAM($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'policy-permission-add') {
                $policy_page_model->addSAMPolicyPermission($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'policy-permission-remove') {
                $policy_page_model->deleteSAMPolicyPermission($pageVars);
                $res->control = 'SAM';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSAMLanding($pageVars);
            }
            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $policy_page_model->bindButtons();
//        $res->post_template[] = $ua_page_model->bindButtons();
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}