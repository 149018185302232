<?php

Namespace Model\Database;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Database List of Piranha Web Services';
        $page['heading'] = 'List of Database';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Database Mod', $page, $pageVars);
        return $page;
    }

    public function showDatabaseLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'DatabaseLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database Instance Create 2', $vars);
        };
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_database_landing = $jQuery('.go-database-landing');
                $go_database_landing->off() ;
                $go_database_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'landing', array(), '/database');
                });

                $go_database_size_list = $jQuery('.go-database-size-list');
                $go_database_size_list->off() ;
                $go_database_size_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'size-list', array(), '/database/size/list');
                });


                $go_database_image_list = $jQuery('.go-database-image-list');
                $go_database_image_list->off() ;
                $go_database_image_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'image-list', array(), '/database/image/list');
                });

                $go_database_image_create = $jQuery('.go-database-image-create');
                $go_database_image_create->off() ;
                $go_database_image_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'image-create', array(), '/database/image/create');
                });

                $save_image = $jQuery('.attempt-image-create');
                $save_image->off() ;
                $save_image->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'image-save-attempt', array());
                });

                $image_delete = $jQuery('.attempt-image-delete');
                $image_delete->off() ;
                $image_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $image_name = $jqThis->attr('data-database-image-name') ;
                    $image_id = $jqThis->attr('data-database-image-id') ;
                    \ISOPHP\js_core::$console->log('Clicked image delete button');
                    $msg = "You are about to delete the Instance $image_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $image_id, $image_name) {
                        $navigate->route('Database', 'image-delete-attempt', array('image_id' => $image_id, 'image_name' => $image_name));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

            }
        };
    }

    public static function bindAllButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $instances_page_model = new \Model\Database\InstancePageModel();
                $backup_page_model = new \Model\Database\BackupPageModel();

                $optiongroup_page_model = new \Model\Database\OptionGroupPageModel();
                $parametergroup_page_model = new \Model\Database\ParameterGroupPageModel();
                $subnetgroup_page_model = new \Model\Database\SubnetGroupPageModel();

                $performancechart_page_model = new \Model\Database\PerformanceChartPageModel();
                $query_page_model = new \Model\Database\QueryPageModel();
                $reservation_page_model = new \Model\Database\ReservationPageModel();
                $snapshot_page_model = new \Model\Database\SnapshotPageModel();

                $size_page_model = new \Model\Database\SizePageModel();
                $images_page_model = new \Model\Database\ImagePageModel();

                $b1 = self::bindButtons();
                $b2 = $instances_page_model->bindButtons();
                $b3 = $backup_page_model->bindButtons();
                $b4 = $optiongroup_page_model->bindButtons();
                $b5 = $parametergroup_page_model->bindButtons();
                $b6 = $subnetgroup_page_model->bindButtons();
                $b7 = $performancechart_page_model->bindButtons();
                $b8 = $query_page_model->bindButtons();
                $b9 = $reservation_page_model->bindButtons();
                $b10 = $snapshot_page_model->bindButtons();
                $b11 = $size_page_model->bindButtons();
                $b12 = $images_page_model->bindButtons();

                $b1() ;
                $b2() ;
                $b3() ;
                $b4() ;
                $b5() ;
                $b6() ;
                $b7() ;
                $b8() ;
                $b9() ;
                $b10() ;
                $b11() ;
                $b12() ;

            }
        };
    }


}