<?php

Namespace View;

class DeviceAutomationView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displayDeviceAutomation($binder)
    {
        \ISOPHP\js_core::$console->log('display deviceautomation');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#deviceautomation_content')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displayDeviceAutomationDeviceDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display deviceautomation device dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#deviceautomation_device_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displayDeviceAutomationNewPost($binder)
    {
        \ISOPHP\js_core::$console->log('display new deviceautomation form');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
        $jQuery('#deviceautomation_list')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

}