<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $secrets_are_array = \ISOPHP\core::$php->is_array($rqd['secrets']) ;
    if ($secrets_are_array === true) {
        $secrets_exist = true ;
    } else {
        $secrets_exist = false ;
    }
    if ($secrets_exist === true) {
        $secret_count = \ISOPHP\core::$php->count($rqd['secrets']) ;
    } else {
        $secret_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-scm-secret-create" class="btn btn-success go-scm-secret-create"
                  data-scm-repository-id="' . $rqd['repository_id'] . '"
                  data-scm-repository-name="' . $rqd['repository_id'] . '" >Create New Secret</span>
            <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">Repositories</span>
            <span id="go-scm-landing" class="btn btn-warning go-scm-landing">SCM Home</span>
            <span id="go-scm-repository-secrets" class="btn btn-info go-scm-repository-secrets btn-refresh"
                  data-scm-repository-id="' . $rqd['repository_id'] . '"
                  data-scm-repository-name="' . $rqd['repository_id'] . '" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Secret List for: ' . $rqd['repository_id'] . '</h4>
         </div> ' ;

    if ($secrets_exist === true && $secret_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_scm_secret">
                        <div class="col-sm-2 col-2">
                            <h5 class="scm_secret_table_title">ID</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="scm_secret_table_title">Name</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="scm_secret_table_title">Description</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="scm_secret_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['secrets'] as $one_scm_secret) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2" style="overflow-x: hidden">
                        <p class="scm_list_id"><span class="scm_id scm_listing">' . $one_scm_secret['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3" style="overflow-x: hidden">
                        <p class="scm_list_secret_name"><span class="scm_secret_name scm_listing">' . $one_scm_secret['name'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="scm_list_secret_name"><span class="scm_secret_name scm_listing">' . $one_scm_secret['description'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <i id="delete_scm_secret_' . $one_scm_secret['id'] . '"
                               class="fa fa-1x fa-close hvr-grow dom attempt-secret-delete btn btn-outline-danger"
                               data-scm-repository-id="' . $rqd['repository_id'] . '"
                               data-scm-secret-id="' . $one_scm_secret['id'] . '"
                               data-scm-secret-name="' . $one_scm_secret['name'] . '"></i>
                        </div>
                    </div>
                 </div>';
        }
    } else {
        $html = $html .
            '
        <div class="col-sm-12 col-12 text-center">
            <h4> The Repository ' . $rqd['repository_id'] . ' has no Secrets</h4>
        </div>' ;
    }


    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-scm-secret-create" class="btn btn-success go-scm-secret-create"
                  data-scm-repository-id="' . $rqd['repository_id'] . '"
                  data-scm-repository-name="' . $rqd['repository_id'] . '" >Create New Secret</span>
            <span id="go-scm-repository-list" class="btn btn-info go-scm-repository-list">Repositories</span>
            <span id="go-scm-landing" class="btn btn-warning go-scm-landing">SCM Home</span>
            <span id="go-scm-repository-secrets" class="btn btn-info go-scm-repository-secrets btn-refresh"
                  data-scm-repository-id="' . $rqd['repository_id'] . '"
                  data-scm-repository-name="' . $rqd['repository_id'] . '" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};
