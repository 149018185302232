<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $networkacls_are_array = \ISOPHP\core::$php->is_array($rqd['networkacls']) ;
    if ($networkacls_are_array === true) {
        $networkacls_exist = true ;
    } else {
        $networkacls_exist = false ;
    }
    if ($networkacls_exist === true) {
        $networkacl_count = \ISOPHP\core::$php->count($rqd['networkacls']) ;
    } else {
        $networkacl_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-networkacl-create" class="btn btn-success go-svpc-networkacl-create">Create Network ACL</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-networkacl-list" class="btn btn-info go-svpc-networkacl-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Network ACL List</h4>
         </div> ' ;

    if ($networkacls_exist === true && $networkacl_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_svpc_networkacl">
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">Network ACL ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">VPC ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">Subnet Assoc.</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">Edge Assoc.</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">Main</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_networkacl_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['networkacls'] as $one_svpc_networkacl) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_networkacl['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_svpc_name"><span class="svpc_svpc_name svpc_listing">' . $one_svpc_networkacl['vpc_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_networkacl_name"><span class="name svpc_listing">' . $one_svpc_networkacl['subnet_associations'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_networkacl_data"><span class="svpc_networkacl_data svpc_listing">' . $one_svpc_networkacl['edge_associations'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_networkacl_type"><span class="svpc_type svpc_listing">' . $one_svpc_networkacl['main'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_svpc_networkacl_' . $one_svpc_networkacl['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-networkacl-edit btn btn-outline-primary"
                                   data-svpc-networkacl-id="' . $one_svpc_networkacl['id'] . '"
                                   data-svpc-networkacl-name="' . $one_svpc_networkacl['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_svpc_networkacl_' . $one_svpc_networkacl['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-networkacl-delete btn btn-outline-danger"
                                   data-svpc-networkacl-id="' . $one_svpc_networkacl['id'] . '"
                                   data-svpc-networkacl-name="' . $one_svpc_networkacl['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Network ACLs</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-networkacl-create" class="btn btn-success go-svpc-networkacl-create">Create Network ACL</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-networkacl-list" class="btn btn-info go-svpc-networkacl-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};