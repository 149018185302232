<?php

Namespace Controller;

class SCMController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SCM controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SCM, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SCM\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SCM.php';
            $res->type = 'view';
            $res->view_control = 'SCM';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSCMLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'secret-create') {
                $res->post_template[] = $page_model->showSCMSecretCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'secret-list') {
                $res->post_template[] = $page_model->showSCMSecretList($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-list') {
                $res->post_template[] = $page_model->showSCMRepositoryList($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-browse') {
                $res->post_template[] = $page_model->showSCMRepositoryBrowse($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-history') {
                $res->post_template[] = $page_model->showSCMRepositoryHistory($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-charts') {
                $res->post_template[] = $page_model->showSCMRepositoryCharts($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-contributors') {
                $res->post_template[] = $page_model->showSCMRepositoryContributors($pageVars);
            } else if ($pageVars['route']['action'] == 'commit-details') {
                $res->post_template[] = $page_model->showSCMCommitDetails($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-create') {
                $res->post_template[] = $page_model->showSCMRepositoryCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
                $res->post_template[] = $page_model->showSCMRepositoryListDropDown($pageVars);
                $res->post_template[] = $page_model->bindRepositoryListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showSCMObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'secret-save-attempt') {
                $page_model->saveSecretAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'repository-save-attempt') {
                $page_model->saveRepositoryAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'secret-delete-attempt') {
                $page_model->deleteSecretAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'repository-delete-attempt') {
                $page_model->deleteRepositoryAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToSCM($pageVars);
                $res->view = 'SCM.php';
                $res->control = 'SCM';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSCMLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SCM Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}