<?php

Namespace Core ;

class WindowMessage {

    public static $status_timeout ;

    public static function showMessage($msg_data, $msg_type) {
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $message_overlay = $jQuery('#message_overlay') ;
        $msg_hash = self::makeid() ;
        $message_overlay->append(self::getMessageHTML($msg_data, $msg_type, $msg_hash)) ;
        /*\ISOPHP\js_core::$console->log('Okay message from random' ,$msg_hash) ;*/
        $window = \ISOPHP\js_core::$window ;
        $window->setTimeout(function() use ($msg_hash) {
            self::hideMessage($msg_hash) ;
        }, 3000) ;
    }

    public static function showStatus($msg_type, $variables, $update_url, $finish_function = null) {
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $message_overlay = $jQuery('#message_overlay') ;
        $msg_hash = self::makeid() ;
        $status_html = self::getStatusHTML($msg_type, $msg_hash) ;
        $message_overlay->append($status_html) ;
        /*\ISOPHP\js_core::$console->log('Okay message from random' ,$msg_hash) ;*/
        $window = \ISOPHP\js_core::$window ;
        $mod_id = $variables['mod_id'] ;
        self::$status_timeout[$mod_id] = $window->setInterval(function() use ($msg_hash, $variables, $update_url,$finish_function) {
            $variables['api_uri'] = $update_url;
            $afterwards = self::updateStatus($msg_hash, $variables, $update_url, $finish_function) ;
            $result = \Model\Base::performRequest($variables, $afterwards, true);
        }, 5000) ;
    }

    public static function hideMessage($msg_hash) {
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $message_overlay = $jQuery('#msg_'.$msg_hash) ;
        // $message_overlay->remove() ;
        $message_overlay->fadeOut(900, function() use ($message_overlay) {
            $message_overlay->remove();
        });
        return true ;
    }

    public static function updateStatus($msg_hash, $variables, $update_url, $finish_function = null) {
        $retty = function($data) use ($msg_hash, $variables, $update_url, $finish_function) {

//            \ISOPHP\js_core::$console->log('Upd $msg_hash ' ,$msg_hash) ;
//            \ISOPHP\js_core::$console->log('Upd $variables ' ,$variables) ;
//            \ISOPHP\js_core::$console->log('Upd $update_url ' ,$update_url) ;
//            \ISOPHP\js_core::$console->log('Upd $data ' ,$data) ;
            $jQuery = \ISOPHP\js_core::$jQuery ;
            $message_overlay = $jQuery('#msg_'.$msg_hash) ;
            $status_stage_wrap = $jQuery('#status_stage_'.$msg_hash.'_wrap') ;
            $status_stage_wrap->css('display', 'block') ;
            $status_elapsed_wrap = $jQuery('#status_elapsed_'.$msg_hash.'_wrap') ;
            $status_elapsed_wrap->css('display', 'block') ;
            $status_stage = $jQuery('#status_stage_'.$msg_hash) ;
            $status_stage->html($data['modification']['stage']) ;
            $status_elapsed = $jQuery('#status_elapsed_'.$msg_hash) ;
            $status_elapsed->html($data['modification']['time']) ;
            $status_name = $jQuery('#status_name_'.$msg_hash) ;
            $status_name->html($variables['vm_name']) ;

            if ($data['modification']['status'] == 'Complete') {
                $fnk = function() use ($message_overlay) {
                    $message_overlay->remove();
                } ;
                $message_overlay->fadeOut(900, $fnk);
                $mod_id = $variables['mod_id'] ;
                $window = \ISOPHP\js_core::$window ;
                $window->clearInterval(self::$status_timeout[$mod_id]) ;

                if ($finish_function !== null) {
                    $finish_function() ;
                }

            }

            return true ;

        } ;
        return $retty ;
    }

    public static function getMessageHTML($msg_data, $msg_type, $msg_hash) {
        if ($msg_type === "good") {
            $msg_class = ' btn-success' ; }
        else if ($msg_type === "warning") {
            $msg_class = ' btn-warning' ; }
        else {
            $msg_class = ' btn-danger' ; }
        $h = '' ;
        $h = $h . '  <div id="msg_'.$msg_hash.'" class="fullRow '.$msg_class.'">' ;
        $h = $h . '    <span class="message_text ">' ;
        $h = $h . $msg_data ;
        $h = $h . '    </span>' ;
        $h = $h . '  </div>' ;
        return $h ;
    }

    public static function getStatusHTML($msg_type, $msg_hash) {
        if ($msg_type === "good") {
            $msg_class = ' btn-success' ; }
        else {
            $msg_class = ' btn-danger' ; }
        $h = '' ;
        $h = $h . '  <div id="msg_'.$msg_hash.'" class="fullRow status_overlay '.$msg_class.'">' ;
        $h = $h . '    <div class="fullRow">' ;
        $h = $h . '      <span id="status_name_'.$msg_hash.'"></span>' ;
        $h = $h . '    </div>' ;
        $h = $h . '    <div class="fullRow status_stage_wrap" id="status_stage_'.$msg_hash.'_wrap">' ;
        $h = $h . '      <span> Stage: <span id="status_stage_'.$msg_hash.'">' ;
        $h = $h . '      </span></span>' ;
        $h = $h . '    </div>' ;
        $h = $h . '    <div class="fullRow status_elapsed_wrap" id="status_elapsed_'.$msg_hash.'_wrap">' ;
        $h = $h . '      <span> Time: <span id="status_elapsed_'.$msg_hash.'">' ;
        $h = $h . '      </span> (s)</span>' ;
        $h = $h . '    </div>' ;
        $h = $h . '  </div>' ;
        return $h ;
    }



    public static function randomNum($charCount) {
        $random = \ISOPHP\core::$php->mt_rand(0,($charCount-1)) ;
        return $random ;
    }

    public static function randomChar(){
        $char_ray = array('a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','1','2','3','4','5','6','7','8','9','0') ;
        $rand = self::randomNum(count($char_ray)) ;
        return $char_ray[$rand];
    }

    public static function makeid($length = 16){
        $str = "";
        for($i = 0; $i < $length; $i=$i+1) {
            $str = $str . self::randomChar();
        }
        return $str;
    }

    public static function areYouSure($message, $yesfunction = null) {
        $overlay_html = self::getOverlayHTML('confirm', $message) ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $jQuery('.overlay_fephp')->remove() ;
        $jQuery('body')->append($overlay_html) ;
        $jQuery('#overlay_confirm')->show() ;
        $jQuery('#close_page_overlay')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
        $jQuery('#overlay_no_button')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
        $jQuery('#overlay_yes_button')->on('click', function() use ($jQuery, $yesfunction) {
            if ($yesfunction !== null) {
                $yesfunction() ;
            }
            self::closeOverlay() ;
        } );
    }

    public static function dialogOverlay($title, $content=null, $yes_title=null, $no_title=null, $yesfunction = null) {
        $overlay_html = self::getOverlayHTML('confirm', $title, $content, $yes_title, $no_title) ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $jQuery('.overlay_fephp')->remove() ;
        $jQuery('body')->append($overlay_html) ;
        $jQuery('#overlay_confirm')->show() ;
        $jQuery('#close_page_overlay')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
        $jQuery('#overlay_no_button')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
        $jQuery('#overlay_yes_button')->on('click', function() use ($jQuery, $yesfunction) {
            if ($yesfunction !== null) {
                $yesfunction() ;
            }
            self::closeOverlay() ;
        } );
    }

    public static function showOverlay($message, $data=null) {
        $overlay_html = self::getOverlayHTML('ok', $message, $data) ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $jQuery('.overlay_fephp')->remove() ;
        $jQuery('body')->append($overlay_html) ;
        $jQuery('#overlay_ok')->show() ;
        $jQuery('#close_page_overlay')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
        $jQuery('#overlay_ok_button')->on('click', function() use ($jQuery) {
            self::closeOverlay() ;
        } );
    }

    public static function closeOverlay() {
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $jQuery('#overlay_ok')->fadeOut('fast') ;
        $jQuery('.overlay_fephp')->fadeOut('fast') ;
    }

    public static function getOverlayHTML($overlay_type, $title, $data=null, $yes_button_title=null, $no_button_title=null) {
        $htmlvar  = '<div id="overlay_'.$overlay_type.'" class="overlay_fephp"> ';
        $htmlvar = $htmlvar . '    <div id="overlay_inner" class="overlay_inner"> ';
        $htmlvar = $htmlvar . '        <div id="close_button_div"> ';
        $htmlvar = $htmlvar . '            <span id="close_page_overlay"> ';
        $htmlvar = $htmlvar . '                <img src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/close_button.png" /> ';
        $htmlvar = $htmlvar . '            </span> ';
        $htmlvar = $htmlvar . '        </div> ';
        $htmlvar = $htmlvar . '        <div id="overlay_content" class="overlay_content"> ';
        $htmlvar = $htmlvar . '            <h3 class="progressTitle">' . $title . '</h3> ';
        if ($data !== null) {
            $htmlvar = $htmlvar . '            <div class="fullWidth"> ';
            $htmlvar = $htmlvar . '                <div id="overlay_data">'.$data.'</div>';
            $htmlvar = $htmlvar . '            </div> ';
        }
        $htmlvar = $htmlvar . '            <div id="overlay_buttons" class="fullWidth overlay_buttons"> ';

        $yes_title = ($yes_button_title !== null) ? $yes_button_title : (($overlay_type === 'ok') ? 'OK' : 'Yes');
        $no_title = ($no_button_title !== null) ? $no_button_title : 'No';

        if ($overlay_type === 'confirm') {
            $htmlvar = $htmlvar . '                <span id="overlay_yes_button" class="btn btn-success hvr-float-shadow">'. $yes_title .'</span> ';
            $htmlvar = $htmlvar . '                <span id="overlay_no_button" class="btn btn-warning hvr-float-shadow">'. $no_title .'</span> ';
        } else if ($overlay_type === 'ok') {
            $htmlvar = $htmlvar . '                <span id="overlay_ok_button" class="btn btn-warning hvr-float-shadow">'. $yes_title .'</span> ';
        }
        $htmlvar = $htmlvar . '            </div> ';
        $htmlvar = $htmlvar . '        </div> ';
        $htmlvar = $htmlvar . '    </div> ';
        $htmlvar = $htmlvar . '</div> ';
        return $htmlvar ;
    }


}
