<?php

Namespace View;

class ClosureView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displayClosure($binder)
    {
        \ISOPHP\js_core::$console->log('display closure');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#closure_content')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displayClosureRepositoryDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display closure repository dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $closure_repository_dropdown = $jQuery('#closure_repository_dropdown') ;
        $closure_repository_dropdown->html('');
        $closure_repository_dropdown->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding displayClosureRepositoryDropDown');
        \ISOPHP\js_core::$console->log($closure_repository_dropdown);
        $binder();
    }

    public function displayClosureNewPost($binder)
    {
        \ISOPHP\js_core::$console->log('display new closure form');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
        $jQuery('#closure_list')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

}