<?php

Namespace Controller;

class DatabaseController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Database controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Database, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Database\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('Database List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Database.php';
            $res->type = 'view';
            $res->view_control = 'Database';
            $res->post_template = array();

            $instances_page_model = new \Model\Database\InstancePageModel();
            $reservation_page_model = new \Model\Database\ReservationPageModel();
            $query_page_model = new \Model\Database\QueryPageModel();
            $performancechart_page_model = new \Model\Database\PerformanceChartPageModel();
            $backup_page_model = new \Model\Database\BackupPageModel();
            $snapshot_page_model = new \Model\Database\SnapshotPageModel();
            $subnetgroup_page_model = new \Model\Database\SubnetGroupPageModel();
            $parametergroup_page_model = new \Model\Database\ParameterGroupPageModel();
            $optiongroup_page_model = new \Model\Database\OptionGroupPageModel();
            $image_page_model = new \Model\Database\ImagePageModel();
            $size_page_model = new \Model\Database\SizePageModel();
            $svpc_page_model = new \Model\SVPC\PageModel();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showDatabaseLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'image-list') {
                $res->post_template[] = $image_page_model->showDatabaseImageList($pageVars);
            } else if ($pageVars['route']['action'] == 'size-list') {
                $res->post_template[] = $size_page_model->showDatabaseSizeList($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-list') {
                $res->post_template[] = $instances_page_model->showDatabaseInstanceList($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-create') {
                $res->post_template[] = $instances_page_model->showDatabaseInstanceCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceSizeDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceImageDropDown($pageVars);
                $res->post_template[] = $svpc_page_model->showSVPCVPCListMultiSelect($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-save-attempt') {
                $instances_page_model->saveInstanceAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'instance-delete-attempt') {
                $instances_page_model->deleteInstanceAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'query-list') {
                $res->post_template[] = $query_page_model->showDatabaseQueryList($pageVars);
            } else if ($pageVars['route']['action'] == 'query-create') {
                $res->post_template[] = $query_page_model->showDatabaseQueryCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceListDropDown($pageVars);
//            $res->post_template[] = $query_page_model->showDatabaseQueryImageDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'query-save-attempt') {
                $query_page_model->saveQueryAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'query-delete-attempt') {
                $query_page_model->deleteQueryAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'performancechart-list') {
                $res->post_template[] = $performancechart_page_model->showDatabasePerformanceChartList($pageVars);
            } else if ($pageVars['route']['action'] == 'performancechart-create') {
                $res->post_template[] = $performancechart_page_model->showDatabasePerformanceChartCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'performancechart-save-attempt') {
                $performancechart_page_model->savePerformanceChartAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'performancechart-delete-attempt') {
                $performancechart_page_model->deletePerformanceChartAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-list') {
                $res->post_template[] = $backup_page_model->showDatabaseBackupList($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-create') {
                $res->post_template[] = $backup_page_model->showDatabaseBackupCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-save-attempt') {
                $backup_page_model->saveBackupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-delete-attempt') {
                $backup_page_model->deleteBackupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-list') {
                $res->post_template[] = $snapshot_page_model->showDatabaseSnapshotList($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-create') {
                $res->post_template[] = $snapshot_page_model->showDatabaseSnapshotCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-save-attempt') {
                $snapshot_page_model->saveSnapshotAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-delete-attempt') {
                $snapshot_page_model->deleteSnapshotAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'subnetgroup-list') {
                $res->post_template[] = $subnetgroup_page_model->showDatabaseSubnetGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'subnetgroup-create') {
                $res->post_template[] = $subnetgroup_page_model->showDatabaseSubnetGroupCreate($pageVars);
//            $res->post_template[] = $subnetgroup_page_model->showDatabaseSubnetGroupSizeDropDown($pageVars);
//            $res->post_template[] = $subnetgroup_page_model->showDatabaseSubnetGroupImageDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'subnetgroup-save-attempt') {
                $subnetgroup_page_model->saveSubnetGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'subnetgroup-delete-attempt') {
                $subnetgroup_page_model->deleteSubnetGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'parametergroup-list') {
                $res->post_template[] = $parametergroup_page_model->showDatabaseParameterGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'parametergroup-create') {
                $res->post_template[] = $parametergroup_page_model->showDatabaseParameterGroupCreate($pageVars);
//            $res->post_template[] = $parametergroup_page_model->showDatabaseParameterGroupSizeDropDown($pageVars);
//            $res->post_template[] = $parametergroup_page_model->showDatabaseParameterGroupImageDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'parametergroup-save-attempt') {
                $parametergroup_page_model->saveParameterGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'parametergroup-delete-attempt') {
                $parametergroup_page_model->deleteParameterGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'optiongroup-list') {
                $res->post_template[] = $optiongroup_page_model->showDatabaseOptionGroupList($pageVars);
            } else if ($pageVars['route']['action'] == 'optiongroup-create') {
                $res->post_template[] = $optiongroup_page_model->showDatabaseOptionGroupCreate($pageVars);
//            $res->post_template[] = $optiongroup_page_model->showDatabaseOptionGroupSizeDropDown($pageVars);
//            $res->post_template[] = $optiongroup_page_model->showDatabaseOptionGroupImageDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'optiongroup-save-attempt') {
                $optiongroup_page_model->saveOptionGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'optiongroup-delete-attempt') {
                $optiongroup_page_model->deleteOptionGroupAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'reservation-list') {
                $res->post_template[] = $reservation_page_model->showDatabaseReservationList($pageVars);
            } else if ($pageVars['route']['action'] == 'reservation-create') {
                $reservation_page_model = new \Model\Database\ReservationPageModel();
                $res->post_template[] = $reservation_page_model->showDatabaseReservationCreate($pageVars);
                $res->post_template[] = $instances_page_model->showDatabaseInstanceSizeDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'reservation-save-attempt') {
                $reservation_page_model->saveReservationAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'reservation-delete-attempt') {
                $reservation_page_model->deleteReservationAttemptToDatabase($pageVars);
                $res->view = 'Database.php';
                $res->control = 'Database';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showDatabaseLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $instances_page_model->bindButtons();
            $res->post_template[] = $query_page_model->bindButtons();
            $res->post_template[] = $performancechart_page_model->bindButtons();
            $res->post_template[] = $backup_page_model->bindButtons();
            $res->post_template[] = $snapshot_page_model->bindButtons();
            $res->post_template[] = $subnetgroup_page_model->bindButtons();
            $res->post_template[] = $parametergroup_page_model->bindButtons();
            $res->post_template[] = $optiongroup_page_model->bindButtons();

            \ISOPHP\js_core::$console->log('Database Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}