<?php

Namespace Model\Mindstorm;

use ISOPHP\core;

class SnapshotPageModel extends \Model\Base {

// $bind = \Model\Mindstorm\PageModel::bindButtons();
    public function showMindstormSnapshotCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MindstormView();
            $view->loadTemplate('Mindstorm', 'SnapshotCreate.php', $vars);
            $bind = \Model\Mindstorm\PageModel::bindButtons();
            $view->displayMindstorm($bind);
            \ISOPHP\js_core::$console->log('apply Mindstorm Snapshot Create 2', $vars);
        };
    }

    public function showMindstormSnapshotList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mindstorm/snapshot/all';
            $afterwards = $this->applyMindstormSnapshotListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormSnapshotListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Snapshot List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Snapshot List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'SnapshotList.php', $vars);
                $bind = \Model\Mindstorm\PageModel::bindButtons();
                $view->displayMindstorm($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Snapshot List 2', $vars);
                $page_model = new \Model\Mindstorm\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Snapshot List failed');
            }
        };
        return $func;
    }

    /**
     * Save snapshot
     */
    public function saveSnapshotAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('save snapshot to mindstorm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'snapshot-create';
        $vars['api_uri'] = '/api/mindstorm/snapshot/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save snapshot vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSnapshotResultFromMindstorm($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSnapshotResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save mindstorm snapshot result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['snapshot']['mindstorm_snapshot_name'].' for Instance '.$request_data['snapshot']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm snapshot saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['snapshot']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm snapshot save failed');
            }
        };
        return $func;
    }
    /**
     * Delete snapshot
     */
    public function deleteSnapshotAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete snapshot to mindstorm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'snapshot-delete';
        $vars['api_uri'] = '/api/mindstorm/snapshot/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['snapshot_id'] = $jQuery('#snapshot_name')->val();
        \ISOPHP\js_core::$console->log('delete snapshot vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteSnapshotResultFromMindstorm($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSnapshotResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete mindstorm snapshot result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['snapshot']['mindstorm_snapshot_name'].' for Instance '.$request_data['snapshot']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm snapshot deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['snapshot']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm snapshot delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete snapshot
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_mindstorm_snapshot_list = $jQuery('.go-mindstorm-snapshot-list');
                $go_mindstorm_snapshot_list->off() ;
                $go_mindstorm_snapshot_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'snapshot-list', array(), '/mindstorm/snapshot/list');
                });

                $go_mindstorm_snapshot_create = $jQuery('.go-mindstorm-snapshot-create');
                $go_mindstorm_snapshot_create->off() ;
                $go_mindstorm_snapshot_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'snapshot-create', array(), '/mindstorm/snapshot/create');
                });

                $save_snapshot = $jQuery('.attempt-snapshot-create');
                $save_snapshot->off() ;
                $save_snapshot->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'snapshot-save-attempt', array());
                });

                $snapshot_delete = $jQuery('.attempt-snapshot-delete');
                $snapshot_delete->off() ;
                $snapshot_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $snapshot_name = $jqThis->attr('data-mindstorm-snapshot-name') ;
                    $snapshot_id = $jqThis->attr('data-mindstorm-snapshot-id') ;
                    \ISOPHP\js_core::$console->log('Clicked snapshot delete button');
                    if ($snapshot_name !== '') {
                        $name_or_id = $snapshot_name ;
                    } else {
                        $name_or_id = $snapshot_id ;
                    }
                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $snapshot_id) {
                        $navigate->route('Mindstorm', 'snapshot-delete-attempt', array('snapshot_id' => $snapshot_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}