<?php

\Core\View::$template = function () {

    $html = '
            <div class="fullRow"> 
                <div class="col-sm-12 offset-sm-12" >
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-securitygroup-create" class="btn btn-success attempt-securitygroup-create">Create</span>
                        <span id="go-sc1-securitygroup-list" class="btn btn-warning go-sc1-securitygroup-list">Security Group List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                                    
                    <div>
                        <h4>New Security Group</h4>
                    </div>

                    <div class="col-sm-12 col-12">
                        <label>Name</label>
                        <input type="text" name="name" class="form-control" id="name" value="" />
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>VPC</label>
                        <div id="svpc_vpc_dropdown">
                            <select class="form-control" name="vpcid" id="vpcid">
                                <option>Loading VPCs</option>
                            </select>
                        </div>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <h5>Inbound Rules</h5>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>New Inbound Rule</label>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-2 col-2">
                            Type
                        </div>
                        <div class="col-sm-1 col-1">
                            Protocol
                        </div>
                        <div class="col-sm-1 col-1">
                            Port Range
                        </div>
                        <div class="col-sm-4 col-4">
                            Source
                        </div>
                        <div class="col-sm-2 col-2">
                            Description
                        </div>
                        <div class="col-sm-2 col-2">
                            &nbsp;
                        </div>
                    </div>
               
                    <div class="col-sm-12 col-12 inbound_vars_wrap">
                        <div class="col-sm-2 col-2">
                            <select name="inbound_vars_type" id="inbound_vars_type" class="inbound_vars_type">
                                <option value="none">Please Choose</option>
                                <option value="customtcp">Custom TCP Rule</option>
                                <option value="customudp">Custom UDP Rule</option>
                                <option value="http">HTTP</option>
                                <option value="https">HTTPS</option>
                                <option value="alltcp">All TCP</option>
                                <option value="alludp">All UDP</option>
                                <option value="allicmp">All ICMP</option>
                                <option value="alltraffic">All traffic</option>
                                <option value="ssh">SSH</option>
                                <option value="smtp">SMTP</option>
                                <option value="dnsudp">DNS (UDP)</option>
                                <option value="dnstcp">DNS (TCP)</option>
                                <option value="pop3">POP3</option>
                                <option value="imap">IMAP</option>
                                <option value="mysql">MySQL</option>
                                <option value="postgresql">PostgreSQL</option>
                            </select>
                        </div>
                        <div class="col-sm-1 col-1">
                            <select name="inbound_vars_protocol" id="inbound_vars_protocol" class="inbound_vars_protocol">
                                <option value="tcp">TCP</option>
                                <option value="udp">UDP</option>
                            </select>
                        </div>
                        <div class="col-sm-1 col-1">
                            <input type="text" class="input_sg_portrange" name="inbound_vars_portrange" id="inbound_vars_portrange" class="inbound_vars_portrange" />
                        </div>
                        <div class="col-sm-4 col-4">
                            <div class="leftCell">
                                <select name="inbound_vars_source_type" id="inbound_vars_source_type" class="inbound_vars_source_type">
                                    <option value="custom">Custom</option>
                                    <option value="anywhere">Anywhere</option>
                                    <option value="myip">My IP</option>
                                </select>
                            </div>
                            <div class="rightCell">
                                <input type="text" name="inbound_vars_source_data" id="inbound_vars_source_data" class="inbound_vars_source_data" />
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <input type="text" name="inbound_vars_description" id="inbound_vars_description" class="inbound_vars_description" />
                        </div>
                        <div class="col-sm-2 col-2">
                            <span id="add-securitygroup-outbound-rule" class="btn btn-success add-securitygroup-inbound-rule">
                                Add Rule
                            </span>
                        </div>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>Existing Inbound Rules</label>
                    </div>
               
                    <div class="col-sm-12 col-12 current_rules_inbound">
               
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-2 col-2">
                                Type
                            </div>
                            <div class="col-sm-1 col-1">
                                Protocol
                            </div>
                            <div class="col-sm-1 col-1">
                                Port Range
                            </div>
                            <div class="col-sm-4 col-4">
                                Source
                            </div>
                            <div class="col-sm-2 col-2">
                                Description
                            </div>
                            <div class="col-sm-2 col-2">
                                Add
                            </div>
                        </div>
               
                        <div class="col-sm-12 col-12 current_rules_inbound_inner" id="current_rules_inbound_inner">
                        None Yet
                        </div>
                        
                    </div>

                    <div class="col-sm-12 col-12">
                        <h5>Outbound Rules</h5>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>New Outbound Rule</label>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-2 col-2">
                            Type
                        </div>
                        <div class="col-sm-1 col-1">
                            Protocol
                        </div>
                        <div class="col-sm-1 col-1">
                            Port Range
                        </div>
                        <div class="col-sm-4 col-4">
                            Destination
                        </div>
                        <div class="col-sm-2 col-2">
                            Description
                        </div>
                        <div class="col-sm-2 col-2">
                            Add
                        </div>
                    </div>
               
                    <div class="col-sm-12 col-12 outbound_vars_wrap">
                        <div class="col-sm-2 col-2">
                            <select name="outbound_vars_type" id="outbound_vars_type" class="outbound_vars_type">
                                <option value="customtcp">Custom TCP Rule</option>
                                <option value="customudp">Custom UDP Rule</option>
                                <option value="http">HTTP</option>
                                <option value="https">HTTPS</option>
                                <option value="alltcp">All TCP</option>
                                <option value="alludp">All UDP</option>
                                <option value="allicmp">All ICMP</option>
                                <option value="alltraffic">All traffic</option>
                                <option value="ssh">SSH</option>
                                <option value="smtp">SMTP</option>
                                <option value="dns-udp">DNS (UDP)</option>
                                <option value="dns-tcp">DNS (TCP)</option>
                                <option value="pop3">POP3</option>
                                <option value="imap">IMAP</option>
                                <option value="mysql">MySQL</option>
                                <option value="postgreSQL">PostgreSQL</option>
                            </select>
                        </div>
                        <div class="col-sm-1 col-1">
                            <select name="outbound_vars_protocol" id="outbound_vars_protocol" class="outbound_vars_protocol">
                                <option value="tcp">TCP</option>
                                <option value="udp">UDP</option>
                            </select>
                        </div>
                        <div class="col-sm-1 col-1">
                            <input type="text" class="input_sg_portrange" name="outbound_vars_portrange" id="outbound_vars_portrange" />
                        </div>
                        <div class="col-sm-4 col-4">
                            <div class="leftCell oneThird">
                                <select name="destination_type" id="outbound_vars_destination_type" class="outbound_vars_destination_type">
                                    <option value="custom">Custom</option>
                                    <option value="anywhere">Anywhere</option>
                                    <option value="myip">My IP</option>
                                </select>
                            </div>
                            <div class="rightCell twoThirds">
                                <input type="text" name="outbound_vars_destination_data" id="outbound_vars_destination_data" />
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <input type="text" name="outbound_vars_description" id="outbound_vars_description" class="outbound_vars_description" />
                        </div>
                        <div class="col-sm-2 col-2">
                            <span id="add-securitygroup-outbound-rule" class="btn btn-success add-securitygroup-outbound-rule">
                                Add Rule
                            </span>
                        </div>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>Existing Outbound Rules</label>
                    </div>
               
                    <div class="col-sm-12 col-12 current_rules_outbound_wrap">
               
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-2 col-2">
                                Type
                            </div>
                            <div class="col-sm-1 col-1">
                                Protocol
                            </div>
                            <div class="col-sm-1 col-1">
                                Port Range
                            </div>
                            <div class="col-sm-4 col-4">
                                Destination
                            </div>
                            <div class="col-sm-2 col-2">
                                Description
                            </div>
                            <div class="col-sm-2 col-2">
                                &nbsp;
                            </div>
                        </div>
               
                        <div class="col-sm-12 col-12 current_rules_outbound_inner" id="current_rules_outbound_inner">
                        None Yet
                        </div>
                        
                    </div>

                </div>
            </div>';

    $html = $html . '    
                           
        <div class="col-sm-12 col-12 text-center">
            <span id="attempt-securitygroup-create" class="btn btn-success attempt-securitygroup-create">Create</span>
            <span id="go-sc1-securitygroup-list" class="btn btn-warning go-sc1-securitygroup-list">Security Group List</span>
            <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
        </div> ';

    return $html;

};
