<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-scheduledinstance-create" class="btn btn-success attempt-scheduledinstance-create">Create</span>
                        <span id="go-mindstorm-scheduledinstance-list" class="btn btn-warning go-mindstorm-scheduledinstance-list">Snapshot List</span>
                        <span id="go-mindstorm-landing" class="btn btn-warning go-mindstorm-landing">Mindstorm Home</span>
                    </div>
                
                    <div>
                        <h4>New Snapshot</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Size</label>
                                <div id="mindstorm_size_dropdown">
                                    <select class="form-control" name="instance_name" id="instance_name">
                                        <option>Loading Sizes</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control" id="name" value="" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Start Time</label>
                                <input type="text" name="start_time" class="form-control datepicker-dropdown" id="start_time" value="" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>End Time</label>
                                <input type="text" name="end_time" class="form-control datepicker-dropdown" id="end_time" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea name="description" id="description" class="form-control"></textarea>
                            </div>
                        </div>
                        
                    </div>
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-scheduledinstance-create" class="btn btn-success attempt-scheduledinstance-create">Create</span>
                        <span id="go-mindstorm-scheduledinstance-list" class="btn btn-warning go-mindstorm-scheduledinstance-list">Snapshot List</span>
                        <span id="go-mindstorm-landing" class="btn btn-warning go-mindstorm-landing">Mindstorm Home</span>
                    </div>
                    
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating Mindstorm Volumes</strong></h4>
                     
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_scheduledinstance_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_scheduledinstance_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_scheduledinstance_awscli_aws" class="instruction_set instruction_set_create_scheduledinstance_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com --caller-reference 123451234512345 --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceVolumeSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>

             </div>';

    return $html;

};
