<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('InstanceTerminal pagevars: ', $pv);
    $rqd = $pv['request_data'];


    $html = $html . '</div>' ;
    $html = $html . '<script> </script>' ;

    return $html;

};