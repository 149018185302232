<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $sizes_are_array = \ISOPHP\core::$php->is_array($rqd['sizes']) ;
    if ($sizes_are_array === true) {
        $sizes_exist = true ;
    } else {
        $sizes_exist = false ;
    }
    if ($sizes_exist === true) {
        $size_count = \ISOPHP\core::$php->count($rqd['sizes']) ;
    } else {
        $size_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-size-list" class="btn-refresh btn btn-info go-sc1-size-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Instance Sizes</h4>
         </div> ' ;

    if ($rqd['page_count'] > 1) {
        $html = $html .
            '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-size-paginator') ;
        $html = $html .'</div>' ;
    }

    $html = $html .
       '<div class="col-sm-12 col-12 one_sc1_size item_list_sc1_sizes page_item_list_title" id="item_list_sc1_sizes">
            <div class="col-sm-2 col-2">
                <h5 class="sc1_size_id_title">Slug</h5>
            </div>
            <div class="col-sm-2 col-4">
                <h5 class="sc1_size_id_title">Description</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_size_id_title">Cores</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_size_id_title">Memory</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_size_id_title">HDD Size</h5>
            </div>
        </div>';

    if ($sizes_exist === true && $size_count !== false) {

        foreach ($rqd['sizes'] as $one_sc1_size) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sc1_size">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_sizes_slug"><span class="sc1_name sc1_listing">' . $one_sc1_size['slug'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-4">
                        <p class="sc1_list_description"><span class="sc1_id sc1_listing">' . $one_sc1_size['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_sizes_cores">' . $one_sc1_size['cores'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_sizes_mem">' . $one_sc1_size['mem'] . '</p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_sizes_hdd">' . $one_sc1_size['hdd'] . '</p>
                    </div>                      
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-size-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no available Sizes</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-size-list" class="btn-refresh btn btn-info go-sc1-size-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Sizes</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_size_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_size_awscli"></i></h5>
                    <pre id="instruction_set_list_size_awscli" class="instruction_set instruction_set_list_size_awscli">
aws ec2 describe-size-types --profile=pharaoh --region--endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             
             </div>';


    return $html;

};
