<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $sitetositevpns_are_array = \ISOPHP\core::$php->is_array($rqd['sitetositevpns']) ;
    if ($sitetositevpns_are_array === true) {
        $sitetositevpns_exist = true ;
    } else {
        $sitetositevpns_exist = false ;
    }
    if ($sitetositevpns_exist === true) {
        $sitetositevpn_count = \ISOPHP\core::$php->count($rqd['sitetositevpns']) ;
    } else {
        $sitetositevpn_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-sitetositevpn-create" class="btn btn-success go-svpc-sitetositevpn-create">Create Site To Site VPN</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-sitetositevpn-list" class="btn btn-info go-svpc-sitetositevpn-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Site To Site VPN List</h4>
         </div> ' ;

    if ($sitetositevpns_exist === true && $sitetositevpn_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_svpc_sitetositevpn">
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">Site To Site VPN ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">VPC ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">Subnet Assoc.</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">Edge Assoc.</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">Main</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="svpc_sitetositevpn_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['sitetositevpns'] as $one_svpc_sitetositevpn) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_sitetositevpn['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_svpc_name"><span class="svpc_svpc_name svpc_listing">' . $one_svpc_sitetositevpn['vpc_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_sitetositevpn_name"><span class="name svpc_listing">' . $one_svpc_sitetositevpn['subnet_associations'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_sitetositevpn_data"><span class="svpc_sitetositevpn_data svpc_listing">' . $one_svpc_sitetositevpn['edge_associations'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_sitetositevpn_type"><span class="svpc_type svpc_listing">' . $one_svpc_sitetositevpn['main'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_svpc_sitetositevpn_' . $one_svpc_sitetositevpn['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-sitetositevpn-edit btn btn-outline-primary"
                                   data-svpc-sitetositevpn-id="' . $one_svpc_sitetositevpn['id'] . '"
                                   data-svpc-sitetositevpn-name="' . $one_svpc_sitetositevpn['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_svpc_sitetositevpn_' . $one_svpc_sitetositevpn['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-sitetositevpn-delete btn btn-outline-danger"
                                   data-svpc-sitetositevpn-id="' . $one_svpc_sitetositevpn['id'] . '"
                                   data-svpc-sitetositevpn-name="' . $one_svpc_sitetositevpn['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Site To Site VPNs</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-sitetositevpn-create" class="btn btn-success go-svpc-sitetositevpn-create">Create Site To Site VPN</span>
            <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-sitetositevpn-list" class="btn btn-info go-svpc-sitetositevpn-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};