<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $objects_are_array = \ISOPHP\core::$php->is_array($rqd['objects']) ;
    if ($objects_are_array === true) {
        $objects_exist = true ;
    } else {
        $objects_exist = false ;
    }
    if ($objects_exist === true) {
        $object_count = \ISOPHP\core::$php->count($rqd['objects']) ;
    } else {
        $object_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-artifact-object-create" class="btn btn-success go-artifact-object-create">Create New Object</span>
            <span id="go-artifact-landing" class="btn btn-warning go-artifact-landing">Artifact Home</span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Object List</h4>
         </div> ' ;

    if ($objects_exist === true && $object_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_artifact_object">
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Artifact Object ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Repository</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="artifact_object_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['objects'] as $one_artifact_object) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="artifact_list_id"><span class="artifact_id artifact_listing">' . $one_artifact_object['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="artifact_list_repository_name"><span class="artifact_repository_name artifact_listing">' . $one_artifact_object['repository_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="artifact_list_object_name"><span class="artifact_object_name artifact_listing">' . $one_artifact_object['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="artifact_list_object_data"><span class="artifact_object_data artifact_listing">' . $one_artifact_object['value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="artifact_list_object_type"><span class="artifact_type artifact_listing">' . $one_artifact_object['type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="edit_artifact_object_' . $one_artifact_object['id'] . '"
                                   class="fa fa-1x fa-info-square hvr-grow dom attempt-object-edit btn btn-outline-primary"
                                   data-artifact-object-id="' . $one_artifact_object['id'] . '"
                                   data-artifact-object-name="' . $one_artifact_object['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="edit_artifact_object_' . $one_artifact_object['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-object-edit btn btn-outline-primary"
                                   data-artifact-object-id="' . $one_artifact_object['id'] . '"
                                   data-artifact-object-name="' . $one_artifact_object['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_artifact_object_' . $one_artifact_object['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-object-delete btn btn-outline-danger"
                                   data-artifact-object-id="' . $one_artifact_object['id'] . '"
                                   data-artifact-object-name="' . $one_artifact_object['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {


    }

    return $html;

};