<?php

Namespace Controller;

class PCSController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('PCS controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In PCS, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\PCS\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'PCS.php';
            $res->type = 'view';
            $res->view_control = 'PCS';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showPCSLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'definition-list') {
                $res->post_template[] = $page_model->showPCSDefinitionList($pageVars);
            } else if ($pageVars['route']['action'] == 'definition-create') {
                $res->post_template[] = $page_model->showPCSDefinitionCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'container-list') {
                $res->post_template[] = $page_model->showPCSDefinitionListDropDown($pageVars);
                $res->post_template[] = $page_model->bindDefinitionListDropDownButtonsForContainerList();
                $res->post_template[] = $page_model->showPCSContainerList($pageVars);
            } else if ($pageVars['route']['action'] == 'container-create') {
                $res->post_template[] = $page_model->showPCSContainerCreate($pageVars);
                $res->post_template[] = $page_model->showPCSDefinitionListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindDefinitionListDropDownButtonsForContainerCreate();
            } else if ($pageVars['route']['action'] == 'definition-save-attempt') {
                $page_model->saveDefinitionAttemptToPCS($pageVars);
                $res->view = 'PCS.php';
                $res->control = 'PCS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'container-save-attempt') {
                $page_model->saveContainerAttemptToPCS($pageVars);
                $res->view = 'PCS.php';
                $res->control = 'PCS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'definition-delete-attempt') {
                $page_model->deleteDefinitionAttemptToPCS($pageVars);
                $res->view = 'PCS.php';
                $res->control = 'PCS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'container-delete-attempt') {
                $page_model->deleteContainerAttemptToPCS($pageVars);
                $res->view = 'PCS.php';
                $res->control = 'PCS';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showPCSLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('PCS Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}