<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars ;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv) ;
    $rqd = $pv['request_data'] ;

    $keypairs_are_array = \ISOPHP\core::$php->is_array($rqd['keypairs']) ;
    if ($keypairs_are_array === true) {
        $keypairs_exist = true ;
    } else {
        $keypairs_exist = false ;
    }
    if ($keypairs_exist === true) {
        $keypair_count = \ISOPHP\core::$php->count($rqd['keypairs']) ;
    } else {
        $keypair_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-keypair-create" class="btn btn-success go-sc1-keypair-create">New Key Pair</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-keypair-list" class="btn-refresh btn btn-info go-sc1-keypair-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>SSH Keypairs</h4>
        </div> ' ;

    if ($keypairs_exist === true && $keypair_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-keypair-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_keypair item_list_sc1_keypairs" id="item_list_sc1_keypairs">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">Fingerprint</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_keypair_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>
                 <div class="col-sm-12 col-12 sahara_table page_item_list">';
        foreach ($rqd['keypairs'] as $one_sc1_keypair) {
            $html = $html .
                   '<div class="col-sm-12 col-12 one_sc1_keypair">      
                        <div class="col-sm-2 col-2">
                            <span id="sc1_list_id" class="sc1_id sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->substr($one_sc1_keypair['id'], 0, 10) ;

            $html = $html .
                           '</span>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_instance_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_keypair['name'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_keypair_name"><span class="sc1_keypair_name sc1_listing">' . $one_sc1_keypair['fingerprint_short'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_keypair_data"><span class="sc1_keypair_data sc1_listing">' . $one_sc1_keypair['description'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_keypair_type"><span class="sc1_type sc1_listing">' . $one_sc1_keypair['format'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="col-sm-12 col-12">
                                <div class="col-sm-6 col-6">
                                    <i id="edit_sc1_keypair_' . $one_sc1_keypair['id'] . '"
                                       class="fa fa-1x fa-edit hvr-grow dom attempt-keypair-edit btn btn-outline-primary"
                                       data-sc1-keypair-id="' . $one_sc1_keypair['id'] . '"
                                       data-sc1-keypair-name="' . $one_sc1_keypair['name'] . '"></i>
                                </div>
                                <div class="col-sm-6 col-6">
                                    <i id="delete_sc1_keypair_' . $one_sc1_keypair['id'] . '"
                                       class="fa fa-1x fa-close hvr-grow dom attempt-keypair-delete btn btn-outline-danger"
                                       data-sc1-keypair-id="' . $one_sc1_keypair['id'] . '"
                                       data-sc1-keypair-name="' . $one_sc1_keypair['name'] . '"></i>
                                </div>
                            </div>
                        </div>
                    </div>';
        }
        $html = $html .
                '</div>';

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-keypair-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Key Pairs</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-keypair-create" class="btn btn-success go-sc1-keypair-create">New Key Pair</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-keypair-list" class="btn-refresh btn btn-info go-sc1-keypair-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Key Pairs</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_keypair_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_keypair_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_keypair_awscli_aws" class="instruction_set instruction_set_list_keypair_awscli_aws">
aws ec2 list-keypairs --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
             </div>';


    return $html;

};