<?php

$variables = array() ;
$variables['application_slug'] = 'saharaws' ;
$variables['description'] = 'Sahara Web Services Client' ;
$variables['subdomain'] = 'www' ;
$variables['webclientsubdomain'] = 'www' ;
$variables['server_subdomain'] = 'server' ;
$variables['admin_subdomain'] = 'admin' ;
$variables['crm_subdomain'] = 'crm' ;
$variables['domain'] = 'saharaws.com' ;
$variables['friendly_app_slug'] = 'SaharaWebServices' ;
$variables['desktop_app_slug'] = $variables['friendly_app_slug'] ;
$variables['random_port_suffix'] = '59' ;
$variables['infra_type'] = 'cloud' ;
$variables['backendenv'] = 'local' ;


if (isset($params)) {
    $variables = $params ;
} else {
    $variables = array() ;
}

$variables["subdomain"] = "www" ;
$variables['linux_one_ip'] ='148.100.5.126' ;

# Conditional Params
if (isset($params["run_type"]) && $params["run_type"]=="add") {
    $variables["run_type_add"] = true ; }
else if (isset($params["run_type"]) && $params["run_type"]=="remove")  {
    $variables["run_type_remove"] = true ; }

$variables['android_shell_prefix'] = '' ;
$variables['infra_type'] = 'cloud' ;
$variables['pharaoh_source_key'] = 'atfsp0ray275kwo8' ;
$variables['pharaoh_source_secret'] = '5aja1uhds3kixgzx' ;

$variables['client_subdomain_suffix'] = '' ;
$variables['is_service'] = false ;

if (isset($variables['client_slug']) && $variables['client_slug'] =='pharaoh_tools') {
    $variables['domain'] = 'pharaohtools.com' ;
    $variables['company_name'] = 'Pharaoh Tools Ltd.' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='amanshia_bacon') {
//    $variables['domain'] = 'amanshiabacon.com' ;
    $variables['client_subdomain_suffix'] = '.clients.amanshiabacon' ;
    $variables['domain'] = 'pharaohtools.com' ;
    $variables['company_name'] = 'Amanshia Bacon Ltd.' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='laughing_babies') {
    $variables['domain'] = 'laughingbabies.co.uk' ;
    $variables['company_name'] = 'Laughing Babies' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='job_jacker') {
    $variables['domain'] = 'jobjacker.com' ;
    $variables['company_name'] = 'Job Jacker Ltd.' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='genieholidays') {
//    $variables['domain'] = 'genieholidays.co.uk' ;
    $variables['client_subdomain_suffix'] = '.clients.genieholidays' ;
    $variables['domain'] = 'pharaohtools.com' ;
    $variables['company_name'] = 'Genie Holidays Ltd.' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='tote_international') {
//    $variables['domain'] = 'toteinternational.com' ;
    $variables['client_subdomain_suffix'] = '.clients.toteinternational' ;
    $variables['domain'] = 'pharaohtools.com' ;
    $variables['company_name'] = 'Tote International Ltd.' ;
} else if (isset($variables['client_slug']) && $variables['client_slug'] =='imperial_invites') {
    $variables['domain'] = 'imperialinvites.co.uk' ;
    $variables['company_name'] = 'Imperial Invites Ltd.' ;
}

$variables['sphinx_deployment_key_private'] = '-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAtceyQ5SwwrvOAHooJL9dVZ9i+6eZPArbwNHjuqi8Z5r1RDVH
L50xlS+jB1KzRMEqUBj5wbVCp+HgbuatWOb+ci2PDjbDLwpKw/tHXi6dLeQW5vOU
jmyTpfVR8RDQwrW1ItJyn2Tz1cotZQX/sZ0Kki/09kgtEYgbC+sPns1w+GAil0sl
Vy7PcpTIkUA02dSSos2LGjLBCjZ8Uj4cWb8Q8mNkf+Q1xFuL8tHWjAWrgVRRxPuK
WQkiSaSh8ItEfZ2CVyghSTuYx2y3bIdAmobUccxVWpQtEcdprH0ygK80jGd335AL
u2r9y7rJgjxHWsFSyj3hpjFwBEiPrswHYtUXKaeRNK3fo2Mvck9r1rAw+CPiyDFD
OkEYpSBGHdWycmdafs6tvkt/NNO7Krzzyv8plNLlfVREvubblQyQxtcDiQ3JUuU4
pHatoeZ6sVbHc7I3eHQkdiqwEoJCzX2m70KxsGByqNdg203vQkQS9Y00jKy34Mqq
K8a0tzaSzmaD+hzpTYqCt8jnwHsIbV9xGG5lRu2euA13XdXDJE8VsN4qrX8xZafC
qIWsgLcHVJ96oeqXlMYSt0JreULtCfSASELj5xTiXadGEzxYCqgAfpD57XEMSbnG
KUU1KTptsRCBuGTAI7lghSSJFdDHPHftXmXSrOvPeHb8To50adQu99DOpKUCAwEA
AQKCAgBX9l8zxqLWSIzBvFrqlVNz2GHMhuhmnpaCMs6xB2/L7rcan3Fn//f7Sgbo
x0MKVTLFeaB59N1hYbL511Qwve6Ij7U22pcOjztopJJ/Z60VhNb0QRA07yNvXkud
jFaIsecKB64BpZc8b84RoZp+dxCjduNhvcFZR3RXCxMO9DjjZ5m1fl5+Vp95wbdg
WiJ4+h1O/ImATukMqRr1rSqI3yUzNZdvL544+1e4U4QvU5sPRR8gY9wpg3r9CdZ3
EN0+r9ZfmIKq7lTaw6fqs3Q2kmIBAzCWWTeJ7+YZ2qdyj9Wsz3C5VPnoYjiXujZC
eCrLJtuKE/G6OxgKapZkrkQwtg6zfo7fWRwC8eN8qtz1zQSWBZ9BjVcbeRWQSoak
ipdZLJmfX5wz3nVphhfH1P+m/NOt5urcDCRvHk0OHGsRpLaMFrMy3noF3gaCIQLs
n9nGyUpBZMfoDt7hYi2qnN50TACuMXitLWbqMcspjxgFl3M0hU/0Nb2Sw/uBzaAO
0o+OYhG1e2ppO/V2mm77G2hv2bDaNeFso/IPpMh1XInheO0VRhNbzTQ7RWXe/Pll
Hy0QLNnUQ0QjQ5aHZhBsTHaP4mdv9RTkGIzpzIoO/6RiBPIDBeVXAUqqvC3o1Dv5
c37p9YJcuyquMXDNXeRANYwFNg7ezj50dEiJeM5ShimRiHOB4QKCAQEA7uArX/4z
mzbq0mxhUCXxjWWOBJhwtZaU3PiHyeuLee324FWUqIpvJRPCHvo9BMvY0hff8CKW
2GAXgxsx3Qc0I2DZgBnLvug1MuzynISK34/pbVgimXar0tm0KhODT799m2ahFyFm
5g2V3NLiQxmLdB+PolkGqiBbPlNKFqvNgu/2qSZTzs2lhGjwD5qgRZ8wYzPweiZN
4vxzxQiRU+VBcGwQaUxG+k30vlNiflGTMv08/S9bu+D3X8maoqOWS0EUIYnhxOcy
3x6F8IS/iuBG1FH8sWCTXQKYOY955YdruEfZyrqxOPj98F/ZpGB7YT1fTlFDNdgL
IPYGgmmjgQDqCQKCAQEAws+2VANCcoVfOnfaXG58Rg/FrUeDsWQ7pKxCOGbuYwIW
qlJazuqJWmZco7G5/B7cVAokVmHaihoJe/wqG5brdLGtnjOIiMlrURou+eKA9GvI
/oFgITZMnGIsq/OzKPd+AuYVsw6MG9Qd9rl0WSNPvsVEm3zWNzEoxgPC5njrnRTD
0wylaKn7tTc41rDdtYKo+/dnl3XPqTg3+l5hQEJJ1FZzlWbi4iV++ecRSt51JgiF
rPAwOCbSSJgBZAjAZJpOApZp99GjjydTtBslgiPzk0GBno/u4+wJrTDthxSzXgs+
sPBjwNHN3Pv3P0Q+xrc3aDicOFOgSjHb5JdDOvn8vQKCAQBv01F65q2SA+t6BnKn
6pth7iDF1fctiwi1SUOIK1RoCBaCfo3ROL0/Rjih99OI40TlVXfoGskzEgjvv1Ng
fXbS+QvZBDp1bI86/+Gzk/jPH7fPEGtiHEPjuTa2W9wlASf0D99pzKE3ITT+41o4
v0ShYAUFo1eTXQJZ8vPEGTlkCqIWHXMuZOcPTXDUXTaaYSNtzWrORBBCKpynqJMg
CzrPPkeQg7zWgJgKYU7Cg729KP/cJsdy9KXZma9iY+J/6D9cVAf0/aa6/LcHGZ3x
jXya+KarAuL5r7VDfP87jGHJKxmx5iQA389BMB3a7a/Unb54E2K7WiAblhEGDB4W
zi7BAoIBAQCNyABpuomUsY6yDeKFXP/jOb1jtGWbKFjkK15ykC81LaLzSMbASx+2
BEOt+z5hyNAP9eWmXMdhtTtr2gWHWNp6bonuVpErBHSvbP/9a6TwvrVkzSH6aftt
4WGjr9SXnzA9ydjHHXJJGqLIGwcbjMCQFiAtjbiWKJgFLV2ewSixhRhUNKL5vqTr
qYobblOds79qyg/BwsNk4/6RoDjlPJlhCZ5LgLrlXebA2DPUqnzWNmObjp95bn+i
x8ZTWK81SLw30+LBsvh3ymZ38T3mLmYqqQU+MoDprrMIMlDc++rjKgnxWrMu9UL/
bv3uNKv/kf6oQnMn520EMq9X+eK7PI99AoIBAQDbcHm7ET7Tpb6QdPK22ynbrQV9
0yrGQzqK2i/a3UMKZWRdq6nO85HSi3CKExAWunRDY/g1pVTgM7xNN4geaERuLi6U
H7+XXCwg8wwJXMvWdaQL6PdmdUntB+nKmDiiK5qYWyUqxHsF8TpIzS4NVs0MQHsY
Nd0+MI5eoBK2oEFtxCpzxl0+aXs/pKjZ7qMusVtLv/JGiTIzbJXYFBJSlq67lEN+
HqCVSJwZNLNmZ6bV8J6jiINhaICRmNwGF8Um7BYe4+uURFdtcdqOa/YbZ6/3Ivdy
RVnxAKEkuXHFSJj+bCXQ2v4Kg99N0cs+4U/iEG+quaUqqQwosRzDz47jR1KX
-----END RSA PRIVATE KEY-----';


$variables["devops_private_key"] = '-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEA0xo9+ksLUflaOisNr/8GX1rNZWPI8/Q6oU3mlDZrd3JCDFIv
yfRW/cyvhq2192LdSVpomaJKBJ+bxQUAoIsLCAfcQoqlY0jG/9JG729INQn8ECaN
MeGqJhVe+fkP/QBNk1l6zxB1J5XJP7+730tcO6p42EaEnTK8NnQxD0oxeT8pG9I3
zGXsO+G96sVtkBBSlKZmxmGqWI3CZBjss3BguC8Oih5/bg9ukVwzYVMpNX+HSllF
/FTHVbTF9C7/C3OnDJ6rMgSsP6HWDgsxSkgNEIzij0Fld9AtbJCzGjT6UfSGSfYJ
8iEUIH7cJP6+LynZn8gZjoI7wmaF6k7rLbuWYL52v12yL3W491jgDyURab9iMkjE
tzoT4CIZkyD/TZwVbFPMHIxJCNHs1MpiMpLcGy/R60fM0u304H+YLsMMWh2DDxOq
4K7dPIry8P/OEQH5ydt9jzvrIqbf4C6szZmKPS5Os9RJ6eWGUKVl/3852O3whWMH
/BsvWOKGj+Pf+enUv5Hmk4nc7ZpVanq4D9AIVSRtJ4ZHNhTXzQvUr20OF0OkrusN
vRjaxA6Mx1VzjjxWJmkgLZEqV2EincpYVxseYLsjeCkYO5EB06BnU72GKM+L7sZ1
HB2bB4xmoNF3GXD8LxSiGT/a8PC7HWNHzH+IJBBRJyGvuBCjx5s8e1ckTAkCAwEA
AQKCAgB6RhDwENeVu6CPYLCk6EqAoM+nkoyrASOUmtKbtom4ve2TxWb4yxY4G37v
u15S1GjoO/2qP14wH2LSU3txghf5ptPllvkE8PqS4vFDbmmcjxP5JttRsSwksuw4
ThL8sFg+qO2BUj8JlrDiw+PGCph51tv4KaBWjTSHNfXUrVzKpdLh0nmPYtnaNX8T
pRc5ArtSbq/I6HkKodGeuPEhXk4nYNpDFjcpQLcCay03ijusSRnL7rmi1lg/RF4E
edQwheSUYrM2XY6HrO2XZNQ/+kvW8kOoRnDHXUVyRL34yAbVwZSm8mQAYziNOe25
X/Q4zTzIV9a1vKlwiIG4nc2Kx8TpZkC4LoQl9LV0KFaxHOm/1eBJSJV2pOmUj9Fq
Dxm04rv9/0PO9dTOUX5y/I/kgXbiE7XzZmAyRUOnDkD7hPrDxjIK45DyUE1sfFou
kvj1Bq0Bdd5o5IH+jpGtPNTVf1n8+Yix6BM874UpKFwEM7lZZT1UkVkZvf6eNTwi
J6KawIgPiAe3Mki4A47PvGv/toW0wy5jQfJoweI0Tym2Ld96heOwJZ6khyjws/j/
zeJUlkDd3oBvkDfZeI+rDvv8UQwTDWx9K+LSrm7qQFnU15M8Wd1/tJim4YfCDmRq
d80JOWl/PVU1J3UgFvLQlj4lzLA3OLET7dg6UyACdSrUyaEKgQKCAQEA+7fgTu31
CI3lG3r2UaQn5kDkgxdA1DFmZMyMQr9v2BYHlo0iMaLXPy6lY6OWT1q8TY7yst96
YMxuxFc1yxxp96FUJowXwnyzodvdO7gIe3Fwr8xpY2KrLlLhDUnHG03p/nJ8Xj0q
TgwLiKbHRDoPZomvdNvn1xarT22zXlKDkFaR4ZJw15/zDeiylc7eNeXn0mxYTnzE
uLw6gKrfHk4Tlj6OOnVXNUMdJSIdE6IwIP5mD1gjIe3sbSd/SWrkYEqhLk4TN1N+
ZVxtMttHZpZBW0dOkAbRMEx8bfEvu/JcJ+7iVk1nIS4jjGnKQfvnkbP0XvjraJgh
G6myvmuHpz/lZQKCAQEA1rGAfF3n7cQEmt+qQUC3Fl0/5piyRKYl1dzLhJxP83gU
AXuGBgcd1qK/YK69z/jnQXxhpm/nfHJvxr7GiZ4ySTwXeCCiLX+UHtW44He9Uc7q
fyfnS9yqEtm+c91fKUP09r3DyKs5ZUne8d/iLfWcHHp2OrDw4UE4orouO5tdnl65
bDFlXembrEOVxmcyVyTi+40zrGVRxOphfhEDkHqTedVVxPF1HkE4+rk/OYyCQBnX
VpwVVAxjuITHb1iFChRM8m1phxs7n9jPsTqL3xw5zcLcn8u2mXmuMy+7Be4Lvcji
hVP6Roeh/MPd8LHV23+r/a8eOAMWQosel8bLu51D1QKCAQEAn8Sk9dQpa+WMXrXc
NBOKU6YqpF4VAtncEOyXtiIGHlRVOqSX6INgNbUouqZxWCrSTDQILaqU5W28fJiS
BzogtFFMtS7EQeiep4SC1k3H7HojOSH4XmGZH0zxlVqXquf4Nk16TOJrwJ/6jiC1
Z8OmVMHdgMG3VJkJ4N9TMvISmYGpFFC7mNDoF5L0Dwv/lJw5WSD1p9OflVW7mEFc
XsLVwc4wvkAOOzs++3u8kuKlw7tVEjYyDm4SW3HvY60aCVg1F8vsfHhdSH6yvM3b
cuy5zPLklZwOGKbjb3DfiF6bSalhNb/pTnEXlR7shpcZ8ywSVlmOKZQtp349eomL
z4dJ1QKCAQEAx/23W6K6XvE+/OonFfvr0WhyEyPgxDQlgI/aAaD+lSUhNbRUR58v
e3ZFYhHE/Uu2WHk+GaWadIHBf9jM5vs3iqLwldWIXWu/le2u92JzOBbQuDx6qF9Z
CpBNam8nqzADrClfjLl7kM/8X0IX1oo5rwmgwtF6wcpYsLVQRfRXhCRnA9MLwyzO
9+xJQ/VznlvdGUkSfqAvSIiw4peEw3x7jn1dFUV7vQAyzNPtgnVSC9ZGkwZ4k7+q
0QLXKPDDlX0NpLKpH9MNeryj1EteaipH6lV/4VcJtPP1gvcOd9qkk1jjMiHlPPgx
vFzUjNeMCJlopLsRIko13SztBaOIuy8nNQKCAQAO9rq1rW2ynoM704XgF4UaO7Dn
Gw611BnpIk3Lnp2dv39uKTrTqk2uGF2LWZ1M8ciyU4j7AOdnT7ecZPn+vjq5BcK3
p09s6Ag7Q6QjaXaCRON5Fs9+vy/q83JJnIZ96CTUhMAmEy9us6QC2wmntnpJvjZz
IM3nHM8GYhRzmxn5tOS8ogbJMKolS2Xv2m7zu7Oksk95fLEuGucqmq52eix9K/kD
x8Jm1ij9D25PIRMRHieGP+MqURHsSLAKg3nxSV8lUnDJkceHzJvfPWTemT6lz1s+
IZUijpnaopcKzO4EYTYfDT5sytYyDlvm83ihiLT+DjwqObsrAlVJ9+rv5Aeh
-----END RSA PRIVATE KEY-----';