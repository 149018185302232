<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Piranha Web Services</h1>
                <p>Choose your next Service here.</p>
            </div> ';

    $html = $html . '     
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_compute col-sm-12">
                        <h4>Compute</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sc1">
                        <strong>Virtual Machines:</strong> Virtual Machines in the Cloud <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_closure">
                        <strong>Closure:</strong> Serverless Cloud Compute for Code Execution <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_storage col-sm-12">
                        <h4>Storage</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ss3">
                        <strong>Simple:</strong> Piranha&apos;s S3 compatible File Storage System <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_containers col-sm-12">
                        <h4>Containers</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry beta_service service_menu_service_pcr">
                        <strong>Registry:</strong> Piranha Container Registry <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_organization col-sm-12">
                        <h4>Organization</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        <strong>Access:</strong> Piranha Access Management <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Billing:</strong> Managing Invoices
                    </span>
                </div>
                
            </div> ';

    $html = $html . '    
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_networking col-sm-12">
                        <h4>Networking</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_svpc">
                        <strong>VPC:</strong> Virtual Private Cloud Networking <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_dns">
                        <strong>DNS:</strong> DNS Services in the Cloud <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_developer_tools col-sm-12">
                        <h4>Developer Tools</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_scm">
                        <strong>SCM:</strong> Source Code Management Repositories <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pipeline">
                        <strong>Pipelines:</strong> Automatic scripting for your Code Repositories <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_artifact">
                        <strong>Artifacts:</strong> Artifact Repositories for your Packages <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_analytics col-sm-12">
                        <h4>Analytics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_appanalytics">
                        <strong>Analytics:</strong> Web Page and Mobile Application Analytics <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_database col-sm-12">
                        <h4>Data</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ssqs">
                        <strong>Queues:</strong> Piranha managed AMQP Queues  <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
            </div> ';

    return $html ;

} ;