<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $executions_are_array = \ISOPHP\core::$php->is_array($rqd['executions']) ;
    if ($executions_are_array === true) {
        $executions_exist = true ;
    } else {
        $executions_exist = false ;
    }
    if ($executions_exist === true) {
        $execution_count = \ISOPHP\core::$php->count($rqd['executions']) ;
    } else {
        $execution_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-landing" class="btn btn-warning go-build-landing">Build Home</span>
            <span id="go-build-pipeline-list" class="btn btn-success go-build-pipeline-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
       '<div class="col-sm-12 col-12">
            <h4>Pipeline Execution List</h4>
        </div> ' ;

    $html = $html .
       '<div class="col-sm-12 col-12">
            <h5>Queued: '.$rqd['count_queued'].', Executing: '.$rqd['count_executing'].'</h5>
        </div> ' ;

    if ($executions_exist === true && $execution_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'build-pipeline-execution-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
              '<div class="col-sm-12 col-12 one_build_pipeline">
                    <div class="col-sm-1 col-1">
                        <h5 class="build_pipeline_table_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_pipeline_table_title">Repo</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="build_pipeline_table_title">#</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_pipeline_table_title">Start</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_pipeline_table_title">Duration</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_pipeline_table_title">Status</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="build_pipeline_table_title">Params</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="build_pipeline_table_title">Log</h5>
                    </div>                     
               </div>';

        foreach ($rqd['executions'] as $one_build_execution_details) {

            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-1 col-1">
                        <p class="build_list_id">
                            <span style="float: left; display: block;"
                                  data-toggle="' . $one_build_execution_details['id'] . '"
                                  class="build_id build_listing">' . $one_build_execution_details['id_short'] . '
                            </span>
                            <i style="float: left; display: block;" 
                               class="fa fa-copy build_list_start_time"></i>
                        </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_start_time"><span class="run_start build_listing">' . $one_build_execution_details['repository_id'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">' ;

                    if (isset($one_build_execution_details['git_identifier_short'])) {
                        $html = $html . '<p class="build_list_start_time"><span class="run_start build_listing">' . $one_build_execution_details['git_identifier_short'] . '... </span></p>' ;
                    } else {
                        $html = $html . '<p class="build_list_start_time"><span class="run_start build_listing">' . $one_build_execution_details['git_identifier'] . '</span></p>' ;
                    }


            $html = $html .
                '
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_start_time"><span class="run_start build_listing">' . $one_build_execution_details['start_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_duration"><span class="run_end build_list_duration">' . $one_build_execution_details['duration_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_build_name"><span class="build_pipeline_name build_listing">' ;

            if ($one_build_execution_details['status'] === 'success') {
                $html =  $html . '<span class="btn btn-outline-success"> Success </span>' ;
                $html =  $html . '<span id="attempt-pipeline-rebuild" class="btn btn-success pipeline_rebuild_button attempt-pipeline-rebuild" ';
                $html =  $html . '      data-build-execution-id="'.$one_build_execution_details['id'].'" ';
                $html =  $html . '      data-build-unique-id="'.$one_build_execution_details['unique_id'].'" ';
                $html =  $html . '      data-build-repository-id="'.$one_build_execution_details['repository_id'] . '" ';
                $html =  $html . '      data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            } else if ($one_build_execution_details['status'] === 'failed') {
                $html =  $html . '<span class="btn btn-outline-danger"> Failure </span>' ;
                $html =  $html . '<span id="attempt-pipeline-rebuild" class="btn btn-success pipeline_rebuild_button attempt-pipeline-rebuild" ';
                $html =  $html . '      data-build-execution-id="'.$one_build_execution_details['id'].'" ';
                $html =  $html . '      data-build-unique-id="'.$one_build_execution_details['unique_id'].'" ';
                $html =  $html . '      data-build-repository-id="'.$one_build_execution_details['repository_id'] . '" ';
                $html =  $html . '      data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            } else if ($one_build_execution_details['status'] === 'started') {
                $html =  $html . '<span class="btn btn-outline-warning"> Executing </span>' ;
                $html =  $html . '<span id="attempt-pipeline-cancel" class="btn btn-danger pipeline_cancel_button attempt-pipeline-cancel" ';
                $html =  $html . '      data-build-execution-id="'.$one_build_execution_details['id'].'" ';
                $html =  $html . '      data-build-unique-id="'.$one_build_execution_details['unique_id'].'" ';
                $html =  $html . '      data-build-repository-id="'.$one_build_execution_details['repository_id'] . '" ';
                $html =  $html . '      data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-close fa-close-button"></i>';
                $html =  $html . '</span>' ;
            } else {
                $html =  $html . '<span class="btn btn-outline-dark"> Unknown </span>' ;
                $html =  $html . '<span id="attempt-pipeline-rebuild" class="btn btn-success pipeline_rebuild_button attempt-pipeline-rebuild" ';
                $html =  $html . '      data-build-execution-id="'.$one_build_execution_details['id'].'" ';
                $html =  $html . '      data-build-unique-id="'.$one_build_execution_details['unique_id'].'" ';
                $html =  $html . '      data-build-repository-id="'.$one_build_execution_details['repository_id'] . '" ';
                $html =  $html . '      data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            }

            $html = $html .
                '</p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <span class="build_type build_listing">'  ;

            $params_are_array = \ISOPHP\core::$php->is_array($one_build_execution_details['params']) ;
            if ($params_are_array === true) {
                $execution_count = \ISOPHP\core::$php->count($rqd['executions']) ;
                if ($execution_count > 0) {
                    foreach ( $one_build_execution_details['params'] as $parameter_key => $parameter_value) {
                        $html =  $html . '<input type="hidden" class="execution_parameters_'.$one_build_execution_details['repository_id'].'_'.$one_build_execution_details['id'].'" name="'.$parameter_key.'" value="'.$parameter_value.'" />' ;
                    }
                    $html =  $html . '                      
                        <span id="show_build_pipeline_execution_parameters_'.$one_build_execution_details['repository_id'].'_'.$one_build_execution_details['id'].'" 
                         class="btn btn-outline-primary show-build-pipeline-execution-parameters"
                          data-build-execution-id="'.$one_build_execution_details['id'].'"
                          data-build-repository-id="'.$one_build_execution_details['repository_id'] . '"
                          data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >'
                        .' View </span>' ;
                } else {
                    $html =  $html . '<span>No Parameters Included</span>' ;
                }
            }

            $html = $html .
                       '</span>
                    </div>
                    <div class="col-sm-1 col-1">                        
                        <span id="show_build_pipeline_execution_log_'.$one_build_execution_details['repository_id'].'_'.$one_build_execution_details['id'].'" 
                         class="btn btn-outline-primary show-pipeline-execution-log"
                          data-build-execution-id="'.$one_build_execution_details['id'].'"
                          data-build-unique-id="'.$one_build_execution_details['unique_id'].'"
                          data-build-repository-id="'.$one_build_execution_details['repository_id'] . '"
                          data-build-git-id="'.$one_build_execution_details['git_identifier'].'" >'
                         .' View </span>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'build-pipeline-execution-paginator') ;
            $html = $html .'</div>' ;
        }

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-landing" class="btn btn-warning go-build-landing">Build Home</span>
            <span id="go-build-pipeline-list" class="btn btn-success go-build-pipeline-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};
