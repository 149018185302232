<?php

\Core\View::$template = function () {

    $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;
    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    if (\ISOPHP\core::$php->count($rqd['keysets']) > 0) {
        foreach ($rqd['keysets'] as $keyset) {
            $html = $html . '<div class="col-sm-12 col-12">' ;
            $html = $html . '  <div class="col-sm-8 col-8">' ;
            $html = $html . '     '.$keyset['name'] ;
            $html = $html . '  </div>' ;
            $html = $html . '  <div class="col-sm-4 col-4">' ;
            $html = $html . '     <i id="delete_sam_keyset_' . $user_logged['user']['id'] . '_' . $keyset['id'] . '"' ;
            $html = $html . '       class="fa fa-1x fa-close hvr-grow dom attempt-keyset-delete btn btn-outline-danger" ';
            $html = $html . '       data-sam-user-id="' . $user_logged['user']['id'] . '"' ;
            $html = $html . '       data-sam-keyset-name="' . $keyset['name'] . '"' ;
            $html = $html . '       data-sam-keyset-id="' . $keyset['id'] . '"></i>' ;
            $html = $html . '  </div>' ;
            $html = $html . '</div>' ;
        }
    } else {
        $html = $html . '  <h6>No Keysets found</h6>' ;
    }

    return $html;

};