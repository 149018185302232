<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $sizes_are_array = \ISOPHP\core::$php->is_array($rqd['sizes']) ;
    if ($sizes_are_array === true) {
        $sizes_exist = true ;
    } else {
        $sizes_exist = false ;
    }
    if ($sizes_exist === true) {
        $size_count = \ISOPHP\core::$php->count($rqd['sizes']) ;
    } else {
        $size_count = false ;
    }

    if ($sizes_exist === true && $size_count !== false) {
        $html = $html . '<select class="form-control" name="size_slug" id="size_slug">';
        foreach ($rqd['sizes'] as $size) {
            $html = $html . '<option value="' . $size['slug'] . '">' . $size['cores'] . ' Cores, ' . $size['mem'] . 'MB RAM, ' . $size['hdd'] . 'GB HDD</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available Instance Sizes</option>
             </select>' ;

    }

    return $html;

};