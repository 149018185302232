<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $executions_are_array = \ISOPHP\core::$php->is_array($rqd['executions']) ;
    if ($executions_are_array === true) {
        $executions_exist = true ;
    } else {
        $executions_exist = false ;
    }
    if ($executions_exist === true) {
        $execution_count = \ISOPHP\core::$php->count($rqd['executions']) ;
    } else {
        $execution_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
            <span id="go-closure-function-list" class="btn btn-success go-closure-function-list">Function List</span>
            <span id="go-function-execution-list" data-closure-function-id="' . $rqd['function_id'] .'" class="btn btn-info go-function-execution-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
       '<div class="col-sm-12 col-12">
            <h4>Function Execution List</h4>
         </div> ' ;

    if ($executions_exist === true && $execution_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'closure-function-execution-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_closure_function">
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Start</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Duration</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Status</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Trigger</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="closure_function_table_title">Outputs</h5>
                        </div>                     
                    </div>
                 </div>';
        foreach ($rqd['executions'] as $one_closure_execution) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_id"><span class="closure_id closure_listing">' . $one_closure_execution['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_start_time"><span class="closure_start_time closure_listing">' . $one_closure_execution['start_time_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_duration"><span class="closure_function_name closure_list_duration">' . $one_closure_execution['duration_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_duration">
                            <span class="closure_function_name closure_list_status">' ;


            if ($one_closure_execution['status'] === 'success') {
                $html =  $html . '<span class="btn btn-outline-success"> Success </span>' ;
                $html =  $html . '<span id="attempt-function-rebuild" class="btn btn-success function_rebuild_button attempt-function-rebuild" ';
                $html =  $html . '      data-closure-function-id="'.$rqd['function_id'].'" ';
                $html =  $html . '      data-closure-function-name="'.$rqd['function_name'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            } else if ($one_closure_execution['status'] === 'failed') {
                $html =  $html . '<span class="btn btn-outline-danger"> Failure </span>' ;
                $html =  $html . '<span id="attempt-function-rebuild" class="btn btn-success function_rebuild_button attempt-function-rebuild" ';
                $html =  $html . '      data-closure-function-id="'.$rqd['function_id'].'" ';
                $html =  $html . '      data-closure-function-name="'.$rqd['function_name'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            } else if ($one_closure_execution['status'] === 'started') {
                $html =  $html . '<span class="btn btn-outline-warning"> Pending </span>' ;
            } else {
                $html =  $html . '<span class="btn btn-outline-dark"> Unknown </span>' ;
                $html =  $html . '<span id="attempt-function-rebuild" class="btn btn-success function_rebuild_button attempt-function-rebuild" ';
                $html =  $html . '      data-closure-function-id="'.$rqd['function_id'].'" ';
                $html =  $html . '      data-closure-function-name="'.$rqd['function_name'].'" >';
                $html =  $html . '    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>';
                $html =  $html . '</span>' ;
            }

            $html = $html . '</span>
                        </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="closure_list_closure_name"><span class="closure_function_name closure_listing">' ;

            $trigger_options = [
                ['slug' => 'manual', 'friendly' => 'Manual']
            ] ;
            foreach ($trigger_options as $trigger_option) {
                if ($trigger_option['slug'] === $one_closure_execution['trigger']) {
                    $html =  $html . $trigger_option['friendly'] ;
                }
            }

            $html = $html . '
                        </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <span class="closure_type closure_listing">'  ;

            $destinations = [
                ['slug' => 'standard_log', 'friendly' => 'Standard Log']
            ] ;
            $closure_destinations = \ISOPHP\core::$php->explode(',', $one_closure_execution['destinations']) ;
            foreach ($destinations as $destination) {
                if ( \ISOPHP\core::$php->in_array($destination['slug'], $closure_destinations) ) {
                    $html =  $html . '<span id="show_closure_function_log_'.$rqd['function_id'].'_'.$one_closure_execution['id'].'" ' ;
                    $html =  $html . ' class="btn btn-outline-primary show-function-execution-log" data-closure-function-id="'.$rqd['function_id'] ;
                    $html =  $html . '" data-closure-function-execution-id="'.$one_closure_execution['id'].'">' ;
                    $html =  $html . $destination['friendly'].'</span>' ;
                }
            }

            $html = $html .
                       '</span>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'closure-function-execution-paginator') ;
            $html = $html .'</div>' ;
        }

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
            <span id="go-closure-function-list" class="btn btn-success go-closure-function-list">Function List</span>
            <span id="go-function-execution-list" data-closure-function-id="' . $rqd['function_id'] . '" class="btn btn-info go-function-execution-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html . '<input type="hidden" id="closure-function-id" value="' . $rqd['function_id'] . '" />' ;

    return $html;

}; 