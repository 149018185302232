<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-registry-create" class="btn btn-success attempt-registry-create">Create</span>
                        <span id="go-pcr-registry-list" class="btn btn-info go-pcr-registry-list">PCR Registry List</span>
                        <span id="go-pcr-landing" class="btn btn-info go-pcr-landing">PCR Home</span>
                    </div>
                        
                    <div>
                        <h4>New Registry</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Registry Name</label>
                            <input type="text" name="registry_name" class="form-control" id="registry_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Comment</label>
                            <textarea name="comment" id="comment" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-registry-create" class="btn btn-success attempt-registry-create">Create</span>
                        <span id="go-pcr-registry-list" class="btn btn-info go-pcr-registry-list">PCR Registry List</span>
                        <span id="go-pcr-landing" class="btn btn-info go-pcr-landing">PCR Home</span>
                    </div>
                    
                </div>
            </div>
             
        </div>';

    return $html;

};