<?php

Namespace Model\SCM;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SCM List of Piranha Web Services';
        $page['heading'] = 'List of SCM';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SCM Mod', $page, $pageVars);
        return $page;
    }

    public function showSCMLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SCMView();
            $view->loadTemplate('SCM', 'SCMLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySCM($bind);
            \ISOPHP\js_core::$console->log('apply SCM Repository Create 2', $vars);
        };
    }

    public function showSCMRepositoryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SCMView();
            $view->loadTemplate('SCM', 'RepositoryCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySCM($bind);
            \ISOPHP\js_core::$console->log('apply SCM Repository Create 2', $vars);
        };
    }

    public function showSCMSecretCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Secret Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SCMView();
            $view->loadTemplate('SCM', 'SecretCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySCM($bind);
            \ISOPHP\js_core::$console->log('apply SCM Secret Create 2', $vars);
        };
    }

    public function showSCMRepositoryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM List');
            $vars['control'] = 'SCM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/scm/repository/all';
            $afterwards = $this->applySCMRepositoryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository List 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository List failed');
            }
        };
        return $func;
    }

    public function showSCMSecretList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Secret List');
            $vars['control'] = 'SCM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/scm/secret/all';
            $vars['repository_id'] = $pageVars['params']['repository_id'];
            $afterwards = $this->applySCMSecretListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMSecretListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Secret List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Secret List 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'SecretList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Secret List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Secret List failed');
            }
        };
        return $func;
    }

    public function showSCMRepositoryHistory($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Repository History');
            $vars['control'] = 'SCM';
            $vars['action'] = 'commit-list';
            $vars['api_uri'] = '/api/scm/repository/commit/all';
            $vars['repository_id'] = $pageVars['params']['repository_id'];
            $vars['branch'] = $pageVars['params']['branch'];
            $afterwards = $this->applySCMRepositoryHistoryResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryHistoryResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository History result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository History 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryHistory.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository History 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository History failed');
            }
        };
        return $func;
    }



    public function showSCMRepositoryCharts($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Repository List');
            $vars['control'] = 'SCM';
            $vars['action'] = 'repository-chart';
            $vars['api_uri'] = '/api/scm/repository/chart/all';
            $vars['repository_id'] = $pageVars['params']['repository_id'];
            $afterwards = $this->applySCMRepositoryChartsResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryChartsResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository Charts result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository Charts 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryCharts.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository Charts 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository Charts failed');
            }
        };
        return $func;
    }

    public function showSCMRepositoryContributors($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Repository List');
            $vars['control'] = 'SCM';
            $vars['action'] = 'repository-contributors';
            $vars['api_uri'] = '/api/scm/repository/contributors/all';
            $afterwards = $this->applySCMRepositoryContributorsResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryContributorsResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository Contributors result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository Contributors 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryContributors.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository Contributors 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository Contributors failed');
            }
        };
        return $func;
    }

    public function showSCMRepositoryBrowse($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Repository Browse');
            $vars['control'] = 'SCM';
            $vars['action'] = 'repository-filebrowse';
            $vars['api_uri'] = '/api/scm/repository/filebrowse/all';
            $afterwards = $this->applySCMRepositoryBrowseResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryBrowseResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository Browse result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository Browse 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryBrowse.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository Browse 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository Browse failed');
            }
        };
        return $func;
    }

    public function showSCMCommitDetails($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Repository Commit Details');
            $vars['control'] = 'SCM';
            $vars['action'] = 'commit-details';
            $vars['api_uri'] = '/api/scm/commit/one';
            $vars['repository_id'] = $pageVars['params']['repository_id'];
            $vars['commit_id'] = $pageVars['params']['commit_id'];
            $afterwards = $this->applySCMCommitDetailsResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMCommitDetailsResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Commit Details result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Commit Details 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'CommitDetails.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Commit Details 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Commit Details failed');
            }
        };
        return $func;
    }

    public function showSCMRepositoryListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM List');
            $vars['control'] = 'SCM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/scm/repository/all';
            $afterwards = $this->applySCMRepositoryListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        };
    }

    public function applySCMRepositoryListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Repository List 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'RepositoryListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCMRepositoryDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SCM Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Repository List failed');
            }
        };
        return $func;
    }

    public function showSCMObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SCM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SCMView();
            $view->loadTemplate('SCM', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySCM($bind);
            \ISOPHP\js_core::$console->log('apply SCM Object Create 2', $vars);
        };
    }

    public function showSCMObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for repository name');
            \ISOPHP\js_core::$console->log($pageVars['repository_name']);
            if (isset($pageVars['repository_name'])) {
                \ISOPHP\js_core::$console->log('Showing SCM List');
                $vars['control'] = 'SCM';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/scm/object/all';
                $vars['repository_name'] = $pageVars['repository_name'];
                $afterwards = $this->applySCMObjectListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SCM Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applySCMObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SCM Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Object List 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCM($bind);
                \ISOPHP\js_core::$console->log('apply SCM Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save repository
     */
    public function saveRepositoryAttemptToSCM($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save repository to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'repository-create';
        $vars['api_uri'] = '/api/scm/repository/create';
        $vars['repository_name'] = $jQuery('#repository_name')->val();
        $vars['repository_description'] = $jQuery('#repository_description')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRepositoryResultFromSCM($pageVars);
        $msg = 'Requesting Creation of SCM Repository '.$vars['repository_name'] ;
        \Core\WindowMessage::showMessage($msg, 'good');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRepositoryResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save scm repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Repository '.$request_data['repository']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm repository saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SCM', 'repository-list', array(), '/scm/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Repository '.$request_data['repository']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm repository save failed');
            }
        };
        return $func;
    }

    /**
     * Save secret
     */
    public function saveSecretAttemptToSCM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save secret to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'secret-create';
        $vars['api_uri'] = '/api/scm/secret/create';
        $vars['repository_id'] = $pageVars['params']['repository_id'];
        $vars['secret_description'] = $jQuery('#secret_description')->val();
        $vars['secret_name'] = $jQuery('#secret_name')->val();
        $secret_value = $jQuery('#secret_value')->val() ;
        $base64_secret_value = \ISOPHP\js_core::$window->btoa($secret_value);
        $vars['secret_value'] = $base64_secret_value ;
        $afterwards = $this->saveSecretResultFromSCM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSecretResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save scm secret result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Secret '.$request_data['secret_id'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm repository saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SCM', 'secret-list', array(
                    'repository_id' => $request_data['repository_id']
                ), '/scm/repository/'.$request_data['repository_id'].'/secret/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Secret '.$request_data['secret_id'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm repository save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToSCM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save object to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/scm/object/create';
        $vars['repository_name'] = $jQuery('#repository_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $vars['object_value'] = $jQuery('#object_value')->val();
        $vars['object_type'] = $jQuery('#object_type')->val();
        \ISOPHP\js_core::$console->log('save object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromSCM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save scm object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['object']['scm_object_name'].' for Repository '.$request_data['object']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm object saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['object']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToSCM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/scm/object/delete';
        $vars['repository_name'] = $pageVars['params']['repository_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromSCM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteObjectResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete scm object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object']['scm_object_name'].' from Repository '.$request_data['object']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm object deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete repository
     */
    public function deleteRepositoryAttemptToSCM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete repository to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'repository-delete';
        $vars['api_uri'] = '/api/scm/repository/delete';
        $vars['repository_name'] = $pageVars['params']['repository_name'];
        $vars['repository_id'] = $pageVars['params']['repository_id'];
        \ISOPHP\js_core::$console->log('delete repository vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deleteRepositoryResultFromSCM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRepositoryResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete scm repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Repository '.$request_data['repository']['scm_repository_name'].' for Repository '.$request_data['repository']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm repository deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SCM', 'repository-list', array(), '/scm/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Repository '.$request_data['repository']['scm_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm repository delete failed');
            }
            $show_repository_list = self::showSCMRepositoryList($pageVars) ;
            $show_repository_list() ;
        };
        return $func;
    }

    /**
     * Delete secret
     */
    public function deleteSecretAttemptToSCM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete secret to scm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SCM';
        $vars['action'] = 'secret-delete';
        $vars['api_uri'] = '/api/scm/secret/delete';
        $vars['secret_name'] = $pageVars['params']['secret_name'];
        $vars['secret_id'] = $pageVars['params']['secret_id'];
        $vars['repository_id'] = $pageVars['params']['repository_id'];
        $afterwards = $this->deleteSecretResultFromSCM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSecretResultFromSCM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete scm secret result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Secret '.$request_data['secret_id'].' from Repository '.$request_data['repository_id'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('scm secret deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SCM', 'secret-list', array(
                    'repository_id' => $request_data['repository_id']
                ), '/scm/repository/'.$request_data['repository_id'].'/secret/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Secret '.$request_data['secret_id'] ;
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('scm secret delete failed');
            }
            $show_secret_list = self::showSCMSecretList($pageVars) ;
            $show_secret_list() ;
        };
        return $func;
    }

    

    public static function showSCMSingleRepositoryDetails($repository_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing SC1 Single Repository');
            $vars['control'] = 'SCM';
            $vars['action'] = 'repository-one';
            $vars['api_uri'] = '/api/scm/repository/one';
            $vars['repository_id'] = $repository_id;
            $afterwards = self::applySCMSingleRepositoryResult($repository_id);
            $result = self::performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SCM Model: ', $result);
            return $result;
        }
    }

    public static function applySCMSingleRepositoryResult($repository_id) {
        $func = function ($request_data) use ($repository_id) {
            \ISOPHP\js_core::$console->log('applying our SCM Single Repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SCM Single Instance List 1', $vars);
                $view = new \View\SCMView();
                $view->loadTemplate('SCM', 'SingleRepositoryDetails.php', $vars);
                $bind = self::bindButtons();
                $view->displaySCMSingleRepositoryDetails($bind);
                \ISOPHP\js_core::$console->log('apply SCM Single repository List 2', $vars);
                
                $jQuery = \ISOPHP\js_core::$jQuery;
                $overlay_inner = $jQuery('.overlay_inner');
                $overlay_inner->addClass('overlay_inner_large') ;

            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SCM Single repository List failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_scm_landing = $jQuery('.go-scm-landing');
                $go_scm_landing->off() ;
                $go_scm_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'landing', array(), '/scm');
                });

                $go_scm_commit_details = $jQuery('.go-scm-commit-details');
                $go_scm_commit_details->off() ;
                $go_scm_commit_details->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $commit_id = $jqThis->attr('data-scm-commit-id') ;
                    $repository_name = $jqThis->attr('data-scm-repository-name') ;
                    $navigate->route('SCM', 'commit-details', array(
                        'commit_id' => $commit_id,
                        'repository_name' => $repository_name
                    ), '/scm/commit/'.$commit_id);
                });

                $go_scm_repository_browse = $jQuery('.go-scm-repository-browse');
                $go_scm_repository_browse->off() ;
                $go_scm_repository_browse->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'repository-browse', array(
                        'repository_id' => $repository_id
                    ), '/scm/repository/browse');
                });

                $go_scm_repository_history = $jQuery('.go-scm-repository-history');
                $go_scm_repository_history->off() ;
                $go_scm_repository_history->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $branch_name = $jqThis->attr('data-scm-branch-name') ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'repository-history', array(
                        'repository_id' => $repository_id,
                        'branch' => $branch_name
                    ), '/scm/repository/history/'.$repository_id.'/'.$branch_name);
                });

                $go_scm_repository_contributors = $jQuery('.go-scm-repository-contributors');
                $go_scm_repository_contributors->off() ;
                $go_scm_repository_contributors->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'repository-contributors', array(
                        'repository_id' => $repository_id
                    ), '/scm/repository/contributors');
                });

                $go_scm_repository_charts = $jQuery('.go-scm-repository-charts');
                $go_scm_repository_charts->off() ;
                $go_scm_repository_charts->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'repository-charts', array(
                        'repository_id' => $repository_id
                    ), '/scm/repository/charts');
                });

                $go_scm_repository_list = $jQuery('.go-scm-repository-list');
                $go_scm_repository_list->off() ;
                $go_scm_repository_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'repository-list', array(), '/scm/repository/list');
                });

                $go_scm_repository_create = $jQuery('.go-scm-repository-create');
                $go_scm_repository_create->off() ;
                $go_scm_repository_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'repository-create', array(), '/scm/repository/create');
                });

                $save_repository = $jQuery('.attempt-repository-create');
                $save_repository->off() ;
                $save_repository->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'repository-save-attempt', array());
                });

                $go_scm_secret_list = $jQuery('.go-scm-repository-secrets');
                $go_scm_secret_list->off() ;
                $go_scm_secret_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'secret-list', array(
                        'repository_id' => $repository_id
                    ), '/scm/repository/'.$repository_id.'/secret/list');
                });

                $go_scm_secret_create = $jQuery('.go-scm-secret-create');
                $go_scm_secret_create->off() ;
                $go_scm_secret_create->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'secret-create', array(
                        'repository_id' => $repository_id
                    ), '/scm/repository/'.$repository_id.'/secret/list');
                });

                $save_secret = $jQuery('.attempt-secret-create');
                $save_secret->off() ;
                $save_secret->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $navigate->route('SCM', 'secret-save-attempt', array(
                        'repository_id' => $repository_id
                    ));
                });

                $go_scm_object_list = $jQuery('.go-scm-object-list');
                $go_scm_object_list->off() ;
                $go_scm_object_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'object-list', array(), '/scm/object/list');
                });

                $go_scm_object_create = $jQuery('.go-scm-object-create');
                $go_scm_object_create->off() ;
                $go_scm_object_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'object-create', array(), '/scm/object/create');
                });

                $save_object = $jQuery('.attempt-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SCM', 'object-save-attempt', array());
                });

                $branch_menu_display_toggle = $jQuery('.branch_menu_display_toggle');
                $branch_menu_display_toggle->off() ;
                $branch_menu_display_toggle->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $menu_content = $jQuery("#repository_history_branch_content") ;
                    if ($menu_content->css('display') === 'none') {
                        $menu_content->css('display', 'block') ;
                        $menu_content->on('mouseleave', function ($jsthis) use ($menu_content) {
                            $menu_content->css('display', 'none') ;
                        } ) ;
                    } else {
                        $menu_content->css('display', 'none') ;
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-scm-object-name') ;
                    $object_id = $jqThis->attr('data-scm-object-id') ;
                    $repository_name = $jqThis->attr('data-scm-repository-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg = "You are about to delete the Object for $object_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $repository_name) {
                        $navigate->route('SCM', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'repository_name' => $repository_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $repository_delete = $jQuery('.attempt-repository-delete');
                $repository_delete->off() ;
                $repository_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_name = $jqThis->attr('data-scm-repository-name') ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    \ISOPHP\js_core::$console->log('Clicked repository delete button');
                    $msg = "You are about to delete the Repository $repository_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $repository_name, $repository_id) {
                        $navigate->route('SCM', 'repository-delete-attempt', array(
                            'repository_name' => $repository_name,
                            'repository_id' => $repository_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $secret_delete = $jQuery('.attempt-secret-delete');
                $secret_delete->off() ;
                $secret_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
                    $secret_id = $jqThis->attr('data-scm-secret-id') ;
                    $secret_name = $jqThis->attr('data-scm-secret-name') ;
                    \ISOPHP\js_core::$console->log('Clicked secret delete button');
                    $msg = "You are about to delete the Secret $secret_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $secret_name, $secret_id, $repository_id) {
                        $navigate->route('SCM', 'secret-delete-attempt', array(
                            'secret_name' => $secret_name,
                            'secret_id' => $secret_id,
                            'repository_id' => $repository_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $repository_info = $jQuery('.attempt-repository-info');
                $repository_info->off() ;
                $repository_info->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_name = $jqThis->attr('data-scm-repository-name') ;
                    $repository_id = $jqThis->attr('data-scm-repository-id') ;
//                    $repository_state = $jqThis->attr('data-scm-repository-state') ;
                    \ISOPHP\js_core::$console->log('Clicked repository info button');
                    $msg = "Repository Details for: $repository_name";
                    $data  = '' ;
                    $data .= '<div class="col-sm-12" id="scm_single_repository_details">' ;
                    $data .= '  <h3>Loading ...</h3>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
                    self::showSCMSingleRepositoryDetails($repository_id) ;
                } ) ;

            }
        };
    }

    public function bindRepositoryListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding repository switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding repository switch');
            $switch_repository = $jQuery('#repository_name');
            $switch_repository->off() ;
            $switch_repository->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#repository_name') ;
                $repository_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked repository switch');
                $navigate->route('SCM', 'object-list', array(
                    'repository_name' => $repository_name,
                ));
            } ) ;

        };
        return $func;
    }



}