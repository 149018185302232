<?php

Namespace Model\Database;

use ISOPHP\core;

class ReservationPageModel extends \Model\Base {

// $bind = \Model\Database\PageModel::bindButtons();
    public function showDatabaseReservationCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'ReservationCreate.php', $vars);
            $bind = \Model\Database\PageModel::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database Reservation Create 2', $vars);
        };
    }

    public function showDatabaseReservationList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/reservation/all';
            $afterwards = $this->applyDatabaseReservationListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseReservationListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Reservation List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Reservation List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'ReservationList.php', $vars);
                $bind = \Model\Database\PageModel::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database Reservation List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Reservation List failed');
            }
        };
        return $func;
    }

    /**
     * Save reservation
     */
    public function saveReservationAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('save reservation to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'reservation-create';
        $vars['api_uri'] = '/api/database/reservation/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save reservation vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveReservationResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveReservationResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save database reservation result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['reservation']['database_reservation_name'].' for Instance '.$request_data['reservation']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database reservation saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['reservation']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database reservation save failed');
            }
        };
        return $func;
    }
    /**
     * Delete reservation
     */
    public function deleteReservationAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete reservation to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'reservation-delete';
        $vars['api_uri'] = '/api/database/reservation/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['reservation_id'] = $jQuery('#reservation_name')->val();
        \ISOPHP\js_core::$console->log('delete reservation vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteReservationResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteReservationResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete database reservation result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['reservation']['database_reservation_name'].' for Instance '.$request_data['reservation']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database reservation deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['reservation']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database reservation delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete reservation
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_database_reservation_list = $jQuery('.go-database-reservation-list');
                $go_database_reservation_list->off() ;
                $go_database_reservation_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'reservation-list', array(), '/database/reservation/list');
                });

                $go_database_reservation_create = $jQuery('.go-database-reservation-create');
                $go_database_reservation_create->off() ;
                $go_database_reservation_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'reservation-create', array(), '/database/reservation/create');
                });

                $save_reservation = $jQuery('.attempt-reservation-create');
                $save_reservation->off() ;
                $save_reservation->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'reservation-save-attempt', array());
                });

                $reservation_delete = $jQuery('.attempt-reservation-delete');
                $reservation_delete->off() ;
//                $reservation_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
//                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $reservation_name = $jqThis->attr('data-database-reservation-name') ;
//                    $reservation_id = $jqThis->attr('data-database-reservation-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked reservation delete button');
//                    if ($reservation_name !== '') {
//                        $name_or_id = $reservation_name ;
//                    } else {
//                        $name_or_id = $reservation_id ;
//                    }
//                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $reservation_id) {
//                        $navigate->route('Database', 'reservation-delete-attempt', array('reservation_id' => $reservation_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
//                } ) ;


            }
        } ;
    }

}