<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-keypair-create" class="btn btn-success attempt-keypair-create">Create</span>
                        <span id="go-sc1-keypair-list" class="btn btn-warning go-sc1-keypair-list">Key Pair List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
            
                    <div>
                        <h4>New Key Pair</h4>
                    </div>

                    <div class="col-sm-12 col-12">
                        <label>Name</label>
                        <input type="text" name="name" class="form-control" id="name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>SSH Public Key</label>
                        <textarea name="new_key_data" id="new_key_data" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="description" id="description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-keypair-create" class="btn btn-success attempt-keypair-create">Create</span>
                        <span id="go-sc1-keypair-list" class="btn btn-warning go-sc1-keypair-list">Key Pair List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                    
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating SC1 Volumes</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_keypair_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_keypair_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_keypair_ptc_aws" class="instruction_set instruction_set_create_keypair_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
             </div>';

    return $html;

};
