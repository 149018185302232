<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Analytics for your Applications</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Analytics for Web and Mobile Applications</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-analytics-application-list" class="go-analytics-application-list btn btn-lg btn-warning">Applications</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-analytics-application-create" class="go-analytics-application-create btn btn-lg btn-warning">Create Application</span>
                    </div>
                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;