<?php

Namespace Model\Home;

use ISOPHP\console;
use ISOPHP\js_core;
use \Model\Navigate;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Piranha Web Services';
        $page['heading'] = 'Piranha Web Services Home Page';
        $page['user_logged'] = $pageVars['params']['user_logged']['user'];

        $config = new \Model\Configuration();
        $server_url = $config->variable('ISOPHP_API_SERVER_URL');

        $user_logged = \ISOPHP\core::$registry->getValue('user_logged');
        $user = $user_logged['user'];
        $user_uploads = $user_logged['user_uploads'];
        $user_details = $user_logged['user_details'];

        $data = array();
        $data['user_id'] = (isset($user['id']) && $user['id'] !== "") ? $user['id'] : '';
        $data['user_type'] = (isset($user['id']) && $user['id'] !== "") ? $user['user_type'] : '';
        $data['user_email'] = (isset($user['id']) && $user['id'] !== "") ? $user['email'] : '';
        $data['user_pwd'] = (isset($user['id']) && $user['id'] !== "") ? $user['pwd'] : '';
        $data['user_full_name'] = (isset($user['id']) && $user['id'] !== "") ? $user['full_name'] : 'there';
        $data['user_phone_number'] = (isset($user['id']) && $user['id'] !== "") ? $user['phone_number'] : '';
        $data['user_company_name'] = (isset($user['id']) && $user['id'] !== "") ? $user['company_name'] : '';
        $data['user_email_subscription'] = (isset($user['id']) && $user['id'] !== "") ? $user['email_subscription'] : '';
        $data['user_address'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['address'] : '';
        $data['user_dob'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['date_of_birth'] : '';
        $data['user_gender'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['gender'] : '';
        $data['year_experience'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['year_experience'] : '';
        $data['month_experience'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['month_experience'] : '';
        $data['user_profile_photo_src'] = '';
        $data['user_resume_file_src'] = '';
        $data['site_url'] = $server_url;

        /* if ($user_uploads['upload_tag'] == 'profile_photo') {
             $data['user_profile_photo_src'] = ($user_uploads['file_name'] !== null) ? $server_url . "/" . $user_uploads['file_location'] : '';
         }*/


        if ($user_uploads) {
            foreach ($user_uploads as $each_upload) {
                if ($each_upload['upload_tag'] == 'profile_photo') {
                    $data['user_profile_photo_src'] = ($each_upload['file_name'] !== "") ? $server_url . "/" . $each_upload['file_location'] : '';
                }
                if ($each_upload['upload_tag'] == 'resume_file') {
                    $data['user_resume_file_src'] = ($each_upload['file_name'] !== "") ? $server_url . "/" . $each_upload['file_location'] : '';
                }
            }
        }


        if ($data['user_type'] == 1) {

            if ($pageVars['params']['skill']) {
                $skill = $pageVars['params']['skill'];
                $skillSelect = '';
                if ($skill) {
                    foreach ($skill as $RowData) {
                        $skillSelect = $skillSelect . '<option value="' . $RowData['id'] . '">' . $RowData['skill_name'] . '</option>';
                    }
                }
                $data['skill'] = $skillSelect;
            }

            if ($user_logged['user_education']) {
                $user_education = $user_logged['user_education'];
                $education_html = '';

                foreach ($user_education as $rowEdu) {
                    $content_id = $rowEdu['id'];
                    $content = $rowEdu['content'];
                    $institution = $rowEdu['institution'];
                    $pass_year = $rowEdu['pass_year'];
                    $education_html = $education_html . '<div class="card" style="width: 18rem;" id="education_remove_' . $content_id . '">
                          <div class="card-body">
                            <label>Education:</label>
                            <p class="card-title">' . $content . '</p> 
                            <label>Institute:</label>
                            <p class="card-title">' . $institution . '</p>
                             <label>Passing Year:</label>
                            <p class="card-title">' . $pass_year . '</p>
                            <a class="btn btn-primary remove-education" id="education_' . $content_id . '">Remove</a>
                          </div>
                    </div>';
                }

                $data['education_html'] = $education_html;
            }

            if ($user_logged['user_certificate']) {
                $user_certificate = $user_logged['user_certificate'];
                $certificate_html = '';
                foreach ($user_certificate as $rowCert) {
                    $certificate_html = $certificate_html . '<button type="button" class="btn btn-primary" id="certificate_remove_' . $rowCert['id'] . '">' . $rowCert['content'] . '
                             <span class="badge badge-light remove-certificate" id="certificate_' . $rowCert['id'] . '">X</span>
                          </button>';
                }
                $data['certificate_html'] = $certificate_html;
            }

            if ($user_logged['user_skill']) {
                $user_skill = $user_logged['user_skill'];
                $skill_html = '';
                foreach ($user_skill as $rowSkill) {
                    $skill_html = $skill_html . '<button type="button" class="btn btn-primary" id="skill_remove_' . $rowSkill['id'] . '">' . $rowSkill['skill_details']['skill_name'] . '
                             <span class="badge badge-light remove-skill" id="skill_' . $rowSkill['id'] . '">X</span>
                          </button>';
                }
                $data['skill_html'] = $skill_html;
            }

        }

        $page['user_data'] = $data;
        \ISOPHP\js_core::$console->log('I am on homepagemodel', $page, $pageVars);
        return $page;

    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Showing Last Login');
                $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
                \ISOPHP\js_core::$console->log('Logged user at home: ', $user_logged);
                $last_login = $user_logged['last_login'];
                $jQuery = \ISOPHP\js_core::$jQuery;
                $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
                $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
                $jQuery('#latest-login-date')->html($login_date);
                $jQuery('#latest-login-time')->html($login_time);
                return true;
            }
        };
    }

    public static function bindButtons()
    {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $jQuery = \ISOPHP\js_core::$jQuery;
                $navigate = new \Model\Navigate();

                $logout = $jQuery('#logout');
                $save_employer = $jQuery('#go-save-employer');
                $save_candidate = $jQuery('#go-save-candidate');

                $skill = $jQuery('#skill');
                $candidate_gender = $jQuery('#candidate_gender');
                $skill->select2();
                $candidate_gender->select2();


                #remove Education Action bind
                $jQuery('.remove-education')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $education_id = $jqThis->currentTarget->id;
                    $education_id = \ISOPHP\core::$php->str_replace("education_", "", $education_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-education', array('education_id' => $education_id));
                });


                #remove Certificate Action bind
                $jQuery('.remove-certificate')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $certificate_id = $jqThis->currentTarget->id;
                    $certificate_id = \ISOPHP\core::$php->str_replace("certificate_", "", $certificate_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-certificate', array('certificate_id' => $certificate_id));
                });

                #remove Certificate action bind

                $jQuery('.remove-skill')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $skill_id = $jqThis->currentTarget->id;
                    $skill_id = \ISOPHP\core::$php->str_replace("skill_", "", $skill_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-skill', array('skill_id' => $skill_id));
                });


                $logout->on('click', function () {
                    $navigate = new \Model\Navigate();
                    $navigate->route('Logout', 'now', array());
                });
                $save_employer->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'employer-save-attempt', array());
                });

                $save_candidate->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'candidate-save-attempt', array());
                });

                $link_job_list = $jQuery('#user_menu_link_job_list');
                $link_job_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    \ISOPHP\js_core::$console->log("Job List here!!!");
                    $navigate->route('JobList', 'show', array());
                });


                $jQuery('#add_education')->on('click', function ($jqThis) use ($jQuery, $navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'add-education', array());
                });


                $jQuery('#add_certificate')->on('click', function ($jqThis) use ($jQuery, $navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'add-certificate', array());
                });

                $skill->on('change', function ($jqThis) use ($jQuery, $skill, $navigate) {
                    $jqThis->preventDefault();
                    $skill_id = $skill->val();
                    \ISOPHP\js_core::$console->log("Skill on change value", $skill->val());
                    $navigate->route('Home', 'add-skill', array('skill_id' => $skill_id));
                });


            }
        };
    }

    public function getUserData($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

                #Get pre populate skill  drop down data
                $vars['api_uri'] = '/api/get-skills';
                $afterwards = $this->applyGetSkillResult($pageVars);
                $result = $this->performRequest($vars, $afterwards);
                return $result;
            }
        };
    }

    public function applyGetSkillResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data) {
                $skill = $request_data;
                $skillSelect = '<option >Select your skill</option>';
                if ($skill) {
                    foreach ($skill as $RowData) {
                        $skillSelect = $skillSelect . '<option value="' . $RowData['id'] . '">' . $RowData['skill_name'] . '</option>';
                    }
                }
                $jQuery('#skill')->html($skillSelect);
            }
        };
        return $func;
    }


    public function addCandidateEducation($pageVars)
    {
        $vars['control'] = 'Education';
        $vars['action'] = 'add-education';
        $vars['api_uri'] = '/api/candidate-education/save';

        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['type'] = 'edu';

        # profile data
        $vars['content'] = $jQuery('#education')->val();
        $vars['content_institution'] = $jQuery('#institution')->val();
        $vars['content_pass_year'] = $jQuery('#pass_year')->val();

        if (array_key_exists('content', $vars)) {
            $education_length = \ISOPHP\core::$php->strlen($vars['content']);
            if ($education_length < 10) {
                $error_message = 'Education field must be at least 10 characters and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        if (array_key_exists('content_institution', $vars)) {
            $education_length = \ISOPHP\core::$php->strlen($vars['content_institution']);
            if ($education_length < 8) {
                $error_message = 'Institute field must be at least 8 characters and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        $afterwards = $this->applySaveEducationResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function addCandidatCertificate($pageVars)
    {
        $vars['control'] = 'Certificate';
        $vars['action'] = 'add-certificate';
        $vars['api_uri'] = '/api/candidate-education/save';

        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['type'] = 'cert';

        # profile data
        $vars['content'] = $jQuery('#certificate')->val();

        if (array_key_exists('content', $vars)) {
            $education_length = \ISOPHP\core::$php->strlen($vars['content']);
            if ($education_length < 10) {
                $error_message = 'Certificate field must be at least 10 characters and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        $afterwards = $this->applySaveCertificateResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function addSkill($pageVars)
    {
        $vars['control'] = 'Education';
        $vars['action'] = 'remove-education';
        $vars['api_uri'] = '/api/skill/add';

        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['skill_id'] = $pageVars['params']['skill_id'];
        $pageVars['skill_id'] = $pageVars['params']['skill_id'];
        $afterwards = $this->applyAddSkillResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function removeSkill($pageVars)
    {
        $vars['control'] = 'Education';
        $vars['action'] = 'remove-education';
        $vars['api_uri'] = '/api/skill/remove';

        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['skill_id'] = $pageVars['params']['skill_id'];
        $pageVars['skill_id'] = $pageVars['params']['skill_id'];
        $afterwards = $this->applyRemoveSkillResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function removeCandidateEducation($pageVars)
    {
        $vars['control'] = 'Education';
        $vars['action'] = 'remove-education';
        $vars['api_uri'] = '/api/candidate-education/delete';

        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['type'] = 'edu';
        $vars['content_id'] = $pageVars['params']['education_id'];
        $pageVars['content_id'] = $pageVars['params']['education_id'];


        $afterwards = $this->applyRemoveEducationResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function removeCandidateCertificate($pageVars)
    {
        $vars['control'] = 'Certificate_id';
        $vars['action'] = 'remove-certificate';
        $vars['api_uri'] = '/api/candidate-education/delete';

        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];
        $vars['type'] = 'edu';
        $vars['content_id'] = $pageVars['params']['certificate_id'];
        $pageVars['content_id'] = $pageVars['params']['certificate_id'];


        $afterwards = $this->applyRemoveCertificateResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applySaveEducationResult($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                $content = $request_data['candidate_education']['content'];
                $institution = $request_data['candidate_education']['institution'];
                $pass_year = $request_data['candidate_education']['pass_year'];
                $content_id = $request_data['candidate_education']['id'];

                $html = '<div class="card" style="width: 18rem;" id="education_remove_' . $content_id . '">
                          <div class="card-body">
                            <label>Education:</label>
                            <p class="card-title">' . $content . '</p> 
                            <label>Institute:</label>
                            <p class="card-title">' . $institution . '</p>
                             <label>Passing Year:</label>
                            <p class="card-title">' . $pass_year . '</p>
                            <a class="btn btn-primary remove-education" id="education_' . $content_id . '">Remove</a>
                          </div>
                    </div>';

                $jQuery('.added_education')->append($html);
                $jQuery('#education')->val('');
                $jQuery('#institution')->val('');
                $jQuery('#pass_year')->val('');
                \Core\WindowMessage::showMessage($welcome_message, 'good');

                $jQuery('.remove-education')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $education_id = $jqThis->currentTarget->id;
                    $education_id = \ISOPHP\core::$php->str_replace("education_", "", $education_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-education', array('education_id' => $education_id));
                });

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    public function applySaveCertificateResult($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                $content = $request_data['candidate_education']['content'];
                $content_id = $request_data['candidate_education']['id'];
                $html = '<button type="button" class="btn btn-primary" id="certificate_remove_' . $content_id . '">' . $content . '
                             <span class="badge badge-light remove-certificate" id="certificate_' . $content_id . '">X</span>
                          </button>';
                $jQuery('.added_certificate')->append($html);
                $jQuery('#certificate')->val('');
                \Core\WindowMessage::showMessage($welcome_message, 'good');

                $jQuery('.remove-certificate')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $certificate_id = $jqThis->currentTarget->id;
                    $certificate_id = \ISOPHP\core::$php->str_replace("certificate_", "", $certificate_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-certificate', array('certificate_id' => $certificate_id));
                });

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    public function applyAddSkillResult($pageVars)
    {

        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                $content = $request_data['skill']['skill_details']['skill_name'];
                $skill_id = $request_data['skill']['id'];
                $html = '<button type="button" class="btn btn-primary" id="skill_remove_' . $skill_id . '">' . $content . '
                             <span class="badge badge-light remove-skill" id="skill_' . $skill_id . '">X</span>
                          </button>';
                $jQuery('.added_skill')->append($html);
                $jQuery('#skill')->val('');
                \Core\WindowMessage::showMessage($welcome_message, 'good');

                $jQuery('.remove-skill')->unbind()->bind('click', function ($jqThis) use ($jQuery) {
                    $jqThis->preventDefault();
                    $skill_id = $jqThis->currentTarget->id;
                    $skill_id = \ISOPHP\core::$php->str_replace("skill_", "", $skill_id);
                    $navigate = new \Model\Navigate();
                    $navigate->route('Home', 'remove-skill', array('skill_id' => $skill_id));
                });

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    public function applyRemoveEducationResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'good');
                $jQuery('#education_remove_' . $pageVars['content_id'])->remove();

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    public function applyRemoveSkillResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'good');
                $jQuery('#skill_remove_' . $pageVars['skill_id'])->remove();

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    public function applyRemoveCertificateResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;

        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data['status'] === 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'good');
                $jQuery('#certificate_remove_' . $pageVars['content_id'])->remove();

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['message'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }


    public function getEmployerSaveResult($pageVars)
    {
        $vars['control'] = 'Employer';
        $vars['action'] = 'employer-save-attempt';
        $vars['api_uri'] = '/api/employer/save';

        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];

        # profile data
        $vars['email'] = $jQuery('#email')->val();
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['phone_number'] = $jQuery('#phone_number')->val();
        $vars['company_name'] = $jQuery('#company_name')->val();
        $vars['upload_tag_profile'] = $jQuery('#profile_upload_tag')->val();
        $vars['file_name_profile'] = $jQuery('#profile_file_name')->val();


        # additional data
        $vars['address'] = $jQuery->trim($jQuery('#address')->val());

        \ISOPHP\js_core::$console->log("Form Validation ignored.");

        $form_valid = $this->validateFormFields($vars);
        if ($form_valid !== true) {
            $message = 'Invalid Employer Form';
            \Core\WindowMessage::showMessage($message, 'bad');
            return false;
        }
        if ($form_valid === true) {
            $message = 'Valid Employer Form - Trying now...';
            \Core\WindowMessage::showMessage($message, 'good');
            #return true;
        }
        $afterwards = $this->applySaveResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applySaveResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            if ($request_data['status'] === 'OK') {
                $navigate = new \Model\Navigate();

                $welcome_message = "Successfully saved";
                $extra_params['user_logged']['user'] = $request_data['user'];
                $extra_params['user_logged']['user_details'] = $request_data['user_details'];
                $extra_params['user_logged']['user_uploads'] = $request_data['user_uploads'];

                $registry = \ISOPHP\core::$registry;
                \ISOPHP\js_core::$console->log('registry is ', $registry);
                \ISOPHP\js_core::$console->log('logged user to registry is ', $extra_params['user_logged']);
                $registry::setValue('user_logged', $extra_params['user_logged']);

                \Core\WindowMessage::showMessage($welcome_message, 'good');

                $navigate->route('Home', 'show', $extra_params, '/home');

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data->error;
                \ISOPHP\js_core::$console->log('request not ok: ', $request_data);
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }


    public function getCandidateSaveResult($pageVars)
    {
        $vars['control'] = 'Candidate';
        $vars['action'] = 'candidate-save-attempt';
        $vars['api_uri'] = '/api/candidates-profile/save';


        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];

        # profile data
        $vars['email'] = $jQuery('#email')->val();
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['phone_number'] = $jQuery('#phone_number')->val();
        $vars['upload_tag_profile'] = $jQuery('#profile_upload_tag')->val();
        $vars['file_name_profile'] = $jQuery('#profile_file_name')->val();

        # additional data
        $vars['address'] = $jQuery('#candidate_address')->val();
        $vars['date_of_birth'] = $jQuery('#candidate_date_of_birth')->val();
        $vars['gender'] = $jQuery('#candidate_gender')->val();
        $vars['year_exp'] = $jQuery('#year_experience')->val();
        $vars['month_exp'] = $jQuery('#month_experience')->val();

        #skill

        $skills = $jQuery('#skill')->val();

        $php = \ISOPHP\core::$php;
        \ISOPHP\js_core::$console->log("Skill", $php->json_encode($skills));
        $vars['skills'] = $php->json_encode($skills);
        \ISOPHP\js_core::$console->log("Skill", $skills);

        \ISOPHP\js_core::$console->log("Var all values Value" . $vars);

        # resume data
        $vars['upload_tag_resume'] = $jQuery('#resume_upload_tag')->val();
        $vars['file_name_resume'] = $jQuery('#resume_file_name')->val();


        $form_valid = $this->validateFormFields($vars);
        if ($form_valid !== true) {
            $message = 'Invalid Candidate Form';
            \Core\WindowMessage::showMessage($message, 'bad');
            return false;
        }

        if ($form_valid === true) {
            $message = 'Valid Candidate Form - Trying now...';
            \Core\WindowMessage::showMessage($message, 'good');
        }

        \ISOPHP\js_core::$console->log('Cadndidate Con attempt action................... 111');


        $afterwards = $this->applyCandidateSaveResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applyCandidateSaveResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            if ($request_data['status'] === 'OK') {
                $navigate = new \Model\Navigate();

                $welcome_message = "Successfully saved";

                $extra_params['user_logged']['user'] = $request_data['user'];
                $extra_params['user_logged']['user_uploads'] = $request_data['user_uploads'];
                $extra_params['user_logged']['user_details'] = $request_data['user_details'];

                $registry = \ISOPHP\core::$registry;
                \ISOPHP\js_core::$console->log('registry is ', $registry);
                \ISOPHP\js_core::$console->log('logged user to registry is ', $extra_params['user_logged']);

                $registry::setValue('user_logged', $extra_params['user_logged']);

                $navigate->route('Home', 'show', $extra_params, '/home');

                \Core\WindowMessage::showMessage($welcome_message, 'good');
            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['error'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    /**
     * Common Form validation
     * Add validation for new field here
     * for input value validation use \ISOPHP\core::$locutuspcre->preg_match
     */
    public function validateFormFields($vars)
    {
        /* email address validation */
        if (array_key_exists('email', $vars)) {
            $emailRegex = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
            $emailMatch = \ISOPHP\core::$locutuspcre->preg_match($emailRegex, $vars['email']);
            if (!$emailMatch) {
                $error_message = 'Please enter a valid email address.';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        /*Will be added more validation for phone number*/
        if (array_key_exists('phone_number', $vars)) {
            $phone_number = \ISOPHP\core::$php->strlen($vars['phone_number']);
            if ($phone_number < 8) {
                $error_message = 'Phone field must be 8 digits and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
            $phoneRegex = '^[0-9-+s()]*$';
            $phoneMatch = \ISOPHP\core::$locutuspcre->preg_match($phoneRegex, $vars['phone_number']);
            if (!$phoneMatch) {
                $error_message = 'Phone field must be numeric';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        if (array_key_exists('address', $vars)) {
            $address_length = \ISOPHP\core::$php->strlen($vars['address']);
            if ($address_length < 3) {
                $error_message = 'Address field must be at least 3 characters and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        /*Will be added more validation for date of birth*/
        if (array_key_exists('date_of_birth', $vars)) {
            $date_of_birth = $vars['date_of_birth'];
            if ($date_of_birth == '') {
                $error_message = 'Date field can not be empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        if (array_key_exists('gender', $vars)) {
            $gender = $vars['gender'];
            if ($gender == '') {
                $error_message = 'Gender field can not be empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        $integerRegex = '^[0-9-+s()]*$';

        if (array_key_exists('year_exp', $vars)) {
            $year_exp = $vars['year_exp'];

            if ($year_exp != '') {


                $yearMatch = \ISOPHP\core::$locutuspcre->preg_match($integerRegex, $year_exp);

                if (!$yearMatch) {
                    $error_message = 'Year of experience field must be numeric';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }

                if ($year_exp <= 0) {
                    $error_message = 'Year of experience field must be greater than 0';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }
            }
        }


        if (array_key_exists('month_exp', $vars)) {
            $month_exp = $vars['month_exp'];

            if ($month_exp != '') {


                $monthMatch = \ISOPHP\core::$locutuspcre->preg_match($integerRegex, $month_exp);
                if (!$monthMatch) {
                    $error_message = 'Month of experience field must be numeric';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }

                if ($month_exp <= 0 || $month_exp > 12) {
                    $error_message = 'Month of experience field must be fewer than 13 and greater than 0';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }
            }


        }

        return true;
    }


}