<?php

Namespace Controller;

class ArtifactController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Artifact controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Artifact, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Artifact\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Artifact.php';
            $res->type = 'view';
            $res->view_control = 'Artifact';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showArtifactLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-list') {
                $res->post_template[] = $page_model->showArtifactRepositoryList($pageVars);
            } else if ($pageVars['route']['action'] == 'repository-create') {
                $res->post_template[] = $page_model->showArtifactRepositoryCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'object-list') {
                $res->post_template[] = $page_model->showArtifactRepositoryListDropDown($pageVars);
                $res->post_template[] = $page_model->bindRepositoryListDropDownButtonsForObjectList();
                $res->post_template[] = $page_model->showArtifactObjectList($pageVars);
            } else if ($pageVars['route']['action'] == 'object-create') {
                $res->post_template[] = $page_model->showArtifactObjectCreate($pageVars);
                $res->post_template[] = $page_model->showArtifactRepositoryListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindRepositoryListDropDownButtonsForObjectCreate();
            } else if ($pageVars['route']['action'] == 'repository-save-attempt') {
                $page_model->saveRepositoryAttemptToArtifact($pageVars);
                $res->view = 'Artifact.php';
                $res->control = 'Artifact';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-save-attempt') {
                $page_model->saveObjectAttemptToArtifact($pageVars);
                $res->view = 'Artifact.php';
                $res->control = 'Artifact';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'repository-delete-attempt') {
                $page_model->deleteRepositoryAttemptToArtifact($pageVars);
                $res->view = 'Artifact.php';
                $res->control = 'Artifact';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'object-delete-attempt') {
                $page_model->deleteObjectAttemptToArtifact($pageVars);
                $res->view = 'Artifact.php';
                $res->control = 'Artifact';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showArtifactLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Artifact Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}