<?php

Namespace Controller;

class SLBController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SLB controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SLB, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SLB\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('Mini Proxy List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SLB.php';
            $res->type = 'view';
            $res->view_control = 'SLB';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSLBLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'record-list') {
                $res->post_template[] = $page_model->showSLBRecordList($pageVars);
            } else if ($pageVars['route']['action'] == 'record-create') {
                $sc1_instance_page_model = new \Model\SC1\InstancePageModel();
                $res->post_template[] = $page_model->showSLBRecordCreate($pageVars);
                $res->post_template[] = $sc1_instance_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'record-save-attempt') {
                $page_model->saveRecordAttemptToSLB($pageVars);
                $res->view = 'SLB.php';
                $res->control = 'SLB';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'record-delete-attempt') {
                $page_model->deleteRecordAttemptToSLB($pageVars);
                $res->view = 'SLB.php';
                $res->control = 'SLB';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSLBLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SLB Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}