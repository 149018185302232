<?php
\Core\View::$template = function () {
    $html = '

<div class="coming-soon">

<div class="overlay"></div>
<div class="coming-bg"></div>

<div class="masthead">
    <div class="masthead-bg"></div>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12 my-auto">
                <div class="masthead-content text-white py-5 py-md-0">
                    <img src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/piranha-corp.png" height="80">
                    <h1 class="mb-3 font-fix-cs">Piranha Web Services is Under Construction</h1>
                    <p class="mb-5">We&apos;re beavering away on this site. We&apos;ll be ready
                        <strong>really soon</strong>, Sign up now for updates using the form below-</p>
                    <div class="input-group input-group-newsletter msg-email-subscription">
                    </div>
                    <div class="input-group input-group-newsletter">
                        
                        <input type="email" id="email" class="form-control" placeholder="Enter email..." aria-label="Enter email..." aria-describedby="basic-addon">
                        <div class="input-group-append">
                            <button class="btn btn-secondary email_subscription" type="button">Notify Me</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="social-icons">
    <ul class="list-unstyled text-center mb-0">
        <li class="list-unstyled-item">
            <a href="#">
                <i class="fa fa-twitter"></i>
            </a>
        </li>
        <li class="list-unstyled-item">
            <a href="#">
                <i class="fa fa-facebook"></i>
            </a>
        </li>
        <li class="list-unstyled-item">
            <a href="#">
                <i class="fa fa-instagram"></i>
            </a>
        </li>
    </ul>
</div>


</div>';



    return $html;
};

