<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $objects_are_array = \ISOPHP\core::$php->is_array($rqd['objects']) ;
    if ($objects_are_array === true) {
        $objects_exist = true ;
    } else {
        $objects_exist = false ;
    }
    if ($objects_exist === true) {
        $object_count = \ISOPHP\core::$php->count($rqd['objects']) ;
    } else {
        $object_count = false ;
    }

    $html = $html .
       '<div class="col-sm-12 col-12 text-center">
            <span id="go-ss3-object-create" class="btn btn-success go-ss3-object-create"
                  data-ss3-object-creation-key="'.$rqd['key'].'"
                  data-ss3-bucket-id="'.$rqd['bucket'].'"
                  data-ss3-bucket-name="'.$rqd['bucket'].'" >New Object</span>
            <span id="go-ss3-landing" class="btn btn-warning go-ss3-landing">SS3 Home</span>
            <span id="go-ss3-bucket-list" class="btn btn-warning go-ss3-bucket-list">Bucket List</span>
            <span id="go-ss3-object-list"
                  class="btn-refresh btn btn-info go-ss3-object-list"
                  data-ss3-bucket-id="'.$rqd['bucket'].'"
                  data-ss3-bucket-name="'.$rqd['bucket'].'" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Object List: <strong>' ;

    if (isset($rqd['key'])) {
        \ISOPHP\js_core::$console->log('rqd key is set');

        $key_parts = \ISOPHP\core::$php->explode('/', $rqd['key']) ;
        $key_parts_joined_by_slash = '' ;
        $key_parts_joined_by_dash = '' ;

        \ISOPHP\js_core::$console->log('pagevars: ', $pv);
        $html_snippet = '' ;
        foreach ($key_parts as $key_part) {
            if ($key_part !== '') {

                $key_parts_joined_by_slash .= $key_part.'/' ;
                $key_parts_joined_by_dash .= '-'.$key_part ;

                $html_snippet .= '
            <span id="go-ss3-object-list-'.$rqd['bucket'].'-'.$key_parts_joined_by_dash.'"
                  class="go-ss3-object-list hover-menu storage-title"
                  data-ss3-bucket-id="'.$rqd['bucket'].'"
                  data-ss3-bucket-name="'.$rqd['bucket'].'"
                  data-ss3-object-key="'.$key_parts_joined_by_slash.'" >/'.$key_part.'</span> ' ;

                \ISOPHP\js_core::$console->log('current html snippet is', $html_snippet);
            }
        }
        \ISOPHP\js_core::$console->log('final html snippet is', $html_snippet);

        $html = $html . $html_snippet ;
    } else {
        \ISOPHP\js_core::$console->log('rqd key is not set');
        $html = $html . $rqd['bucket'] ;
    }


    $html = $html .
            '</strong> ('. $rqd['scope'] .') </h4>
        </div> ' ;

    if ($objects_exist === true && $object_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'ss3-object-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_ss3_object">
                        <div class="col-sm-3 col-3">
                            <h5 class="ss3_object_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ss3_object_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ss3_object_table_title">Size</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ss3_object_table_title">Modified</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="ss3_object_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';

        $col_size = 4 ;
        if ($rqd['scope'] === 'public') {
            $col_size = 4 ;
        }
        if ($rqd['scope'] === 'private') {
            $col_size = 6 ;
        }

        foreach ($rqd['objects'] as $one_ss3_object) {

            $dotless_name = \ISOPHP\core::$php->str_replace('.', '', $one_ss3_object['name']) ;

            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-3 col-3" style="overflow-x: hidden"> ';

            if ($one_ss3_object['type'] === 'directory') {
                $html = $html .
                        '<p class="ss3_list_id">
                             <span
                                class="ss3_id ss3_listing"
                                id="info_ss3_object_' . $dotless_name . '"
                                data-ss3-object-id="' . $one_ss3_object['id'] . '"
                                data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                data-ss3-bucket-name="' . $rqd['bucket'] . '">
                                 '.$one_ss3_object['name'].'
                             </span>
                         </p> ';
            } else {
                    $html = $html .
                        '<p class="ss3_list_id">
                            <span class="ss3_id ss3_listing">'.$one_ss3_object['name'].'</span>
                         </p>' ;
            }

            $html = $html .
                   '</div>
                    <div class="col-sm-2 col-2"> ' ;

            $html = $html . '<p class="ss3_list_id"><span class="ss3_id ss3_listing">'.$one_ss3_object['type'].'</span></p>' ;

            $html = $html . '
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ss3_list_id"><span class="ss3_id ss3_listing">' . $one_ss3_object['size_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ss3_list_id"><span class="ss3_id ss3_listing">' . $one_ss3_object['last_modified'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3"> <!-- end of row buttons start -->  ';

            if ($one_ss3_object['type'] === 'directory') {

                $html = $html .
                           '<div class="col-sm-8 col-8">
                                <i id="list_ss3_bucket_'.$rqd['bucket'].'"
                                   class="fa fa-1x fa-bars hvr-grow dom go-ss3-object-list btn btn-outline-primary"
                                   data-ss3-bucket-id="'.$rqd['bucket'].'"
                                   data-ss3-bucket-name="'.$rqd['bucket'].'"
                                   data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                   data-ss3-object-id="' . $one_ss3_object['name'] . '"
                                   data-ss3-object-key="' . $one_ss3_object['key'] . '"></i>
                            </div>
                            
                            <div class="col-sm-4 col-4">
                                <i id="delete_ss3_object_' . $dotless_name . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-ss3-object-delete btn btn-outline-danger"
                                   data-ss3-object-id="' . $one_ss3_object['id'] . '"
                                   data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                   data-ss3-bucket-name="' . $rqd['bucket'] . '"></i>
                            </div>';

            } else {

                if ($rqd['scope'] === 'public') {
                    $html = $html .
                        '   <div class="col-sm-'.$col_size.' col-'.$col_size.'">
                                <a href="'.$one_ss3_object['public_url'].'" target="_blank">
                                        <i id="info_ss3_object_' . $dotless_name . '"
                                           class="fa fa-1x fa-download hvr-grow dom show-ss3-object-info btn btn-outline-success"
                                           data-ss3-object-id="' . $one_ss3_object['id'] . '"
                                           data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                           data-ss3-bucket-name="' . $rqd['bucket'] . '"></i>
                                </a>
                            </div> ';
                }

                $html = $html .
                    '
                            <div class="col-sm-'.$col_size.' col-'.$col_size.'">
                                <i id="download_ss3_object_' . $rqd['bucket'] . '_' . $dotless_name . '"
                                   class="fa fa-1x fa-download hvr-grow dom attempt-ss3-object-download btn btn-outline-primary"
                                   data-ss3-object-id="' . $one_ss3_object['id'] . '"
                                   data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                   data-ss3-bucket-name="' . $rqd['bucket'] . '"></i>
                            </div>
                            
                            <div class="col-sm-'.$col_size.' col-'.$col_size.'">
                                <i id="delete_ss3_object_' . $dotless_name . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-ss3-object-delete btn btn-outline-danger"
                                   data-ss3-object-id="' . $one_ss3_object['id'] . '"
                                   data-ss3-object-name="' . $one_ss3_object['name'] . '"
                                   data-ss3-bucket-name="' . $rqd['bucket'] . '"></i>
                            </div>';
            }

            $html = $html .
                '       
       
                    </div> <! -- end of row buttons end -->
                </div> ';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html . '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'ss3-object-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
               '<div class="col-sm-12 col-12 text-center">
                    <span id="go-ss3-object-create" class="btn btn-success go-ss3-object-create"
                          data-ss3-object-creation-key="'.$rqd['key'].'"
                          data-ss3-bucket-id="'.$rqd['bucket'].'"
                          data-ss3-bucket-name="'.$rqd['bucket'].'" >New Object</span>
                    <span id="go-ss3-landing" class="btn btn-warning go-ss3-landing">SS3 Home</span>
                    <span id="go-ss3-bucket-list" class="btn btn-warning go-ss3-bucket-list">Bucket List</span>
                    <span id="go-ss3-object-list"
                          class="btn-refresh btn btn-info go-ss3-object-list"
                          data-ss3-bucket-id="'.$rqd['bucket'].'"
                          data-ss3-bucket-name="'.$rqd['bucket'].'" >
                        <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                    </span>
                </div>';


        $html = $html . '<input type="hidden" id="ss3-bucket-id" value="' . $rqd['bucket'] . '" />' ;

    } else {

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                    <h5>This Bucket is Empty</h5>
             </div>';

    }

    return $html;

};
