<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to your S3 Compatible Storage Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Storage For Web Based Buckets and Objects</h3>
                    <p>S3 Compatible Storage for Buckets and Objects.</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-ss3-bucket-list" class="go-ss3-bucket-list btn btn-lg btn-warning">Buckets</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-ss3-bucket-create" class="go-ss3-bucket-create btn btn-lg btn-warning">Create Bucket</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;
