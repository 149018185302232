<?php

Namespace Model\SC1;

use ISOPHP\core;

class NetworkInterfacePageModel extends \Model\Base {


    public function showSC1NetworkInterfaceCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'NetworkInterfaceCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 NetworkInterface Create 2', $vars);
        };
    }

    public function showSC1NetworkInterfaceList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/networkinterface/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1NetworkInterfaceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1NetworkInterfaceListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 NetworkInterface List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 NetworkInterface List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'NetworkInterfaceList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 NetworkInterface List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 NetworkInterface List failed');
            }
        };
        return $func;
    }

    /**
     * Save networkinterface
     */
    public function saveNetworkInterfaceAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save networkinterface to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'networkinterface-create';
        $vars['api_uri'] = '/api/sc1/networkinterface/create';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['networkinterface_name'] = $jQuery('#networkinterface_name')->val();
        $vars['networkinterface_type'] = $jQuery('#networkinterface_type')->val();
        \ISOPHP\js_core::$console->log('save networkinterface vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveNetworkInterfaceResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveNetworkInterfaceResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 networkinterface result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created NetworkInterface '.$request_data['networkinterface']['sc1_networkinterface_name'].' for Instance '.$request_data['networkinterface']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 networkinterface saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating NetworkInterface '.$request_data['networkinterface']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 networkinterface save failed');
            }
        };
        return $func;
    }
    /**
     * Delete networkinterface
     */
    public function deleteNetworkInterfaceAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete networkinterface to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'networkinterface-delete';
        $vars['api_uri'] = '/api/sc1/networkinterface/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete networkinterface vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteNetworkInterfaceResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteNetworkInterfaceResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 networkinterface result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted NetworkInterface '.$request_data['networkinterface']['sc1_networkinterface_name'].' for Instance '.$request_data['networkinterface']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 networkinterface deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting NetworkInterface '.$request_data['networkinterface']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 networkinterface delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding network interface buttons');

                $go_sc1_networkinterface_list = $jQuery('.go-sc1-networkinterface-list');
                $go_sc1_networkinterface_list->off() ;
                $go_sc1_networkinterface_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'networkinterface-list', array(), '/sc1/networkinterface/list');
                });

                $go_sc1_networkinterface_list_page = $jQuery('.sc1-networkinterface-paginator');
                $go_sc1_networkinterface_list_page->off() ;
                $go_sc1_networkinterface_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 networkinterface paginator');
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $new_page = $jqThis->attr('data-page') ;
//                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'networkinterface-list', array('page' => $new_page), '/sc1/networkinterface/list/page/'.$new_page);
                });

                $go_sc1_networkinterface_create = $jQuery('.go-sc1-networkinterface-create');
                $go_sc1_networkinterface_create->off() ;
                $go_sc1_networkinterface_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'networkinterface-create', array(), '/sc1/networkinterface/create');
                });

                $save_networkinterface = $jQuery('.attempt-networkinterface-create');
                $save_networkinterface->off() ;
                $save_networkinterface->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'networkinterface-save-attempt', array());
                });

                $networkinterface_delete = $jQuery('.attempt-networkinterface-delete');
                $networkinterface_delete->off() ;
                $networkinterface_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $networkinterface_name = $jqThis->attr('data-sc1-networkinterface-name') ;
//                    $networkinterface_id = $jqThis->attr('data-sc1-networkinterface-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked networkinterface delete button'. $networkinterface_name. $networkinterface_id);
//                    if ($networkinterface_name !== '') {
//                        $name_or_id = $networkinterface_name ;
//                    } else {
//                        $name_or_id = $networkinterface_id ;
//                    }
//                    $msg = "You are about to delete this Launch Template $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $networkinterface_id, $networkinterface_name) {
//                        $navigate->route('SC1', 'networkinterface-delete-attempt', array(
//                            'id' => $networkinterface_id,
//                            'name' => $networkinterface_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}