<?php

\Core\View::$template = function() {
    $html = '
        <form id="pharaoh_form">
            <header class="col-sm-12 navbar">
                <div class="col-sm-2">
                    <img class="nav_logo" src="/app/Default/Assets/images/digital-money-direct-logo-small.png" />
                </div>
                <div class="col-sm-8">
                    <h1>User Settings with Digital Money Direct</h1>
                </div>
                <div class="col-sm-2">
                    <div id="user-menu" class="user-menu"></div>
                    <div id="settings-menu" class="settings-menu"></div>
                </div>
            </header>
            
            <div class="col-sm-12">
                <div class="col-sm-6">
                    <h2>Password Confirmation</h2>
                </div> 
                <div class="col-sm-6">
                    <input type="password" name="pass_conf" id="pass_conf" />
                </div> 
            </div> ' ;

    $html = $html . '</form>' ;
    return $html ;

} ;



\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>User Settings</h1>
                <p>Configure your application</p>
            </div> ';

    $html = $html . '     
            <div class="col-sm-12">
            
                <div class="col-sm-12 user_setting_entry_margin">
                    <span class="btn col-sm-12 user_setting_entry service_menu_group service_menu_group_compute col-sm-12">
                        <h4>Dark Theme</h4>
                    </span>                    
                    <span class="btn col-sm-12 user_setting_entry service_menu_service_sc1">
                        <span id="light_dark" class="btn btn-warning light_dark">Light/Dark</span>
                        <span>
                            <i id="switch_theme_set" class="fa fa-1x fa-toggle-off hvr-grow switch_theme_set"></i>
                        </span>
                    </span>
                </div>
            
                <div class="col-sm-12 user_setting_entry_margin">
                    <span class="btn user_setting_entry service_menu_group_media col-sm-12">
                        <h4>Media</h4>
                    </span>
                    <span class="btn col-sm-12 user_setting_entry unimplemented_service service_menu_service_video">
                        <strong>Video: </strong> Premium Video Content to Rent or Buy
                    </span>
                    <span class="btn col-sm-12 user_setting_entry unimplemented_service service_menu_service_channels">
                        <strong>Channels: </strong> Video Hosting for Public Channels 
                    </span>
                </div>
                    
            </div>
                    
        </div> ';

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <span id="user-settings-save" class="btn btn-success user-settings-save">Save</span>
            </div>
            <div class="col-sm-12">
                <span id="go-home" class="btn btn-warning go-home">Cancel</span>
            </div>
    
        </div> ' ;
    
    return $html ;

} ;