<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Mindstorm, your Robotics Development Platform</h1>
            </div>
        
            <div class="col-sm-12 row-margin-top">
                
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Mindstorm</h3>
                        <p>A platform for building and using Robotic Applications for Lego Mindstorms</p>            
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-mindstorm-instance-list" class="go-mindstorm-instance-list btn btn-lg btn-warning landing-label">Platform Instances</span>
                        </div>
                    </div>
                    
                </div>
                    
                <div class="col-sm-6">
                
                    <div class="col-sm-12">
                        <h3>Storage</h3>
                        <p>Always be able to return to a working state</p>          
                    </div>
                    
                    <div class="col-sm-12 landing-row">
                        <div class="col-sm-6">
                            <span id="go-mindstorm-backup-list" class="btn btn-lg btn-warning go-mindstorm-backup-list landing-label">Backups</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-mindstorm-snapshot-list" class="btn btn-lg btn-warning go-mindstorm-snapshot-list landing-label">Snapshots</span>
                        </div>                                      
                    </div>
                    
                </div>
                
            </div> ';


//    $html = $html . '
//            <div class="col-sm-12 row-margin-top">
//
//                <div class="col-sm-6">
//
//                    <div class="col-sm-12">
//                        <h3>Networking &amp; Security</h3>
//                        <p>Providing Control over the networks in which your resources reside.</p>
//                    </div>
//
//                    <div class="col-sm-12 landing-row">
//                        <div class="col-sm-4">
//                            <span id="go-mindstorm-subnetgroup-list" class="go-mindstorm-subnetgroup-list btn btn-lg btn-warning landing-label disabled">Subnet Groups</span>
//                        </div>
//                        <div class="col-sm-4">
//                            <span id="go-mindstorm-parametergroup-list" class="go-mindstorm-parametergroup-list btn btn-lg btn-warning landing-label disabled">Parameter Groups</span>
//                        </div>
//                        <div class="col-sm-4">
//                            <span id="go-mindstorm-optiongroup-list" class="go-mindstorm-optiongroup-list btn btn-lg btn-warning landing-label disabled">Option Groups</span>
//                        </div>
//                    </div>
//
//                </div>
//
//                <div class="col-sm-6">
//                    &nbsp;
//                </div>
//
//            </div>
//
//        </div>';

    return $html ;

} ;