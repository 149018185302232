<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Arduino Design</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Design for Arduino Hardware Controllers</h3>
                    <p>Arduino Programming and Design</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-arduinodesign-bucket-list" class="go-arduinodesign-bucket-list btn btn-lg btn-warning">Buckets</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinodesign-bucket-create" class="go-arduinodesign-bucket-create btn btn-lg btn-warning">Create Bucket</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinodesign-object-list" class="go-arduinodesign-object-list btn btn-lg btn-warning">Objects</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinodesign-object-create" class="go-arduinodesign-object-create btn btn-lg btn-warning">Create Object</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;