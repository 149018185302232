<?php

Namespace Model\SVPC;

use ISOPHP\core;

class PeeringConnectionPageModel extends \Model\Base
{

    public function showSVPCPeeringConnectionCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'PeeringConnectionCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC PeeringConnection Create 2', $vars);
        };
    }

    public function showSVPCPeeringConnectionList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/peeringconnection/all';
            $afterwards = $this->applySVPCPeeringConnectionListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCPeeringConnectionListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC PeeringConnection List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC PeeringConnection List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'PeeringConnectionList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC PeeringConnection List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC PeeringConnection List failed');
            }
        };
        return $func;
    }



    /**
     * Save peeringconnection
     */
    public function savePeeringConnectionAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save peeringconnection to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'peeringconnection-create';
        $vars['api_uri'] = '/api/svpc/peeringconnection/create';
        $vars['svpc_name'] = $jQuery('#svpc_name')->val();
        $vars['cidr_value'] = $jQuery('#cidr_value')->val();
        \ISOPHP\js_core::$console->log('save peeringconnection vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePeeringConnectionResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function savePeeringConnectionResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc peeringconnection result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created PeeringConnection '.$request_data['peeringconnection']['svpc_peeringconnection_name'].' for SVPC '.$request_data['peeringconnection']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc peeringconnection saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating PeeringConnection '.$request_data['peeringconnection']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc peeringconnection save failed');
            }
        };
        return $func;
    }


    /**
     * Delete peeringconnection
     */
    public function deletePeeringConnectionAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete peeringconnection to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'peeringconnection-delete';
        $vars['api_uri'] = '/api/svpc/peeringconnection/delete';
        $vars['svpc_peeringconnection_id'] = $jQuery('#svpc_peeringconnection_id')->val();
        \ISOPHP\js_core::$console->log('delete peeringconnection vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePeeringConnectionResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deletePeeringConnectionResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc peeringconnection result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted PeeringConnection '.$request_data['peeringconnection']['svpc_peeringconnection_name'].' for SVPC '.$request_data['peeringconnection']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc peeringconnection deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'peeringconnection-list', array(), '/svpc/peeringconnection/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting PeeringConnection '.$request_data['peeringconnection']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc peeringconnection delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons Peering Connections');                
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });
                
                $go_svpc_peeringconnection_list = $jQuery('.go-svpc-peeringconnection-list');
                $go_svpc_peeringconnection_list->off() ;
                $go_svpc_peeringconnection_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'peeringconnection-list', array(), '/svpc/peeringconnection/list');
                });

                $go_svpc_peeringconnection_create = $jQuery('.go-svpc-peeringconnection-create');
                $go_svpc_peeringconnection_create->off() ;
                $go_svpc_peeringconnection_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'peeringconnection-create', array(), '/svpc/peeringconnection/create');
                });

                $save_peeringconnection = $jQuery('.attempt-peeringconnection-create');
                $save_peeringconnection->off() ;
                $save_peeringconnection->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'peeringconnection-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $peeringconnection_delete = $jQuery('.attempt-peeringconnection-delete');
                $peeringconnection_delete->off() ;
                $peeringconnection_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $peeringconnection_name = $jqThis->attr('data-svpc-peeringconnection-name') ;
//                    $peeringconnection_id = $jqThis->attr('data-svpc-peeringconnection-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked peeringconnection delete button');
//                    $msg = "You are about to delete the Peering Connection for $peeringconnection_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $peeringconnection_id) {
//                        $navigate->route('SVPC', 'peeringconnection-delete-attempt', array('peeringconnection_id' => $peeringconnection_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

            }
        };
    }


}