<?php

Namespace Model\SC1;

use ISOPHP\core;

class BackupPageModel extends \Model\Base {


    public function showSC1BackupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'BackupCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Backup Create 2', $vars);
        };
    }

    public function showSC1BackupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/backup/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1BackupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1BackupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Backup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Backup List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'BackupList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Backup List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Backup List failed');
            }
        };
        return $func;
    }

    /**
     * Save backup
     */
    public function saveBackupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save backup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'backup-create';
        $vars['api_uri'] = '/api/sc1/backup/create';
        $vars['instance_id'] = $jQuery('#vm_id')->val();
        $vars['backup_name'] = $jQuery('#backup_name')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save backup vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveBackupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveBackupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 backup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Backup '.$request_data['backup']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 backup saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Backup '.$request_data['backup']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 backup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete backup
     */
    public function deleteBackupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete backup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'backup-delete';
        $vars['api_uri'] = '/api/sc1/backup/delete';
        $vars['instance_name'] = $pageVars['params']['instance_id'];
        $vars['backup_id'] = $pageVars['params']['backup_id'];
        \ISOPHP\js_core::$console->log('delete backup vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBackupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBackupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 backup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Backup '.$request_data['backup']['name'].' for Instance '.$request_data['backup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 backup deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Backup '.$request_data['backup']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 backup delete failed');
            }
        };
        return $func;
    }

    /**
     * Delete backup
     */
    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding backup buttons');

                # Backups
                $go_sc1_backup_list = $jQuery('.go-sc1-backup-list');
                $go_sc1_backup_list->off() ;
                $go_sc1_backup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'backup-list', array(), '/sc1/backup/list');
                });

                $go_sc1_backup_list_page = $jQuery('.sc1-backup-paginator');
                $go_sc1_backup_list_page->off() ;
                $go_sc1_backup_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 backup paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'backup-list', array('page' => $new_page), '/sc1/backup/list/page/'.$new_page);
                });

                $go_sc1_backup_create = $jQuery('.go-sc1-backup-create');
                $go_sc1_backup_create->off() ;
                $go_sc1_backup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'backup-create', array(), '/sc1/backup/create');
                });

                $save_backup = $jQuery('.attempt-backup-create');
                $save_backup->off() ;
                $save_backup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'backup-save-attempt', array());
                });

                $backup_delete = $jQuery('.attempt-backup-delete');
                $backup_delete->off() ;
                $backup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $backup_name = $jqThis->attr('data-sc1-backup-name') ;
//                    $backup_id = $jqThis->attr('data-sc1-backup-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked backup delete button');
//                    if ($backup_name !== '') {
//                        $name_or_id = $backup_name ;
//                    } else {
//                        $name_or_id = $backup_id ;
//                    }
//                    $msg = "You are about to delete the Backup $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $backup_id, $backup_name) {
//                        $navigate->route('SC1', 'backup-delete-attempt', array(
//                            'backup_id' => $backup_id,
//                            'backup_name' => $backup_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $backup_restore = $jQuery('.attempt-backup-restore');
                $backup_restore->off() ;
                $backup_restore->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $backup_name = $jqThis->attr('data-sc1-backup-name') ;
//                    $backup_id = $jqThis->attr('data-sc1-backup-id') ;
//                    $instance_name = $jqThis->attr('data-sc1-instance-name') ;
//                    $instance_id = $jqThis->attr('data-sc1-instance-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked backup restore button');
//                    if ($backup_name !== '') {
//                        $name_or_id = $backup_name ;
//                    } else {
//                        $name_or_id = $backup_id ;
//                    }
//                    if ($instance_name !== '') {
//                        $instance_name_or_id = $instance_name ;
//                    } else {
//                        $instance_name_or_id = $instance_id ;
//                    }
//                    $msg = "You are about to restore the Backup $name_or_id, to Instance $instance_name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $backup_id, $instance_id) {
//                        $navigate->route('SC1', 'backup-restore-attempt', array(
//                            'id' => $backup_id,
//                            'instance_id' => $instance_id
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}