<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-12" >
                
                    <div>
                        <h4>New Function</h4>
                    </div>
                    
                    <div class="row">
                    
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-function-create" class="btn btn-success attempt-function-create">Create</span>
                            <span id="go-closure-function-list" class="btn btn-warning go-closure-function-list">Function List</span>
                            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Repository</label>
                                <div id="closure_repository_dropdown">
                                    <select class="form-control" name="repository_name" id="repository_name">
                                        <option>Loading Repositories</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="function_name" class="form-control" id="function_name" value="" />
                            </div>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Runtime</label>
                                <select id="function_runtime" name="function_runtime" class="form-control">
                                     <option value="php_7.2">PHP 7.2</option>
                                     <option value="php_7.3">PHP 7.3</option>
                                     <option value="php_7.4">PHP 7.4</option>
                                     <option value="php_8">PHP 8</option>
                                     <option value="node_12.0">Node 12.0</option>
                                     <option value="node_13.0">Node 13.0</option>
                                     <option value="node_14.0">Node 14.0</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>VPC</label>
                                <div id="closure_vpc_dropdown">
                                    <select class="form-control" name="vpc_name" id="vpc_name">
                                        <option>Loading VPC&apos;s</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                        
                            <div class="col-sm-6 col-6">
                                <div class="col-sm-12 col-12">
                                    <label>Triggers</label>
                                </div>
                                <div class="col-sm-12 col-12">
                                    <select name="function_triggers" id="function_triggers"
                                            class="form-control" multiple="multiple" size="5">
                                        <option value="manual">Manual</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="col-sm-6 col-6">
                                <div class="col-sm-12 col-12">
                                    <label>Destinations</label>
                                </div>
                                <div class="col-sm-12 col-12">
                                    <select name="function_destinations" id="function_destinations"
                                            class="form-control" multiple="multiple" size="5">
                                        <option value="logs">Standard Logs</option>
                                    </select>
                                </div>
                            </div>
                        
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-12 col-12">
                                <label>Code</label>
                            </div>
                            <div class="col-sm-12 col-12">
                                <textarea name="function_code" id="function_code" class="form-control fullWidth" cols="30" rows="12"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-function-create" class="btn btn-success attempt-function-create">Create</span>
                            <span id="go-closure-function-list" class="btn btn-warning go-closure-function-list">Function List</span>
                            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';


    $html = $html . '
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/js/CodeMirror/lib/codemirror.css" />
        <script src="/app/Closure/Assets/js/CodeMirror/lib/codemirror.js"></script>
        
        <script src="/app/Closure/Assets/js/CodeMirror/mode/htmlmixed/htmlmixed.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/htmlembedded/htmlembedded.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/javascript/javascript.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/xml/xml.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/css/css.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/clike/clike.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/php/php.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/addon/display/autorefresh.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/addon/display/fullscreen.js"></script>
        
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/js/CodeMirror/addon/display/fullscreen.css" />
        <script src="/app/Closure/Assets/js/CodeMirror/mode/php/php.js"></script> 
        
        <script src="/app/Closure/Assets/js/code-highlight.js"></script>
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/css/default.css" /> ' ;

    return $html;

};
