<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $loadbalancers_are_array = \ISOPHP\core::$php->is_array($rqd['loadbalancers']) ;
    if ($loadbalancers_are_array === true) {
        $loadbalancers_exist = true ;
    } else {
        $loadbalancers_exist = false ;
    }
    if ($loadbalancers_exist === true) {
        $vm_count = \ISOPHP\core::$php->count($rqd['loadbalancers']) ;
    } else {
        $vm_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-loadbalancer-create" class="btn btn-success go-sc1-loadbalancer-create">New Load Balancer</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-loadbalancer-executionlog-list" class="btn btn-info go-sc1-loadbalancer-executionlog-list">Execution Logs</span>
            <span id="go-sc1-loadbalancer-list" class="btn-refresh btn btn-info go-sc1-loadbalancer-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Load Balancers</h4>
        </div> ' ;
    if ($loadbalancers_exist === true && $vm_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-loadbalancer-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
            '<div class="col-sm-12 col-12 one_sc1_loadbalancer item_list_sc1_loadbalancers page_item_list_title" id="item_list_sc1_loadbalancers">
            <div class="col-sm-1 col-1">
                <h5 class="sc1_loadbalancer_id_title">ID</h5>
            </div>
            <div class="col-sm-3 col-3">
                <h5 class="sc1_loadbalancer_id_title">Name</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="sc1_loadbalancer_id_title">Status</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="sc1_loadbalancer_id_title">Region</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_loadbalancer_id_title">Networks</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_loadbalancer_id_title">DNS</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_loadbalancer_id_title">More</h5>
            </div>
        </div>';

        foreach ($rqd['loadbalancers'] as $one_sc1_loadbalancer) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sc1_loadbalancer">
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' . $one_sc1_loadbalancer['vmid'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_name"><span class="sc1_name sc1_listing">' . $one_sc1_loadbalancer['name'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_status">' . $one_sc1_loadbalancer['status'] . '</p>
                    </div>     
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_region">' . $one_sc1_loadbalancer['region'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">' ;

                foreach ($one_sc1_loadbalancer['nics_expanded'] as $nic_expanded) {
                    $html = $html . '<span class="sc1_list_region">' ;
                    $html = $html . "  ".$nic_expanded['vpc_id'] ;
                    $html = $html . '</span>' ;
                }

            $html = $html .
                   '</div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_dns">' . $one_sc1_loadbalancer['dns_default_public'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="instance_list_button_width">
                                <i id="edit_sc1_loadbalancer_' . $one_sc1_loadbalancer['vmid'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-loadbalancer-edit btn btn-outline-primary"
                                   data-sc1-loadbalancer-id="' . $one_sc1_loadbalancer['vmid'] . '"
                                   data-sc1-loadbalancer-state="' . $one_sc1_loadbalancer['status'] . '"
                                   data-sc1-loadbalancer-name="' . $one_sc1_loadbalancer['name'] . '"></i>
                            </div>
                            <div class="instance_list_button_margin">
                                &nbsp;
                            </div>
                            <div class="instance_list_button_width">
                                <i id="edit_sc1_loadbalancer_' . $one_sc1_loadbalancer['vmid'] . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-loadbalancer-info btn btn-outline-primary"
                                   data-sc1-loadbalancer-id="' . $one_sc1_loadbalancer['vmid'] . '"
                                   data-sc1-loadbalancer-state="' . $one_sc1_loadbalancer['status'] . '"
                                   data-sc1-loadbalancer-name="' . $one_sc1_loadbalancer['name'] . '"></i>
                            </div>
                            <div class="instance_list_button_margin">
                                &nbsp;
                            </div>
                            <div class="instance_list_button_width">
                                <i id="delete_sc1_loadbalancer_' . $one_sc1_loadbalancer['vmid'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-loadbalancer-delete btn btn-outline-danger"
                                   data-sc1-loadbalancer-id="' . $one_sc1_loadbalancer['vmid'] . '"
                                   data-sc1-loadbalancer-name="' . $one_sc1_loadbalancer['name'] . '"></i>
                            </div>
                            <div class="col-sm-1 col-1"> </div>
                        </div>
                    </div>                        
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-loadbalancer-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Load Balancers</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-loadbalancer-create" class="btn btn-success go-sc1-loadbalancer-create">New Load Balancer</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-loadbalancer-executionlog-list" class="btn btn-info go-sc1-loadbalancer-executionlog-list">Execution Logs</span>
            <span id="go-sc1-loadbalancer-list" class="btn-refresh btn btn-info go-sc1-loadbalancer-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Load Balancers</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_loadbalancer_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_loadbalancer_awscli"></i></h5>
                    <pre id="instruction_set_list_loadbalancer_awscli" class="instruction_set instruction_set_list_loadbalancer_awscli">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             
             </div>';


    return $html;

};
