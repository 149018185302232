<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to DNS, your DNS Management Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>DNS Domains and Records</h3>
                        <p>DNS provides Domain Name Records across the Internet or within your VPC&apos;s
                         and supporting all common Record types including A Records, TXT Records and CNAMES.</p>            
                    </div>
                    
                    <div class="col-sm-12">
                    
                        <div class="col-sm-6">
                            <span id="go-dns-domain-list" class="go-dns-domain-list btn btn-lg btn-warning">Domains</span>
                        </div>
                        
                        <div class="col-sm-6">
                            <span id="go-dns-domain-create" class="go-dns-domain-create btn btn-lg btn-warning">Create Domain</span>
                        </div>
                                        
                    </div>
                    
                </div>
                    
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>Health Checks</h3>
                        <p>Ensure Your DNS and Websites are working as intended.</p>          
                    </div>
                    
                    <div class="col-sm-12">
                   
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                        <div class="col-sm-6">
                            <span id="go-dns-domain-list" class="btn btn-lg btn-warning disabled">Health Checks</span>
                        </div>
                        <div class="col-sm-3">
                            &nbsp;
                        </div>
                                        
                    </div>
                </div>
                    
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>Domain Registration</h3>
                        <p>Register your domain names here, and you can manage them here too with automated integration.</p>          
                    </div>
                    
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-dns-domain-register-list" class="btn btn-lg btn-warning disabled">Domains</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-dns-domain-register-new" class="btn btn-lg btn-warning disabled">Register New</span>
                        </div>                                        
                    </div>
                    
                </div>
                
            </div>
          
        </div>';

    return $html ;

} ;
