<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $paymentmethods_are_array = \ISOPHP\core::$php->is_array($rqd['paymentmethods']) ;
    if ($paymentmethods_are_array === true) {
        $paymentmethods_exist = true ;
    } else {
        $paymentmethods_exist = false ;
    }
    if ($paymentmethods_exist === true) {
        $paymentmethod_count = \ISOPHP\core::$php->count($rqd['paymentmethods']) ;
    } else {
        $paymentmethod_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-billing-paymentmethod-create" class="btn btn-success go-billing-paymentmethod-create">Create New Payment Method</span>
            <span id="go-billing-landing" class="btn btn-warning go-billing-landing">Billing Home</span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Payment Method List</h4>
         </div> ' ;

    if ($paymentmethods_exist === true && $paymentmethod_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_billing_paymentmethod">
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">Method</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">Details</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">Default</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_paymentmethod_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['paymentmethods'] as $one_billing_paymentmethod) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_id"><span class="billing_id billing_listing">' . $one_billing_paymentmethod['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_paymentmethod_method"><span class="name billing_listing">' . $one_billing_paymentmethod['method'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_paymentmethod_name"><span class="name billing_listing">' . $one_billing_paymentmethod['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <span class="billing_paymentmethod_data billing_listing">
                        <p class="billing_list_paymentmethod_details">
                        Name:' . $one_billing_paymentmethod['name_on_card'] . '
                        </p> 
                        <p class="billing_list_paymentmethod_details">
                        Card No: **** **** **** '  . $one_billing_paymentmethod['card_number'] .
                        '</p>
                        </span>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_paymentmethod_default"><span class="billing_type billing_listing">' ;

                    if ($one_billing_paymentmethod['default'] == true) {
                        $html = $html .'<i id="edit_billing_paymentmethod_' . $one_billing_paymentmethod['id'] . '"
                                           class="fa fa-1x fa-check hvr-grow dom attempt-paymentmethod-edit btn btn-outline-success"
                                           data-billing-paymentmethod-id="' . $one_billing_paymentmethod['id'] . '"
                                           data-billing-paymentmethod-name="' . $one_billing_paymentmethod['name'] . '"></i>';
                    } else {
                        $html = $html .'<i id="edit_billing_paymentmethod_' . $one_billing_paymentmethod['id'] . '"
                                           class="fas fa-1x fa-times hvr-grow dom attempt-paymentmethod-edit btn btn-outline-danger"
                                           data-billing-paymentmethod-id="' . $one_billing_paymentmethod['id'] . '"
                                           data-billing-paymentmethod-name="' . $one_billing_paymentmethod['name'] . '"></i>';
                    }

                    $html = $html . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_billing_paymentmethod_' . $one_billing_paymentmethod['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-paymentmethod-edit btn btn-outline-primary"
                                   data-billing-paymentmethod-id="' . $one_billing_paymentmethod['id'] . '"
                                   data-billing-paymentmethod-name="' . $one_billing_paymentmethod['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_billing_paymentmethod_' . $one_billing_paymentmethod['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-paymentmethod-delete btn btn-outline-danger"
                                   data-billing-paymentmethod-id="' . $one_billing_paymentmethod['id'] . '"
                                   data-billing-paymentmethod-name="' . $one_billing_paymentmethod['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {


    }

    return $html;

};