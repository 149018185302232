<?php

Namespace Model\Services2;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Services2 List of Piranha Web Services';
        $page['heading'] = 'List of Services';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Services2 Mod getPage', $page, $pageVars);
        return $page;
    }

    public function showServices2Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\Services2View();
            $view->loadTemplate('Services2', 'ServicesLanding2.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Domain Create 2', $vars);
        };
    }

    public function showServices3Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\Services2View();
            $view->loadTemplate('Services2', 'ServicesLanding3.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Landing 3', $vars);
        };
    }

    public function showServices4Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\Services2View();
            $view->loadTemplate('Services2', 'ServicesLanding4.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Landing 4', $vars);
        };
    }

    public function showServices5Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\Services2View();
            $view->loadTemplate('Services2', 'ServicesLanding5.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Landing 5', $vars);
        };
    }

    public function showServices6Landing($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Services Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\Services2View();
            $view->loadTemplate('Services2', 'ServicesLanding6.php', $vars);
            $bind = self::bindButtons();
            $view->displayServices($bind);
            \ISOPHP\js_core::$console->log('apply Services Landing 6', $vars);
        };
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Showing Last Login');
                $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
                \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
                $last_login = $user_logged['last_login'];
                $jQuery = \ISOPHP\js_core::$jQuery;
                $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
                $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
                $jQuery('#latest-login-date')->html($login_date);
                $jQuery('#latest-login-time')->html($login_time);
//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
                return true;
            }
        } ;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_service_menu_service_video = $jQuery('.service_menu_service_video');
                $go_service_menu_service_video->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $msg  = "You are about to be taken to <strong>www.videorentals.co.uk</strong>,";
                    $msg .= " our premium video content hub .<br>Are you sure ?";
                    $confirmAction = function () use ($navigate) {
                        $url = 'https://www.videorentals.co.uk' ;
                        \ISOPHP\js_core::$window->open($url, '_blank')->focus();
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

                $go_service_menu_service_channels = $jQuery('.service_menu_service_channels');
                $go_service_menu_service_channels->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $msg  = "You are about to be taken to <strong>www.streemee.co</strong>,";
                    $msg .= " our user video sharing and streaming hub .<br>Are you sure ?";
                    $confirmAction = function () use ($navigate) {
                        $url = 'https://www.streemee.co' ;
                        \ISOPHP\js_core::$window->open($url, '_blank')->focus();
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

                $go_service_menu_service_webcade = $jQuery('.service_menu_service_webcade');
                $go_service_menu_service_webcade->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $msg  = "You are about to be taken to <strong>www.webcade.co</strong>,";
                    $msg .= " our Gaming Arcade hub .<br>Are you sure ?";
                    $confirmAction = function () use ($navigate) {
                        $url = 'http://www.webcade.co' ;
                        \ISOPHP\js_core::$window->open($url, '_blank')->focus();
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

                $go_service_menu_service_scratchcade = $jQuery('.service_menu_service_scratchcade');
                $go_service_menu_service_scratchcade->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $msg  = "You are about to be taken to <strong>www.scratchcade.com</strong>,";
                    $msg .= " our Scratch Application Platform hub .<br>Are you sure ?";
                    $confirmAction = function () use ($navigate) {
                        $url = 'http://www.scratchcade.com' ;
                        \ISOPHP\js_core::$window->open($url, '_blank')->focus();
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

                $go_service_menu_service_meetings = $jQuery('.service_menu_service_meetings');
                $go_service_menu_service_meetings->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $msg  = "You are about to be taken to <strong>www.devcloud.isophp.org.uk/</strong>,";
                    $msg .= " which has a chat application which our meetings might be based on.<br>";
                    $msg .= "Are you sure ?";
                    $confirmAction = function () use ($navigate) {
                        $url = 'http://www.devcloud.isophp.org.uk/' ;
                        \ISOPHP\js_core::$window->open($url, '_blank')->focus();
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

                $go_service_menu_service_drive = $jQuery('.service_menu_service_drive');
                $go_service_menu_service_drive->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Drive', 'show', array(), '/drive');
                });

                $go_service_menu_service_meeting = $jQuery('.service_menu_service_meeting');
                $go_service_menu_service_meeting->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Meeting', 'show', array(), '/meeting');
                });

                $go_service_menu_service_deviceautomation = $jQuery('.service_menu_service_deviceautomation');
                $go_service_menu_service_deviceautomation->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('DeviceAutomation', 'show', array(), '/deviceautomation');
                });

            }
        };
    }


}