<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="slb-attempt-record-create" class="btn btn-success slb-attempt-record-create">Create</span>
                        <span id="go-slb-record-list" class="btn btn-warning go-slb-record-list">Proxy Record List</span>
                        <span id="go-slb-landing" class="btn btn-warning go-slb-landing">SLB Home</span>
                    </div>
            
                    <div>
                        <h4>New Simple Load Balancer Record</h4>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Friendly Name</label>
                            <input type="text" name="friendly_name" class="form-control" id="friendly_name" value="" />
                        </div>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Instance</label>
                            <div id="sc1_instance_dropdown">
                                Loading Instances
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Use Proxy Protocol?</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="checkbox" id="use_proxy_protocol" name="use_proxy_protocol" class="form-control" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Include HTTP Target?</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="checkbox" id="include_http" name="include_http" class="form-control" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Target HTTP Port</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="text" id="target_http_port" name="target_http_port" class="form-control" value="80" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Include HTTPS Target?</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="checkbox" id="include_https" name="include_https" class="form-control" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Target HTTPS Port</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="text" id="target_https_port" name="target_https_port" class="form-control" value="443" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-6 col-6">
                            <div class="form-group">
                                <label>Alternate Hostnames (Comma Separated)</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <input type="text" id="alternate_hostnames" name="alternate_hostnames" class="form-control" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="slb-attempt-record-create" class="btn btn-success slb-attempt-record-create">Create</span>
                        <span id="go-slb-record-list" class="btn btn-warning go-slb-record-list">Proxy Record List</span>
                        <span id="go-slb-landing" class="btn btn-warning go-slb-landing">SLB Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';


    return $html;

};