<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $queues_are_array = \ISOPHP\core::$php->is_array($rqd['queues']) ;
    if ($queues_are_array === true) {
        $queues_exist = true ;
    } else {
        $queues_exist = false ;
    }
    if ($queues_exist === true) {
        $queue_count = \ISOPHP\core::$php->count($rqd['queues']) ;
    } else {
        $queue_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-ssqs-queue-create" class="btn btn-success go-ssqs-queue-create">New Queue</span>
            <span id="go-ssqs-landing" class="btn btn-info go-ssqs-landing">SSQS Home</span>
            <span id="go-ssqs-list" class="btn btn-info go-ssqs-queue-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Queue List</h4>
         </div> ' ;

    if ($queues_exist === true && $queue_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                        <div class="col-sm-3 col-3">
                            <h5 class="ssqs_queue_id_title">SSQS Queue ID</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="ssqs_queue_id_title">Queue Name</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="ssqs_queue_id_title">Count</h5>
                        </div>                    
                        <div class="col-sm-1 col-1">
                            <h5 class="ssqs_queue_id_title">State</h5>
                        </div>                    
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_queue_id_title">Idle Since</h5>
                        </div>                    
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_queue_id_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['queues'] as $one_ssqs_queue) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_ssqs_queue">
                    <div class="col-sm-3 col-3">
                        <p class="ssqs_list_id"><span class="ssqs_id ssqs_listing">' . $one_ssqs_queue['ssqs_queue_id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="ssqs_list_name"><span class="ssqs_name ssqs_listing">' . $one_ssqs_queue['ssqs_queue_name'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="ssqs_list_queue_count ssqs_listing">' . $one_ssqs_queue['message_count'] . '</p>
                    </div>     
                    <div class="col-sm-1 col-1">
                        <p class="ssqs_list_queue_count ssqs_listing">' . $one_ssqs_queue['state'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_queue_idle ssqs_listing">' . $one_ssqs_queue['idle_since'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_ssqs_queue_' . $one_ssqs_queue['ssqs_queue_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-queue-edit btn btn-outline-primary"
                                   data-ssqs-queue-id="' . $one_ssqs_queue['ssqs_queue_id'] . '"
                                   data-ssqs-queue-name="' . $one_ssqs_queue['ssqs_queue_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_ssqs_queue_' . $one_ssqs_queue['ssqs_queue_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-queue-delete btn btn-outline-danger"
                                   data-ssqs-queue-id="' . $one_ssqs_queue['ssqs_queue_id'] . '"
                                   data-ssqs-queue-name="' . $one_ssqs_queue['ssqs_queue_name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-ssqs-queue-create" class="btn btn-success go-ssqs-queue-create">New Queue</span>
                <span id="go-ssqs-landing" class="btn btn-info go-ssqs-landing">SSQS Home</span>
                <span id="go-ssqs-list" class="btn btn-info go-ssqs-queue-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Queues</h5>
             </div>' ;

    }

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SSQS Queues</strong></h4>
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_queue_awscli"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_queue_awscli"></i></h5>
                    <pre id="instruction_set_list_queue_awscli" class="instruction_set instruction_set_list_queue_awscli">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
             </div>';


    return $html;

};