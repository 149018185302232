<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $devices_are_array = \ISOPHP\core::$php->is_array($rqd['devices']) ;
    if ($devices_are_array === true) {
        $devices_exist = true ;
    } else {
        $devices_exist = false ;
    }
    if ($devices_exist === true) {
        $device_count = \ISOPHP\core::$php->count($rqd['devices']) ;
    } else {
        $device_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-deviceautomation-device-create" class="btn btn-success go-deviceautomation-device-create">New Device</span>
            <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
            <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Device List</h4>
        </div> ' ;

    if ($devices_exist === true && $device_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_deviceautomation_device">
                    <div class="col-sm-2 col-2">
                        <h5 class="deviceautomation_device_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="deviceautomation_device_id_title">Type</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="deviceautomation_device_id_title">Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="deviceautomation_device_id_title">Description</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="deviceautomation_device_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['devices'] as $one_deviceautomation_device) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_deviceautomation_device">
                    <div class="col-sm-2 col-2">
                        <p class="deviceautomation_list_id"><span class="deviceautomation_id deviceautomation_listing">' . $one_deviceautomation_device['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="deviceautomation_list_type"><span class="deviceautomation_type deviceautomation_listing">' . $one_deviceautomation_device['type_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="deviceautomation_list_name"><span class="deviceautomation_name deviceautomation_listing">' . $one_deviceautomation_device['name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="deviceautomation_list_description deviceautomation_listing">' . $one_deviceautomation_device['description'] . '</p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-3 col-3"> ' ;

            $screen_viewable_options = ['doorchime_amazon', 'camera_amazon', 'camera_piranha'] ;
            if (\ISOPHP\core::$php->in_array($one_deviceautomation_device['type'], $screen_viewable_options)) {

                $html = $html .
                    '  <i id="screenview_deviceautomation_device_' . $one_deviceautomation_device['id'] . '"
                           class="fa fa-1x fa-television hvr-grow dom attempt-device-screenview btn btn-outline-success"
                           data-deviceautomation-device-id="' . $one_deviceautomation_device['id'] . '"
                           data-deviceautomation-device-name="' . $one_deviceautomation_device['id'] . '"></i>' ;
            }

            $html = $html .
                '
                            </div> 
                            <div class="col-sm-3 col-3">
                                <i id="control_deviceautomation_device_' . $one_deviceautomation_device['id'] . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-device-control btn btn-outline-primary"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_device['id'] . '"
                                   data-deviceautomation-device-name="' . $one_deviceautomation_device['id'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="recording_history_deviceautomation_device_' . $one_deviceautomation_device['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-deviceautomation-recording-list btn btn-outline-primary"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_device['id'] . '"
                                   data-deviceautomation-device-name="' . $one_deviceautomation_device['id'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="delete_deviceautomation_device_' . $one_deviceautomation_device['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-device-delete btn btn-outline-danger"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_device['id'] . '"
                                   data-deviceautomation-device-name="' . $one_deviceautomation_device['id'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Automated Devices set up</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-deviceautomation-device-create" class="btn btn-success go-deviceautomation-device-create">New Device</span>
                <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
                <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};