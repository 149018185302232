<?php

Namespace Model\SC1;

use ISOPHP\core;

class BalancedGroupPageModel extends \Model\Base {


    public function showSC1BalancedGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'BalancedGroupCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 BalancedGroup Create 2', $vars);
        };
    }

    public function showSC1BalancedGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/balancedgroup/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1BalancedGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1BalancedGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 BalancedGroup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 BalancedGroup List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'BalancedGroupList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 BalancedGroup List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 BalancedGroup List failed');
            }
        };
        return $func;
    }

    /**
     * Save balancedgroup
     */
    public function saveBalancedGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save balancedgroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'balancedgroup-create';
        $vars['api_uri'] = '/api/sc1/balancedgroup/create';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['balancedgroup_name'] = $jQuery('#balancedgroup_name')->val();
        $vars['balancedgroup_type'] = $jQuery('#balancedgroup_type')->val();
        \ISOPHP\js_core::$console->log('save balancedgroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveBalancedGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveBalancedGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 balancedgroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created BalancedGroup '.$request_data['balancedgroup']['sc1_balancedgroup_name'].' for Instance '.$request_data['balancedgroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 balancedgroup saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating BalancedGroup '.$request_data['balancedgroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 balancedgroup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete balancedgroup
     */
    public function deleteBalancedGroupAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete balancedgroup to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'balancedgroup-delete';
        $vars['api_uri'] = '/api/sc1/balancedgroup/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete balancedgroup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBalancedGroupResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBalancedGroupResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 balancedgroup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Balanced Group '.$request_data['balancedgroup']['sc1_balancedgroup_name'].' for Instance '.$request_data['balancedgroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 balancedgroup deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Balanced Group '.$request_data['balancedgroup']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 balancedgroup delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding network interface buttons');

                $go_sc1_balancedgroup_list = $jQuery('.go-sc1-balancedgroup-list');
                $go_sc1_balancedgroup_list->off() ;
                $go_sc1_balancedgroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'balancedgroup-list', array(), '/sc1/balancedgroup/list');
                });

                $go_sc1_balancedgroup_list_page = $jQuery('.sc1-balancedgroup-paginator');
                $go_sc1_balancedgroup_list_page->off() ;
                $go_sc1_balancedgroup_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 balancedgroup paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'balancedgroup-list', array('page' => $new_page), '/sc1/balancedgroup/list/page/'.$new_page);
                });

                $go_sc1_balancedgroup_create = $jQuery('.go-sc1-balancedgroup-create');
                $go_sc1_balancedgroup_create->off() ;
                $go_sc1_balancedgroup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'balancedgroup-create', array(), '/sc1/balancedgroup/create');
                });

                $save_balancedgroup = $jQuery('.attempt-balancedgroup-create');
                $save_balancedgroup->off() ;
                $save_balancedgroup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'balancedgroup-save-attempt', array());
                });

                $balancedgroup_delete = $jQuery('.attempt-balancedgroup-delete');
                $balancedgroup_delete->off() ;
                $balancedgroup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $balancedgroup_name = $jqThis->attr('data-sc1-balancedgroup-name') ;
                    $balancedgroup_id = $jqThis->attr('data-sc1-balancedgroup-id') ;
                    \ISOPHP\js_core::$console->log('Clicked balancedgroup delete button'. $balancedgroup_name. $balancedgroup_id);
                    if ($balancedgroup_name !== '') {
                        $name_or_id = $balancedgroup_name ;
                    } else {
                        $name_or_id = $balancedgroup_id ;
                    }
                    $msg = "You are about to delete the Balanced Group $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $balancedgroup_id, $balancedgroup_name) {
                        $navigate->route('SC1', 'balancedgroup-delete-attempt', array(
                            'id' => $balancedgroup_id,
                            'name' => $balancedgroup_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}