<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $devices_are_array = \ISOPHP\core::$php->is_array($rqd['devices']) ;
    if ($devices_are_array === true) {
        $devices_exist = true ;
    } else {
        $devices_exist = false ;
    }
    if ($devices_exist === true) {
        $device_count = \ISOPHP\core::$php->count($rqd['devices']) ;
    } else {
        $device_count = false ;
    }
    if ($devices_exist === true && $device_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your device:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="device_name" id="device_name">';
        foreach ($rqd['devices'] as $one_mobiledevice_device) {
            $html = $html . '<option value="' . $one_mobiledevice_device['mobiledevice_device_name'] . '">' . $one_mobiledevice_device['mobiledevice_device_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available devices</option>
             </select>' ;

    }

    $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-mobiledevice-landing" class="btn btn-info go-mobiledevice-landing">MobileDevice Home</span>
             </div>' ;

    return $html;

};