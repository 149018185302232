<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    HTTP Url: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$rqd['repository']['url_http'] ;
    $html = $html . '  </div>' ;

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    ID: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$rqd['repository']['id'] ;
    $html = $html . '  </div>' ;     

    $html = $html . '  <div class="col-sm-12 subtitle">' ;
    $html = $html . '    Description: ' ;
    $html = $html . '  </div>' ;
    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    '.$rqd['repository']['description'] ;
    $html = $html . '  </div>' ;        

    $html = $html . '  <div class="col-sm-12">' ;
    $html = $html . '    &nbsp;';
    $html = $html . '  </div>' ;
    
    return $html;

};