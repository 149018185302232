<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $registries_are_array = \ISOPHP\core::$php->is_array($rqd['registries']) ;
    if ($registries_are_array === true) {
        $registries_exist = true ;
    } else {
        $registries_exist = false ;
    }
    if ($registries_exist === true) {
        $registry_count = \ISOPHP\core::$php->count($rqd['registries']) ;
    } else {
        $registry_count = false ;
    }
    if ($registries_exist === true && $registry_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your registry:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="registry_name" id="registry_name">';
        foreach ($rqd['registries'] as $one_pcr_registry) {
            $html = $html . '<option value="' . $one_pcr_registry['pcr_registry_name'] . '">' . $one_pcr_registry['pcr_registry_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available registries</option>
             </select>' ;

    }

    $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-pcr-landing" class="btn btn-info go-pcr-landing">PCR Home</span>
             </div>' ;

    return $html;

};