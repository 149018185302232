<?php

\Core\View::$template = function () {

    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-12" >
                
                    <div>
                        <h4>Update Function</h4>
                    </div>
                    
                    <div class="row">
                    
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-function-create" class="btn btn-success attempt-function-create">Update</span>
                            <span id="go-closure-function-list" class="btn btn-warning go-closure-function-list">Function List</span>
                            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Repository</label>
                                <div id="closure_repository_dropdown">
                                    <select class="form-control" name="repository_name" id="repository_name">
                                        <option>Loading Repositories</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="function_name" class="form-control" id="function_name" value="'.$rqd['function']['name'].'" />
                            </div>
                        </div>
                       
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>ID</label>
                                <input type="text" name="function_id" class="form-control" id="function_id" value="'.$rqd['function']['id'].'" readonly="readonly" />
                            </div>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Runtime</label>
                                <select id="function_runtime" name="function_runtime" class="form-control"> ';

    $runtimes = [
       ['slug' => 'php_7.2', 'friendly' => 'PHP 7.2'] ,
       ['slug' => 'php_7.3', 'friendly' => 'PHP 7.3'] ,
       ['slug' => 'php_7.4', 'friendly' => 'PHP 7.4'] ,
       ['slug' => 'php_8.0', 'friendly' => 'PHP 8.0'] ,
       ['slug' => 'node_12.0', 'friendly' => 'Node 12.0'] ,
       ['slug' => 'node_13.0', 'friendly' => 'Node 13.0'] ,
       ['slug' => 'node_14.0', 'friendly' => 'Node 14.0'] ,
    ] ;
    foreach ($runtimes as $runtime) {
        if ($runtime['slug'] === $rqd['function']['runtime']) {
            $html =  $html . ' <option value="'.$runtime['slug'].'" selected="selected">'.$runtime['friendly'].'</option>' ;
        } else {
            $html =  $html . ' <option value="'.$runtime['slug'].'">'.$runtime['friendly'].'</option> ' ;
        }
    }

    $html =  $html . '   
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>VPC</label>
                                <div id="closure_vpc_dropdown">
                                    <select class="form-control" name="vpc_name" id="vpc_name">
                                        <option>Loading VPC&apos;s</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                        
                            <div class="col-sm-6 col-6">
                                <div class="col-sm-12 col-12">
                                    <label>Triggers</label>
                                </div>
                                <div class="col-sm-12 col-12">
                                    <select name="function_triggers" id="function_triggers"
                                            class="form-control" multiple="multiple" size="5">';

    $chosen_triggers = \ISOPHP\core::$php->explode(',', $rqd['function']['triggers']) ;
    $available_triggers = [
        ['slug' => 'manual', 'friendly' => 'Manual']
    ] ;
    foreach ($available_triggers as $available_trigger) {
        if (in_array($available_trigger, $chosen_triggers)) {
            $html =  $html . ' <option value="'.$available_trigger['slug'].'" selected="selected">'.$available_trigger['friendly'].'</option>' ;
        } else {
            $html =  $html . ' <option value="'.$available_trigger['slug'].'">'.$available_trigger['friendly'].'</option> ' ;
        }
    }

    $html =  $html . '              </select>
                                </div>
                            </div>
                            
                            <div class="col-sm-6 col-6">
                                <div class="col-sm-12 col-12">
                                    <label>Destinations</label>
                                </div>
                                <div class="col-sm-12 col-12">
                                    <select name="function_destinations" id="function_destinations"
                                            class="form-control" multiple="multiple" size="5">';

    $chosen_destinations = \ISOPHP\core::$php->explode(',', $rqd['function']['destinations']) ;
    $available_destinations = [
        ['slug' => 'logs', 'friendly' => 'Standard Logs']
    ] ;
    foreach ($available_destinations as $available_destination) {
        if (in_array($available_destination, $chosen_destinations)) {
            $html =  $html . ' <option value="'.$available_destination['slug'].'" selected="selected">'.$available_destination['friendly'].'</option>' ;
        } else {
            $html =  $html . ' <option value="'.$available_destination['slug'].'">'.$available_destination['friendly'].'</option> ' ;
        }
    }

    $html =  $html . '              </select>
                                </div>
                            </div>
                            
                        </div> ';

    $html = $html . '   <div class="col-sm-12 col-12">
                            <div class="col-sm-12 col-12">
                                <label>Code</label>
                            </div>
                            <div class="col-sm-12 col-12">
                                <textarea name="function_code" id="function_code" class="form-control fullWidth" cols="30" rows="12">' ;

    $html = $html . \ISOPHP\core::$php->htmlspecialchars($rqd['function']['code']) ;

    $html = $html .            '</textarea>
                            </div>
                            <input type="hidden" id="log_data_hidden" value="'.\ISOPHP\core::$php->htmlspecialchars($rqd['function']['code']).'" />
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-function-create" class="btn btn-success attempt-function-create">Update</span>
                            <span id="go-closure-function-list" class="btn btn-warning go-closure-function-list">Function List</span>
                            <span id="go-closure-landing" class="btn btn-warning go-closure-landing">Closure Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';



    $html = $html . '
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/js/CodeMirror/lib/codemirror.css" />
        <script src="/app/Closure/Assets/js/CodeMirror/lib/codemirror.js"></script>
        
        <script src="/app/Closure/Assets/js/CodeMirror/mode/htmlmixed/htmlmixed.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/htmlembedded/htmlembedded.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/javascript/javascript.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/xml/xml.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/css/css.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/clike/clike.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/mode/php/php.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/addon/display/autorefresh.js"></script>
        <script src="/app/Closure/Assets/js/CodeMirror/addon/display/fullscreen.js"></script>
        
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/js/CodeMirror/addon/display/fullscreen.css" />
        <script src="/app/Closure/Assets/js/CodeMirror/mode/php/php.js"></script> 
        
        <script src="/app/Closure/Assets/js/code-highlight.js"></script>
        <link rel="stylesheet" href="' . CSS_ASSET_PREFIX . 'app/Closure/Assets/css/default.css" /> ' ;

    return $html;

};
