<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $recordings_are_array = \ISOPHP\core::$php->is_array($rqd['recordings']) ;
    if ($recordings_are_array === true) {
        $recordings_exist = true ;
    } else {
        $recordings_exist = false ;
    }
    if ($recordings_exist === true) {
        $recording_count = \ISOPHP\core::$php->count($rqd['recordings']) ;
    } else {
        $recording_count = false ;
    }

    $html = $html .
        '
         <div class="col-sm-12 col-12 text-center">
            <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
            <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">Device Automation List</span>
            <span id="go-deviceautomation-recording-list" data-deviceautomation-device-id="' . $rqd['device_id'] .'" class="btn btn-info go-deviceautomation-recording-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
         </div>

         <div class="col-sm-12 col-12">
            <h4>Recording List for Device: <strong>'.$rqd['device_id'].'</strong></h4>
         </div> ' ;

    if ($recordings_exist === true && $recording_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_deviceautomation_recording">
                        <div class="col-sm-3 col-3">
                            <h5 class="deviceautomation_recording_table_title">ID</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="deviceautomation_recording_table_title">Start</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="deviceautomation_recording_table_title">Length</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="deviceautomation_recording_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['recordings'] as $one_deviceautomation_recording) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-3 col-3">
                        <p class="deviceautomation_list_id"><span class="deviceautomation_id deviceautomation_listing">' . $one_deviceautomation_recording['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="deviceautomation_list_device_name"><span class="deviceautomation_device_name deviceautomation_listing">' . $one_deviceautomation_recording['start_friendly'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="deviceautomation_list_recording_name"><span class="deviceautomation_recording_name deviceautomation_listing">' . $one_deviceautomation_recording['duration'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="playerview_deviceautomation_recording_' . $one_deviceautomation_recording['id'] . '"
                                   class="fa fa-1x fa-television hvr-grow dom go-deviceautomation-recording-play btn btn-outline-success"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_recording['device_id'] . '"
                                   data-deviceautomation-recording-id="' . $one_deviceautomation_recording['id'] . '"
                                   data-deviceautomation-recording-name="' . $one_deviceautomation_recording['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="download_deviceautomation_recording_' . $one_deviceautomation_recording['id'] . '"
                                   class="fa fa-1x fa-download hvr-grow dom go-deviceautomation-recording-download btn btn-outline-success"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_recording['device_id'] . '"
                                   data-deviceautomation-recording-id="' . $one_deviceautomation_recording['id'] . '"
                                   data-deviceautomation-recording-name="' . $one_deviceautomation_recording['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_deviceautomation_recording_' . $one_deviceautomation_recording['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-recording-delete btn btn-outline-danger"
                                   data-deviceautomation-device-id="' . $one_deviceautomation_recording['device_id'] . '"
                                   data-deviceautomation-recording-id="' . $one_deviceautomation_recording['id'] . '"
                                   data-deviceautomation-recording-name="' . $one_deviceautomation_recording['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>
                 
                 ';
        }

        $html .= '
         <div class="col-sm-12 col-12 text-center">
            <span id="go-deviceautomation-landing" class="btn btn-info go-deviceautomation-landing">Device Automation Home</span>
            <span id="go-deviceautomation-device-list" class="btn btn-info go-deviceautomation-device-list">Device Automation List</span>
            <span id="go-deviceautomation-recording-list" data-deviceautomation-device-id="' . $rqd['device_id'] .'" class="btn btn-info go-deviceautomation-recording-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
         </div> ' ;

    } else {


    }

    return $html;

};