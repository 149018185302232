<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to SSQS, your Message Queueing Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Queues and Messages</h3>
                    <p>A system for Queues and Messages allows services and clients across the Internet or within your SVPC&apos;s
                    to make use of High Availability, Scalable Queueing Technology.</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-ssqs-queue-list" class="go-ssqs-queue-list btn btn-lg btn-warning">Queues</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-ssqs-queue-create" class="go-ssqs-queue-create btn btn-lg btn-warning">Create Queue</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-ssqs-message-list" class="go-ssqs-message-list btn btn-lg btn-warning">Messages</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-ssqs-message-create" class="go-ssqs-message-create btn btn-lg btn-warning">Create Message</span>
                    </div>
                                    
                </div>
                
            </div>
          
        </div>';

    return $html ;

} ;