<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $usage_histories_are_array = \ISOPHP\core::$php->is_array($rqd['usage_history']) ;
    if ($usage_histories_are_array === true) {
        $usage_history_exists = true ;
    } else {
        $usage_history_exists = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-billing-invoice-pay" class="btn btn-success go-billing-invoice-pay">Pay Current Invoice</span>
            <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
            <span id="go-billing-invoice-list" class="btn btn-info go-billing-invoice-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Usage History</h4>
        </div> ' ;

    if ($usage_history_exists === true) {
        $html = $html .'
                 <div class="col-sm-12 col-12">
                        <div class="col-sm-2 col-2">
                            <h5 class="billing_usage_history_id_title">Period</h5>
                        </div>
                        <div class="col-sm-4 col-4">' ;
        $html = $html . $rqd['usage_history']['period_format']  ;
        $html = $html .
                       '</div>
                        <div class="col-sm-3 col-3">
                            <h5 class="billing_usage_history_id_title">Amount</h5>
                        </div>
                        <div class="col-sm-3 col-3"><strong>' ;
        $html = $html . '£'.$rqd['usage_history']['amount']  ;
        $html = $html .
                       '</strong></div>                        
                    </div>
                 </div> ';

        if (\ISOPHP\core::$php->count($rqd['usage_history']['chargeable_items']) > 0) {


            $html = $html .
                '<div class="col-sm-12 col-12">
                    <h6>Chargeable Items</h6>
                 </div>';

            $html = $html .
                '<div id="billing_invoice_chargeableitems" class="col-sm-12 col-12"> ';

            if ($rqd['usage_history']['chargeable_item_page_count'] > 1) {
                $html = $html .
                    '<div class="col-sm-12">';
                $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['usage_history']['chargeable_item_page_count'], $rqd['usage_history']['chargeable_item_page'], 'billing-invoice-chargeableitem-paginator') ;
                $html = $html .'</div>' ;
            }

            $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <h6>Entity</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Charge Type</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Entity ID</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Start</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Price</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Period</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Unit</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Item Total</h6>
                    </div> 
                 </div>';
            $html = $html . '<div id="chargeableitems_table" class="col-sm-12 col-12">' ;

            foreach ($rqd['usage_history']['chargeable_items_page'] as $chargeable_item) {
                $entity_id_short = \ISOPHP\core::$php->substr($chargeable_item['entity_id'], 0, 10) ;
                $html = $html .
                '<div class="col-sm-12 col-12 one_billing_chargeable_item">
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_id"><span class="billing_id billing_listing">' . $chargeable_item['entity'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_name"><span class="billing_name billing_listing">' . $chargeable_item['charge_type_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_id">
                            <span class="billing_id billing_listing tooltip" data-toggle="tooltip" title="' . $chargeable_item['entity_id'] . '">
                                ' . $entity_id_short . '
                            </span>
                        </p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="billing_list_comment billing_listing">' . $chargeable_item['charge_period_start_friendly'] . '</p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="billing_list_comment billing_listing">' . $chargeable_item['price_per_charge_period'] . '</p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="billing_list_comment billing_listing">' . $chargeable_item['total_charge_period'] . '</p>
                    </div>    
                    <div class="col-sm-1 col-1">
                        <p class="billing_list_comment billing_listing">' . $chargeable_item['charge_period_unit'] . '</p>
                    </div>  
                    <div class="col-sm-2 col-2">
                        <p class="billing_list_comment billing_listing">' . $chargeable_item['total_charge'] . '</p>
                    </div>                       
                 </div>';
            }

            $html = $html . '</div>' ;

            if ($rqd['usage_history']['chargeable_item_page_count'] > 1) {
                $html = $html .
                    '<div class="col-sm-12">';
                $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['usage_history']['chargeable_item_page_count'], $rqd['usage_history']['chargeable_item_page'], 'billing-invoice-chargeableitem-paginator') ;
                $html = $html .'</div>' ;
            }

            $html = $html . '</div>';

        } else {
            $html = $html .
            '<div class="col-sm-12">
                <h6>There are no Chargeable Items</h6>
             </div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There is no available history</h5>
             </div>' ;

    }

    $html = $html .
       '<div class="col-sm-12 col-12 text-center">
            <span id="go-billing-invoice-pay" class="btn btn-success go-billing-invoice-pay">Pay Current Invoice</span>
            <span id="go-billing-landing" class="btn btn-info go-billing-landing">Billing Home</span>
            <span id="go-billing-invoice-list" class="btn btn-info go-billing-invoice-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};
