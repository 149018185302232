<?php

Namespace Controller;

class CostPredictionController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Cost Prediction controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Cost Prediction, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\CostPrediction\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'CostPrediction.php';
            $res->type = 'view';
            $res->view_control = 'CostPrediction';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showCostPredictionLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'prediction-list') {
                $res->post_template[] = $page_model->showCostPredictionPredictionList($pageVars);
            } else if ($pageVars['route']['action'] == 'prediction-create') {
                $res->post_template[] = $page_model->showCostPredictionPredictionCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'prediction-save-attempt') {
                $page_model->savePredictionAttemptToCostPrediction($pageVars);
                $res->view = 'CostPrediction.php';
                $res->control = 'CostPrediction';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'prediction-delete-attempt') {
                $page_model->deletePredictionAttemptToCostPrediction($pageVars);
                $res->view = 'CostPrediction.php';
                $res->control = 'CostPrediction';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showCostPredictionLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('CostPrediction Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}