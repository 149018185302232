<?php

Namespace Model\UserSettings ;

class PageModel extends \Model\Base {

    public $returned_data ;

    public function getPage() {
        $page = array() ;
        $page['title'] = 'User Settings for Piranha Web Services';
        $page['heading'] = 'User Settings';
        \ISOPHP\js_core::$console->log('UserSettings Mod', $page) ;
        return $page ;
    }

    public function getUserSettingsResult($pageVars) {
        $vars['control'] = 'UserSettings' ;
        $vars['action'] = 'attempt' ;
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $form_valid = $this->validateForm() ;
        if ($form_valid !== true) {
            $message = 'Invalid Registration Form' ;
            \Core\WindowMessage::showMessage($message, 'bad') ;
            return false ;
        }
        if ($form_valid === true) {
            $message = 'Valid Registration Form - Trying now...' ;
            \Core\WindowMessage::showMessage($message, 'good') ;
            return true ;
        }
        $result = $this->performRequest($vars, null) ;
        \ISOPHP\js_core::$console->log('UserSettings Data Model: ', $result) ;
        return $result ;
    }

    public function applyUserSettingsResult($pageVars) {
        $func = function($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our billing result to', $request_data) ;
            if ($request_data['status'] === 'OK') {
                \ISOPHP\js_core::$console->log('saying hello') ;
                $welcome_message = "Hello, ".$request_data->user->name ;
                \Core\WindowMessage::showMessage($welcome_message, 'good') ;
                $navigate = new \Model\Navigate() ;
                $extra_params = $pageVars['params'] ;
                $extra_params['user_logged'] = $request_data->user ;
                \ISOPHP\js_core::$console->log('apply billing', $extra_params ) ;
                $navigate->route('HomeSummary', 'show', $extra_params) ;
            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = 'UserSettings Failed' ;
                \ISOPHP\js_core::$console->log('saying '.$welcome_message) ;
                \Core\WindowMessage::showMessage($welcome_message, 'bad') ;
            }
        } ;
        return $func ;
    }


    public function showAccountOptions($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Account Options') ;
            $vars['control'] = 'Account' ;
            $vars['action'] = 'summary' ;

            $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;
            \ISOPHP\js_core::$console->log('Logged user at UserSettings: ', $user_logged) ;

            $vars['user_id'] = $user_logged['user_id'] ;
            $afterwards = $this->applyAccountOptionsResult($pageVars) ;
            $result = $this->performRequest($vars, $afterwards, true) ;
            \ISOPHP\js_core::$console->log('UserSettings Account Options Model: ', $result) ;
            return $result ;
        } ;
    }

    public function applyAccountOptionsResult($pageVars) {
        $func = function($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Account Options result to', $request_data) ;
            if ($request_data['status'] === 'OK') {
                $vars = array() ;
                $vars['params'] = $pageVars['params'] ;
                $vars['request_data'] = $request_data ;
                \ISOPHP\js_core::$console->log('apply Account Options 1', $vars ) ;
                $view = new \View\UserSettingsView();
                $vars['type'] = 'billing' ;
                $view->loadTemplate ('UserSettings', 'UserSettingsAccountDropdown.php', $vars) ;
                $view->displayDropdown($vars['type']) ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Account Options failed') ;
            }
        } ;
        return $func ;
    }

    public function showCardOptions($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Card Options') ;
            $vars['control'] = 'Account' ;
            $vars['action'] = 'summary' ;

            $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;
            \ISOPHP\js_core::$console->log('Logged user at UserSettings: ', $user_logged) ;

            $vars['user_id'] = $user_logged['user_id'] ;
            $afterwards = $this->applyCardOptionsResult($pageVars) ;
            $result = $this->performRequest($vars, $afterwards, true) ;
            \ISOPHP\js_core::$console->log('UserSettings Card Options Model: ', $result) ;
            return $result ;
        } ;
    }

    public function applyCardOptionsResult($pageVars) {
        $func = function($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Card Options result to', $request_data) ;
            if ($request_data['status'] === 'OK') {
                $vars = array() ;
                $vars['params'] = $pageVars['params'] ;
                $vars['request_data'] = $request_data ;
                \ISOPHP\js_core::$console->log('apply Card Options 1', $vars ) ;
                $view = new \View\UserSettingsView();
                $vars['type'] = 'billing' ;
                $view->loadTemplate ('UserSettings', 'UserSettingsCardDropdown.php', $vars) ;
                $view->displayDropdown($vars['type']) ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Card Options failed') ;
            }
        } ;
        return $func ;
    }

    public function validateForm() {
        $jQuery = \ISOPHP\js_core::$jQuery ;
        $vars = array() ;
        $vars['user'] = $jQuery('#username')->val() ;
        $vars['email'] = $jQuery('#email')->val() ;
        $vars['pass'] = $jQuery('#pass')->val() ;
        $vars['pass_conf']= $jQuery('#pass_conf')->val() ;
        $user_length = \ISOPHP\core::$php->strlen($vars['user']) ;
        if ($user_length < 3) {
            $error_message = 'User field must be at least 3 characters' ;
            \Core\WindowMessage::showMessage($error_message, 'bad') ;
            return false ;
        }
        $email_length = \ISOPHP\core::$php->strlen($vars['email']) ;
        if ($email_length < 3) {
            $error_message = 'Email field must be at least 3 characters' ;
            \Core\WindowMessage::showMessage($error_message, 'bad') ;
            return false ;
        }
        $pass_length = \ISOPHP\core::$php->strlen($vars['pass']) ;
        if ($pass_length < 3) {
            $error_message = 'Password field must be at least 3 characters' ;
            \Core\WindowMessage::showMessage($error_message, 'bad') ;
            return false ;
        }
        if ($vars['pass'] !== $vars['pass_conf']) {
            $error_message = 'Password Confirmation field must be at least 3 characters' ;
            \Core\WindowMessage::showMessage($error_message, 'bad') ;
            return false ;
        }
        return true ;
    }

    public static function bindButtons() {
        return function () {
            \ISOPHP\js_core::$console->log('Binding buttons') ;
            $jQuery = \ISOPHP\js_core::$jQuery ;
            $perform_billing = $jQuery('#perform-billing') ;
            $perform_billing->on('click', function () {
                $navigate = new \Model\Navigate() ;
                $navigate->route('UserSettings', 'attempt', array()) ;
            }) ;
            $cancel_billing = $jQuery('#cancel-billing') ;
            $cancel_billing->on('click', function () {
                $navigate = new \Model\Navigate() ;
                $navigate->route('HomeSummary', 'default', array()) ;
            }) ;
        } ;
    }

}