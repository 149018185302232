<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $containers_are_array = \ISOPHP\core::$php->is_array($rqd['containers']) ;
    if ($containers_are_array === true) {
        $containers_exist = true ;
    } else {
        $containers_exist = false ;
    }
    if ($containers_exist === true) {
        $container_count = \ISOPHP\core::$php->count($rqd['containers']) ;
    } else {
        $container_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcs-container-create" class="btn btn-success go-pcs-container-create">Create New Container</span>
            <span id="go-pcs-landing" class="btn btn-warning go-pcs-landing">PCS Home</span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Container List</h4>
         </div> ' ;

    if ($containers_exist === true && $container_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_pcs_container">
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">PCS Container ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">Definition</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcs_container_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['containers'] as $one_pcs_container) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="pcs_list_id"><span class="pcs_id pcs_listing">' . $one_pcs_container['pcs_container_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcs_list_definition_name"><span class="pcs_definition_name pcs_listing">' . $one_pcs_container['pcs_definition_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcs_list_container_name"><span class="pcs_container_name pcs_listing">' . $one_pcs_container['pcs_container_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcs_list_container_data"><span class="pcs_container_data pcs_listing">' . $one_pcs_container['pcs_container_value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcs_list_container_type"><span class="pcs_type pcs_listing">' . $one_pcs_container['pcs_container_type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="edit_pcs_container_' . $one_pcs_container['pcs_container_id'] . '"
                                   class="fa fa-1x fa-info-square hvr-grow dom attempt-container-edit btn btn-outline-primary"
                                   data-pcs-container-id="' . $one_pcs_container['pcs_container_id'] . '"
                                   data-pcs-container-name="' . $one_pcs_container['pcs_container_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="edit_pcs_container_' . $one_pcs_container['pcs_container_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-container-edit btn btn-outline-primary"
                                   data-pcs-container-id="' . $one_pcs_container['pcs_container_id'] . '"
                                   data-pcs-container-name="' . $one_pcs_container['pcs_container_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_pcs_container_' . $one_pcs_container['pcs_container_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-container-delete btn btn-outline-danger"
                                   data-pcs-container-id="' . $one_pcs_container['pcs_container_id'] . '"
                                   data-pcs-container-name="' . $one_pcs_container['pcs_container_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {


    }

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing PCS Containers</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_container_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_container_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_container_awscli_aws" class="instruction_set instruction_set_list_container_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceContainerSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceContainerSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_container_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_container_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_container_ptc_aws" class="instruction_set instruction_set_list_container_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceContainerSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceContainerSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};