<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Piranha Web Services</h1>
                <p>Choose your next Service here.</p>
            </div> ';

    $html = $html . '     
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_compute col-sm-12">
                        <h4>Compute</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sc1">
                        <strong>Virtual Machines:</strong> Virtual Machines in the Cloud <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_closure">
                        <strong>Closure Function:</strong> Serverless Cloud Compute for Code Execution <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_closure_repo">
                        <strong>Closure Repository:</strong> A Repository for Closure Applications <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_closure_program">
                        <strong>Closure Program:</strong> Execute multiple <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_storage col-sm-12">
                        <h4>Storage</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ss3">
                        <strong>Simple:</strong> Piranha&apos;s S3 compatible File Storage System <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_containers col-sm-12">
                        <h4>Containers</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry beta_service service_menu_service_pcr">
                        <strong>Registry:</strong> Docker Container Registry <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>                    
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pcs">
                        <strong>Orchestration:</strong> Our Container Orchestration Service <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pcs">
                        <strong>Kubernetes:</strong> Kubernetes on Piranha <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_database col-sm-12">
                        <h4>Data</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_ssqs">
                        <strong>Queues:</strong> Piranha managed AMQP Queues <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_database_document">
                        <strong>Document:</strong> Managed Piranha DB and Mongo DB Instances <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_database_relational">
                        <strong>Relational:</strong> Managed MySQL and PostgreSQL Instances <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_hadoop">
                        <strong>Hadoop:</strong> Hadoop Big Data Services <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
                
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_end_user_computing col-sm-12">
                        <h4>End User Computing</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_remotedesktop">
                        <strong>Desktop:</strong> Remote Windows User Desktops
                    </span>
                </div>

                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_office col-sm-12">
                        <h4>Office</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_drive">
                        <strong>Drive:</strong> Cloud Storage for Desktop and Mobile
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_documents">
                        <strong>Documents:</strong> Text Documents and Desktop Publishing
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spreadsheets">
                        <strong>Spreadsheets:</strong> Spreadsheets for Tabular Data
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_slides">
                        <strong>Slides:</strong> Slides Presentation Publishing
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_meeting">
                        <strong>Meetings:</strong> Messaging and Meetings for Teams
                    </span>
                </div>

                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_gaming col-sm-12">
                        <h4>Gaming</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_gamenode">
                        <strong>Game Server:</strong> Roblox, Minecraft and other Game Servers 
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_scratchnode">
                        <strong>Scratch Server:</strong> Standalone Servers for Scratch Applications
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_webcade">
                        <strong>Webcade:</strong> Our Arcade Gaming Platform for Applications across Languages
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_scratchcade">
                        <strong>Scratchcade:</strong> Our Arcade Gaming Platform for Scratch Applications
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_organization col-sm-12">
                        <h4>Organization</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        <strong>Access:</strong> Access Management <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Billing:</strong> Managing Invoices
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_costing">
                        <strong>Cost Prediction:</strong> Predict the cost of resources you&apos;re using <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn col-sm-12 service_page_entry service_menu_group service_menu_group_physics col-sm-12">
                        <h4>Physics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_sam">
                        <strong>Ballistics:</strong> Ballistic Trajectory Modelling <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Fuel Modelling:</strong> Managing Invoices
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Wind Modelling:</strong> Modelling effects of Wind
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_billing">
                        <strong>Fluid Mechanics Modelling:</strong> Modelling effects of Fluid Mechanics
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_spacepropulsion col-sm-12">
                        <h4>Space and Propulsion</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_enginemodelling">                        
                        <strong>Engine Modelling:</strong> Control Homes and Property Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_tracjectorymodelling">
                        <strong>Trajectory Modelling:</strong> Control Drones and Flying Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_vehicle">
                        <strong>Vehicle:</strong> Control Vehicles and Logistic Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_satellitemodelling">
                        <strong>Satellite Modelling:</strong> Control Vehicles and Logistic Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_satellitegroundstation">
                        <strong>Satellite Ground Station:</strong> Control Vehicles and Logistic Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
                
            </div> ';

    $html = $html . '    
            <div class="col-sm-6">
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_networking col-sm-12">
                        <h4>Networking</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_svpc">
                        <strong>VPC:</strong> Virtual Private Cloud Networking <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_dns">
                        <strong>DNS:</strong> DNS Services in the Cloud <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pmp">
                        <strong>Mini Proxy:</strong> Piranha Mini Proxy - VM Access with DNS instead of IP Addresses <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_developer_tools col-sm-12">
                        <h4>Developer Tools</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_build">
                        <strong>Build:</strong> Build Servers for your Automation <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_scm">
                        <strong>SCM:</strong> Source Code Management Repositories <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_pipeline">
                        <strong>Pipelines:</strong> Automatic scripting for your Code Repositories <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_artifact">
                        <strong>Artifacts:</strong> Artifact Repositories for your Packages <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_front_end_mobile col-sm-12">
                        <h4>Mobile</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_mobiledevice">                        
                        <strong>Devices:</strong> Real Mobile Devices for Application Testing <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_mobileemulator">                        
                        <strong>Emulation:</strong> Emulated Mobile Devices for Application Testing <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_analytics col-sm-12">
                        <h4>Analytics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_appanalytics">
                        <strong>Applications:</strong> Web Page and Mobile Application Analytics <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/beta.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_machine_learning col-sm-12">
                        <h4>Machine Learning</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_transcribe">
                        <strong>Transcribe:</strong> Speech to Text Conversion
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_lex">
                        <strong>Lex:</strong> Amazon&apos;s Voice Recognition Tool
                    </span>
                </div>

                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_robotics col-sm-12">
                        <h4>Robotics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_mindstorm">
                        <strong>Lego Mindstorm:</strong> Expand your Robot&apos;s Functions on our Platform
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_arduinodesign">
                        <strong>Arduino Design:</strong> Design for Arduino Boards and Components
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_arduinoexecute">
                        <strong>Arduino Execute:</strong> Execution for your Arduino Programs on Real Arduinos
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_blockchain col-sm-12">
                        <h4>Blockchain</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_blockchain">
                        <strong>Managed Blockchain:</strong> Managed Instances for major Blockchains
                    </span>
                </div>

                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group_media col-sm-12">
                        <h4>Media</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_video">
                        <strong>Video: </strong> Premium Video Content to Rent or Buy
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_channels">
                        <strong>Channels: </strong> Video Hosting for Public Channels 
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_deviceautomation col-sm-12">
                        <h4>Device Automation</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_deviceautomation_device">                        
                        <strong>Devices:</strong> Control Homes and Property Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_deviceautomation_drone">
                        <strong>Drone:</strong> Control Drones and Flying Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_deviceautomation_vehicle">
                        <strong>Vehicle:</strong> Control Vehicles and Logistic Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
          
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_robotics col-sm-12">
                        <h4>Robotics</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_robotics">                        
                        <strong>Walking:</strong> Synthesize Proteins on Demand <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_robotics_satellitegroundstation">
                        <strong>Driving:</strong> Polymerase Chain Reaction <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_robotics_satellitegroundstation">
                        <strong>Dexterity:</strong> Synthesize Neurons on Demand <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_naturalsciences col-sm-12">
                        <h4>Natural Sciences</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_naturalsciences">
                        <strong>Science at Scale:</strong> Control Homes and Property Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_spacepropulsion_satellitegroundstation">
                        <strong>Mouse Automation:</strong> Control Homes and Property Devices <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
            
                <div class="col-sm-12 service_page_entry_margin">
                    <span class="btn service_page_entry service_menu_group service_menu_group_biotechnology col-sm-12">
                        <h4>Biotechnology</h4>
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology">                        
                        <strong>Protein Synthesis:</strong> Synthesize Proteins on Demand <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology_pcr">
                        <strong>PCR:</strong> Polymerase Chain Reaction <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology_neuronsynthesis">
                        <strong>Neuron Synthesis:</strong> Synthesize Neurons on Demand <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology_electrophoresis">
                        <strong>Electrophoresis:</strong> Perform visual comparisons of DNA Samples <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology_cellmodelling">
                        <strong>Cell Modelling:</strong> Create and Execute Models of Cells <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                    <span class="btn col-sm-12 service_page_entry service_menu_service_biotechnology_dnasequencing">
                        <strong>DNA Sequencing:</strong> Perform Sequencing of DNA Samples <img class="services_betagamma_image" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/gamma.png" />
                    </span>
                </div>
                    
            </div> ';

    return $html ;

} ;