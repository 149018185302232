<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $messages_are_array = \ISOPHP\core::$php->is_array($rqd['messages']) ;
    if ($messages_are_array === true) {
        $messages_exist = true ;
    } else {
        $messages_exist = false ;
    }
    if ($messages_exist === true) {
        $message_count = \ISOPHP\core::$php->count($rqd['messages']) ;
    } else {
        $message_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-ssqs-message-create" class="btn btn-success go-ssqs-message-create">Create New Message</span>
            <span id="go-ssqs-landing" class="btn btn-warning go-ssqs-landing">SSQS Home</span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Message List</h4>
         </div> ' ;

    if ($messages_exist === true && $message_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_ssqs_message">
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">SSQS Message ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">Queue</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="ssqs_message_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['messages'] as $one_ssqs_message) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_id"><span class="ssqs_id ssqs_listing">' . $one_ssqs_message['ssqs_message_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_queue_name"><span class="ssqs_queue_name ssqs_listing">' . $one_ssqs_message['ssqs_queue_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_message_name"><span class="ssqs_message_name ssqs_listing">' . $one_ssqs_message['ssqs_message_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_message_data"><span class="ssqs_message_data ssqs_listing">' . $one_ssqs_message['ssqs_message_value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="ssqs_list_message_type"><span class="ssqs_type ssqs_listing">' . $one_ssqs_message['ssqs_message_type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_ssqs_message_' . $one_ssqs_message['ssqs_message_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-message-edit btn btn-outline-primary"
                                   data-ssqs-message-id="' . $one_ssqs_message['ssqs_message_id'] . '"
                                   data-ssqs-message-name="' . $one_ssqs_message['ssqs_message_name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_ssqs_message_' . $one_ssqs_message['ssqs_message_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-message-delete btn btn-outline-danger"
                                   data-ssqs-message-id="' . $one_ssqs_message['ssqs_message_id'] . '"
                                   data-ssqs-message-name="' . $one_ssqs_message['ssqs_message_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Messages</h5>
             </div>' ;

    }

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SSQS Messages</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_message_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_message_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_message_awscli_aws" class="instruction_set instruction_set_list_message_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_message_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_message_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_message_ptc_aws" class="instruction_set instruction_set_list_message_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceMessageSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};