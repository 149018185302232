<?php

Namespace Controller;

class BillingController extends \Controller\Base
{

    public function execute($pageVars)
    {


        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Billing controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Billing, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }
            $page_model = new \Model\Billing\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Billing.php';
            $res->type = 'view';
            $res->view_control = 'Billing';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showBillingLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'paymentmethod-list') {
                $res->post_template[] = $page_model->showBillingPaymentMethodList($pageVars);
            } else if ($pageVars['route']['action'] == 'paymentmethod-create') {
                $res->post_template[] = $page_model->showBillingPaymentMethodCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'invoice-list') {
//            $res->post_template[] = $page_model->showBillingPaymentMethodListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindPaymentMethodListDropDownButtonsForInvoiceList();
                $res->post_template[] = $page_model->showBillingInvoiceList($pageVars);
            } else if ($pageVars['route']['action'] == 'usage-history') {
                $res->post_template[] = $page_model->showBillingUsageHistory($pageVars);
            } else if ($pageVars['route']['action'] == 'invoice-chargeableitem-list') {
                $showlist = $page_model->showBillingInvoiceChargeableItemsList($pageVars);
                $showlist();
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'invoice-create') {
                $res->post_template[] = $page_model->showBillingInvoiceCreate($pageVars);
                $res->post_template[] = $page_model->showBillingPaymentMethodListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindPaymentMethodListDropDownButtonsForInvoiceCreate();
            } else if ($pageVars['route']['action'] == 'paymentmethod-save-attempt') {
                $page_model->savePaymentMethodAttemptToBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'invoice-save-attempt') {
                $page_model->saveInvoiceAttemptToBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'paymentmethod-delete-attempt') {
                $page_model->deletePaymentMethodAttemptToBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'invoice-delete-attempt') {
                $page_model->deleteInvoiceAttemptToBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'csvinvoice-download-attempt') {
                $page_model->downloadCSVInvoiceAttemptFromBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'pdfinvoice-download-attempt') {
                $page_model->downloadPDFInvoiceAttemptFromBilling($pageVars);
                $res->view = 'Billing.php';
                $res->control = 'Billing';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showBillingLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('Billing Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}
