<?php

Namespace Model\SAM;

use ISOPHP\core;

class PolicyPageModel extends \Model\Base
{

    static $current_policy ;
    static $policy_entities ;

    public function showSAMPolicyCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'PolicyCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM Policy Create 2', $vars);
        };
    }

    public function showSAMPolicyList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/policy/all';
            $afterwards = $this->applySAMPolicyListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMPolicyListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM Policy List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM Policy List 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'PolicyList.php', $vars);
                $sam_page_model = new \Model\SAM\PageModel();
                $bind1 = $sam_page_model->bindButtons();
                $bind2 = self::bindButtons();
                $binds = function () use ($bind1, $bind2) {
                    $bind1() ;
                    $bind2() ;
                } ;
                $view->displaySAM($binds);
                \ISOPHP\js_core::$console->log('apply SAM Policy List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM Policy List failed');
            }
        };
        return $func;
    }

    public function showSAMPolicyEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/policy/one';
            $vars['id'] = $pageVars['params']['policy_id'];
            $afterwards = $this->applySAMPolicyEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMPolicyEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM Policy Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM Policy Edit 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'PolicyEdit.php', $vars);
                $sam_page_model = new \Model\SAM\PageModel();
                $bind1 = $sam_page_model->bindButtons();
                $bind2 = self::bindButtons();
                $binds = function () use ($bind1, $bind2) {
                    $bind1() ;
                    $bind2() ;
                } ;
                $view->displaySAM($binds);
                \ISOPHP\js_core::$console->log('apply SAM Policy Edit 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM Policy Edit failed');
            }
        };
        return $func;
    }



    public function showSAMPolicyEntityListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                #Get pre populate skill  drop down data
                $vars['api_uri'] = '/api/sam/policyentity/all';
                $afterwards = $this->applySAMPolicyEntityListDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards);
                return $result;
            }
        };
    }

    public function applySAMPolicyEntityListDropDownResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data) {
                $policy_entities = $request_data['policyentities'];
                self::$policy_entities = $policy_entities;
                $policy_entities_select = '<option >Select your Policy Entity</option>';
                if ($policy_entities) {
                    foreach ($policy_entities as $RowData) {
                        $policy_entities_select = $policy_entities_select . '<option value="' . $RowData['id'] . '">' . $RowData['name'] . '</option>';
                    }
                }
                $pes = $jQuery('#permission_entity_select') ;
                $pes->html($policy_entities_select);
                $pes->select2();
            }
        };
        return $func;
    }


    /**
     * Save policy
     */
    public function savePolicyAttemptToSAM($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save policy to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'policy-create';
        $vars['api_uri'] = '/api/sam/policy/create';
        $vars['policy_name'] = $jQuery('#policy_name')->val();
        $vars['perms'] = self::$current_policy ;
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePolicyResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function savePolicyResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sam policy result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Policy '.$request_data['policy']['name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam policy saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Policy '.$request_data['policy']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam policy save failed');
            }
        };
        return $func;
    }

    /**
     * Delete group
     */
    public function deletePolicyAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete group to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'policy-delete';
        $vars['api_uri'] = '/api/sam/policy/delete';
        $vars['id'] = $pageVars['params']['id'];
        $vars['name'] = $pageVars['params']['name'];
        \ISOPHP\js_core::$console->log('delete policy vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deletePolicyResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deletePolicyResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sam policy result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Policy '.$request_data['policy']['name'].' for Policy '.$request_data['policy']['sam_policy_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam policy deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Policy '.$request_data['policy']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam policy delete failed');
            }
        };
        return $func;
    }



    public function addSAMPolicyPermission($pageVars) {

        $jQuery = \ISOPHP\js_core::$jQuery;
        $pes = $jQuery('#permission_entity_select') ;
        $entity = $pes->val();
        $pac = $jQuery('#permission_available_create') ;
        $par = $jQuery('#permission_available_read') ;
        $paw = $jQuery('#permission_available_write') ;
        $pal = $jQuery('#permission_available_list') ;
        $pad = $jQuery('#permission_available_delete') ;
        $create_enabled = $pac->is(":checked");
        $read_enabled = $par->is(":checked");
        $write_enabled = $paw->is(":checked");
        $list_enabled = $pal->is(":checked");
        $delete_enabled = $pad->is(":checked");

        $entity_length = \ISOPHP\core::$php->strlen($entity);
        if ($entity_length == 0 || $entity == 0) {
            $error_message = 'Entity must not be empty';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }
        if (
            $create_enabled == false &&
            $read_enabled == false &&
            $write_enabled == false &&
            $list_enabled == false &&
            $delete_enabled == false
        ) {
            $error_message = 'At least one of the create, read, write, delete and list permissions must be chosen';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }
        $entity_name = $entity ;
        $policyentities = self::$policy_entities ;
        foreach ($policyentities as $policyentity) {
            if ($policyentity['id'] == $entity) {
                $entity_name = $policyentity['name'] ;
                break ;
            }
        }

        $html =         '<div class="fullRow">' ;
        $html = $html . '<strong>'.$entity_name.': </strong>' ;
        $perms = array() ;
        if ($create_enabled == true) {
            $perms['create'] = true ;
            $html = $html . 'Create&nbsp;' ;
        } else {
            $perms['create'] = false ;
        }
        if ($read_enabled == true) {
            $perms['read'] = true ;
            $html = $html . 'Read&nbsp;' ;
        } else {
            $perms['read'] = false ;
        }
        if ($write_enabled == true) {
            $perms['write'] = true ;
            $html = $html . 'Write&nbsp;' ;
        } else {
            $perms['write'] = false ;
        }
        if ($list_enabled == true) {
            $perms['list'] = true ;
            $html = $html . 'List&nbsp;' ;
        } else {
            $perms['list'] = false ;
        }
        if ($delete_enabled == true) {
            $perms['delete'] = true ;
            $html = $html . 'Delete&nbsp;' ;
        } else {
            $perms['delete'] = false ;
        }
        $html = $html . '</div>' ;

        $current_policy = self::$current_policy ;
        $current_policy[$entity_name] = $perms ;
        self::$current_policy = $current_policy ;

        $current_permissions = $jQuery('#current_permissions') ;
        $current_permissions->append($html) ;

        $pes->val('') ;
        $pac->removeAttr('checked');
        $par->removeAttr('checked');
        $paw->removeAttr('checked');
        $pal->removeAttr('checked');
        $pad->removeAttr('checked');

        return true;
    }



    public function deleteSAMPolicyPermission($pageVars) {

        $jQuery = \ISOPHP\js_core::$jQuery;
        $pes = $jQuery('#permission_entity_select') ;
//
//        $current_policy = self::$current_policy ;
//        unset($current_policy[$entity_name]) ;
//        self::$current_policy = $current_policy ;

        return true;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_sam_policy_account = $jQuery('.go-sam-policy-account');
                $go_sam_policy_account->off() ;
                $go_sam_policy_account->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'policy-account', array(), '/sam/policy/account');
                });

                $add_sam_policy_permission = $jQuery('.add-sam-policy-permission');
                $add_sam_policy_permission->off() ;
                $add_sam_policy_permission->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'policy-permission-add', array(), '/sam/policy/permission/add');
                });

                $go_sam_policy_list = $jQuery('.go-sam-policy-list');
                $go_sam_policy_list->off() ;
                $go_sam_policy_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'policy-list', array(), '/sam/policy/list');
                });

                $go_sam_policy_create = $jQuery('.go-sam-policy-create');
                $go_sam_policy_create->off() ;
                $go_sam_policy_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'policy-create', array(), '/sam/policy/create');
                });

                $save_policy = $jQuery('.attempt-policy-create');
                $save_policy->off() ;
                $save_policy->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'policy-save-attempt', array());
                });

                $policy_delete = $jQuery('.attempt-policy-delete');
                $policy_delete->off() ;
                $policy_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $policy_name = $jqThis->attr('data-sam-policy-name') ;
//                    $policy_id = $jqThis->attr('data-sam-policy-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked policy delete button '.$policy_name.' '.$policy_id);
//                    $msg = "You are about to delete the Policy $policy_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $policy_id, $policy_name) {
//                        $navigate->route('SAM', 'policy-delete-attempt', array(
//                            'name' => $policy_name,
//                            'id' => $policy_id
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        };
    }


}