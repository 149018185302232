<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-pipeline-create" class="btn btn-success attempt-pipeline-execute">Execute</span>
                        <span id="go-build-pipeline-list" class="btn btn-info go-build-pipeline-list">Build Job List</span>
                        <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                    </div>
                        
                    <div>
                        <h4>New Job</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="pipeline_name" class="form-control" id="pipeline_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea name="description" id="description" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Shell</label>
                            <textarea name="shell_data" id="shell_data" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-pipeline-execute" class="btn btn-success attempt-pipeline-execute">Execute</span>
                        <span id="go-build-pipeline-list" class="btn btn-info go-build-pipeline-list">Build Job List</span>
                        <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                    </div>
                    
                </div>
            </div>
            
        </div>';

    return $html;

};