<?php

\Core\View::$template = function() {
    $html = '
        <form id="pharaoh_form">
            <header class="col-sm-12 navbar">
                <div class="col-sm-2">
                    <img class="nav_logo" src="/app/Default/Assets/images/digital-money-direct-logo-small.png" />
                </div>
                <div class="col-sm-8">
                    <h1>Billing Settings</h1>
                </div>
                <div class="col-sm-2">
                    <div id="user-menu" class="user-menu"></div>
                    <div id="settings-menu" class="settings-menu"></div>
                </div>
            </header>
            <div class="col-sm-12">
                <p>We will aim to bill you from your accounts first. You can pick an account and card you prefer to be billed from here.</p>
            </div> 
            <div class="col-sm-12">
                <div class="col-sm-6">
                    <h2>Account</h2>
                </div> 
                <div class="col-sm-6" id="billing_account_wrap">
                    &nbsp;
                </div> 
            </div> 
            <div class="col-sm-12">
                <div class="col-sm-6">
                    <h2>Card</h2>
                </div> 
                <div class="col-sm-6" id="card_account_wrap">
                    &nbsp;
                </div> 
            </div> ' ;

    $html = $html . '    <div class="col-sm-12">' ;
    $html = $html . '        <span id="perform-billing" class="btn btn-success">Save</span>' ;
    $html = $html . '    </div>' ;
    $html = $html . '    <div class="col-sm-12">' ;
    $html = $html . '        <span id="cancel-billing" class="btn btn-info">Home</span>' ;
    $html = $html . '    </div>' ;
    $html = $html . '</form>' ;
    return $html ;

} ;