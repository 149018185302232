<?php

Namespace Model\SAM;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SAM List of Piranha Web Services';
        $page['heading'] = 'List of SAM';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SAM Mod', $page, $pageVars);
        return $page;
    }

    public function showSAMLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'SAMLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM Group Create 2', $vars);
        };
    }

    public function showSAMGroupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'GroupCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM Group Create 2', $vars);
        };
    }

    public function showSAMGroupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Group List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/group/all';
            $afterwards = $this->applySAMGroupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMGroupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM Group List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM Group List 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'GroupList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAM($bind);
                \ISOPHP\js_core::$console->log('apply SAM Group List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM Group List failed');
            }
        };
        return $func;
    }

    public function showSAMGroupListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/group/all';
            $afterwards = $this->applySAMGroupListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMGroupListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM Group List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM Group List 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'GroupListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAMGroupDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SAM Group List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM Group List failed');
            }
        };
        return $func;
    }

    public function showSAMUserCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'UserCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM User Create 2', $vars);
        };
    }

    public function showSAMUserEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Edit');
            $vars = array();
            $vars['params'] = $pageVars['params'];

            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/user/account';
            $vars['keysets'] = 'true';
            $vars['id'] = $pageVars['params']['user_id'] ;
            $afterwards = $this->applySAMUserEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);

            \ISOPHP\js_core::$console->log('SAM Edit Model: ', $result);

        };
    }


    public function applySAMUserEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 User Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 User Edit 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'UserEdit.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAM($bind);
                $view->loadTemplate('SAM', 'KeysetList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAMUserKeysetList($bind);
                \ISOPHP\js_core::$console->log('apply SC1 User Edit 2', $vars);

                $jQuery = \ISOPHP\js_core::$jQuery;
                $jQuery('#username')->val($request_data['user']['username']) ;
                $jQuery('#userpass')->val() ;
                $jQuery('#full_name')->val($request_data['user']['fullname']) ;
                $jQuery('#email')->val($request_data['user']['email']) ;
                $jQuery('#description')->val($request_data['user']['description']) ;
                $jQuery('#edit_user_id')->val($request_data['user']['id']) ;

                $bind = self::bindButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 User Edit failed');
            }
        };
        return $func;
    }

    public function showSAMUserList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/user/all';
            $afterwards = $this->applySAMUserListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMUserListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM User List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM User List 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'UserList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAM($bind);
                \ISOPHP\js_core::$console->log('apply SAM User List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM User List failed');
            }
        };
        return $func;
    }

    public function showSAMRoleCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'RoleCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM Role Create 2', $vars);
        };
    }

    public function showSAMRoleList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM List');
            $vars['control'] = 'SAM';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sam/role/all';
            $afterwards = $this->applySAMRoleListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function applySAMRoleListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SAM Role List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SAM Role List 1', $vars);
                $view = new \View\SAMView();
                $view->loadTemplate('SAM', 'RoleList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySAM($bind);
                \ISOPHP\js_core::$console->log('apply SAM Role List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SAM Role List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save user
     */
    public function saveUserAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save user to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'user-create';
        $vars['api_uri'] = '/api/sam/user/create';
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['username'] = $jQuery('#username')->val();
        $vars['email'] = $jQuery('#email')->val();
        $vars['password'] = $jQuery('#userpass')->val();
        $vars['description'] = $jQuery('#description')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveUserResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveUserResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sam user result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created User '.$request_data['user']['username'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam user saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'user-list', array(), '/sam/user/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating User '.$request_data['user']['username'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam user save failed');
            }
        };
        return $func;
    }

    /**
     * Save user
     */
    public function editUserAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save user to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'user-account';
        $vars['api_uri'] = '/api/sam/user/account';
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['username'] = $jQuery('#username')->val();
        $vars['email'] = $jQuery('#email')->val();
        $vars['password'] = $jQuery('#userpass')->val();
        $vars['description'] = $jQuery('#description')->val();

        $vars['id'] = $pageVars['params']['user_id'] ;

        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->editUserResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function editUserResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Edit sam user result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Edited User '.$request_data['user']['username'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam user edited, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'user-list', array(), '/sam/user/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Editing User '.$request_data['user']['username'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam user edit failed');
            }
        };
        return $func;
    }

    /**
     * Save group
     */
    public function saveGroupAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save group to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'group-create';
        $vars['api_uri'] = '/api/sam/group/create';
        $vars['name'] = $jQuery('#group_name')->val();
        \ISOPHP\js_core::$console->log('save group vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveGroupResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveGroupResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sam group result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Group '.$request_data['group']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam group saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'group-list', array(), '/sam/group/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Group '.$request_data['group']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam group save failed');
            }
        };
        return $func;
    }

    /**
     * Save role
     */
    public function saveRoleAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save role to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'role-create';
        $vars['api_uri'] = '/api/sam/role/create';
        $vars['name'] = $jQuery('#name')->val();
        \ISOPHP\js_core::$console->log('save role vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRoleResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRoleResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sam role result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Role '.$request_data['role']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam role saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'role-list', array(), '/sam/role/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Role '.$request_data['role']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam role save failed');
            }
        };
        return $func;
    }

    /**
     * Save role
     */
    public function saveKeysetAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('save user keyset to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'user-keyset-create';
        $vars['api_uri'] = '/api/sam/user/keyset/create';
        $vars['name'] = $jQuery('#keyset_new_name')->val();
        $vars['user_id'] = $jQuery('#edit_user_id')->val() ;
        \ISOPHP\js_core::$console->log('save user keyset vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveKeysetResultFromSAM($pageVars, $vars['user_id']);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveKeysetResultFromSAM($pageVars, $user_id)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars, $user_id) {
            \ISOPHP\js_core::$console->log('applying our Save sam user keyset result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Keyset '.$request_data['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam user keyset saved, data: ', $request_data);

                $msg = "Your new Keyset named " . $request_data['name'] . " has been created. "."<br />";
                $msg .= "Key ID: " . $request_data['key_id']. "<br />";
                $msg .= "Secret: " . $request_data['secret']. "<br />";
                $msg .= "Please store these credentials now, as you will be unable to access them after this window is closed.";

                \Core\WindowMessage::showOverlay($msg);
                $jQuery('#keyset_new_name')->val('') ;

                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'user-edit', array('user_id' => $user_id), '/sam/user/edit/'.$user_id);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Keyset '.$request_data['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam keyset create failed');
            }
        };
        return $func;
    }
    /**
     * Delete group
     */
    public function deleteGroupAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete group to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'group-delete';
        $vars['api_uri'] = '/api/sam/group/delete';
        $vars['id'] = $pageVars['params']['group_id'];
        \ISOPHP\js_core::$console->log('delete group vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteGroupResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteGroupResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sam group result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Group '.$request_data['group'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam group deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'group-list', array(), '/sam/group/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Group '.$request_data['group'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam group delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete group
     */
    public function deleteUserAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete user to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'user-delete';
        $vars['api_uri'] = '/api/sam/user/delete';
        $vars['id'] = $pageVars['params']['user_id'];
        \ISOPHP\js_core::$console->log('delete user vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteUserResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteUserResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sam user result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted User '.$request_data['user'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam user deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'user-list', array(), '/sam/user/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting User '.$request_data['user'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam user delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete role
     */
    public function deleteRoleAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete role to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'role-delete';
        $vars['api_uri'] = '/api/sam/role/delete';
        $vars['id'] = $pageVars['params']['role_id'];
        \ISOPHP\js_core::$console->log('delete role vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteRoleResultFromSAM($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRoleResultFromSAM($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sam role result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Role '.$request_data['role'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam role deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'role-list', array(), '/sam/role/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Role '.$request_data['role'] ;
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam role delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete keyset
     */
    public function deleteKeysetAttemptToSAM($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete keyset to sam');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SAM';
        $vars['action'] = 'keyset-delete';
        $vars['api_uri'] = '/api/sam/user/keyset/delete';
        $vars['id'] = $pageVars['params']['id'];
        $vars['user_id'] = $jQuery('#edit_user_id')->val() ;
        \ISOPHP\js_core::$console->log('delete keyset vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deleteKeysetResultFromSAM($pageVars, $vars['user_id']);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteKeysetResultFromSAM($pageVars, $user_id)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars, $user_id) {
            \ISOPHP\js_core::$console->log('applying our Delete sam keyset result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Keyset '.$request_data['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sam keyset deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SAM', 'user-edit', array('user_id' => $user_id), '/sam/user/edit/'.$user_id);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Keyset '.$request_data['name'] ;
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sam keyset delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_sam_landing = $jQuery('.go-sam-landing');
                $go_sam_landing->off() ;
                $go_sam_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'landing', array(), '/sam');
                });

                $go_sam_user_account = $jQuery('.go-sam-user-account');
                $go_sam_user_account->off() ;
                $go_sam_user_account->on('click', function ($jsthis) use ($navigate) {
                    $jsthis->preventDefault();
                    $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;
                    $navigate->route('SAM', 'user-edit', array('user_id' => $user_logged['user']['id'] ), '/sam/user/edit/'.$user_logged['user']['id'] );
                });

                $go_sam_user_list = $jQuery('.go-sam-user-list');
                $go_sam_user_list->off() ;
                $go_sam_user_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'user-list', array(), '/sam/user/list');
                });

                $go_sam_keyset_create = $jQuery('.go-sam-keyset-create');
                $go_sam_keyset_create->off() ;
                $go_sam_keyset_create->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $user_id = $jQuery('#edit_user_id')->val() ;
                    $new_keyset_name = $jQuery('#keyset_new_name')->val() ;
                    if ($new_keyset_name === '') {
                        $msg = 'Keyset requires a Name' ;
                        \Core\WindowMessage::showMessage($msg, 'bad') ;
                    } else {
                        $msg = "You are about to create a new Keyset named $new_keyset_name for the User ID $user_id.<br>Are you sure?";
                        $confirmAction = function () use ($navigate, $user_id) {
                            $navigate->route('SAM', 'user-keyset-create', array('user_id' => $user_id));
                        };
                        \Core\WindowMessage::areYouSure($msg, $confirmAction);
                    }

                });

                $go_sam_user_create = $jQuery('.go-sam-user-create');
                $go_sam_user_create->off() ;
                $go_sam_user_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'user-create', array(), '/sam/user/create');
                });

                $save_user = $jQuery('.attempt-user-create');
                $save_user->off() ;
                $save_user->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'user-save-attempt', array());
                });

                $go_sam_user_edit = $jQuery('.go-sam-user-edit');
                $go_sam_user_edit->off() ;
                $go_sam_user_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $user_id = $jqThis->attr('data-sam-user-id') ;
                    $navigate->route('SAM', 'user-edit', array('user_id' => $user_id), '/sam/user/edit/'.$user_id);
                });

                $edit_user = $jQuery('.attempt-user-edit');
                $edit_user->off() ;
                $edit_user->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $user_id = $jqThis->attr('data-sam-user-id') ;
                    $navigate->route('SAM', 'user-edit-attempt', array('user_id' => $user_id));
                });

                $user_delete = $jQuery('.attempt-user-delete');
                $user_delete->off() ;
                $user_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $user_name = $jqThis->attr('data-sam-user-name') ;
                    $user_id = $jqThis->attr('data-sam-user-id') ;
                    \ISOPHP\js_core::$console->log('Clicked user delete button');
                    $msg = "You are about to delete the User $user_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $user_id) {
                        $navigate->route('SAM', 'user-delete-attempt', array('user_id' => $user_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

                $keyset_delete = $jQuery('.attempt-keyset-delete');
                $keyset_delete->off() ;
                $keyset_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $user_id = $jQuery('#edit_user_id')->val() ;
                    $keyset_id = $jqThis->attr('data-sam-keyset-id') ;
                    $keyset_name = $jqThis->attr('data-sam-keyset-name') ;
                    \ISOPHP\js_core::$console->log('Clicked keyset delete button');
                    $msg = "You are about to delete the Keyset $keyset_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $keyset_id) {
                        $navigate->route('SAM', 'keyset-delete-attempt', array('id' => $keyset_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;



                $go_sam_group_list = $jQuery('.go-sam-group-list');
                $go_sam_group_list->off() ;
                $go_sam_group_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'group-list', array(), '/sam/group/list');
                });

                $go_sam_group_create = $jQuery('.go-sam-group-create');
                $go_sam_group_create->off() ;
                $go_sam_group_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'group-create', array(), '/sam/group/create');
                });

                $save_group = $jQuery('.attempt-group-create');
                $save_group->off() ;
                $save_group->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'group-save-attempt', array());
                });

                $group_delete = $jQuery('.attempt-group-delete');
                $group_delete->off() ;
                $group_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $group_name = $jqThis->attr('data-sam-group-name') ;
//                    $group_id = $jqThis->attr('data-sam-group-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked group delete button');
//                    $msg = "You are about to delete the Group $group_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $group_id) {
//                        $navigate->route('SAM', 'group-delete-attempt', array('group_id' => $group_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $go_sam_role_list = $jQuery('.go-sam-role-list');
                $go_sam_role_list->off() ;
                $go_sam_role_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'role-list', array(), '/sam/role/list');
                });

                $go_sam_role_create = $jQuery('.go-sam-role-create');
                $go_sam_role_create->off() ;
                $go_sam_role_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SAM', 'role-create', array(), '/sam/role/create');
                });

                $save_role = $jQuery('.attempt-role-create');
                $save_role->off() ;
                $save_role->on('click', function ($jqThis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked role create button');
                    $jqThis->preventDefault();
//                    $role_name = $jQuery('#role_name')->val() ;
//                    \ISOPHP\js_core::$console->log('role name', $role_name);
//                    $navigate->route('SAM', 'role-save-attempt', array(
//                        'role_name' => $role_name
//                    ));
                });

                $role_delete = $jQuery('.attempt-role-delete');
                $role_delete->off() ;
                $role_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked role delete button');
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $role_name = $jqThis->attr('data-sam-role-name') ;
//                    $role_id = $jqThis->attr('data-sam-role-id') ;
//                    $msg = "You are about to delete the Role $role_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $role_id) {
//                        $navigate->route('SAM', 'role-delete-attempt', array('role_id' => $role_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;



                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

            }
        };
    }


}
