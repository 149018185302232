<?php

Namespace Controller;

class SSQSController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SSQS controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SSQS, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }
            $page_model = new \Model\SSQS\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SSQS.php';
            $res->type = 'view';
            $res->view_control = 'SSQS';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSSQSLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'queue-list') {
                $res->post_template[] = $page_model->showSSQSQueueList($pageVars);
            } else if ($pageVars['route']['action'] == 'queue-create') {
                $res->post_template[] = $page_model->showSSQSQueueCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'message-list') {
                $res->post_template[] = $page_model->showSSQSMessageList($pageVars);
            } else if ($pageVars['route']['action'] == 'message-create') {
                $res->post_template[] = $page_model->showSSQSMessageCreate($pageVars);
                $res->post_template[] = $page_model->showSSQSQueueListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'queue-save-attempt') {
                $page_model->saveQueueAttemptToSSQS($pageVars);
                $res->view = 'SSQS.php';
                $res->control = 'SSQS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'message-save-attempt') {
                $page_model->saveMessageAttemptToSSQS($pageVars);
                $res->view = 'SSQS.php';
                $res->control = 'SSQS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'queue-delete-attempt') {
                $page_model->deleteQueueAttemptToSSQS($pageVars);
                $res->view = 'SSQS.php';
                $res->control = 'SSQS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'message-delete-attempt') {
                $page_model->deleteMessageAttemptToSSQS($pageVars);
                $res->view = 'SSQS.php';
                $res->control = 'SSQS';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSSQSLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SSQS Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}