<?php

$userdata = \Core\View::$page_vars['user_data'];

$config = new \Model\Configuration();
$server_url = $config->variable('ISOPHP_API_SERVER_URL');

\Core\View::$template = function () use ($userdata, $server_url) {
    \ISOPHP\js_core::$console->log('Showing select option', $userdata);

    $html = '';
    $html = $html . '
        <header class="col-sm-12 navbar inside-navbar">
            <div class="col-sm-4 xs-center">            
                <img class="nav_logo" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/piranha-corp.png" height="80" />
            </div>
            <div class="col-sm-4 xs-center">
                <span>Hello, <strong>' . $userdata['full_name'] . '</strong></span> 
                <p>Home @ Piranha Web Services</p>
            </div>
            <div class="col-sm-4 xs-center">
                <div id="user-menu-button" class="menu-button user-menu-button menu-btn-top">
                    <!--<div id="user_menu_entries" class="menu_entries user_menu_entries">
                        <div class="menu_entry">
                            <span id="user_menu_link_job_list">Job List</span>
                        </div>
                    </div>-->
                </div>
                <div id="user-menu-button" class="menu-button user-menu-button"></div>
                <div id="settings-menu-button" class="menu-button settings-menu-button"></div>
            </div>
        </header>  ';

    $html = $html . '
    <section class="latest">
          <div class="container-fluid"> 
            <div class="row">
              <div class="col-sm-12 text-center">
                <div class="latest_wrap">
                  <p><label>Latest Login: </label><span id="latest-login-date"> </span><span  id="latest-login-time"> </span></p>
                </div> 
                
              </div>  
            </div>
          </div>
        </section>';

    $html = $html . '
    <div class="container">
        <div class="col-sm-12" id="home_summary_accounts" style="padding-bottom: 120px;">
        <div class="row">';

    $html = $html . '    
        <div class="col-sm-6">
            <div>
                <h4>Profile</h4>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Full Name</label>
                        <input type="text" name="full_name" class="form-control" id="full_name" value="' . $userdata['user_full_name'] . '"/>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" name="email" id="email" class="form-control" value="' . $userdata['user_email'] . '"/>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Phone Number</label>
                        <input type="tel" maxlength="11" name="phone_number" class="form-control" id="phone_number" value="' . $userdata['user_phone_number'] . '"/>
                    </div>
                </div>
            </div>
        </div>';

    if ($userdata['user_type'] == 2) {

        $html = $html . '
            <div class="col-sm-6 center-block-candidate">
                <div>
                    <h4>Additional Info</h4>
                </div> 
                <div class="row">              
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Company Name</label>

                            <input type="text" name="company_name" class="form-control" id="company_name" value="' . $userdata['user_company_name'] . '"/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Address</label>
                            <textarea name="address" id="address" class="form-control" rows="10" cols="30">' . $userdata['user_address'] . '</textarea>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-save-employer" class="btn btn-success">Save</span>
                            <span id="cancel-save-employer"  class="btn btn-danger">Cancel</span>
                        </div>
                    </div>
                </div>
            </div>';

    } else {

        $html = $html . '
        <div class="col-sm-6 center-block-candidate">            
            <div>
                <h4>Additional Info</h4>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Address</label>
                        <input type="text" name="candidate_address" id="candidate_address" class="form-control" value="' . $userdata['user_address'] . '" /> 
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Date Of Birth</label>
                        <input type="date" name="candidate_date_of_birth" id="candidate_date_of_birth" class="form-control" value="' . $userdata['user_dob'] . '"/>
                    </div> 
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Gender</label>
                        <select id="candidate_gender" name="candidate_gender" class="form-control">
                         <option ' . (($userdata['user_gender'] == "") ? "selected" : "") . ' value="">Select</option>
                         <option ' . (($userdata['user_gender'] == "male") ? "selected" : "") . ' value="male">Male</option>
                         <option ' . (($userdata['user_gender'] == "female") ? "selected" : "") . ' value="female">Female</option>
                         <option ' . (($userdata['user_gender'] == "other") ? "selected" : "") . ' value="other">Other</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Skills (Select Multiple)</label>
                        <div class="added_skill">
                         ' . $userdata['skill_html'] . '
                         </div>
                        <select name="skill" id="skill" class="form-control select2">
                            
                        </select> 
                    </div>
                    <input type="hidden" id="user_skill" value="">
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Added Education</label>
                        <div class="added_education">
                         ' . $userdata['education_html'] . '
                         </div>
                        <label>Education</label>
                        <div class="education-div">
                            <input type="text" name="education" id="education" class="form-control education" value="" /> 
                        </div>
                         <label>Institute</label>
                        <div class="education-div">
                            <input type="text" name="institution" id="institution" class="form-control institution" value="" /> 
                        </div>
                        <label>Passing Year</label>
                        <div class="education-div">
                            <input type="date" name="pass_year" id="pass_year" class="form-control pass_year" value="" /> 
                        </div>
                    </div>
                    <button type="button" class="btn btn-info" id="add_education">Add New</button>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Certification</label>
                        
                        <div class="added_certificate">
                          ' . $userdata['certificate_html'] . '
                         </div>
                       
                         <div class="certificate-div">
                            <div class="input-div">
                                <input type="text" name="certificate" id="certificate" class="form-control certificate" value="" /> 
                            </div>
                         </div>
                    </div>
                    <button type="button" class="btn btn-info" id="add_certificate">Add New</button>
                </div>
                
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Work Experience</label>
                                            
                         <div class="certificate-div">
                            <label>Year</label>
                            <div class="input-div">
                                <input type="text" name="year_experience" id="year_experience" class="form-control" value="' . $userdata['year_experience'] . '" /> 
                            </div>
                         </div>
                         
                         <div class="certificate-div">
                            <label>Month</label>
                            <div class="input-div">
                                <input type="text" name="month_experience" id="month_experience" class="form-control" value="' . $userdata['month_experience'] . '" /> 
                            </div>
                         </div>
                    </div>
                </div>
                
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <label>Upload Resume (video/doc/pdf)</label>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                    <div class="preview">' . (($userdata['user_resume_file_src'] != "") ? '<a title="Click to download file" target="_blank" href="' . $userdata['user_resume_file_src'] . '" class="item_url">Download Resume</a>' : '') . '</div>
                                    <input type="hidden" name="resume_upload_tag" id="resume_upload_tag" value="resume_file" />
                                    <input type="hidden" name="resume_file[file_name]" id="resume_file_name" />
                                    <form action="/index.php?control=Documents&action=fileupload" class="dropzone"
                                            id="resume-drop"></form>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                      
                            <span id="go-save-candidate" class="btn btn-success">Save</span>
                            <span id="cancel-save-employer"  class="btn btn-danger">Cancel</span>
                        </div> 
                    </div>
                </div>
            </div>
            <input type="hidden" value="' . $server_url . '" id="server_value">
        </div>';
    }
    $html = $html . '</div>';

// \ISOPHP\js_core::$console->log('Html here: ', $html);

    $html = $html . '</div></div>';
    $html = $html . '<script type="text/javascript"
        src="' . REQUIRE_PREFIX . '/app/Default/Assets/Documents/js/documents.js"></script>';

    $html = $html . '
        <section class="footer-bottom">
      <div class="container">
        <div class="row clearfix">
          <div class="col-md-3 col-12 col-xs-12 col-lg-3 xs-center">
            © 2018 Piranha Web Services
          </div>';

    if (CURRENT_TARGET !== 'mobile') {
        $html = $html . '
          <div class="col-md-9 col-12 col-xs-12 col-lg-9">
            <ul class="footer-links">
              <li>
                <a href="#">Innovation</a>
              </li>
              <li>
                <a class="go-jobslist" href="/jobslist">Jobs</a>
              </li>
              <li>
                <a href="#">Careers</a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">Terms</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">Twitter</a>
              </li>
            </ul>
          </div> ';
    }

    $html = $html . '
        </div>
      </div>
    </section>
    ';

    return $html;
};
