<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-bucket-create" class="btn btn-success attempt-bucket-create">Create</span>
                        <span id="go-arduinodesign-bucket-list" class="btn btn-info go-arduinodesign-bucket-list">ArduinoDesign Bucket List</span>
                        <span id="go-arduinodesign-landing" class="btn btn-info go-arduinodesign-landing">ArduinoDesign Home</span>
                    </div>
                        
                    <div>
                        <h4>New Bucket</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Bucket Name</label>
                            <input type="text" name="bucket_name" class="form-control" id="bucket_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Comment</label>
                            <textarea name="comment" id="comment" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-bucket-create" class="btn btn-success attempt-bucket-create">Create</span>
                        <span id="go-arduinodesign-bucket-list" class="btn btn-info go-arduinodesign-bucket-list">ArduinoDesign Bucket List</span>
                        <span id="go-arduinodesign-landing" class="btn btn-info go-arduinodesign-landing">ArduinoDesign Home</span>
                    </div>
                    
                </div>
            </div>
            
            <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating ArduinoDesign Buckets</strong></h4>     
                    
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_object_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_object_awscli_aws" class="instruction_set instruction_set_create_object_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com \
                               --caller-reference 123451234512345
                               --profile=pharaoh \
                               --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceObjectSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure<i id="switch_instruction_set_create_object_ptc_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_object_ptc_aws" class="instruction_set instruction_set_create_object_ptc_aws">
ptconfigure-enterprise AWSRoute53 ensure-bucket-exists --bucket-name="testmailbucket.com" \
                                                       --bucket-email="testmail@testmailbucket.com" \
                                                       --bucket-comment="A Comment" \
                                                       --bucket-ttl="60" \
                                                       --aws-access-key="1234" \
                                                       --aws-secret-key="ABC1234" \
                                                       --aws-region="eu-west-1" \
                                                       --aws-endpoint="http://aws.saharaws.vm:8888" \
                                                       --output-format="json" \
                                                       --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmailbucket.com"
}
                    </pre>
             
     
                    <h5>Digital Ocean Mode - Pharaoh Configure<i id="switch_instruction_set_create_object_ptc_do"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_ptc_do"></i></h5>
                    <pre id="instruction_set_create_object_ptc_do" class="instruction_set instruction_set_create_object_ptc_do">
ptconfigure-enterprise sahara --mode-on=digitalocean --sahara="http://digitalocean.saharaws.vm:8888" -yg
ptconfigure-enterprise digitalocean ensure-bucket-exists --bucket-name="testmailbucket.com" \
                                                         --bucket-email="testmail@testmailbucket.com" \
                                                         --bucket-comment="A Comment" \
                                                         --bucket-ttl="60" \
                                                         --aws-access-key="1234" \
                                                         --aws-secret-key="ABC1234" \
                                                         --aws-region="eu-west-1" \
                                                         --aws-endpoint="http://aws.saharaws.vm:8888" \
                                                         --output-format="json" \
                                                         --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmailbucket.com"
}
                    </pre>
             
     
                    <h5>Rackspace Mode - Pharaoh Configure<i id="switch_instruction_set_create_object_ptc_rax"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_ptc_rax"></i></h5>
                    <pre id="instruction_set_create_object_ptc_rax" class="instruction_set instruction_set_create_object_ptc_rax">
ptconfigure-enterprise sahara --mode-on=rackspace --sahara="http://rackspace.saharaws.vm:8888" -yg
ptconfigure-enterprise rackspace ensure-bucket-exists --bucket-name="testmailbucket.com" \
                                                      --bucket-email="testmail@testmailbucket.com" \
                                                      --bucket-comment="A Comment" \
                                                      --bucket-ttl="60" \
                                                      --aws-access-key="1234" \
                                                      --aws-secret-key="ABC1234" \
                                                      --aws-region="eu-west-1" \
                                                      --aws-endpoint="http://aws.saharaws.vm:8888" \
                                                      --output-format="json" \
                                                      --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmailbucket.com"
}
                    </pre>
                    
             </div>
             
        </div>';

    return $html;

};