<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $users_are_array = \ISOPHP\core::$php->is_array($rqd['users']) ;
    if ($users_are_array === true) {
        $users_exist = true ;
    } else {
        $users_exist = false ;
    }
    if ($users_exist === true) {
        $user_count = \ISOPHP\core::$php->count($rqd['users']) ;
    } else {
        $user_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-user-create" class="btn btn-success go-sam-user-create">New User</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-user-list" class="btn btn-info go-sam-user-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>User List</h4>
         </div> ' ;

    if ($users_exist === true && $user_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_sam_user">
                    <div class="col-sm-1 col-1">
                        <h5 class="sam_user_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_user_name_title">Name</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_user_groups_title">Groups</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_user_policies_title">Policies</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_user_email_title">Email</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="sam_user_default_admin_title">Admin</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_user_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['users'] as $one_sam_user) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sam_user">
                    <div class="col-sm-1 col-1">
                        <p class="sam_list_id"><span class="sam_id sam_listing">' . $one_sam_user['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sam_list_name"><span class="sam_name sam_listing">' . $one_sam_user['username'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2 sam_list_groups"> ' ;

            if (is_array($one_sam_user['groups']) && count($one_sam_user['groups']) > 0) {
                foreach ($one_sam_user['groups'] as $one_group_entity => $one_group_name) {
                    $html = $html . '<span class="col-sm-12 col-12 one_sam_group">' ;
                    $html = $html . '    <strong>'.$one_group_name.'</strong>' ;
                    $html = $html . '</span>' ;
                }
            } else {
                $html = $html . 'Zero Groups for User' ;
            }

            $html = $html . '
                    </div>
                    <div class="col-sm-2 col-2 sam_list_policies"> ' ;

            if (is_array($one_sam_user['policies']) && count($one_sam_user['policies']) > 0) {
                foreach ($one_sam_user['policies'] as $one_policy_entity => $one_policy_name) {
                    $html = $html . '<span class="col-sm-12 col-12 one_sam_policy">' ;
                    $html = $html . '    <strong>'.$one_policy_name.'</strong>' ;
                    $html = $html . '</span>' ;
                }
            } else {
                $html = $html . 'Zero Policies for User' ;
            }

            $html = $html . '
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sam_list_email">' . $one_sam_user['email'] . '</p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sam_list_admin">' ;

                    if ($one_sam_user['default_admin'] == true) {
                        $html = $html . '<strong>Yes</strong>' ;
                    } else {
                        $html = $html . 'No' ;
                    }

            $html = $html .
                       '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sam_user_' . $one_sam_user['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom go-sam-user-edit btn btn-outline-primary"
                                   data-sam-user-id="' . $one_sam_user['id'] . '"
                                   data-sam-user-name="' . $one_sam_user['username'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sam_user_' . $one_sam_user['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-user-delete btn btn-outline-danger"
                                   data-sam-user-id="' . $one_sam_user['id'] . '"
                                   data-sam-user-name="' . $one_sam_user['username'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Users</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-user-create" class="btn btn-success go-sam-user-create">New User</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-user-list" class="btn btn-info go-sam-user-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div> ' ;

    return $html;

};