<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $vms_are_array = \ISOPHP\core::$php->is_array($rqd['vms']) ;
    if ($vms_are_array === true) {
        $vms_exist = true ;
    } else {
        $vms_exist = false ;
    }
    if ($vms_exist === true) {
        $vm_count = \ISOPHP\core::$php->count($rqd['vms']) ;
    } else {
        $vm_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-instance-create" class="btn btn-success go-sc1-instance-create">New Instance</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-instance-executionlog-list" class="btn btn-info go-sc1-instance-executionlog-list">Execution Logs</span>
            <span id="go-sc1-instance-list" class="btn-refresh btn btn-info go-sc1-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Virtual Machines</h4>
        </div> ' ;
    if ($vms_exist === true && $vm_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-instance-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
            '<div class="col-sm-12 col-12 one_sc1_instance item_list_sc1_instances page_item_list_title" id="item_list_sc1_instances">
            <div class="col-sm-1 col-1">
                <h5 class="sc1_instance_id_title">ID</h5>
            </div>
            <div class="col-sm-3 col-3">
                <h5 class="sc1_instance_id_title">Name</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="sc1_instance_id_title">Status</h5>
            </div>
            <div class="col-sm-1 col-1">
                <h5 class="sc1_instance_id_title">Region</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_instance_id_title">Networks</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_instance_id_title">DNS</h5>
            </div>
            <div class="col-sm-2 col-2">
                <h5 class="sc1_instance_id_title">More</h5>
            </div>
        </div>';

        foreach ($rqd['vms'] as $one_sc1_instance) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sc1_instance">
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' . $one_sc1_instance['vmid'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_name"><span class="sc1_name sc1_listing">' . $one_sc1_instance['name'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_status">' . $one_sc1_instance['status'] . '</p>
                    </div>     
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_region">' . $one_sc1_instance['region'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">' ;

                foreach ($one_sc1_instance['nics_expanded'] as $expanded_nic) {

                    $html = $html .
                        '<span class="sc1_list_network">'
                            .$expanded_nic['network'] . ', '
                            .$expanded_nic['ip_address'].
                        ' </span>' ;

                }

            $html = $html .
                   '</div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_dns">' . $one_sc1_instance['dns_default_public'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="instance_list_button_width">
                                <i id="edit_sc1_instance_' . $one_sc1_instance['vmid'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-instance-edit btn btn-outline-primary"
                                   data-sc1-instance-id="' . $one_sc1_instance['vmid'] . '"
                                   data-sc1-instance-state="' . $one_sc1_instance['status'] . '"
                                   data-sc1-instance-name="' . $one_sc1_instance['name'] . '"></i>
                            </div>
                            <div class="instance_list_button_margin">
                                &nbsp;
                            </div>
                            <div class="instance_list_button_width">
                                <i id="info_sc1_instance_' . $one_sc1_instance['vmid'] . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-instance-info btn btn-outline-primary"
                                   data-sc1-instance-id="' . $one_sc1_instance['vmid'] . '"
                                   data-sc1-instance-state="' . $one_sc1_instance['status'] . '"
                                   data-sc1-instance-name="' . $one_sc1_instance['name'] . '"></i>
                            </div>
                            <div class="instance_list_button_margin">
                                &nbsp;
                            </div>
                            <div class="instance_list_button_width">
                                <i id="delete_sc1_instance_' . $one_sc1_instance['vmid'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-instance-delete btn btn-outline-danger"
                                   data-sc1-instance-id="' . $one_sc1_instance['vmid'] . '"
                                   data-sc1-instance-name="' . $one_sc1_instance['name'] . '"></i>
                            </div>
                            <div class="col-sm-1 col-1"> </div>
                        </div>
                    </div>                        
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-instance-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Instances</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-instance-create" class="btn btn-success go-sc1-instance-create">New Instance</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-instance-executionlog-list" class="btn btn-info go-sc1-instance-executionlog-list">Execution Logs</span>
            <span id="go-sc1-instance-list" class="btn-refresh btn btn-info go-sc1-instance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html;

};
