<?php

Namespace Model\PCR;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'PCR List of Piranha Web Services';
        $page['heading'] = 'List of PCR';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('PCR Mod', $page, $pageVars);
        return $page;
    }

    public function showPCRLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCRView();
            $view->loadTemplate('PCR', 'PCRLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCR($bind);
            \ISOPHP\js_core::$console->log('apply PCR Registry Create 2', $vars);
        };
    }

    public function showPCRRegistryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCRView();
            $view->loadTemplate('PCR', 'RegistryCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCR($bind);
            \ISOPHP\js_core::$console->log('apply PCR Registry Create 2', $vars);
        };
    }

    public function showPCRRegistryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR List');
            $vars['control'] = 'PCR';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/pcr/registry/all';
            $afterwards = $this->applyPCRRegistryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('PCR Model: ', $result);
            return $result;
        };
    }

    public function applyPCRRegistryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCR Registry List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCR Registry List 1', $vars);
                $view = new \View\PCRView();
                $view->loadTemplate('PCR', 'RegistryList.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCR($bind);
                \ISOPHP\js_core::$console->log('apply PCR Registry List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCR Registry List failed');
            }
        };
        return $func;
    }

    public function showPCRRegistryListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR List');
            $vars['control'] = 'PCR';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/pcr/registry/all';
            $afterwards = $this->applyPCRRegistryListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('PCR Model: ', $result);
            return $result;
        };
    }

    public function applyPCRRegistryListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCR Registry List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCR Registry List 1', $vars);
                $view = new \View\PCRView();
                $view->loadTemplate('PCR', 'RegistryListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCRRegistryDropDown($bind);
                \ISOPHP\js_core::$console->log('apply PCR Registry List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCR Registry List failed');
            }
        };
        return $func;
    }

    public function showPCRRegistryTagList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR List');
            $vars['control'] = 'PCR';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/pcr/registry/tag/all';
            $vars['registry_id'] = $pageVars['params']['registry_id'];
            $afterwards = $this->applyPCRRegistryTagListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('PCR Model: ', $result);
            return $result;
        };
    }

    public function applyPCRRegistryTagListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCR Registry Tag List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCR Registry Tag List 1', $vars);
                $view = new \View\PCRView();
                $view->loadTemplate('PCR', 'RegistryTagList.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCR($bind);
                \ISOPHP\js_core::$console->log('apply PCR Registry Tag List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCR Registry Tag List failed');
            }
        } ;
        return $func ;
    }

    public function showPCRImageCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing PCR Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\PCRView();
            $view->loadTemplate('PCR', 'ImageCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCR($bind);
            \ISOPHP\js_core::$console->log('apply PCR Image Create 2', $vars);
        };
    }

    public function showPCRImageList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for registry name');
            \ISOPHP\js_core::$console->log($pageVars['registry_name']);
            if (isset($pageVars['registry_name'])) {
                \ISOPHP\js_core::$console->log('Showing PCR List');
                $vars['control'] = 'PCR';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/pcr/image/all';
                $vars['registry_name'] = $pageVars['registry_name'];
                $afterwards = $this->applyPCRImageListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('PCR Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyPCRImageListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our PCR Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply PCR Image List 1', $vars);
                $view = new \View\PCRView();
                $view->loadTemplate('PCR', 'ImageList.php', $vars);
                $bind = self::bindButtons();
                $view->displayPCR($bind);
                \ISOPHP\js_core::$console->log('apply PCR Image List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('PCR Image List failed');
            }
        };
        return $func;
    }

    public static function showPCRSingleRegistryTagDetails($registry_id, $tag_id) {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('Showing SC1 Single Repository');
            $vars['registry_id'] = $registry_id;
            $vars['tag_id'] = $tag_id;
            $vars['protocol'] = \ISOPHP\js_core::$window->location->protocol;
            $vars['hostname'] = \ISOPHP\js_core::$window->location->hostname;
            $current_port = \ISOPHP\js_core::$window->location->port ;
            $vars['port_string'] = '';
            $no_string_ports = [80, 443] ;
            if (\ISOPHP\core::$php->in_array($current_port, $no_string_ports) === false) {
                $vars['port_string'] = ':'.$current_port ;
            }
            \ISOPHP\js_core::$console->log('apply PCR Single registry List 1', $vars);
            $view = new \View\PCRView();
            $view->loadTemplate('PCR', 'SingleRegistryDetails.php', $vars);
            $bind = self::bindButtons();
            $view->displayPCRSingleRegistryDetails($bind);
            \ISOPHP\js_core::$console->log('apply PCR Single registry List 2', $vars);
        }
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save registry
     */
    public function saveRegistryAttemptToPCR($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save registry to pcr');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCR';
        $vars['action'] = 'registry-create';
        $vars['api_uri'] = '/api/pcr/registry/create';
        $vars['registry_name'] = $jQuery('#registry_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRegistryResultFromPCR($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRegistryResultFromPCR($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save pcr registry result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Registry '.$request_data['registry']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcr registry saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('PCR', 'registry-list', array(), '/pcr/registry/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Registry '.$request_data['registry']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcr registry save failed');
            }
        };
        return $func;
    }

    /**
     * Save image
     */
    public function saveImageAttemptToPCR($pageVars)
    {
        \ISOPHP\js_core::$console->log('save image to pcr');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCR';
        $vars['action'] = 'image-create';
        $vars['api_uri'] = '/api/pcr/image/create';
        $vars['registry_name'] = $jQuery('#registry_name')->val();
        $vars['image_name'] = $jQuery('#image_name')->val();
        $vars['image_value'] = $jQuery('#image_value')->val();
        $vars['image_type'] = $jQuery('#image_type')->val();
        \ISOPHP\js_core::$console->log('save image vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveImageResultFromPCR($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveImageResultFromPCR($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save pcr image result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Image '.$request_data['image']['pcr_image_name'].' for Registry '.$request_data['image']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcr image saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Image '.$request_data['image']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcr image save failed');
            }
        };
        return $func;
    }
    /**
     * Delete image
     */
    public function deleteImageAttemptToPCR($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete image to pcr');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCR';
        $vars['action'] = 'image-delete';
        $vars['api_uri'] = '/api/pcr/image/delete';
        $vars['registry_name'] = $pageVars['params']['registry_name'];
        $vars['image_key'] = $pageVars['params']['image_key'];
        $vars['image_id'] = $pageVars['params']['image_id'];
        \ISOPHP\js_core::$console->log('delete image vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveImageResultFromPCR($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteImageResultFromPCR($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete pcr image result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Image '.$request_data['image']['pcr_image_name'].' from Registry '.$request_data['image']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcr image deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Image '.$request_data['image']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcr image delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete registry
     */
    public function deleteRegistryAttemptToPCR($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete registry to pcr');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'PCR';
        $vars['action'] = 'registry-delete';
        $vars['api_uri'] = '/api/pcr/registry/delete';
        $vars['registry_name'] = $pageVars['params']['registry_name'];
        \ISOPHP\js_core::$console->log('delete registry vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteRegistryResultFromPCR($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRegistryResultFromPCR($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete pcr registry result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Registry '.$request_data['registry']['pcr_registry_name'].' for Registry '.$request_data['registry']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('pcr registry deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('PCR', 'registry-list', array(), '/pcr/registry/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Registry '.$request_data['registry']['pcr_registry_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('pcr registry delete failed');
            }
            $show_registry_list = self::showPCRRegistryList($pageVars) ;
            $show_registry_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_pcr_landing = $jQuery('.go-pcr-landing');
                $go_pcr_landing->off() ;
                $go_pcr_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'landing', array(), '/pcr');
                });

                $go_pcr_registry_list = $jQuery('.go-pcr-registry-list');
                $go_pcr_registry_list->off() ;
                $go_pcr_registry_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'registry-list', array(), '/pcr/registry/list');
                });

                $go_pcr_registry_create = $jQuery('.go-pcr-registry-create');
                $go_pcr_registry_create->off() ;
                $go_pcr_registry_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'registry-create', array(), '/pcr/registry/create');
                });

                $save_registry = $jQuery('.attempt-registry-create');
                $save_registry->off() ;
                $save_registry->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'registry-save-attempt', array());
                });

                $registry_tag_list = $jQuery('.go-registry-tag-list');
                $registry_tag_list->off() ;
                $registry_tag_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $reg_id = $jqThis->attr('data-pcr-registry-id') ;
                    \ISOPHP\js_core::$console->log('Clicked registry tag list button');
                    $navigate->route('PCR', 'registry-tag-list', array(
                        'registry_id' => $reg_id,
                    ), '/pcr/registry/'.$reg_id.'/tag/list');
                } ) ;

                $go_pcr_image_list = $jQuery('.go-pcr-image-list');
                $go_pcr_image_list->off() ;
                $go_pcr_image_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'image-list', array(), '/pcr/image/list');
                });

                $go_pcr_image_create = $jQuery('.go-pcr-image-create');
                $go_pcr_image_create->off() ;
                $go_pcr_image_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'image-create', array(), '/pcr/image/create');
                });

                $save_image = $jQuery('.attempt-image-create');
                $save_image->off() ;
                $save_image->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('PCR', 'image-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $image_delete = $jQuery('.attempt-image-delete');
                $image_delete->off() ;
                $image_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $image_name = $jqThis->attr('data-pcr-image-name') ;
                    $image_id = $jqThis->attr('data-pcr-image-id') ;
                    $registry_name = $jqThis->attr('data-pcr-registry-name') ;
                    \ISOPHP\js_core::$console->log('Clicked image delete button');
                    $msg = "You are about to delete the Image for $image_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $image_id, $image_name, $registry_name) {
                        $navigate->route('PCR', 'image-delete-attempt', array(
                            'image_id' => $image_id,
                            'image_name' => $image_name,
                            'registry_name' => $registry_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $registry_delete = $jQuery('.attempt-registry-delete');
                $registry_delete->off() ;
                $registry_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $registry_name = $jqThis->attr('data-pcr-registry-name') ;
                    \ISOPHP\js_core::$console->log('Clicked registry delete button');
                    $msg = "You are about to delete the Registry $registry_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $registry_name) {
                        $navigate->route('PCR', 'registry-delete-attempt', array(
                            'registry_name' => $registry_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $tag_info = $jQuery('.attempt-tag-info');
                $tag_info->off() ;
                $tag_info->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $registry_name = $jqThis->attr('data-pcr-registry-name') ;
                    $registry_id = $jqThis->attr('data-pcr-registry-id') ;
                    $registry_tag_name = $jqThis->attr('data-pcr-tag-id') ;
                    $registry_tag_id = $jqThis->attr('data-pcr-tag-name') ;
                    \ISOPHP\js_core::$console->log('Clicked tag info button,  '.$registry_name.', '.$registry_id.', '.$registry_tag_name.', '.$registry_tag_id);
                    $msg = "Registry Details for: $registry_name:$registry_tag_name";
                    $data  = '' ;
                    $data .= '<div class="col-sm-12" id="pcr_single_registry_details">' ;
                    $data .= '  <h3>Loading ...</h3>' ;
                    $data .= '</div>' ;
                    \Core\WindowMessage::showOverlay($msg, $data);
                    self::showPCRSingleRegistryTagDetails($registry_id, $registry_tag_name) ;
                } ) ;

            }
        };
    }

    public function bindRegistryListDropDownButtonsForImageList()
    {
        \ISOPHP\js_core::$console->log('Started binding registry switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding registry switch');
            $switch_registry = $jQuery('#registry_name');
            $switch_registry->off() ;
            $switch_registry->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#registry_name') ;
                $registry_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked registry switch');
                $navigate->route('PCR', 'image-list', array(
                    'registry_name' => $registry_name,
                ));
            } ) ;

        };
        return $func;
    }



}