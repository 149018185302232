<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $groups_are_array = \ISOPHP\core::$php->is_array($rqd['groups']) ;
    if ($groups_are_array === true) {
        $groups_exist = true ;
    } else {
        $groups_exist = false ;
    }
    if ($groups_exist === true) {
        $group_count = \ISOPHP\core::$php->count($rqd['groups']) ;
    } else {
        $group_count = false ;
    }

    if ($groups_exist === true && $group_count !== false) {
        $html = $html . '<select class="form-control" name="group_name" id="group_name">';
        foreach ($rqd['groups'] as $one_sam_group) {
            $html = $html . '<option value="' . $one_sam_group['sam_group_name'] . '">' . $one_sam_group['sam_group_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available Groups</option>
             </select>' ;

    }

    return $html;

};