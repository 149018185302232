<?php

Namespace View;

class BillingView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displayBilling($binder)
    {
        \ISOPHP\js_core::$console->log('display billing');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#billing_content')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displayBillingPaymentMethodDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display billing paymentmethod dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#billing_paymentmethod_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displayBillingChargeableItemsPage($binder)
    {
        \ISOPHP\js_core::$console->log('display billing chargeable items page');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#billing_invoice_chargeableitems')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }


    public function displayBillingSingleInvoiceDetails($binder)
    {
        \ISOPHP\js_core::$console->log('display billing invoice overlay');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
        $jQuery('#billing_single_invoice_details')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

}
