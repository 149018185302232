<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $images_are_array = \ISOPHP\core::$php->is_array($rqd['images']) ;
    if ($images_are_array === true) {
        $images_exist = true ;
    } else {
        $images_exist = false ;
    }
    if ($images_exist === true) {
        $image_count = \ISOPHP\core::$php->count($rqd['images']) ;
    } else {
        $image_count = false ;
//            $vm_sizes = [] ;
//            for ($i=0; $i<count($sc1_data['sizes']); $i++ ) {
//                $temp = $sc1_data['sizes'][$i] ;
//                unset()
//                $vm_sizes[] = $temp ;
//            }
    }

    if ($images_exist === true && $image_count !== false) {
        $html = $html . '<select class="form-control" name="image_id" id="image_id">';
        foreach ($rqd['images'] as $image) {
            if (isset($pv['params']['image_id_preselected']) && ($pv['params']['image_id_preselected'] === $image['image_id']) ) {
                $html = $html . '<option value="' . $image['image_id'] . '" selected="selected">' . $image['name'] . '</option>';
            } else {
                $html = $html . '<option value="' . $image['image_id'] . '">' . $image['name'] . '</option>';
            }
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available Images</option>
             </select>' ;

    }

    return $html;

};