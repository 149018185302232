<?php

Namespace Model\SC1;

use ISOPHP\core;

class VolumePageModel extends \Model\Base {


    public function showSC1VolumeCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'VolumeCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Volume Create 2', $vars);
        };
    }

    public function showSC1VolumeList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/volume/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1VolumeListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1VolumeListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Volume List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Volume List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'VolumeList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Volume List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Volume List failed');
            }
        };
        return $func;
    }

    /**
     * Save volume
     */
    public function saveVolumeAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save volume to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'volume-create';
        $vars['api_uri'] = '/api/sc1/volume/create';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['volume_name'] = $jQuery('#volume_name')->val();
        $vars['capacity'] = $jQuery('#capacity')->val();
        $vars['type'] = $jQuery('#type')->val();
        \ISOPHP\js_core::$console->log('save volume vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveVolumeResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveVolumeResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 volume result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Volume '.$request_data['volume']['sc1_volume_name'].' for Instance '.$request_data['volume']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 volume saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Volume '.$request_data['volume']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 volume save failed');
            }
        };
        return $func;
    }
    /**
     * Delete volume
     */
    public function deleteVolumeAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete volume to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'volume-delete';
        $vars['api_uri'] = '/api/sc1/volume/delete';
        $vars['instance_name'] = $pageVars['params']['instance_id'];
        $vars['volume_id'] = $pageVars['params']['volume_id'];
        \ISOPHP\js_core::$console->log('delete volume vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteVolumeResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteVolumeResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 volume result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Volume '.$request_data['volume']['sc1_volume_name'].' for Instance '.$request_data['volume']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 volume deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Volume '.$request_data['volume']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 volume delete failed');
            }
        };
        return $func;
    }

    /**
     * Delete volume
     */
    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding volume buttons');

                # Volumes
                $go_sc1_volume_list = $jQuery('.go-sc1-volume-list');
                $go_sc1_volume_list->off() ;
                $go_sc1_volume_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'volume-list', array(), '/sc1/volume/list');
                });

                $go_sc1_volume_list_page = $jQuery('.sc1-volume-paginator');
                $go_sc1_volume_list_page->off() ;
                $go_sc1_volume_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 volume paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'volume-list', array('page' => $new_page), '/sc1/volume/list/page/'.$new_page);
                });

                $go_sc1_volume_create = $jQuery('.go-sc1-volume-create');
                $go_sc1_volume_create->off() ;
                $go_sc1_volume_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'volume-create', array(), '/sc1/volume/create');
                });

                $save_volume = $jQuery('.attempt-volume-create');
                $save_volume->off() ;
                $save_volume->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'volume-save-attempt', array());
                });

                $volume_delete = $jQuery('.attempt-volume-delete');
                $volume_delete->off() ;
                $volume_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $volume_name = $jqThis->attr('data-sc1-volume-name') ;
//                    $volume_id = $jqThis->attr('data-sc1-volume-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked volume delete button');
//                    if ($volume_name !== '') {
//                        $name_or_id = $volume_name ;
//                    } else {
//                        $name_or_id = $volume_id ;
//                    }
//                    $msg = "You are about to delete the Volume $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $volume_id, $volume_name) {
//                        $navigate->route('SC1', 'volume-delete-attempt', array(
//                            'volume_id' => $volume_id,
//                            'volume_name' => $volume_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}