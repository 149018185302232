<?php

\Core\View::$template = function() {
    $html = '<div class="container"><div class="row"><div class="col-sm-6 col-12 offset-sm-3">
        <form id="pharaoh_form" class="center-block register-cstm clearfix" style="margin-top: 120px;">
            <header class="login_header row">
                <div class="col-sm-12">
                    <h2 class="register-header"><span>Login to Piranha Web Services</span></h2>
                </div>
            </header>
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Organisation ID</label>
                    <input type="text" name="organisation_id" id="organisation_id" class="form-control" />
                </div>
            </div> 
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Email/User</label>
                    <input type="text" name="email_username" id="email_username" class="form-control" />
                </div>
            </div> 
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" name="pass" id="pass" class="form-control"/>
                </div> 
            </div> ' ;

    $html = $html . '<div class="row">' ;
    $html = $html . '<div class="col-sm-6 col-6 xs-left text-right">' ;
    $html = $html . '<div class="btn btn-success"><span id="perform-login">Login</span></div>' ;
    $html = $html . '</div>' ;
    $html = $html . '<div class="col-sm-6 col-6 xs-right text-left">' ;
    $html = $html . '<div class="btn btn-danger"><span id="cancel-login">Cancel</span></div>' ;
    $html = $html . '</div>' ;
    $html = $html . '</div>' ;
    $html = $html . '<hr>' ;
    $html = $html . '</form></div></div></div>' ;
    return $html ;

} ;
