<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $objects_are_array = \ISOPHP\core::$php->is_array($rqd['objects']) ;
    if ($objects_are_array === true) {
        $objects_exist = true ;
    } else {
        $objects_exist = false ;
    }
    if ($objects_exist === true) {
        $object_count = \ISOPHP\core::$php->count($rqd['objects']) ;
    } else {
        $object_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-arduinoexecute-object-create" class="btn btn-success go-arduinoexecute-object-create">Create New Object</span>
            <span id="go-arduinoexecute-landing" class="btn btn-warning go-arduinoexecute-landing">ArduinoExecute Home</span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Object List</h4>
         </div> ' ;

    if ($objects_exist === true && $object_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_arduinoexecute_object">
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">ArduinoExecute Object ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">Bucket</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="arduinoexecute_object_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['objects'] as $one_arduinoexecute_object) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="arduinoexecute_list_id"><span class="arduinoexecute_id arduinoexecute_listing">' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="arduinoexecute_list_bucket_name"><span class="arduinoexecute_bucket_name arduinoexecute_listing">' . $one_arduinoexecute_object['arduinoexecute_bucket_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="arduinoexecute_list_object_name"><span class="arduinoexecute_object_name arduinoexecute_listing">' . $one_arduinoexecute_object['arduinoexecute_object_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="arduinoexecute_list_object_data"><span class="arduinoexecute_object_data arduinoexecute_listing">' . $one_arduinoexecute_object['arduinoexecute_object_value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="arduinoexecute_list_object_type"><span class="arduinoexecute_type arduinoexecute_listing">' . $one_arduinoexecute_object['arduinoexecute_object_type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="edit_arduinoexecute_object_' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   class="fa fa-1x fa-info-square hvr-grow dom attempt-object-edit btn btn-outline-primary"
                                   data-arduinoexecute-object-id="' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   data-arduinoexecute-object-name="' . $one_arduinoexecute_object['arduinoexecute_object_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="edit_arduinoexecute_object_' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-object-edit btn btn-outline-primary"
                                   data-arduinoexecute-object-id="' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   data-arduinoexecute-object-name="' . $one_arduinoexecute_object['arduinoexecute_object_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_arduinoexecute_object_' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-object-delete btn btn-outline-danger"
                                   data-arduinoexecute-object-id="' . $one_arduinoexecute_object['arduinoexecute_object_id'] . '"
                                   data-arduinoexecute-object-name="' . $one_arduinoexecute_object['arduinoexecute_object_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {


    }

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing ArduinoExecute Objects</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_object_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_object_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_object_awscli_aws" class="instruction_set instruction_set_list_object_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceObjectSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceObjectSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_object_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_object_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_object_ptc_aws" class="instruction_set instruction_set_list_object_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceObjectSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceObjectSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};