<?php

Namespace Controller ;

class Result {

  public $page;
  public $view;
  public $control;
  public $type;
  public $view_control;
  public $post_template = array();

}