<?php

Namespace Model\Database;

use ISOPHP\core;

class SizePageModel extends \Model\Base {
    
    public function showDatabaseSizeList($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Size List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/size/all';
            $afterwards = $this->applyDatabaseSizeListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseSizeListResult($pageVars) {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Size List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Size List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'SizeList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database Size List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Size List failed');
            }
        };
        return $func;
    }


    public function showDatabaseSizeListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/size/all';
            $afterwards = $this->applyDatabaseSizeListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseSizeListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Size List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Size List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'SizeListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayDatabaseSizeDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Database Size List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Size List failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_database_size_list = $jQuery('.go-database-size-list');
                $go_database_size_list->off() ;
                $go_database_size_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'size-list', array(), '/database/size/list');
                });
            }
        };
    }


}