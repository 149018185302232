<?php

Namespace Model\SC1;

use ISOPHP\core;

class LoadBalancerPageModel extends \Model\Base {

// $bind = \Model\SC1\PageModel::bindButtons();
    public function showSC1LoadBalancerCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'LoadBalancerCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer Create 2', $vars);
        };
    }

    public function addSC1LoadBalancerCreateButtonChanges($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');

            $jQuery = \ISOPHP\js_core::$jQuery;
            $by_date_cron_radio = $jQuery('input[type=radio][name=by_date_cron]');
            $by_date_cron_radio->on('change', function($jqThis) use ($jQuery) {
                $by_date_cron_val = $jQuery('input[type=radio][name=by_date_cron]')->val() ;
                if ($by_date_cron_val === 'date'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($by_date_cron_val === 'cron'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

            $frequency_radio = $jQuery('input[type=radio][name=frequency]');
            $frequency_radio->on('change', function($jqThis) use ($jQuery) {
                $frequency_val = $jQuery('input[type=radio][name=frequency]')->val() ;
                if ($frequency_val === 'once'){
                    $cron_wrapper = $jQuery('#scheduled-instance-cron-wrapper');
                    $cron_wrapper->slideUp() ;
                } else if ($frequency_val === 'repeat'){
                    $cron_wrapper = $jQuery('#scheduled-instance-date-wrapper');
                    $cron_wrapper->slideUp() ;
                }
            }) ;

        };
    }



    public function showSC1LoadBalancerSizeDropDown($pageVars)
    {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            return function () use ($pageVars) {
                \ISOPHP\js_core::$console->log('Showing SC1 List');
                $vars['control'] = 'SC1';
                $vars['action'] = 'list-size';
                $vars['api_uri'] = '/api/sc1/size/all';
                $afterwards = $this->applySC1LoadBalancerSizeDropDownResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
                return $result;
            };
        }
    }

    public function applySC1LoadBalancerSizeDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LoadBalancer Size List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer Size List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LoadBalancerSizeDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1LoadBalancerSizeDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer Size List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LoadBalancer Size List failed');
            }
        };
        return $func;
    }

    public function showSC1LoadBalancerList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/loadbalancer/all';
            $afterwards = $this->applySC1LoadBalancerListResult($pageVars);
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1LoadBalancerListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LoadBalancer List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LoadBalancerList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LoadBalancer List failed');
            }
        };
        return $func;
    }

    public function showSC1LoadBalancerExecutionLogList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 ExecutionLog List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/loadbalancer/executionlog/all';
            $afterwards = $this->applySC1LoadBalancerExecutionLogListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1LoadBalancerExecutionLogListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LoadBalancer ExecutionLog List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer ExecutionLog List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LoadBalancerExecutionLogList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LoadBalancer ExecutionLog List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LoadBalancer ExecutionLog List failed');
            }
        };
        return $func;
    }

    /**
     * Save loadbalancer
     */
    public function saveLoadBalancerAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save loadbalancer to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'loadbalancer-create';
        $vars['api_uri'] = '/api/sc1/loadbalancer/create';
        $vars['instance_id'] = $jQuery('#instance_id')->val();
        $vars['size_id'] = $jQuery('#size_slug')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size_slug'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save loadbalancer vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
//        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
//        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveLoadBalancerResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveLoadBalancerResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 loadbalancer result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Load Balancer '.$request_data['loadbalancer']['sc1_loadbalancer_name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 loadbalancer saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'loadbalancer-list', array(), '/sc1/loadbalancer/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Load Balancer '.$request_data['loadbalancer']['sc1_loadbalancer_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 loadbalancer save failed');
            }
        };
        return $func;
    }
    /**
     * Delete loadbalancer
     */
    public function deleteLoadBalancerAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete loadbalancer to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'loadbalancer-delete';
        $vars['api_uri'] = '/api/sc1/loadbalancer/delete';
        $vars['loadbalancer_id'] = $pageVars['params']['loadbalancer_id'];
        \ISOPHP\js_core::$console->log('delete loadbalancer vars');
        \ISOPHP\js_core::$console->log($pageVars);
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteLoadBalancerResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteLoadBalancerResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 loadbalancer result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Load Balancer '.$request_data['loadbalancer']['sc1_loadbalancer_name'].' for Instance '.$request_data['loadbalancer']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 loadbalancer deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'loadbalancer-list', array(), '/sc1/loadbalancer/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Load Balancer '.$request_data['loadbalancer']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 loadbalancer delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete loadbalancer
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding load balancer buttons');

                $go_sc1_loadbalancer_list = $jQuery('.go-sc1-loadbalancer-list');
                $go_sc1_loadbalancer_list->off() ;
                $go_sc1_loadbalancer_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'loadbalancer-list', array(), '/sc1/loadbalancer/list');
                });

                $go_sc1_loadbalancer_list_page = $jQuery('.sc1-loadbalancer-paginator');
                $go_sc1_loadbalancer_list_page->off() ;
                $go_sc1_loadbalancer_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 loadbalancer paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'loadbalancer-list', array('page' => $new_page), '/sc1/loadbalancer/list/page/'.$new_page);
                });

                $go_sc1_loadbalancer_executionlog_list = $jQuery('.go-sc1-loadbalancer-executionlog-list');
                $go_sc1_loadbalancer_executionlog_list->off() ;
                $go_sc1_loadbalancer_executionlog_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'loadbalancer-executionlog-list', array(), '/sc1/loadbalancer/executionlog/list');
                });

                $go_sc1_loadbalancer_create = $jQuery('.go-sc1-loadbalancer-create');
                $go_sc1_loadbalancer_create->off() ;
                $go_sc1_loadbalancer_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'loadbalancer-create', array(), '/sc1/loadbalancer/create');
                });

                $save_loadbalancer = $jQuery('.attempt-loadbalancer-create');
                $save_loadbalancer->off() ;
                $save_loadbalancer->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'loadbalancer-save-attempt', array());
                });

                $loadbalancer_delete = $jQuery('.attempt-loadbalancer-delete');
                $loadbalancer_delete->off() ;
                $loadbalancer_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $loadbalancer_name = $jqThis->attr('data-sc1-loadbalancer-name') ;
                    $loadbalancer_id = $jqThis->attr('data-sc1-loadbalancer-id') ;
                    \ISOPHP\js_core::$console->log('Clicked loadbalancer delete button');
                    if ($loadbalancer_name !== '') {
                        $name_or_id = $loadbalancer_name ;
                    } else {
                        $name_or_id = $loadbalancer_id ;
                    }
                    $msg = "You are about to delete the Load Balancer $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $loadbalancer_id) {
                        $navigate->route('SC1', 'loadbalancer-delete-attempt', array(
                            'loadbalancer_id' => $loadbalancer_id)
                        );
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}