<?php

Namespace Controller;

class Services2Controller extends \Controller\Base
{

    public function execute($pageVars)
    {

        \ISOPHP\js_core::$console->log('Services controller List pagevars');
        \ISOPHP\js_core::$console->log($pageVars);

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('services controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated() ;
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Services, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }
        }

        $page_model = new \Model\Services2\PageModel();
        $default_page_model = new \Model\DefaultModule\PageModel();
        $page = $page_model->getPage($pageVars);
        \ISOPHP\js_core::$console->log('Yes serv con Here is action params....:', $pageVars['route']['action']);
        \ISOPHP\js_core::$window->document->title = $page['title'];
        $res = new \Controller\Result();

        $res->page = $page;
        $res->view = 'Services2.php';
        $res->type = 'view';
        $res->view_control = 'Services2';
        $res->post_template = array();

        if ($pageVars['route']['action'] == 'landing' || $pageVars['route']['action'] == 'show') {
            $res->post_template[] = $page_model->showServices2Landing($pageVars);
        } else if ($pageVars['route']['action'] == 'services3') {
            $res->post_template[] = $page_model->showServices3Landing($pageVars);
        } else if ($pageVars['route']['action'] == 'services4') {
            $res->post_template[] = $page_model->showServices4Landing($pageVars);
        } else if ($pageVars['route']['action'] == 'services5') {
            $res->post_template[] = $page_model->showServices5Landing($pageVars);
        } else if ($pageVars['route']['action'] == 'services6') {
            $res->post_template[] = $page_model->showServices6Landing($pageVars);
        }

        $res->post_template[] = $page_model->showLastLogin($pageVars);
        $res->post_template[] = $page_model->bindButtons();
        \ISOPHP\js_core::$console->log('Services2 Con', $res);
        $res->post_template[] = $default_page_model->bindMenu();
        return $res;

    }

}