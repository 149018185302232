<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $subnets_are_array = \ISOPHP\core::$php->is_array($rqd['subnets']) ;
    if ($subnets_are_array === true) {
        $subnets_exist = true ;
    } else {
        $subnets_exist = false ;
    }
    if ($subnets_exist === true) {
        $subnet_count = \ISOPHP\core::$php->count($rqd['subnets']) ;
    } else {
        $subnet_count = false ;
    }

    if ($subnets_exist === true && $subnet_count !== false) {
        $html = $html . '<select class="form-control" name="subnet_id" id="subnet_id">';
        foreach ($rqd['subnets'] as $one_subnet_subnet) {
            $html = $html . '<option value="' . $one_subnet_subnet['id'] . '">' ;
            $html = $html . $one_subnet_subnet['id'] . ' / ';
            $html = $html . $one_subnet_subnet['name'] ;
            $html = $html . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available Subnets in this VPC</option>
             </select>' ;

    }

    return $html;

};