<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Arduino Execute</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Executing programs on Arduino Hardware Controllers</h3>
                    <p>Arduino Program Execution</p>                      
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-arduinoexecute-bucket-list" class="go-arduinoexecute-bucket-list btn btn-lg btn-warning">Buckets</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinoexecute-bucket-create" class="go-arduinoexecute-bucket-create btn btn-lg btn-warning">Create Bucket</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinoexecute-object-list" class="go-arduinoexecute-object-list btn btn-lg btn-warning">Objects</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-arduinoexecute-object-create" class="go-arduinoexecute-object-create btn btn-lg btn-warning">Create Object</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;