<?php

\Core\View::$template = function () {

    $user_logged = \ISOPHP\core::$registry->getValue('user_logged') ;

    $pv = \Core\View::$page_vars;
    $userdata = \Core\View::$page_vars['user_data'];

    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];
    \ISOPHP\js_core::$console->log('$rqd: ', $rqd);

    $html = '';

    $html = $html . '
                      
        <div class="col-sm-12 col-12">
            <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
        </div>
                                    
        <div class="col-sm-12 col-12">
                            
            <div class="col-sm-6">
                <div>
                    <h4>Profile</h4>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>User Name</label>
                            <input type="text" name="username" class="form-control" id="username" value="' . $user_logged['user']['username'] . '"/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Full Name</label>
                            <input type="text" name="full_name" class="form-control" id="full_name" value="' . $user_logged['user']['full_name'] . '"/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" value="' . $user_logged['user']['email'] . '"/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" name="pass1" class="form-control" id="pass1" value="****" placeholder="****" />
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Password Confirmation</label>
                            <input type="password" name="pass2" class="form-control" id="pass2" value="****" placeholder="****" />
                        </div>
                    </div>
                </div>
            </div> ';

    $html = $html . '
            <div class="col-sm-6 center-block-candidate">
                <div>
                    <h4>Additional Info</h4>
                </div> 
                <div class="row">              
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Company Name</label>

                            <input type="text" name="company_name" class="form-control" id="company_name" value="' . $user_logged['user']['user_company_name'] . '"/>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Address</label>
                            <textarea name="address" id="address" class="form-control" rows="10" cols="30">' . $user_logged['user']['user_address'] . '</textarea>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-save-employer" class="btn btn-success">Save</span>
                            <span id="cancel-save-employer"  class="btn btn-danger">Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                      
        <div class="col-sm-12 col-12">
            <span id="go-sam-user-list" class="btn btn-info go-sam-user-list">User List</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
        </div>';

    $html = $html . '<script type="text/javascript"
        src="' . REQUIRE_PREFIX . '/app/Default/Assets/Documents/js/documents.js"></script>';


    return $html;
};
