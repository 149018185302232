<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-job-edit" class="btn btn-success attempt-job-edit">Edit</span>
                        <span id="go-build-job-list" class="btn btn-info go-build-job-list">Build Job List</span>
                        <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                    </div>
                        
                    <div>
                        <h4>Edit Job</h4>
                    </div>
                    
                    <input type="hidden" name="job_id" id="job_id" class="form-control" value="' . $rqd['job']['build_job_id'] .'" />

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>ID</label>
                            <span>' . $rqd['job']['build_job_id'] .'</span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="job_name" class="form-control" id="job_name" value="' . $rqd['job']['build_job_name'] .'" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea name="description" id="description" class="form-control" rows="10" cols="30">' . $rqd['job']['build_job_description'] .'</textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Shell</label>
                            <textarea name="shell_data" id="shell_data" class="form-control" rows="10" cols="30">' . $rqd['job']['shell_data'] .'</textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-job-edit" class="btn btn-success attempt-job-edit">Edit</span>
                        <span id="go-build-job-list" class="btn btn-info go-build-job-list">Build Job List</span>
                        <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                    </div>
                    
                </div>
            </div>
            
        </div>';

    return $html;

};