<?php

Namespace Controller ;

class UserSettingsController extends \Controller\Base {

    public function execute($pageVars) {

        \ISOPHP\js_core::$console->log('User Settings controller List pagevars');
        \ISOPHP\js_core::$console->log($pageVars);

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('User Settings controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated() ;
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In UserSettings, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }
        }

        $page_model = new \Model\UserSettings\PageModel();
        $default_page_model = new \Model\DefaultModule\PageModel();
        $page = $page_model->getPage($pageVars);
        \ISOPHP\js_core::$console->log('Yes serv con Here is action params....:', $pageVars['route']['action']);
        \ISOPHP\js_core::$window->document->title = $page['title'];
        $res = new \Controller\Result();

        $res->page = $page;
        $res->view = 'UserSettings.php';
        $res->type = 'view';
        $res->view_control = 'UserSettings';
        $res->post_template = array();

        if ($pageVars['route']['action'] == 'landing' || $pageVars['route']['action'] == 'show') {
            $res->post_template[] = $page_model->showAccountOptions($pageVars);
        }

        $res->post_template[] = $page_model->bindButtons();
        \ISOPHP\js_core::$console->log('UserSettings Con', $res);
        $res->post_template[] = $default_page_model->bindMenu();
        return $res;

    }

}