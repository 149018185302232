<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $domains_are_array = \ISOPHP\core::$php->is_array($rqd['domains']) ;
    if ($domains_are_array === true) {
        $domains_exist = true ;
    } else {
        $domains_exist = false ;
    }
    if ($domains_exist === true) {
        $domain_count = \ISOPHP\core::$php->count($rqd['domains']) ;
    } else {
        $domain_count = false ;
    }

    if ($domains_exist === true && $domain_count !== false) {
        $html = $html . '<select class="form-control" name="domain_name" id="domain_name">';
        foreach ($rqd['domains'] as $one_dns_domain) {
            $html = $html . '<option value="' . $one_dns_domain['dns_domain_name'] . '">' . $one_dns_domain['dns_domain_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available domains</option>
             </select>' ;

    }

    return $html;

};