<?php

Namespace Model\SC1;

use ISOPHP\core;

class LaunchTemplatePageModel extends \Model\Base {


    public function showSC1LaunchTemplateCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'LaunchTemplateCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate Create 2', $vars);
        };
    }

    public function showSC1LaunchTemplateList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/launchtemplate/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1LaunchTemplateListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1LaunchTemplateListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LaunchTemplate List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LaunchTemplateList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LaunchTemplate List failed');
            }
        };
        return $func;
    }

    public function showSC1LaunchTemplateEdit($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Edit');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/launchtemplate/one';
            $vars['id'] = $pageVars['params']['id'] ;
            $afterwards = $this->applySC1LaunchTemplateEditResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'LaunchTemplateEdit.php', $vars);
            $bind = \Model\SC1\PageModel::bindAllButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Launch Template Edit 2', $vars);
            return $result;
        };
    }

    public function applySC1LaunchTemplateEditResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 LaunchTemplate Edit result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate Edit 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'LaunchTemplateEdit.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate Edit 2', $vars);

                $requested_data = array() ;
                foreach ($request_data as $request_data_key => $request_data_value) {
                    $requested_data[$request_data_key] = $request_data_value ;
                }
                $pageVars['params']['image_id_preselected'] = $vars['request_data']['launchtemplate']['image_id'] ;
                $pageVars['params']['keypair_preselected'] = $vars['request_data']['launchtemplate']['keypair'] ;
                $pageVars['params']['size_id_preselected'] = $vars['request_data']['launchtemplate']['size_id'] ;
                $pageVars['params']['vpc_ids_preselected'] = $vars['request_data']['launchtemplate']['private_network_ids'] ;

                \ISOPHP\js_core::$console->log('apply SC1 LaunchTemplate Edit 2', $vars);
                \ISOPHP\js_core::$console->log($pageVars);

                $instances_page_model = new \Model\SC1\InstancePageModel();
                $svpc_page_model = new \Model\SVPC\PageModel();
                $f1 = $instances_page_model->showSC1InstanceImageDropDown($pageVars);
                $f2 = $instances_page_model->showSC1InstanceSizeDropDown($pageVars);
                $f3 = $instances_page_model->showSC1KeypairDropDown($pageVars);
                $f4 = $svpc_page_model->showSVPCVPCListMultiSelect($pageVars);
                $f1() ;
                $f2() ;
                $f3() ;
                $f4() ;

                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 LaunchTemplate Edit failed');
            }
        };
        return $func;
    }

    /**
     * Update launchtemplate
     */
    public function updateLaunchTemplateAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('update launchtemplate to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'launchtemplate-update';
        $vars['api_uri'] = '/api/sc1/launchtemplate/update';
        $vars['id'] = $jQuery('#launchtemplate_id')->val();
        $vars['template_name'] = $jQuery('#template_name')->val();
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $private_network_array = $jQuery('#svpc_name')->val();
        $vars['private_networks'] = \ISOPHP\core::$php->join("," , $private_network_array) ;

        \ISOPHP\js_core::$console->log('keypair id val');
        \ISOPHP\js_core::$console->log( $jQuery('#keypair_id')->val() );
        if ($jQuery('#keypair_id')->val() !== "none") {
            $vars['keypair'] = $jQuery('#keypair_id')->val();
        }
        if ($jQuery('#internet_enabled')->is(":checked")) {
            $vars['internet_enabled'] = "true";
        }
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['image_id'] = $jQuery('#image_id')->val();
        $vars['description'] = $jQuery('#description')->val();
        $vars['userdata'] = $jQuery('#userdata')->val();

        \ISOPHP\js_core::$console->log('save launchtemplate vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveLaunchTemplateResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    /**
     * Save launchtemplate
     */
    public function saveLaunchTemplateAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save launchtemplate to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $valid = $this->validateLaunchTemplateForm($pageVars) ;
        if ($valid !== true) {
            return false ;
        }
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'launchtemplate-create';
        $vars['api_uri'] = '/api/sc1/launchtemplate/create';
        $vars['template_name'] = $jQuery('#template_name')->val();
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['public_network'] = $jQuery('#public_network')->val();
        $private_network_array = $jQuery('#svpc_name')->val();
        $vars['private_networks'] = \ISOPHP\core::$php->join("," , $private_network_array) ;
        if ($jQuery('#keypair_id')->val() !== "none") {
            $vars['keypair'] = $jQuery('#keypair_id')->val();
        }
        if ($jQuery('#internet_enabled')->is(":checked")) {
            $vars['internet_enabled'] = "true";
        }
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['image_id'] = $jQuery('#image_id')->val();
        $vars['description'] = $jQuery('#description')->val();
        $vars['userdata'] = $jQuery('#userdata')->val();

        \ISOPHP\js_core::$console->log('save launchtemplate vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveLaunchTemplateResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function validateLaunchTemplateForm() {
        \ISOPHP\js_core::$console->log('validate launchtemplate to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $template_name = $jQuery('#template_name')->val();
        $template_name_length = \ISOPHP\core::$php->strlen($template_name);
        if ($template_name_length == 0) {
            $error_message = 'Template must have a name';
            \Core\WindowMessage::showMessage($error_message, 'bad');
            return false;
        }
        \ISOPHP\js_core::$console->log('launchtemplate is valid');
        return true ;
    }

    public function saveLaunchTemplateResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 launchtemplate result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created LaunchTemplate '.$request_data['launchtemplate']['sc1_launchtemplate_name'].' for Instance '.$request_data['launchtemplate']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 launchtemplate saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'launchtemplate-list', array(), '/sc1/launchtemplate/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating LaunchTemplate '.$request_data['launchtemplate']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 launchtemplate save failed');
            }
        };
        return $func;
    }
    /**
     * Delete launchtemplate
     */
    public function deleteLaunchTemplateAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete launchtemplate to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'launchtemplate-delete';
        $vars['api_uri'] = '/api/sc1/launchtemplate/delete';
        $vars['name'] = $pageVars['params']['name'] ;
        $vars['id'] = $pageVars['params']['id'] ;
        \ISOPHP\js_core::$console->log('delete launchtemplate vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteLaunchTemplateResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteLaunchTemplateResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 launchtemplate result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Launch Template '.$request_data['launchtemplate'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 launchtemplate deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'launchtemplate-list', array(), '/sc1/launchtemplate/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Launch Template '.$request_data['launchtemplate'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 launchtemplate delete failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                # Launch Template Buttons
                \ISOPHP\js_core::$console->log('binding launch template buttons');

                $go_sc1_launchtemplate_list = $jQuery('.go-sc1-launchtemplate-list');
                $go_sc1_launchtemplate_list->off() ;
                $go_sc1_launchtemplate_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'launchtemplate-list', array(), '/sc1/launchtemplate/list');
                });

                $go_sc1_launchtemplate_list_page = $jQuery('.sc1-launchtemplate-paginator');
                $go_sc1_launchtemplate_list_page->off() ;
                $go_sc1_launchtemplate_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 launchtemplate paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'launchtemplate-list', array('page' => $new_page), '/sc1/launchtemplate/list/page/'.$new_page);
                });

                $go_sc1_launchtemplate_create = $jQuery('.go-sc1-launchtemplate-create');
                $go_sc1_launchtemplate_create->off() ;
                $go_sc1_launchtemplate_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'launchtemplate-create', array(), '/sc1/launchtemplate/create');
                });

                $go_sc1_launchtemplate_edit = $jQuery('.go-sc1-launchtemplate-edit');
                $go_sc1_launchtemplate_edit->off() ;
                $go_sc1_launchtemplate_edit->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $launchtemplate_id = $jqThis->attr('data-sc1-launchtemplate-id') ;
                    $navigate->route('SC1', 'launchtemplate-edit', array(
                        'id' => $launchtemplate_id
                    ), '/sc1/launchtemplate/edit/'.$launchtemplate_id);
                });

                $save_launchtemplate = $jQuery('.attempt-sc1-launchtemplate-create');
                $save_launchtemplate->off() ;
                $save_launchtemplate->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'launchtemplate-save-attempt', array());
                });

                $launchtemplate_update = $jQuery('.attempt-sc1-launchtemplate-update');
                $launchtemplate_update->off() ;
                $launchtemplate_update->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $launchtemplate_id = $jqThis->attr('data-sc1-launchtemplate-id') ;
                    $navigate->route('SC1', 'launchtemplate-update-attempt', array(
                        'id' => $launchtemplate_id
                    ), '/sc1/launchtemplate/update/'.$launchtemplate_id);
                } ) ;

                $launchtemplate_delete = $jQuery('.attempt-sc1-launchtemplate-delete');
                $launchtemplate_delete->off() ;
                $launchtemplate_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $launchtemplate_name = $jqThis->attr('data-sc1-launchtemplate-name') ;
                    $launchtemplate_id = $jqThis->attr('data-sc1-launchtemplate-id') ;
                    \ISOPHP\js_core::$console->log('Clicked launchtemplate delete button'. $launchtemplate_name. $launchtemplate_id);
                    if ($launchtemplate_name !== '') {
                        $name_or_id = $launchtemplate_name ;
                    } else {
                        $name_or_id = $launchtemplate_id ;
                    }
                    $msg = "You are about to delete this Launch Template $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $launchtemplate_id, $launchtemplate_name) {
                        $navigate->route('SC1', 'launchtemplate-delete-attempt', array(
                            'id' => $launchtemplate_id,
                            'name' => $launchtemplate_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}