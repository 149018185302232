<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                    <div class="row">
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-capacityreservation-create" class="btn btn-success attempt-capacityreservation-create">Create</span>
                            <span id="go-sc1-capacityreservation-list" class="btn btn-warning go-sc1-capacityreservation-list">Capacity Reservation List</span>
                            <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                        </div>
                
                        <div>
                            <h4>New Capacity Reservation</h4>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Size</label>
                                <div id="sc1_size_dropdown">
                                    <select class="form-control" name="instance_name" id="instance_name">
                                        <option>Loading Instances</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Count</label>
                                <input type="text" name="count" class="form-control" id="count" value="" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Start Time</label>
                                <input type="text" name="start_time" class="form-control datepicker-dropdown" id="start_time" value="" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>End Time</label>
                                <input type="text" name="end_time" class="form-control datepicker-dropdown" id="end_time" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea name="description" id="description" class="form-control"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-capacityreservation-create" class="btn btn-success attempt-capacityreservation-create">Create</span>
                            <span id="go-sc1-capacityreservation-list" class="btn btn-warning go-sc1-capacityreservation-list">Capacity Reservation List</span>
                            <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating SC1 Capacity Reservations</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_capacityreservation_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_capacityreservation_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_capacityreservation_ptc_aws" class="instruction_set instruction_set_create_capacityreservation_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
                     
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_capacityreservation_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_capacityreservation_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_capacityreservation_awscli_aws" class="instruction_set instruction_set_create_capacityreservation_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com --caller-reference 123451234512345 --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceVolumeSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>

             </div>';

    return $html;

};