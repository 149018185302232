<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $scheduledinstance_executionlogs_are_array = \ISOPHP\core::$php->is_array($rqd['scheduledinstance_executionlogs']) ;
    if ($scheduledinstance_executionlogs_are_array === true) {
        $scheduledinstance_executionlogs_exist = true ;
    } else {
        $scheduledinstance_executionlogs_exist = false ;
    }
    if ($scheduledinstance_executionlogs_exist === true) {
        $scheduledinstance_executionlog_count = \ISOPHP\core::$php->count($rqd['scheduledinstance_executionlogs']) ;
    } else {
        $scheduledinstance_executionlog_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scheduledinstance-list" class="btn btn-info go-sc1-scheduledinstance-list">Scheduled Instances</span>
            <span id="go-sc1-scheduledinstance-executionlog-list" class="btn btn-info go-sc1-scheduledinstance-executionlog-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Scheduled Instance Execution Log List</h4>
         </div> ' ;

    if ( ($scheduledinstance_executionlogs_exist === true) && ($scheduledinstance_executionlog_count > 0) ) {
        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_scheduledinstance item_list_sc1_scheduledinstance_executionlogs" id="item_list_sc1_scheduledinstance_executionlogs">
                        <div class="col-sm-3 col-3">
                            <h5 class="sc1_scheduledinstance_table_title">Time</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="sc1_scheduledinstance_table_title">Status</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="sc1_scheduledinstance_table_title">ID</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="sc1_scheduledinstance_table_title">Message</h5>
                        </div>
                    </div>
                 </div>';
        foreach ($rqd['scheduledinstance_executionlogs'] as $one_sc1_scheduledinstance_executionlog) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_time"><span class="sc1_list_time sc1_listing">' . $one_sc1_scheduledinstance_executionlog['time'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_status"><span class="sc1_list_status sc1_listing">' . $one_sc1_scheduledinstance_executionlog['status'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_id"><span class="sc1_list_id sc1_listing">' . $one_sc1_scheduledinstance_executionlog['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_message"><span class="sc1_list_message sc1_listing">' . $one_sc1_scheduledinstance_executionlog['message'] . '</span></p>
                    </div>
                 </div>';
        }
    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Scheduled Instance Execution Logs</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scheduledinstance-list" class="btn btn-info go-sc1-scheduledinstance-list">Scheduled Instances</span>
            <span id="go-sc1-scheduledinstance-executionlog-list" class="btn btn-info go-sc1-scheduledinstance-executionlog-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    return $html;

};