<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $pipelines_are_array = \ISOPHP\core::$php->is_array($rqd['pipelines']) ;
    if ($pipelines_are_array === true) {
        $pipelines_exist = true ;
    } else {
        $pipelines_exist = false ;
    }
    if ($pipelines_exist === true) {
        $pipeline_count = \ISOPHP\core::$php->count($rqd['pipelines']) ;
    } else {
        $pipeline_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-build-pipeline-create" class="btn btn-success go-build-pipeline-create">New Pipeline</span>
            <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
            <span id="go-build-pipeline-list" class="btn-refresh btn btn-info go-build-pipeline-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Pipeline List</h4>
        </div> ' ;

    if ($pipelines_exist === true && $pipeline_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_build_pipeline">
                    <div class="col-sm-3 col-3">
                        <h5 class="build_pipeline_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="build_pipeline_id_title">Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="build_pipeline_id_title">Description</h5>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h5 class="build_pipeline_id_title">#</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="build_pipeline_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['pipelines'] as $one_build_pipeline) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_build_pipeline">
                    <div class="col-sm-3 col-3">
                        <p class="build_list_id"><span class="build_id build_listing">' . $one_build_pipeline['build_pipeline_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="build_list_name"><span class="build_name build_listing">' . $one_build_pipeline['build_pipeline_name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="build_list_comment build_listing">' . $one_build_pipeline['build_pipeline_description'] . '</p>
                    </div>  
                    <div class="col-sm-1 col-1">
                        <p class="build_list_count build_listing">' . $one_build_pipeline['last_run_build'] . '</p>
                    </div>     
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-3 col-3">
                                <i id="execute_build_pipeline_' . $one_build_pipeline['id'] . '"
                                   class="fa fa-1x fa-play hvr-grow dom attempt-pipeline-execute btn btn-outline-success"
                                   data-build-pipeline-id="' . $one_build_pipeline['id'] . '"
                                   data-build-pipeline-name="' . $one_build_pipeline['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="execution_list_build_pipeline_' . $one_build_pipeline['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-pipeline-execution-list btn btn-outline-primary"
                                   data-build-pipeline-id="' . $one_build_pipeline['id'] . '"
                                   data-build-pipeline-name="' . $one_build_pipeline['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="edit_build_pipeline_' . $one_build_pipeline['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom go-build-pipeline-edit btn btn-outline-primary"
                                   data-build-pipeline-id="' . $one_build_pipeline['id'] . '"
                                   data-build-pipeline-name="' . $one_build_pipeline['name'] . '"></i>
                            </div>
                            <div class="col-sm-3 col-3">
                                <i id="delete_build_pipeline_' . $one_build_pipeline['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-pipeline-delete btn btn-outline-danger"
                                   data-build-pipeline-id="' . $one_build_pipeline['id'] . '"
                                   data-build-pipeline-name="' . $one_build_pipeline['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Pipelines</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-build-pipeline-create" class="btn btn-success go-build-pipeline-create">New Pipeline</span>
                <span id="go-build-landing" class="btn btn-info go-build-landing">Build Home</span>
                <span id="go-build-pipeline-list" class="btn-refresh btn btn-info go-build-pipeline-list btn-refresh">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};