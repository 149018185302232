<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Mobile Device, your real Mobile Handset Access</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Mobile Devices for Application Testing</h3>
                    <p>Handsets for Application</p>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-mobiledevice-device-list" class="go-mobiledevice-device-list btn btn-lg btn-warning">Devices</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-mobiledevice-device-create" class="go-mobiledevice-device-create btn btn-lg btn-warning">Create Device</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-mobiledevice-emulator-list" class="go-mobiledevice-emulator-list btn btn-lg btn-warning">Emulators</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-mobiledevice-emulator-create" class="go-mobiledevice-emulator-create btn btn-lg btn-warning">Create Emulator</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;