<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to your Access Management Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>Piranha Access Management</h3>
                        <p>Users, Groups, Permissions and Security Credentials across your Piranha Cloud Estate</p>            
                    </div>
                    
                    <div class="col-sm-12">
                    
                        <div class="col-sm-12 landing-row">
                            <div class="col-sm-6">
                                <span id="go-sam-user-list" class="go-sam-user-list btn btn-lg btn-warning">User List</span>
                            </div>
                            <div class="col-sm-6">
                                <span id="go-sam-user-create" class="go-sam-user-create btn btn-lg btn-warning">User Create</span>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 landing-row">
                            <div class="col-sm-6">
                                <span id="go-sam-group-list" class="go-sam-group-list btn btn-lg btn-warning disabled">Group List</span>
                            </div>
                            <div class="col-sm-6">
                                <span id="go-sam-group-create" class="go-sam-group-create btn btn-lg btn-warning disabled">Group Create</span>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 landing-row">
                            <div class="col-sm-6">
                                <span id="go-sam-role-list" class="go-sam-role-list btn btn-lg btn-warning disabled">Role List</span>
                            </div>
                            <div class="col-sm-6">
                                <span id="go-sam-role-create" class="go-sam-role-create btn btn-lg btn-warning disabled">Role Create</span>
                            </div>
                        </div>
                                        
                    </div>
                    
                </div>
                    
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>Policies</h3>
                        <p>Policies for your Piranha Cloud Estate</p>          
                    </div>
                    
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <span id="go-sam-policy-list" class="go-sam-policy-list btn btn-lg btn-warning disabled">Policies</span>
                        </div>
                        <div class="col-sm-6">
                            <span id="go-sam-policy-create" class="go-sam-policy-create btn btn-lg btn-warning disabled">New Policy</span>
                        </div>                                        
                    </div>
                </div>
                    
                <div class="col-sm-4">
                
                    <div class="col-sm-12">
                        <h3>Account Settings</h3>
                        <p>Manage your User Account</p>          
                    </div>
                    
                    <div class="col-sm-12">
                        <span id="go-sam-user-account" class="go-sam-user-account btn btn-lg btn-warning">Your Account</span>
                    </div>
                    
                </div>
                
            </div>
          
        </div>';

    return $html ;

} ;
