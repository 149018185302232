<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                
                    <div class="col-sm-12 col-12">
                        <h4>Domain <strong>' . $pv['params']['domain_name'] . '</strong>, New Record</h4>
                    </div> 
                    
                    <div class="row">
                    
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="record_name" class="form-control" id="record_name" value="" />
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Identifier</label>
                                <input type="text" name="identifier" class="form-control" id="identifier" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Value</label>
                                <textarea name="record_value" id="record_value" class="form-control"></textarea>
                            </div>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Record Type</label>
                                <select id="record_type" name="record_type" class="form-control">
                                     <option value="a_ipv4_address">A – IPv4 address</option>
                                     <option value="cname_canonical_name">CNAME – Canonical name</option>
                                     <option value="mx_mail_exchange">MX – Mail exchange</option>
                                     <option value="aaaa_ipv6_address">AAAA – IPv6 address</option>
                                     <option value="txt_text">TXT – Text</option>
                                     <option value="ptr_pointer">PTR – Pointer</option>
                                     <option value="srv_service_locator">SRV – Service locator</option>
                                     <option value="spf_sender_policy_framework">SPF – Sender Policy Framework</option>
                                     <option value="naptr_name_authority_pointer">NAPTR – Name Authority Pointer</option>
                                     <option value="ns_name_server">NS – Name server</option>
                                </select>
                            </div>
                        </div>
                       
                       <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Region</label>
                                <select id="region" name="reion" class="form-control">
                                     <option value="dc">UK South</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-record-create" class="btn btn-success attempt-record-create">Create</span>
                            <span id="go-dns-record-list"
                                  class="btn btn-warning go-dns-record-list"
                                  data-dns-domain-name="' . $pv['params']['domain_name'] . '">DNS Record List</span>
                            <span id="go-dns-landing" class="btn btn-warning go-dns-landing">DNS Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    $html = $html . '<input type="hidden" id="dns-domain-id" value="' . $pv['params']['domain_id'] . '" />' ;
    $html = $html . '<input type="hidden" id="dns-domain-name" value="' . $pv['params']['domain_name'] . '" />' ;

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating DNS Records</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_record_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_record_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_record_ptc_aws" class="instruction_set instruction_set_create_record_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
                     
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_record_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_record_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_record_awscli_aws" class="instruction_set instruction_set_create_record_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com --caller-reference 123451234512345 --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceRecordSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>

             </div>';

    return $html;

};

/*
 *
 *
                    <h5>AWS Mode - Pharaoh Configure<i id="switch_instruction_set_create_record_ptc_rax"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_record_ptc_rax"></i></h5>
                    <pre id="instruction_set_create_record_ptc_rax" class="instruction_set instruction_set_create_record_ptc_rax">
ptconfigure-enterprise AWSRoute53 ensure-domain-exists --domain-name="testmaildomain.com" --domain-email="testmail@testmaildomain.com"  --domain-comment="A Comment"  --domain-ttl="60"  --aws-access-key="1234" --aws-secret-key="ABC1234" --aws-region="eu-west-1" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format="json" --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmaildomain.com"
}

                    </pre>
 *
 */
