<?php

Namespace Model\ArduinoExecute;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'ArduinoExecute List of Piranha Web Services';
        $page['heading'] = 'List of ArduinoExecute';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('ArduinoExecute Mod', $page, $pageVars);
        return $page;
    }

    public function showArduinoExecuteLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing ArduinoExecute Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArduinoExecuteView();
            $view->loadTemplate('ArduinoExecute', 'ArduinoExecuteLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayArduinoExecute($bind);
            \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket Create 2', $vars);
        };
    }

    public function showArduinoExecuteBucketCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing ArduinoExecute Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArduinoExecuteView();
            $view->loadTemplate('ArduinoExecute', 'BucketCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayArduinoExecute($bind);
            \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket Create 2', $vars);
        };
    }

    public function showArduinoExecuteBucketList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing ArduinoExecute List');
            $vars['control'] = 'ArduinoExecute';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/arduinoexecute/bucket/all';
            $afterwards = $this->applyArduinoExecuteBucketListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('ArduinoExecute Model: ', $result);
            return $result;
        };
    }

    public function applyArduinoExecuteBucketListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our ArduinoExecute Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket List 1', $vars);
                $view = new \View\ArduinoExecuteView();
                $view->loadTemplate('ArduinoExecute', 'BucketList.php', $vars);
                $bind = self::bindButtons();
                $view->displayArduinoExecute($bind);
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('ArduinoExecute Bucket List failed');
            }
        };
        return $func;
    }

    public function showArduinoExecuteBucketListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing ArduinoExecute List');
            $vars['control'] = 'ArduinoExecute';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/arduinoexecute/bucket/all';
            $afterwards = $this->applyArduinoExecuteBucketListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('ArduinoExecute Model: ', $result);
            return $result;
        };
    }

    public function applyArduinoExecuteBucketListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our ArduinoExecute Bucket List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket List 1', $vars);
                $view = new \View\ArduinoExecuteView();
                $view->loadTemplate('ArduinoExecute', 'BucketListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayArduinoExecuteBucketDropDown($bind);
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Bucket List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('ArduinoExecute Bucket List failed');
            }
        };
        return $func;
    }

    public function showArduinoExecuteObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing ArduinoExecute Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArduinoExecuteView();
            $view->loadTemplate('ArduinoExecute', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayArduinoExecute($bind);
            \ISOPHP\js_core::$console->log('apply ArduinoExecute Object Create 2', $vars);
        };
    }

    public function showArduinoExecuteObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for bucket name');
            \ISOPHP\js_core::$console->log($pageVars['bucket_name']);
            if (isset($pageVars['bucket_name'])) {
                \ISOPHP\js_core::$console->log('Showing ArduinoExecute List');
                $vars['control'] = 'ArduinoExecute';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/arduinoexecute/object/all';
                $vars['bucket_name'] = $pageVars['bucket_name'];
                $afterwards = $this->applyArduinoExecuteObjectListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('ArduinoExecute Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyArduinoExecuteObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our ArduinoExecute Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Object List 1', $vars);
                $view = new \View\ArduinoExecuteView();
                $view->loadTemplate('ArduinoExecute', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayArduinoExecute($bind);
                \ISOPHP\js_core::$console->log('apply ArduinoExecute Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('ArduinoExecute Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save bucket
     */
    public function saveBucketAttemptToArduinoExecute($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save bucket to arduinoexecute');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'ArduinoExecute';
        $vars['action'] = 'bucket-create';
        $vars['api_uri'] = '/api/arduinoexecute/bucket/create';
        $vars['bucket_name'] = $jQuery('#bucket_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveBucketResultFromArduinoExecute($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveBucketResultFromArduinoExecute($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save arduinoexecute bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Bucket '.$request_data['bucket']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('arduinoexecute bucket saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('ArduinoExecute', 'bucket-list', array(), '/arduinoexecute/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Bucket '.$request_data['bucket']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('arduinoexecute bucket save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToArduinoExecute($pageVars)
    {
        \ISOPHP\js_core::$console->log('save object to arduinoexecute');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'ArduinoExecute';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/arduinoexecute/object/create';
        $vars['bucket_name'] = $jQuery('#bucket_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $vars['object_value'] = $jQuery('#object_value')->val();
        $vars['object_type'] = $jQuery('#object_type')->val();
        \ISOPHP\js_core::$console->log('save object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromArduinoExecute($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromArduinoExecute($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save arduinoexecute object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['object']['arduinoexecute_object_name'].' for Bucket '.$request_data['object']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('arduinoexecute object saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['object']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('arduinoexecute object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToArduinoExecute($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to arduinoexecute');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'ArduinoExecute';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/arduinoexecute/object/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromArduinoExecute($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteObjectResultFromArduinoExecute($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete arduinoexecute object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object']['arduinoexecute_object_name'].' from Bucket '.$request_data['object']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('arduinoexecute object deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('arduinoexecute object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete bucket
     */
    public function deleteBucketAttemptToArduinoExecute($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete bucket to arduinoexecute');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'ArduinoExecute';
        $vars['action'] = 'bucket-delete';
        $vars['api_uri'] = '/api/arduinoexecute/bucket/delete';
        $vars['bucket_name'] = $pageVars['params']['bucket_name'];
        \ISOPHP\js_core::$console->log('delete bucket vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBucketResultFromArduinoExecute($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBucketResultFromArduinoExecute($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete arduinoexecute bucket result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Bucket '.$request_data['bucket']['arduinoexecute_bucket_name'].' for Bucket '.$request_data['bucket']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('arduinoexecute bucket deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('ArduinoExecute', 'bucket-list', array(), '/arduinoexecute/bucket/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Bucket '.$request_data['bucket']['arduinoexecute_bucket_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('arduinoexecute bucket delete failed');
            }
            $show_bucket_list = self::showArduinoExecuteBucketList($pageVars) ;
            $show_bucket_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_arduinoexecute_landing = $jQuery('.go-arduinoexecute-landing');
                $go_arduinoexecute_landing->off() ;
                $go_arduinoexecute_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'landing', array(), '/arduinoexecute');
                });

                $go_arduinoexecute_bucket_list = $jQuery('.go-arduinoexecute-bucket-list');
                $go_arduinoexecute_bucket_list->off() ;
                $go_arduinoexecute_bucket_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'bucket-list', array(), '/arduinoexecute/bucket/list');
                });

                $go_arduinoexecute_bucket_create = $jQuery('.go-arduinoexecute-bucket-create');
                $go_arduinoexecute_bucket_create->off() ;
                $go_arduinoexecute_bucket_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'bucket-create', array(), '/arduinoexecute/bucket/create');
                });

                $save_bucket = $jQuery('.attempt-bucket-create');
                $save_bucket->off() ;
                $save_bucket->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'bucket-save-attempt', array());
                });

                $go_arduinoexecute_object_list = $jQuery('.go-arduinoexecute-object-list');
                $go_arduinoexecute_object_list->off() ;
                $go_arduinoexecute_object_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'object-list', array(), '/arduinoexecute/object/list');
                });

                $go_arduinoexecute_object_create = $jQuery('.go-arduinoexecute-object-create');
                $go_arduinoexecute_object_create->off() ;
                $go_arduinoexecute_object_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'object-create', array(), '/arduinoexecute/object/create');
                });

                $save_object = $jQuery('.attempt-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('ArduinoExecute', 'object-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-arduinoexecute-object-name') ;
                    $object_id = $jqThis->attr('data-arduinoexecute-object-id') ;
                    $bucket_name = $jqThis->attr('data-arduinoexecute-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg = "You are about to delete the Object for $object_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $bucket_name) {
                        $navigate->route('ArduinoExecute', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'bucket_name' => $bucket_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $bucket_delete = $jQuery('.attempt-bucket-delete');
                $bucket_delete->off() ;
                $bucket_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $bucket_name = $jqThis->attr('data-arduinoexecute-bucket-name') ;
                    \ISOPHP\js_core::$console->log('Clicked bucket delete button');
                    $msg = "You are about to delete the Bucket $bucket_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $bucket_name) {
                        $navigate->route('ArduinoExecute', 'bucket-delete-attempt', array(
                            'bucket_name' => $bucket_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindBucketListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding bucket switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding bucket switch');
            $switch_bucket = $jQuery('#bucket_name');
            $switch_bucket->off() ;
            $switch_bucket->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#bucket_name') ;
                $bucket_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked bucket switch');
                $navigate->route('ArduinoExecute', 'object-list', array(
                    'bucket_name' => $bucket_name,
                ));
            } ) ;

        };
        return $func;
    }



}