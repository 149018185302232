<?php

Namespace Controller ;

class ComingSoonController extends \Controller\Base {

    public function execute($pageVars) {
        $page_model = new \Model\ComingSoon\PageModel() ;
        $page = $page_model->getPage() ;
        \ISOPHP\js_core::$console->log('ICP', $page) ;
        $res = new \Controller\Result() ;
        // if ($pageVars['route']['action'] == 'attempt') {
        //     \ISOPHP\js_core::$console->log('Coming Soon email subscription in controller');
        //     $page_model->setEmailSubscriptionResult($pageVars);
        //     $res->type = null; # no type means no further action
        //     return $res;
        // }
        $res->page = $page ;
        $res->view = 'ComingSoon.php' ;
        $res->type = 'view' ;
        $res->view_control = 'ComingSoon' ;
        $res->post_template[] = $page_model->bindButtons() ;
        \ISOPHP\js_core::$console->log('ComingSoon Con', $res) ;
        return $res ;
    }

}