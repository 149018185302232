<?php

Namespace Model\SC1;

use ISOPHP\core;

class SnapshotPageModel extends \Model\Base {


    public function showSC1SnapshotCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'SnapshotCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 Snapshot Create 2', $vars);
        };
    }

    public function showSC1SnapshotList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/snapshot/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1SnapshotListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1SnapshotListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Snapshot List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Snapshot List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'SnapshotList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Snapshot List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Snapshot List failed');
            }
        };
        return $func;
    }

    /**
     * Save snapshot
     */
    public function saveSnapshotAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save snapshot to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'snapshot-create';
        $vars['api_uri'] = '/api/sc1/snapshot/create';
        $vars['instance_id'] = $jQuery('#vm_id')->val();
        $vars['snapshot_name'] = $jQuery('#snapshot_name')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save snapshot vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSnapshotResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSnapshotResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 snapshot result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Snapshot '.$request_data['snapshot']['name'] ;
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 snapshot saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'snapshot-list', array(), '/sc1/snapshot/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Snapshot '.$request_data['snapshot']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 snapshot save failed');
            }
        };
        return $func;
    }
    /**
     * Delete snapshot
     */
    public function deleteSnapshotAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete snapshot to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'snapshot-delete';
        $vars['api_uri'] = '/api/sc1/snapshot/delete';
        $vars['instance_name'] = $pageVars['params']['instance_id'];
        $vars['snapshot_id'] = $pageVars['params']['snapshot_id'];
        \ISOPHP\js_core::$console->log('delete snapshot vars');
        \ISOPHP\js_core::$console->log($vars);
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteSnapshotResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSnapshotResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 snapshot result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Snapshot '.$request_data['snapshot']['name'].' for Instance '.$request_data['snapshot']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 snapshot deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'snapshot-list', array(), '/sc1/snapshot/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Snapshot '.$request_data['snapshot']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 snapshot delete failed');
            }
        };
        return $func;
    }

    /**
     * Restore snapshot
     */
    public function restoreSnapshotAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('restore snapshot to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'snapshot-restore';
        $vars['api_uri'] = '/api/sc1/snapshot/restore';
        $vars['instance_id'] = $pageVars['params']['instance_id'];
        $vars['id'] = $pageVars['params']['snapshot_id'];
        \ISOPHP\js_core::$console->log('restore snapshot vars');
        $afterwards = $this->restoreSnapshotResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function restoreSnapshotResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Restore sc1 snapshot result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Restored Snapshot '.$request_data['snapshot']['name'].' for Instance '.$request_data['snapshot']['instance_id'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 snapshot restored, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SC1', 'snapshot-list', array(), '/sc1/snapshot/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Restoring Snapshot '.$request_data['snapshot']['name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 snapshot restore failed');
            }
        };
        return $func;
    }

    /**
     * Delete snapshot
     */
    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding snapshot buttons');

                # Snapshots
                $go_sc1_snapshot_list = $jQuery('.go-sc1-snapshot-list');
                $go_sc1_snapshot_list->off() ;
                $go_sc1_snapshot_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'snapshot-list', array(), '/sc1/snapshot/list');
                });

                $go_sc1_snapshot_list_page = $jQuery('.sc1-snapshot-paginator');
                $go_sc1_snapshot_list_page->off() ;
                $go_sc1_snapshot_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 snapshot paginator');
                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $new_page = $jqThis->attr('data-page') ;
//                    $jsthis->preventDefault();
//                    $navigate->route('SC1', 'snapshot-list', array('page' => $new_page), '/sc1/snapshot/list/page/'.$new_page);
                });

                $go_sc1_snapshot_create = $jQuery('.go-sc1-snapshot-create');
                $go_sc1_snapshot_create->off() ;
                $go_sc1_snapshot_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'snapshot-create', array(), '/sc1/snapshot/create');
                });

                $save_snapshot = $jQuery('.attempt-snapshot-create');
                $save_snapshot->off() ;
                $save_snapshot->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'snapshot-save-attempt', array());
                });

                $snapshot_delete = $jQuery('.attempt-snapshot-delete');
                $snapshot_delete->off() ;
                $snapshot_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $snapshot_name = $jqThis->attr('data-sc1-snapshot-name') ;
//                    $snapshot_id = $jqThis->attr('data-sc1-snapshot-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked snapshot delete button');
//                    if ($snapshot_name !== '') {
//                        $name_or_id = $snapshot_name ;
//                    } else {
//                        $name_or_id = $snapshot_id ;
//                    }
//                    $msg = "You are about to delete the Snapshot $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $snapshot_id, $snapshot_name) {
//                        $navigate->route('SC1', 'snapshot-delete-attempt', array(
//                            'snapshot_id' => $snapshot_id,
//                            'snapshot_name' => $snapshot_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $snapshot_restore = $jQuery('.attempt-snapshot-restore');
                $snapshot_restore->off() ;
                $snapshot_restore->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $snapshot_name = $jqThis->attr('data-sc1-snapshot-name') ;
//                    $snapshot_id = $jqThis->attr('data-sc1-snapshot-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked snapshot restore button');
//                    if ($snapshot_name !== '') {
//                        $name_or_id = $snapshot_name ;
//                    } else {
//                        $name_or_id = $snapshot_id ;
//                    }
//                    $msg = "You are about to restore the Snapshot $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $snapshot_id, $snapshot_name) {
//                        $navigate->route('SC1', 'snapshot-restore-attempt', array(
//                            'snapshot_id' => $snapshot_id,
//                            'snapshot_name' => $snapshot_name
//                        ));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        } ;
    }

}