<?php

Namespace View;

class SC1View extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('#template', $tpl_data);
    }

    public function displaySC1($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_content')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySC1InstanceDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 instance dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_instance_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySC1ImageDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 image dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_image_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySC1SizeDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 size dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_size_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySC1LoadBalancerSizeDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 loadbalancer size dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_loadbalancer_size_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }


    public function displaySC1KeypairDropDown($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 keypair dropdown');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_keypair_dropdown')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }


    public function displaySC1SingleInstanceDetails($binder)
    {
        \ISOPHP\js_core::$console->log('display sc1 single instance details');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();
//        \ISOPHP\js_core::$console->log('davey ravey don display accounts', $tpl_data) ;
        $jQuery('#sc1_single_instance_details')->html($tpl_data);
        \ISOPHP\js_core::$console->log('binding');
        $binder();
    }

    public function displaySC1Terminal($binder, $instance_id, $rqd)
    {
        \ISOPHP\js_core::$console->log('display sc1 terminal');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $tplfunc = \Core\View::$template;
//        \ISOPHP\js_core::$console->log('pl func', $tplfunc);
        $tpl_data = $tplfunc();


        $msg = "Terminal for: $instance_id.";

        $data  = '<div class="col-sm-12 overlay-col">' ;
        $data  = '  <link rel="stylesheet" type="text/css" href="/app/SC1/Assets/css/code-terminal.css">' ;
        $data .= '  <script type="text/javascript" src="/app/SC1/Assets/js/console.js"></script>' ;
        $data .= '  <div class="col-sm-12 terminal-console" id="terminal-console">' ;

        \ISOPHP\js_core::$console->log('InstanceTerminal rqd history: ', $rqd['history']);
        foreach ($rqd['history'] as $one_history_line) {
            \ISOPHP\js_core::$console->log('InstanceTerminal $one_history_line: ', $one_history_line);
            if ($one_history_line['type'] == 'input') {
                \ISOPHP\js_core::$console->log('InstanceTerminal type: ', 'input');
                $data .= '<pre class="terminal_row terminal_input">$ '.$one_history_line['command']."</pre>" ;
            } else if ($one_history_line['type'] == 'output') {
                \ISOPHP\js_core::$console->log('InstanceTerminal type: ', 'output');
                if (isset($one_history_line['stdout'])) {
                    $data .= '<pre class="terminal_row terminal_stdout">'.$one_history_line['stdout']."</pre>" ;
                } else if (isset($one_history_line['stderr'])) {
                    $data .= '<pre class="terminal_row terminal_stderr">'.$one_history_line['stderr']."</pre>" ;
                }
            }
        }

        $data .= '    <pre class="cursor">' ;
        $data .= '<input type="text" name="command_data" id="command_data" />' ;
        $data .= '<i></i></pre>' ;
        $data .= '  <input type="hidden" name="command_data_instance_id" id="command_data_instance_id" value="'.$rqd['instance_id'].'" />' ;

        $data .= '</div>' ;

        \Core\WindowMessage::showOverlay($msg, $data);

        $jQuery('#sc1_terminal')->html($tpl_data);
        \ISOPHP\js_core::$console->log('terminal binding');
        $binder();
    }

}