<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                
                    <div>
                        <h4>New SVPC Subnet</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>SVPC</label>
                            <div id="svpc_svpc_dropdown">
                                <select class="form-control" name="svpc_name" id="svpc_name">
                                    <option>Loading SVPCs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control"></input>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>IPv4 CIDR</label>
                            <input type="text"  name="cidr" id="cidr" class="form-control"></input>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-subnet-create" class="btn btn-success attempt-subnet-create">Create</span>
                        <span id="go-svpc-landing" class="btn btn-warning go-svpc-landing">SVPC Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    return $html;

};
