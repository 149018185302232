<?php

Namespace Model\SVPC;

use ISOPHP\core;

class SiteToSiteVPNPageModel extends \Model\Base
{

    public function showSVPCSiteToSiteVPNCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'SiteToSiteVPNCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC SiteToSiteVPN Create 2', $vars);
        };
    }

    public function showSVPCSiteToSiteVPNList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/sitetositevpn/all';
            $afterwards = $this->applySVPCSiteToSiteVPNListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCSiteToSiteVPNListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC SiteToSiteVPN List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC SiteToSiteVPN List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'SiteToSiteVPNList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC SiteToSiteVPN List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC SiteToSiteVPN List failed');
            }
        };
        return $func;
    }



    /**
     * Save sitetositevpn
     */
    public function saveSiteToSiteVPNAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save sitetositevpn to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'sitetositevpn-create';
        $vars['api_uri'] = '/api/svpc/sitetositevpn/create';
        $vars['svpc_name'] = $jQuery('#svpc_name')->val();
        $vars['cidr_value'] = $jQuery('#cidr_value')->val();
        \ISOPHP\js_core::$console->log('save sitetositevpn vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSiteToSiteVPNResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSiteToSiteVPNResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc sitetositevpn result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created SiteToSiteVPN '.$request_data['sitetositevpn']['svpc_sitetositevpn_name'].' for SVPC '.$request_data['sitetositevpn']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc sitetositevpn saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating SiteToSiteVPN '.$request_data['sitetositevpn']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc sitetositevpn save failed');
            }
        };
        return $func;
    }


    /**
     * Delete sitetositevpn
     */
    public function deleteSiteToSiteVPNAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete sitetositevpn to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'sitetositevpn-delete';
        $vars['api_uri'] = '/api/svpc/sitetositevpn/delete';
        $vars['svpc_sitetositevpn_id'] = $jQuery('#svpc_sitetositevpn_id')->val();
        \ISOPHP\js_core::$console->log('delete sitetositevpn vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSiteToSiteVPNResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSiteToSiteVPNResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc sitetositevpn result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted SiteToSiteVPN '.$request_data['sitetositevpn']['svpc_sitetositevpn_name'].' for SVPC '.$request_data['sitetositevpn']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc sitetositevpn deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'sitetositevpn-list', array(), '/svpc/sitetositevpn/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting SiteToSiteVPN '.$request_data['sitetositevpn']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc sitetositevpn delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons Site to Site VPN');
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });

                $go_svpc_sitetositevpn_list = $jQuery('.go-svpc-sitetositevpn-list');
                $go_svpc_sitetositevpn_list->off() ;
                $go_svpc_sitetositevpn_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'sitetositevpn-list', array(), '/svpc/sitetositevpn/list');
                });

                $go_svpc_sitetositevpn_create = $jQuery('.go-svpc-sitetositevpn-create');
                $go_svpc_sitetositevpn_create->off() ;
                $go_svpc_sitetositevpn_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'sitetositevpn-create', array(), '/svpc/sitetositevpn/create');
                });

                $save_sitetositevpn = $jQuery('.attempt-sitetositevpn-create');
                $save_sitetositevpn->off() ;
                $save_sitetositevpn->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'sitetositevpn-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $sitetositevpn_delete = $jQuery('.attempt-sitetositevpn-delete');
                $sitetositevpn_delete->off() ;
                $sitetositevpn_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $sitetositevpn_name = $jqThis->attr('data-svpc-sitetositevpn-name') ;
//                    $sitetositevpn_id = $jqThis->attr('data-svpc-sitetositevpn-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked sitetositevpn delete button');
//                    $msg = "You are about to delete the Network ACL for $sitetositevpn_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $sitetositevpn_id) {
//                        $navigate->route('SVPC', 'sitetositevpn-delete-attempt', array('sitetositevpn_id' => $sitetositevpn_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

            }
        };
    }


}