<?php

Namespace View ;

class LoginView extends \Core\View {

    public function display($data) {
        $tplfunc = \Core\View::$template ;
        $tpl_data = \Core\View::parse_view_template($tplfunc) ;
        \Core\View::execute_view_template('#template', $tpl_data) ;
    }

}