<?php

Namespace Controller ;

class LogoutController extends \Controller\Base {

    public function execute($pageVars) {
        $page_model = new \Model\Logout\PageModel() ;
        $page = $page_model->getPage($pageVars) ;
        \ISOPHP\js_core::$console->log('Logout control pagevars', $pageVars) ;
        \ISOPHP\js_core::$window->document->title = $page['title'] ;
        $res = new \Controller\Result() ;
        if ($pageVars['route']['action'] == 'now') {
            \ISOPHP\js_core::$console->log('about to perform logout', $pageVars) ;
            $data = $page_model->performLogout() ;
            \ISOPHP\js_core::$console->log('Logout Controller Attempt Result', $data) ;
            $res->type = null ; # no type means no further action
            \ISOPHP\js_core::$console->log('Logout Con attempt action', $res) ;
            return $res ;
        }
    }

}