<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-policy-edit" class="btn btn-success attempt-policy-edit">Edit</span>
                        <span id="go-sam-policy-list" class="btn btn-info go-sam-policy-list">SAM Policy List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                        
                    <div>
                        <h4>Edit Existing Policy</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Policy Name</label>
                            <input type="text" name="policy_name" class="form-control" id="policy_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Permission Entity</label>
                            <div class="permission_entity_wrap form-control" type="text" name="permission_entity_wrap" id="permission_entity_wrap">
                                <select name="permission_entity_select" id="permission_entity_select" class="form-control select2">
                                    
                                </select> 
                            </div>                            
                            <div class="col-sm-12 col-12 permissions_available_wrap">
                                <div class="col-sm-12 col-12 form-group">
                                    <label class="permissions_available_label">Permissions Enabled</label>
                                </div>
                                <div class="col-sm-12 col-12 form-group">                    
                                    <div class="col-sm-1 col-1">
                                        &nbsp;
                                    </div>                   
                                    <div class="col-sm-2 col-2">
                                        Create: <input type="checkbox" name="permission_available_create" class="form-control" id="permission_available_create" />
                                    </div>                   
                                    <div class="col-sm-2 col-2">
                                        Write: <input type="checkbox" name="permission_available_write" class="form-control" id="permission_available_write" />
                                    </div>                    
                                    <div class="col-sm-2 col-2">
                                        List: <input type="checkbox" name="permission_available_list" class="form-control" id="permission_available_list" />
                                    </div>                    
                                    <div class="col-sm-2 col-2">
                                        Read: <input type="checkbox" name="permission_available_read" class="form-control" id="permission_available_read" />
                                    </div>                    
                                    <div class="col-sm-2 col-2">
                                        Delete: <input type="checkbox" name="permission_available_delete" class="form-control" id="permission_available_delete" />
                                    </div>                    
                                    <div class="col-sm-1 col-1">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <span id="add-sam-policy-permission" class="btn btn-success add-sam-policy-permission">
                            Add Permission
                        </span>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Current Permissions</label>
                            <div name="current_permissions" id="current_permissions" class="form-control col-sm-12 col-12"></div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Comment</label>
                            <textarea name="comment" id="comment" class="form-control" rows="10" cols="30"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-policy-edit" class="btn btn-success attempt-policy-edit">Edit</span>
                        <span id="go-sam-policy-list" class="btn btn-info go-sam-policy-list">SAM Policy List</span>
                        <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
                    </div>
                    
                </div>
                
            </div>
             
        </div>';

    return $html;

};