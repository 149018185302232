<?php

\Core\View::$template = function() {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars in application configure page account dropdown: ', $pv);
    $rqd = $pv['request_data'];

    $html = $html .
        '    <select name="'.$pv['type'].'_account" id="'.$pv['type'].'_account"> ' ;
    foreach ($rqd['accounts'] as $one_account) {
        $html = $html .
            '       <option value="'.$one_account['dmd_account_id'].'"
                            id="account_'.$one_account['dmd_account_id'].'"
                            data-dmd_account_id="'.$one_account['dmd_account_id'].'" >
                        '.$one_account['dmd_account_id'].': '.$one_account['title'].'
                    </option>' ;
    }

    $html = $html .
        '    </select>' ;

    return $html ;

} ;