<?php

Namespace Model;

class Base {

    public $params ;

    protected function stripNewLines($inputLine) {
        $inputLine = str_replace("\n", "", $inputLine);
        $inputLine = str_replace("\r", "", $inputLine);
        return $inputLine;
    }

    public function ensureTrailingSlash($str) {
        if (substr($str, -1, 1) != DIRECTORY_SEPARATOR) {
            $str = $str . DIRECTORY_SEPARATOR ;
        }
        return $str ;
    }

    public function performRequest($request_vars, $afterwards) {

        $jQuery = \ISOPHP\js_core::$jQuery ;
        $new_data_string = '' ;

        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        \ISOPHP\js_core::$console->log('perform request $user_logged: ') ;
        \ISOPHP\js_core::$console->log($user_logged) ;

        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        # todo this should be an array length not a strlen
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $newest_token = [];
            $newest_token['time'] = 0 ;
            $newest_token['data'] = 'XXXX';
            foreach ($cookies['user_tokens'] as $one_token) {
                \ISOPHP\js_core::$console->log('one token time', $one_token['time']) ;
                \ISOPHP\js_core::$console->log('newest token time', $newest_token['time']) ;
                if ($one_token['time'] > $newest_token['time']) {
                    \ISOPHP\js_core::$console->log('newest token overwritten') ;
                    $newest_token = $one_token ;
                }
            }
            $request_vars['token'] = $newest_token['data'] ;
        }
        \ISOPHP\js_core::$console->log('token added to request') ;
        \ISOPHP\js_core::$console->log($request_vars) ;

        foreach ($request_vars as $one_var_key => $one_var_val) {
            $new_data_string = $new_data_string.$one_var_key."=".$one_var_val.'&' ;
        }
        \ISOPHP\js_core::$console->log('new data string') ;
        \ISOPHP\js_core::$console->log($new_data_string) ;

        $length = \ISOPHP\core::$php->strlen($new_data_string) ;
        $new_data_string = \ISOPHP\core::$php->substr($new_data_string, 0, $length-1) ;
        $new_data_string = \ISOPHP\js_core::$window->encodeURI($new_data_string) ;
        
        \ISOPHP\js_core::$console->log('new data string 2') ;
        \ISOPHP\js_core::$console->log($new_data_string) ;

        $config = new \Model\Configuration() ;
        $server_url = $config->variable('ISOPHP_API_SERVER_URL') ;

        \ISOPHP\js_core::$console->log('api server url: ', $server_url, 'post to:',$request_vars['api_uri']) ;

        $data = array(
            'type' => 'POST',
            'url' => $server_url . '' . $request_vars['api_uri'],
            'dataType'=> "json",
            'data' => $new_data_string
        ) ;

        $ajax_object = $jQuery->ajax($data);
        \ISOPHP\js_core::$console->log('api server url: ', $server_url, 'post to:',$request_vars['api_uri']) ;

        $status = $ajax_object->always(
            function ($data) use ($jQuery, $afterwards) {
                \ISOPHP\js_core::$console->log('returned request from server api with: ') ;
                \ISOPHP\js_core::$console->log($data) ;
                \ISOPHP\js_core::$console->log(is_object($data)) ;
                \ISOPHP\js_core::$console->log(is_array($data)) ;
                if (is_array($data) === false) {
                    if (is_object($data) === true) {
                        if (isset($data->status)) {
                            \ISOPHP\js_core::$console->log('status is', $data->status) ;
                            if ($data->status == 403) {
                                \ISOPHP\js_core::$console->log('403 Status, log out of Web App', $data->status) ;
                                $page_model = new \Model\Logout\PageModel() ;
                                $page_model->performLogout() ;
                            }
                        }
                    }
                }
                $this->returned_data = $data ;
                 \ISOPHP\js_core::$console->log('server api showing what afterwards is', $afterwards) ;
                if ($afterwards !== null) {
                    $afterwards($data) ;
                }
                 \ISOPHP\js_core::$console->log('server api afterwards done') ;
            }
        );
        return $status ;
    }

    public function performFileRequest($request_vars, $afterwards) {

        $jQuery = \ISOPHP\js_core::$jQuery ;
        $new_data_string = '' ;

        $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
        if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
            $request_vars['token'] = $cookies['user_tokens'][0]['data'] ;
            \ISOPHP\js_core::$console->log('token added to request vars') ;
        }

        $window = \ISOPHP\js_core::$window ;
        $req = new $window->XMLHttpRequest();
        $formData = new $window->FormData();

        foreach ($request_vars as $one_var_key => $one_var_val) {
            $formData->append($one_var_key, $one_var_val);
            \ISOPHP\js_core::$console->log('Appending '.$one_var_key.' => '.$one_var_val. ' to Form Data') ;
        }

        $config = new \Model\Configuration() ;
        $server_url = $config->variable('ISOPHP_API_SERVER_URL') ;

        $file_element = \ISOPHP\js_core::$window->document->getElementById("file") ;
        $file_parent_entry = $file_element->files ;
        \ISOPHP\js_core::$console->log('dump file element', $file_element) ;
        \ISOPHP\js_core::$console->log('dump file parent', $file_parent_entry) ;

        $latest_entry = $file_parent_entry->{0} ;
        \ISOPHP\js_core::$console->log('$latest_entry : ', $latest_entry) ;

        $formData->append("file", $latest_entry);

        \ISOPHP\js_core::$console->log('File append called') ;

        $data = array(
            'type' => 'POST',
            'url' => $server_url . '' . $request_vars['api_uri'],
            'dataType'=> "json",
            'data' => $formData,
            'contentType' => false,
            'processData' => false,
            'cache' => false,
        ) ;
        \ISOPHP\js_core::$console->log('Data array set') ;

        $ajax_object = $jQuery->ajax($data);
        \ISOPHP\js_core::$console->log('ajax object created') ;
        \ISOPHP\js_core::$console->log('api server url: ', $server_url, 'post to:',$request_vars['api_uri']) ;
        $status = $ajax_object->always(
            function ($data) use ($jQuery, $afterwards) {
                \ISOPHP\js_core::$console->log('returned request from server api with: ', $data) ;
                if (is_array($data) === false) {
                    if (is_object($data) === true) {
                        if (isset($data->status)) {
                            \ISOPHP\js_core::$console->log('status is', $data->status);
                            if ($data->status == 403) {
                                \ISOPHP\js_core::$console->log('403 Status, log out of Web App', $data->status);
                                $page_model = new \Model\Logout\PageModel();
                                $page_model->performLogout();
                            }
                        }
                    }
                }
                $this->returned_data = $data ;
                \ISOPHP\js_core::$console->log('server api showing what afterwards is', $afterwards) ;
                if ($afterwards !== null) {
                    $afterwards($data) ;
                }
                \ISOPHP\js_core::$console->log('server api afterwards done') ;
            }
        );
        \ISOPHP\js_core::$console->log('ajax object always complete') ;
        return $status ;

    }

    public function downloadURI($uri, $name = '')  {
        $window = \ISOPHP\js_core::$window ;
        $link = $window->document->createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        $link->setAttribute('download', $name);
        $link->href = $uri;
        $window->document->body->appendChild($link);
        $link->click();
        $link->remove();
    }

}
