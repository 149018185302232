<?php

Namespace Controller;

class SMPController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SMP controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SMP, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\SMP\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('Mini Proxy List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SMP.php';
            $res->type = 'view';
            $res->view_control = 'SMP';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSMPLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'record-list') {
                $res->post_template[] = $page_model->showSMPRecordList($pageVars);
            } else if ($pageVars['route']['action'] == 'record-create') {
                $sc1_instance_page_model = new \Model\SC1\InstancePageModel();
                $res->post_template[] = $page_model->showSMPRecordCreate($pageVars);
                $res->post_template[] = $sc1_instance_page_model->showSC1InstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'record-save-attempt') {
                $page_model->saveRecordAttemptToSMP($pageVars);
                $res->view = 'SMP.php';
                $res->control = 'SMP';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'record-delete-attempt') {
                $page_model->deleteRecordAttemptToSMP($pageVars);
                $res->view = 'SMP.php';
                $res->control = 'SMP';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSMPLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SMP Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}