<?php

Namespace Controller ;

class Base {

  public $content;
  protected $registeredModels = array();

  public function __construct() {
    $this->content = array();
  }

}