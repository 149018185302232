<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to Closure, your Serverless Code Execution Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Storage for Serverless Applications and Closures</h3>
                    <h3>Execution for Serverless Closures</h3>
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-closure-repository-list" class="go-closure-repository-list btn btn-lg btn-warning">Repositories</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-closure-repository-create" class="go-closure-repository-create btn btn-lg btn-warning">Create Repository</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-closure-function-list" class="go-closure-function-list btn btn-lg btn-warning">Functions</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-closure-function-create" class="go-closure-function-create btn btn-lg btn-warning">Create Function</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;