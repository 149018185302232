<?php

Namespace Core;

class BootStrap {

    private static $exitCode ;

    public static function setExitCode($exitCode){
        self::$exitCode = $exitCode ;
    }

    public static function getExitCode(){
        return (is_null(self::$exitCode)) ? 0 : self::$exitCode ;
    }

    public function main($argv_or_boot_params_null, $params) {
        // \ISOPHP\js_core::$console->log('bootstrap main', $argv_or_boot_params_null, $params) ;
        $this->ensure_session_exists() ;
        $routeObject = new \Core\Router();
        $route = $routeObject->run($argv_or_boot_params_null);
        $startPageVars = array("messages"=>array(), "route"=>$route, "params" => $params);
        // \ISOPHP\js_core::$console->log('current route is', $route, $startPageVars) ;
        $this->executeControl($route["control"], $startPageVars);
    }

    public function executeControl($controlToExecute, $pageVars) {
        \ISOPHP\js_core::$console->log('core control class loading') ;
        $control = new \Core\Control();
        \ISOPHP\js_core::$console->log('core control class loaded, executing module '.$controlToExecute, $control, "pvars are", $pageVars) ;
        $controlResult = $control->executeControl($controlToExecute, $pageVars);
        \ISOPHP\js_core::$console->log('control res 1', $controlResult) ;
        if ($controlResult->type == "view") {
            $viewClass = NAMESPACE_PREFIX.'View\\'.$controlResult->view_control.'View' ;
            \ISOPHP\js_core::$console->log('View Class Name Is:', $viewClass) ;
            $view =  new $viewClass() ;

            // flush the template of the view, i think it breaks susequent loads
            \Core\View::$template = null ;

            \ISOPHP\js_core::$console->log('BootStrap\executeControl $view:', $view) ;
            \ISOPHP\js_core::$console->log('BootStrap\executeControl $controlResult->view_control:', $controlResult->view_control) ;
            \ISOPHP\js_core::$console->log('BootStrap\executeControl $controlResult->view:', $controlResult->view) ;
            \ISOPHP\js_core::$console->log('BootStrap\executeControl $controlResult->page:', $controlResult->page) ;

            $view->executeView($controlResult->view_control, $controlResult->view, $controlResult->page);

            if (isset($controlResult->post_template)) {
                \ISOPHP\js_core::$console->log('post template is set', $controlResult->post_template) ;
                foreach ($controlResult->post_template as $post_template_method) {
                    $post_template_method() ;
                }
                $controlResult->post_template = null ;
            }
            \ISOPHP\js_core::$console->log('post template is not set', $controlResult->post_template) ;

        }
        else if ($controlResult->type == "control") {
            \ISOPHP\js_core::$console->log('control res 2', $controlResult) ;
            $this->executeControl( $controlResult->control, $controlResult->page );
        }
    }

    public function ensure_session_exists() {
        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
            \ISOPHP\js_core::$console->log('session ens') ;
            return true ;
        } else {
            if ( $this->is_session_started() === false ) {
                session_start();
            }
            return true ;
        }
    }

    public function is_session_started() {
        if ( php_sapi_name() !== 'cli' ) {
            if ( version_compare(phpversion(), '5.4.0', '>=') ) {
                return session_status() === PHP_SESSION_ACTIVE ? true : false;
            } else {
                return session_id() === '' ? false : true;
            }
        }
        return false;
    }

}