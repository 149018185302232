<?php

Namespace Controller;

class MindstormController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Mindstorm controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Mindstorm, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Mindstorm\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('Mindstorm List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'Mindstorm.php';
            $res->type = 'view';
            $res->view_control = 'Mindstorm';
            $res->post_template = array();

            $instances_page_model = new \Model\Mindstorm\InstancePageModel();
            $backup_page_model = new \Model\Mindstorm\BackupPageModel();
            $snapshot_page_model = new \Model\Mindstorm\SnapshotPageModel();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showMindstormLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-list') {
                $res->post_template[] = $instances_page_model->showMindstormInstanceList($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-create') {
                $res->post_template[] = $instances_page_model->showMindstormInstanceCreate($pageVars);
                $res->post_template[] = $instances_page_model->showMindstormInstanceSizeDropDown($pageVars);
                $res->post_template[] = $instances_page_model->showMindstormInstanceImageDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'instance-save-attempt') {
                $instances_page_model->saveInstanceAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'instance-delete-attempt') {
                $instances_page_model->deleteInstanceAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-list') {
                $res->post_template[] = $backup_page_model->showMindstormBackupList($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-create') {
                $res->post_template[] = $backup_page_model->showMindstormBackupCreate($pageVars);
                $res->post_template[] = $instances_page_model->showMindstormInstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'backup-save-attempt') {
                $backup_page_model->saveBackupAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'backup-delete-attempt') {
                $backup_page_model->deleteBackupAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-list') {
                $res->post_template[] = $snapshot_page_model->showMindstormSnapshotList($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-create') {
                $res->post_template[] = $snapshot_page_model->showMindstormSnapshotCreate($pageVars);
                $res->post_template[] = $instances_page_model->showMindstormInstanceListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'snapshot-save-attempt') {
                $snapshot_page_model->saveSnapshotAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'snapshot-delete-attempt') {
                $snapshot_page_model->deleteSnapshotAttemptToMindstorm($pageVars);
                $res->view = 'Mindstorm.php';
                $res->control = 'Mindstorm';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showMindstormLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $instances_page_model->bindButtons();
            $res->post_template[] = $backup_page_model->bindButtons();
            $res->post_template[] = $snapshot_page_model->bindButtons();

            \ISOPHP\js_core::$console->log('Mindstorm Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}