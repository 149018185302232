<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $pages_are_array = \ISOPHP\core::$php->is_array($rqd['pages']) ;
    if ($pages_are_array === true) {
        $pages_exist = true ;
    } else {
        $pages_exist = false ;
    }
    if ($pages_exist === true) {
        $page_count = \ISOPHP\core::$php->count($rqd['pages']) ;
    } else {
        $page_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-analytics-landing" class="btn btn-warning go-analytics-landing">Analytics Home</span>
            <span id="go-analytics-application-list" class="btn btn-success go-analytics-application-list">Application List</span>
            <span id="access_list_analytics_application_' . $rqd['application_id'] . '"   
                  class="hvr-grow dom go-analytics-application-access-list btn btn-info"
                  data-analytics-application-id="' . $rqd['application_id'] . '"
                  data-analytics-application-name="' . $rqd['application_id'] . '">
                  Reload
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    $html = $html .
       '<div class="col-sm-12 col-12">
            <h4>Application Execution List</h4>
         </div> ' ;

    if ($pages_exist === true && $page_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_analytics_application">
                        <div class="col-sm-2 col-2">
                            <h5 class="analytics_application_table_title">Page</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="analytics_application_table_title">Hits</h5>
                        </div>                     
                        <div class="col-sm-1 col-1">
                            <h5 class="analytics_application_table_title">Time</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="analytics_application_table_title">Visitors</h5>
                        </div>                     
                        <div class="col-sm-1 col-1">
                            <h5 class="analytics_application_table_title">Time</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="analytics_application_table_title">Actions</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="analytics_application_table_title">Load</h5>
                        </div>                     
                        <div class="col-sm-2 col-2">
                            <h5 class="analytics_application_table_title">Time</h5>
                        </div>
                    </div>
                 </div>';
        foreach ($rqd['pages'] as $one_analytics_page) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_page"><span class="analytics_id analytics_listing">' . $one_analytics_page['page'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="analytics_list_hits"><span class="analytics_start_time analytics_listing">' . $one_analytics_page['hits'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="analytics_list_time_spent"><span class="analytics_application_name analytics_list_duration">' . $one_analytics_page['total_time_spent'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="analytics_list_hits"><span class="analytics_start_time analytics_listing">' . $one_analytics_page['sum_daily_entry_uniq_visitors'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="analytics_list_time_spent"><span class="analytics_application_name analytics_list_duration">' . $one_analytics_page['total_time_spent'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_id"><span class="analytics_id analytics_listing">' . $one_analytics_page['entry_actions'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_start_time"><span class="analytics_start_time analytics_listing">' . $one_analytics_page['avg_page_load_time'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_duration"><span class="analytics_application_name analytics_list_duration">Details</span></p>
                    </div>
                 </div>';
        }
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-analytics-landing" class="btn btn-warning go-analytics-landing">Analytics Home</span>
            <span id="go-analytics-application-list" class="btn btn-success go-analytics-application-list">Application List</span>
            <span id="access_list_analytics_application_' . $rqd['application_id'] . '"   
                  class="hvr-grow dom go-analytics-application-access-list btn btn-info"
                  data-analytics-application-id="' . $rqd['application_id'] . '"
                  data-analytics-application-name="' . $rqd['application_id'] . '">
                  Reload
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>' ;

    return $html ;

}; 