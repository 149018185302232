<?php

Namespace Controller;

class PCRController extends \Controller\Base
{

    public function execute($pageVars) {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('PCR controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In PCR, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\PCR\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'PCR.php';
            $res->type = 'view';
            $res->view_control = 'PCR';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showPCRLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'registry-list') {
                $res->post_template[] = $page_model->showPCRRegistryList($pageVars);
            } else if ($pageVars['route']['action'] == 'registry-tag-list') {
                $res->post_template[] = $page_model->showPCRRegistryTagList($pageVars);
            } else if ($pageVars['route']['action'] == 'registry-create') {
                $res->post_template[] = $page_model->showPCRRegistryCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'image-list') {
                $res->post_template[] = $page_model->showPCRRegistryListDropDown($pageVars);
                $res->post_template[] = $page_model->bindRegistryListDropDownButtonsForImageList();
                $res->post_template[] = $page_model->showPCRImageList($pageVars);
            } else if ($pageVars['route']['action'] == 'image-create') {
                $res->post_template[] = $page_model->showPCRImageCreate($pageVars);
                $res->post_template[] = $page_model->showPCRRegistryListDropDown($pageVars);
//            $res->post_template[] = $page_model->bindRegistryListDropDownButtonsForImageCreate();
            } else if ($pageVars['route']['action'] == 'registry-save-attempt') {
                $page_model->saveRegistryAttemptToPCR($pageVars);
                $res->view = 'PCR.php';
                $res->control = 'PCR';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'image-save-attempt') {
                $page_model->saveImageAttemptToPCR($pageVars);
                $res->view = 'PCR.php';
                $res->control = 'PCR';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'registry-delete-attempt') {
                $page_model->deleteRegistryAttemptToPCR($pageVars);
                $res->view = 'PCR.php';
                $res->control = 'PCR';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'image-delete-attempt') {
                $page_model->deleteImageAttemptToPCR($pageVars);
                $res->view = 'PCR.php';
                $res->control = 'PCR';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showPCRLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('PCR Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}