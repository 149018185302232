<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to SCM, your Source Code Management Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Git based storage of your Source Code</h3>         
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-scm-repository-list" class="go-scm-repository-list btn btn-lg btn-warning">Repositories</span>
                    </div>
                    
                    <div class="col-sm-6">
                        <span id="go-scm-repository-create" class="go-scm-repository-create btn btn-lg btn-warning">Create Repository</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;