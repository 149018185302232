<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $tags_are_array = \ISOPHP\core::$php->is_array($rqd['tags']) ;
    if ($tags_are_array === true) {
        $tags_exist = true ;
    } else {
        $tags_exist = false ;
    }
    if ($tags_exist === true) {
        $tag_count = \ISOPHP\core::$php->count($rqd['tags']) ;
    } else {
        $tag_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcr-landing" class="btn btn-warning go-pcr-landing">PCR Home</span>
            <span id="go-pcr-registry-list" class="btn btn-warning go-pcr-registry-list">Registry List</span>
            <span id="go-registry-tag-list"
                  class="btn btn-info go-registry-tag-list"
                  data-pcr-registry-id="'.$rqd['registry'].'" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4><strong>Tag List: </strong>'.$rqd['registry'].'</h4>
        </div> ' ;

    if ($tags_exist === true && $tag_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_pcr_tag">
                        <div class="col-sm-3 col-3">
                            <h5 class="pcr_tag_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_tag_table_title">Size</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_tag_table_title">Time</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_tag_table_title">Layers</h5>
                        </div>
                        <div class="col-sm-3 col-3">
                            <h5 class="pcr_tag_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['tags'] as $one_pcr_tag) {
            $dotless_id = \ISOPHP\core::$php->str_replace('.', '_', $one_pcr_tag['id']) ;
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-3 col-3">
                        <p class="pcr_list_id"><span class="pcr_id pcr_listing">' . $one_pcr_tag['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_tag_name"><span class="pcr_tag_name pcr_listing">' . $one_pcr_tag['size'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_tag_data"><span class="pcr_tag_data pcr_listing">' . $one_pcr_tag['created'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_tag_data"><span class="pcr_tag_data pcr_listing">' . $one_pcr_tag['layers'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="info_pcr_tag_' . $dotless_id . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-tag-info btn btn-outline-primary"
                                   data-pcr-tag-id="' . $one_pcr_tag['id'] . '"
                                   data-pcr-tag-name="' . $one_pcr_tag['name'] . '"
                                   data-pcr-registry-id="' . $rqd['registry'] . '"
                                   data-pcr-registry-name="' . $rqd['registry'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_pcr_tag_' . $dotless_id . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-tag-delete btn btn-outline-danger"
                                   data-pcr-tag-id="' . $one_pcr_tag['id'] . '"
                                   data-pcr-tag-name="' . $one_pcr_tag['name'] . '"
                                   data-pcr-registry-id="' . $rqd['registry'] . '"
                                   data-pcr-registry-name="' . $rqd['registry'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>
                  ';


        }

        $html = $html .
            '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcr-landing" class="btn btn-warning go-pcr-landing">PCR Home</span>
            <span id="go-pcr-registry-list" class="btn btn-warning go-pcr-registry-list">Registry List</span>
            <span id="go-registry-tag-list"
                  class="btn btn-info go-registry-tag-list"
                  data-pcr-registry-id="'.$rqd['registry'].'" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
         ' ;

    } else {


    }

    return $html;

};