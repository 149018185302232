<?php

Namespace Model\Mindstorm;

use ISOPHP\core;

class BackupPageModel extends \Model\Base {

// $bind = \Model\Mindstorm\PageModel::bindButtons();
    public function showMindstormBackupCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MindstormView();
            $view->loadTemplate('Mindstorm', 'BackupCreate.php', $vars);
            $bind = \Model\Mindstorm\PageModel::bindButtons();
            $view->displayMindstorm($bind);
            \ISOPHP\js_core::$console->log('apply Mindstorm Backup Create 2', $vars);
        };
    }

    public function showMindstormBackupList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mindstorm List');
            $vars['control'] = 'Mindstorm';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mindstorm/backup/all';
            $afterwards = $this->applyMindstormBackupListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Mindstorm Model: ', $result);
            return $result;
        };
    }

    public function applyMindstormBackupListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mindstorm Backup List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mindstorm Backup List 1', $vars);
                $view = new \View\MindstormView();
                $view->loadTemplate('Mindstorm', 'BackupList.php', $vars);
                $bind = \Model\Mindstorm\PageModel::bindButtons();
                $view->displayMindstorm($bind);
                \ISOPHP\js_core::$console->log('apply Mindstorm Backup List 2', $vars);
                $page_model = new \Model\Mindstorm\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mindstorm Backup List failed');
            }
        };
        return $func;
    }

    /**
     * Save backup
     */
    public function saveBackupAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('save backup to mindstorm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'backup-create';
        $vars['api_uri'] = '/api/mindstorm/backup/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save backup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveBackupResultFromMindstorm($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveBackupResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save mindstorm backup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['backup']['mindstorm_backup_name'].' for Instance '.$request_data['backup']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm backup saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['backup']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm backup save failed');
            }
        };
        return $func;
    }
    /**
     * Delete backup
     */
    public function deleteBackupAttemptToMindstorm($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete backup to mindstorm');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Mindstorm';
        $vars['action'] = 'backup-delete';
        $vars['api_uri'] = '/api/mindstorm/backup/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['backup_id'] = $jQuery('#backup_name')->val();
        \ISOPHP\js_core::$console->log('delete backup vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteBackupResultFromMindstorm($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteBackupResultFromMindstorm($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete mindstorm backup result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['backup']['mindstorm_backup_name'].' for Instance '.$request_data['backup']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mindstorm backup deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['backup']['mindstorm_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mindstorm backup delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete backup
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_mindstorm_backup_list = $jQuery('.go-mindstorm-backup-list');
                $go_mindstorm_backup_list->off() ;
                $go_mindstorm_backup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'backup-list', array(), '/mindstorm/backup/list');
                });

                $go_mindstorm_backup_create = $jQuery('.go-mindstorm-backup-create');
                $go_mindstorm_backup_create->off() ;
                $go_mindstorm_backup_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'backup-create', array(), '/mindstorm/backup/create');
                });

                $save_backup = $jQuery('.attempt-backup-create');
                $save_backup->off() ;
                $save_backup->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Mindstorm', 'backup-save-attempt', array());
                });

                $backup_delete = $jQuery('.attempt-backup-delete');
                $backup_delete->off() ;
                $backup_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $backup_name = $jqThis->attr('data-mindstorm-backup-name') ;
                    $backup_id = $jqThis->attr('data-mindstorm-backup-id') ;
                    \ISOPHP\js_core::$console->log('Clicked backup delete button');
                    if ($backup_name !== '') {
                        $name_or_id = $backup_name ;
                    } else {
                        $name_or_id = $backup_id ;
                    }
                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $backup_id) {
                        $navigate->route('Mindstorm', 'backup-delete-attempt', array('backup_id' => $backup_id));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}