<?php

Namespace Model\SLB;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SLB List of Piranha Web Services';
        $page['heading'] = 'List of SLB';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SLB Mod', $page, $pageVars);
        return $page;
    }

    public function showSLBLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SLB Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SLBView();
            $view->loadTemplate('SLB', 'SLBLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySLB($bind);
            \ISOPHP\js_core::$console->log('apply SLB Domain Create 2', $vars);
        };
    }

    public function showSLBRecordCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SLB Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SLBView();
            $view->loadTemplate('SLB', 'RecordCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySLB($bind);
            \ISOPHP\js_core::$console->log('apply SLB Record Create 2', $vars);
        };
    }

    public function showSLBRecordList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SLB List');
            $vars['control'] = 'SLB';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/slb/record/all';
            $afterwards = $this->applySLBRecordListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SLB Model: ', $result);
            return $result;
        };
    }

    public function applySLBRecordListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SLB Record List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SLB Record List 1', $vars);
                $view = new \View\SLBView();
                $view->loadTemplate('SLB', 'RecordList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySLB($bind);
                \ISOPHP\js_core::$console->log('apply SLB Record List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SLB Record List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save record
     */
    public function saveRecordAttemptToSLB($pageVars)
    {
        \ISOPHP\js_core::$console->log('save record to slb');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SLB';
        $vars['action'] = 'record-create';
        $vars['api_uri'] = '/api/slb/record/create';
        $vars['instance_id'] = $jQuery('#vm_id')->val();
        $vars['friendly_name'] = $jQuery('#friendly_name')->val();
        $vars['target_http_port'] = $jQuery('#target_http_port')->val();
        $vars['target_https_port'] = $jQuery('#target_https_port')->val();
        $vars['alternate_hostnames'] = $jQuery('#alternate_hostnames')->val();
        \ISOPHP\js_core::$console->log('save record vars');
        \ISOPHP\js_core::$console->log($vars);
        \ISOPHP\js_core::$console->log('is it checked');
        \ISOPHP\js_core::$console->log($jQuery('#include_http')->is(':checked'));
        $include_http = ($jQuery('#include_http')->is(':checked') == 1) ? "true" : "false" ;
        $vars['include_http'] = $include_http ;
        \ISOPHP\js_core::$console->log($jQuery('#include_https')->is(':checked'));
        $include_https = ($jQuery('#include_https')->is(':checked') == 1) ? "true" : "false" ;
        $vars['include_https'] = $include_https ;
        \ISOPHP\js_core::$console->log($jQuery('#use_proxy_protocol')->is(':checked'));
        $use_proxy_protocol = ($jQuery('#use_proxy_protocol')->is(':checked') == 1) ? "true" : "false" ;
        $vars['use_proxy_protocol'] = $use_proxy_protocol ;
        $afterwards = $this->saveRecordResultFromSLB($pageVars);
        $msg = 'Attempting to create Record '. $vars['friendly_name'];
        \Core\WindowMessage::showMessage($msg, 'good');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRecordResultFromSLB($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save slb record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Record '.$request_data['record']['slb_record_name'].' for Domain '.$request_data['record']['slb_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('slb record saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SLB', 'record-list', array(), '/slb/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Record. '.$request_data['message'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('slb record save failed');
            }
        };
        return $func;
    }
    /**
     * Delete record
     */
    public function deleteRecordAttemptToSLB($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete record to slb');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SLB';
        $vars['action'] = 'record-delete';
        $vars['api_uri'] = '/api/slb/record/delete';
        $vars['id'] = $pageVars['params']['record_id'];
        \ISOPHP\js_core::$console->log('delete record vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deleteRecordResultFromSLB($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRecordResultFromSLB($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete slb record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Record '.$request_data['record_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('slb record deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SLB', 'record-list', array(), '/slb/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Record '.$request_data['record_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('slb record delete failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_slb_landing = $jQuery('.go-slb-landing');
                $go_slb_landing->off() ;
                $go_slb_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SLB', 'landing', array(), '/slb');
                });

                $go_slb_record_list = $jQuery('.go-slb-record-list');
                $go_slb_record_list->off() ;
                $go_slb_record_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SLB', 'record-list', array(), '/slb/record/list');
                });

                $go_slb_record_create = $jQuery('.go-slb-record-create');
                $go_slb_record_create->off() ;
                $go_slb_record_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SLB', 'record-create', array(), '/slb/record/create');
                });

                $save_record = $jQuery('.slb-attempt-record-create');
                $save_record->off() ;
                $save_record->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SLB', 'record-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                });

                $record_delete = $jQuery('.slb-attempt-record-delete');
                $record_delete->off() ;
                $record_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $record_name = $jqThis->attr('data-slb-record-name') ;
                    $record_id = $jqThis->attr('data-slb-record-id') ;
                    \ISOPHP\js_core::$console->log('Clicked record delete button');
                    $msg = "You are about to delete the Record for $record_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $record_id) {
                        $navigate->route('SLB', 'record-delete-attempt', array(
                            'record_id' => $record_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

            }
        };
    }


}