<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="smp-attempt-record-create" class="btn btn-success smp-attempt-record-create">Create</span>
                        <span id="go-smp-record-list" class="btn btn-warning go-smp-record-list">Proxy Record List</span>
                        <span id="go-smp-landing" class="btn btn-warning go-smp-landing">SMP Home</span>
                    </div>
            
                    <div>
                        <h4>New Mini Proxy Record</h4>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Friendly Name</label>
                            <input type="text" name="friendly_name" class="form-control" id="friendly_name" value="" />
                        </div>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Instance</label>
                            <div id="sc1_instance_dropdown">
                                Loading Instances
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Instance Target Port</label>
                            <input type="text" id="target_proxy_port" name="target_proxy_port" class="form-control" value="22" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="smp-attempt-record-create" class="btn btn-success smp-attempt-record-create">Create</span>
                        <span id="go-smp-record-list" class="btn btn-warning go-smp-record-list">Proxy Record List</span>
                        <span id="go-smp-landing" class="btn btn-warning go-smp-landing">SMP Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';


    return $html;

};