<?php

Namespace Model\Database;

use ISOPHP\core;

class ImagePageModel extends \Model\Base {
    
    public function showDatabaseImageList($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Image List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/image/all';
            $afterwards = $this->applyDatabaseImageListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseImageListResult($pageVars) {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Image List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'ImageList.php', $vars);
                $bind = self::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database Image List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Image List failed');
            }
        };
        return $func;
    }


    public function showDatabaseImageListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Image Drop Down List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/image/all';
            $afterwards = $this->applyDatabaseImageListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabaseImageListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database Image List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'ImageListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayDatabaseImageDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Database Image List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database Image List failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_database_image_list = $jQuery('.go-database-image-list');
                $go_database_image_list->off() ;
                $go_database_image_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Database', 'image-list', array(), '/database/image/list');
                });
            }
        };
    }


}