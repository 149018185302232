<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $repositories_are_array = \ISOPHP\core::$php->is_array($rqd['repositories']) ;
    if ($repositories_are_array === true) {
        $repositories_exist = true ;
    } else {
        $repositories_exist = false ;
    }
    if ($repositories_exist === true) {
        $repository_count = \ISOPHP\core::$php->count($rqd['repositories']) ;
    } else {
        $repository_count = false ;
    }
    if ($repositories_exist === true && $repository_count !== false) {

        $html = $html . '<p>';
        $html = $html . '  Choose your repository:';
        $html = $html . '</p>';

        $html = $html . '<select class="form-control" name="repository_name" id="repository_name">';
        foreach ($rqd['repositories'] as $one_closure_repository) {
            $html = $html . '<option value="' . $one_closure_repository['closure_repository_name'] . '">' . $one_closure_repository['closure_repository_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available repositories</option>
             </select>' ;

    }
    return $html;

};