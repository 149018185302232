<?php

Namespace Model\SVPC;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SVPC List of Piranha Web Services';
        $page['heading'] = 'List of SVPC';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SVPC Mod', $page, $pageVars);
        return $page;
    }

    public function showSVPCLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'SVPCLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC Landing 2', $vars);
        };
    }

    public function showSVPCVPCCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'VPCCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC VPC Create 2', $vars);
        };
    }

    public function showSVPCVPCList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/vpc/all';
            $afterwards = $this->applySVPCVPCListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCVPCListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC VPC List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC VPC List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'VPCList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC VPC List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC VPC List failed');
            }
        };
        return $func;
    }

    public function showSVPCVPCListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing VPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/vpc/all';
            $afterwards = $this->applySVPCVPCListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCVPCListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC VPC List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC VPC List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'VPCListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPCVPCDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SVPC VPC List 2', $vars);
                
                
                $showDropdown = self::showSVPCSubnetListDropDown($pageVars) ;
                $showDropdown() ;
                    
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC VPC List failed');
            }
        };
        return $func;
    }
    
    public function showSVPCSubnetListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing VPC Subnet List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/subnet/all';
            $vars['vpc_id'] = $jQuery('#svpc_name')->val();;
            $afterwards = $this->applySVPCSubnetListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCSubnetListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC Subnet List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC Subnet List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'SubnetListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPCSubnetDropDown($bind);
                \ISOPHP\js_core::$console->log('apply SVPC Subnet List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC Subnet List failed');
            }
        };
        return $func;
    }
    

    public function showSVPCVPCListMultiSelect($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing VPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/vpc/all';
            $afterwards = $this->applySVPCVPCListMultiSelectResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCVPCListMultiSelectResult($pageVars) {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC VPC MultiSelect result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC VPC MultiSelect 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'VPCListMultiSelect.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPCVPCMultiSelect($bind);
                \ISOPHP\js_core::$console->log('apply SVPC VPC MultiSelect 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC VPC MultiSelect failed');
            }
        };
        return $func;
    }

    public function showSVPCSubnetCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'SubnetCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC Subnet Create 2', $vars);
        };
    }

    public function showSVPCSubnetList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/subnet/all';
            $afterwards = $this->applySVPCSubnetListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCSubnetListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC Subnet List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC Subnet List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'SubnetList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC Subnet List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC Subnet List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);
//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save svpc
     */
    public function saveSVPCAttemptToSVPC($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save svpc to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'svpc-create';
        $vars['api_uri'] = '/api/svpc/vpc/create';
        $vars['name'] = $jQuery('#name')->val();
        $vars['cidr'] = $jQuery('#cidr')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $afterwards = $this->saveSVPCResultFromSVPC($pageVars);
        $msg = 'Creating VPC '.$vars['name'];
        \Core\WindowMessage::showMessage($msg, 'warning');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSVPCResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc vpc result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created VPC '.$request_data['svpc']['name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc vpc saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating VPC '.$request_data['svpc']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc vpc save failed');
            }
        };
        return $func;
    }

    /**
     * Save subnet
     */
    public function saveSubnetAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save subnet to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'subnet-create';
        $vars['api_uri'] = '/api/svpc/subnet/create';
        $vars['svpc_name'] = $jQuery('#svpc_name')->val();
        $vars['cidr_value'] = $jQuery('#cidr_value')->val();
        \ISOPHP\js_core::$console->log('save subnet vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSubnetResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveSubnetResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc subnet result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Subnet '.$request_data['subnet']['svpc_subnet_name'].' for SVPC '.$request_data['subnet']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc subnet saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Subnet '.$request_data['subnet']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc subnet save failed');
            }
        };
        return $func;
    }

    /**
     * Delete svpc
     */
    public function deleteSVPCAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete svpc to svpc');
        \ISOPHP\js_core::$console->log($pageVars);
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'svpc-delete';
        $vars['api_uri'] = '/api/svpc/vpc/delete';
        $vars['id'] = $pageVars['params']['svpc_id'];
        \ISOPHP\js_core::$console->log('delete svpc vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deleteSVPCResultFromSVPC($pageVars);
        $msg = 'Deleting VPC id '.$vars['id'];
        \Core\WindowMessage::showMessage($msg, 'warning');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSVPCResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc vpc result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted SVPC '.$request_data['svpc']['svpc_vpc_name'].' for SVPC '.$request_data['svpc']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc vpc deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting SVPC '.$request_data['svpc']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc vpc delete failed');
            }
            $show_svpc_list = self::showSVPCVPCList($pageVars) ;
            $show_svpc_list() ;
        };
        return $func;
    }
    /**
     * Delete subnet
     */
    public function deleteSubnetAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete subnet to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'subnet-delete';
        $vars['api_uri'] = '/api/svpc/subnet/delete';
        $vars['svpc_subnet_id'] = $jQuery('#svpc_subnet_id')->val();
        \ISOPHP\js_core::$console->log('delete subnet vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveSubnetResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteSubnetResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc subnet result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Subnet '.$request_data['subnet']['svpc_subnet_name'].' for SVPC '.$request_data['subnet']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc subnet deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'subnet-list', array(), '/svpc/subnet/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Subnet '.$request_data['subnet']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc subnet delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons');

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                \ISOPHP\js_core::$console->log('About to Bind SVPC Landing Button');
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                \ISOPHP\js_core::$console->log('$go_svpc_landing 1: ', $go_svpc_landing);
                $go_svpc_landing->off() ;
                \ISOPHP\js_core::$console->log('$go_svpc_landing 2: ', $go_svpc_landing);
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    \ISOPHP\js_core::$console->log('Go SVPC Landing clicked');
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });

                $go_sc1_securitygroup_list = $jQuery('.go-sc1-securitygroup-list');
                $go_sc1_securitygroup_list->off() ;
                $go_sc1_securitygroup_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'securitygroup-list', array(), '/sc1/securitygroup/list');
                });

                $go_svpc_vpc_list = $jQuery('.go-svpc-vpc-list');
                $go_svpc_vpc_list->off() ;
                $go_svpc_vpc_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
                });

                $go_svpc_vpc_create = $jQuery('.go-svpc-vpc-create');
                $go_svpc_vpc_create->off() ;
                $go_svpc_vpc_create->on('click', function ($jqThis) use ($navigate) {
//                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'vpc-create', array(), '/svpc/vpc/create');
                });

                $save_svpc = $jQuery('.attempt-svpc-vpc-create');
                $save_svpc->off() ;
                $save_svpc->on('click', function ($jqThis) use ($navigate) {
                    \ISOPHP\js_core::$console->log('Clicked attempt vpc create');
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'vpc-save-attempt', array());
                });

                $go_svpc_subnet_list = $jQuery('.go-svpc-subnet-list');
                $go_svpc_subnet_list->off() ;
                $go_svpc_subnet_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'subnet-list', array(), '/svpc/subnet/list');
                });

                $go_svpc_subnet_create = $jQuery('.go-svpc-subnet-create');
                $go_svpc_subnet_create->off() ;
                $go_svpc_subnet_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'subnet-create', array(), '/svpc/subnet/create');
                });

                $save_subnet = $jQuery('.attempt-subnet-create');
                $save_subnet->off() ;
                $save_subnet->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'subnet-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $subnet_delete = $jQuery('.attempt-subnet-delete');
                $subnet_delete->off() ;
                $subnet_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $subnet_name = $jqThis->attr('data-svpc-subnet-name') ;
//                    $subnet_id = $jqThis->attr('data-svpc-subnet-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked subnet delete button');
//                    $msg = "You are about to delete the Subnet for $subnet_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $subnet_id) {
//                        $navigate->route('SVPC', 'subnet-delete-attempt', array('subnet_id' => $subnet_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $svpc_delete = $jQuery('.attempt-svpc-delete');
                $svpc_delete->off() ;
                $svpc_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $svpc_name = $jqThis->attr('data-svpc-vpc-name') ;
                    $svpc_id = $jqThis->attr('data-svpc-vpc-id') ;
                    \ISOPHP\js_core::$console->log('Clicked svpc delete button');
                    if ($svpc_name !== '') {
                        $name_or_id = $svpc_name ;
                    } else {
                        $name_or_id = $svpc_id ;
                    }
                    $msg = "You are about to delete the SVPC $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $svpc_id, $svpc_name) {
                        $navigate->route('SVPC', 'vpc-delete-attempt', array(
                            'svpc_id' => $svpc_id
                        ) );
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }


}