<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-volume-create" class="btn btn-success attempt-volume-create">Create</span>
                        <span id="go-sc1-volume-list" class="btn btn-warning go-sc1-volume-list">Volume List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                                    
                    <div>
                        <h4>New Volume</h4>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Instance</label>
                            <div id="sc1_instance_dropdown">
                                <select class="form-control" name="instance_id" id="instance_id">
                                    <option>Loading Instances</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="volume_name" class="form-control" id="volume_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Size</label>
                            <textarea name="volume_value" id="volume_value" class="form-control"></textarea>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-volume-create" class="btn btn-success attempt-volume-create">Create</span>
                        <span id="go-sc1-volume-list" class="btn btn-warning go-sc1-volume-list">Volume List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating SC1 Volumes</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_volume_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_volume_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_volume_ptc_aws" class="instruction_set instruction_set_create_volume_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
             </div>';

    return $html;

};