<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $applications_are_array = \ISOPHP\core::$php->is_array($rqd['applications']) ;
    if ($applications_are_array === true) {
        $applications_exist = true ;
    } else {
        $applications_exist = false ;
    }
    if ($applications_exist === true) {
        $application_count = \ISOPHP\core::$php->count($rqd['applications']) ;
    } else {
        $application_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-analytics-application-create" class="btn btn-success go-analytics-application-create">New Application</span>
            <span id="go-analytics-landing" class="btn btn-info go-analytics-landing">Analytics Home</span>
            <span id="go-analytics-application-list" class="btn btn-info go-analytics-application-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Application List</h4>
        </div> ' ;

    if ($applications_exist === true && $application_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_analytics_application">
                    <div class="col-sm-1 col-1">
                        <h5 class="analytics_application_id_title">ID</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="analytics_application_id_title">Name</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="analytics_application_id_title">Description</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="analytics_application_id_title">Site URL</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="analytics_application_id_title">Tracking URL</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="analytics_application_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['applications'] as $one_analytics_application) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_analytics_application">
                    <div class="col-sm-1 col-1">
                        <p class="analytics_list_id"><span class="analytics_id analytics_listing">' . $one_analytics_application['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_name"><span class="analytics_name analytics_listing">' . $one_analytics_application['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_comment analytics_listing">' . $one_analytics_application['description'] . '</p>
                    </div>    
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_comment analytics_listing">' . $one_analytics_application['application_url'] . '</p>
                    </div>         
                    <div class="col-sm-2 col-2">
                        <p class="analytics_list_comment analytics_listing">' . $one_analytics_application['tracking_url'] . '</p>
                    </div>     
                    <div class="col-sm-3 col-3">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="access_list_analytics_application_' . $one_analytics_application['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-analytics-application-access-list btn btn-outline-primary"
                                   data-analytics-application-id="' . $one_analytics_application['id'] . '"
                                   data-analytics-application-name="' . $one_analytics_application['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="info_analytics_application_' . $one_analytics_application['id'] . '"
                                   class="fa fa-1x fa-info hvr-grow dom attempt-application-info btn btn-outline-primary"
                                   data-analytics-application-id="' . $one_analytics_application['id'] . '"
                                   data-analytics-application-name="' . $one_analytics_application['name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_analytics_application_' . $one_analytics_application['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-application-delete btn btn-outline-danger"
                                   data-analytics-application-id="' . $one_analytics_application['id'] . '"
                                   data-analytics-application-name="' . $one_analytics_application['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no registered Applications</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-analytics-application-create" class="btn btn-success go-analytics-application-create">New Application</span>
                <span id="go-analytics-landing" class="btn btn-info go-analytics-landing">Analytics Home</span>
                <span id="go-analytics-application-list" class="btn btn-info go-analytics-application-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};