<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $securitygroups_are_array = \ISOPHP\core::$php->is_array($rqd['securitygroups']) ;
    if ($securitygroups_are_array === true) {
        $securitygroups_exist = true ;
    } else {
        $securitygroups_exist = false ;
    }
    if ($securitygroups_exist === true) {
        $securitygroup_count = \ISOPHP\core::$php->count($rqd['securitygroups']) ;
    } else {
        $securitygroup_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-securitygroup-create" class="btn btn-success go-sc1-securitygroup-create">New Security Group</span>
            <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-securitygroup-list" class="btn-refresh btn btn-info go-sc1-securitygroup-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Security Group List</h4>
         </div> ' ;

    if ($securitygroups_exist === true && $securitygroup_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-securitygroup-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_securitygroup item_list_sc1_securitygroups" id="item_list_sc1_securitygroups">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">VPC</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">Inbound</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">Outbound</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_securitygroup_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>
                 <div class="col-sm-12 col-12 sahara_table page_item_list">';
        foreach ($rqd['securitygroups'] as $one_sc1_securitygroup) {
            $html = $html .
                   '
                     <div class="col-sm-12 col-12">
                     
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_id"><span class="sc1_id sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->substr($one_sc1_securitygroup['id'], 0, 10) ;

            $html = $html .
                    '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_instance_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_securitygroup['vpc_id'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_securitygroup_name"><span class="sc1_securitygroup_name sc1_listing">' . $one_sc1_securitygroup['name'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_securitygroup_data">
                                <span class="sc1_securitygroup_data sc1_listing">' ;

                $html = $html. $one_sc1_securitygroup['inbound'] ;
                $html = $html .

                        '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <p class="sc1_list_securitygroup_type"><span class="sc1_type sc1_listing">' . $one_sc1_securitygroup['outbound'] . '</span></p>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="col-sm-12 col-12">.
                                <div class="col-sm-6 col-6">
                                    <i id="edit_sc1_securitygroup_' . $one_sc1_securitygroup['id'] . '"
                                       class="fa fa-1x fa-edit hvr-grow dom attempt-securitygroup-edit btn btn-outline-primary"
                                       data-sc1-securitygroup-id="' . $one_sc1_securitygroup['id'] . '"
                                       data-sc1-securitygroup-name="' . $one_sc1_securitygroup['name'] . '"></i>
                                </div>
                                <div class="col-sm-6 col-6">
                                    <i id="delete_sc1_securitygroup_' . $one_sc1_securitygroup['id'] . '"
                                       class="fa fa-1x fa-close hvr-grow dom attempt-securitygroup-delete btn btn-outline-danger"
                                       data-sc1-securitygroup-id="' . $one_sc1_securitygroup['id'] . '"
                                       data-sc1-securitygroup-name="' . $one_sc1_securitygroup['name'] . '"></i>
                                </div>
                            </div>
                        </div>
                     </div>';

        }
        $html = $html .
                '</div>' ;

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-securitygroup-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Security Groups</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-securitygroup-create" class="btn btn-success go-sc1-securitygroup-create">New Security Group</span>
            <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-securitygroup-list" class="btn-refresh btn btn-info go-sc1-securitygroup-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">

                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Security Groups</strong></h4>
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_securitygroup_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_securitygroup_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_securitygroup_awscli_aws" class="instruction_set instruction_set_list_securitygroup_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
             </div>';


    return $html;

};