<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-loadbalancer-create" class="btn btn-success attempt-loadbalancer-create">Create</span>
                        <span id="go-sc1-loadbalancer-list" class="btn btn-info go-sc1-loadbalancer-list">SC1 Load Balancer List</span>
                        <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
                    </div>
                        
                    <div>
                        <h4>Application Load Balancer</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="loadbalancer_name" class="form-control" id="loadbalancer_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label> Internet / Private</label>
                            <input type="radio" name="internet_private" class="form-control" id="internet_private" value="internet" />
                            <input type="radio" name="internet_private" class="form-control" id="internet_private" value="private" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Listeners</label>
                            <input type="text" name="instance_name" class="form-control" id="instance_name" value="" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div class="col-sm-12 col-6">
                            Protocol
                        </div>
                        <div class="col-sm-12 col-6">
                            Port
                        </div>
                    </div>
                                          
                    <div class="col-sm-12 col-12 networks_available_wrap">
                        <div class="col-sm-12 col-12 form-group">
                            <label class="networks_enabled_label">Networks Enabled</label>
                        </div>
                        <div class="col-sm-12 col-12 form-group">                  
                            Private: <input type="checkbox" name="networks_enabled_private" class="form-control" id="networks_enabled_private" />
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description:</label>
                        <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <!--
                    
                    <div class="col-sm-12 col-12">
                        <label>Security Groups:</label>
                        <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Routing:</label>
                        <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Balanced Groups:</label>
                        <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    -->               

                    <div class="col-sm-12 col-12">
                        <div><label>Size</label></div>                          
                        <div id="sc1_loadbalancer_size_dropdown"></div>
                    </div> 
                        
                    <div class="col-sm-12 col-12">
                        <div class="form-group">
                            <label>Instance</label>
                            <div id="sc1_instance_dropdown">
                                <select class="form-control" name="instance_id" id="instance_id">
                                    <option>Loading Instances</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-12">
                        <div class="col-sm-12 col-12 form-group">
                            <h5>VPC Networks:</h5>
                        </div>
                              
                        <div class="col-sm-12 col-12">
                            <div id="svpc_vpc_multiselect" class="form-control">
                                <select class="form-control" name="svpc_name" id="svpc_name">
                                    <option>Loading SVPCs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-loadbalancer-create" class="btn btn-success attempt-loadbalancer-create">Create</span>
                        <span id="go-sc1-loadbalancer-list" class="btn btn-info go-sc1-loadbalancer-list">SC1 Load Balancer List</span>
                        <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
                    </div>
                    
                </div>
            </div>
            
        </div>';

    return $html;

};