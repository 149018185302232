<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    if ($rqd['chargeableitems']['chargeable_item_page_count'] > 1) {
        $html = $html .
            '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['chargeableitems']['chargeable_item_page_count'], $rqd['chargeableitems']['chargeable_item_page'], 'billing-invoice-chargeableitem-paginator') ;
        $html = $html .'</div>' ;
    }

    $html = $html .
        '<div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <h6>Entity</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Charge Type</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Entity ID</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Start</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Price</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Period</h6>
                    </div>
                    <div class="col-sm-1 col-1">
                        <h6>Unit</h6>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h6>Item Total</h6>
                    </div> 
                 </div>';
    $html = $html . '<div id="chargeableitems_table" class="col-sm-12 col-12">' ;

    foreach ($rqd['chargeableitems']['chargeable_items_page'] as $chargeable_item) {
        $entity_id_short = \ISOPHP\core::$php->substr($chargeable_item['entity_id'], 0, 10) ;
        $html = $html .
        '<div class="col-sm-12 col-12 one_billing_chargeable_item">
            <div class="col-sm-2 col-2">
                <p class="billing_list_id"><span class="billing_id billing_listing">' . $chargeable_item['entity'] . '</span></p>
            </div>
            <div class="col-sm-2 col-2">
                <p class="billing_list_name"><span class="billing_name billing_listing">' . $chargeable_item['charge_type_id'] . '</span></p>
            </div>
            <div class="col-sm-2 col-2">
                <p class="billing_list_id">
                    <span class="billing_id billing_listing tooltip" data-toggle="tooltip" title="' . $chargeable_item['entity_id'] . '">
                        ' . $entity_id_short . '
                    </span>
                </p>
            </div>
            <div class="col-sm-1 col-1">
                <p class="billing_list_comment billing_listing">' . $chargeable_item['charge_period_start_friendly'] . '</p>
            </div>
            <div class="col-sm-1 col-1">
                <p class="billing_list_comment billing_listing">' . $chargeable_item['price_per_charge_period'] . '</p>
            </div>
            <div class="col-sm-1 col-1">
                <p class="billing_list_comment billing_listing">' . $chargeable_item['total_charge_period'] . '</p>
            </div>    
            <div class="col-sm-1 col-1">
                <p class="billing_list_comment billing_listing">' . $chargeable_item['charge_period_unit'] . '</p>
            </div>  
            <div class="col-sm-2 col-2">
                <p class="billing_list_comment billing_listing">' . $chargeable_item['total_charge'] . '</p>
            </div>                       
         </div>';
    }

    $html = $html . '</div>' ;

    if ($rqd['chargeableitems']['chargeable_item_page_count'] > 1) {
        $html = $html .
            '<div class="col-sm-12">';
        $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['chargeableitems']['chargeable_item_page_count'], $rqd['chargeableitems']['chargeable_item_page'], 'billing-invoice-chargeableitem-paginator') ;
        $html = $html .'</div>' ;
    }

    return $html;

};
