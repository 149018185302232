<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                
                    <div>
                        <h4>New Image</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Registry</label>
                                <div id="pcr_registry_dropdown">
                                    <select class="form-control" name="registry_name" id="registry_name">
                                        <option>Loading Registries</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="image_name" class="form-control" id="image_name" value="" />
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Value</label>
                                <textarea name="image_value" id="image_value" class="form-control"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-image-create" class="btn btn-success attempt-image-create">Create</span>
                            <span id="go-pcr-image-list" class="btn btn-warning go-pcr-image-list">PCR Image List</span>
                            <span id="go-pcr-landing" class="btn btn-warning go-pcr-landing">PCR Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    return $html;

};
