<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-launchconfiguration-create" class="btn btn-success attempt-launchconfiguration-create">Create</span>
                        <span id="go-sc1-launchconfiguration-list" class="btn btn-warning go-sc1-launchconfiguration-list">Launch Configuration List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                
                    <div>
                        <h4>New Launch Configuration</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Image</label></div>                          
                                <div id="sc1_image_dropdown"></div>
                            </div>
                        </div> 

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Size</label></div>                          
                                <div id="sc1_size_dropdown"></div>
                            </div>
                        </div> 

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Template Name</label>
                                <input type="text" name="template_name" class="form-control" id="template_name" value="" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Instance Name</label>
                                <input type="text" name="instance_name" class="form-control" id="instance_name" value="" />
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <div><label>Keypair</label></div>                          
                                <div id="sc1_keypair_dropdown"></div>
                            </div>
                        </div> 
                                              
                        <div class="col-sm-12 col-12 networks_available_wrap">
                            <div class="col-sm-12 col-12 form-group">
                                <label class="networks_enabled_label">Networks Enabled</label>
                            </div>
                            <div class="col-sm-12 col-12 form-group">                    
                                <div class="col-sm-1 col-1">
                                    &nbsp;
                                </div>                   
                                <div class="col-sm-5 col-5">
                                    Public: <input type="checkbox" name="networks_enabled_public" class="form-control" id="networks_enabled_public" />
                                </div>                   
                                <div class="col-sm-5 col-5">
                                    Private: <input type="checkbox" name="networks_enabled_private" class="form-control" id="networks_enabled_private" />
                                </div>                 
                                <div class="col-sm-1 col-1">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-sm-12 col-12">
                        
                            <div class="col-sm-4 col-4">
                                <div class="form-group">
                                    <label>Description:</label>
                                    <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                                </div>
                            </div>
                      
                            <div class="col-sm-8 col-8">
                                <div class="form-group">
                                    <label>User Data Script:</label>
                                    <textarea name="vm_userdata" id="vm_userdata" class="form-control" rows="10"></textarea>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-launchconfiguration-create" class="btn btn-success attempt-launchconfiguration-create">Create</span>
                        <span id="go-sc1-launchconfiguration-list" class="btn btn-warning go-sc1-launchconfiguration-list">Launch Configuration List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                    
                </div>
            </div>
        </div>';

    return $html;

};
