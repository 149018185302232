<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-6 offset-sm-3 col-12" >
                        
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-object-create" class="btn btn-success attempt-object-create">Create</span>
                        <span id="go-artifact-object-list" class="btn btn-warning go-artifact-object-list">Object List</span>
                        <span id="go-artifact-landing" class="btn btn-warning go-artifact-landing">Artifact Home</span>
                    </div>
                
                    <div>
                        <h4>New Artifact Object</h4>
                    </div>
                    
                    <div class="row">

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Repository</label>
                                <div id="artifact_repository_dropdown">
                                    <select class="form-control" name="repository_name" id="repository_name">
                                        <option>Loading Repositories</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="object_name" class="form-control" id="object_name" value="" />
                            </div>
                        </div>
                       
                        <div class="col-sm-12 col-12">
                            <div class="form-group">
                                <label>Object Type</label>
                                <select id="object_type" name="object_type" class="form-control">
                                     <option value="zip">Zip File</option>
                                     <option value="bin">Binary File</option>
                                     <option value="exe">Windows Executable</option>
                                     <option value="iso">ISO Image</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 col-12 text-center">
                            <span id="attempt-object-create" class="btn btn-success attempt-object-create">Create</span>
                            <span id="go-artifact-object-list" class="btn btn-warning go-artifact-object-list">Object List</span>
                            <span id="go-artifact-landing" class="btn btn-warning go-artifact-landing">Artifact Home</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Creating Artifact Objects</strong></h4>
                   
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_create_object_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_ptc_aws"></i></h5>
                    <pre id="instruction_set_create_object_ptc_aws" class="instruction_set instruction_set_create_object_ptc_aws">
ptconfigure-enterprise AWSRoute53 list -yg --type="Hosted-Zone" --aws-access-key="sahara_access_key" --aws-secret-key="sahara_secret_key" --aws-region="sahara_region" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format=json
{
    "HostedZones": null
}
                    </pre>
                    
                     
                    <h5>AWS Mode - AWS CLI<i id="switch_instruction_set_create_object_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_awscli_aws"></i></h5>
                    <pre id="instruction_set_create_object_awscli_aws" class="instruction_set instruction_set_create_object_awscli_aws">
aws route53 create-hosted-zone --name mytesturl.com --caller-reference 123451234512345 --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZone": {
        "Name": "mytesturl.com", 
        "Config": {
            "Comment": "", 
            "PrivateZone": false
        }, 
        "LinkedService": {
            "ServicePrincipal": "string", 
            "Description": "string"
        }, 
        "CallerReference": "123451234512345", 
        "ResourceObjectSetCount": 1, 
        "Id": "string"
    }, 
    "DelegationSet": {
        "NameServers": [
            "string"
        ], 
        "CallerReference": "123451234512345", 
        "Id": "string"
    }, 
    "VPC": {
        "VPCId": "12345", 
        "VPCRegion": "eu-west-2"
    }, 
    "ChangeInfo": {
        "Status": "string", 
        "Comment": "", 
        "SubmittedAt": "1560094644", 
        "Id": "4173b0c44a17891639142dd4e723966d"
    }
}
                    </pre>

             </div>';

    return $html;

};

/*
 *
 *
                    <h5>AWS Mode - Pharaoh Configure<i id="switch_instruction_set_create_object_ptc_rax"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_create_object_ptc_rax"></i></h5>
                    <pre id="instruction_set_create_object_ptc_rax" class="instruction_set instruction_set_create_object_ptc_rax">
ptconfigure-enterprise AWSRoute53 ensure-repository-exists --repository-name="testmailrepository.com" --repository-email="testmail@testmailrepository.com"  --repository-comment="A Comment"  --repository-ttl="60"  --aws-access-key="1234" --aws-secret-key="ABC1234" --aws-region="eu-west-1" --aws-endpoint="http://aws.saharaws.vm:8888" --output-format="json" --disable-duplicates -yg ;
{
    "status": "created",
    "requested": "testmailrepository.com"
}

                    </pre>
 *
 */