<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $network_interfaces_are_array = \ISOPHP\core::$php->is_array($rqd['network_interfaces']) ;
    if ($network_interfaces_are_array === true) {
        $network_interfaces_exist = true ;
    } else {
        $network_interfaces_exist = false ;
    }
    if ($network_interfaces_exist === true) {
        $network_interface_count = \ISOPHP\core::$php->count($rqd['network_interfaces']) ;
    } else {
        $network_interface_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-networkinterface-create" class="btn btn-success go-sc1-networkinterface-create">New Network Interface</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-networkinterface-list" class="btn-refresh btn btn-info go-sc1-networkinterface-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Network Interface List</h4>
         </div> ' ;

    if ($network_interfaces_exist === true && $network_interface_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-networkinterface-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_networkinterface">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">VM</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">Network</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">MAC</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_networkinterface_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>
                 <div class="col-sm-12 col-12 sahara_table page_item_list">';
        foreach ($rqd['network_interfaces'] as $one_sc1_networkinterface) {
            $html = $html .
                   '
                 <div class="col-sm-12 col-12">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' . $one_sc1_networkinterface['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_instance_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_networkinterface['vmid'] .' : '.$one_sc1_networkinterface['vm_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_networkinterface_name"><span class="sc1_networkinterface_name sc1_listing">' . $one_sc1_networkinterface['vpc_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_networkinterface_data"><span class="sc1_networkinterface_data sc1_listing">' . $one_sc1_networkinterface['mac_address'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_networkinterface_type"><span class="sc1_type sc1_listing">' . $one_sc1_networkinterface['type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_networkinterface_' . $one_sc1_networkinterface['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-networkinterface-edit btn btn-outline-primary"
                                   data-sc1-networkinterface-id="' . $one_sc1_networkinterface['id'] . '"
                                   data-sc1-networkinterface-name="' . $one_sc1_networkinterface['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_networkinterface_' . $one_sc1_networkinterface['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-networkinterface-delete btn btn-outline-danger"
                                   data-sc1-networkinterface-id="' . $one_sc1_networkinterface['id'] . '"
                                   data-sc1-networkinterface-name="' . $one_sc1_networkinterface['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                </div>';
        }
        $html = $html .
            '</div>';

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-networkinterface-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Network Interfaces</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-networkinterface-create" class="btn btn-success go-sc1-networkinterface-create">New Network Interface</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-networkinterface-list" class="btn-refresh btn btn-info go-sc1-networkinterface-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">

                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Network Interfaces</strong></h4>
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_networkinterface_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_networkinterface_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_networkinterface_awscli_aws" class="instruction_set instruction_set_list_networkinterface_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
             </div>';


    return $html;

};