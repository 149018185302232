<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $launchtemplates_are_array = \ISOPHP\core::$php->is_array($rqd['launchtemplates']) ;
    if ($launchtemplates_are_array === true) {
        $launchtemplates_exist = true ;
    } else {
        $launchtemplates_exist = false ;
    }
    if ($launchtemplates_exist === true) {
        $launchtemplate_count = \ISOPHP\core::$php->count($rqd['launchtemplates']) ;
    } else {
        $launchtemplate_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-launchtemplate-create" class="btn btn-success go-sc1-launchtemplate-create">New Launch Template</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-launchtemplate-list" class="btn-refresh btn btn-info go-sc1-launchtemplate-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Launch Template List</h4>
         </div> ' ;

    if ($launchtemplates_exist === true && $launchtemplate_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-launchtemplate-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_launchtemplate item_list_sc1_launchtemplates" id="item_list_sc1_launchtemplates">
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">ID</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">Name</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">Size</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">Image</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">Pub. IP</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchtemplate_table_title">Networks</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_launchtemplate_table_title">Key</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchtemplate_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_launchtemplate_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['launchtemplates'] as $one_sc1_launchtemplate_key => $one_sc1_launchtemplate) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' ;

            $html = $html . $one_sc1_launchtemplate['id'] ;

            $html = $html .
                    '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchtemplate_name"><span class="sc1_instance_name sc1_listing">' ;

                        if ($one_sc1_launchtemplate['template_name'] == null) {
                            $html = $html . 'Unset' ;
                        } else {
                            $html = $html . $one_sc1_launchtemplate['template_name'] ;
                        }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchtemplate_size_id"><span class="sc1_instance_size sc1_listing">' ;

                        if ($one_sc1_launchtemplate['size_id'] == null) {
                            $html = $html . 'Unset' ;
                        } else {
                            $html = $html . $one_sc1_launchtemplate['size_id'] ;
                        }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchtemplate_image_id"><span class="sc1_launchtemplate_start_time sc1_listing">' ;

                        if ($one_sc1_launchtemplate['image_id'] == null) {
                            $html = $html . 'Unset' ;
                        } else {
                            $html = $html . $one_sc1_launchtemplate['image_id'] ;
                        }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchtemplate_publicip"><span class="sc1_launchtemplate_end_time sc1_listing">' ;

                        if ($one_sc1_launchtemplate['internet_enabled'] == null) {
                            $html = $html . 'No' ;
                        } else if ($one_sc1_launchtemplate['internet_enabled'] == true) {
                            $html = $html . 'Yes' ;
                        } else {
                            $html = $html . 'No' ;
                        }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_launchtemplate_networks"><span class="sc1_launchtemplate_end_time sc1_listing">';
                    # private_network_ids
                    #    . $one_sc1_launchtemplate['networks']

                    $private_networks_are_array = \ISOPHP\core::$php->is_array($one_sc1_launchtemplate['private_network_ids']) ;
                    if ( ($private_networks_are_array === true) &&
                         (\ISOPHP\core::$php->count($one_sc1_launchtemplate['private_network_ids']) > 0 ) ) {
                        foreach ($one_sc1_launchtemplate['private_network_ids'] as $private_network_id) {
                            $html = $html . '<span class="sc1_list_region">' ;
                            $html = $html . "  ".$private_network_id ;
                            $html = $html . '</span>' ;
                        }
                    } else {
                        $html = $html . '<span class="sc1_list_region">' ;
                        $html = $html . "  None Defined" ;
                        $html = $html . '</span>' ;
                    }

            $html = $html .
                        '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_launchtemplate_key"><span class="sc1_launchtemplate_key sc1_listing">' ;

                        if ($one_sc1_launchtemplate['keypair'] == null) {
                            $html = $html . 'Unset' ;
                        } else {
                            $html = $html . $one_sc1_launchtemplate['keypair'] ;
                        }

            $html = $html .
                '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_launchtemplate_description"><span class="sc1_launchtemplate_key sc1_listing">' . $one_sc1_launchtemplate['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_launchtemplate_' . $one_sc1_launchtemplate['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom go-sc1-launchtemplate-edit btn btn-outline-primary"
                                   data-sc1-launchtemplate-id="' . $one_sc1_launchtemplate['id'] . '"
                                   data-sc1-launchtemplate-name="' . $one_sc1_launchtemplate['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_launchtemplate_' . $one_sc1_launchtemplate['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-sc1-launchtemplate-delete btn btn-outline-danger"
                                   data-sc1-launchtemplate-id="' . $one_sc1_launchtemplate['id'] . '"
                                   data-sc1-launchtemplate-name="' . $one_sc1_launchtemplate['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-launchtemplate-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Launch Templates</h5>
             </div>' ;

    }

    $html = $html .
       '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-launchtemplate-create" class="btn btn-success go-sc1-launchtemplate-create">New Launch Template</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-launchtemplate-list" class="btn-refresh btn btn-info go-sc1-launchtemplate-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    $html = $html .
        '    <div class="col-sm-12 cloud_instruction_wrap">
                    <h4>Cloud Compatibility Instructions - <strong>Listing SC1 Launch Templates</strong></h4>
                    
                    
                    <h5>AWS Mode - AWS CLI <i id="switch_instruction_set_list_launchtemplate_awscli_aws"
                                              class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_launchtemplate_awscli_aws"></i></h5>
                    <pre id="instruction_set_list_launchtemplate_awscli_aws" class="instruction_set instruction_set_list_launchtemplate_awscli_aws">
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
                    
                    <h5>AWS Mode - Pharaoh Configure <i id="switch_instruction_set_list_launchtemplate_ptc_aws"
                                                        class="fa fa-1x fa-toggle-off hvr-grow switch_instruction_set switch_instruction_set_list_launchtemplate_ptc_aws"></i></h5>
                    <pre id="instruction_set_list_launchtemplate_ptc_aws" class="instruction_set instruction_set_list_launchtemplate_ptc_aws" >
aws route53 list-hosted-zones --profile=pharaoh --endpoint-url=http://aws.saharaws.vm:8888
{
    "HostedZones": [
        {
            "ResourceScheduled nstanceSetCount": 1, 
            "CallerReference": "123456", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "123456", 
            "Name": "woonicorn.me.uk"
        }, 
        {
            "ResourceVolumeSetCount": 1, 
            "CallerReference": "SAHARAID1559476975.9559", 
            "Config": {
                "PrivateZone": false
            }, 
            "Id": "SAHARAID1559476975.9559", 
            "Name": "woodave.com"
        }
    ]
}
                    </pre>
             </div>';


    return $html;

};