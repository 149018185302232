<?php

Namespace Model\Database;

use ISOPHP\core;

class PerformanceChartPageModel extends \Model\Base {

// $bind = \Model\Database\PageModel::bindButtons();
    public function showDatabasePerformanceChartCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\DatabaseView();
            $view->loadTemplate('Database', 'PerformanceChartCreate.php', $vars);
            $bind = \Model\Database\PageModel::bindButtons();
            $view->displayDatabase($bind);
            \ISOPHP\js_core::$console->log('apply Database PerformanceChart Create 2', $vars);
        };
    }

    public function showDatabasePerformanceChartList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Database List');
            $vars['control'] = 'Database';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/database/performancechart/all';
            $afterwards = $this->applyDatabasePerformanceChartListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Database Model: ', $result);
            return $result;
        };
    }

    public function applyDatabasePerformanceChartListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Database PerformanceChart List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Database PerformanceChart List 1', $vars);
                $view = new \View\DatabaseView();
                $view->loadTemplate('Database', 'PerformanceChartList.php', $vars);
                $bind = \Model\Database\PageModel::bindButtons();
                $view->displayDatabase($bind);
                \ISOPHP\js_core::$console->log('apply Database PerformanceChart List 2', $vars);
                $page_model = new \Model\Database\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Database PerformanceChart List failed');
            }
        };
        return $func;
    }

    /**
     * Save performancechart
     */
    public function savePerformanceChartAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('save performancechart to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'performancechart-create';
        $vars['api_uri'] = '/api/database/performancechart/create';
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['name'] = $jQuery('#name')->val();
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save performancechart vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->savePerformanceChartResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function savePerformanceChartResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save database performancechart result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Scheduled Instance '.$request_data['performancechart']['database_performancechart_name'].' for Instance '.$request_data['performancechart']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database performancechart saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Scheduled Instance '.$request_data['performancechart']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database performancechart save failed');
            }
        };
        return $func;
    }
    /**
     * Delete performancechart
     */
    public function deletePerformanceChartAttemptToDatabase($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete performancechart to database');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Database';
        $vars['action'] = 'performancechart-delete';
        $vars['api_uri'] = '/api/database/performancechart/delete';
        $vars['instance_name'] = $jQuery('#instance_name')->val();
        $vars['performancechart_id'] = $jQuery('#performancechart_name')->val();
        \ISOPHP\js_core::$console->log('delete performancechart vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deletePerformanceChartResultFromDatabase($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deletePerformanceChartResultFromDatabase($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete database performancechart result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Scheduled Instance '.$request_data['performancechart']['database_performancechart_name'].' for Instance '.$request_data['performancechart']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('database performancechart deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Scheduled Instance '.$request_data['performancechart']['database_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('database performancechart delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete performancechart
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding scheduled instance buttons');

                # Scheduled Instance

                $go_database_performancechart_list = $jQuery('.go-database-performancechart-list');
                $go_database_performancechart_list->off() ;
                $go_database_performancechart_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'performancechart-list', array(), '/database/performancechart/list');
                });

                $go_database_performancechart_create = $jQuery('.go-database-performancechart-create');
                $go_database_performancechart_create->off() ;
                $go_database_performancechart_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'performancechart-create', array(), '/database/performancechart/create');
                });

                $save_performancechart = $jQuery('.attempt-performancechart-create');
                $save_performancechart->off() ;
                $save_performancechart->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('Database', 'performancechart-save-attempt', array());
                });

                $performancechart_delete = $jQuery('.attempt-performancechart-delete');
                $performancechart_delete->off() ;
                $performancechart_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $performancechart_name = $jqThis->attr('data-database-performancechart-name') ;
//                    $performancechart_id = $jqThis->attr('data-database-performancechart-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked performancechart delete button');
//                    if ($performancechart_name !== '') {
//                        $name_or_id = $performancechart_name ;
//                    } else {
//                        $name_or_id = $performancechart_id ;
//                    }
//                    $msg = "You are about to delete the Scheduled Instance $name_or_id. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $performancechart_id) {
//                        $navigate->route('Database', 'performancechart-delete-attempt', array('performancechart_id' => $performancechart_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }

}