<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $scheduledinstances_are_array = \ISOPHP\core::$php->is_array($rqd['scheduledinstances']) ;
    if ($scheduledinstances_are_array === true) {
        $scheduledinstances_exist = true ;
    } else {
        $scheduledinstances_exist = false ;
    }
    if ($scheduledinstances_exist === true) {
        $scheduledinstance_count = \ISOPHP\core::$php->count($rqd['scheduledinstances']) ;
    } else {
        $scheduledinstance_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-scheduledinstance-create" class="btn btn-success go-sc1-scheduledinstance-create">New Scheduled Instance</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scheduledinstance-executionlog-list" class="btn btn-info go-sc1-scheduledinstance-executionlog-list">Execution Logs</span>
            <span id="go-sc1-scheduledinstance-list" class="btn-refresh btn btn-info go-sc1-scheduledinstance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Scheduled Instance List</h4>
         </div> ' ;

    if ($scheduledinstances_exist === true && $scheduledinstance_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-scheduledinstance-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_scheduledinstance item_list_sc1_scheduledinstances" id="item_list_sc1_scheduledinstances"">
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scheduledinstance_table_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scheduledinstance_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scheduledinstance_table_title">Size</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_scheduledinstance_table_title">Start</h5>
                        </div>
                        <div class="col-sm-1 col-1">
                            <h5 class="sc1_scheduledinstance_table_title">End</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scheduledinstance_table_title">Description</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="sc1_scheduledinstance_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['scheduledinstances'] as $one_sc1_scheduledinstance) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table page_item_list">
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' ;

            $html = $html . \ISOPHP\core::$php->substr($one_sc1_scheduledinstance['id'], 0, 10) ;

            $html = $html .
                      ' </p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_instance_name"><span class="sc1_instance_name sc1_listing">' . $one_sc1_scheduledinstance['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_scheduledinstance_size"><span class="sc1_scheduledinstance_name sc1_listing">' . $one_sc1_scheduledinstance['size'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_scheduledinstance_start_time"><span class="sc1_scheduledinstance_start_time sc1_listing">' . $one_sc1_scheduledinstance['start_time'] . '</span></p>
                    </div>
                    <div class="col-sm-1 col-1">
                        <p class="sc1_list_scheduledinstance_end_time"><span class="sc1_scheduledinstance_end_time sc1_listing">' . $one_sc1_scheduledinstance['end_time'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="sc1_list_scheduledinstance_description"><span class="sc1_scheduledinstance_description sc1_listing">' . $one_sc1_scheduledinstance['description'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_scheduledinstance_' . $one_sc1_scheduledinstance['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-scheduledinstance-edit btn btn-outline-primary"
                                   data-sc1-scheduledinstance-id="' . $one_sc1_scheduledinstance['id'] . '"
                                   data-sc1-scheduledinstance-name="' . $one_sc1_scheduledinstance['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sc1_scheduledinstance_' . $one_sc1_scheduledinstance['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-scheduledinstance-delete btn btn-outline-danger"
                                   data-sc1-scheduledinstance-id="' . $one_sc1_scheduledinstance['id'] . '"
                                   data-sc1-scheduledinstance-name="' . $one_sc1_scheduledinstance['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'sc1-scheduledinstance-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Scheduled Instances</h5>
             </div>' ;

    }

    $html = $html .
        '  
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sc1-scheduledinstance-create" class="btn btn-success go-sc1-scheduledinstance-create">New Scheduled Instance</span>
            <span id="go-sc1-landing" class="btn btn-info go-sc1-landing">SC1 Home</span>
            <span id="go-sc1-scheduledinstance-executionlog-list" class="btn btn-info go-sc1-scheduledinstance-executionlog-list">Execution Logs</span>
            <span id="go-sc1-scheduledinstance-list" class="btn-refresh btn btn-info go-sc1-scheduledinstance-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>  ';

    return $html;

};