<?php

\Core\View::$template = function() {

    $html = '
        <div class="col-sm-12">
        
            <div class="col-sm-12">
                <h1>Welcome to the Piranha Container Registry Service</h1>
            </div>
        
            <div class="col-sm-12">
                
                <div class="col-sm-12">
                    <h3>Public and Private Container Registries.</h3>            
                </div>
                
                <div class="col-sm-12">
                
                    <div class="col-sm-6">
                        <span id="go-pcr-registry-list" class="go-pcr-registry-list btn btn-lg btn-warning">Registries</span>
                    </div>
                    <div class="col-sm-6">
                        <span id="go-pcr-registry-create" class="go-pcr-registry-create btn btn-lg btn-warning">Create Registry</span>
                    </div>
                                    
                </div>
                    
            </div>
          
        </div>';

    return $html ;

} ;