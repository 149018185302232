<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $policies_are_array = \ISOPHP\core::$php->is_array($rqd['policies']) ;
    if ($policies_are_array === true) {
        $policies_exist = true ;
    } else {
        $policies_exist = false ;
    }
    if ($policies_exist === true) {
        $policy_count = \ISOPHP\core::$php->count($rqd['policies']) ;
    } else {
        $policy_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-policy-create" class="btn btn-success go-sam-policy-create">New Policy</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-policy-list" class="btn btn-info go-sam-policy-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Policy List</h4>
         </div> ' ;

    if ($policies_exist === true && $policy_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12 one_sam_policy">
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_policy_id_title">ID</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="sam_policy_name_title">Name</h5>
                    </div>
                    <div class="col-sm-5 col-5">
                        <h5 class="sam_policy_id_title">Permissions</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="sam_policy_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['policies'] as $one_sam_policy) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sam_policy">
                    <div class="col-sm-2 col-2">
                        <p class="sam_list_id"><span class="sam_id sam_listing">' . $one_sam_policy['id'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="sam_list_name"><span class="sam_name sam_listing">' . $one_sam_policy['name'] . '</span></p>
                    </div>
                    <div class="col-sm-5 col-5">' ;

            foreach ($one_sam_policy['permissions'] as $one_permission_entity => $one_permission_values) {
                $html = $html . '<div class="col-sm-12 col-12 one_sam_permission">' ;
                $html = $html . '<strong>'.$one_permission_entity.': </strong>' ;
                foreach ($one_permission_values as $value_type => $is_enabled) {
                    if ($is_enabled == true) {
                        $html = $html . $value_type.'&nbsp;' ;
                    }
                }
                $html = $html . '</div>' ;
            }

            $html = $html . '
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">' ;

                if ($one_sam_policy['default'] !== true) {
                    $html = $html . '
                                    <i id="edit_sam_policy_' . $one_sam_policy['id'] . '"
                                       class="fa fa-1x fa-edit hvr-grow dom attempt-policy-edit btn btn-outline-primary"
                                       data-sam-policy-id="' . $one_sam_policy['id'] . '"
                                       data-sam-policy-name="' . $one_sam_policy['name'] . '"></i>' ;
                }

            $html = $html . '
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_sam_policy_' . $one_sam_policy['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-policy-delete btn btn-outline-danger"
                                   data-sam-policy-id="' . $one_sam_policy['id'] . '"
                                   data-sam-policy-name="' . $one_sam_policy['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Available Policies</h5>
             </div>' ;

    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-sam-policy-create" class="btn btn-success go-sam-policy-create">New Policy</span>
            <span id="go-sam-landing" class="btn btn-info go-sam-landing">SAM Home</span>
            <span id="go-sam-policy-list" class="btn btn-info go-sam-policy-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div> ' ;

    return $html;

};