<?php

Namespace Model\Artifact;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Artifact List of Piranha Web Services';
        $page['heading'] = 'List of Artifact';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Artifact Mod', $page, $pageVars);
        return $page;
    }

    public function showArtifactLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Artifact Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArtifactView();
            $view->loadTemplate('Artifact', 'ArtifactLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayArtifact($bind);
            \ISOPHP\js_core::$console->log('apply Artifact Repository Create 2', $vars);
        };
    }

    public function showArtifactRepositoryCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Artifact Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArtifactView();
            $view->loadTemplate('Artifact', 'RepositoryCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayArtifact($bind);
            \ISOPHP\js_core::$console->log('apply Artifact Repository Create 2', $vars);
        };
    }

    public function showArtifactRepositoryList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Artifact List');
            $vars['control'] = 'Artifact';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/artifact/repository/all';
            $afterwards = $this->applyArtifactRepositoryListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Artifact Model: ', $result);
            return $result;
        };
    }

    public function applyArtifactRepositoryListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Artifact Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Artifact Repository List 1', $vars);
                $view = new \View\ArtifactView();
                $view->loadTemplate('Artifact', 'RepositoryList.php', $vars);
                $bind = self::bindButtons();
                $view->displayArtifact($bind);
                \ISOPHP\js_core::$console->log('apply Artifact Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Artifact Repository List failed');
            }
        };
        return $func;
    }

    public function showArtifactRepositoryListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Artifact List');
            $vars['control'] = 'Artifact';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/artifact/repository/all';
            $afterwards = $this->applyArtifactRepositoryListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('Artifact Model: ', $result);
            return $result;
        };
    }

    public function applyArtifactRepositoryListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Artifact Repository List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Artifact Repository List 1', $vars);
                $view = new \View\ArtifactView();
                $view->loadTemplate('Artifact', 'RepositoryListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayArtifactRepositoryDropDown($bind);
                \ISOPHP\js_core::$console->log('apply Artifact Repository List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Artifact Repository List failed');
            }
        };
        return $func;
    }

    public function showArtifactObjectCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Artifact Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\ArtifactView();
            $view->loadTemplate('Artifact', 'ObjectCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayArtifact($bind);
            \ISOPHP\js_core::$console->log('apply Artifact Object Create 2', $vars);
        };
    }

    public function showArtifactObjectList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for repository name');
            \ISOPHP\js_core::$console->log($pageVars['repository_name']);
            if (isset($pageVars['repository_name'])) {
                \ISOPHP\js_core::$console->log('Showing Artifact List');
                $vars['control'] = 'Artifact';
                $vars['action'] = 'list';
                $vars['api_uri'] = '/api/artifact/object/all';
                $vars['repository_name'] = $pageVars['repository_name'];
                $afterwards = $this->applyArtifactObjectListResult($pageVars);
                $result = $this->performRequest($vars, $afterwards, true);
                \ISOPHP\js_core::$console->log('Artifact Model: ', $result);
                return $result;
            }
            return null ;
        };
    }

    public function applyArtifactObjectListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Artifact Object List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Artifact Object List 1', $vars);
                $view = new \View\ArtifactView();
                $view->loadTemplate('Artifact', 'ObjectList.php', $vars);
                $bind = self::bindButtons();
                $view->displayArtifact($bind);
                \ISOPHP\js_core::$console->log('apply Artifact Object List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Artifact Object List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save repository
     */
    public function saveRepositoryAttemptToArtifact($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save repository to artifact');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Artifact';
        $vars['action'] = 'repository-create';
        $vars['api_uri'] = '/api/artifact/repository/create';
        $vars['repository_name'] = $jQuery('#repository_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRepositoryResultFromArtifact($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRepositoryResultFromArtifact($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save artifact repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Repository '.$request_data['repository']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('artifact repository saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Artifact', 'repository-list', array(), '/artifact/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Repository '.$request_data['repository']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('artifact repository save failed');
            }
        };
        return $func;
    }

    /**
     * Save object
     */
    public function saveObjectAttemptToArtifact($pageVars)
    {
        \ISOPHP\js_core::$console->log('save object to artifact');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Artifact';
        $vars['action'] = 'object-create';
        $vars['api_uri'] = '/api/artifact/object/create';
        $vars['repository_name'] = $jQuery('#repository_name')->val();
        $vars['object_name'] = $jQuery('#object_name')->val();
        $vars['object_value'] = $jQuery('#object_value')->val();
        $vars['object_type'] = $jQuery('#object_type')->val();
        \ISOPHP\js_core::$console->log('save object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromArtifact($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveObjectResultFromArtifact($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save artifact object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Object '.$request_data['object']['artifact_object_name'].' for Repository '.$request_data['object']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('artifact object saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Object '.$request_data['object']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('artifact object save failed');
            }
        };
        return $func;
    }
    /**
     * Delete object
     */
    public function deleteObjectAttemptToArtifact($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete object to artifact');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Artifact';
        $vars['action'] = 'object-delete';
        $vars['api_uri'] = '/api/artifact/object/delete';
        $vars['repository_name'] = $pageVars['params']['repository_name'];
        $vars['object_key'] = $pageVars['params']['object_key'];
        $vars['object_id'] = $pageVars['params']['object_id'];
        \ISOPHP\js_core::$console->log('delete object vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveObjectResultFromArtifact($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteObjectResultFromArtifact($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete artifact object result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Object '.$request_data['object']['artifact_object_name'].' from Repository '.$request_data['object']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('artifact object deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Object '.$request_data['object']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('artifact object delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete repository
     */
    public function deleteRepositoryAttemptToArtifact($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete repository to artifact');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'Artifact';
        $vars['action'] = 'repository-delete';
        $vars['api_uri'] = '/api/artifact/repository/delete';
        $vars['repository_name'] = $pageVars['params']['repository_name'];
        \ISOPHP\js_core::$console->log('delete repository vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteRepositoryResultFromArtifact($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRepositoryResultFromArtifact($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete artifact repository result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Repository '.$request_data['repository']['artifact_repository_name'].' for Repository '.$request_data['repository']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('artifact repository deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('Artifact', 'repository-list', array(), '/artifact/repository/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Repository '.$request_data['repository']['artifact_repository_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('artifact repository delete failed');
            }
            $show_repository_list = self::showArtifactRepositoryList($pageVars) ;
            $show_repository_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_artifact_landing = $jQuery('.go-artifact-landing');
                $go_artifact_landing->off() ;
                $go_artifact_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'landing', array(), '/artifact');
                });

                $go_artifact_repository_list = $jQuery('.go-artifact-repository-list');
                $go_artifact_repository_list->off() ;
                $go_artifact_repository_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'repository-list', array(), '/artifact/repository/list');
                });

                $go_artifact_repository_create = $jQuery('.go-artifact-repository-create');
                $go_artifact_repository_create->off() ;
                $go_artifact_repository_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'repository-create', array(), '/artifact/repository/create');
                });

                $save_repository = $jQuery('.attempt-repository-create');
                $save_repository->off() ;
                $save_repository->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'repository-save-attempt', array());
                });

                $go_artifact_object_list = $jQuery('.go-artifact-object-list');
                $go_artifact_object_list->off() ;
                $go_artifact_object_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'object-list', array(), '/artifact/object/list');
                });

                $go_artifact_object_create = $jQuery('.go-artifact-object-create');
                $go_artifact_object_create->off() ;
                $go_artifact_object_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'object-create', array(), '/artifact/object/create');
                });

                $save_object = $jQuery('.attempt-object-create');
                $save_object->off() ;
                $save_object->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Artifact', 'object-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $object_delete = $jQuery('.attempt-object-delete');
                $object_delete->off() ;
                $object_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $object_name = $jqThis->attr('data-artifact-object-name') ;
                    $object_id = $jqThis->attr('data-artifact-object-id') ;
                    $repository_name = $jqThis->attr('data-artifact-repository-name') ;
                    \ISOPHP\js_core::$console->log('Clicked object delete button');
                    $msg = "You are about to delete the Object for $object_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $object_id, $object_name, $repository_name) {
                        $navigate->route('Artifact', 'object-delete-attempt', array(
                            'object_id' => $object_id,
                            'object_name' => $object_name,
                            'repository_name' => $repository_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $repository_delete = $jQuery('.attempt-repository-delete');
                $repository_delete->off() ;
                $repository_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $repository_name = $jqThis->attr('data-artifact-repository-name') ;
                    \ISOPHP\js_core::$console->log('Clicked repository delete button');
                    $msg = "You are about to delete the Repository $repository_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $repository_name) {
                        $navigate->route('Artifact', 'repository-delete-attempt', array(
                            'repository_name' => $repository_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindRepositoryListDropDownButtonsForObjectList()
    {
        \ISOPHP\js_core::$console->log('Started binding repository switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding repository switch');
            $switch_repository = $jQuery('#repository_name');
            $switch_repository->off() ;
            $switch_repository->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#repository_name') ;
                $repository_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked repository switch');
                $navigate->route('Artifact', 'object-list', array(
                    'repository_name' => $repository_name,
                ));
            } ) ;

        };
        return $func;
    }



}