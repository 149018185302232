<?php

\Core\View::$template = function () {

    $html = '   
        <div class="container">
            <div class="row"> 
                <div class="col-sm-10 offset-sm-1 col-10" >
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-backup-create" class="btn btn-success attempt-backup-create">Create</span>
                        <span id="go-sc1-backup-list" class="btn btn-warning go-sc1-backup-list">Backup List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                                    
                    <div>
                        <h4>New Backup</h4>
                    </div>
               
                    <div class="col-sm-12 col-12">
                        <label>Instance</label>
                        <div id="sc1_instance_dropdown">
                            <select class="form-control" name="instance_id" id="instance_id">
                                <option>Loading Instances</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-12 col-12">
                        <label>Name</label>
                        <input type="text" name="backup_name" class="form-control" id="backup_name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="description" id="description" class="form-control"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-backup-create" class="btn btn-success attempt-backup-create">Create</span>
                        <span id="go-sc1-backup-list" class="btn btn-warning go-sc1-backup-list">Backup List</span>
                        <span id="go-sc1-landing" class="btn btn-warning go-sc1-landing">SC1 Home</span>
                    </div>
                        
                </div>
            </div>
        </div>';

    return $html;

};