<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $images_are_array = \ISOPHP\core::$php->is_array($rqd['images']) ;
    if ($images_are_array === true) {
        $images_exist = true ;
    } else {
        $images_exist = false ;
    }
    if ($images_exist === true) {
        $image_count = \ISOPHP\core::$php->count($rqd['images']) ;
    } else {
        $image_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
            <span id="go-database-image-list" class="btn btn-info go-database-image-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Images</h4>
        </div> ' ;

    if ($images_exist === true && $image_count !== false) {

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'database-image-paginator') ;
            $html = $html .'</div>' ;
        }

        $html = $html .
                '<div class="col-sm-12 col-12 page_item_list_title">
                    <div class="col-sm-12 col-12 one_sc1_image">
                        <div class="col-sm-3 col-3">
                            <h5 class="image_id_title">ID</h5>
                        </div>
                        <div class="col-sm-4 col-4">
                            <h5 class="image_id_title">Name</h5>
                        </div>
                        <div class="col-sm-5 col-5">
                            <h5 class="image_id_title">Description</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['images'] as $one_sc1_image) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_sc1_image page_item_list">
                    <div class="col-sm-3 col-3">
                        <p class="sc1_list_id"><span class="sc1_id sc1_listing">' . $one_sc1_image['image_id'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="sc1_list_name"><span class="sc1_name sc1_listing">' . $one_sc1_image['name'] . '</span></p>
                    </div>
                    <div class="col-sm-5 col-5">
                        <p class="sc1_list_description sc1_listing">' . $one_sc1_image['description'] . '</p>
                    </div>
                    <!--
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="edit_sc1_image_' . $one_sc1_image['image_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-image-edit btn btn-outline-primary"
                                   data-database-image-id="' . $one_sc1_image['image_id'] . '"
                                   data-database-image-name="' . $one_sc1_image['name'] . '"></i>
                            </div>
                        </div>
                    </div>
                    -->         
                 </div>';
        }

        if ($rqd['page_count'] > 1) {
            $html = $html .
                '<div class="col-sm-12">';
            $html = $html . \Model\DefaultModule\PageModel::getPaginatorHTML($rqd['page_count'], $rqd['page'], 'database-image-paginator') ;
            $html = $html .'</div>' ;
        }

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no Database Images</h5>
             </div>' ;

    }

    $html = $html .
        '<div class="col-sm-12 col-12 text-center">
                <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
                <span id="go-database-image-list" class="btn btn-info go-database-image-list btn-refresh">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    return $html;

};