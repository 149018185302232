<?php

Namespace Controller;

class SVPCController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('SVPC controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In SVPC, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }
            $page_model = new \Model\SVPC\PageModel();
            $dhcpoptions_page_model = new \Model\SVPC\DHCPOptionsPageModel();
            $networkacl_page_model = new \Model\SVPC\NetworkACLPageModel();
            $internetgateway_page_model = new \Model\SVPC\InternetGatewayPageModel();
            $natgateway_page_model = new \Model\SVPC\NATGatewayPageModel();
            $routetable_page_model = new \Model\SVPC\RouteTablePageModel();
            $peeringconnection_page_model = new \Model\SVPC\PeeringConnectionPageModel();
            $sitetositevpn_page_model = new \Model\SVPC\SiteToSiteVPNPageModel();
            $sc1_page_model = new \Model\SC1\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('SVPC List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'SVPC.php';
            $res->type = 'view';
            $res->view_control = 'SVPC';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showSVPCLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'vpc-list') {
                $res->post_template[] = $page_model->showSVPCVPCList($pageVars);
            } else if ($pageVars['route']['action'] == 'vpc-create') {
                $res->post_template[] = $page_model->showSVPCVPCCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'vpc-save-attempt') {
                $page_model->saveSVPCAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'vpc-delete-attempt') {
                $page_model->deleteSVPCAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'subnet-list') {
                $res->post_template[] = $page_model->showSVPCSubnetList($pageVars);
            } else if ($pageVars['route']['action'] == 'subnet-create') {
                $res->post_template[] = $page_model->showSVPCSubnetCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'subnet-save-attempt') {
                $page_model->saveSubnetAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'subnet-delete-attempt') {
                $page_model->deleteSubnetAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkacl-list') {
                $res->post_template[] = $networkacl_page_model->showSVPCNetworkACLList($pageVars);
            } else if ($pageVars['route']['action'] == 'networkacl-create') {
                $res->post_template[] = $networkacl_page_model->showSVPCNetworkACLCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'networkacl-save-attempt') {
                $networkacl_page_model->saveNetworkACLAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkacl-delete-attempt') {
                $networkacl_page_model->deleteNetworkACLAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'dhcpoptions-list') {
                $res->post_template[] = $dhcpoptions_page_model->showSVPCDHCPOptionsList($pageVars);
            } else if ($pageVars['route']['action'] == 'dhcpoptions-create') {
                $res->post_template[] = $dhcpoptions_page_model->showSVPCDHCPOptionsCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'dhcpoptions-save-attempt') {
                $dhcpoptions_page_model->saveDHCPOptionsAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'dhcpoptions-delete-attempt') {
                $dhcpoptions_page_model->deleteDHCPOptionsAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'internetgateway-list') {
                $res->post_template[] = $internetgateway_page_model->showSVPCInternetGatewayList($pageVars);
            } else if ($pageVars['route']['action'] == 'internetgateway-create') {
                $res->post_template[] = $internetgateway_page_model->showSVPCInternetGatewayCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'internetgateway-save-attempt') {
                $internetgateway_page_model->saveInternetGatewayAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'internetgateway-delete-attempt') {
                $internetgateway_page_model->deleteInternetGatewayAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'natgateway-list') {
                $res->post_template[] = $natgateway_page_model->showSVPCNATGatewayList($pageVars);
            } else if ($pageVars['route']['action'] == 'natgateway-create') {
                $res->post_template[] = $natgateway_page_model->showSVPCNATGatewayCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'natgateway-save-attempt') {
                $natgateway_page_model->saveNATGatewayAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'natgateway-delete-attempt') {
                $natgateway_page_model->deleteNATGatewayAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkacl-list') {
                $res->post_template[] = $networkacl_page_model->showSVPCNetworkACLList($pageVars);
            } else if ($pageVars['route']['action'] == 'networkacl-create') {
                $res->post_template[] = $networkacl_page_model->showSVPCNetworkACLCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'networkacl-save-attempt') {
                $networkacl_page_model->saveNetworkACLAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'networkacl-delete-attempt') {
                $networkacl_page_model->deleteNetworkACLAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'routetable-list') {
                $res->post_template[] = $routetable_page_model->showSVPCRouteTableList($pageVars);
            } else if ($pageVars['route']['action'] == 'routetable-create') {
                $res->post_template[] = $routetable_page_model->showSVPCRouteTableCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'routetable-save-attempt') {
                $routetable_page_model->saveRouteTableAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'routetable-delete-attempt') {
                $routetable_page_model->deleteRouteTableAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'peeringconnection-list') {
                $res->post_template[] = $peeringconnection_page_model->showSVPCPeeringConnectionList($pageVars);
            } else if ($pageVars['route']['action'] == 'peeringconnection-create') {
                $res->post_template[] = $peeringconnection_page_model->showSVPCPeeringConnectionCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'peeringconnection-save-attempt') {
                $peeringconnection_page_model->savePeeringConnectionAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'peeringconnection-delete-attempt') {
                $peeringconnection_page_model->deletePeeringConnectionAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'sitetositevpn-list') {
                $res->post_template[] = $sitetositevpn_page_model->showSVPCSiteToSiteVPNList($pageVars);
            } else if ($pageVars['route']['action'] == 'sitetositevpn-create') {
                $res->post_template[] = $sitetositevpn_page_model->showSVPCSiteToSiteVPNCreate($pageVars);
                $res->post_template[] = $page_model->showSVPCVPCListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'sitetositevpn-save-attempt') {
                $sitetositevpn_page_model->saveSiteToSiteVPNAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'sitetositevpn-delete-attempt') {
                $sitetositevpn_page_model->deleteSiteToSiteVPNAttemptToSVPC($pageVars);
                $res->view = 'SVPC.php';
                $res->control = 'SVPC';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showSVPCLanding($pageVars);
            }

//          $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $internetgateway_page_model->bindButtons();
            $res->post_template[] = $natgateway_page_model->bindButtons();
            $res->post_template[] = $dhcpoptions_page_model->bindButtons();
            $res->post_template[] = $routetable_page_model->bindButtons();
            $res->post_template[] = $peeringconnection_page_model->bindButtons();
            $res->post_template[] = $networkacl_page_model->bindButtons();
            $res->post_template[] = $dhcpoptions_page_model->bindButtons();
            $res->post_template[] = $sitetositevpn_page_model->bindButtons();
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $sc1_page_model->bindButtons();
            \ISOPHP\js_core::$console->log('SVPC Controller', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}