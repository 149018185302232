<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $directories_are_array = \ISOPHP\core::$php->is_array($rqd['directories']) ;
    if ($directories_are_array === true) {
        $directories_exist = true ;
    } else {
        $directories_exist = false ;
    }
    if ($directories_exist === true) {
        $directory_count = \ISOPHP\core::$php->count($rqd['directories']) ;
    } else {
        $directory_count = false ;
    }
    if ($directories_exist === true && $directory_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your directory:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="directory_name" id="directory_name">';
        foreach ($rqd['directories'] as $one_drive_directory) {
            $html = $html . '<option value="' . $one_drive_directory['drive_directory_name'] . '">' . $one_drive_directory['drive_directory_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available directories</option>
             </select>' ;

    }

    $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-drive-landing" class="btn btn-info go-drive-landing">Drive Home</span>
             </div>' ;

    return $html;

};