<?php

Namespace View;

class LandingPageView extends \Core\View
{

    public function display($data)
    {
        $tplfunc = \Core\View::$template;
        \ISOPHP\console::log($tplfunc);
        $tpl_data = \Core\View::parse_view_template($tplfunc);
        \Core\View::execute_view_template('LandingPage', $tpl_data);
    }

}