<?php

\Core\View::$template = function () {

    $html = '';
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $paymentmethods_are_array = \ISOPHP\core::$php->is_array($rqd['paymentmethods']) ;
    if ($paymentmethods_are_array === true) {
        $paymentmethods_exist = true ;
    } else {
        $paymentmethods_exist = false ;
    }
    if ($paymentmethods_exist === true) {
        $paymentmethod_count = \ISOPHP\core::$php->count($rqd['paymentmethods']) ;
    } else {
        $paymentmethod_count = false ;
    }
    if ($paymentmethods_exist === true && $paymentmethod_count !== false) {

        $html = $html . '<h2>';
        $html = $html . '  Choose your Payment Method:<h2>';
        $html = $html . '</h2>';

        $html = $html . '<select class="form-control" name="paymentmethod_name" id="paymentmethod_name">';
        foreach ($rqd['paymentmethods'] as $one_billing_paymentmethod) {
            $html = $html . '<option value="' . $one_billing_paymentmethod['billing_paymentmethod_name'] . '">' . $one_billing_paymentmethod['billing_paymentmethod_name'] . '</option>';
        }
        $html = $html . '</select>';
    } else {

        $html = $html .
            '<select class="form-control">
                <option>There are no available Payment Methods</option>
             </select>' ;

    }

    return $html;

};
