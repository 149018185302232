<?php

Namespace Model\SVPC;

use ISOPHP\core;

class InternetGatewayPageModel extends \Model\Base
{

    public function showSVPCInternetGatewayCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'InternetGatewayCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC InternetGateway Create 2', $vars);
        };
    }

    public function showSVPCInternetGatewayList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/internetgateway/all';
            $afterwards = $this->applySVPCInternetGatewayListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCInternetGatewayListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC InternetGateway List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC InternetGateway List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'InternetGatewayList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC InternetGateway List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC InternetGateway List failed');
            }
        };
        return $func;
    }



    /**
     * Save internetgateway
     */
    public function saveInternetGatewayAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save internetgateway to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'internetgateway-create';
        $vars['api_uri'] = '/api/svpc/internetgateway/create';
        $vars['svpc_name'] = $jQuery('#svpc_name')->val();
        $vars['cidr_value'] = $jQuery('#cidr_value')->val();
        \ISOPHP\js_core::$console->log('save internetgateway vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveInternetGatewayResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveInternetGatewayResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc internetgateway result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created InternetGateway '.$request_data['internetgateway']['name'].' for SVPC '.$request_data['internetgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc internetgateway saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating InternetGateway '.$request_data['internetgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc internetgateway save failed');
            }
        };
        return $func;
    }


    /**
     * Delete internetgateway
     */
    public function deleteInternetGatewayAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete internetgateway to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'internetgateway-delete';
        $vars['api_uri'] = '/api/svpc/internetgateway/delete';
        $vars['id'] = $jQuery('#svpc_internetgateway_id')->val();
        \ISOPHP\js_core::$console->log('delete internetgateway vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveInternetGatewayResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteInternetGatewayResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc internetgateway result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted InternetGateway '.$request_data['internetgateway']['name'].' for SVPC '.$request_data['internetgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc internetgateway deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'internetgateway-list', array(), '/svpc/internetgateway/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting InternetGateway '.$request_data['internetgateway']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc internetgateway delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons Internet Gateway');
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });
                
                $go_svpc_internetgateway_list = $jQuery('.go-svpc-internetgateway-list');
                $go_svpc_internetgateway_list->off() ;
                $go_svpc_internetgateway_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'internetgateway-list', array(), '/svpc/internetgateway/list');
                });

                $go_svpc_internetgateway_create = $jQuery('.go-svpc-internetgateway-create');
                $go_svpc_internetgateway_create->off() ;
                $go_svpc_internetgateway_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'internetgateway-create', array(), '/svpc/internetgateway/create');
                });

                $save_internetgateway = $jQuery('.attempt-internetgateway-create');
                $save_internetgateway->off() ;
                $save_internetgateway->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'internetgateway-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $internetgateway_delete = $jQuery('.attempt-internetgateway-delete');
                $internetgateway_delete->off() ;
                $internetgateway_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $internetgateway_name = $jqThis->attr('data-svpc-internetgateway-name') ;
//                    $internetgateway_id = $jqThis->attr('data-svpc-internetgateway-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked internetgateway delete button');
//                    $msg = "You are about to delete the Internet Gateway for $internetgateway_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $internetgateway_id) {
//                        $navigate->route('SVPC', 'internetgateway-delete-attempt', array('internetgateway_id' => $internetgateway_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

            }
        };
    }


}