<?php

\Core\View::$template = function() {

    $greeting    = '<span>Hi, <strong>there</strong></span>' ;
    $back_button = '<span class="btn btn-warning go-home-page">Back</span>' ;
    \ISOPHP\js_core::$console->log('sc1 tpl uniter auth check');
    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
    if (isset($cookies['user_id']) && ($cookies['user_full_name'] !== '') ) {
        \ISOPHP\js_core::$console->log('sc1 summary user full name from sc1 list page') ;
        $moniker = $cookies['user_full_name'] ;
        $greeting = '<span>Hello, <strong>'.$moniker.'</strong></span>';
    }
    $html = '' ;

    if(isset($cookies['user_id'])){
        $html = '
            <link href="' . CSS_ASSET_PREFIX . 'app/Default/Assets/fontawesome/fontawesome-free-5.9.0-web/css/all.css" rel="stylesheet">
            <link rel="stylesheet" type="text/css" href="' . CSS_ASSET_PREFIX . 'app/Services/Assets/css/default.css">
            <header class="col-sm-12 navbar inside-navbar">
              <div class="col-sm-4 xs-center">            
                <img class="nav_logo service_menu_services" src="' . IMAGE_ASSET_PREFIX . 'app/Default/Assets/images/piranha-corp.png" height="80" />
              </div>
              <div class="col-sm-4 xs-center">
                <span>'. $greeting .' </span> 
                <p>Home @ Piranha Web Services</p>
              </div>
              <div class="col-sm-4 xs-center">
                <div id="user-menu-button" class="menu-button user-menu-button menu-btn-top"></div>
                <div id="user-menu-button" class="menu-button user-menu-button"></div>
                <div id="settings-menu-button" class="menu-button settings-menu-button"></div>
              </div>
            </header> ';


$html = $html . '
        <section class="latest">
          <div class="container-fluid"> 
            <div class="row">
              <div class="col-sm-12 text-center">
                <div class="latest_wrap">
                  <p>
                    <label id="user_full_name">'.$cookies['user_full_name'].'</label>
                    <span id="user_username">('.$cookies['user_username'].')</span>,
                    <label id="user_organisation_name">'.$cookies['user_organisation_name'].'</label>
                    <span id="user_organisation_id">('.$cookies['user_organisation_id'].')</span>
                  </p>
                </div> 
                
              </div>  
            </div>
          </div>
        </section>  ';

    } else {

            $html = '
            <link rel="stylesheet" type="text/css" href="'.CSS_ASSET_PREFIX.'app/SC1/Assets/css/default.css">
            <form id="pharaoh_form">
            <nav class="navbar navbar-expand-lg fixed-top nav-transparent">
              <div class="container">
                <a class="navbar-brand" href="#" style="height: 90px;">
                  <img src="/app/Default/Assets/images/piranha-corp.png" height="80">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item active">
                      <a class="nav-link" href="#">
                        Invite
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link go-register-hiring" href="/register/hiring">Hiring?</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link nav-signup go-sc1list" href="/sc1list">SC1</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link nav-signup" id="go-index-page" href="/login">Login</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>  ' ;

    }

    $main_srv_button = '<span id="back_button" class="go-services-page service_menu_services btn btn-warning">Services</span>';
    $html = $html . ' <div class="col-sm-12 service_generic_content" id="sc1_content">';
    $html = $html . '   <img class="page_loader_spinner" src="/app/Default/Assets/images/loader.gif" /> ' ;
    $html = $html . ' </div>  ' ;

    $html = $html . '    <div class="col-sm-12 back_btn_wrap">' ;
    $html = $html . $main_srv_button ;
    $html = $html . '    </div>' ;

    $html = $html . '</form>' ;

    $html = $html .'
        <section class="footer-bottom">
      <div class="container">
        <div class="row clearfix">
          <div class="col-md-3 col-12 col-xs-12 col-lg-3  xs-center">
            © 2018 Piranha Web Services
          </div>' ;

    if (CURRENT_TARGET !== 'mobile') {
        $html = $html .'
          <div class="col-md-9 col-12 col-xs-12 col-lg-9">
            <ul class="footer-links">
              <li>
                <a href="#">Innovation</a>
              </li>
              <li>
                <a class="go-sc1list" href="/sc1list">SC1</a>
              </li>
              <li>
                <a href="#">Careers</a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">Terms</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">Twitter</a>
              </li>
            </ul>
          </div> ';
    }

    $html = $html .'
        </div>
      </div>
    </section>
    ';

    return $html ;
};
