<?php

Namespace Model\SMP;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'SMP List of Piranha Web Services';
        $page['heading'] = 'List of SMP';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('SMP Mod', $page, $pageVars);
        return $page;
    }

    public function showSMPLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SMP Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SMPView();
            $view->loadTemplate('SMP', 'SMPLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displaySMP($bind);
            \ISOPHP\js_core::$console->log('apply SMP Domain Create 2', $vars);
        };
    }

    public function showSMPRecordCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SMP Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SMPView();
            $view->loadTemplate('SMP', 'RecordCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySMP($bind);
            \ISOPHP\js_core::$console->log('apply SMP Record Create 2', $vars);
        };
    }

    public function showSMPRecordList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SMP List');
            $vars['control'] = 'SMP';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/smp/record/all';
            $afterwards = $this->applySMPRecordListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SMP Model: ', $result);
            return $result;
        };
    }

    public function applySMPRecordListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SMP Record List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SMP Record List 1', $vars);
                $view = new \View\SMPView();
                $view->loadTemplate('SMP', 'RecordList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySMP($bind);
                \ISOPHP\js_core::$console->log('apply SMP Record List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SMP Record List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save record
     */
    public function saveRecordAttemptToSMP($pageVars)
    {
        \ISOPHP\js_core::$console->log('save record to smp');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SMP';
        $vars['action'] = 'record-create';
        $vars['api_uri'] = '/api/smp/record/create';
        $vars['instance_id'] = $jQuery('#vm_id')->val();
        $vars['friendly_name'] = $jQuery('#friendly_name')->val();
        $vars['target_proxy_port'] = $jQuery('#target_proxy_port')->val();
        \ISOPHP\js_core::$console->log('save record vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveRecordResultFromSMP($pageVars);
        $msg = 'Attempting to create Record '. $vars['friendly_name'];
        \Core\WindowMessage::showMessage($msg, 'good');
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRecordResultFromSMP($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save smp record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Record '.$request_data['record']['smp_record_name'].' for Domain '.$request_data['record']['smp_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('smp record saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SMP', 'record-list', array(), '/smp/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Record '.$request_data['record']['smp_domain_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('smp record save failed');
            }
        };
        return $func;
    }
    /**
     * Delete record
     */
    public function deleteRecordAttemptToSMP($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete record to smp');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SMP';
        $vars['action'] = 'record-delete';
        $vars['api_uri'] = '/api/smp/record/delete';
        $vars['id'] = $pageVars['params']['record_id'];
        \ISOPHP\js_core::$console->log('delete record vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->deleteRecordResultFromSMP($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRecordResultFromSMP($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete smp record result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Record '.$request_data['record_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('smp record deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SMP', 'record-list', array(), '/smp/record/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Record '.$request_data['record_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('smp record delete failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_smp_landing = $jQuery('.go-smp-landing');
                $go_smp_landing->off() ;
                $go_smp_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SMP', 'landing', array(), '/smp');
                });

                $go_smp_record_list = $jQuery('.go-smp-record-list');
                $go_smp_record_list->off() ;
                $go_smp_record_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SMP', 'record-list', array(), '/smp/record/list');
                });

                $go_smp_record_create = $jQuery('.go-smp-record-create');
                $go_smp_record_create->off() ;
                $go_smp_record_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SMP', 'record-create', array(), '/smp/record/create');
                });

                $save_record = $jQuery('.smp-attempt-record-create');
                $save_record->off() ;
                $save_record->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SMP', 'record-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                });

                $record_delete = $jQuery('.smp-attempt-record-delete');
                $record_delete->off() ;
                $record_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $record_name = $jqThis->attr('data-smp-record-name') ;
                    $record_id = $jqThis->attr('data-smp-record-id') ;
                    \ISOPHP\js_core::$console->log('Clicked record delete button');
                    $msg = "You are about to delete the Record for $record_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $record_id) {
                        $navigate->route('SMP', 'record-delete-attempt', array(
                            'record_id' => $record_id
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                });

            }
        };
    }


}