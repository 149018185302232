<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $registries_are_array = \ISOPHP\core::$php->is_array($rqd['registries']) ;
    if ($registries_are_array === true) {
        $registries_exist = true ;
    } else {
        $registries_exist = false ;
    }
    if ($registries_exist === true) {
        $registry_count = \ISOPHP\core::$php->count($rqd['registries']) ;
    } else {
        $registry_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcr-registry-create" class="btn btn-success go-pcr-registry-create">New Registry</span>
            <span id="go-pcr-landing" class="btn btn-info go-pcr-landing">PCR Home</span>
            <span id="go-pcr-registry-list" class="btn btn-info go-pcr-registry-list btn-refresh">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>Registry List</h4>
        </div> ' ;

    if ($registries_exist === true && $registry_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-3 col-3">
                        <h5 class="pcr_registry_id_title">ID</h5>
                    </div>
                    <div class="col-sm-4 col-4">
                        <h5 class="pcr_registry_id_title">Name</h5>
                    </div>
                    <div class="col-sm-3 col-3">
                        <h5 class="pcr_registry_id_title">Description</h5>
                    </div>
                    <div class="col-sm-2 col-2">
                        <h5 class="pcr_registry_id_title">Action</h5>
                    </div>
                 </div>';
        foreach ($rqd['registries'] as $one_pcr_registry) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_pcr_registry">
                    <div class="col-sm-3 col-3">
                        <p class="pcr_list_id"><span class="pcr_id pcr_listing">' . $one_pcr_registry['id'] . '</span></p>
                    </div>
                    <div class="col-sm-4 col-4">
                        <p class="pcr_list_name"><span class="pcr_name pcr_listing">' . $one_pcr_registry['name'] . '</span></p>
                    </div>
                    <div class="col-sm-3 col-3">
                        <p class="pcr_list_description pcr_listing">' . $one_pcr_registry['description'] . '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-6 col-6">
                                <i id="images_pcr_registry_' . $one_pcr_registry['id'] . '"
                                   class="fa fa-1x fa-history hvr-grow dom go-registry-tag-list btn btn-outline-primary"
                                   data-pcr-registry-id="' . $one_pcr_registry['id'] . '"
                                   data-pcr-registry-name="' . $one_pcr_registry['name'] . '"></i>
                            </div>
                            <div class="col-sm-6 col-6">
                                <i id="delete_pcr_registry_' . $one_pcr_registry['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-registry-delete btn btn-outline-danger"
                                   data-pcr-registry-id="' . $one_pcr_registry['id'] . '"
                                   data-pcr-registry-name="' . $one_pcr_registry['name'] . '"></i>
                            </div>
                        </div>
                    </div>                        
                 </div>';
        }

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-pcr-registry-create" class="btn btn-success go-pcr-registry-create">New Registry</span>
                <span id="go-pcr-landing" class="btn btn-info go-pcr-landing">PCR Home</span>
                <span id="go-pcr-registry-list" class="btn btn-info go-pcr-registry-list btn-refresh">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no hosted Registries</h5>
             </div>' ;

    }


    return $html;

};