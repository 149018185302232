<?php

Namespace Model\MobileDevice;

use ISOPHP\core;

class PageModel extends \Model\Base
{

    public function getPage($pageVars)
    {
        $page = array();
        $page['title'] = 'Mobile Device List of Piranha Web Services';
        $page['heading'] = 'List of Mobile Device';
        $page['user_logged'] = $pageVars['params']['user_logged'];
        \ISOPHP\js_core::$console->log('Mobile Device Mod', $page, $pageVars);
        return $page;
    }

    public function showMobileDeviceLanding($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing MobileDevice Landing');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MobileDeviceView();
            $view->loadTemplate('MobileDevice', 'MobileDeviceLanding.php', $vars);
            $bind = self::bindButtons();
            $view->displayMobileDevice($bind);
            \ISOPHP\js_core::$console->log('apply MobileDevice Device Create 2', $vars);
        };
    }

    public function showMobileDeviceDeviceCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing MobileDevice Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MobileDeviceView();
            $view->loadTemplate('MobileDevice', 'DeviceCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayMobileDevice($bind);
            \ISOPHP\js_core::$console->log('apply MobileDevice Device Create 2', $vars);
        };
    }

    public function showMobileDeviceDeviceList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing MobileDevice List');
            $vars['control'] = 'MobileDevice';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mobiledevice/device/all';
            $afterwards = $this->applyMobileDeviceDeviceListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('MobileDevice Model: ', $result);
            return $result;
        };
    }

    public function applyMobileDeviceDeviceListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our MobileDevice Device List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply MobileDevice Device List 1', $vars);
                $view = new \View\MobileDeviceView();
                $view->loadTemplate('MobileDevice', 'DeviceList.php', $vars);
                $bind = self::bindButtons();
                $view->displayMobileDevice($bind);
                \ISOPHP\js_core::$console->log('apply MobileDevice Device List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('MobileDevice Device List failed');
            }
        };
        return $func;
    }

    public function showMobileDeviceEmulatorCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mobile Device Emulator Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MobileDeviceView();
            $view->loadTemplate('MobileDevice', 'EmulatorCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayMobileDevice($bind);
            \ISOPHP\js_core::$console->log('apply Mobile Device Emulator Create 2', $vars);
        };
    }

    public function showMobileDeviceEmulatorList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing Mobile Device Emulator List');
            $vars['control'] = 'MobileDevice';
            $vars['action'] = 'emulator-list';
            $vars['api_uri'] = '/api/mobiledevice/emulator/all';
            $afterwards = $this->applyMobileDeviceEmulatorListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('MobileDevice Model: ', $result);
            return $result;
        };
    }

    public function applyMobileDeviceEmulatorListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our Mobile Device Emulator List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply Mobile Device Emulator List 1', $vars);
                $view = new \View\MobileDeviceView();
                $view->loadTemplate('MobileDevice', 'EmulatorList.php', $vars);
                $bind = self::bindButtons();
                $view->displayMobileDevice($bind);
                \ISOPHP\js_core::$console->log('apply Mobile Device Emulator List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('Mobile Device Emulator List failed');
            }
        };
        return $func;
    }

    public function showMobileDeviceDeviceListDropDown($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing MobileDevice List');
            $vars['control'] = 'MobileDevice';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mobiledevice/device/all';
            $afterwards = $this->applyMobileDeviceDeviceListDropDownResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('MobileDevice Model: ', $result);
            return $result;
        };
    }

    public function applyMobileDeviceDeviceListDropDownResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our MobileDevice Device List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply MobileDevice Device List 1', $vars);
                $view = new \View\MobileDeviceView();
                $view->loadTemplate('MobileDevice', 'DeviceListDropDown.php', $vars);
                $bind = self::bindButtons();
                $view->displayMobileDeviceDeviceDropDown($bind);
                \ISOPHP\js_core::$console->log('apply MobileDevice Device List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('MobileDevice Device List failed');
            }
        };
        return $func;
    }

    public function showMobileDeviceRecordingCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing MobileDevice Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\MobileDeviceView();
            $view->loadTemplate('MobileDevice', 'RecordingCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displayMobileDevice($bind);
            \ISOPHP\js_core::$console->log('apply MobileDevice Recording Create 2', $vars);
        };
    }

    public function showMobileDeviceRecordingList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('checking for device name');
            \ISOPHP\js_core::$console->log($pageVars['device_name']);
            \ISOPHP\js_core::$console->log('Showing MobileDevice List');
            $vars['control'] = 'MobileDevice';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/mobiledevice/recording/all';
            $vars['device_name'] = $pageVars['params']['device_name'];
            $vars['device_id'] = $pageVars['params']['device_id'];
            $afterwards = $this->applyMobileDeviceRecordingListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('MobileDevice Model: ', $result);
            return $result;
        };
    }

    public function applyMobileDeviceRecordingListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our MobileDevice Recording List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply MobileDevice Recording List 1', $vars);
                $view = new \View\MobileDeviceView();
                $view->loadTemplate('MobileDevice', 'RecordingList.php', $vars);
                $bind = self::bindButtons();
                $view->displayMobileDevice($bind);
                \ISOPHP\js_core::$console->log('apply MobileDevice Recording List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('MobileDevice Recording List failed');
            }
        };
        return $func;
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {

            \ISOPHP\js_core::$console->log('Showing Last Login');
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
            \ISOPHP\js_core::$console->log('Logged user at home summary: ', $user_logged);
            $last_login = $user_logged['last_login'];
            $jQuery = \ISOPHP\js_core::$jQuery;
            $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
            $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
            $jQuery('#latest-login-date')->html($login_date);
            $jQuery('#latest-login-time')->html($login_time);

//            \ISOPHP\js_core::$console->log('HomeSummary Account Model: ') ;
            return true;
        };
    }

    /**
     * Save device
     */
    public function saveDeviceAttemptToMobileDevice($pageVars)
    {        
        \ISOPHP\js_core::$console->log('save device to mobiledevice');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'MobileDevice';
        $vars['action'] = 'device-create';
        $vars['api_uri'] = '/api/mobiledevice/device/create';
        $vars['device_name'] = $jQuery('#device_name')->val();
        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveDeviceResultFromMobileDevice($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveDeviceResultFromMobileDevice($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save mobiledevice device result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Device '.$request_data['device']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mobiledevice device saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('MobileDevice', 'device-list', array(), '/mobiledevice/device/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Device '.$request_data['device']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mobiledevice device save failed');
            }
        };
        return $func;
    }

    /**
     * Save recording
     */
    public function saveRecordingAttemptToMobileDevice($pageVars)
    {
        \ISOPHP\js_core::$console->log('save recording to mobiledevice');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'MobileDevice';
        $vars['action'] = 'recording-create';
        $vars['api_uri'] = '/api/mobiledevice/recording/create';
        $vars['device_name'] = $jQuery('#device_name')->val();
        $vars['recording_name'] = $jQuery('#recording_name')->val();
        $vars['recording_value'] = $jQuery('#recording_value')->val();
        $vars['recording_type'] = $jQuery('#recording_type')->val();
        \ISOPHP\js_core::$console->log('save recording vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRecordingResultFromMobileDevice($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRecordingResultFromMobileDevice($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save mobiledevice recording result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Recording '.$request_data['recording']['mobiledevice_recording_name'].' for Device '.$request_data['recording']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mobiledevice recording saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Recording '.$request_data['recording']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mobiledevice recording save failed');
            }
        };
        return $func;
    }
    /**
     * Delete recording
     */
    public function deleteRecordingAttemptToMobileDevice($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete recording to mobiledevice');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'MobileDevice';
        $vars['action'] = 'recording-delete';
        $vars['api_uri'] = '/api/mobiledevice/recording/delete';
        $vars['device_name'] = $pageVars['params']['device_name'];
        $vars['recording_key'] = $pageVars['params']['recording_key'];
        $vars['recording_id'] = $pageVars['params']['recording_id'];
        \ISOPHP\js_core::$console->log('delete recording vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRecordingResultFromMobileDevice($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRecordingResultFromMobileDevice($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete mobiledevice recording result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Recording '.$request_data['recording']['mobiledevice_recording_name'].' from Device '.$request_data['recording']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mobiledevice recording deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Recording '.$request_data['recording']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mobiledevice recording delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete device
     */
    public function deleteDeviceAttemptToMobileDevice($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete device to mobiledevice');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'MobileDevice';
        $vars['action'] = 'device-delete';
        $vars['api_uri'] = '/api/mobiledevice/device/delete';
        $vars['device_name'] = $pageVars['params']['device_name'];
        \ISOPHP\js_core::$console->log('delete device vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteDeviceResultFromMobileDevice($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteDeviceResultFromMobileDevice($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete mobiledevice device result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Device '.$request_data['device']['mobiledevice_device_name'].' for Device '.$request_data['device']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('mobiledevice device deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('MobileDevice', 'device-list', array(), '/mobiledevice/device/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Device '.$request_data['device']['mobiledevice_device_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('mobiledevice device delete failed');
            }
            $show_device_list = self::showMobileDeviceDeviceList($pageVars) ;
            $show_device_list() ;
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                $go_landing_page = $jQuery('#go-landing-page');
                $go_landing_page->off() ;
                $go_landing_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('LandingPage', 'show', array(), '/');
                });

                $go_home_page = $jQuery('.go-home-page');
                $go_home_page->off() ;
                $go_home_page->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('Home', 'show', array(), '/home');
                });

                $go_mobiledevice_landing = $jQuery('.go-mobiledevice-landing');
                $go_mobiledevice_landing->off() ;
                $go_mobiledevice_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'landing', array(), '/mobiledevice');
                });

                $go_mobiledevice_device_list = $jQuery('.go-mobiledevice-device-list');
                $go_mobiledevice_device_list->off() ;
                $go_mobiledevice_device_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'device-list', array(), '/mobiledevice/device/list');
                });

                $go_mobiledevice_device_create = $jQuery('.go-mobiledevice-device-create');
                $go_mobiledevice_device_create->off() ;
                $go_mobiledevice_device_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'device-create', array(), '/mobiledevice/device/create');
                });

                $save_device = $jQuery('.attempt-device-create');
                $save_device->off() ;
                $save_device->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'device-save-attempt', array());
                });

                $go_mobiledevice_emulator_list = $jQuery('.go-mobiledevice-emulator-list');
                $go_mobiledevice_emulator_list->off() ;
                $go_mobiledevice_emulator_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'emulator-list', array(), '/mobiledevice/emulator/list');
                });

                $go_mobiledevice_emulator_create = $jQuery('.go-mobiledevice-emulator-create');
                $go_mobiledevice_emulator_create->off() ;
                $go_mobiledevice_emulator_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'emulator-create', array(), '/mobiledevice/emulator/create');
                });

                $save_emulator = $jQuery('.attempt-emulator-create');
                $save_emulator->off() ;
                $save_emulator->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'emulator-save-attempt', array());
                });

                $go_mobiledevice_recording_list = $jQuery('.go-mobiledevice-recording-list');
                $go_mobiledevice_recording_list->off() ;
                $go_mobiledevice_recording_list->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
                    $device_id = $jqThis->attr('data-mobiledevice-device-id') ;
                    \ISOPHP\js_core::$console->log('Clicked recording list button');
                    $navigate->route('MobileDevice', 'recording-list', array(
                        'device_name' => $device_name,
                        'device_id' => $device_id,
                    ), '/mobiledevice/'.$device_id.'/recording/list' );
                });

                $go_mobiledevice_recording_play = $jQuery('.go-mobiledevice-recording-play');
                $go_mobiledevice_recording_play->off() ;
                $go_mobiledevice_recording_play->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked recording play button');
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
                    $device_id = $jqThis->attr('data-mobiledevice-device-id') ;
                    $recording_id = $jqThis->attr('data-mobiledevice-recording-id') ;
                    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
                    if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
                        $token = $cookies['user_tokens'][0]['data'] ;
                    }
                    $config = new \Model\Configuration() ;
                    $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
                    $server_url .= '/api/mobiledevice/recording/play' ;
                    $server_url .= '?token='.$token ;
                    $server_url .= '&device_name='.$device_name ;
                    $server_url .= '&device_id='.$device_id ;
                    $server_url .= '&recording_id='.$recording_id ;
                    $html  = '<div class="fullWidth">' ;
                    if ($device_name !== '') {
                        $html .= '    <div class="fullWidth">' ;
                        $html .= '        <h4><strong>Device Name:</strong>'.$device_name.'</h4>' ;
                        $html .= '    </div>' ;
                    }
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <h4><strong>Device ID:</strong>'.$device_id.'</h4>' ;
                    $html .= '    </div>' ;
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <h4><strong>Recording ID:</strong>'.$recording_id.'</h4>' ;
                    $html .= '    </div>' ;
                    $html .= '    <div class="fullWidth">' ;
                    $html .= '        <video width="700" height="450" controls="controls" preload="true"> ' ;
                    $html .= '            <source src="'.$server_url.'" type="video/mp4" /> ' ;
                    $html .= '        </video> ' ;
                    $html .= '    </div>' ;
                    \Core\WindowMessage::showOverlay($html);
                    $jQuery = \ISOPHP\js_core::$jQuery;
                    $overlay_inner = $jQuery('.overlay_inner');
                    $overlay_inner->addClass('overlay_inner_large') ;
                });


                $go_mobiledevice_recording_download = $jQuery('.go-mobiledevice-recording-download');
                $go_mobiledevice_recording_download->off() ;
                $go_mobiledevice_recording_download->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked recording download button');
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
                    $device_id = $jqThis->attr('data-mobiledevice-device-id') ;
                    $recording_id = $jqThis->attr('data-mobiledevice-recording-id') ;
                    $cookies = \Model\DefaultModule\PageModel::getCookieArray() ;
                    if ( isset($cookies['user_tokens']) && (\ISOPHP\core::$php->strlen($cookies['user_tokens']) > 0) ) {
                        $token = $cookies['user_tokens'][0]['data'] ;
                    }
                    $config = new \Model\Configuration() ;
                    $server_url  = $config->variable('ISOPHP_API_SERVER_URL') ;
                    $server_url .= '/api/mobiledevice/recording/play' ;
                    $server_url .= '?token='.$token ;
                    $server_url .= '&device_name='.$device_name ;
                    $server_url .= '&device_id='.$device_id ;
                    $server_url .= '&recording_id='.$recording_id ;
                    $slug = $device_id.'_'.$recording_id ;
                    $dl_el_id = 'download_link_'.$slug ;
                    $html  = '<a target="_blank" id="'.$dl_el_id.'" href="'.$server_url.'" type="hidden" download>'.$slug.'.mp4</a>' ;
                    \ISOPHP\js_core::$console->log('Recording download html', $html);
                    $body_jq = $jQuery('body') ;
                    $body_jq->append($html) ;
                    $dl_el_js = \ISOPHP\js_core::$window->document->getElementById($dl_el_id) ;
                    $dl_el_js->click() ;
                    \ISOPHP\js_core::$console->log('$dl_el_js', $dl_el_js);
                    \ISOPHP\js_core::$console->log('dl id', $dl_el_id);
//                    $dl_el_jq->remove() ;
                });

                $go_mobiledevice_recording_create = $jQuery('.go-mobiledevice-recording-create');
                $go_mobiledevice_recording_create->off() ;
                $go_mobiledevice_recording_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'recording-create', array(), '/mobiledevice/recording/create');
                });

                $save_recording = $jQuery('.attempt-recording-create');
                $save_recording->off() ;
                $save_recording->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('MobileDevice', 'recording-save-attempt', array());
                });

                $recording_delete = $jQuery('.attempt-recording-delete');
                $recording_delete->off() ;
                $recording_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $recording_name = $jqThis->attr('data-mobiledevice-recording-name') ;
                    $recording_id = $jqThis->attr('data-mobiledevice-recording-id') ;
                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
                    \ISOPHP\js_core::$console->log('Clicked recording delete button');
                    $msg = "You are about to delete the Recording for $recording_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $recording_id, $recording_name, $device_name) {
                        $navigate->route('MobileDevice', 'recording-delete-attempt', array(
                            'recording_id' => $recording_id,
                            'recording_name' => $recording_name,
                            'device_name' => $device_name,
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

                $device_delete = $jQuery('.attempt-device-delete');
                $device_delete->off() ;
                $device_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $device_name = $jqThis->attr('data-mobiledevice-device-name') ;
                    \ISOPHP\js_core::$console->log('Clicked device delete button');
                    $msg = "You are about to delete the Device $device_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $device_name) {
                        $navigate->route('MobileDevice', 'device-delete-attempt', array(
                            'device_name' => $device_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;

            }
        };
    }

    public function bindDeviceListDropDownButtonsForRecordingList()
    {
        \ISOPHP\js_core::$console->log('Started binding device switch');
        $func = function () {

            $navigate = new \Model\Navigate();
            $jQuery = \ISOPHP\js_core::$jQuery;

            \ISOPHP\js_core::$console->log('Binding device switch');
            $switch_device = $jQuery('#device_name');
            $switch_device->off() ;
            $switch_device->on('change', function () use ($navigate, $jQuery) {
                $jqThis = $jQuery('#device_name') ;
                $device_name = $jqThis->val() ;
                \ISOPHP\js_core::$console->log('Clicked device switch');
                $navigate->route('MobileDevice', 'recording-list', array(
                    'device_name' => $device_name,
                ));
            } ) ;

        };
        return $func;
    }



}