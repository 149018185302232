<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $vpcs_are_array = \ISOPHP\core::$php->is_array($rqd['vpcs']) ;
    if ($vpcs_are_array === true) {
        $vpcs_exist = true ;
    } else {
        $vpcs_exist = false ;
    }
    if ($vpcs_exist === true) {
        $svpc_count = \ISOPHP\core::$php->count($rqd['vpcs']) ;
    } else {
        $svpc_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-svpc-vpc-create" class="btn btn-success go-svpc-vpc-create disabled">Create VPC</span>
            <span id="go-svpc-landing" class="btn btn-info go-svpc-landing">SVPC Home</span>
            <span id="go-svpc-list" class="btn-refresh btn btn-info go-svpc-vpc-list">
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>
        
        <div class="col-sm-12 col-12">
            <h4>VPC List</h4>
         </div> ' ;

    if ($vpcs_exist === true && $svpc_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                        <div class="col-sm-2 col-2">
                            <h5 class="id_title">ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="id_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="id_title">Subnets</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="id_title">CIDR</h5>
                        </div>                    
                        <div class="col-sm-1 col-1">
                            <h5 class="id_title">State</h5>
                        </div>                    
                        <div class="col-sm-1 col-1">
                            <h5 class="id_title">Default</h5>
                        </div>                    
                        <div class="col-sm-2 col-2">
                            <h5 class="id_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['vpcs'] as $one_svpc_vpc) {
            $html = $html .
                '<div class="col-sm-12 col-12 one_svpc_vpc">
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_vpc['id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_name"><span class="svpc_name svpc_listing">' . $one_svpc_vpc['name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">' ;

                    if (isset($one_svpc_vpc['subnets'])) {
                        if (\ISOPHP\core::$php->is_array($one_svpc_vpc['subnets'])) {
                            foreach ($one_svpc_vpc['subnets'] as $one_vpc_subnet) {
                                $html = $html . '<p class="svpc_list_svpc_count svpc_listing">' ;
                                $html = $html . '    <strong>' . $one_vpc_subnet['id'] .'</strong> : ' ;
                                $html = $html . '</p>' ;
                            }
                        }
                    }

            $html = $html .
                   '</div>
                    <div class="col-sm-2 col-2">
                        <p class="svpc_list_id"><span class="svpc_id svpc_listing">' . $one_svpc_vpc['cidr'] . '</span></p>
                    </div>     
                    <div class="col-sm-1 col-1">
                        <p class="svpc_list_svpc_count svpc_listing">' . $one_svpc_vpc['state'] . '</p>
                    </div>      
                    <div class="col-sm-1 col-1">
                        <p class="svpc_list_svpc_idle svpc_listing">' ;

                    if ($one_svpc_vpc['default'] == true) {
                        $html = $html . '<strong>Yes</strong>' ;
                    } else {
                        $html = $html . 'No' ;
                    }

            $html = $html .
                       '</p>
                    </div>     
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12"> ';



            if ($one_svpc_vpc['default'] !== true) {
                        $html = $html .
                            '<div class="col-sm-6 col-6">
                                <i id="edit_svpc_vpc_' . $one_svpc_vpc['id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-svpc-edit btn btn-outline-primary"
                                   data-svpc-vpc-id="' . $one_svpc_vpc['id'] . '"
                                   data-svpc-vpc-name="' . $one_svpc_vpc['name'] . '"></i>
                             </div> ';
                        $html = $html .
                            '<div class="col-sm-6 col-6">
                                <i id="delete_svpc_vpc_' . $one_svpc_vpc['id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-svpc-delete btn btn-outline-danger"
                                   data-svpc-vpc-id="' . $one_svpc_vpc['id'] . '"
                                   data-svpc-vpc-name="' . $one_svpc_vpc['name'] . '"></i>
                             </div>';
                    }

            $html = $html .
                       '
                         </div>
                    </div>                        
                 </div>';
        }

        $html = $html .
            '<div class="col-sm-12 col-12 text-center">
                <span id="go-svpc-vpc-create" class="btn btn-success go-svpc-vpc-create disabled">Create VPC</span>
                <span id="go-svpc-landing" class="btn btn-info go-svpc-landing">SVPC Home</span>
                <span id="go-svpc-list" class="btn-refresh btn btn-info go-svpc-vpc-list">
                    <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
                </span>
            </div>' ;

    } else {

        $html = $html .
            '<div class="col-sm-12">
                <h5>There are no available VPCs</h5>
             </div>' ;

    }

    return $html;

};