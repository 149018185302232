<?php

Namespace Controller;

class DNSController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('DNS controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In DNS, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\DNS\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);
            \ISOPHP\js_core::$console->log('DNS List pagevars', $pageVars);
            \ISOPHP\js_core::$console->log('Yes Here is action params....:', $pageVars['route']['action']);
            \ISOPHP\js_core::$window->document->title = $page['title'];
            $res = new \Controller\Result();

            $res->page = $page;
            $res->view = 'DNS.php';
            $res->type = 'view';
            $res->view_control = 'DNS';
            $res->post_template = array();

            if ($pageVars['route']['action'] == 'landing') {
                $res->post_template[] = $page_model->showDNSLanding($pageVars);
            } else if ($pageVars['route']['action'] == 'domain-list') {
                $res->post_template[] = $page_model->showDNSDomainList($pageVars);
            } else if ($pageVars['route']['action'] == 'domain-create') {
                $res->post_template[] = $page_model->showDNSDomainCreate($pageVars);
            } else if ($pageVars['route']['action'] == 'record-list') {
                $res->post_template[] = $page_model->showDNSRecordList($pageVars);
            } else if ($pageVars['route']['action'] == 'record-create') {
                $res->post_template[] = $page_model->showDNSRecordCreate($pageVars);
                $res->post_template[] = $page_model->showDNSDomainListDropDown($pageVars);
            } else if ($pageVars['route']['action'] == 'domain-save-attempt') {
                $page_model->saveDomainAttemptToDNS($pageVars);
                $res->view = 'DNS.php';
                $res->control = 'DNS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'record-save-attempt') {
                $page_model->saveRecordAttemptToDNS($pageVars);
                $res->view = 'DNS.php';
                $res->control = 'DNS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'domain-delete-attempt') {
                $page_model->deleteDomainAttemptToDNS($pageVars);
                $res->view = 'DNS.php';
                $res->control = 'DNS';
                $res->type = null;
                return $res;
            } else if ($pageVars['route']['action'] == 'record-delete-attempt') {
                $page_model->deleteRecordAttemptToDNS($pageVars);
                $res->view = 'DNS.php';
                $res->control = 'DNS';
                $res->type = null;
                return $res;
            } else {
                $res->post_template[] = $page_model->showDNSLanding($pageVars);
            }

            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            \ISOPHP\js_core::$console->log('DNS Con', $res);
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}