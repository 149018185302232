<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $keypairs_are_array = \ISOPHP\core::$php->is_array($rqd['keypairs']) ;
    if ($keypairs_are_array === true) {
        $keypairs_exist = true ;
    } else {
        $keypairs_exist = false ;
    }
    if ($keypairs_exist === true) {
        $keypair_count = \ISOPHP\core::$php->count($rqd['keypairs']) ;
    } else {
        $keypair_count = false ;
    }

    $html = $html . '<select class="form-control" name="keypair_id" id="keypair_id">';
    $html = $html . '<option value="none">No Keypair</option>' ;
    if ($keypairs_exist === true && $keypair_count !== false) {
        foreach ($rqd['keypairs'] as $one_sc1_keypair) {
            $html = $html . '<option value="' . $one_sc1_keypair['id'] . '" ' ;
            if (isset($pv['keypair_id_preselected']) && ($pv['keypair_id_preselected'] === $one_sc1_keypair['id']) ) {
                $html = $html . ' selected="selected"' ;
            }
            $html = $html . '>' ;
            $html = $html . $one_sc1_keypair['name'] ;
            if (isset($one_sc1_keypair['description'])) {
                $html = $html . ' - ' . $one_sc1_keypair['description'] ;
            }
            $html = $html . '</option>';
        }
    } else {
        $html = $html . '<option value="none">There are no available Keypairs</option>' ;
    }
    $html = $html . '</select>';

    return $html;

};