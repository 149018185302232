<?php

Namespace Model\SAM;

use ISOPHP\console;
use ISOPHP\js_core;
use \Model\Navigate;

class UserAccountPageModel extends \Model\Base
{

    public function applySAMUserAccountResult($pageVars) {

        $func = function ($request_data) use ($pageVars) {

            $page = array();
//        $page['title'] = 'Piranha Web Services';
//        $page['heading'] = 'Piranha Web Services Home Page';
//        $page['user_logged'] = $pageVars['params']['user_logged']['user'];

            $config = new \Model\Configuration();
            $server_url = $config->variable('ISOPHP_API_SERVER_URL');

            $user_logged = \ISOPHP\core::$registry->getValue('user_logged');
            $user = $user_logged['user'];
            $user_uploads = $user_logged['user_uploads'];
            $user_details = $user_logged['user_details'];

            $data = array();
            $data['user_id'] = (isset($user['id']) && $user['id'] !== "") ? $user['id'] : '';
            $data['user_type'] = (isset($user['id']) && $user['id'] !== "") ? $user['user_type'] : '';
            $data['user_email'] = (isset($user['id']) && $user['id'] !== "") ? $user['email'] : '';
            $data['user_pwd'] = (isset($user['id']) && $user['id'] !== "") ? $user['pwd'] : '';
            $data['user_full_name'] = (isset($user['id']) && $user['id'] !== "") ? $user['full_name'] : 'there';
            $data['user_phone_number'] = (isset($user['id']) && $user['id'] !== "") ? $user['phone_number'] : '';
            $data['user_company_name'] = (isset($user['id']) && $user['id'] !== "") ? $user['company_name'] : '';
            $data['user_email_subscription'] = (isset($user['id']) && $user['id'] !== "") ? $user['email_subscription'] : '';
            $data['user_address'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['address'] : '';
            $data['user_dob'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['date_of_birth'] : '';
            $data['user_gender'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['gender'] : '';
            $data['year_experience'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['year_experience'] : '';
            $data['month_experience'] = (isset($user_details) && $user_details['user_id'] == $data['user_id']) ? $user_details['month_experience'] : '';
            $data['user_profile_photo_src'] = '' ;
            $data['user_resume_file_src'] = '' ;
            $data['site_url'] = $server_url;

            if ($user_uploads) {
                foreach ($user_uploads as $each_upload) {
                    if ($each_upload['upload_tag'] == 'profile_photo') {
                        $data['user_profile_photo_src'] = ($each_upload['file_name'] !== "") ? $server_url . "/" . $each_upload['file_location'] : '';
                    }
                    if ($each_upload['upload_tag'] == 'resume_file') {
                        $data['user_resume_file_src'] = ($each_upload['file_name'] !== "") ? $server_url . "/" . $each_upload['file_location'] : '';
                    }
                }
            }

            $page['user_data'] = $data;
            \ISOPHP\js_core::$console->log('I am on homepagemodel', $page, $pageVars);
        } ;
        return $func ;

    }

    public function showSAMUserAccount($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SAM Account');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SAMView();
            $view->loadTemplate('SAM', 'UserAccount.php', $vars);
            $bind_first = self::bindButtons();
            $bind_second = $this->populateSAMUserAccount($pageVars) ;
            $bind = function () use ($bind_first, $bind_second) {
                $bind_first() ;
                $bind_second() ;
            } ;
            $view->displaySAM($bind);
            \ISOPHP\js_core::$console->log('apply SAM User Account 2', $vars);
        };
    }

    public function populateSAMUserAccount($pageVars) {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Loading SAM User Account');
            $vars['control'] = 'SAM';
            $vars['action'] = 'user';
            $vars['api_uri'] = '/api/sam/user/account';
            $user_logged = \ISOPHP\core::$registry->getValue('user_logged');
            $user = $user_logged['user'];
            $vars['id'] = $user['id'] ;
            $afterwards = $this->applySAMUserAccountResult($pageVars);
            $result = $this->performRequest($vars, $afterwards);
            \ISOPHP\js_core::$console->log('SAM Model: ', $result);
            return $result;
        };
    }

    public function showLastLogin($pageVars)
    {
        return function () use ($pageVars) {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                \ISOPHP\js_core::$console->log('Showing Last Login');
                $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
                \ISOPHP\js_core::$console->log('Logged user at home: ', $user_logged);
                $last_login = $user_logged['last_login'];
                $jQuery = \ISOPHP\js_core::$jQuery;
                $login_date = \ISOPHP\core::$php->date('l, d/m/Y', $last_login);
                $login_time = \ISOPHP\core::$php->date('H:i:s', $last_login);
                $jQuery('#latest-login-date')->html($login_date);
                $jQuery('#latest-login-time')->html($login_time);
                return true;
            }
        };
    }

    public static function bindButtons()
    {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $jQuery = \ISOPHP\js_core::$jQuery;
                $navigate = new \Model\Navigate();

                $logout = $jQuery('#logout');
                $save_employer = $jQuery('#go-save-employer');

                $skill = $jQuery('#skill');
                $candidate_gender = $jQuery('#candidate_gender');
                $skill->select2();
                $candidate_gender->select2();

                $logout->on('click', function () {
                    $navigate = new \Model\Navigate();
                    $navigate->route('Logout', 'now', array());
                });

                $save_employer->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SAM', 'employer-save-attempt', array());
                });

                $skill->on('change', function ($jqThis) use ($jQuery, $skill, $navigate) {
                    $jqThis->preventDefault();
                    $skill_id = $skill->val();
                    \ISOPHP\js_core::$console->log("Skill on change value", $skill->val());
                    $navigate->route('Home', 'add-skill', array('skill_id' => $skill_id));
                });

            }
        };
    }

    public function applyGetSkillResult($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($pageVars, $jQuery) {
            if ($request_data) {
                $skill = $request_data;
                $skillSelect = '<option >Select your skill</option>';
                if ($skill) {
                    foreach ($skill as $RowData) {
                        $skillSelect = $skillSelect . '<option value="' . $RowData['id'] . '">' . $RowData['skill_name'] . '</option>';
                    }
                }
                $jQuery('#skill')->html($skillSelect);
            }
        };
        return $func;
    }

    public function getEmployerSaveResult($pageVars)
    {
        $vars['control'] = 'Employer';
        $vars['action'] = 'employer-save-attempt';
        $vars['api_uri'] = '/api/employer/save';

        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];

        # profile data
        $vars['email'] = $jQuery('#email')->val();
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['phone_number'] = $jQuery('#phone_number')->val();
        $vars['company_name'] = $jQuery('#company_name')->val();
        $vars['upload_tag_profile'] = $jQuery('#profile_upload_tag')->val();
        $vars['file_name_profile'] = $jQuery('#profile_file_name')->val();


        # additional data
        $vars['address'] = $jQuery->trim($jQuery('#address')->val());

        \ISOPHP\js_core::$console->log("Form Validation ignored.");

        $form_valid = $this->validateFormFields($vars);
        if ($form_valid !== true) {
            $message = 'Invalid Employer Form';
            \Core\WindowMessage::showMessage($message, 'bad');
            return false;
        }
        if ($form_valid === true) {
            $message = 'Valid Employer Form - Trying now...';
            \Core\WindowMessage::showMessage($message, 'good');
            #return true;
        }
        $afterwards = $this->applySaveResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applySaveResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            if ($request_data['status'] === 'OK') {
                $navigate = new \Model\Navigate();

                $welcome_message = "Successfully saved";
                $extra_params['user_logged']['user'] = $request_data['user'];
                $extra_params['user_logged']['user_details'] = $request_data['user_details'];
                $extra_params['user_logged']['user_uploads'] = $request_data['user_uploads'];

                $registry = \ISOPHP\core::$registry;
                \ISOPHP\js_core::$console->log('registry is ', $registry);
                \ISOPHP\js_core::$console->log('logged user to registry is ', $extra_params['user_logged']);
                $registry::setValue('user_logged', $extra_params['user_logged']);

                \Core\WindowMessage::showMessage($welcome_message, 'good');

                $navigate->route('Home', 'show', $extra_params, '/home');

            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data->error;
                \ISOPHP\js_core::$console->log('request not ok: ', $request_data);
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }


    public function getCandidateSaveResult($pageVars)
    {
        $vars['control'] = 'Candidate';
        $vars['action'] = 'candidate-save-attempt';
        $vars['api_uri'] = '/api/candidates-profile/save';

        $jQuery = \ISOPHP\js_core::$jQuery;
        $user = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = $user['id'];

        # profile data
        $vars['email'] = $jQuery('#email')->val();
        $vars['full_name'] = $jQuery('#full_name')->val();
        $vars['phone_number'] = $jQuery('#phone_number')->val();
        $vars['upload_tag_profile'] = $jQuery('#profile_upload_tag')->val();
        $vars['file_name_profile'] = $jQuery('#profile_file_name')->val();

        # additional data
        $vars['address'] = $jQuery('#candidate_address')->val();
        $vars['date_of_birth'] = $jQuery('#candidate_date_of_birth')->val();
        $vars['gender'] = $jQuery('#candidate_gender')->val();
        $vars['year_exp'] = $jQuery('#year_experience')->val();
        $vars['month_exp'] = $jQuery('#month_experience')->val();

        #skill

        $skills = $jQuery('#skill')->val();

        $php = \ISOPHP\core::$php;
        \ISOPHP\js_core::$console->log("Skill", $php->json_encode($skills));
        $vars['skills'] = $php->json_encode($skills);
        \ISOPHP\js_core::$console->log("Skill", $skills);

        \ISOPHP\js_core::$console->log("Var all values Value" . $vars);

        # resume data
        $vars['upload_tag_resume'] = $jQuery('#resume_upload_tag')->val();
        $vars['file_name_resume'] = $jQuery('#resume_file_name')->val();


        $form_valid = $this->validateFormFields($vars);
        if ($form_valid !== true) {
            $message = 'Invalid Candidate Form';
            \Core\WindowMessage::showMessage($message, 'bad');
            return false;
        }

        if ($form_valid === true) {
            $message = 'Valid Candidate Form - Trying now...';
            \Core\WindowMessage::showMessage($message, 'good');
        }

        \ISOPHP\js_core::$console->log('Cadndidate Con attempt action................... 111');


        $afterwards = $this->applyCandidateSaveResult($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function applyCandidateSaveResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            if ($request_data['status'] === 'OK') {
                $navigate = new \Model\Navigate();

                $welcome_message = "Successfully saved";

                $extra_params['user_logged']['user'] = $request_data['user'];
                $extra_params['user_logged']['user_uploads'] = $request_data['user_uploads'];
                $extra_params['user_logged']['user_details'] = $request_data['user_details'];

                $registry = \ISOPHP\core::$registry;
                \ISOPHP\js_core::$console->log('registry is ', $registry);
                \ISOPHP\js_core::$console->log('logged user to registry is ', $extra_params['user_logged']);

                $registry::setValue('user_logged', $extra_params['user_logged']);

                $navigate->route('Home', 'show', $extra_params, '/home');

                \Core\WindowMessage::showMessage($welcome_message, 'good');
            } else if ($request_data['status'] !== 'OK') {
                $welcome_message = $request_data['error'];
                \Core\WindowMessage::showMessage($welcome_message, 'bad');
            }
        };
        return $func;
    }

    /**
     * Common Form validation
     * Add validation for new field here
     * for input value validation use \ISOPHP\core::$locutuspcre->preg_match
     */
    public function validateFormFields($vars)
    {
        /* email address validation */
        if (array_key_exists('email', $vars)) {
            $emailRegex = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
            $emailMatch = \ISOPHP\core::$locutuspcre->preg_match($emailRegex, $vars['email']);
            if (!$emailMatch) {
                $error_message = 'Please enter a valid email address.';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        /*Will be added more validation for phone number*/
        if (array_key_exists('phone_number', $vars)) {
            $phone_number = \ISOPHP\core::$php->strlen($vars['phone_number']);
            if ($phone_number < 8) {
                $error_message = 'Phone field must be 8 digits and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
            $phoneRegex = '^[0-9-+s()]*$';
            $phoneMatch = \ISOPHP\core::$locutuspcre->preg_match($phoneRegex, $vars['phone_number']);
            if (!$phoneMatch) {
                $error_message = 'Phone field must be numeric';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        if (array_key_exists('address', $vars)) {
            $address_length = \ISOPHP\core::$php->strlen($vars['address']);
            if ($address_length < 3) {
                $error_message = 'Address field must be at least 3 characters and not empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        /*Will be added more validation for date of birth*/
        if (array_key_exists('date_of_birth', $vars)) {
            $date_of_birth = $vars['date_of_birth'];
            if ($date_of_birth == '') {
                $error_message = 'Date field can not be empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        if (array_key_exists('gender', $vars)) {
            $gender = $vars['gender'];
            if ($gender == '') {
                $error_message = 'Gender field can not be empty';
                \Core\WindowMessage::showMessage($error_message, 'bad');
                return false;
            }
        }

        $integerRegex = '^[0-9-+s()]*$';

        if (array_key_exists('year_exp', $vars)) {
            $year_exp = $vars['year_exp'];

            if ($year_exp != '') {


                $yearMatch = \ISOPHP\core::$locutuspcre->preg_match($integerRegex, $year_exp);

                if (!$yearMatch) {
                    $error_message = 'Year of experience field must be numeric';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }

                if ($year_exp <= 0) {
                    $error_message = 'Year of experience field must be greater than 0';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }
            }
        }


        if (array_key_exists('month_exp', $vars)) {
            $month_exp = $vars['month_exp'];

            if ($month_exp != '') {


                $monthMatch = \ISOPHP\core::$locutuspcre->preg_match($integerRegex, $month_exp);
                if (!$monthMatch) {
                    $error_message = 'Month of experience field must be numeric';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }

                if ($month_exp <= 0 || $month_exp > 12) {
                    $error_message = 'Month of experience field must be fewer than 13 and greater than 0';
                    \Core\WindowMessage::showMessage($error_message, 'bad');
                    return false;
                }
            }


        }

        return true;
    }


}