<?php

Namespace Model\SVPC;

use ISOPHP\core;

class RouteTablePageModel extends \Model\Base
{

    public function showSVPCRouteTableCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SVPCView();
            $view->loadTemplate('SVPC', 'RouteTableCreate.php', $vars);
            $bind = self::bindButtons();
            $view->displaySVPC($bind);
            \ISOPHP\js_core::$console->log('apply SVPC Route Table Create 2', $vars);
        };
    }

    public function showSVPCRouteTableList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SVPC List');
            $vars['control'] = 'SVPC';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/svpc/routetable/all';
            $afterwards = $this->applySVPCRouteTableListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SVPC Model: ', $result);
            return $result;
        };
    }

    public function applySVPCRouteTableListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SVPC Route Table List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SVPC Route Table List 1', $vars);
                $view = new \View\SVPCView();
                $view->loadTemplate('SVPC', 'RouteTableList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySVPC($bind);
                \ISOPHP\js_core::$console->log('apply SVPC Route Table List 2', $vars);
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SVPC Route Table List failed');
            }
        };
        return $func;
    }



    /**
     * Save routetable
     */
    public function saveRouteTableAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('save routetable to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'routetable-create';
        $vars['api_uri'] = '/api/svpc/routetable/create';
        $vars['svpc_name'] = $jQuery('#svpc_name')->val();
        $vars['cidr_value'] = $jQuery('#cidr_value')->val();
        \ISOPHP\js_core::$console->log('save routetable vars');
        \ISOPHP\js_core::$console->log($vars);
        $afterwards = $this->saveRouteTableResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveRouteTableResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save svpc routetable result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Route Table '.$request_data['routetable']['svpc_routetable_name'].' for SVPC '.$request_data['routetable']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc routetable saved, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'vpc-list', array(), '/svpc/vpc/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Route Table '.$request_data['routetable']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc routetable save failed');
            }
        };
        return $func;
    }


    /**
     * Delete routetable
     */
    public function deleteRouteTableAttemptToSVPC($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete routetable to svpc');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SVPC';
        $vars['action'] = 'routetable-delete';
        $vars['api_uri'] = '/api/svpc/routetable/delete';
        $vars['id'] = $jQuery('#svpc_routetable_id')->val();
        \ISOPHP\js_core::$console->log('delete routetable vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveRouteTableResultFromSVPC($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteRouteTableResultFromSVPC($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete svpc routetable result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Route Table '.$request_data['routetable']['svpc_routetable_name'].' for SVPC '.$request_data['routetable']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('svpc routetable deleted, data: ', $request_data);
                $navigate = new \Model\Navigate();
                $navigate->route('SVPC', 'routetable-list', array(), '/svpc/routetable/list');
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Route Table '.$request_data['routetable']['svpc_vpc_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('svpc routetable delete failed');
            }
        };
        return $func;
    }


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('Binding SVPC Buttons Route Table');
                
                $go_svpc_landing = $jQuery('.go-svpc-landing');
                $go_svpc_landing->off() ;
                $go_svpc_landing->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SVPC', 'landing', array(), '/svpc');
                });
                
                $go_svpc_routetable_list = $jQuery('.go-svpc-routetable-list');
                $go_svpc_routetable_list->off() ;
                $go_svpc_routetable_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'routetable-list', array(), '/svpc/routetable/list');
                });

                $go_svpc_routetable_create = $jQuery('.go-svpc-routetable-create');
                $go_svpc_routetable_create->off() ;
                $go_svpc_routetable_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'routetable-create', array(), '/svpc/routetable/create');
                });

                $save_routetable = $jQuery('.attempt-routetable-create');
                $save_routetable->off() ;
                $save_routetable->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
//                    $navigate->route('SVPC', 'routetable-save-attempt', array());
                });

                $instructions_switch = $jQuery('.switch_instruction_set');
                $instructions_switch->off() ;
                $instructions_switch->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $id = $jsthis->target->id ;
                    $new_id = str_replace('switch_', '', $id) ;
                    $instruction_set = $jQuery("#" . $new_id) ;
                    $jq_switch = $jQuery("#" . $id) ;
                    if ($instruction_set->css('display') == 'none') {
                        $jq_switch->removeClass("fa-toggle-off");
                        $jq_switch->addClass("fa-toggle-on");
                        $instruction_set->slideDown();
                    } else {
                        $jq_switch->removeClass("fa-toggle-on");
                        $jq_switch->addClass("fa-toggle-off");
                        $instruction_set->slideUp();
                    }
                } ) ;

                $routetable_delete = $jQuery('.attempt-routetable-delete');
                $routetable_delete->off() ;
                $routetable_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {

                    $jsthis->preventDefault();
//                    $this_id = $jsthis->currentTarget->id;
//                    $jqThis = $jQuery('#'.$this_id) ;
//                    $routetable_name = $jqThis->attr('data-svpc-routetable-name') ;
//                    $routetable_id = $jqThis->attr('data-svpc-routetable-id') ;
//                    \ISOPHP\js_core::$console->log('Clicked routetable delete button');
//                    $msg = "You are about to delete the Network ACL for $routetable_name. This cannot be undone.<br>Are you sure ?";
//                    $confirmAction = function () use ($navigate, $routetable_id) {
//                        $navigate->route('SVPC', 'routetable-delete-attempt', array('routetable_id' => $routetable_id));
//                    };
//                    \Core\WindowMessage::areYouSure($msg, $confirmAction);

                } ) ;

            }
        };
    }


}