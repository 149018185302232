<?php

Namespace Model\SC1;

use ISOPHP\core;

class ImagePageModel extends \Model\Base
{

    public function showSC1ImageList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Image List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/image/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1ImageListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Image List Model: ', $result);
            return $result;
        };
    }

    public function applySC1ImageListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 Image List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 Image List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'ImageList.php', $vars);
                $bind = self::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 Image List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 Image List failed');
            }
        };
        return $func;
    }

    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding images buttons');

                $go_sc1_image_list = $jQuery('.go-sc1-image-list');
                $go_sc1_image_list->off() ;
                $go_sc1_image_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'image-list', array(), '/sc1/image/list');
                });

                $go_sc1_image_list_page = $jQuery('.sc1-image-paginator');
                $go_sc1_image_list_page->off() ;
                $go_sc1_image_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 image paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'image-list', array('page' => $new_page), '/sc1/image/list/page/'.$new_page);
                });

                $go_sc1_image_create = $jQuery('.go-sc1-image-create');
                $go_sc1_image_create->off() ;
                $go_sc1_image_create->on('click', function ($jqThis) use ($navigate) {
//                    $jqThis->preventDefault();
//                    $navigate->route('SC1', 'image-create', array(), '/sc1/image/create');
                });

                $save_image = $jQuery('.attempt-image-create');
                $save_image->off() ;
                $save_image->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'image-save-attempt', array());
                });

                $image_delete = $jQuery('.attempt-image-delete');
                $image_delete->off() ;
                $image_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $image_name = $jqThis->attr('data-sc1-image-name') ;
                    $image_id = $jqThis->attr('data-sc1-image-id') ;
                    \ISOPHP\js_core::$console->log('Clicked image delete button');
                    $msg = "You are about to delete the Instance $image_name. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $image_id, $image_name) {
                        $navigate->route('SC1', 'image-delete-attempt', array('image_id' => $image_id, 'image_name' => $image_name));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        };
    }


}