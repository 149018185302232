<?php

Namespace Controller;

class HomeController extends \Controller\Base
{

    public function execute($pageVars)
    {

        if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {

            \ISOPHP\js_core::$console->log('Home controller uniter auth check');
            $user_is_authorised = \Model\DefaultModule\PageModel::userIsAuthenticated();
            if ($user_is_authorised === false) {
                \ISOPHP\js_core::$console->log('In Home, User is not authorised');
                $navigate = new \Model\Navigate();
                $navigate->route('LandingPage', 'show', array(), '/');
                return true;
            }

            $page_model = new \Model\Home\PageModel();
            $default_page_model = new \Model\DefaultModule\PageModel();
            $page = $page_model->getPage($pageVars);


            $res = new \Controller\Result();
            if ($pageVars['route']['action'] == 'employer-save-attempt') {
                $data = $page_model->getEmployerSaveResult($pageVars);
                \ISOPHP\js_core::$console->log('Employer Save Attempt Result', $data);
                $res->type = null; # no type means no further action
                \ISOPHP\js_core::$console->log('Register Con attempt action', $res);
                return $res;
            }

            if ($pageVars['route']['action'] == 'candidate-save-attempt') {
                $data = $page_model->getCandidateSaveResult($pageVars);
                \ISOPHP\js_core::$console->log('Cadndidate Save Attempt Result');
                $res->type = null; # no type means no further action
                \ISOPHP\js_core::$console->log('Cadndidate Con attempt action', $res);
                return $res;
            }

            if ($pageVars['route']['action'] == 'add-education') {
                $data = $page_model->addCandidateEducation($pageVars);
                \ISOPHP\js_core::$console->log('Add Candidate Education 1');
                $res->type = null; # no type means no further action
                return $res;
            }

            if ($pageVars['route']['action'] == 'add-certificate') {
                $data = $page_model->addCandidatCertificate($pageVars);
                \ISOPHP\js_core::$console->log('Add certificate certificate 1');
                $res->type = null; # no type means no further action
                return $res;
            }

            if ($pageVars['route']['action'] == 'remove-education') {
                $data = $page_model->removeCandidateEducation($pageVars);
                \ISOPHP\js_core::$console->log('Remove Candidate Education 1');
                $res->type = null; # no type means no further action
                return $res;
            }

            if ($pageVars['route']['action'] == 'remove-certificate') {
                $data = $page_model->removeCandidateCertificate($pageVars);
                \ISOPHP\js_core::$console->log('Remove Candidate certificate 1');
                $res->type = null; # no type means no further action
                return $res;
            }
            if ($pageVars['route']['action'] == 'add-skill') {
                $data = $page_model->addSkill($pageVars);
                \ISOPHP\js_core::$console->log('Remove Candidate certificate 1');
                $res->type = null; # no type means no further action
                return $res;
            }

            if ($pageVars['route']['action'] == 'remove-skill') {
                $data = $page_model->removeSkill($pageVars);
                \ISOPHP\js_core::$console->log('Remove Candidate certificate 1');
                $res->type = null; # no type means no further action
                return $res;
            }

            $res->page = $page;
            $res->view = 'Home.php';
            $res->type = 'view';
            $res->view_control = 'Home';
            $res->post_template = array();
            $res->post_template[] = $page_model->showLastLogin($pageVars);
            $res->post_template[] = $page_model->getUserData($pageVars);
            $res->post_template[] = $page_model->bindButtons();
            $res->post_template[] = $default_page_model->bindMenu();
            return $res;
        }
    }

}