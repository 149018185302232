<?php

\Core\View::$template = function () {

    $html = '   
        <div class="row"> 
            <div class="col-sm-12 col-12" >
                
                <div class="row">
                                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-instance-create" class="btn btn-success attempt-instance-create">Create</span>
                        <span id="go-database-instance-list" class="btn btn-info go-database-instance-list">Database Instance List</span>
                        <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
                    </div>
                        
                    <div>
                        <h4>New Database Instance</h4>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Instance Name</label>
                        <input type="text" name="instance_name" class="form-control" id="instance_name" value="" />
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div><label>Type</label></div>
                        <div id="database_image_dropdown"></div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <div><label>Size</label></div>                          
                        <div id="database_size_dropdown"></div>
                    </div>
                                          
                    <div class="col-sm-12 col-12 networks_available_wrap">
                        <div class="col-sm-12 col-12 form-group">
                            <label class="networks_enabled_label">Networks</label>
                        </div>
                        <div class="col-sm-12 col-12 form-group">
                            Internet: <input type="checkbox" style="height: 3em;" name="internet_enabled" class="form-control" id="internet_enabled" checked="checked" />
                        </div>
                        <div class="col-sm-12 col-12 form-group">
                            Egress: <input type="checkbox" style="height: 3em;" name="egress_enabled" class="form-control" id="egress_enabled" checked="checked" />
                        </div>
                    </div>
                                          
                    <div class="col-sm-12 col-12 networks_available_wrap">
                        <div class="col-sm-12 col-12 form-group">
                            <label class="networks_enabled_label">VPC Networks:</label>
                        </div>
                              
                        <div class="col-sm-12 col-12">
                            <div id="svpc_vpc_multiselect" class="form-control">
                                <select class="form-control" name="svpc_name" id="svpc_name">
                                    <option>Loading SVPCs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-12">
                        <label>Description</label>
                        <textarea name="vm_description" id="vm_description" class="form-control" rows="10" cols="30"></textarea>
                    </div>
                    
                    <div class="col-sm-12 col-12 text-center">
                        <span id="attempt-instance-create" class="btn btn-success attempt-instance-create">Create</span>
                        <span id="go-database-instance-list" class="btn btn-info go-database-instance-list">Database Instance List</span>
                        <span id="go-database-landing" class="btn btn-info go-database-landing">Database Home</span>
                    </div>
                    
                </div>
            </div>
            
             
        </div>';

    return $html;

};