<?php

Namespace Model\SC1;

use ISOPHP\core;

class CapacityReservationPageModel extends \Model\Base {


    public function showSC1CapacityReservationCreate($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 Create');
            $vars = array();
            $vars['params'] = $pageVars['params'];
            $view = new \View\SC1View();
            $view->loadTemplate('SC1', 'CapacityReservationCreate.php', $vars);
            $bind = \Model\SC1\PageModel::bindButtons();
            $view->displaySC1($bind);
            \ISOPHP\js_core::$console->log('apply SC1 CapacityReservation Create 2', $vars);
        };
    }

    public function showSC1CapacityReservationList($pageVars)
    {
        return function () use ($pageVars) {
            \ISOPHP\js_core::$console->log('Showing SC1 List');
            $vars['control'] = 'SC1';
            $vars['action'] = 'list';
            $vars['api_uri'] = '/api/sc1/capacityreservation/all';
            if (!isset($pageVars['params']['page']) || $pageVars['params']['page']=='') {
                $vars['page'] = 1 ;
            } else {
                $vars['page'] = $pageVars['params']['page'] ;
            }
            $afterwards = $this->applySC1CapacityReservationListResult($pageVars);
            $result = $this->performRequest($vars, $afterwards, true);
            \ISOPHP\js_core::$console->log('SC1 Model: ', $result);
            return $result;
        };
    }

    public function applySC1CapacityReservationListResult($pageVars)
    {
        $func = function ($request_data) use ($pageVars) {
            \ISOPHP\js_core::$console->log('applying our SC1 CapacityReservation List result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $vars = array();
                $vars['params'] = $pageVars['params'];
                $vars['request_data'] = $request_data;
                \ISOPHP\js_core::$console->log('apply SC1 CapacityReservation List 1', $vars);
                $view = new \View\SC1View();
                $view->loadTemplate('SC1', 'CapacityReservationList.php', $vars);
                $bind = \Model\SC1\PageModel::bindButtons();
                $view->displaySC1($bind);
                \ISOPHP\js_core::$console->log('apply SC1 CapacityReservation List 2', $vars);
                $page_model = new \Model\SC1\PageModel();
                $bind = $page_model::bindAllButtons() ;
                $bind() ;
            }
            if ($request_data['status'] !== 'OK') {
                \ISOPHP\js_core::$console->log('SC1 CapacityReservation List failed');
            }
        };
        return $func;
    }

    /**
     * Save capacityreservation
     */
    public function saveCapacityReservationAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('save capacityreservation to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'capacityreservation-create';
        $vars['api_uri'] = '/api/sc1/capacityreservation/create';
        $vars['size'] = $jQuery('#size_slug')->val();
        $vars['count'] = $jQuery('#count')->val();
        $vars['start_time'] = $jQuery('#start_time')->val();
        $vars['end_time'] = $jQuery('#end_time')->val();
        $vars['description'] = $jQuery('#description')->val();
        \ISOPHP\js_core::$console->log('save capacityreservation vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->saveCapacityReservationResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function saveCapacityReservationResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Save sc1 capacityreservation result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Created Capacity Reservation '.$request_data['capacityreservation']['sc1_capacityreservation_name'].' for Instance '.$request_data['capacityreservation']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 capacityreservation saved, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Creating Capacity Reservation '.$request_data['capacityreservation']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 capacityreservation save failed');
            }
        };
        return $func;
    }
    /**
     * Delete capacityreservation
     */
    public function deleteCapacityReservationAttemptToSC1($pageVars)
    {
        \ISOPHP\js_core::$console->log('delete capacityreservation to sc1');
        $jQuery = \ISOPHP\js_core::$jQuery;
        $vars = array() ;
        $vars['control'] = 'SC1';
        $vars['action'] = 'capacityreservation-delete';
        $vars['api_uri'] = '/api/sc1/capacityreservation/delete';
        $vars['name'] = $pageVars['params']['name'];
        $vars['id'] = $pageVars['params']['id'];
        \ISOPHP\js_core::$console->log('delete capacityreservation vars');
        \ISOPHP\js_core::$console->log($vars);
//        $vars['comment'] = $jQuery('#comment')->val();
        $user_logged = \ISOPHP\core::$registry->getValue('user_logged')['user'];
        $vars['user_id'] = isset($user_logged['id']) ? $user_logged['id'] : '';
        $afterwards = $this->deleteCapacityReservationResultFromSC1($pageVars);
        $result = $this->performRequest($vars, $afterwards);
        return $result;
    }

    public function deleteCapacityReservationResultFromSC1($pageVars)
    {
        $jQuery = \ISOPHP\js_core::$jQuery;
        $func = function ($request_data) use ($jQuery, $pageVars) {
            \ISOPHP\js_core::$console->log('applying our Delete sc1 capacityreservation result to', $request_data);
            if ($request_data['status'] === 'OK') {
                $msg = 'Deleted Capacity Reservation '.$request_data['capacityreservation']['sc1_capacityreservation_name'].' for Instance '.$request_data['capacityreservation']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'good');
                \ISOPHP\js_core::$console->log('sc1 capacityreservation deleted, data: ', $request_data);
            }
            if ($request_data['status'] !== 'OK') {
                $msg = 'Failed Deleting Capacity Reservation '.$request_data['capacityreservation']['sc1_instance_name'];
                \Core\WindowMessage::showMessage($msg, 'bad');
                \ISOPHP\js_core::$console->log('sc1 capacityreservation delete failed');
            }
        };
        return $func;
    }
    /**
     * Delete capacityreservation
     */


    public static function bindButtons() {
        return function () {
            if (ISOPHP_EXECUTION_ENVIRONMENT === 'UNITER') {
                $navigate = new \Model\Navigate();
                $jQuery = \ISOPHP\js_core::$jQuery;

                \ISOPHP\js_core::$console->log('binding capacity reservation buttons');

                # Capacity Reservation

                $go_sc1_capacityreservation_list = $jQuery('.go-sc1-capacityreservation-list');
                $go_sc1_capacityreservation_list->off() ;
                $go_sc1_capacityreservation_list->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'capacityreservation-list', array(), '/sc1/capacityreservation/list');
                });

                $go_sc1_capacityreservation_list_page = $jQuery('.sc1-capacityreservation-paginator');
                $go_sc1_capacityreservation_list_page->off() ;
                $go_sc1_capacityreservation_list_page->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    \ISOPHP\js_core::$console->log('Clicked sc1 capacityreservation paginator');
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $new_page = $jqThis->attr('data-page') ;
                    $jsthis->preventDefault();
                    $navigate->route('SC1', 'capacityreservation-list', array('page' => $new_page), '/sc1/capacityreservation/list/page/'.$new_page);
                });

                $go_sc1_capacityreservation_create = $jQuery('.go-sc1-capacityreservation-create');
                $go_sc1_capacityreservation_create->off() ;
                $go_sc1_capacityreservation_create->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'capacityreservation-create', array(), '/sc1/capacityreservation/create');
                });

                $save_capacityreservation = $jQuery('.attempt-capacityreservation-create');
                $save_capacityreservation->off() ;
                $save_capacityreservation->on('click', function ($jqThis) use ($navigate) {
                    $jqThis->preventDefault();
                    $navigate->route('SC1', 'capacityreservation-save-attempt', array());
                });

                $capacityreservation_delete = $jQuery('.attempt-capacityreservation-delete');
                $capacityreservation_delete->off() ;
                $capacityreservation_delete->on('click', function ($jsthis) use ($navigate, $jQuery) {
                    $jsthis->preventDefault();
                    $this_id = $jsthis->currentTarget->id;
                    $jqThis = $jQuery('#'.$this_id) ;
                    $capacityreservation_name = $jqThis->attr('data-sc1-capacityreservation-name') ;
                    $capacityreservation_id = $jqThis->attr('data-sc1-capacityreservation-id') ;
                    \ISOPHP\js_core::$console->log('Clicked capacityreservation delete button');
                    if ($capacityreservation_name !== '') {
                        $name_or_id = $capacityreservation_name ;
                    } else {
                        $name_or_id = $capacityreservation_id ;
                    }
                    $msg = "You are about to delete the Capacity Reservation $name_or_id. This cannot be undone.<br>Are you sure ?";
                    $confirmAction = function () use ($navigate, $capacityreservation_id, $capacityreservation_name) {
                        $navigate->route('SC1', 'capacityreservation-delete-attempt', array(
                            'capacityreservation_id' => $capacityreservation_id,
                            'capacityreservation_name' => $capacityreservation_name
                        ));
                    };
                    \Core\WindowMessage::areYouSure($msg, $confirmAction);
                } ) ;


            }
        } ;
    }
}