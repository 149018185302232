<?php

\Core\View::$template = function () {

    $html = ''; 
    $pv = \Core\View::$page_vars;
    \ISOPHP\js_core::$console->log('pagevars: ', $pv);
    $rqd = $pv['request_data'];

    $images_are_array = \ISOPHP\core::$php->is_array($rqd['images']) ;
    if ($images_are_array === true) {
        $images_exist = true ;
    } else {
        $images_exist = false ;
    }
    if ($images_exist === true) {
        $image_count = \ISOPHP\core::$php->count($rqd['images']) ;
    } else {
        $image_count = false ;
    }

    $html = $html .
        '
        <div class="col-sm-12 col-12 text-center">
            <span id="go-pcr-image-create" class="btn btn-success go-pcr-image-create">Create New Image</span>
            <span id="go-pcr-landing" class="btn btn-warning go-pcr-landing">PCR Home</span>
            <span id="go-registry-tag-list"
                  class="btn btn-info go-registry-tag-list"
                  data-pcr-registry-id="" >
                <i class="fa fa-1x fa-refresh fa-refresh-button"></i>
            </span>
        </div>

        <div class="col-sm-12 col-12">
            <h4>Image List</h4>
         </div> ' ;

    if ($images_exist === true && $image_count !== false) {
        $html = $html .
                '<div class="col-sm-12 col-12">
                    <div class="col-sm-12 col-12 one_pcr_image">
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">PCR Image ID</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">Registry</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">Name</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">Value</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">Type</h5>
                        </div>
                        <div class="col-sm-2 col-2">
                            <h5 class="pcr_image_table_title">Action</h5>
                        </div>                        
                    </div>
                 </div>';
        foreach ($rqd['images'] as $one_pcr_image) {
            $html = $html .
                '<div class="col-sm-12 col-12 sahara_table">
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_id"><span class="pcr_id pcr_listing">' . $one_pcr_image['pcr_image_id'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_registry_name"><span class="pcr_registry_name pcr_listing">' . $one_pcr_image['pcr_registry_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_image_name"><span class="pcr_image_name pcr_listing">' . $one_pcr_image['pcr_image_name'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_image_data"><span class="pcr_image_data pcr_listing">' . $one_pcr_image['pcr_image_value'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <p class="pcr_list_image_type"><span class="pcr_type pcr_listing">' . $one_pcr_image['pcr_image_type'] . '</span></p>
                    </div>
                    <div class="col-sm-2 col-2">
                        <div class="col-sm-12 col-12">
                            <div class="col-sm-4 col-4">
                                <i id="edit_pcr_image_' . $one_pcr_image['pcr_image_id'] . '"
                                   class="fa fa-1x fa-info-square hvr-grow dom attempt-image-edit btn btn-outline-primary"
                                   data-pcr-image-id="' . $one_pcr_image['pcr_image_id'] . '"
                                   data-pcr-image-name="' . $one_pcr_image['pcr_image_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="edit_pcr_image_' . $one_pcr_image['pcr_image_id'] . '"
                                   class="fa fa-1x fa-edit hvr-grow dom attempt-image-edit btn btn-outline-primary"
                                   data-pcr-image-id="' . $one_pcr_image['pcr_image_id'] . '"
                                   data-pcr-image-name="' . $one_pcr_image['pcr_image_name'] . '"></i>
                            </div>
                            <div class="col-sm-4 col-4">
                                <i id="delete_pcr_image_' . $one_pcr_image['pcr_image_id'] . '"
                                   class="fa fa-1x fa-close hvr-grow dom attempt-image-delete btn btn-outline-danger"
                                   data-pcr-image-id="' . $one_pcr_image['pcr_image_id'] . '"
                                   data-pcr-image-name="' . $one_pcr_image['pcr_image_name'] . '"></i>
                            </div>
                        </div>
                    </div>
                 </div>';
        }
    } else {


    }

    return $html;

};